import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import logoHotline from "../../images/Badge_hotline.png";
import BadgeCard from '../Modules/BadgeCard';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import logo from "../../images/rocky-logo-square.png";
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    CardOfferPlan: theme.CardPlanBorder, 
    CardOffer: {
        boxShadow: "none",
        border: '0px',
        borderRadius: "10px",
        margin: `0 2px`,
        backgroundColor: theme.palette.themeLightGrey,
        color: theme.palette.black,
        top: 0,
        minWidth: 100,
        textAlign: "center",
    },
    CardSubItem: {
        margin: 10,
        color: theme.palette.black,
        padding: 0,
        border: '1px',
        borderColor: 'black',
        borderRadius: "10px",
        backgroundColor: 'white',
    },
    CardBackItem: {
        transform: "scale(0.8)",
        margin: 4,
        color: theme.palette.black,
        padding: 0,
        border: '1px',
        borderColor: 'black',
        borderRadius: "10px",
        backgroundColor: 'white',
    },
});



class SubscriptionCard extends Component {

    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading purchases',
            selectedSubItem: 0,
        };
    }

    componentDidUpdate(prevProps)  {
        if(this.props.changeOffer?.length > 2 && this.props.changeOffer !== this.state.selectedId){
            var sIndex = -1;
            this.props.item?.items?.map( (subItem, index) => {
                if(subItem.id === this.props.changeOffer){
                    sIndex = index;
                }
            })
            if(sIndex >= 0){
                this.setState({selectedId: this.props.changeOffer, selectedSubItem: sIndex});
            }
        }
    }

    render() {
        //console.log('props', this.props)
        const {theme, classes} = this.props;
        const {item} = this.props;
        var profile = (this.props.user?.onboardingResponses?.includes('Executive')
            ?   'executive'
            :   this.props.user?.onboardingResponses?.includes('Teenager')
                ?   'teenager'
                :   this.props.user?.onboardingResponses?.includes('Student')
                    ?   'student'
                    :   this.props.user?.onboardingResponses?.includes('Professional')
                        ?   'professional'
                            :   this.props.user?.onboardingResponses?.includes('Available')
                            ?   'seeker'
                        :   'default'
            )
        //console.log('profile', profile)
        if(item!== undefined){
            return(
                <div>
                    <Card className={classes.CardOfferPlan} > 
                        {false &&
                            <Grid container>
                                <Grid item xs={9}> 
                                    <Grid 
                                        container
                                        direction="column"
                                        justify="space-evenly"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <span><strong>
                                                {
                                                item.items!== undefined && item.items.length >0
                                                    ?item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                        item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].title !== undefined && 
                                                        item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].title !== ""
                                                        ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].title 
                                                        :   item.title
                                                    :   item.title
                                                } 
                                            </strong></span>
                                            <span style={{color: theme.palette.secondary.light}}><br/>
                                                {
                                                item.items!== undefined && item.items.length >0
                                                    ?item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                        item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].description !== undefined && 
                                                        item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].description !== ""
                                                        ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].description 
                                                        :   item.description
                                                    :   item.description
                                                } 
                                            </span>
                                        </Grid>

                                        {(item.items=== undefined || item.items.length < 1) &&
                                                <>
                                                    <Grid item>
                                                        <p style={{color: theme.palette.themeGrey}}><br/>  {item.type} </p>
                                                    </Grid>

                                                    <Grid item>
                                                        <span><strong>{item.price} </strong> </span>
                                                        <span style={{color: theme.palette.secondary.light}}><br/> {item.billing} </span>
                                                    </Grid>
                                                </>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} style={{backgroundColor:theme.palette.primary.dark}}> 
                                    <img src={
                                        item.items!== undefined && item.items.length >0
                                            ?item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].imageUrl !== undefined && 
                                                item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].imageUrl !==""
                                                ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].imageUrl 
                                                :   logo
                                            : item.imageUrl!== undefined && item.imageUrl!==''
                                                ?   item.imageUrl
                                                :   logo
                                        } 
                                        style={{maxWidth:'80%', 
                                            maxHeight: '80%', 
                                            height: 'auto', 
                                            verticalAlign: 'middle',
                                            marginTop: 8,
                                            textAlign: 'right',
                                            borderRadius: theme.borderRadius,
                                        }} 
                                        alt='Rocky.ai' />
                                </Grid>
                            </Grid>
                        }
                        {(item.items!== undefined && item.items.length >0) &&
                            <div style={{textAlign:'left', width: '100%'}}><br/>Choose your plan:</div>
                        }
                        {(item.items!== undefined && item.items.length >0) &&
                            item.items.map( (subItem, index) => {
                                if(subItem.requirements === undefined || profile === "default" || !(profile?.length > 2) || subItem.requirements?.includes(profile)){
                                    return(
                                        <Grid item xs={12} key={"subitemlist_"+index}
                                            style={{
                                                borderStyle : index === this.state.selectedSubItem ? 'solid' : 'none',
                                                backgroundColor : index === this.state.selectedSubItem ? theme.palette.themeLightGrey : 'transparent',
                                                margin : 0,
                                                borderRadius : theme.borderRadius
                                            }}
                                        > 
                                            {(item.price!==undefined && index === this.state.selectedSubItem) &&
                                                <Grid container>
                                                    <Grid item xs={9}> 
                                                        <Grid 
                                                            container
                                                            direction="column"
                                                            justify="space-evenly"
                                                            alignItems="center"
                                                        >
                                                            <Grid item style={{paddingLeft: 15, paddingRight:15, paddingTop:15}}>
                                                                <span><strong>
                                                                    {
                                                                    item.items!== undefined && item.items.length >0
                                                                        ?item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                                            item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].title !== undefined && 
                                                                            item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].title !== ""
                                                                            ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].title 
                                                                            :   item.title
                                                                        :   item.title
                                                                    } 
                                                                </strong></span>
                                                                <span style={{color: theme.palette.secondary.light}}><br/>
                                                                    {
                                                                    item.items!== undefined && item.items.length >0
                                                                        ?item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                                            item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].description !== undefined && 
                                                                            item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].description !== ""
                                                                            ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].description 
                                                                            :   item.description
                                                                        :   item.description
                                                                    } 
                                                                </span>
                                                            </Grid>
                        
                                                            {(item.items=== undefined || item.items.length < 1) &&
                                                                    <>
                                                                        <Grid item>
                                                                            <p style={{color: theme.palette.themeGrey}}><br/>  {item.type} </p>
                                                                        </Grid>
                        
                                                                        <Grid item>
                                                                            <span><strong>{item.price} </strong> </span>
                                                                            <span style={{color: theme.palette.secondary.light}}><br/> {item.billing} </span>
                                                                        </Grid>
                                                                    </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={3}> 
                                                        <img src={
                                                            item.items!== undefined && item.items.length >0
                                                                ?item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                                    item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].imageUrl !== undefined && 
                                                                    item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].imageUrl !==""
                                                                    ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].imageUrl 
                                                                    :   logo
                                                                : item.imageUrl!== undefined && item.imageUrl!==''
                                                                    ?   item.imageUrl
                                                                    :   logo
                                                            } 
                                                            style={{maxWidth:'80%', 
                                                                maxHeight: '80%', 
                                                                height: 'auto', 
                                                                verticalAlign: 'middle',
                                                                marginTop: 8,
                                                                textAlign: 'right',
                                                                borderRadius: theme.borderRadius,
                                                            }} 
                                                            alt='Rocky.ai' />
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Card className={index === this.state.selectedSubItem ? classes.CardSubItem : classes.CardBackItem} 
                                                onClick={ () => {
                                                    if(index === this.state.selectedSubItem && this.props.cardClickAction !== undefined){
                                                        this.props.cardClickAction(item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0]);
                                                    }
                                                    this.setState({selectedSubItem: index, selectedId: subItem.id});
                                                    if(this.props.changeSelection !== undefined){
                                                        this.props.changeSelection(subItem.id);
                                                    }
                                                    if(this.props.changeArticle !== undefined){
                                                        this.props.changeArticle(subItem);
                                                    }
                                                }}
                                            >
                                                <Grid 
                                                    container
                                                    direction="row"
                                                    justify="space-between" 
                                                    alignItems="center"
                                                >  
                                                    <Grid item xs={12} 
                                                        style={{
                                                            backgroundColor: subItem.promotion!== undefined && subItem.promotion!=='' &&  index === this.state.selectedSubItem
                                                                ? theme.palette.secondary.main
                                                                : theme.palette.themeLightGrey
                                                            , 
                                                            width:"100%"
                                                        }}
                                                    >
                                                        {subItem.promotion!== undefined && subItem.promotion!=='' &&
                                                            <div style={{paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom:0}}>
                                                                <p>
                                                                    <span 
                                                                        style={{
                                                                            color: subItem.promotion!== undefined && subItem.promotion!=='' &&  index === this.state.selectedSubItem
                                                                                ? theme.palette.white
                                                                                : theme.palette.black
                                                                        }}
                                                                    >
                                                                        {index === this.state.selectedSubItem
                                                                            ?
                                                                            <strong>{subItem.promotion}</strong>
                                                                            :
                                                                            subItem.promotion
                                                                        }
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {(this.props.user !== undefined && this.props.user.purchasedProduct !== undefined && 
                                                            ((this.props.user.purchasedProduct.id === subItem.id ) || (this.props.user.purchasedProduct.id === subItem.storeId)))
                                                            ?
                                                                <CheckCircleIcon
                                                                    style={{padding:0, color: index === this.state.selectedSubItem ? 'green': 'green'}}
                                                                /> 
                                                            :
                                                                <Radio
                                                                    style={{padding:0}}
                                                                    checked={ (index === this.state.selectedSubItem  )}
                                                                /> 
                                                        }
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <span style={{color: theme.palette.themeGrey, fontSize:14}}>{subItem.type} </span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span><strong>{subItem?.price?.includes("Free") ? "Check Store" : subItem.price}</strong> </span>
                                                        <span style={{color: theme.palette.secondary.light}}><br/> {subItem.billing} </span>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            {(item.price!==undefined && index === this.state.selectedSubItem) &&
                                                <div style={{textAlign:'right', width:'100%', marginBottom: 20, paddingRight: 10}}>
                                                    {(item.items!== undefined && item.items.length > 0) 
                                                        ?
                                                            <>
                                                                {item.items?.[this.state.selectedSubItem]?.details?.length>0 
                                                                    &&
                                                                    <div style={{width: '100%', textAlign: 'right', paddingRight: 10, paddingLeft: 10, paddingTop:10, marginTop:-10, paddingBottom: 10}}>
                                                                        <span style={{fontSize: "smaller"}}>{item.items[this.state.selectedSubItem].details}</span>
                                                                    </div>
                                                                }
                                                                {(this.props.user?.purchasedProduct?.owned && ((this.props.user?.purchasedProduct?.id === subItem?.id ) || (this.props.user?.purchasedProduct?.id === subItem?.storeId)))
                                                                    ?
                                                                        <div>
                                                                            {window?.CdvPurchas?.store?.version !== undefined
                                                                            ?
                                                                                <div>
                                                                                    You own this subscription! &nbsp;&nbsp;
                                                                                    {this.props.storeManageSubscriptions!== undefined
                                                                                    &&
                                                                                        <Button onClick={() => this.props.storeManageSubscriptions()}>
                                                                                            Manage Mobile Subscriptions</Button>
                                                                                    }
                                                                                </div>
                                                                            :
                                                                                <div>You own this subscription! &nbsp;&nbsp;
                                                                                    {this.props.user?.purchasedProduct?.receiptLink?.includes("http") 
                                                                                        &&
                                                                                        <Button onClick={() => {window.open(this.props.user.purchasedProduct.receiptLink, "_blank")}}>
                                                                                            Manage Subscriptions</Button>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    :
                                                                        <div>
                                                                            <Button 
                                                                                disabled={item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].canPurchase!==false ? false : true}
                                                                                onClick={ () => {this.props.cardClickAction(item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0])}}
                                                                            >
                                                                                {item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                                                                    item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].button !==undefined && 
                                                                                    item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].button !==""
                                                                                    ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].button
                                                                                    :   "Purchase"
                                                                                }&nbsp;<CreditCardIcon/>
                                                                            </Button>
                                                                        </div>
                                                                }
                                                                {item.items?.[this.state.selectedSubItem]?.comparison?.length>0 
                                                                    &&
                                                                    <Grid 
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between" 
                                                                        alignItems="center"
                                                                        style={{width: '100%', padding: 10}}
                                                                    >
                                                                        <Grid item xs={1}>
                                                                            <InfoIcon style={{color: theme.palette.secondary.main, textAlign:'left'}}/>
                                                                        </Grid>
                                                                        <Grid item xs={11}>
                                                                            <div style={{width: '100%', textAlign: 'right', paddingRight: 0, paddingLeft: 10, paddingTop:0, marginTop: 0, paddingBottom: 0}}>
                                                                                {item.items?.[this.state.selectedSubItem]?.comparison?.split(",").map((textLine, textIndex) => (
                                                                                    <span key={"text_"+textIndex} style={{fontSize: "small", color: theme.palette.secondary.main, lineHeight: '.4em'}}>
                                                                                        &nbsp;{textLine}<br/>
                                                                                    </span>
                                                                                ))}
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                        :
                                                            <Button 
                                                                disabled={item.canPurchase!==false ? false : true}
                                                                onClick={ () => {this.props.cardClickAction(item)}}
                                                            >
                                                                {item.button!==undefined && item.button !==" "
                                                                    ?   item.button
                                                                    :   "Purchase"
                                                                }&nbsp;<CreditCardIcon/>
                                                            </Button>
                                                    }
                                                </div>
                                            }
                                        </Grid>
                                    )
                                }
                                else return ""
                            })
                        }
                        {this.props?.user?.roles?.includes("COACHADMIN") 
                        &&
                            <div 
                                style={{
                                    transform: "scale(0.8)",
                                    margin: 4}
                                }
                            >
                                <BadgeCard
                                    backgroundColor ={"white"}
                                    image={logoHotline} 
                                    avatarImage = {undefined}
                                    title = {'Get your custom offer!'}
                                    subtitle = {'Book a call'}
                                    strongSubtitle = {true}
                                    onClick={ () => window.open("https://calendly.com/rocky-ai/30min", "_blank") }
                                    button={<>Zoom&nbsp;<HeadsetMicIcon /></>}
                                /> 
                            </div>
                        }
                    </Card>
                    {false && item.price!==undefined && 
                        <div style={{float:'right', marginTop:-40, marginBottom: 30}}>
                            {(item.items!== undefined && item.items.length >0) 
                                ?
                                    <Button 
                                        disabled={item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].canPurchase!==false ? false : true}
                                        onClick={ () => {this.props.cardClickAction(item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0])}}>
                                        {item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0] !== undefined &&
                                            item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].button !==undefined && 
                                            item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].button !==""
                                            ?   item.items[this.state.selectedSubItem ?this.state.selectedSubItem :0].button
                                            :   "Purchase"
                                        }
                                    </Button>
                                :
                                    <Button 
                                        disabled={item.canPurchase!==false ? false : true}
                                        onClick={ () => {this.props.cardClickAction(item)}}>
                                        {item.button!==undefined && item.button !==" "
                                            ?   item.button
                                            :   "Purchase"
                                        }
                                    </Button>
                            }
                        </div>
                    }
                </div>
            )
        }
        else{
            return ""
        }
    }


}

export default compose(
    withStyles(styles, { withTheme: true }),
)(SubscriptionCard);
