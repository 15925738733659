export const LANDING = '/';
export const HOME = '/home';
export const WELCOME = '/welcome';
export const WELCOMECHAT = '/welcomechat';
export const ONBOARDING = '/onboarding';
export const ONBOARDQUICK = '/onboardquick';
//export const WELCOME_1 = '/welcome_1';
//export const WELCOME_2 = '/welcome_2';
//export const WELCOME_3 = '/welcome_3';
//export const ONBOARDING_Q1 = '/onboarding_q1';
//export const ONBOARDING_Q2 = '/onboarding_q2';
//export const ONBOARDING_Q3 = '/onboarding_q3';
//export const ONBOARDING_Q4 = '/onboarding_q4';
//export const ONBOARDING_Q5 = '/onboarding_q5';
//export const ONBOARDING_LOADING_RESULT = '/onboarding_loading_result';
//export const ONBOARDING_SCENARIO = '/onboarding_scenario';
export const FIRST_SESSION = '/first_session';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const LINK_UNSUBSCRIBE_EMAIL = '/emailnotifications';
export const PROGRAM = '/program';
export const ROUTINE = '/routine';
export const STATS = '/stats';
export const CONTENT = '/content';
export const DIALOGUE = '/dialogue';
export const JOURNAL = '/days';
export const MESSAGES = '/messages';
export const SUPPORT = '/support';
export const CHATBOT = '/chatbot';
export const START_PRIMING = '/start_priming';
export const START_REFLECTION = '/start_reflection';
export const DIALOGUES = '/dialogues';
export const DIALOGUES_DETAIL = '/dialogues/:id';
//export const ACCOUNT = '/account';
export const PROFILE = '/profile';
export const PAYMENT = '/payment';
export const PRODUCTS = '/products';

// ADMIN SECTION
export const ADMIN = '/admin';
export const ADMIN_DASHBOARD = '/admin/dashboard';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_TUTORIALS = '/admin/tutorials';
export const ADMIN_SHOP = '/admin/shop';
export const ADMIN_NEWS = '/admin/news';
export const ADMIN_USERS_DETAILS = '/admin/users/:id';
export const ADMIN_CONTENTS = '/admin/contents';
export const ADMIN_CONTENTS_DETAILS = '/admin/contents/:id';
export const ADMIN_FLAGS = '/admin/content_flags'
export const ADMIN_BADGES = '/admin/badges';
export const ADMIN_BADGES_DETAILS = '/admin/badges/:id';
export const ADMIN_DIALOGUES = '/admin/dialogues';
export const ADMIN_DIALOGUES_DETAILS = '/admin/dialogues/:id';
export const ADMIN_FEEDBACKS = '/admin/feedbacks';
export const ADMIN_LEADERSHIP_CATEGORIES = '/admin/leadership_categories';
export const ADMIN_LEADERSHIP_CATEGORIES_DETAILS = '/admin/leadership_categories/:id';
export const ADMIN_LEADERSHIP_PROGRAMS = '/admin/leadership_programs';
export const ADMIN_LEADERSHIP_PROGRAMS_DETAILS = '/admin/leadership_programs/:id';
export const ADMIN_LEADERSHIP_QUALITIES = '/admin/leadership_qualities';
export const ADMIN_LEADERSHIP_QUALITIES_DETAILS = '/admin/leadership_qualities/:id';
export const ADMIN_TASKS = '/admin/tasks';
export const ADMIN_TASKS_DETAILS = '/admin/tasks/:id';
export const ADMIN_UTTERANCES = '/admin/utterances';
export const ADMIN_UTTERANCES_DETAILS = '/admin/utterances/:id';
export const ADMIN_INTENTS = '/admin/intents';
export const ADMIN_INTENTSTATS = '/admin/intentstats';
export const ADMIN_TRAINING = '/admin/training';
export const ADMIN_QUESTIONNAIRES = '/admin/questionnaires';


// COACH Pages
export const COACH = '/coach';
export const COACH_SIGN_UP = '/coachsignup';
export const CREATOR_SIGN_UP = '/creatorsignup';
export const TEAM_SIGN_UP = '/teamsignup';
export const COACH_USERS = '/coach/users';
export const COACH_CONTENT = '/coach/content';
export const COACH_ADMIN = '/coach/admin';

export const ALEXA_SIGN_IN = '/alexasignin';

