import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

//// List lements
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//import AddCircleIcon from '@material-ui/icons/AddCircle';
import SendIcon from '@material-ui/icons/Send';

const styles = theme => ({
    root: {
        
    },
})



class ShowGoalSuggestions extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            contentSelection : [],
            loading : true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const preLoadContenSelection = [...this.props.contentSelection];
        this.loadSuggestions(preLoadContenSelection);
        //console.log('state', this.state)
        //this.loadMessages()
    }
    componentWillUnmount() {
        this._isMounted = false;
        //if(this.unsubscribeMessages!==undefined) this.unsubscribeMessages();
    }
    componentDidUpdate(prevProps)  {
        var newValues = '';
        var oldValues = '';
        if(this.props.contentSelection !== undefined ){
            newValues = this.props.contentSelection
        }
        if(prevProps.contentSelection !== undefined){
            oldValues = prevProps.contentSelection;
        }
        //console.log('opened', this.props.nluIntent )
        if(newValues !== oldValues && this._isMounted) {
            this.setState({
                loading : true,
            })
            this.loadSuggestions(newValues);
            //console.log(this.props.nluIntent.intent)
        }
        return true
    }

    loadSuggestions(contenSelection) {
        var actionOptions = [];
        var reflectionOptions = [];
        contenSelection !== undefined && contenSelection.map( (g) => {
            var actionArray = undefined;
            var reflectionArray = undefined;
            if(g.validated){
                actionArray = g.actions;
                reflectionArray = g.explanation;
            }
            var randomChoiceText = []; //["I want to ", "I want ", "I should ", "I could ", "I must "];
            var randomReflectionText = ["Today, I am interested in ", "My day is fantastic, because ", "I was always inspired by ", "Today I was really good in ", "I have challenges with "];
            var choiceText = randomChoiceText[Math.floor(Math.random()*randomChoiceText.length)];
            var choiceText2 = choiceText;
            var reflectionText = randomReflectionText[Math.floor(Math.random()*randomReflectionText.length)];
            if(actionArray!== undefined && actionArray.length >0){
                choiceText = actionArray[Math.floor(Math.random()*actionArray.length)];
                choiceText2 = choiceText;
                if(contenSelection.length <=3){
                    choiceText2 = actionArray[Math.floor(Math.random()*actionArray.length)];
                }
            }
            if(reflectionArray!== undefined && reflectionArray.length >0){
                reflectionText = reflectionArray[Math.floor(Math.random()*reflectionArray.length)];
            }
            if(choiceText?.length > 4 && !actionOptions.includes(choiceText)){
                actionOptions.push(choiceText);
            }
            if(choiceText2?.length > 4 && !actionOptions.includes(choiceText2)){
                actionOptions.push(choiceText2);
            }
            if(!reflectionOptions.includes(reflectionText)){
                reflectionOptions.push(reflectionText);
            }
            return true;
        })
        this.setState({contentSelection : contenSelection, actionOptions, reflectionOptions, loading: false})
    }

    render () {
        const {theme} = this.props;
        if(this.state.loading){
            return "...loading"
        }
        else{
            //console.log('state', this.props)
            var checkActionFlag = false
            //// overwrtite
            if(this.props.actionFlag === true){
                checkActionFlag = true;
            }
            else if(this.props.questionIntent !== undefined && this.props.questionIntent.utteranceClass !== undefined 
                && this.props.questionIntent.utteranceClass.length > 0){
                var lastIntent = this.props.questionIntent.utteranceClass[this.props.questionIntent.utteranceClass.length-1];
                if(lastIntent.includes('priorit') || lastIntent.includes('activit') || lastIntent.includes('task') || lastIntent.includes('prep') || lastIntent.includes('expectation') || lastIntent.includes('help') ){
                    checkActionFlag = true;
                }
            }
            if(checkActionFlag){
                return (
                    <List >
                        {this.state.actionOptions !== undefined && this.state.actionOptions.map( (choiceText, index) => {
                            return(
                                <ListItem key={'actionsuggest_'+index}     
                                    style={
                                        this.state['swuphover'+index] === true
                                            ?   {paddingLeft:0, paddingRight:0, backgroundColor: theme.palette.themeGold, borderRadius: theme.borderRadius}
                                            :   {paddingLeft:0, paddingRight:0, backgroundColor: 'transparent' }
                                    }
                                    onClick={() => {this.submitInspiration(this.modifiedChoiceText(choiceText))} }
                                    onMouseEnter={() => {this.setState({['swuphover'+index]: true})}}
                                    onMouseLeave={() => {this.setState({['swuphover'+index]: false})}}
                                    >
                                    <ListItemText style={{paddingLeft:10, paddingRight:10}}>
                                        {this.modifiedChoiceText(choiceText)}
                                    </ListItemText>
                                    <ListItemIcon style={{paddingLeft:10, paddingRight:10, minWidth:'auto', color:theme.palette.themeRed}}>
                                        <SendIcon fontSize='small' />
                                    </ListItemIcon>
                                </ListItem>
                            )
                        })}
                    </List>
                )
            }
            else{
                return (
                    <List >
                        {this.state.reflectionOptions !== undefined && this.state.reflectionOptions.map( (choiceText, index) => {
                            return(
                                <ListItem key={'actionsuggest_'+index}  
                                    style={
                                        this.state['swuphover'+index] === true
                                            ?   {paddingLeft:0, paddingRight:0, backgroundColor: theme.palette.themeGold, borderRadius: theme.borderRadius}
                                            :   {paddingLeft:0, paddingRight:0, backgroundColor: 'transparent' }
                                    }
                                    onClick={() => {this.submitInspiration(this.modifiedChoiceText(choiceText))} }
                                    onMouseEnter={() => {this.setState({['swuphover'+index]: true})}}
                                    onMouseLeave={() => {this.setState({['swuphover'+index]: false})}}
                                    >
                                    <ListItemText style={{paddingLeft:10, paddingRight:10}}>
                                        {this.modifiedChoiceText(choiceText)}
                                    </ListItemText>
                                    <ListItemIcon style={{paddingLeft:10, paddingRight:10, minWidth:'auto', color:theme.palette.themeRed}}>
                                        <SendIcon fontSize='small' />
                                    </ListItemIcon>
                                </ListItem>
                            )
                        })}
                    </List>
                )
            }
        }
    }

    modifiedChoiceText(choiceText){
        if(!(choiceText?.length > 0)){
            return ""
        }
        else if(this.props.commitType === "wish"){
            return choiceText.replace("I will ", "I wish to ")
        }
        else if(this.props.commitType === "want"){
            return choiceText.replace("I will ", "I want to ")
        }
        else if(this.props.commitType === "should"){
            return choiceText.replace("I will ", "I should ")
        }
        else{
            return choiceText
        }
    }

    submitInspiration(choiceText){
        if(this.props.submitInspiration !== undefined){
            this.props.submitInspiration(choiceText); 
        }
    }

}

export default compose(
    withStyles(styles, { withTheme: true})
)(ShowGoalSuggestions);
