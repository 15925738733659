export function DeleteExamplesFromOtherIntentes(that, exampleText, replaceId) {
    // 3rd remove from from all other training sets
    var replaceIds = [];
    if (typeof replaceId === 'string' || replaceId instanceof String){
        replaceIds = [replaceId];
    }
    else{
        replaceIds = replaceId;
    }
    console.log('DeleteExamplesFromOtherIntentes search for duplicates: ', exampleText)
    if(exampleText !== undefined && exampleText!== ""){
        that.props.firebase.agentIntentClasses()
        .where('examples', 'array-contains', exampleText)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
                if(!replaceIds.includes(doc.id)){
                    that.props.firebase.agentIntent(doc.id).update({examples: that.props.firebase.fieldValue.arrayRemove(exampleText) })
                    console.log('removed duplicate example from present', doc.id)
                }
                else{
                    //console.log('example now in', replaceId, exampleText)
                }
            })
        })
        that.props.firebase.agentIntentClasses()
        .where('examplesPast', 'array-contains', exampleText)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
                if(!replaceIds.includes(doc.id)){
                    that.props.firebase.agentIntent(doc.id).update({examplesPast: that.props.firebase.fieldValue.arrayRemove(exampleText) })
                    console.log('removed duplicate example from past', doc.id)
                }
                else{
                    //console.log('example now past in', replaceId, exampleText)
                }
            })
        })
        that.props.firebase.agentIntentClasses()
        .where('examplesFuture', 'array-contains', exampleText)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
                if(!replaceIds.includes(doc.id)){
                    that.props.firebase.agentIntent(doc.id).update({examplesFuture: that.props.firebase.fieldValue.arrayRemove(exampleText) })
                    console.log('removed duplicate example from future', doc.id)
                }
                else{
                    //console.log('example now fute', replaceId, exampleText)
                }
            })
        })
        that.props.firebase.agentIntentClasses()
        .where('examplesLow', 'array-contains', exampleText)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
                if(!replaceIds.includes(doc.id)){
                    that.props.firebase.agentIntent(doc.id).update({examplesLow: that.props.firebase.fieldValue.arrayRemove(exampleText) })
                    console.log('removed duplicate example from Low', doc.id)
                }
                else{
                    //console.log('example now low', replaceId, exampleText)
                }
            })
        })
        that.props.firebase.agentIntentClasses()
        .where('examplesFalse', 'array-contains', exampleText)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
                if(!replaceIds.includes(doc.id)){
                    that.props.firebase.agentIntent(doc.id).update({examplesFalse: that.props.firebase.fieldValue.arrayRemove(exampleText) })
                    console.log('removed duplicate example from False', doc.id)
                }
                else{
                    //console.log('example now false', replaceId, exampleText)
                }
            })
        })
        if(exampleText !== undefined && exampleText!== ""){
            var id = (exampleText+"").replace(/[^A-Za-z]/g,'');
            id = id.replace(/ /g,'');
            if(id.length > 75){
                id = id.substring(0, 75);
            }
            //console.log('delete id from training data sets', id)
            if(id !== undefined && id !== ""){
                try{
                    that.props.firebase.trainingDataItem(id).delete();
                    if(that.props.cleanItem !== undefined){
                        console.log('clean also', exampleText)
                        that.props.cleanItem(exampleText);
                    }
                }
                catch(e){
                    
                }
            }
        }
    }
}

export async function AddExampletoNLUTraining(that, example, sentiment, intent, tense="default") {
    var qualities = undefined;
    if(example?.length > 1){
        if (sentiment === undefined){
            sentiment = that.state.selectedSentiment
        }
        if (intent === undefined){
            intent = that.state.selectedIntent
        }
        //console.log('home: ' + that.props.groupIntent, that.props.groupSentiment )
        if(sentiment!==undefined && intent!==undefined){
            //console.log('Searching for ('+tense+ '): ' + intent, sentiment)
            that.setState({errorMessage : 'Searching for ('+tense+ '): ' + intent});
            //ar index = 0;
            return that.props.firebase
            .agentIntentClasses()
            .where('sentiment', '==', sentiment)
            .where('intent', '==', intent)
            .where('tenses', 'array-contains', tense)
            .get().then(snapshot => {
                if(snapshot.empty){
                    console.log('no examples')
                    return qualities;
                }
                else{
                    var newIds = []
                    snapshot.forEach((doc) =>{
                        newIds.push(doc.id);
                        //console.log( tense + ":", example)
                        var traingSet = doc.data();
                        if(traingSet?.qualities?.length > 0){
                            console.log("traingSet?.qualities", traingSet?.qualities)
                            qualities = traingSet.qualities;
                        }
                        var upDateSet = {}
                        var key = 'examples';
                        if(that.props.selectedExplanation!== undefined &&  that.props.selectedExplanation!== ""){
                            upDateSet["responses"] = that.props.firebase.fieldValue.arrayUnion(that.props.selectedExplanation.trim());
                        }
                        if(tense === 'future'){
                            upDateSet["examplesFuture"] =  that.props.firebase.fieldValue.arrayUnion(example.trim());
                            key = 'examplesFuture';
                        }
                        else if (tense === 'past'){
                            upDateSet["examplesPast"] =  that.props.firebase.fieldValue.arrayUnion(example.trim());
                            key = 'examplesPast';
                        }
                        else{
                            upDateSet["examples"] = that.props.firebase.fieldValue.arrayUnion(example.trim());
                            key = 'examples';
                        }
                        that.props.firebase.agentIntent(doc.id).update(upDateSet);
                        if(that._isMounted){
                            //console.log('found '+ index + ":", doc.data(), upDateSet)
                            that.setState({errorMessage : 'Added '+example.trim() +' to ('+tense+ ') ' + intent})
                            if(that.props.addIntent!== undefined) {
                                that.props.addIntent(intent, tense)
                            }
                            if(that.props.handlerRemoveItem!== undefined) {
                                var removeText = example;
                                if (that.props.nluIntent !== undefined && that.props.nluIntent.text !== undefined && that.props.nluIntent.text !== ""){
                                    removeText = that.props.nluIntent.text;
                                }
                                //console.log('remove', removeText)
                                that.props.handlerRemoveItem(removeText, doc.id, key)
                            }
                        }
                        //index += 1;
                    })
                    //console.log('remove: ', that.state.previewTextArray?.[0] , example)
                    DeleteExamplesFromOtherIntentes(that, (that.state.previewTextArray?.[0] ||  example), newIds);
                    if(that.props.cleanItem !== undefined){
                        that.props.cleanItem((that.state.previewTextArray?.[0] || example));
                    }
                    console.log('added: ', sentiment, qualities)
                    return qualities;
                }
            })
        }
        else{
            that.setState({errorMessage : 'Please choose sentiment and intent first!'});
            return qualities;
        }
    }
}

export const DescriptionSentiment = {
    inform_positive_short: 'A positive response or sentiment that should lead to a follow-up question.',
    inform_positive_long: 'Information about an accomplishment or intention with description. NO follow-up.',
    inform_neutral_short: 'An indication about a topic or goal is provided and requires a follow-up question',
    inform_neutral_long: 'A conclusion about a topic is provided. NO follow-up.',
    inform_negative_short: 'A negative response or sentiment that requires a follow-up question.',
    inform_negative_long: 'A failure reason is provided. NO follow-up.',
    inform_intention_short: 'An action described that requires a follow-up question.',
    inform_intention_long: 'A goal or intention is defined.',
    question: 'A general question was asked.',
    chat: 'A request for consultation was given to the AI.',
    inform_measurement: 'Any numbers and dates.',
}

export const SentimentTopicIntentsPrefixes = {
    inform_positive_short: 'inform_sentiment_',
    inform_positive_long: 'inform_accomplishment_',
    inform_neutral_short: 'inform_indicator_',
    inform_neutral_long: 'inform_conclusions_',
    inform_negative_short: 'inform_challenge_',
    inform_negative_long: 'inform_failurereason_',
    inform_intention_short: 'inform_activity_',
    inform_intention_long: 'inform_intention_',
    question: 'question_conclusions_',
    chat: 'request_consulting_',
}

export const TopicIntentsPrefixes = [
    'inform_sentiment_',
    'inform_accomplishment_',
    'inform_indicator_',
    'inform_conclusions_',
    'inform_challenge_',
    'inform_failurereason_',
    'inform_activity_',
    'inform_intention_',
    'question_conclusions_',
    'request_consulting_'
]

export function SentimentPrefixChanger(newPrefix, intentText){
    if(newPrefix.slice(-1) !== "_"){
        newPrefix = newPrefix + "_"
    }
    TopicIntentsPrefixes.map(item => {
        intentText = intentText.replace(item, newPrefix);
        //console.log('intentText', intentText, item, newPrefix)
    })
    return intentText
}

export function SelectIntentsList(preIntent, utter, mood='default') {
    var resultIntentsList = [];
    if(preIntent?.length>3){
        var intentIndicator = preIntent;
        var intentActivity = preIntent;
        var intentSentiment = preIntent;
        var intentChallenge = preIntent;
        TopicIntentsPrefixes.map(intent => {
            intentIndicator = intentIndicator.replace(intent, 'inform_indicator_');
            intentActivity = intentActivity.replace(intent, 'inform_activity_');
            intentSentiment = intentSentiment.replace(intent, 'inform_sentiment_');
            intentChallenge = intentChallenge.replace(intent, 'inform_challenge_');
        })
        //console.log('swith utter', utter)
        switch (utter){
            case 'utter_ask_activity':
                return [intentActivity]
            case 'utter_ask_awareness':
                return [intentIndicator, intentActivity]
            case 'utter_ask_goal_habit':
                return [intentIndicator, intentActivity, intentChallenge]
            case 'utter_ask_rapport_extended':
                if(mood === 'negative') return [intentIndicator, intentChallenge]
                if(mood === 'positive') return [intentIndicator, intentSentiment]
                else return [intentIndicator, intentActivity]
            case 'utter_ask_improvement':
                if(mood === 'negative') return [intentIndicator, intentChallenge, intentActivity]
                if(mood === 'positive') return [intentActivity, intentSentiment]
                else return [intentActivity]
            case 'utter_ask_achievement':
                if(mood === 'positive') return [intentIndicator, intentSentiment]
                else return [intentSentiment]
            case 'utter_ask_highlight':
                if(mood === 'positive') return [intentIndicator, intentSentiment]
                else return [intentSentiment]
            case 'utter_ask_habit_insight':
                if(mood === 'negative') return [intentIndicator, intentChallenge]
                else return [intentIndicator, intentActivity]
            case 'utter_ask_expectation':
                return [intentChallenge, intentIndicator, intentActivity]
            case 'utter_ask_challenges':
                return [intentChallenge]
            case 'utter_ask_priorities':
                return [intentIndicator, intentActivity]
            case 'utter_ask_preparation':
                return [intentIndicator, intentActivity]
            default:
                return [intentIndicator]
        }
        //console.log('swith utterres', resultIntentsList)
    }
    return resultIntentsList 
}

export const UtterancesLeadQuestions = [
    'utter_ask_assessment',
    'utter_ask_energy_level',
    'utter_ask_satisfaction_level',
    'utter_ask_progress',
    'utter_ask_awareness',
    'utter_ask_activity',
    'utter_ask_quiz',
    'utter_ask_goal_habit'
]

export const UtterancesResponseQuestions = [
    "utter_ask_rapport_extended", 
    "utter_ask_habit_insight",
    "utter_ask_highlight", 
    "utter_ask_achievement", 
    "utter_ask_improvement", 
    "utter_ask_expectation",
    "utter_ask_challenges",
    "utter_ask_preparation", 
    "utter_ask_priority",
    "utter_recommend_advice_ask_further",
    "utter_ask_mindset", 
    "utter_ask_goal_habit"
]

export const UtterancesDescriptions = {
    'utter_ask_mindset' : {
        type: "Lead Question",
        name: "Mindset",
        description: "A general question that is asking about a general daily status 'e.g. What's up?'"
    },
    'utter_ask_awareness': {
        type: "Lead Question",
        name: "Awareness/Thoughts",
        description: "DAILY reflective question on awareness and new insights related to module"
    },
    'utter_ask_activity': {
        type: "Lead Question",
        name: "Tasks/Activities",
        description: "DAILY activity question on upcoming or previous activities related to module"
    },
    'utter_ask_quiz': {
        type: "Lead Question",
        name: "Power Question",
        description: "Any ONCE-A-WHILE big question related to module"
    },
    'utter_ask_rapport_extended': {
        type: "Response Question",
        name: "Clarification",
        description: "Simple question that looks deeper into the user response related to the user topic"
    },
    'utter_ask_preparation': {
        type: "Response Question",
        name: "Preparation",
        description: "Next steps that help to get open tasks done related to the users topic"
    },
    'utter_ask_improvement': {
        type: "Response Question",
        name: "Improvements",
        description: "What improvement activity is needed from the users perespective related to the user topic"
    },
    'utter_ask_highlight': {
        type: "Response Question",
        name: "Highlights",
        description: "What was a related highlight related to the users topic"
    },
    'utter_ask_priorities': {
        type: "Response Question",
        name: "Priority",
        description: "Define a priority related to the users topic"
    }, 
    'utter_ask_achievement': {
        type: "Response Question",
        name: "Achievement",
        description: "What was achieved by the user related to the users topic"
    }, 
    'utter_ask_expectation': {
        type: "Response Question",
        name: "Expectation",
        description: "What is expected by the user related to the users topic"
    },
    'utter_ask_challenges': {
        type: "Response Question",
        name: "Challenges",
        description: "What challenges is the user facing related to the users topic"
    }, 
    'utter_ask_habit_insight': {
        type: "Response Question",
        name: "Background/Context",
        description: "Ask for more context information of the user related to the users topic"
    },
    'utter_ask_goal_habit': {
        type: "Lead Question",
        name: "Commitments",
        description: "At the end of the chat ask user for a commitment related to the module"
    },
    'utter_ask_progress' : {
        type: "Lead Question",
        name: "Progress Made",
        description: "Start a evening chat with asking about progress instead of How are you?..."
    },
    'utter_ask_energy_level' : {
        type: "Conversation starter",
        name: "Morning Mood",
        description: "Start a morning chat with asking about the current mood or How are you?../."
    },
    'utter_ask_assessment' : {
        type: "Conversation starter",
        name: "Rating/Assessment",
        description: "Start a chat with rating or assessment related to module 'e.g. How do you rate your current...?'"
    },
    'utter_ask_satisfaction_level' : {
        type: "Conversation starter",
        name: "Evening Satisfaction",
        description: "Start an evening chat with asking about the current satisfaciton with the day or How was your day?../."
    },
    "utter_welcome_to_program" : "INTRO - Welcome to Program", 
    "utter_ask_feedback": "QUESTION - Any Feedback - Ask for feedback at the end of the chat" ,
    "utter_inform_leadership_quality_phase": "INTRO - Move to Module - Introduction sentence to next phase in conversation",
    "utter_recommend_advice_ask_further": "Advice Nugget - Give a piece of advice and ask a related follow-up on the advice",
    "utter_inform_todays_leadership_quality_phase": "INTRO - Move to Extra Module - Introduction",
    "utter_inform_priority_phase": "INTRO - Move to action Module - Introduction",
    "utter_inform_mindset_phase": "INTRO - Start mindset check - Prepare for the first mindset question",
    "utter_affirm_greeting": "BRIDGE - Finished the mindset phase",
    "utter_confirm_content": "BRIDGE - Finished the awareness phase",
    "utter_confirm_assessment": "BRIDGE - Finished the extra assessment phase"
}

export const UtterancesMainQuestions = [
    'utter_ask_mindset',
    'utter_ask_assessment',
    'utter_ask_awareness',
    'utter_ask_activity',
    'utter_ask_quiz', 
    'utter_ask_rapport_extended', 
    'utter_ask_preparation', 
    'utter_ask_improvement', 
    'utter_ask_highlight', 
    'utter_ask_priorities',
    'utter_ask_achievement', 
    'utter_ask_expectation', 
    'utter_ask_challenges', 
    'utter_ask_habit_insight', 
    'utter_ask_goal_habit', 
    'utter_ask_progress'
]

export function LoadIntentClasses(that) {
    var intentIds = [];
    var intentIdsText = [];
    var intentIdsDict = {};
    var intentIdsDictText = {};
    const sentiment = 'inform_neutral_short';
    //console.log('LoadIntentClasses loading', sentiment)
    if(that._isMounted && sentiment!==undefined && sentiment!==""){
        that.setState({errorMessage : 'Loading intents list!'});
        //console.log('added: ', sentiment)
        if(that.state.intentList===undefined){
            //console.log('finding error 2')
            var intentList = {};
            var intentTree = {};
            that.props.firebase
            .agentIntentClasses()
            .where('active', '==', true)
            .where('sentiment', '==', sentiment)
            .where('tenses', 'array-contains', 'default')
            .orderBy('description', 'asc')
            .get().then(snapshot => {
                //console.log('finding error 3')
                snapshot.forEach(doc =>{
                    //console.log('finding error 4')
                    var dataRead = doc.data();
                    var groupId = sentiment;
                    var response = '';
                    if(dataRead.intent !== undefined && dataRead.intent !== "" && dataRead.description !== undefined && dataRead.description !== ""){
                        //intentList.push({...doc.data(), id: doc.id})
                        if(dataRead.intent?.includes("inform_indicator_") && !dataRead.intent?.includes("allothers")){
                            var exampleResponse = dataRead.intent;
                            if(dataRead.responses !== undefined && dataRead.responses.length>0){
                                exampleResponse = dataRead.responses[0];
                            }
                            //intentList.push({intent: dataRead.intent, description: dataRead.description, exampleResponse : exampleResponse, id: doc.id})
                            intentList[dataRead.intent] = ({
                                intent: dataRead.intent, 
                                description: dataRead.description, 
                                exampleResponse : exampleResponse, 
                                id: doc.id,
                                qualities: dataRead.qualities
                            })
                            if(dataRead.responses!== undefined && dataRead.responses.length > 0){
                                response = dataRead.responses[0]
                                if (response.length > 30){
                                    response = response.substring(0,30) + "..."
                                }
                            }
                            var intentBase = dataRead.intent.replace("inform_indicator_", "");
                            Object.keys(SentimentTopicIntentsPrefixes).map(itemSentiment => {
                                if(itemSentiment.includes("_short")){
                                    var newIntentSentiment = SentimentTopicIntentsPrefixes[itemSentiment]+intentBase;
                                    //console.log('intents iteems', newIntentSentiment);
                                    var text = newIntentSentiment + ' - ' + response
                                    groupId = itemSentiment;
                                    if(!Object.keys(intentIdsDict).includes(groupId)){
                                        intentIdsDict[groupId] = [];
                                        intentIdsDictText[groupId] = [];
                                    }
                                    intentIds.push(newIntentSentiment);
                                    intentIdsText.push(text);
                                    intentIdsDict[groupId].push(newIntentSentiment);
                                    intentIdsDictText[groupId].push(text);
                                }
                            })
                            var intentItems = intentBase.split("_");
                            for (let i = 0; i < intentItems.length; i++) {
                                if(i === 0 && intentTree[intentItems[0]] === undefined){
                                    intentTree[intentItems[0]] = {}
                                }
                                else if(i === 1 && intentTree[intentItems[0]][intentItems[1]] === undefined){
                                    intentTree[intentItems[0]][intentItems[1]] = {}
                                }
                                else if(i === 2 && intentTree[intentItems[0]][intentItems[1]][intentItems[2]] === undefined){
                                    intentTree[intentItems[0]][intentItems[1]][intentItems[2]] = {}
                                }
                                else if(i === 3 && intentTree[intentItems[0]][intentItems[1]][intentItems[2]][intentItems[3]] === undefined){
                                    intentTree[intentItems[0]][intentItems[1]][intentItems[2]][intentItems[3]] = {}
                                }
                            }
                        }
                    }
                })
                if(that._isMounted && Object.keys(intentList)?.length>0){
                    //console.log('finding error 5')
                    //console.log('added loadIntent: ', sentiment, intentList, intentTree, intentIds , intentIdsText, intentIdsDict, intentIdsDictText )
                    that.setState({intentList : intentList, intentTree, intentIds , intentIdsText, intentIdsDict, intentIdsDictText})
                }
            })
        }
    }
}
