import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
/****
import { elements, loadStripe } from '@stripe/react-stripe-js';
import { useElements, useStripe } from '@stripe/react-stripe-js';
const YOUR_STRIPE_PUBLISHABLE_KEY = 'whsec_j50jpjkkbWFAMCGU9ERM6vM3SfezULfI'; // Replace with your actual key
const stripePromise = loadStripe(YOUR_STRIPE_PUBLISHABLE_KEY);
 */
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import SubscriptionCard from '../Profile/SubscriptionCard';

import Button from '@material-ui/core/Button';
//import Card from '@material-ui/core/Card';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingAbout from './SettingAbout';

// style elements
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

//popup and dialogu features
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class SettingSubscriptionWebBlue extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            openPaymentDialog: false,
            plan: 'Content and Web App',
            error: '',
            productForPayment : null,
            status1: 'Loading...',
            status2: 'Loading...',
            loadedStore: false,
            approved: false,
            verified: false,
            owned: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        var buyer = 'enduser';
        var groupId = 'ROCKY';
        if(this.props.coachCode) {
            groupId = this.props.coachCode;
        }
        if((this.context.roles?.includes('COACHADMIN') )){
            buyer = 'coach';
            groupId = "#NEWCOACHCREATOR";
        }
        else if(this.props.coach || (this.context.roles?.includes('COACHADMIN') || this.context.roles?.includes('REMOTECOACH') )){
            buyer = 'coach';
            groupId = "#REMOTECOACH";
        }
        var production = true;
        if(this.props.sandbox) {
            production = false;
        }
        var products = {};
        var groupIdTitles = [];
        groupId = groupId.replace("#","");
        this.props.firebase.purchaseArticles()
        .where('active', '==', true)
        .where('store', '==', 'Stripe')
        .where('production', '==', production)
        .where('groupId', '==', groupId)
        .where('category', '==', buyer)
        .orderBy('position', 'asc')
        .get()
        .then(articles => {
            if(this._isMounted){
                articles.forEach(doc => {
                    var article = doc.data();
                    if(this.props.premiumOnly === true && !article.premium){
                        // do nothing
                    }
                    else{
                        var groupId = doc.id;
                        if (article.groupId !== undefined && article.groupId !== ''){
                            groupId = article.groupId;
                        }
                        if (groupIdTitles.includes(groupId)){
                            if(article.position === 1){
                                products[groupId]['items'].unshift(article);
                                //console.log('number 1', products)
                            }
                            else if(article.position > 1){
                                //console.log('number pos', article.position, products[groupId]['items'].length, products)
                                if(article.position <= products[groupId]['items'].length){
                                    products[groupId]['items'].splice((article.position - 1), 0, article);
                                }
                                else{
                                    products[groupId]['items'].push(article);
                                }
                            }
                            else{
                                products[groupId]['items'].push(article);
                            }
                        }
                        else{
                            groupIdTitles.push(groupId)
                            products[groupId] = article;
                            products[groupId]['items'] = [];
                            products[groupId]['items'].push(article);
                        }
                    }
                });
                if(this._isMounted ){
                    //console.log(products, groupIdTitles)
                    this.setState({
                        products,
                        groupIdTitles
                    });
                }
                //console.log(products, groupIdTitles)
            }
        })
        
        this.setState({
            groupId: groupId, 
            buyer
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { user } = this.props;
        const { theme, classes } = this.props;
        
        //console.log(process.env, 'version', process.env.npm_package_version)
        return (
            user
            ?
                <div className={classes.grow}>
                    {this.props.showHeading && 
                        <h1><ShoppingCartIcon/>&nbsp; My offers</h1>
                    }
                    {this.state.error !== undefined && this.state.error !== "" &&
                        <p style={theme.textSettings}>
                            <span>Info: <br/> </span>
                            <span style={theme.textErrorCenterPadding}>{this.state.error}</span>
                        </p>
                    }
                    {
                        this.showSubscriptionOffers()
                    }
                    {
                        this.showPaymentDialogue()
                    }

                    {this.props.showTerms &&
                        <Accordion style={{marginTop:30}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="About-Expand"
                                aria-controls="About-content"
                                id="About-header"
                                >
                                <FormControlLabel
                                    aria-label="About"
                                    control={<InfoIcon style={theme.cardSettings.iconDesign} />}
                                    label="About, Terms & Privacy"
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{textAlign: "left"}}>
                                    <SettingAbout user={user} defaultSet={this.props.defaultSet}/>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    }
                </div>
            :
                "User loading"
        );
    }

    showSubscriptionOffers(){
        const {theme} = this.props;
        //console.log("p", this.state.products, "ids", this.state.groupIdTitles)
        if(this.state.products!== undefined && this.state.groupIdTitles!==undefined){
            return(
                <div style={{overflowX: 'hidden', width: '100%', textAlign: 'center'}}>
                    <Grid container direction={"row"} justify="center" style={{width: "100%"}}>
                        {
                            this.state.groupIdTitles.length>0
                            ?
                                this.state.groupIdTitles.map((productGroup, index) => {
                                    //console.log(this.state.products[productGroup])
                                    return(
                                        <Grid item key={'item_'+index}>
                                            <SubscriptionCard 
                                                user={this.context}
                                                item={this.state.products[productGroup]}
                                                cardClickAction={ (selectedProduct) => {this.openPaymentDialogue(selectedProduct, false)}}
                                            />
                                        </Grid>
                                    )
                                })
                            :   this.props.fallbackURL?.includes('http')
                                ?
                                    <Grid item style={{width: '100%'}}>
                                        <p style={theme.textSupport}>Get access to our services:</p>
                                        <br/><br/>
                                        <Button onClick={() => {window.open(this.props.fallbackURL.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email), "_blank")}}>
                                            Open Store
                                        </Button>
                                    </Grid>
                                :
                                    <Grid item>No offers available</Grid>
                        }
                    </Grid>
                </div>
            )
        }
        else{
            return("")
        }
    }

    openPaymentDialogue = (product, openIframe=false) => {
        this.setState({productForPayment: product});
        var stripeURL = 'https://buy.stripe.com/'+product.id+'';
        if(this.props.sandbox) {
            stripeURL = 'https://buy.stripe.com/'+product.id+'';
        }
        /////// FIRST HAS ?
        stripeURL = stripeURL + '?prefilled_promo_code=' + product.storeId;
        //stripeURL = stripeURL + 'testMode=N&paymentcc=Y';utm_content
        stripeURL = stripeURL + '&utm_source=' + (IsMobileDevice() ? (window?.device?.platform === 'Android'? "Android" : window?.device?.platform === 'iOS' ? "iOS" : "mobile") : "web");
        stripeURL = stripeURL + '&utm_content=' + product.groupId?.replace("#","");
        stripeURL = stripeURL + '&description=' + "Best APP ever";
        stripeURL = stripeURL + '&prefilled_email=' + this.props.user.email;
        stripeURL = stripeURL + '&client_reference_id=' + this.context.uid;
        //stripeURL = stripeURL + '&metadata={"ab":"' + this.context.uid + '"}';
        
        //stripeURL = stripeURL + '&sealmcafeevisible=Y';
        //stripeURL = stripeURL + '&sealverisignvisible=Y';
        if(this.props.sandbox) {
            console.log(stripeURL);
        }
        if(openIframe){
            this.setState({openPaymentDialog: true, productForPayment:product, stripeURL });
        }
        else{
            this.setState({error: "The checkout will be opened in a new browser window and use our payment partner Stripe!"})
            stripeURL = stripeURL + '&browsertitle=Checkout+AI+Coach';
            var win = window.open(stripeURL, '_blank');
            win.focus();
        }
    }

    createThankyouUrl () {
        var thankyouUrl = 'https%3A%2F%2Fapp.rocky.ai%2F%23%2Fpayment%3FinvoiceId%3D%21%7Binvoice.id%7D';
        if(this.props.sandbox) {
            thankyouUrl = 'http%3A%2F%2Flocalhost%3A3000%2F%23%2Fpayment%3FinvoiceId%3D%21%7Binvoice.id%7D';
        }
        //var thankyouUrl = 'https://app.rocky.ai/#/payment?invoiceId=!{invoice.id}';
        //var thankyouUrl = 'https%3A%2F%2Fapp.rocky.ai%2F%23%2Fpayment%3FinvoiceId%3D%21%7Binvoice.id%7D';
        //var thankyouUrl = 'http://localhost:3000/#/payment?invoiceId=!{invoice.id}&email=!{shopper.email}&firstName=!{shopper.firstName}&lastname=!{shopper.lastName}&invoiceStatus=!{invoice.status}&price=!{invoice.total}&priceLocalCur=!{invoice.currency}';
        //var thankyouUrl = 'http%3A%2F%2Flocalhost%3A3000%2F%23%2Fpayment%3FinvoiceId%3D%21%7Binvoice.id%7D';
        thankyouUrl = thankyouUrl + '%26email%3D%21%7Bshopper.email%7D';
        thankyouUrl = thankyouUrl + '%26firstName%3D%21%7Bshopper.firstName%7D';
        thankyouUrl = thankyouUrl + '%26lastName%3D%21%7Bshopper.lastName%7D';
        thankyouUrl = thankyouUrl + '%26shopperId%3D%21%7Bshopper.id%7D';
        thankyouUrl = thankyouUrl + '%26invoiceStatus%3D%21%7Binvoice.status%7D';
        thankyouUrl = thankyouUrl + '%26userId%3D%21%7Bmerchant.transaction.id%7D';
        thankyouUrl = thankyouUrl + '%26price%3D%21%7Binvoice.total%7D';
        thankyouUrl = thankyouUrl + '%26priceLocalCur%3D%21%7Binvoice.currency%7D';
        return thankyouUrl
    }

    closePaymentDialogue = () => {
        this.setState({openPaymentDialog: false, productForPayment:null, stripeURL:''})
    }

    showPaymentDialogue = () => {
        const {theme} = this.props;
        return(
            <RemoveScroll enabled={this.state.openPaymentDialog || false}>
                <Dialog
                    open={this.state.openPaymentDialog || false}
                    onClose={this.closePaymentDialogue}
                    //fullScreen={true}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                            <IconButton onClick={this.closePaymentDialogue}>
                                <ArrowBackIcon />
                            </IconButton>
                            <span style={theme.dialogTitle.titleText}>
                                {this.props.sandbox? "Sandbox " : "" } AI Coaching Shop
                            </span>
                    </DialogTitle>
                    <DialogContent 
                        style={{
                            margin:0, 
                            padding:0 , 
                            overflow: 'hidden'
                        }}
                    >
                        {this.state.stripeURL?.includes('http')
                        &&
                            <iframe 
                                style={{
                                    borderWidth: 0,
                                    border: 'none'
                                }}
                                allowpaymentrequest= 'true'
                                width= '100%'
                                height= '100%'
                                loading= 'eager'
                                sandbox= "allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                                referrerPolicy= "origin-when-cross-origin"
                                src={this.state.stripeURL}
                            >
                            </iframe>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closePaymentDialogue}>
                            Close Shop
                        </Button>
                    </DialogActions>
                </Dialog>
            </RemoveScroll> 
        )
    }

}

SettingSubscriptionWebBlue.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingSubscriptionWebBlue);



        /**
        let productsEnduser = [
            {
                store: 'Bluesnap',
                id: '2611841',
                title: 'Daily Coaching Questions',
                state: 'loading',
                description: 'Unlimited access to the reflection journaling',
                price: 'USD 3.99',
                owned: false,
                canPurchase: true,
                type: 'MONTHLY',
                billing: 'monthly', 
                button: 'Subscribe monthly'
            },
            {
                store: 'Bluesnap',
                id: '2611863',
                title: 'Daily Coaching Questions',
                state: 'loading',
                description: 'Unlimited access to the reflection journaling',
                price: 'USD 31.99',
                promotion: 'Save 30%',
                owned: false,
                canPurchase: true,
                type: 'YEARLY',
                billing: 'annualy',
                button: 'Subscribe annually'
            }
        ]
        if(this.props.sandbox) {
            productsEnduser = [
                {
                    store: 'Bluesnap',
                    id: '2611841',
                    title: 'Daily Coaching Questions',
                    state: 'loading',
                    description: 'Unlimited access to the reflection journaling',
                    price: 'USD 3.99',
                    owned: false,
                    canPurchase: true,
                    type: 'MONTHLY',
                    billing: 'monthly',
                    button: 'Subscribe monthly'
                },
                {
                    store: 'Bluesnap',
                    id: '2611863',
                    title: 'Daily Coaching Questions',
                    state: 'loading',
                    description: 'Unlimited access to the reflection journaling',
                    price: 'USD 31.99',
                    promotion: 'Save 30%',
                    owned: false,
                    canPurchase: true,
                    type: 'YEARLY',
                    billing: 'annualy',
                    button: 'Subscribe annually'
                }
            ]
        }
        let productsCoach = [
            {
                store: 'Bluesnap',
                id: '3874270',
                title: 'Monthly Coachee Seats',
                state: 'loading',
                description: 'Premium account for Rocky.ai with remote coach access',
                price: 'USD 10.00',
                owned: false,
                canPurchase: true,
                type: 'SEATS',
                billing: "once",
                button: 'Buy Seats'
            }
        ]
        if(this.props.sandbox) {
            productsCoach = [
                {
                    store: 'Bluesnap',
                    id: '2621585',
                    title: 'Monthly Coachee Seats',
                    state: 'loading',
                    description: 'Premium account for Rocky.ai with remote coach access',
                    price: 'USD 10.00',
                    owned: false,
                    canPurchase: true,
                    type: 'SEATS',
                    billing: "once",
                    button: 'Buy Seats'
                }
            ]
        }
         */
