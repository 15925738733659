import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color'

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingColorPicker extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: '',
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
            return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, newValue) => {
        //console.log('h8ex', newValue)
        if(newValue?.hex?.includes("#")){
            var value = newValue.hex;
            value = value.toUpperCase();
            this.setState({
                [param]: value,
                errorMessage: ''
            });
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[p] !== null && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                if(this.props.emptyOption || this.state[p] !== ""){
                    var newValue = this.state[p].trim();
                    var cancel = false;
                    var isHexColor  = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(newValue);
                    if(!isHexColor){
                        this.setState({
                            errorMessage: "Invalid color input"
                        });
                        cancel = true;
                    }
                    if(!cancel){
                        var updateSet = { [p]: newValue };
                        if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                            Object.assign(updateSet, this.props.updateExtraChange)
                        }
                        if(this.props.updateCopyTo !== undefined && typeof this.props.updateCopyTo === 'string'){
                            Object.assign(updateSet, {[this.props.updateCopyTo]: this.state[p]})
                        }
                        if(this.props.dbUpdateLink !== undefined){
                            this.props.dbUpdateLink.update(updateSet);
                        }
                        if(this.props.updateState !== undefined){
                            this.props.updateState(updateSet);
                        }
                    }
                }
            }
            return '';
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title , labels} = this.props;
        const { theme, classes } = this.props;

        return (
        content
            ?
            <div className={classes.grow}>
                <Card style={{...theme.cardAdminSettings, minWidth: 120}}>
                    <Grid container>
                        <Grid item xs={9} ><p style={{color:"darkgreen"}}>{title}</p>  </Grid>
                        <Grid item xs={1} >  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)
                            &&
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            {params.map((p, index) => (
                                <p style={{...theme.textSettings, position: 'relative'}} key={"current_"+p}>
                                    {(this.props.showShort!==false && this.props.noLabel!== true)
                                        && <span style={{maxWidth: '20%'}}>{labels!==undefined ? labels[index] : p}</span>
                                    }
                                    <span style={{position: 'auto', display: 'inline-flex', paddingLeft: "25%", paddingTop: 0, fontWeight: 700, right: 0}}>
                                        {content[p]?.includes("#") 
                                        ?
                                            <span style={{width: 40, height: 14, backgroundColor: content[p], rounded: 12, display: 'block', marginRight: 20}}>
                                                &nbsp;
                                            </span>
                                        :   this.props.defaultValue?.includes("#") 
                                            ?    <span style={{width: 40, height: 14, backgroundColor: this.props.defaultValue, rounded: 12, display: 'block', marginRight: 20}}>
                                                    &nbsp;
                                                </span>
                                            :   ''
                                        }
                                        <strong>{content[p] || this.props.defaultValue || '[n/a]'}</strong>
                                    </span>
                                </p>
                            )) }
                        </Grid>
                    </Grid>
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                >
                    <DialogTitle>Change Color</DialogTitle>
                    <form>
                        <DialogContent>
                            {
                                params.map((p, index) => (
                                    <div style={{width: `100%`}}  key={"change_"+p}> 
                                        {this.props.defaultValue?.includes("#")
                                            &&
                                            <div style={{paddingBottom: 20, textAlign: 'right', display: 'inline-flex'}}
                                                onClick={() => this.handleSettingChange(p, {hex: this.props.defaultValue})}
                                            >
                                                <span style={{width: 40, height: 14, backgroundColor: this.props.defaultValue, rounded: 12, display: 'block', marginRight: 20}}>
                                                    &nbsp;
                                                </span>
                                                {this.props.defaultValue + " (Default)"} 
                                            </div>
                                        }
                                        {content?.[p]?.includes("#")
                                        &&
                                            <p
                                                onClick={() => this.handleSettingChange(p, {hex: content[p]})}
                                            >   
                                                {
                                                    ///// Ensure to have extra second line in the place
                                                }
                                                <div style={{paddingBottom: 20, textAlign: 'right', display: 'inline-flex'}}>
                                                    <span style={{width: 40, height: 14, backgroundColor: content[p], rounded: 12, display: 'block', marginRight: 20}}>
                                                        &nbsp;
                                                    </span>
                                                    {content[p] + " (Initial)"} 
                                                </div>
                                            </p>
                                        }
                                        <SketchPicker 
                                            key={"change_"+p}
                                            id={"change_"+p}
                                            color={this.state[p] || this.props.defaultValue || theme.palette.themeAlertGreen}
                                            disableAlpha={true}
                                            onChangeComplete={ (hex) => {
                                                this.handleSettingChange(p, hex)
                                            }}
                                            presetColors={[
                                                theme.palette.themeAlertGreen,
                                                theme.palette.themeAlertRed,
                                                theme.palette.themeAlertOrange,
                                                theme.palette.themeAlertBlue,
                                            ]}
                                        />
                                        <p style={{paddingTop: 10, textAlign: 'right', display: 'inline-flex'}}>
                                            {this.state[p]?.includes("#") 
                                            &&
                                                <span style={{width: 40, height: 14, backgroundColor: this.state[p], rounded: 12, display: 'block', marginRight: 20}}>
                                                    &nbsp;
                                                </span>
                                            }
                                            {this.state[p] || this.props.defaultValue }
                                        </p>
                                        {false &&
                                            <TextField
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                                multiline={this.props.multiline || false}
                                                key={"change_"+p}
                                                id={"change_"+p}
                                                label={((labels!==undefined ? labels[index] : p) + (this.props.max > 0 ? (" (" + this.state[p]?.length+ "/"+ this.props.max+ " character)") : ""))}
                                                value={this.state[p]}
                                                onChange={(e) => {
                                                    this.handleSettingChange(p, e?.target?.value)
                                                }}
                                            />
                                        }
                                    </div>
                                ))
                            }
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                                ?
                                    <p style={theme.textErrorCenterPadding}>
                                        {this.state.errorMessage}
                                    </p>
                                :   <p> </p>
                            }
                        </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        {this.state.errorMessage=== undefined || this.state.errorMessage===''
                        ?
                            <Button onClick={this.handleSettingSubmit}>
                                Save
                            </Button>
                        :
                            <Button disabled>
                                Save
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingColorPicker.propTypes = {
    classes: PropTypes.object.isRequired
};


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingColorPicker);
