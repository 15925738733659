import React, { Component } from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import {CheckXPLevel} from '../Charts/XPLevels';

//import Grid from '@material-ui/core/Grid';

import logo from "../../images/rocky-logo.png";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Badge from '@material-ui/core/Badge';

import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';

import IconButton from '@material-ui/core/IconButton';
//import Avatar from '@material-ui/core/Avatar';
//import MoreIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
//import BookmarksIcon from '@material-ui/icons/Bookmarks';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
//import SchoolIcon from '@material-ui/icons/School';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
//import EqualizerIcon from '@material-ui/icons/Equalizer';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
//import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FeedbackIcon from '@material-ui/icons/Feedback';
//import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
//import GroupIcon from '@material-ui/icons/Group';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
//import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
//import FeedbackIcon from '@material-ui/icons/Feedback';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import Fab from '@material-ui/core/Fab';



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    fabButtonCenter: {
        position: 'relative',
        zIndex: 1,
        top: -8,
        left: 0,
        right: 0,
        lineHeight: 0.4,
        textTransform: 'none',
        margin: '0 auto',
        backgroundColor: theme.palette.themeNaviGrey
    },
});



class Navigation extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl:null ,
            isBrowser : true
        };
    }

    componentDidMount() { 
        document.addEventListener("deviceready", () => this.handleBrowserState(), false);
        if(this.props.user!== undefined){
            const xpLevels = {
                modules: CheckXPLevel(this.props.user, 'modules'),
                followUp: CheckXPLevel(this.props.user, 'followUp'),
                content: CheckXPLevel(this.props.user, 'content'),
                journal: CheckXPLevel(this.props.user, 'journal'),
                social: CheckXPLevel(this.props.user, 'social'),
            }
            //console.log('xpLevels', xpLevels)
            this.setState({xpLevels: xpLevels});
        }
    }
    handleBrowserState = () => {
        this.setState({ isBrowser : false});
        window.removeEventListener("deviceready", () => this.handleBrowserState());
    }

    // to get the menue element running in class objects
    // https://stackoverflow.com/questions/56442030/how-can-i-convert-popover-material-ui-functional-component-to-class-based-compon
    handleOpen = (event) => {
        //console.log('state open')
        this.state.ancherEl
        ? this.setState({ anchorEl: null })
        : this.setState({ open: true, anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ open: false, anchorEl:null });
    }

    MobileAppBarItem = (name, icon, iconAlternative=undefined, link, inactive=false) => {
        const {classes} = this.props;
        const {pathname} = this.props.location;
        //console.log(pathname, this.props)
        if(name === "Rocky" || name === "Coach"){
            return(
                <Link to={link} style={{ textDecoration: 'none' }}>
                    <Fab className={classes.fabButtonCenter} size={"large"}>
                        <div>
                            <div style={{
                                    ...this.props.theme.AppBarIcons,
                                    color: this.props.theme.palette.themeDarkBlue 
                                }}
                            >
                                {icon}
                            </div>
                            <div
                                style={{
                                    ...this.props.theme.AppBarText, 
                                    color: this.props.theme.palette.themeDarkBlue
                                }}
                            >
                                <br/>{this.props.defaultSet?.programAvatarName?.length > 2 ? this.props.defaultSet.programAvatarName?.length > 6 ? "Coach" : this.props.defaultSet.programAvatarName : name}
                            </div>
                        </div>
                    </Fab>
                </Link>
            )
        }
        else if(link === pathname || (link==='/home' && pathname==='/')){
            return(
                <div 
                    style={{ textDecoration: 'none', paddingLeft:6, paddingRight:6 }}
                    onClick={() => {
                        try{
                            var mydoc = document.getElementById('pageroot')
                            //console.log('1xxxwww', mydoc)
                            if (mydoc) {
                                mydoc.scrollIntoView({ behavior: 'smooth' });
                            }
                        } catch(e) {}
                    }}
                >
                    <div style={{textAlign: 'center'}}>
                        <div aria-label={name}
                            aria-controls={"mobileMenuId"}
                            aria-haspopup="true"
                            style={{
                                ...this.props.theme.AppBarIcons, 
                                //backgroundColor:this.props.theme.palette.themeGold
                                color: this.props.theme.palette.secondary.light,
                                lineHeight: 0,
                                pointerEvents: (false) 
                            }}
                            >
                            {icon}
                        </div>
                    </div>
                    <div 
                        style={{
                            ...this.props.theme.AppBarText, 
                            color: this.props.theme.palette.secondary.light
                        }}
                        // style={this.props.theme.AppBarText}
                        >
                        {name}
                    </div>
                </div>
            )
        }
        else{
            return(
                <Link to={link} 
                    style={{ textDecoration: 'none', paddingLeft:6, paddingRight:6 }}
                >
                    <div style={{textAlign: 'center'}}>
                        <div aria-label={name}
                            aria-controls={"mobileMenuId"}
                            aria-haspopup="true"
                            style={{
                                ...this.props.theme.AppBarIcons, 
                                //backgroundColor:this.props.theme.palette.themeGold
                                color: inactive? this.props.theme.palette.themeNaviGrey : this.props.theme.palette.themeNaviGrey,
                                lineHeight: 0,
                                pointerEvents: (false) 
                            }}
                            >
                            {iconAlternative? iconAlternative : icon}
                        </div>
                    </div>
                    <div 
                        style={{
                            ...this.props.theme.AppBarText, 
                            color: inactive? this.props.theme.palette.themeNaviGrey : this.props.theme.palette.themeNaviGrey
                        }}
                    >
                        {name}
                    </div>
                </Link>
            )
        }
    }
    MobileAppMenuButton = (name, icon) => {
        const {pathname} = this.props.location;
        //console.log(pathname, icon.props.style)
        if(pathname==='/stats' || pathname==='/profile' || pathname==='/messages' || pathname==='/support'){ // pathname==='/days'
            return(
                <div 
                    style={{ textDecoration: 'none', paddingLeft:6, paddingRight:6 }}
                    onClick={event => this.handleOpen(event)}
                >
                    <div>
                        <div aria-label={name}
                            aria-controls={"mobileMenuIdGold"}
                            aria-haspopup="true"
                            style={{
                                ...this.props.theme.AppBarIcons,
                                color: this.props.theme.palette.white,
                                lineHeight: 0,
                                pointerEvents: (false) 
                            }}
                        >
                            {icon}
                        </div>
                    </div>
                    <div
                        style={{
                            ...this.props.theme.AppBarText, 
                            color: this.props.theme.palette.white
                        }}
                    >
                        {name}
                    </div>
                    {/***
                    <Avatar 
                        style={{...this.props.theme.AppBarIcons, backgroundColor:this.props.theme.palette.themeRed}}
                        >
                        {icon}
                    </Avatar>
                     */}
                </div>
            )
        }
        else{
            return(
                <div 
                    style={{ textDecoration: 'none', paddingLeft:6, paddingRight:6 }}
                    onClick={event => this.handleOpen(event)}
                >
                    <div>
                        <div aria-label={name}
                            aria-controls={"mobileMenuId"}
                            aria-haspopup="true"
                            style={{
                                ...this.props.theme.AppBarIcons,
                                color: this.props.theme.palette.themeNaviGrey,
                                lineHeight: 0,
                                pointerEvents: (false) 
                            }}
                        >
                            <AccountCircleOutlinedIcon />
                        </div>
                    </div>
                    <div style={{
                        ...this.props.theme.AppBarText,
                        color: this.props.theme.palette.themeNaviGrey}}
                    >
                        Me
                    </div>
                </div>
            )
        }
    }
    MobileAppMenuItem = (name, icon, link) => {
        const {pathname} = this.props.location;
        const {theme} = this.props;
        //console.log('current path', pathname, link);
        if( link === pathname){
            return(
                <MenuItem style={{backgroundColor: theme.palette.secondary.light}} onClick={() => {
                        try{
                            var mydoc = document.getElementById('pageroot')
                            //console.log('1xxxwww', mydoc)
                            if (mydoc) {
                                mydoc.scrollIntoView({ behavior: 'smooth' });
                            }
                        } catch(e) {console.log('err', e)}
                        this.handleClose();
                    }}>
                    <IconButton color={"inherit"} style={this.props.theme.AppBarIcons}
                        size="small" >
                        {icon}
                    </IconButton>
                    &nbsp;<span>{name}</span>
                </MenuItem>
            )
        }
        else{
            return(
                <MenuItem  onClick={() => {
                        try{
                            var mydoc = document.getElementById('pageroot')
                            //console.log('2xxxwww', mydoc)
                            if (mydoc) {
                                mydoc.scrollIntoView({ behavior: 'smooth' });
                            }
                        } catch(e) {console.log('err', e)}
                        this.handleClose(); 
                        this.props.history.push(link);
                    }}>
                    <IconButton style={{...this.props.theme.AppBarIcons, color: this.props.theme.palette.secondary.main}}
                        size="small" >
                        {icon}
                    </IconButton>
                    &nbsp;<span style={{color: this.props.theme.palette.secondary.main}}>{name}</span>
                </MenuItem>
            )
        }
    }

    render() {
        const { classes, theme } = this.props;
        //const { firebase } = this.props;
        //console.log('props', this.props);
        return (
            <AuthUserContext.Consumer>
            {authUser => authUser 
            ? (
                <div >
                    <AppBar style={theme.AppBarMobile}>
                        <Toolbar>
                            {this.MobileAppBarItem("Home", <HomeIcon style={theme.DrawerItemAlignment}  />, <HomeOutlinedIcon style={theme.DrawerItemAlignment} />, ROUTES.HOME)}
                            <div className={classes.grow} />
                            {this.state.xpLevels?.content?.valid === false
                                ?   //<span style={{width:40}}>&nbsp;</span>
                                    this.MobileAppBarItem("Quests", <WidgetsIcon style={theme.DrawerItemAlignment} />, <WidgetsOutlinedIcon style={theme.DrawerItemAlignment} />, ROUTES.CONTENT)
                                :   this.MobileAppBarItem("Quests", <WidgetsIcon style={theme.DrawerItemAlignment} />, <WidgetsOutlinedIcon style={theme.DrawerItemAlignment} />, ROUTES.CONTENT)
                            }
                            <div className={classes.grow} />
                            {this.props.defaultSet?.programAvatarName?.length > 2
                                ?   this.MobileAppBarItem("Coach", <ForumIcon style={theme.DrawerItem} />, undefined, ROUTES.ROUTINE) 
                                :   this.MobileAppBarItem("Coach", <ForumIcon style={theme.DrawerItem} />, undefined, ROUTES.ROUTINE) 
                            }
                            <div className={classes.grow} />
                            {this.state.xpLevels?.followUp?.valid === false
                                ?   //<span style={{width:40}}>&nbsp;</span>
                                    this.MobileAppBarItem("Journey", <AssignmentTurnedInIcon style={theme.DrawerItemAlignment} />, <AssignmentTurnedInOutlinedIcon style={theme.DrawerItemAlignment} />,  ROUTES.JOURNAL)
                                :   this.MobileAppBarItem("Journey", <AssignmentTurnedInIcon style={theme.DrawerItemAlignment} />, <AssignmentTurnedInOutlinedIcon style={theme.DrawerItemAlignment} />,  ROUTES.JOURNAL)
                            }
                            <div className={classes.grow} />
                            {/** 
                            {this.MobileAppBarItem("Discuss", <PeopleAltIcon style={theme.DrawerItem} />, undefined, ROUTES.MESSAGES)}
                            */}
                            {this.MobileAppMenuButton("More", 
                                <Badge badgeContent={authUser.newMessages!== undefined && authUser.newMessages.length>0 ? authUser.newMessages.length : 0} 
                                    color="error"
                                    >
                                    <MoreHorizIcon style={theme.DrawerItemAlignment} />
                                </Badge>
                                )
                            }
                        </Toolbar>
                    </AppBar>
                <DialogTutorialPage 
                    handleOpen={this.state.handleTutorialOpen || false}
                    handleBadgeFamily = {'total'}
                    handleClose={() => {this.setState({handleTutorialOpen: false})}}
                />
                <Menu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    getContentAnchorEl={null}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={() => {this.setState({handleTutorialOpen: true, open: false})}}>
                        <IconButton color={"inherit"} style={{...this.props.theme.AppBarIcons, color: this.props.theme.palette.secondary.main}} 
                            size="small" >
                            <HelpOutlineIcon />
                        </IconButton>
                        &nbsp;<span style={{color: this.props.theme.palette.secondary.main}}>Tutorial</span>  
                    </MenuItem>
                    { this.MobileAppMenuItem("Settings", <AccountCircle />, ROUTES.PROFILE) }
                    {/* this.MobileAppMenuItem("Rocky", <PlaylistAddIcon />, ROUTES.ROUTINE) */ }
                    {(this.state.xpLevels?.modules?.valid === false || authUser?.dialogueCounter <= 4)
                        ?   '' 
                        :   this.MobileAppMenuItem("Insights", <DonutSmallIcon/>, ROUTES.STATS)
                    }
                    {/* this.MobileAppMenuItem("Goals", <AssignmentTurnedInIcon/>, ROUTES.JOURNAL)*/}
                    { this.MobileAppMenuItem("Support", 
                        <Badge badgeContent={authUser.newMessages!== undefined && authUser.newMessages.filter(q => q.type === "SUPPORT").length ? authUser.newMessages.filter(q => q.type === "SUPPORT").length : 0} 
                            color="error">
                            <HeadsetMicIcon/>
                        </Badge>
                        , ROUTES.SUPPORT) }
                    {this.state.xpLevels?.social?.valid === false
                        ?   ""
                        : 
                            this.MobileAppMenuItem("Discuss", 
                            <Badge badgeContent={authUser.newMessages!== undefined && authUser.newMessages.filter(q => q.type === "MESSAGES").length ? authUser.newMessages.filter(q => q.type === "MESSAGES").length : 0} 
                                color="error">
                                <PeopleAltIcon/>
                            </Badge>
                            , ROUTES.MESSAGES) 
                    }
                    <div className={classes.grow} />
                    {authUser.roles?.includes(ROLES.ADMIN)  && this.state.isBrowser
                        ? this.MobileAppMenuItem("Contents", <ImportContactsIcon  />, ROUTES.ADMIN_CONTENTS)
                        : ""
                    }
                    {authUser.roles?.includes(ROLES.ADMIN)  && this.state.isBrowser
                        ? this.MobileAppMenuItem("User Chats", <LibraryBooksIcon  />, ROUTES.ADMIN_DIALOGUES)
                        : ""
                    }{authUser.roles?.includes(ROLES.ADMIN) && this.state.isBrowser
                        ? this.MobileAppMenuItem("User Feedback", <FeedbackIcon />, ROUTES.ADMIN_FEEDBACKS)
                        : ""
                    }{authUser.roles?.includes(ROLES.ADMIN) && this.state.isBrowser
                        ? this.MobileAppMenuItem("User Tasks", <DoneAllIcon />, ROUTES.ADMIN_TASKS)
                        : ""
                    }
                    {false && (authUser.roles?.includes(ROLES.COACHADMIN) || authUser.roles?.includes(ROLES.REMOTECOACH)) && this.state.isBrowser
                        ? this.MobileAppMenuItem("Teams", <RecordVoiceOverIcon />, ROUTES.COACH) 
                        : ""
                    }
                </Menu>
                </div>
            ) 
            :   (
                <div>
                <AppBar style={theme.AppBarMobile}>
                    <Toolbar >
                        <div style={styles.logoBackground}>
                            <Link to={ROUTES.HOME}>
                                <img src={logo} style={styles.logo} alt="logo in menu" />
                            </Link>
                        </div>
                        <Button color="inherit" component={Link} to={ROUTES.SIGN_IN}>
                            Sign In
                        </Button>
                    </Toolbar>
                </AppBar>
                </div>
            )
            }
            </AuthUserContext.Consumer>
        
        );
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles, { withTheme: true}),
    withRouter
)(Navigation);
