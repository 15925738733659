import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import AvatarImage from '../Modules/AvatarImage';
import { Link } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';

//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

//import { createGradientImage } from '../Modules/createGradientImage';
import {Textfit} from 'react-textfit';
import LeadershipQualityPicker from '../Goals/LeadershipQualityPicker';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
//import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';

import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class DialogAssessQuality extends Component {  
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var questionCategories = [];
        var userPrograms = ['default'];
        if(this.props.user !== undefined && this.props.user.programs !== undefined && this.props.user.programs.length > 0){
            userPrograms = this.props.user.programs;
        }
        var hidePublicContent = false;
        if(this.props.user !== undefined && this.props.user.hidePublicContent === true){
            hidePublicContent = true;
        }
        var userProfile = 'default';
        ['Teenager', 'Student', 'Available', 'Professional', 'Executive'].map( (item) => {
            if(this.props.user?.onboardingResponses?.includes(item)){
                userProfile = item;
            }
            return true
        })
        //console.log('user',this.props.user)
        this.props.leadershipCategories.forEach(item => {
            if('personalityTraitsTarget' in item && 'active' in item && item.active === true  ){
                if(item.answerQuestionIssueShortTerm !== undefined && item.answerQuestionIssueLongTerm!== undefined && item.answerQuestionInterest !== undefined){
                    //if((userPrograms.includes(item.program) || (userPremiumFlag === true && item.public === true))){
                    if(((item.program === 'default' && hidePublicContent !== true) || 
                        (item.program !== 'default' && userPrograms?.includes(item.program)) || 
                        (item.public === true && hidePublicContent !== true)
                        )){
                        //console.log('item X program', item.program)
                        ///// THIS one is to filter recommendations of articles that are not related to users
                        if((item.program !== 'default' && userPrograms?.includes(item.program)) || item.requirements === undefined || item.requirements.includes('default') || item.requirements.includes(userProfile)){
                            questionCategories.push(item);
                            //console.log('include', item.program)
                        }
                        else{
                            //console.log('exclude', item.program)
                        }
                    }
                    else{
                        //questionCategories.push(item);
                    }
                }
            }
        })
        //console.log('questionCategories', userPrograms, questionCategories)
        var assessmentResults = {};
        var questionsAnswered = 0;
        if(this.props.assessmentResults !== undefined && this.props.assessmentResults.userAssessment !== undefined){
            assessmentResults = this.props.assessmentResults.userAssessment ;
            questionsAnswered = 1;
        }
        else if(this.props.user?.userAssessment !== undefined){
            assessmentResults = this.props.user.userAssessment ;
            questionsAnswered = 1;
        } 
        this.state = {
            questionCategories,
            questionCategoriesReduce: questionCategories,
            currentCategory: this.props.user.currentCategory,
            currentLeadershipQuality: this.props.user.currentLeadershipQuality,
            currentPersonalityTrait: assessmentResults,
            nextQuestion: 'interest',
            questionType: 'interest',
            categories : [],
            loading: true,
            questionsAnswered: questionsAnswered,
            question: 'What would you like to improve?'
        };
    }

    componentDidMount() {   
        this._isMounted = true;
        var assessmentResults;
        if(this.props.assessmentResults !== undefined){
            assessmentResults = this.props.assessmentResults ;
            /**
            this.setState({
                currentPersonalityTrait: assessmentResults,
                questionsAnswered : 1,
            } )
             */
            this.updateQuestionPair(assessmentResults, false, 'average')
            //console.log('started loading following results', assessmentResults);
            //
        }
        else if(this.props.user?.userAssessment !== undefined){
            assessmentResults = this.props.user.userAssessment ;
            this.updateQuestionPair(assessmentResults, false, 'average')
            //console.log('started with user sdetails', assessmentResults);

        } 
        /**
        else{
            this.updateQuestionPair(this.state.currentPersonalityTrait, true)
        } */
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    calcNewPoints(oldPoints, newPoints, questionsAnswered , type="average"){
        //var oldPoints = this.state.currentPersonalityTrait;
        //console.log('recaluclate', questionsAnswered, oldPoints, newPoints)
        var userOCEANPoints = []; // = oldPoints;
        if(type==="average" && oldPoints?.length>0){
            oldPoints.map((item) => {
                userOCEANPoints[item] = (oldPoints[item] + newPoints[item])/2
                //userOCEANPoints["C"] = (oldPoints["C"] + newPoints["C"])/2
                //userOCEANPoints["E"] = (oldPoints["E"] + newPoints["E"])/2
                //userOCEANPoints["A"] = (oldPoints["A"] + newPoints["A"])/2
                //userOCEANPoints["N"] = (oldPoints["N"] + newPoints["N"])/2
                return true
            })
            //console.log("average", userOCEANPoints)
        }
        else if(type==="gap" && oldPoints?.length>0){
            oldPoints.map((item) => {
                userOCEANPoints[item] = newPoints[item] >= 70 ? oldPoints[item]*0.3 : newPoints[item] > 50 ?  oldPoints[item]*0.5 : newPoints[item] >= 25 ? oldPoints[item]*0.7 : newPoints[item] >= 15 ? oldPoints[item]*0.9 : oldPoints[item] ;
                return true
            })
            //userOCEANPoints["O"] = newPoints["O"] >= 70 ? oldPoints["O"]*0.3 : newPoints["O"] > 50 ?  oldPoints["O"]*0.5 : newPoints["O"] >= 25 ? oldPoints["O"]*0.7 : newPoints["O"] >= 15 ? oldPoints["O"]*0.9 : oldPoints["O"] ;
            //userOCEANPoints["C"] = newPoints["C"] >= 70 ? oldPoints["C"]*0.3 : newPoints["C"] > 50 ?  oldPoints["C"]*0.5 : newPoints["C"] >= 25 ? oldPoints["C"]*0.7 : newPoints["C"] >= 15 ? oldPoints["C"]*0.9 : oldPoints["C"] ;
            //userOCEANPoints["E"] = newPoints["E"] >= 70 ? oldPoints["E"]*0.3 : newPoints["E"] > 50 ?  oldPoints["E"]*0.5 : newPoints["E"] >= 25 ? oldPoints["E"]*0.7 : newPoints["E"] >= 15 ? oldPoints["E"]*0.9 : oldPoints["E"] ;
            //userOCEANPoints["A"] = newPoints["A"] >= 70 ? oldPoints["A"]*0.3 : newPoints["A"] > 50 ?  oldPoints["A"]*0.5 : newPoints["A"] >= 25 ? oldPoints["A"]*0.7 : newPoints["A"] >= 15 ? oldPoints["A"]*0.9 : oldPoints["A"] ;
            //console.log("gap", userOCEANPoints, newPoints, oldPoints)
        }
        else{
            userOCEANPoints = oldPoints;
        }
        ///invert N if available
        if(userOCEANPoints["N"] !== undefined && userOCEANPoints["N"] >= 0){
            userOCEANPoints["N"] =  (100-userOCEANPoints["N"]) ;
            if(type==="gap"){
                userOCEANPoints["N"] = newPoints["N"] >= 70 ? oldPoints["N"]*0.3 : newPoints["N"] >= 50 ?  oldPoints["N"]*0.5 : newPoints["N"] >= 25 ? oldPoints["N"]*0.9 : oldPoints["N"] ;
            }
        }
        //console.log('pre- results', userOCEANPoints, oldPoints)
        var questionCategoriesReduce = [];
        var questionCategoriesPremiumReduce = [];
        if(this.state.questionCategories!== undefined ){
            for (var [key, item] of Object.entries(this.state.questionCategories)){
                if('personalityTraitsTarget' in item){
                    //console.log("item", item.value)
                    var sumGrowth = 0;
                    for (const [OCEAN, targetOCEAN] of Object.entries(item.personalityTraitsTarget)){
                        //console.log('itemgrowth', OCEAN, targetOCEAN)
                        if(userOCEANPoints[OCEAN] !== undefined){
                            //console.log('itemgrowth', OCEAN, targetOCEAN)
                            var needFactor = 1
                            if(userOCEANPoints[OCEAN] < 36) needFactor = 4
                            else if(userOCEANPoints[OCEAN] <= 50) needFactor = 2.5
                            else if(userOCEANPoints[OCEAN] < 70) needFactor = 1
                            else if(userOCEANPoints[OCEAN] >= 70) needFactor = 0.5
                            var itemGrowth = needFactor *  ((100-userOCEANPoints[OCEAN]) + ( targetOCEAN * needFactor/2 ) )
                            itemGrowth = targetOCEAN*needFactor - userOCEANPoints[OCEAN];
                            if(itemGrowth < 0) itemGrowth = 0;
                            //console.log('new factor', OCEAN, 'factor', userOCEANPoints[OCEAN], item.value, 'target', targetOCEAN,  'itemGrowth', itemGrowth)
                            //console.log('need factor', )  
                            sumGrowth += itemGrowth;
                        }
                    }
                    item['sumGrowth'] = sumGrowth;
                    //console.log( "sumGrowth", sumGrowth, item.personalityTraitsTarget, userOCEANPoints, item.value, questionsAnswered)
                    if(item.premium === true){
                        //console.log( "prmeu", item.value, sumGrowth)
                        questionCategoriesPremiumReduce.push(item);
                        questionCategoriesPremiumReduce.sort((a,b) => (a.sumGrowth<b.sumGrowth ? 1 : -1))
                    }
                    else{
                        //console.log( "normal", item.value, sumGrowth)
                        questionCategoriesReduce.push(item);
                        questionCategoriesReduce.sort((a,b) => (a.sumGrowth<b.sumGrowth ? 1 : -1))
                    }
                    //console.log( "sumGrowth sorted", questionCategoriesReduce)
                }
                else{
                    console.log('no traits', key)
                }
            }
            //console.log('resultssd', questionCategoriesReduce, questionCategoriesReduce)
            if(Object.keys(this.state.questionCategories).length>3){
                //console.log('result deeper', questionCategoriesReduce, questionsAnswered )
                if(questionsAnswered > 0){
                    var cut = questionCategoriesReduce.slice(0,3);
                    if(questionCategoriesPremiumReduce.length > 0){
                        cut = questionCategoriesReduce.slice(0,2)
                        cut.push(questionCategoriesPremiumReduce[0]);
                    }
                    this.setState({
                        questionCategoriesReduce: cut
                    })
                }
                else{
                    this.setState({
                        questionCategoriesReduce
                    })
                }
            }
            if(questionCategoriesReduce.length === 1){
                //console.log('check is ', questionCategoriesReduce, questionsAnswered )
                this.setState({
                    chosenCategory: questionCategoriesReduce[0],
                    categories : questionCategoriesReduce,
                    openLeadershipQualityPicker1: false,
                    openLeadershipQualityPicker2: true,
                });
            }
        }
        //console.log('resultssd', questionCategoriesReduce)
        ///invert N if available
        if(userOCEANPoints["N"] !== undefined && userOCEANPoints["N"] >= 0){
            userOCEANPoints["N"] =  (100-userOCEANPoints["N"]) ;
        }
        return userOCEANPoints
    }
    

    handleRestart = (event=null) => {
        this.setState({
            questionCategoriesReduce: this.state.questionCategories,
            currentPersonalityTrait: {},
            questionType: 'interest',
            nextQuestion: 'interest',
            categories : [],
            questionsAnswered : 0,
        });
        //this.props.closeDialog();
        this.updateQuestionPair({}, true, 'average')
    }

    handleClose = () => {
        //this.handleRestart()
        if(this.props.closeDialog !== undefined ){
            this.props.closeDialog();
        }
    }


    render(){
        const { theme } = this.props;
        //console.log('categories', this.state)
        return(
            <RemoveScroll enabled={this.props.openDialog || false}> 
                <Dialog
                    open={this.props.openDialog}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={{...theme.dialogTitle, paddingRight: 0}} >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center"
                            style={{height:"100%"}} >
                            <Grid item>
                                <IconButton onClick={this.handleClose}>
                                    <CancelIcon />
                                </IconButton>
                            </Grid>
                            <Grid item style={{paddingLeft:10}}>
                                <span style={theme.dialogTitle.titleText}>Choose a Quest</span>
                            </Grid>
                            <Grid item style={{paddingRight:10}}>
                                <span style={theme.dialogTitle.titleLink} onClick={this.handleClose}>
                                    Cancel
                                </span>
                            </Grid>
                            <Grid item xs={12} style={{height:10}}></Grid>
                            <Grid item xs={12} >
                                <LinearProgress variant="determinate" value={this.state.questionsAnswered>=2? 66 : this.state.questionsAnswered>=1 ? 33 : 0} color="secondary"
                                    style={{
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        height: 7,
                                        borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px'      
                                    }} 
                                />
                            </Grid>
                        </Grid>
                                
                    </DialogTitle>
                    <DialogContent 
                        style={theme.dialogContent}
                        ref={el => this.dialogRef = el} 
                    >
                        {
                            this.props.openDialog 
                            ?  
                                ((this.state.categories !== null && this.state.categories!== undefined && this.state.categories.length===1 )
                                    || this.state.openLeadershipQualityPicker1 
                                    || this.state.openLeadershipQualityPicker2 )
                                    ?   
                                        this.showQuestionPackage()
                                    :   
                                        this.showQuestionPackage()
                            : ""
                            
                        }
                    </DialogContent>
                    <LeadershipQualityPicker
                        defaultSet={this.props.defaultSet || undefined}
                        firstSessionDone={false}
                        chosenCategory={this.state.chosenCategory}
                        scope={'current'}
                        user={this.props.user}
                        preselectQuality={this.state.preselectQuality}
                        openChooseTopic1={this.state.openLeadershipQualityPicker1 || false}
                        openChooseTopic2={this.state.openLeadershipQualityPicker2 || false}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        handlerCloseIgnoreProgramsFlag = {true}
                        handler={ (scope) => {this.handleLeadershipQualityPickerCloseBack()} }
                        handlerOpen2={ (entry) => {this.handleLeadershipQualityPickerOpen2(entry)} }
                        handlerClose2={() =>this.handleLeadershipQualityPickerClose2()}
                        handlerCloseAll={() =>this.handleLeadershipQualityPickerCloseAll()}
                    />
                </Dialog>
            </RemoveScroll>  
        )// end return
        
    }//end render


    updateQuestionPair = (newPoints, reset=false, type='gap') => {
        if(this.dialogRef!==undefined && this.dialogRef!==null && this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0;
        var questionsAnswered = this.state.questionsAnswered + 1
        
        var question = "Which skill is most relevant to you at the moment?"
        var questionType = this.state.nextQuestion;
        if(reset){
            questionsAnswered = 0;
            questionType = "interest";
        }
        var nextQuestion = 'interest'
        var newPointsCalculation = newPoints
        if(!reset){
            newPointsCalculation = this.calcNewPoints(this.state.currentPersonalityTrait,  newPoints, questionsAnswered , type)
        }
        this.setState({
            currentPersonalityTrait: newPointsCalculation, 
            questionType,
            questionsAnswered
        } )
        //console.log('loading new points in', questionsAnswered, newPoints, newPointsCalculation)

        // this.setState({ currentPersonalityTrait: newPoints  }, () => {return true} );
        var option1Text = '1';
        var option2Text = '2';
        var option1Points = {...newPoints};
        var option2Points =  {...newPoints};
        var option1Image = '';
        var option2Image = '';
        var option1Explain = '';
        var option2Explain = '';
        var matchingCategories = [];
        //console.log('updatequestions',questionType, )
        //console.log(this.state.questionCategories)
        if(questionType === "interest" && this.state.questionCategories !== undefined) {
            //question = "What is bothering you these days?";
            question = "What do you wish to improve about yourself?";
            nextQuestion = 'issueShort';
        }
        else if(questionType === "issueShort") {
            question = "What would you like to improve?"
            nextQuestion = 'issueLong';
        }
        else if (questionType === "issueLong") { 
            //question = "What is the absolute priority right now for you?"
            question = "What is close to your personal vision?"
            nextQuestion = 'interest';
        }
        else if (questionType === "issueExtra") { 
            question = "What makes your weekdays more difficult?"
            nextQuestion = 'interest';
        }
        this.setState({  option1Image, option2Image,  })
        this.setState({
            question, nextQuestion, currentPersonalityTrait: newPointsCalculation , questionType, 
            option1Text, option2Text, 
            option1Points, option2Points, 
            categories: matchingCategories, 
            option1Explain, option2Explain
        })
        this.setState({
            loading:true
        })

    }

    showQuestionPackage(){
        const { theme } = this.props;
        //const { question, option1Text, option2Text, option1Image, option2Image , option1Points , option2Points, categories, option1Explain, option2Explain} = this.state;
        const { question } = this.state;  
        return (
            <div>
                <div style={{textAlign : 'center'}} >
                    <h2>
                        {this.state.questionCategoriesReduce?.length>0
                            ?   question
                            :   ""
                        }
                    </h2>
                </div>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {this.state.questionCategoriesReduce!== undefined &&
                        this.state.questionCategoriesReduce.map( (item, index) => {
                            if(this.state.questionsAnswered>=1 || item.program === "default"){
                                return(
                                    <Grid item xs={12} key={"cards_"+index} >
                                        <Card key={"cards_"+item.value} style={{
                                                ...theme.CardDialogueButton,
                                                background: 'linear-gradient(215deg, '+theme.palette.white+' 10%, '+item.backgroundColor+' 90%)',
                                                backgroundImage : 
                                                    item.url !== undefined && item.url !== '' 
                                                        ?  'linear-gradient( to right, '+item.backgroundColor+' 30%, transparent ), url("'+item.url+'")'
                                                        :   '',
                                                backgroundPosition: 'right 40%',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                color: theme.palette.white,
                                                //backgroundColor : this.state['cards_'+item.value] && this.state.questionsAnswered<2 ? theme.palette.themeGold : theme.palette.white
                                            }} 
                                            onClick={() => {
                                                if(true){
                                                    var responseTopic = (item.text  + ". " + item.answerQuestionInterest);
                                                    ExtendDialogueMessage(this.props.firebase, this.context, responseTopic , question, 'utter_ask_challenges', '', 'onboarding');
                                                    this.pickQualityAfterTest(item, true);
                                                }
                                            }}
                                            >
                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                <Grid item xs={3} >
                                                    <div style={{ height: theme.CardDialogueButton.height, maxHeight:theme.CardDialogueButton.maxHeight, marginRight:20  }}> 
                                                        {item.icon!== undefined && item.icon!== "" && item.backgroundColor!== undefined && item.backgroundColor!== ""
                                                            ?
                                                                <AvatarImage type='imageIcon' image={item.icon} vertical-align="middle" squareSize={"auto"}  backgroundColor={item.backgroundColor} />
                                                            :   
                                                                item.url!== undefined && item.url!== ""
                                                                ?
                                                                    <AvatarImage type='imageIcon' image={item.url} vertical-align="middle" squareSize={"auto"}  backgroundColor={"white"} />
                                                                :
                                                                    <AvatarImage type='category' vertical-align="middle" item={item.value} squareSize={"auto"} backgroundColor={"white"} />
                                                        }
                                                        
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9} style={{padding:10, minHeight:1}}> 
                                                    <Textfit 
                                                        style={{
                                                            height: "calc(40% + 1px)", 
                                                            width:"100%",
                                                        }}
                                                    >
                                                    {item.premium &&
                                                        <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeGold}} />
                                                    }
                                                    <strong>{item.text}:</strong>
                                                    </Textfit>
                                                    <div style={{minHeight:1, maxHeight:theme.CardDialogueButton.maxHeight}} >
                                                        {(this.state.questionsAnswered<1) //style={{fontSize:'smaller'}}
                                                            ?
                                                                <Textfit 
                                                                    style={{
                                                                        height: "calc(40% + 1px)", 
                                                                        width:"100%",
                                                                        paddingRight:10
                                                                        //fontSize:'larger'
                                                                        //wordWrap:'break-word'
                                                                    }}
                                                                >
                                                                    {  item.explanationShort }
                                                                </Textfit>
                                                            :
                                                                <Textfit style={{
                                                                        //height: '17.14130434vw',
                                                                        //maxHeight: 90,
                                                                        height: "calc(60% + 1px)", 
                                                                        width:"100%",
                                                                        paddingRight:10
                                                                        }}> 
                                                                    {this.state.questionType  ==='issueShort'
                                                                        ?   item.answerQuestionIssueShortTerm
                                                                        :   (this.state.questionType  ==='issueLong'|| this.state.questionType  ==='issueExtra')
                                                                            ?   item.answerQuestionIssueLongTerm
                                                                            :   item.answerQuestionInterest
                                                                    }
                                                                </Textfit>
                                                        }
                                                    </div>                                                       
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                
                                    
                                )
                            }
                            else return ""
                        })
                    }
                </Grid>
                <div style={theme.divCenter}>
                    <br/>
                    <Link to="#" 
                        onClick={() => {
                            this.setState({openLeadershipQualityPicker1: true})
                            ExtendDialogueMessage(this.props.firebase, this.context, 'Show me the personal development programs, Rocky.', 'Please choose a suggested soft skill category.', 'utter_ask_feedback', '', 'onboarding');
                        }}
                    > 
                        Show all Quests
                    </Link>
                    <br/><br/>
                    {false && this.state.questionsAnswered>=1
                        &&
                        <Link to="#" onClick={() => {this.handleRestart() } }> Restart </Link>
                    }
                </div>
            </div>
        );
    }

    handlercheck = () => {
        //console.log('opener called')
        this.setState({
            openLeadershipQualityPicker1: false,
            openLeadershipQualityPicker2: true,
        });
    }

    handleLeadershipQualityPickerOpen2 = (entry) => {
        //console.log('new things', entry);
        this.setState({
          chosenCategory: entry,
          openLeadershipQualityPicker1: false,
          openLeadershipQualityPicker2: true,
        });
    }
    handleLeadershipQualityPickerCloseBack = () => {
        //console.log('handler called for 2')
        this.setState({
            openLeadershipQualityPicker1: false,
            openLeadershipQualityPicker2: false,
        });
        if(this.props.restartDialog !== undefined){
            this.props.restartDialog();
        }
        else if(this.props.handleClose !== undefined){
            console.log('close parent handle')
            this.props.handleClose();
        }
        //this.props.closeDialog();
    }
    handleLeadershipQualityPickerClose2 = () => {
        //console.log('handler called for 2')
        this.setState({
            openLeadershipQualityPicker1: true,
            openLeadershipQualityPicker2: false,
        });
        //this.props.closeDialog();
    }
    handleLeadershipQualityPickerCloseAll = () => {
        //console.log('handler called')
        this.setState({
            openLeadershipQualityPicker2: false,
            openLeadershipQualityPicker1: false,
        });
        if (this.props.closeDialogNext !== undefined ) {
            this.props.closeDialogNext();
        }
        else if(this.props.handleClose !== undefined){
            console.log('close parent handle')
            this.props.handleClose();
        }
    }
    pickQualityAfterTest(categoryToGo=undefined, finish=true){
        if(categoryToGo!== undefined && categoryToGo!==""){
            //console.log("saved update data", categoryToGo.value)
            if(this.props.handleTopicSelection!== undefined ){ this.props.handleTopicSelection(categoryToGo.initialLevelQuality) }
            if(this.context.onboardingDone !== true){
                //console.log("p[datong", categoryToGo.value)
                var initialSet = {
                    //THIS FLAG IS ONLY SET IF A Module IS ACTIVATED WITH BUTTON BY USER
                    //onboardingDone : true
                };
                if(categoryToGo.value !== undefined && categoryToGo.value !== '' ) initialSet['currentCategory'] = categoryToGo.value;
                if(categoryToGo.initialLevelQuality!== undefined && categoryToGo.initialLevelQuality!== "") { 
                    initialSet['currentLeadershipQuality'] = categoryToGo.initialLevelQuality;
                    initialSet['additionalLeadershipQuality1'] = this.props.firebase.fieldValue.delete();
                    initialSet['additionalLeadershipQuality2'] = this.props.firebase.fieldValue.delete();
                    initialSet['additionalLeadershipQuality3'] = this.props.firebase.fieldValue.delete();
                    initialSet['additionalLeadershipQuality4'] = this.props.firebase.fieldValue.delete();
                    initialSet['additionalLeadershipQuality5'] = this.props.firebase.fieldValue.delete();
                    initialSet['additionalLeadershipQuality6'] = this.props.firebase.fieldValue.delete();
                }
                if(categoryToGo.explanationShort!== undefined) initialSet['ctaText'] = categoryToGo.explanationShort;
                if(categoryToGo.answerQuestionIssueLongTerm!== undefined) initialSet['achievementGoal'] = categoryToGo.answerQuestionIssueLongTerm;
                this.props.firebase.user(this.context.uid).update(initialSet);
                this.setState({
                    //autoload : true,
                    preselectQuality: categoryToGo.initialLevelQuality,
                    categories : [categoryToGo],
                    chosenCategory: categoryToGo.value,
                    onboardingDone: true,
                })
            }
            else{
                this.setState({
                    //preselectQuality: categoryToGo.initialLevelQuality,
                    categories : [categoryToGo],
                    chosenCategory: categoryToGo.value,
                    onboardingDone: true,
                })
            }
        }
        if(finish){
            this.props.firebase.personalityTrait(this.context.uid).set({
                createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                type: 'average',
                O :  this.state.currentPersonalityTrait.O > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.O),
                C :  this.state.currentPersonalityTrait.C > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.C),
                E :  this.state.currentPersonalityTrait.E > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.E),
                A :  this.state.currentPersonalityTrait.A > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.A),
                N :  this.state.currentPersonalityTrait.N > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.N),
                quality : 1,
                userId: this.context.uid
            }, {merge:true }); 
            this.props.firebase.personalityTraitArchive(this.context.uid ).add({
                createdAt : this.props.firebase.fieldValue.serverTimestamp(),
                type: 'onboardingQuiz',
                O :  this.state.currentPersonalityTrait.O > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.O),
                C :  this.state.currentPersonalityTrait.C > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.C),
                E :  this.state.currentPersonalityTrait.E > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.E),
                A :  this.state.currentPersonalityTrait.A > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.A),
                N :  this.state.currentPersonalityTrait.N > 100 ? 95 :  Math.ceil( this.state.currentPersonalityTrait.N),
                userId: this.context.uid,
                quality : 1,
            }); 
            if(this.props.openQualityPickerAfterQuiz !== false){
                this.setState({
                    openLeadershipQualityPicker2 : true
                })
            }
            else{
                this.handleClose()
            }
        }
    }
};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
    
)(DialogAssessQuality);

//  module.exports.handleCurrentAchievementGoalOpen = DialogChangeGoal.handleCurrentAchievementGoalOpen();
