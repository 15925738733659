import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {  withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
// needed if signup and empty user data
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import clsx from 'clsx';
import {GetTimeZone} from '../Modules/LoadUserTimezone';

//import { useMediaQuery } from '@material-ui/core';
import {StoreAppError} from '../Modules/StoreAppError';

import Grid from '@material-ui/core/Grid';
//import backgroundImage from "../../images/login-background.jpg";
//import backgroundImage from "../../images/login-background.png";
//import backgroundImageApp from "../../images/login-background.gif";

import LoadingProgress from '../Modules/LoadingProgress';
import {SetStateLoadCategoriesAndQualities} from '../Modules/LoadCategoriesAndQualities';
import {SetStateLoadUserData} from '../Modules/LoadUserData';
//import DataCache from '../Modules/DataCache';

import NavigationDesktop from '../Navigation/NavigationDesktop';
import NavigationMobile from '../Navigation/NavigationMobile';

import { StoreMobileDeviceToken } from '../Modules/StoreMobileDeviceInfo';

//import DrawerOverview from '../Goals/DrawerOverview';

import SnackbarInfo from '../Modules/SnackbarInfo';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    root: {
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: theme.palette.backPrimary, 
    },
    appKits: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        webkitUserSelect: 'none',
        userSelect: 'none'
    },
    shiftContent: {
        minHeight: '100%',
        backgroundColor: theme.palette.backPrimary,
        paddingLeft: theme.HomeNavigationShiftContent.paddingLeft,
        paddingTop: theme.HomeNavigationShiftContent.paddingTop,
        paddingRight: theme.HomeNavigationShiftContent.paddingRight,
        paddingBottom: theme.HomeNavigationShiftContent.paddingBottom,
    },
    NoshiftContent: {
        minHeight: '100%',
        backgroundColor: theme.palette.backPrimary,
        paddingLeft: theme.HomeNoNavigationShiftContent.paddingLeft,
        paddingTop: theme.HomeNoNavigationShiftContent.paddingTop,
        paddingRight: theme.HomeNoNavigationShiftContent.paddingRight,
        paddingBottom: theme.HomeNoNavigationShiftContent.paddingBottom,
    },
    content: {
        height: '100vh'
    },
    rootLoading: {
        //backgroundImage: `url(${backgroundImage})`,
        //backgroundPosition: "center",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        backgroundColor: theme.palette.themeAltLoading, 
        height: '100vh',
        //overflow: 'hidden',
        overflowY: 'scroll',
        paddingLeft:0,
        paddingRight:0,
        //flexGrow: 1,
    },
    rootLoadingApp: {
        //backgroundImage: `url(${backgroundImageApp})`,
        //backgroundPosition: "center 30px",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        backgroundColor: theme.palette.themeAltLoading, 
        height: '100vh',
        //overflow: 'hidden',
        overflowY: 'scroll',
        paddingLeft:0,
        paddingRight:0,
        //flexGrow: 1,
    },
});

class MainLayoutCoach extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            users: null,
            user: null,
            value: 3,
            botUtterance: '',
            dialogueStatus: '',
            checkDialogue: true,
            left: false,
            newHomeStatus: '',
            routineStatus: '',
            insights: [],
            screenWidth:  window.innerWidth ,
            firestoreLoaded: true,
            loading: true
        };
        // to make it reactive on change needs eventlistener
        //this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        if(this.state.screenWidth !== window.innerWidth && this._isMounted){
            this.setState({ screenWidth: window.innerWidth });
            //console.log('screen change', this.state.screenWidth);
        }
    }
    updateWindowDimensionsEfficientFn = this.debounce(() => {
        if(this.state.screenWidth !== window.innerWidth && this._isMounted){
            this.setState({ screenWidth: window.innerWidth });
            //console.log('screen change', this.state.screenWidth);
        }
    }, 500);

    debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var environment = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(environment, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(environment, args);
        };
    };

    componentDidMount() {
        this._isMounted = true;
        const {theme} = this.props;
        //console.log('user data', this.context)
        if(this.context.uid !== undefined && this.context.uid !== "" && this._isMounted){
            this.getUser()
            if(this.state.failedLoadingUser){
                console.log('no user or incomplete: forward to signup')
                this.props.history.push(ROUTES.SIGN_IN);
            }
            //console.log('before before Coach', this.context)
            SetStateLoadCategoriesAndQualities(this, this.props.firebase, true, false, this.context.programs);
            //console.log('before',  this.context, this.context.uid)
            StoreMobileDeviceToken(this.props.firebase.user(this.context.uid, (this.context?.dialogueCounter >=1)));
        }
        else{
            //console.log('context', this.context)
            // deleted or missing user content, start new signup
            console.log('no user: forward to signup')
            //this.props.history.push(ROUTES.SIGN_IN);
        }

        var userTimezone = GetTimeZone(this.props.firebase, this.context);

        if(this._isMounted){
            window.addEventListener("resize", this.updateWindowDimensionsEfficientFn);
            this.setState({ loading: false, timezone: userTimezone });
            if(window.StatusBar !== undefined && this.state.firebaseConnectionError !== true){
                window.StatusBar.overlaysWebView(false);
                window.StatusBar.backgroundColorByHexString(theme.palette.backPrimary);
                if(theme.palette.darkMode) window.StatusBar.styleLightContent();
                else window.StatusBar.styleDefault();
            }
        }
        this.internetConnectionCheck();
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.updateWindowDimensionsEfficientFn);
    }

    internetConnectionCheck() {
        const {theme} = this.props;
        this.props.firebase.onDisconnectSimple(this, (connected) => {
            var latestConnectionErrorStats = this.state.firebaseConnectionError ;
            if(connected === false && latestConnectionErrorStats === undefined && this._isMounted){
                this.setState({firebaseConnectionMessage : "Connecting with you ..."});
                if(window.StatusBar !== undefined){
                    window.StatusBar.overlaysWebView(false);
                    window.StatusBar.backgroundColorByHexString(theme.palette.themeAltLoading);
                    window.StatusBar.styleLightContent();
                }  
            }
            else if(connected === false && this.state.openAlert !== true && this._isMounted){
                this.setState({ 
                    openAlert:true,
                    firebaseConnectionMessage : 'Trying to connect with the internet!'
                })
                if(window.StatusBar !== undefined){
                    window.StatusBar.overlaysWebView(false);
                    window.StatusBar.backgroundColorByHexString(theme.palette.themeAltLoading);
                    window.StatusBar.styleLightContent();
                }  
            }
            else if(connected === true && this.state.loading !== true){
                if(window.StatusBar !== undefined){
                    window.StatusBar.overlaysWebView(false);
                    window.StatusBar.backgroundColorByHexString(theme.palette.backPrimary);
                    if(theme.palette.darkMode) window.StatusBar.styleLightContent();
                    else window.StatusBar.styleDefault();
                }
            }
        });
    }

    getUser = async () => {
        if(this._isMounted){
            //await SetStateLoadUserData(this, this.props.firebase, this.context.uid)
        }
    }

    render() {
        //console.log('mainlayoutCoach', this.state, this.props)
        const { classes} = this.props;
        const { leadershipCategories, leadershipQualities} = this.state;
        const { screenWidth } = this.state;

        var {user} = this.state
        if(this.context !== undefined && this.context.roles !== undefined && this.context.roles instanceof Array){
            user = this.context;
        }
        // pass props to children
        //https://stackoverflow.com/questions/35835670/react-router-and-this-props-children-how-to-pass-state-to-this-props-children
        // var childrenWithProps = React.cloneElement(children, {leadershipCategories, leadershipQualities, user});
        //console.log(this.context, "my data before start")
        if(this.context.currentLeadershipQuality?.length > 0 && this.context.roles?.length > 0 && leadershipQualities?.length > 0 && leadershipCategories?.length > 0){
            //console.log(this.context, "my data before start")
            return (
                <div
                    className={clsx({
                        [classes.root]: (this.state.firebaseConnectionError || this.state.loading ) ? false : true,
                        [classes.appKits] : (process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM !== undefined && process.env.REACT_APP_DEVICE_PLATFORM === "BROWSER") ? false : true,
                        [classes.rootLoading]: (this.state.firebaseConnectionError || this.state.loading ) && (process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM !== undefined && process.env.REACT_APP_DEVICE_PLATFORM === "BROWSER")
                            ? true : false,
                        [classes.rootLoadingApp]: (this.state.firebaseConnectionError || this.state.loading ) && (process?.env === undefined || process?.env.REACT_APP_DEVICE_PLATFORM !== "BROWSER")
                            ? true : false,
                        [classes.shiftContent]: screenWidth >= 1200,
                        })}
                         
                    id="scrollableDiv"
                    >
                    {screenWidth >= 1200
                        ?
                            <NavigationDesktop defaultSet={this.props.defaultSet || {}} />
                        :
                            ""
                    }
                    { //window.navigator.onLine && this._isMounted  && user !== null && leadershipQualities !== undefined && leadershipCategories !== undefined
                        this.state.firebaseConnectionError!==true && this._isMounted  && user !== null && leadershipQualities !== undefined && leadershipCategories !== undefined
                        ?
                            this.renderMain()
                        :
                            <div style={{paddingTop: '25vH', paddingBottom:20}}>
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} 
                                    //color='light' 
                                    loadingText={"I'm getting ready"} 
                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                    allowRefresh={true}
                                />
                            </div>
                    }
                    {   // (!window.navigator.onLine  ) && 
                        this.state.firebaseConnectionError===true &&
                        <div className={screenWidth >= 1200 ? classes.shiftContent : classes.NoshiftContent} >
                            <p style={this.props.theme.textCenterPadding}>
                                &nbsp; {this.state.firebaseConnectionMessage}
                            </p> 
                        </div>
                    }

                    <SnackbarInfo 
                        alertText = {this.state.firebaseConnectionMessage}
                        openOnClick = {true}
                        alertOpenEvent = {this.state.openAlert || false}
                        closeHandler = {() => {this.setState({openAlert: false})}}
                    />
                    {screenWidth < 1200
                        ?   <NavigationMobile user={this.context} defaultSet={this.props.defaultSet || {}}/>
                        :   ""
                    } 
                </div>
            );
        }
        else if(this.state.failedLoadingFirestore){
            return(
                <div  className={classes.root}  >
                    <div>
                        <p style={this.props.theme.textErrorCenterPadding}>
                            &nbsp; {this.state.firebaseConnectionMessage}
                        </p> 
                    </div>
                </div>
            )
        }
        else{
            return(
                <div  className={classes.root}  >
                    <div>
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"I'm getting ready"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    </div>
                    <SnackbarInfo 
                        alertText = {this.state.firebaseConnectionMessage}
                        openOnClick = {true}
                        alertOpenEvent = {this.state.openAlert || false}
                        closeHandler = {() => {this.setState({openAlert: false})}}
                    />
                </div>
            )
        }
    }

    renderMain() {
        //console.log('props mainlayoutcoachpage',this.props)
        const { classes, children} = this.props;
        const { leadershipCategories, leadershipQualities, dataError} = this.state;
        var {user} = this.state
        if(this.context !== undefined && this.context.roles !== undefined && this.context.roles instanceof Array){
            user = this.context;
        }
        const { screenWidth } = this.state;
        try{
            if(user !== undefined && leadershipQualities !== undefined && leadershipCategories!== undefined && (dataError===undefined || !dataError)){
                return(
                    <div 
                        //className={screenWidth >= 1200 ? classes.shiftContent : classes.NoshiftContent}   
                        className={ classes.NoshiftContent}   
                    > 
                        { screenWidth >= 1200
                            ?
                                <Grid container direction="row" >
                                    <Grid item xs={12}>
                                        <div style={{height:80}}> &nbsp; </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {React.cloneElement(children, {
                                            leadershipCategories, 
                                            leadershipQualities, 
                                            user,
                                            defaultSet : (this.props.defaultSet || undefined),
                                            screen : 'desktop', 
                                            screenWidth : screenWidth,
                                            refreshCategories : this.refreshCategories,
                                            refreshQualities : this.refreshQualities,
                                            refreshDefaultSet : (children?.props?.refreshDefaultSet || undefined)
                                            })}
                                    </Grid>
                                </Grid>
                            :
                                React.cloneElement(children, {
                                    leadershipCategories, 
                                    leadershipQualities, 
                                    user, 
                                    defaultSet : (this.props.defaultSet || undefined),
                                    screen : 'mobile', 
                                    screenWidth : screenWidth,
                                    refreshCategories : this.refreshCategories,
                                    refreshQualities : this.refreshQualities,
                                    refreshDefaultSet : (children?.props?.refreshDefaultSet || undefined)
                                })
                        }
                    </div>
                )
            }
            else{
                StoreAppError(this.props.firebase, 'Failed to load renderMain', 'MainLayout', 'renderMain', 'missingKeyParam')
                return(
                    <div>
                        <p> Sorry we have a technical problem </p>
                        <p> Your user information could not be loaded </p>
                        <Link to={ROUTES.SIGN_IN}>Sign in again</Link>
                    </div>
                )
            }
        }
        catch(error){
            StoreAppError(this.props.firebase, error.message, 'MainLayout', 'renderMain', 'Execution')
            return(
                <div>
                    <p> Sorry we have a technical problem </p>
                    <p> {error} </p>
                    <Link to={ROUTES.SIGN_IN}>Sign in again</Link>
                </div>
            )
        }
    }

    refreshCategories = (leadershipCategories, reload=false) => {
        if(this._isMounted && leadershipCategories?.length>0){
            console.log('owervwrite leadershipCategories')
            this.setState({leadershipCategories}, () => {
                if(reload) SetStateLoadCategoriesAndQualities(this, this.props.firebase, false, true, this.context.programs);
            });
        }
    }
    refreshQualities = (leadershipQualities, reload=false) => {
        if(this._isMounted && leadershipQualities?.length>0){
            console.log('owervwrite quals coach', leadershipQualities.length)
            this.setState({leadershipQualities}, () => {
                if(reload) SetStateLoadCategoriesAndQualities(this, this.props.firebase, false, true, this.context.programs);
            });
        }
    }
    refreshDefaultSet = (defaultSet, reload=false) => {
        //console.log('owervwrite quals defaultSet', this.props)
        if(this._isMounted && this.props.refreshDefaultSet !== undefined){
            console.log('owervwrite second defaultSet')
            this.props.refreshDefaultSet(defaultSet);
        }
    }
}

MainLayoutCoach.propTypes = {
    classes: PropTypes.object.isRequired
};

const condition = authUser => 
    authUser && 
    (   authUser.roles?.includes(ROLES.ADMIN)
        || authUser.roles?.includes(ROLES.TEAM)
        || authUser.roles?.includes(ROLES.COACHADMIN)
        || authUser.roles?.includes(ROLES.REMOTECOACH)
    );

export default compose(
    withStyles(styles, { withTheme: true}),
    withFirebase,
    withRouter,
    // withEmailVerification,
    withAuthorization(condition),
)(MainLayoutCoach);
