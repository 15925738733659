import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthUserContext } from '../Session';
import PropTypes from 'prop-types';
//import DialogueHistory from '../Dialogues/DialogueHistory';
import JournalWeekDetail from './JournalWeekDetail';
import DateBadge from '../Modules/DateBadge';
import Grid from '@material-ui/core/Grid';

import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import {AvatarCard} from '../Modules/AvatarImage';
import MindfulnessScore from '../Charts/MindfulnessScore';
import {SetStateLoadCategoriesAndQualities} from '../Modules/LoadCategoriesAndQualities';
import ContentRecommendationsPreview from '../Content/ContentRecommendationsPreview';


import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import Card from '@material-ui/core/Card';
//import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from "@material-ui/core/CardMedia";
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import satisfied1 from '../../images/satisfied1.png';
import satisfied2 from '../../images/satisfied2.png';
import satisfied3 from '../../images/satisfied3.png';
import satisfied4 from '../../images/satisfied4.png';
import satisfied5 from '../../images/satisfied5.png';


// collapse details
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
//import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {IconPicker} from '../Modules/IconPicker';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
//HN add slide element
import Slide from '@material-ui/core/Slide';

// for visu of mood
import VizSensor from 'react-visibility-sensor';
//import Slider from '@material-ui/core/Slider';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LinearProgress from '@material-ui/core/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

const ColorLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#00695c',
    },
    barColorPrimary: {
      backgroundColor: '#b2dfdb',
    },
})(LinearProgress);

class JournalWeek extends Component {
    _isMounted=false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.props);
        this.state = {
            open: false,
            weeks: {},
            loading: true,
            mainDayMessage: [],
            allDayMessages: [],
            date: new Date(),
            editMessages: false,
            dateKey: props.date,
        };
    }
   
    handleOpen = (mainDayMessage, allDayMessages, image, weekdayIndexStartingSat) => () => {
        //console.log(mainDayMessage, allDayMessages)
        this.setState({
            open: true,
            mainDayMessage: mainDayMessage,
            allDayMessages: allDayMessages,
            dialogueDetails : {},
            image: image, 
            date : new Date(mainDayMessage.date.substring(0, 10)),
            editMessages: false,
            weekdayIndexStartingSat: weekdayIndexStartingSat,
        });
        if(this.props.dialogOpenStatus !== undefined){
            this.props.dialogOpenStatus(true)
        }
        if(mainDayMessage.dialogueId !== undefined && mainDayMessage.dialogueId !==""){
            this.getDialogueDetails(mainDayMessage.dialogueId);
        }
        //HN for the quotes generation
        if (this.state.leadershipCategories === undefined && this._isMounted){
            SetStateLoadCategoriesAndQualities(this, this.props.firebase, true, false, this.context.programs);
        };
    }

    handleClose = () => {
        this.setState({
            open: false,
            mainDayMessage: [],
            allDayMessages: [],
            editMessages: false,
        });
        if(this.props.dialogOpenStatus !== undefined){
            this.props.dialogOpenStatus(false)
        }
    }

    getDialogueDetails = (dialogueId) => {
        this.props.firebase
        .dialogue(dialogueId)
        .get().then(snapshot => {
            if(!snapshot.empty){
                var dialogueDetails = snapshot.data()
                if(this._isMounted) this.setState({dialogueDetails})
                //console.log(dialogueDetails)
            }
        });
    }

    componentDidMount() {
        this._isMounted=true;
        var myMessagesSorted =  this.rearrangeWeeklyReportArray(this.props.dataset.messages);
        this.setState({myMessagesSorted});
    }
    componentWillUnmount() {
        //console.log('unmounting')
        this._isMounted=false;
    }

    rearrangeWeeklyReportArray = (arrayWeekOriginal) => {
        //if (this.props.date==='2020-05-30'){ console.log('Old Array ', arrayWeekOriginal);}
        // this one resoriting the array so that friday is coming before monday
        // also it will group items into arrays of weekdays
        // it also identified for each weekday within the array the answer with the longest sentence
        var reversedArray = [];
        var daySortedArray = {Sat:[], Fri: [], Thu: [], Wed: [], Tue: [], Mon: [], Sun: []};
        var tempDayArray = [];
        var longestSentenceEntryByDay = {Sat:undefined, Fri: undefined, Thu:undefined, Wed:undefined, Tue:undefined, Mon: undefined, Sun: undefined};
        var counter = 0;
        var day = '';
        var longestStringLength = 0;
        arrayWeekOriginal.map(journalItems => {
            reversedArray.unshift(journalItems);
            if (!journalItems.day){
                journalItems.day = 'Week';
            }
            if (journalItems.day === day) {
                if (journalItems.message!== undefined && journalItems.message!=='' && journalItems.message.length > longestStringLength){
                    longestStringLength = journalItems.message.length;
                    longestSentenceEntryByDay[day] = journalItems;
                }
                tempDayArray.unshift(journalItems);
            }
            else {
                tempDayArray = [];
                day = journalItems.day;
                //console.log(journalItems)
                if(journalItems.message!== undefined && journalItems.message!==''){
                    longestStringLength = journalItems.message.length;
                    longestSentenceEntryByDay[day] = journalItems;
                    tempDayArray.unshift(journalItems);
                }
            }
            //tempDayArray.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)); 
            daySortedArray[day] = tempDayArray;
            return counter +=1;
            //counter += 1;
        });
        Object.keys(longestSentenceEntryByDay).forEach((key) => {
            if(longestSentenceEntryByDay[key] === undefined){
                delete longestSentenceEntryByDay[key]
            }
        })
        //if (this.props.date==='2020-05-30')console.log('Highighty ', reversedArray, daySortedArray);
        return [reversedArray, longestSentenceEntryByDay, daySortedArray];
    }

    render() {
        const { theme } = this.props;
        var myMessagesSorted = [[],{},{}]
        if(this.state.myMessagesSorted !== undefined){
            myMessagesSorted = this.state.myMessagesSorted;
        }
        let date = new Date();
        //console.log('new Array ', myMessagesSorted);
        //const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        //console.log(this._isMounted, this.state)
        return (
            <VizSensor 
                partialVisibility={true}
                key={'weekof_'+this.props.date}
                onChange={(isVisible) => { 
                    //console.log('[mounted & visible' , this._isMounted, isVisible)
                    if(isVisible && this.state[isVisible]===undefined) { 
                        this.loadMoodStats();
                        //this.setState({isVisible});
                    }}
                }
                >
                <Grid item md={12} style={{ width: '100%' }}>
                    {this.props.showLastDayOnly !== true && 
                        <div style={theme.cardJournalDayHighlight.titleText}>Week of: {this.props.date}</div>
                    }
                    { 
                        Object.keys(myMessagesSorted[1]).map((dayKey, index) => {
                            if (!this.props.showLastDayOnly || index === 0) {
                                var mainDayMessage = myMessagesSorted[1][dayKey]
                                //let date = new Date(mainDayMessage.date);
                                date = new Date(mainDayMessage.date.substring(0, 10));
                                var weekdayIndexStartingSat = date.getDay()+1
                                if(weekdayIndexStartingSat>6) weekdayIndexStartingSat=0
                                //console.log('date ', Date.parse(mainDayMessage.date));
                                var image = satisfied3
                                try{
                                    if(this.state.moodStats !== undefined && this.state.moodStats['insightsReflection'].currentWeek.weekdaySummary[weekdayIndexStartingSat] ){
                                        var level = Number(this.state.moodStats['insightsReflection'].currentWeek.weekdaySummary[weekdayIndexStartingSat].meanMeasurement);
                                        if(isNaN(level) || level ==="-" || level === undefined){
                                            image = satisfied3
                                        }
                                        else{
                                            level = Math.round(level);
                                        }
                                        switch(level) {
                                            case 5: image = satisfied5; break;
                                            case 4: image = satisfied4; break;
                                            case 3: image = satisfied3; break;
                                            case 2: image = satisfied2; break;
                                            case 1: image = satisfied1; break;
                                            default:  image = satisfied3
                                        }
                                    }
                                    else if(this.state.moodStats !== undefined &&  this.state.moodStats['insightsPriming'].currentWeek.weekdaySummary[weekdayIndexStartingSat] ){
                                        var level2 = Number(this.state.moodStats['insightsPriming'].currentWeek.weekdaySummary[weekdayIndexStartingSat].meanMeasurement);
                                        if(isNaN(level2) || level2 ==="-" || level2 === undefined){
                                            image = satisfied3
                                        }
                                        else{
                                            level2 = Math.round(level2);
                                        }
                                        switch(level2) {
                                            case 5: image = satisfied5; break;
                                            case 4: image = satisfied4; break;
                                            case 3: image = satisfied3; break;
                                            case 2: image = satisfied2; break;
                                            case 1: image = satisfied1; break;
                                            default:  image = satisfied3
                                        }
                                    }

                                }
                                catch(error){
                                    image = satisfied3
                                }
                                //console.log('card', mainDayMessage.messageId+'_'+index+'_'+mainDayMessage.day)
                                return(
                                    
                                        <Card key={mainDayMessage.messageId+'_'+index+'_'+mainDayMessage.day}
                                            style={theme.cardJournalDayHighlight}
                                            onClick={this.handleOpen(mainDayMessage, myMessagesSorted[2][dayKey], image, weekdayIndexStartingSat )}
                                        >
                                                <CardMedia style={theme.cardJournalDayHighlight.CardMedia} 
                                                    image={ this.state.isVisible ? image : ''}>
                                                        <div style={theme.cardJournalDayHighlight.ribbonTitle}> <strong>{dayKey }</strong>
                                                            <IconButton><KeyboardArrowRightIcon /> </IconButton></div> 
                                                        <div style={theme.cardJournalDayHighlight.ribbonSkill}> 
                                                            <AvatarCard textBlock={mainDayMessage.questionTitle 
                                                                    ? mainDayMessage.questionTitle.length > 15
                                                                        ? mainDayMessage.questionTitle.substring(0,14) + '...'
                                                                        : mainDayMessage.questionTitle
                                                                    : 'Key Thought'} 
                                                                type='icon' image={ IconPicker(mainDayMessage.bot_utter)} squareSize='20' backgroundColor={theme.palette.black}/>
                                                        </div>
                                                </CardMedia>
                                                <CardContent key={mainDayMessage.messageId+'_cardcontent_'+index}>
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={2} style={theme.cardGridDate} >
                                                            <DateBadge valueMonth={months[date.getMonth()]} valueDay={date.getDate()}/>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {
                                                            mainDayMessage.message && mainDayMessage.message.length > 99
                                                            ?
                                                                <q>{mainDayMessage.message.substring(0, 79) + "..."}</q>
                                                            :
                                                                <q>{mainDayMessage.message}</q>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {this.returnMoodBarSmiley('insightsPriming', weekdayIndexStartingSat, mainDayMessage.messageId)}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {this.returnMoodBarSmiley('insightsReflection', weekdayIndexStartingSat, mainDayMessage.messageId)}
                                                        </Grid>
                                                    
                                                    </Grid>
                                                </CardContent>
                                        </Card>
                                )// end return
                            }
                            else{
                                return('')
                            }
                        })// end object keys
                    }

                    
                    <RemoveScroll enabled={this.state.open || false}>
                        <Dialog
                            key="DailyJournalItemsPopup"
                            open={this.state.open}
                            onClose={this.handleClose}
                            fullScreen={IsMobileDevice()}
                            PaperProps={{style:theme.dialogWindow}}
                            TransitionComponent={Transition}
                        >
                        <DialogTitle style={theme.dialogTitle}  >
                            <Grid container spacing={0} direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton  onClick={this.handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        Diary {this.state.mainDayMessage.day } {months[this.state.date.getMonth()]} {this.state.date.getDate()}
                                    </span>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleLink}  onClick={this.handleClose}>
                                        Close
                                    </span>
                                </Grid>
                            </Grid>
                            {/**
                                <IconButton onClick={this.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <span style={theme.dialogTitle.titleText}>
                                    Daily journal entries of: {this.state.mainDayMessage.day } {months[this.state.date.getMonth()]} {this.state.date.getDate()}
                                </span>
                             */}
                        </DialogTitle>
                        <DialogContent style={
                            this.state.image!== undefined && this.state.image !== ""
                                ?   {...theme.dialogContent,
                                    //backgroundImage: `url(${this.state.image})`,
                                    //backgroundPosition: "top",
                                    //backgroundSize: "contain",
                                    //backgroundRepeat: "no-repeat",
                                    }
                                :   theme.dialogContent
                            }>
                            {this.state.open
                            ?    <Grid container spacing={1} >
                                    <Grid item xs={12} >
                                        <DateBadge valueMonth={months[this.state.date.getMonth()]} valueDay={this.state.date.getDate()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {this.returnMoodBarSmiley('insightsPriming', this.state.weekdayIndexStartingSat, 'dialog_priming_'+this.props.date)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {this.returnMoodBarSmiley('insightsReflection', this.state.weekdayIndexStartingSat, 'dialog_reflection'+this.props.date)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                        <AvatarCard textBlock={this.state.mainDayMessage.questionTitle 
                                                    ?   this.state.mainDayMessage.questionTitle.length > 25
                                                        ? this.state.mainDayMessage.questionTitle.substring(0,24) + '...'
                                                        : this.state.mainDayMessage.questionTitle
                                                    : 'Key Thought'} 
                                                    type='icon' image={ IconPicker(this.state.mainDayMessage.bot_utter)} squareSize='20' backgroundColor={theme.palette.black}/>
                                    
                                    </Grid>
                                    <Grid item xs={12} style={theme.cardGridJournalDayHighlight} >
                                        {
                                            this.state.mainDayMessage.message && this.state.mainDayMessage.message.length > 99
                                            ?
                                            <q><strong>{this.state.mainDayMessage.message.substring(0, 99) + "..."}</strong></q>
                                            :
                                            <q><strong>{this.state.mainDayMessage.message}</strong></q>
                                        }
                                    </Grid>
                                    {/*** 
                                    <Grid item xs={12} >
                                        <img style={theme.dialogImage} src={this.state.image} alt={'Satisfaction Level'} />
                                    </Grid>
                                    */}
                                    { this.state.dialogueDetails !== undefined && this.state.dialogueDetails.wordCollection !== undefined 
                                        &&
                                        <Grid item xs={12} >
                                            <MindfulnessScore dialogue={this.state.dialogueDetails}/>
                                        </Grid>
                                    }
                                    { this.state.dialogueDetails !== undefined && this.state.dialogueDetails.contentSelection !== undefined 
                                        && this.state.dialogueDetails.contentSelection.length > 0
                                        &&
                                        <>
                                            <Grid item xs={1} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                                {this.state.dialogueDetails.contentSelection !== undefined && this.state.dialogueDetails.contentSelection.length > 0
                                                    &&
                                                        <ArrowLeftIcon  
                                                            style={{color: theme.palette.secondary.main}}
                                                            onClick={() => this.setState({
                                                                contentPreviewItem : this.state.contentPreviewItem === undefined ? this.state.dialogueDetails.contentSelection.length -1
                                                                    :   this.state.contentPreviewItem >= 1
                                                                        ? this.state.contentPreviewItem - 1
                                                                        : this.state.dialogueDetails.contentSelection.length -1
                                                                })}
                                                        />
                                                }
                                            </Grid>
                                            <Grid item style={{width:"80%"}} xs={10}>
                                                <ContentRecommendationsPreview 
                                                    user={this.context}
                                                    contentSelection={this.state.dialogueDetails.contentSelection}
                                                    leadershipCategories={this.state.leadershipCategories}
                                                    leadershipQualities={this.state.leadershipQualities}
                                                    closeAfterActionAdded={false}
                                                    handlerShowPosition={this.state.contentPreviewItem}
                                                    handlerResetPosition={ () => this.setState({contentPreviewItem: undefined})}
                                                    visualLimit={3}
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                                {this.state.dialogueDetails.contentSelection !== undefined && this.state.dialogueDetails.contentSelection.length > 0
                                                    &&
                                                    <ArrowRightIcon
                                                        style={{color: theme.palette.secondary.main}}
                                                        onClick={() => this.setState({
                                                            contentPreviewItem : this.state.contentPreviewItem === undefined ? 2 
                                                                :   this.state.contentPreviewItem >= this.state.dialogueDetails.contentSelection.length-1
                                                                    ? 0
                                                                    : this.state.contentPreviewItem + 1
                                                            })}
                                                    />
                                                }
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={12} >
                                        &nbsp;
                                    </Grid>
                                    { this.state.allDayMessages.map((messages, index2) => {
                                        //console.log('weekdetail ', messages.messageId+'_'+index2);
                                        //console.log('id ', messageId);
                                        //console.log('id value ', this.state.editMessageId);
                                        if(messages.messageId !== undefined && messages.message !== undefined &&  messages.message !== ''){
                                            return(
                                                <JournalWeekDetail key={messages.messageId+'_'+index2} messageSet={messages} day={this.state.mainDayMessage.day}
                                                allMessages={this.props.dataset.messages} />
                                            )
                                        }
                                        else{
                                            return ""
                                        }
                                    })} 
                                </Grid>
                            :   "loading"
                        }
                        </DialogContent>
                        <DialogActions style={
                            this.state.image!== undefined && this.state.image !== ""
                                ?   {
                                    backgroundImage: `url(${this.state.image})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    }
                                :   {}
                            }>
                                &nbsp;
                        </DialogActions>
                    </Dialog>
                </RemoveScroll>  
                </Grid>
            </VizSensor>
        );
    }

    returnMoodBarSmiley = (insightsType, weekdayIndexStartingSat, dayKey) => {
        const {theme} = this.props;
        try{
            if(weekdayIndexStartingSat !== undefined  && this.state.moodStats !== undefined &&  this.state.moodStats[insightsType].currentWeek.weekdaySummary[weekdayIndexStartingSat] !== undefined){
              
                //console.log('smiley', insightsType+dayKey+weekdayIndexStartingSat)
                var level = Number(this.state.moodStats[insightsType].currentWeek.weekdaySummary[weekdayIndexStartingSat].meanMeasurement);
              if(isNaN(level) || level ==="-" || level === undefined){
                level = 0;
              }
              else{
                level = Math.round(level);
              }
              var returnIcon = <RadioButtonUncheckedIcon />
              switch(level) {
                case 5: returnIcon = <SentimentVerySatisfiedIcon style={{color: theme.palette.themeAlertGreen}}/>; break;
                case 4: returnIcon = <SentimentSatisfiedAltIcon />; break;
                case 3: returnIcon = <SentimentSatisfiedIcon />; break;
                case 2: returnIcon = <SentimentDissatisfiedIcon style={{color: theme.palette.themeAlertOrange}}/>; break;
                case 1: returnIcon = <SentimentVeryDissatisfiedIcon style={{color: theme.palette.themeAlertRed}}/>; break;
                default:  returnIcon = <RadioButtonUncheckedIcon style={{color: "transparent"}} />
              }
              if(insightsType==="insightsPriming"){
                return(
                  <Grid container spacing={2}  alignItems="center" key={insightsType+dayKey+weekdayIndexStartingSat}>
                    <Grid item>
                      {returnIcon}
                    </Grid>
                    <Grid item xs key={insightsType+dayKey+weekdayIndexStartingSat}>
                        {level>=1
                        &&
                            <span>
                                <ColorLinearProgress variant="determinate" value={(5-level)  /5*100}  />
                            </span> 
                        }
                        {/****
                        level>=1 &&
                            
                            <StyledSliderLeft  
                                valueLabelDisplay="off"
                                aria-labelledby={"discrete-slider_priming"+insightsType+dayKey+weekdayIndexStartingSat}
                                track="inverted"
                                color={level===0 ? "primary" :"secondary"}
                                key={insightsType+dayKey+weekdayIndexStartingSat}
                                onChangeCommitted={() => {return true}}
                                onChange={() => {return true}}
                                value={(5-level)  /5*100}
                                marks={[{
                                    //label : "Average: " + this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement,
                                    value : (5- (Number(this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement) || Number(this.state.moodStats[insightsType].last3Months.measureMean)))  /5*100
                                    },
                                    //{
                                    //label : "Average: " + this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement,
                                    //value : (5- (Number(this.state.moodStats[insightsType].last3Months.measureMean) || 0))  /5*100
                                    // } 
                                ]}
                                />
                        */}
                    </Grid>
                  </Grid>
                )
              }
              else{
                return(
                  <Grid container spacing={2}  alignItems="center" key={insightsType+dayKey+weekdayIndexStartingSat}>
                    <Grid item xs key={insightsType+dayKey+weekdayIndexStartingSat}>
                        {level>=1
                        &&
                            <span>
                                <LinearProgress variant="determinate" value={(level)  /5*100} color="secondary" />
                            </span> 
                        }
                        {/*
                            level>=1  &&
                                <StyledSliderRight 
                                    valueLabelDisplay="off"
                                    aria-labelledby={"discrete-slider_reflection"+insightsType+dayKey+weekdayIndexStartingSat}
                                    color={level===0 ? "primary" :"secondary"}
                                    key={insightsType+dayKey+weekdayIndexStartingSat}
                                    value={level /5*100}
                                    marks={[{
                                        //label : "Average: " + this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement,
                                        value : (Number(this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement) || Number(this.state.moodStats[insightsType].last3Months.measureMean))  /5*100
                                    },
                                    //{
                                        //label : "Average: " + this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement,
                                        //value : (Number(this.state.moodStats[insightsType].last3Months.measureMean) || 0)  /5*100
                                    //}
                                    ]}
                                />
                        */}
                    </Grid>
                    <Grid item>
                      {returnIcon}
                    </Grid>
                  </Grid>
                )
              }
                //<LinearProgress variant="determinate" color="secondary"
                //  value={(Number(this.state.moodStats[insightsType].currentWeek.weekdaySummary[weekdayIndexStartingSat].meanMeasurement) || 0)  /5*100} 
                //  valueBuffer={Number(this.state.moodStats[insightsType].last3Months.weekdaySummary[weekdayIndexStartingSat].meanMeasurement) || 0  /5*100}  />
              
            }
            else{
              return("")
            }

        }
        catch(error){
            return("")
        }
    }

    loadMoodStats = () => {
        if(this.props.date !== undefined ){
            //if(this._isMounted){ this.setState({isVisible:true})}
            this.setState({isVisible:true})
            ///// write into cache parameter set and Loading ///////////////////
            let loadMoodStats = {};
            var cacheStore = DataCacheRead('loadMoodStats_'+this.props.date+'_'+this.context.uid)
            if(cacheStore.cacheAvailable && cacheStore.cacheData !== undefined ) {
                loadMoodStats = cacheStore.cacheData;
                //console.log( loadMoodStats,'got cahce')
                this.setState({moodStats: loadMoodStats, isVisible:true});
            }
            //console.log('cahce', cacheStore)
            //////////////////////////////////////////////////////////////////
            if(cacheStore === undefined || cacheStore.isCacheExpired){
                this.props.firebase.insightsArchive(this.context.uid, this.props.date).get()
                .then(statsData => {
                    if(statsData.data() !== undefined ){
                        //console.log( statsData.data() ,'got thekey')
                        this.setState({moodStats: statsData.data()})
                        ///// write into cache parameter set and Loading ///////////////////
                        DataCacheWrite('loadMoodStats_'+this.props.date+'_'+this.context.uid, statsData.data(), 360)
                    }
                })
            }
        }
    }
}

JournalWeek.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(JournalWeek);
