//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        "&&:hover": {
            backgroundColor: palette.themeGold
        },
        "&&:focus": {
            backgroundColor: "transparent"
        }
    }
}}

export default muiStyle;