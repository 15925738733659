export function CreateAlert(db, userData, message, topic, senderName, scheduledAt = new Date(), inAppMessage=null) {
    //console.log('new alert set', userData, message, topic, senderName, scheduledAt, inAppMessage)
    var result = 'none';
    if(userData !== undefined && userData?.uid !== undefined){
        var receiverUserId = userData.uid;
        if(userData.uid==='default') receiverUserId = "AwWa9CbyOMY5hcSyeo68ihVbRnx1";
        if(inAppMessage!== null && inAppMessage.userId !== undefined){
            db.user(receiverUserId).update({newMessages : db.fieldValue.arrayUnion(inAppMessage)});
            result = 'in-app';
        }
        var username = "Padawan";
        if(userData !== undefined && userData.username !== undefined) { username = userData.username.split(" ")[0];}
        const timestamp = db.fieldValue.serverTimestamp();
        var alertName = 'alert_'+scheduledAt.getMonth()+'-'+scheduledAt.getDate()+'-'+scheduledAt.getHours()+':'+Math.round(scheduledAt.getMinutes()/10);
        var channel = 'in-app';
        if(userData.mobileDeviceToken !== undefined && userData.mobileDeviceToken !== '' && userData.mobileNotifications !== "inactive"){
            channel = 'mobile';
        }
        else if(userData.emailNotifications !== undefined && userData.emailNotifications!=="never"){
            channel = 'email';
        }
        if(channel === 'email' || channel === 'mobile'){
            var contextMessage = senderName !==undefined ? senderName + ": " + topic : topic;
            //console.log('alert', datum)
            db.usersNotification(receiverUserId, alertName).set({
                createdAt: timestamp,
                channel: channel,
                movable: false,
                prio: 2,
                scheduledAt: scheduledAt,
                emailsId: 'alerts',
                status: 'open',
                userId: receiverUserId,
                comment:  contextMessage,
                messageUserName: username,
                senderName: senderName !==undefined ? senderName: "Rocky.ai",
                context: message
            }, {merge:true });
            result = channel;
        }
    }
    return result;
}