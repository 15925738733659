import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { AddAnalytics } from '../Modules/AddAnalytics';
import { FindTextValueInDict, FindDictByKey, ReturnFilteredDict } from '../Modules/LoadCategoriesAndQualities';
import { BestForProfile } from '../Profile/RoleOverview';

import {SetStateLoadCategoriesAndQualities} from '../Modules/LoadCategoriesAndQualities';
import LinearProgress from '@material-ui/core/LinearProgress';

import { SetNewQuality } from '../Goals/TopicSuggestions';

import AvatarImage from '../Modules/AvatarImage';
import {AvatarCard} from '../Modules/AvatarImage';
import {freeTopics, CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';
import {RenderMediaYoutube} from  '../Content/RenderMedia';

/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
//import {AvatarFoto, AvatarCard} from '../Modules/AvatarImage';
// replaced AvatImage Module
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AppRating from '../Modules/AppRating';
import DialogSettingPlan from '../Profile/DialogSettingPlan';
import TopicSuggestions from '../Goals/TopicSuggestions';

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//import CardActions from '@material-ui/core/CardActions';
//import CardHeader from '@material-ui/core/CardHeader';
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

///// Classical List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

//// Grid List
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
//import ListSubheader from '@material-ui/core/ListSubheader';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import SwipeableViews from 'react-swipeable-views';
import SwipeStepper, {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';

//button for next
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
//import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PublicIcon from '@material-ui/icons/Public';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import IconButton from '@material-ui/core/IconButton';
// collapse details
//import ExpandLess from '@material-ui/icons/ExpandLess';
//import ExpandMore from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    listItem: {
        boxShadow: 1,
        border: '0px',
        borderRadius: theme.borderRadius,
    },
    listItemPremium: {
        boxShadow: 1,
        border: '0px',
        height: '25vh',
        borderRadius: theme.borderRadius,
    },
    innerHTML: theme.innerHTML
});

class LeadershipQualityPicker extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.subscriptionRef = React.createRef();
        this.dialogRef = React.createRef();
        this.topicRef = React.createRef();

        var currentLeadershipQuality = '';
        if(this.props.user!==undefined && this.props.user.currentLeadershipQuality!== undefined){
            currentLeadershipQuality = this.props.user.currentLeadershipQuality;
            if(currentLeadershipQuality === "") currentLeadershipQuality = "open_mind";
        }
        var chosenCategory = this.props.chosenCategory;
        if(this.props.chosenCategory === undefined || this.props.chosenCategory === undefined ){
            if(this.context !== undefined){
                chosenCategory = this.context.currentCategory;
            }
        }
        ////// add user role filter for content selection
        var userRoleFilter = 'default';
        if(this.props.user?.onboardingResponses?.length > 0){
            /// 'executive', 'professional', 'seeker', 'student']}
            if(this.props.user?.onboardingResponses?.includes('Student'))
                userRoleFilter = 'student';
            if(this.props.user?.onboardingResponses?.includes('Teenager'))
                userRoleFilter = 'teenager';
            if(this.props.user?.onboardingResponses?.includes('Available'))
                userRoleFilter = 'seeker';
            if(this.props.user?.onboardingResponses?.includes('Professional'))
                userRoleFilter = 'professional';
            if(this.props.user?.onboardingResponses?.includes('Executive'))
                userRoleFilter = 'executive';
        }
        //console.log('reopened')
        this.state = {
            userRoleFilter,
            loading: false,
            todaysLeadershipQuality: "",
            qualityExpended: '',
            qualityExpendedText: '',
            chosenCategory,
            currentLeadershipQuality: currentLeadershipQuality,
            leadershipCategories: this.props.leadershipCategories,
            leadershipQualities: this.props.leadershipQualities
        };
    }

    componentDidMount() {
        this._isMounted = true;
        SetStateLoadCategoriesAndQualities(this, this.props.firebase, false, false, this.context.programs);
        var chosenCategory = this.props.chosenCategory;
        //console.log('LQP state', chosenCategory, this.state)
        if(this.props.chosenCategory === undefined || this.props.chosenCategory === '' ){
            if(this.context !== undefined){
                if(this.context.programs === undefined || this.context.programs.length === 0){
                    //console.log('user has no programs, adding default')
                    this.props.firebase.user(this.context.uid)
                    .update({
                        programs: ['default']
                    });
                }
                chosenCategory = this.context.currentCategory;
                //console.log('pre loaded c ar', this.props.chosenCategory, this.props)
                if(this.props.openChooseTopic2){
                    //console.log('pre2 ndsc ar', this.props.chosenCategory, this.context)
                    this.handleSubmitCategory(chosenCategory);
                }
            }
        }
        this.checkNonSubscriber();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    checkNonSubscriber( newCategoryChanged = undefined ){
        var nonSubscriber = false;
        var premiumSubscriber = false;
        var trialSubscriber = false;
        //console.log('opening it')
        if((this.state.nonSubscriber === false && this.state.premiumSubscriber === true) || this.props.premiumAccess){
            nonSubscriber = false;
            //console.log('already checkedx premium subscription')
            if(this._isMounted){
                //console.log('subscription', 'add non sub ceiber')
                this.setState({nonSubscriber});
            }
            return nonSubscriber;
        }
        else{
            var program = '';
            if(newCategoryChanged!== undefined){
                program = newCategoryChanged.program;
            }
            if(program === '' && this.state.previewCategory !== undefined) {
                program = this.state.previewCategory.program;
                newCategoryChanged = this.state.previewCategory;
            }
            //console.log('start check program', program, newCategoryChanged, this.state.previewCategory)
            let subscriptionStatus = CheckSubscriptionStatus(this.props.user, program);
            //console.log('subscription check', subscriptionStatus, program)
            if(subscriptionStatus.nonSubscriber || subscriptionStatus.nonSubscriber === false){
                nonSubscriber = subscriptionStatus.nonSubscriber;
                premiumSubscriber = subscriptionStatus.premiumSubscriber;
                trialSubscriber = subscriptionStatus.trialPeriod;
                if(premiumSubscriber){
                    nonSubscriber = false;
                    trialSubscriber = true;
                }
                else if((subscriptionStatus.premiumContentAccess === true && trialSubscriber)){
                    //console.log('trial and premium')
                    nonSubscriber = false;
                    trialSubscriber = true;
                }
                else if(this.state.previewCategory !== undefined && newCategoryChanged.premium === true){
                    //console.log('no due to non premium')
                    nonSubscriber = true;
                    if(subscriptionStatus.premiumContentAccess && trialSubscriber){
                        //console.log('trial premium as included')
                        nonSubscriber = false;
                        trialSubscriber = true;
                    }
                    else if(trialSubscriber){
                        nonSubscriber = false;
                        trialSubscriber = true;
                    }
                    else{
                        trialSubscriber = false;
                    }
                }
                else if(subscriptionStatus.trialPeriod === true){
                    //console.log('trial for defual')
                    trialSubscriber = true;
                }
                else {
                    //console.log('no optionm - nonSubscriber', nonSubscriber)
                }
            }
            //console.log('nonSub prem trial', nonSubscriber, premiumSubscriber, trialSubscriber)
            if(this._isMounted){
                //console.log('subscription', 'add non sub ceiber', nonSubscriber, premiumSubscriber)
                this.setState({nonSubscriber, premiumSubscriber, trialSubscriber});
            }
            return nonSubscriber;
        }
    }

    handleSubmit = (quality, qualityFullText="", activateAllModules=false) => {
        let category = this.state.chosenCategory;
        var allQualities = [];
        if(activateAllModules){
            if(quality?.length > 0 && this.state.overwriteQualityPlus === quality){
                allQualities = FindTextValueInDict(this.props.firebase, this.state.leadershipQualities, quality, 'sisterQualities');
                //console.log('sisterquelau', quality, allQualities)
            }
            else{
                var allQualitiesLoaded = this.state.leadershipQualities?.filter(q => (q.category === category || q.categories?.includes(this.state.chosenCategory)));
                allQualitiesLoaded.forEach(qualityDict => {
                    allQualities.push(qualityDict.value);
                })
            }
            if(quality === undefined || quality === ""){
                quality = allQualities[0];
            }
        }
        var nonSubscriber = this.checkNonSubscriber();
        var userPrograms = this.context.programs;
        //console.log('handle submit to', category, quality, allQualities, nonSubscriber, qualityFullText, activateAllModules)
        if(freeTopics.includes(quality) || !nonSubscriber || quality === "" ){
            //console.log('prop for scope to write', this.state)
            if(this.state.previewCategory !== undefined && this.state.previewCategory.program !== undefined  && this.state.previewCategory.program !== ""){
                let selectedProgram = this.state.previewCategory.program;
                if(userPrograms!== undefined && !userPrograms.includes(selectedProgram)){
                    userPrograms.push(selectedProgram);
                    //console.log('programs', userPrograms)
                }
            }
            if ((this.props.scope === 'current' || this.props.scope === undefined) && quality!=="" ) {
                SetNewQuality(this.props, quality, category, qualityFullText, allQualities);
                this.props.firebase.user(this.context.uid).update({
                    programs: userPrograms,
                    //todaysLeadershipQuality: quality
                });
                this.props.firebase.userCollection(this.context.uid, 'topics').add({
                    programs: userPrograms,
                    todaysLeadershipQuality: quality,
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                    origin : 'catalogue'
                });
                if(this.props.submitNewPath !== undefined){
                    this.props.submitNewPath(category, quality)
                }
                if(this._isMounted) this.setState({currentLeadershipQuality : quality}); 
            } 
            else if(this.props.scope !== 'current') {
                if(userPrograms!==undefined && userPrograms.length>0){
                    this.props.firebase.user(this.context.uid).update({
                        programs: userPrograms,
                        todaysLeadershipQuality: quality
                    });
                    this.props.firebase.userCollection(this.context.uid, 'topics').add({
                        programs: userPrograms,
                        todaysLeadershipQuality: quality,
                        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                        origin : 'catalogue'
                    });
                }
                else{
                    this.props.firebase.user(this.context.uid).update({
                        todaysLeadershipQuality: quality
                    });
                    this.props.firebase.userCollection(this.context.uid, 'topics').add({
                        todaysLeadershipQuality: quality,
                        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                        origin : 'catalogue'
                    });
                }
                if(this._isMounted) this.setState({todaysLeadershipQuality : quality}); 
            }
            AddAnalytics('goal_completion', 'GoalChangeTopic');
            this.props.handlerCloseAll();
            if(this.props.closeDialog) this.props.closeDialog();
        }
        else if(nonSubscriber){
            //console.log('scroll to')
            if(this.subscriptionRef!==undefined && this.subscriptionRef!==null && this.subscriptionRef.current!==undefined && this.subscriptionRef.current!==null ) {
                //console.log('scroll to checked')
                this.subscriptionRef.current.scrollIntoView({behaviour: 'smooth', block: 'start' });
                setTimeout(() => {
                    //console.log('scroll to time d')
                    this.subscriptionRef.current.scrollIntoView({behaviour: 'smooth', block: 'start' });
                }, 300);
                this.setState({unlockAllButton: true})
            }
            AddAnalytics('goal_completion', 'GoalChangeTopic');
        }
        else if(this._isMounted){
            //this.setState({nonSubscriber : true})
        }
    }

    scrollIntoDialogRef = () => {
        //console.log('scrollref', this.dialogRef)
        //if(this.dialogRef!==undefined && this.dialogRef!==null && this.dialogRef.scrollTop!==undefined ) {
            //this.dialogRef.scrollTop = 0;
        //}
        if(this.dialogRef!==undefined && this.dialogRef!==null && this.dialogRef.current!==undefined && this.dialogRef.current!==null ) {
            //console.log('scrollref2', this.dialogRef)
            this.dialogRef.current.scrollIntoView({behaviour: 'smooth', block: 'start' });
        }
    }

    handleSubmitCategory = (category) => {
        if(category !== undefined){
            this.setState({overwriteQualityPlus : undefined});
            try{
                this.scrollIntoDialogRef();
                var previewCategory = this.state.previewCategory || {};
                //console.log('2.', previewCategory)
                if(this.state.leadershipCategories !== undefined ){
                    previewCategory = this.state.leadershipCategories.find(c => c.value === category)
                    //console.log('1.', previewCategory)
                }
                //console.log('previewCategory', previewCategory)
                if(previewCategory !== undefined && previewCategory.text !== undefined){
                    //console.log('MMMMM previewCategory', previewCategory)
                    if(this._isMounted){ 
                        this.setState({previewCategory, chosenCategory: category})
                        //console.log('load init submit')
                        this.loadInitialTopic(previewCategory);
                    }
                    if(previewCategory.text !== undefined && previewCategory.text !== ""){
                        //console.log('update new modules') 
                        ExtendDialogueMessage(this.props.firebase, this.context, previewCategory.text, "What is a skill or subject that you are intrested in?", 'utter_ask_help', '', 'profile');
                    }
                }
                else{
                    if(this._isMounted) {
                        this.setState({previewCategory:{}})
                        //console.log('YYYYYYYY reset category')
                    }
                }
                this.props.handlerOpen2(category);
                this.checkNonSubscriber(previewCategory);
            }
            catch(e){
                console.log('failure with load category', e)
            }
        }
        else{
            console.log('send empty category')
        }
    }
    handleSubmitQualityPlus = (quality, motherCategory) => {
        var category = motherCategory;
        var sisterQualities = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, quality, 'sisterQualities');
        this.handleSubmitCategory(category);
        this.setState({overwriteQualityPlus : quality, sisterQualities, qualityExpended: quality});
        //console.log('handleSubmitQualityPlus = (',quality, motherCategory, sisterQualities)
    }
    handleCatExpandClick = (entry, entryText="") => {
        if(entryText===""){
            entryText = entry;
        }
        if(this._isMounted) {
            this.setState({
                qualityExpended: entry,
                qualityExpendedText: entryText
            });
        }
    };
    handleCatCollapsClick = (entry) =>{
        if(entry === this.state.qualityExpended && this._isMounted){
            //this.setState({qualityExpended: 'no_quality_selected' });
        }
    };

    handleCloseTopicView = () => {
        if(this._isMounted && this.props.handlerClose2 !== undefined){
            //this.setState({qualityExpended: 'no_quality_selected', nonSubscriber : false});
            this.props.handlerClose2();
        }
    }

    handleProps = () => {
        if(this.props.handler !== undefined){
            //console.log('choose special handerl handlers')
            this.props.handler();
        }
        else{
            //console.log('no handlers')
            if(this.props.handlerClose !== undefined){
                this.props.handlerClose();
            }
            if(this.props.handlerCloseAll !== undefined){
                this.props.handlerCloseAll();
            }
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('updates prev', this.props)
        var previewCategory = this.state.previewCategory || {};
        //console.log('3.', previewCategory)
        if(this.props.chosenCategory !== undefined){
            if(this.props.preselectQuality!==undefined && this.props.preselectQuality!==prevProps.preselectQuality){
                var preselectCategory = undefined;
                //console.log('quality chooser', this.props.chosenCategory)
                if(this.state.leadershipCategories!==undefined ){
                    preselectCategory = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.props.preselectQuality, 'category');
                    //preselectCategory = this.props.leadershipQualities.find(c => c.value === this.props.preselectQuality).category;
                    previewCategory = this.props.leadershipCategories.find(c => c.value === preselectCategory);
                    //console.log('4.', previewCategory)
                    if(this._isMounted && this.props.preselectQuality !== "" ){
                        //console.log("OOOOOOOOO inside",  preselectCategory,'cat', previewCategory, 'qual', this.props.preselectQuality);
                        this.setState({
                            currentLeadershipQuality: this.props.preselectQuality, 
                            qualityExpended: this.props.preselectQuality,
                            previewCategory,
                            overwriteQualityPlus : undefined,
                            chosenCategory: preselectCategory
                        }) 
                    }
                }
                else if(this._isMounted && this.props.preselectQuality!== "") {
                    this.setState({currentLeadershipQuality: this.props.preselectQuality, qualityExpended: this.props.preselectQuality}) 
                    //console.log("preview quality updated",  this.props.preselectQuality, prevProps.preselectQuality, this.props.chosenCategory, previewCategory)
                }
                this.checkNonSubscriber();
            }
            else if(this.props.openChooseTopic2 === true && prevProps.openChooseTopic2 !== true && this.state.previewCategory!==undefined){
                //console.log('open chosoe topiuc2', this.state.previewCategory)
                //console.log('load init chosoe t')
                this.loadInitialTopic(this.state.previewCategory);
                this.checkNonSubscriber();
            }
            if(this.props.openChooseTopic2 === true && prevProps.openChooseTopic2 !== true && this.props.chosenCategory !== undefined) {
                //console.log(prevProps, this.props)
                var newValues = '';
                var oldValues = '';
                if(this.props.chosenCategory !== undefined && this.props.chosenCategory !== ""){
                    newValues = this.props.chosenCategory
                }
                if(prevProps.chosenCategory !== undefined ){
                    oldValues = prevProps.chosenCategory;
                }
                //console.log('chosenCatccccccegory', this.props.chosenCategory, newValues, oldValues)
                if(((newValues !== oldValues && newValues !== '') || (this.state.previewCategory === undefined && newValues !== ''))
                    && this._isMounted) {
                    previewCategory = this.state.previewCategory || {};
                    //console.log('5.', previewCategory, this.props.chosenCategory)
                    if(this.props.chosenCategory !== previewCategory.value){
                        try{
                            if(this.state.leadershipCategories!==undefined ){
                                previewCategory = this.state.leadershipCategories.find(c => c.value === this.props.chosenCategory);
                                //console.log('6.', previewCategory)
                            }
                        }
                        catch(e){
                        }
                        this.setState({
                            chosenCategory: this.props.chosenCategory,
                            previewCategory: previewCategory,
                            overwriteQualityPlus : undefined
                        })
                        //console.log('Xxxxxxxxxxx')
                        this.scrollIntoDialogRef();
                        //console.log('load init update')
                        this.loadInitialTopic(previewCategory);
                        //console.log('chosenCategory', this.props.chosenCategory)
                        this.checkNonSubscriber(previewCategory);
                    }
                }
            }
        }
        if(this.state.previewCategory === undefined){
            //console.log('updates prev after', this.state.previewCategory)
        }
        if((this.props.openChooseTopic1 || this.state.openChooseTopic2) && !this.state.openedFirst){
            this.setState({openedFirst : true});
            SetStateLoadCategoriesAndQualities(this, this.props.firebase, false, true, this.context.programs);
            AddAnalytics('screen_view', 'PagePrograms');
        }
        return true
    }

    renderChildren (entry, indexEntry){
        const {theme} = this.props;
        const {engagementStats} = this.props;
        const {previewCategory} = this.state;
        return(
            <Grid item key={entry.value} xs={12} >
                <Card key={entry.value} 
                    style={{
                        ...theme.CardDialogueButtonFrame,
                        backgroundColor : entry.value === this.state.currentLeadershipQuality ? theme.palette.themeGold : theme.CardGoals.backgroundColor
                    }}
                    >
                    <List style={{padding:0, margin:0}} >
                        {/** <ListItem style={{width: '100%'}} button onClick= {this.state.qualityExpended !== entry.value ?() => this.handleCatExpandClick(entry.value, entry.text) : () =>  this.handleSubmit(entry.value, entry.text)} > **/}
                        <ListItem 
                            style={{width: '100%', margin:0, paddingLeft:10}} 
                            button 
                            onClick= {this.state.qualityExpended !== entry.value 
                                ?   () => {
                                        this.handleCatExpandClick(entry.value, entry.text);
                                        if(indexEntry === 0 && this.topicRef!== undefined){
                                            this.topicRef.current.scrollIntoView({behaviour: 'smooth', block: 'start' });
                                        }
                                    }
                                :   () => {
                                        this.handleCatCollapsClick(entry.value);
                                        if(indexEntry === 0 && this.topicRef!== undefined){
                                            this.topicRef.current.scrollIntoView({behaviour: 'smooth', block: 'start' });
                                        }
                                    }
                            } 
                        >
                            {entry.icon!== undefined && entry.icon!== ""
                                ?
                                    <AvatarImage type={'imageIcon'} image={entry.icon} item={entry.value} squareSize='11vh' backgroundColor={entry.backgroundColor} />  
                                :
                                    <AvatarImage type='quality' item={entry.value} squareSize='11vh' backgroundColor={entry.backgroundColor} />  
                            }
                            <ListItemText 
                                style={{paddingLeft: 15, paddingTop: 0}}
                                primary={
                                    <Typography style={{ color: entry.value === this.state.currentLeadershipQuality ? theme.palette.black : theme.palette.themeText}}>
                                        {previewCategory !== undefined && previewCategory.premium 
                                            ?   <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color: entry.value === this.state.currentLeadershipQuality ? theme.palette.main : theme.palette.themeGold}} />
                                            :   (entry === undefined || entry.value === undefined) || (this.state.nonSubscriber === false && this.state.trialSubscriber!==true)
                                                ?   ''
                                                    :   freeTopics.includes(entry.value)
                                                        ?   <span style={{fontSize:'x-small', color:theme.palette.secondary.light}}>FREE &nbsp;</span>
                                                        :   <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeBlue}} />
                                        }
                                        {entry.value === this.state.currentLeadershipQuality ? entry.text + ' (active)': entry.text }
                                    </Typography>
                                }
                                secondary=
                                    {<Typography style={{ color: this.state.qualityExpended !== entry.value ? theme.palette.secondary.main : theme.palette.themeText, width:'95%' }}>
                                        {entry.explanationShort}
                                    </Typography>}
                            />
                            {this.state.qualityExpended === entry.value ? '' : <SearchIcon style={{color: theme.palette.secondary.main}}/>}
                        </ListItem>
                    </List>
                    <Collapse style={{width: '100%'}} in={this.state.qualityExpended === entry.value} key={entry.value} timeout="auto" unmountOnExit>
                        <CardContent 
                            //onClick= {this.state.currentLeadershipQuality !== entry.value ? () =>   this.handleSubmit(entry.value, entry.text) : () =>  this.handleCatCollapsClick(entry.value)} 
                            onClick= { () => this.handleSubmit(entry.value, entry.text)} 
                            >
                                <Grid container spacing={2}  >
                                    {engagementStats?.['leadership_quality']?.[entry.value]?.["points"] > 0
                                        && 
                                            <Grid item xs={12}  style={{width: '100%', textAlign: 'right', fontSize: 'small'}}> 
                                                <span style={{width: '100%', textAlign: 'right', fontSize: 'small'}}>
                                                    {engagementStats?.['leadership_quality']?.[entry.value]?.["points"] > 0
                                                        &&
                                                        " (" + 
                                                            Math.min(Math.round(engagementStats['leadership_quality'][entry.value]["points"] / this.props.engagementBenchmarkQuality * 100 ), 100)
                                                            + "% completed)"
                                                                    
                                                    }
                                                </span>
                                                <LinearProgress 
                                                    variant="determinate" 
                                                    style={{
                                                        width:'100%',
                                                        height: 5,
                                                    }}
                                                    value={
                                                        engagementStats?.['leadership_quality']?.[entry.value]?.["points"]
                                                            ?   Math.min((Math.round(engagementStats['leadership_quality'][entry.value]["points"] / this.props.engagementBenchmarkQuality * 100)), 100)
                                                            :   0
                                                        } 
                                                    color="secondary" 
                                                    />
                                            </Grid>
                                    }
                                    <Grid item xs={10}>
                                        <span style={{color: theme.palette.secondary.main }}>{entry.definition}</span>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <IconButton									
                                            //onClick={() => this.handleSubmit(entry.value, entry.text)}
                                            aria-label="Chooose the skill"
                                            style={{padding:0}}
                                            >
                                            <AddCircleOutlineIcon  fontSize="large" style={{ color: theme.palette.themeRed}} />
                                        </IconButton>
                                    </Grid>
                                </Grid>                                                                    
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
        )
    }

    renderMyProgramsTile(entry, index){
        const {theme} = this.props;
        //console.log('LQP rendermypro', entry, index)
        //const {engagementStats} = this.props;
        //const {previewCategory} = this.state;
        return(
            <GridListTile key={entry.value+'_'+index} 
                onClick={() => {this.handleSubmitCategory(entry.value)}}
                style={{
                    height:'20vh',
                }}
                >
                    <div
                        style={{
                            height: '100%',  backgroundColor: entry.backgroundColor, 
                            borderRadius: theme.borderRadius,
                            backgroundImage : 
                                entry.url !== undefined && entry.url !== '' 
                                    ?  'linear-gradient( to right, '+entry.backgroundColor+' 30%, transparent ), url("'+entry.url+'")'
                                    :   '',
                            backgroundPosition: 'right 40%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item xs style={{padding:10}}>
                                {entry.icon!==undefined && entry.icon!==''
                                    ?
                                    <AvatarImage key={entry.value} type='imageIcon' image={entry.icon} item={entry.value} squareSize='10vh' backgroundColor={(entry.coach_url !== undefined && entry.coach_url !== '' ) ? 'transparent' : entry.backgroundColor} />   
                                    :
                                    <AvatarImage key={entry.value} type='category' item={entry.value} squareSize='10vh' backgroundColor={entry.backgroundColor} />   
                                }
                            </Grid>
                            <Grid item xs> </Grid>
                        </Grid>
                    </div>
                <GridListTileBar
                    title={
                        <span>
                            {entry.premium &&
                                <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeGold}} />
                            }
                            {entry.text}
                        </span>
                        }
                    subtitle={<span>{entry.explanationShort}</span>}
                    style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%"}}
                    actionIcon={
                        <IconButton aria-label={`Choose ${entry.text}`} >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    }
                />
            </GridListTile>
        )
    }

    loadInitialTopic (categoryDict) {
        if(categoryDict !== undefined && this.state.overwriteQualityPlus === undefined){
            if(categoryDict.initialLevelQuality !== undefined && categoryDict.initialLevelQuality !== '' && this._isMounted){
                var preChosenTopic = categoryDict.initialLevelQuality;
                try{
                    var result = ReturnFilteredDict(this.props.firebase, this.state.leadershipQualities, preChosenTopic, 'value');
                    //var result = this.state.leadershipQualities.filter(q => q.value === preChosenTopic)
                    //console.log('result', result, preChosenTopic)
                    if(result !== undefined && result[0] !== undefined && result[0].active){
                        this.setState({
                            qualityExpended: preChosenTopic
                        })
                    }
                }
                catch (error){
                    console.log(error)
                }
                //console.log('open chosoe topiuc2', this.state.previewCategory.initialLevelQuality)
            }
        }
    }

    render() {
        const {theme, classes} = this.props;
        const {previewCategory} = this.state;
        //console.log("STATES", previewCategory, this.props, this.state)
        if(previewCategory === undefined){
            //console.log("STATES", previewCategory, this.props, this.state)
        }
        return (
            <div>
                <DialogTutorialPage 
                    handleOpen={this.state.handleOpenTutorial || false}
                    handleBadgeFamily = {'path'}
                    handleClose={() => {this.setState({handleOpenTutorial: false})}}
                />
                <RemoveScroll enabled={this.props.openChooseTopic1 || false}>
                    <Dialog
                        open={this.props.openChooseTopic1}
                        onClose={this.handleProps}
                        fullScreen={IsMobileDevice()}
                        PaperProps={{style:theme.dialogWindow}}
                        TransitionComponent={Transition}
                        maxWidth="sm"
                        >
                        <DialogTitle style={theme.dialogTitle} >
                            <Grid container spacing={0} direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton onClick={this.handleProps}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                    Choose a Category
                                    </span>
                                </Grid>
                                <Grid item>
                                    <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    {/***
                                    <span style={theme.dialogTitle.titleLink} onClick={this.handleProps}>
                                        Cancel
                                    </span>
                                     */}
                                </Grid>
                            </Grid>
                        </DialogTitle>

                        {this.props.openChooseTopic1 && this.props.user !== undefined 
                        ?
                            <DialogContent style={theme.dialogContentMain}
                                >
                                {!this.props.firstSessionDone
                                    ?
                                        <p style={theme.textSupportCenter}>
                                            Change your personal Coaching Plan by choosing a Category and a new main Quest.
                                        </p>
                                    :
                                        ""
                                }
                                {this.props.user.programs!== undefined && this.props.user.programs.length>=1 
                                    &&  <h4>My Categories:</h4>
                                }
                                <GridList cellHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                                    {this.state.leadershipCategories!==undefined && this.state.leadershipCategories.map((entry, index)=> (
                                        this.props.user?.programs?.includes(entry.program) 
                                        && entry.active 
                                        && (entry.program !== 'default' || (entry.program === 'default' && this.context.currentCategory === entry.value) )
                                            ?   this.renderMyProgramsTile(entry, index)
                                            :   ''
                                    ))}
                                </GridList>
                                <p>
                                    &nbsp;
                                </p>
                                <GridList cellHeight={'auto'} cols={1} spacing={10} style={{overflow: 'hidden'}}>
                                    {this.state.leadershipCategories!==undefined && this.state.leadershipCategories.map((entry, index)=> (
                                        this.context?.programs?.includes(entry.program) && entry.active && entry.program === 'default' 
                                        &&  ((this.context.hidePublicContent !== true || (this.context?.programs?.length === 1 && this.context?.programs[0] === 'default'))  &&
                                            (this.context?.programs?.includes('default') && 
                                            (!(this.props?.defaultSet?.programMinPrograms?.length > 0) || this.props.defaultSet?.programMinPrograms?.includes('default') ))
                                        
                                        )
                                            ?   this.premiumContentFinder(entry.value, true, true)
                                            :   ''
                                    ))}
                                </GridList>
                            </DialogContent>
                            :
                            <DialogContent> loading </DialogContent>
                        }
                        {this.props.removeTodaysOptions === true &&  
                            <DialogActions style={theme.dialogAction}>
                                <Grid container spacing={0} direction="row"
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item >
                                        <Button onClick={ () => {this.handleSubmit("", "")}}>
                                            Remove
                                        </Button>
                                    </Grid>
                                    <Grid item> </Grid>
                                    <Grid item> </Grid>
                                </Grid>
                            </DialogActions>
                        }
                    </Dialog>
                </RemoveScroll>  
	            <RemoveScroll enabled={this.props.openChooseTopic2 || false}> 
                    <Dialog
                        open={this.props.openChooseTopic2}
                        onClose={this.handleProps}
                        fullScreen={IsMobileDevice()}
                        PaperProps={{style:theme.dialogWindow}}
                        TransitionComponent={Transition}
                        >
                        <DialogTitle style={theme.dialogTitle} >
                            <Grid container spacing={0} direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item >
                                    <IconButton 
                                        onClick={
                                            this.props.handlerCloseIgnoreProgramsFlag === true
                                                ?   this.handleProps
                                                :   this.handleCloseTopicView
                                            }>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <span style={theme.dialogTitle.titleText}>
                                        Choose a Quest
                                    </span>
                                </Grid>
                                <Grid item>
                                    <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    {/** 
                                    <span style={theme.dialogTitle.titleLink} 
                                        onClick={
                                            this.props.handlerCloseIgnoreProgramsFlag === true
                                                ?   this.handleProps
                                                :   this.handleProps 
                                                    //  this.handleCloseTopicView
                                            }>
                                        Cancel
                                    </span>
                                    */}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        {(this.props.openChooseTopic2 && previewCategory !== undefined)
                        ?
                            <DialogContent style={theme.dialogContentMain}>

                                {  //this.state.leadershipCategories.find(c => c.value === this.state.chosenCategory)
                                    //this.state.nonSubscriber!== true &&
                                    previewCategory !== undefined && previewCategory.explanationShort !== undefined && previewCategory.definition !== undefined 
                                    ? (
                                        <div ref={this.dialogRef}>
                                            {previewCategory.icon!== undefined && previewCategory.icon!== ""
                                                ?
                                                    <AvatarCard key={this.state.chosenCategory} type='imageIcon' image={previewCategory.icon} textBlock={previewCategory.text} squareSize='20'  backgroundColor={previewCategory.backgroundColor} />
                                                :
                                                    <AvatarCard key={this.state.chosenCategory} dict={this.state.leadershipCategories} type='category' item={this.state.chosenCategory} squareSize='20' />
                                            }
                                            {previewCategory.coach_name !== undefined  && previewCategory.coach_name !== ''
                                                ?
                                                <Grid container spacing={2} direction='row' alignItems="center" justify="space-between" >
                                                    <Grid item xs={7}>
                                                        {previewCategory.premium  
                                                            ?
                                                                <p>
                                                                    <strong>
                                                                        <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeGold}} />
                                                                        {this.state.premiumSubscriber ? 'Exclusive' : 'Premium'}
                                                                    </strong>
                                                                    {this.state.trialSubscriber
                                                                        && 
                                                                        <span>&nbsp;(Trial)</span>
                                                                    }
                                                                </p>
                                                            :
                                                                <p>
                                                                    <strong>
                                                                        <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeBlue}} />
                                                                        Essentials
                                                                    </strong>
                                                                    {this.state.trialSubscriber
                                                                        && 
                                                                        <span>&nbsp;(Trial)</span>
                                                                    }
                                                                </p>
                                                        }
                                                        <p>
                                                            by <strong>{previewCategory.coach_name }</strong>
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        {previewCategory.url !== undefined  && previewCategory.url !== ''
                                                        &&
                                                            <div style={{
                                                                backgroundImage: `url(${previewCategory.url})`,
                                                                backgroundPosition: IsMobileDevice()? "30% center" : "30% center",
                                                                backgroundSize: "cover",
                                                                backgroundRepeat: "no-repeat",
                                                                borderRadius: theme.borderRadius,
                                                                width: '100%',
                                                                height: 150,
                                                            }}>
                                                                &nbsp;
                                                            </div>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                :
                                                <div>
                                                    {previewCategory.premium  
                                                        ?
                                                            <div style={{width:'100%', textAlign:'right'}}>
                                                                <strong>
                                                                    <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeGold}} />
                                                                    Premium
                                                                </strong>
                                                            </div>
                                                        :   (this.state.nonSubscriber || this.state.trialSubscriber)
                                                            ?
                                                                <div style={{width:'100%', textAlign:'right'}}>
                                                                    <strong>
                                                                        <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeBlue}} />
                                                                        Essentials
                                                                    </strong>
                                                                    {this.state.trialSubscriber
                                                                        && 
                                                                        <span>&nbsp;(Trial)</span>
                                                                    }
                                                                </div>
                                                            :   ''
                                                    }
                                                </div>
                                            }
                                            {!(this.props.defaultSet?.programMinPrograms?.includes(previewCategory.program) ) && this.context.currentCategory !== previewCategory.value
                                                && previewCategory.program !== 'default' && this.context?.programs?.length > 1 && this.context?.programs?.includes(previewCategory.program)
                                                &&
                                                <p style={{paddingTop: 20}}>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => {
                                                            //console.log(previewCategory)
                                                            this.props.firebase.user(this.context.uid)
                                                            .update({
                                                                programs: this.props.firebase.fieldValue.arrayRemove(previewCategory.program) 
                                                            });
                                                            this.handleCloseTopicView();
                                                        }}
                                                        >
                                                        Remove Program
                                                        <DeleteForeverIcon fontSize='small'/>
                                                    </Button>
                                                </p>
                                            }
                                            <h2 style={theme.CardContentItem.TextTitle}>
                                                {previewCategory.explanationShort}
                                                {
                                                //FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.state.chosenCategory, 'explanationShort')
                                                }
                                            </h2>
                                            {previewCategory.requirements !== undefined  && previewCategory.requirements.length > 0 && previewCategory.requirements !== ['default']
                                                &&
                                                <div style={theme.textSupport}>
                                                    {BestForProfile(previewCategory.requirements, this.props.defaultSet)}
                                                </div>
                                            }
                                            <div>
                                                <span style={theme.CardContentItem.TextSubTitle}>
                                                    {previewCategory.definition}
                                                    {
                                                    //FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.state.chosenCategory, 'definition')
                                                    }
                                                </span>
                                            </div>
                                            
                                        </div>
                                        )
                                    :
                                    ""
                                }
                                <p>&nbsp;</p>
                                <div ref = {this.topicRef} />
                                <h4 style={theme.textSupport}>Choose a Quest:</h4>
                                <Grid container spacing={2} >
                                    {//this.state.nonSubscriber!== true &&
                                    this.state.overwriteQualityPlus?.length > 2
                                    ?
                                        <Grid item xs={12}>
                                            {this.renderChildren(FindDictByKey(this.props.firebase, this.state.leadershipQualities, this.state.overwriteQualityPlus), 0)}
                                            {this.state.leadershipQualities?.filter(q => (this.state.sisterQualities?.includes(q.value))).map((entry, indexEntry) => {
                                                if (this.state.chosenCategory && entry.active) { 
                                                    if(entry?.url?.includes("http")){
                                                        return(
                                                            this.renderQualityCard(entry, 1 + indexEntry, "", true)
                                                        )
                                                    }
                                                    else  return (this.renderChildren(entry, 1 + indexEntry));			
                                                    }
                                                else {
                                                    return "";
                                                }
                                            })}
                                        </Grid>
                                    :
                                        <Grid item xs={12}>
                                        {this.state.leadershipQualities?.filter(q => (q.category === this.state.chosenCategory || q.categories?.includes(this.state.chosenCategory))).map((entry, indexEntry) => {
                                            if (this.state.chosenCategory && entry.active) {
                                                if(entry?.url?.includes("http")){
                                                    return(
                                                        this.renderQualityCard(entry, 1 + indexEntry, "", true)
                                                    )
                                                }
                                                else  return (this.renderChildren(entry, 1 + indexEntry));			
                                            }
                                            else {
                                                return "";
                                            }
                                        })}
                                        </Grid>
                                    }
                                </Grid>

                                <div style={{marginBottom: 100}}
                                    ref = {this.subscriptionRef}
                                >
                                    {this.state.nonSubscriber && this.state.trialSubscriber !== true
                                    ? 
                                        <>
                                            <h4 style={theme.textSupport}>
                                                Subscriptions:
                                            </h4>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                >
                                                <Grid item xs={12} style={{ minHeight: 200, textAlign: 'center' }}>
                                                    {previewCategory !== undefined && previewCategory.premium
                                                        ?
                                                            <p>
                                                                Please subscribe to a plan to get access to all <strong>PREMIUM</strong> modules.
                                                            </p>
                                                        :
                                                            <p>
                                                                Please subscribe to a plan to get access to this module.
                                                            </p>
                                                    }
                                                    <p>
                                                        <Button
                                                            onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                                            variant='contained'
                                                            style={{backgroundColor: theme.palette.themeRed}}
                                                            >
                                                            Unlock All
                                                        </Button>
                                                    </p>
                                                    {this.context.ratedApp!== true && window.CdvPurchase!== undefined &&
                                                        (previewCategory === undefined || (previewCategory !== undefined && previewCategory.premium !== true))
                                                        &&
                                                        <>
                                                            <p>
                                                                or
                                                            </p>
                                                            <p>
                                                                <AppRating 
                                                                    buttonText="Rate this app" 
                                                                    freeDialoguesRequest={true}  
                                                                    user={this.context} 
                                                                    db={this.props.firebase}
                                                                    purchaseSuccessHandler={() => {
                                                                        this.setState({nonSubscriber: false, premiumSubscriber: true})
                                                                        this.scrollIntoDialogRef();
                                                                    }}
                                                                    />
                                                            </p>
                                                        </>
                                                    }
                                                    <DialogSettingPlan 
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        user={this.context}
                                                        handleOpen={this.state.DialogSettingPlanOpen}
                                                        premiumOnly={previewCategory !== undefined && previewCategory.premium ? true : false}
                                                        purchaseSuccessFlag={() => {
                                                            this.setState({nonSubscriber: false, premiumSubscriber: true})
                                                        }}
                                                        purchaseSuccessHandler={() => {
                                                            this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true})
                                                            this.scrollIntoDialogRef();
                                                            if(this.props.purchaseSuccessHandler!== undefined){
                                                                this.props.purchaseSuccessHandler();
                                                            }
                                                        }}
                                                        handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                                                    />
                                                    {this.context.programs !== undefined && this.context.programs.includes('default') 
                                                        &&
                                                        ((this.context.ratedApp === true || (window.CdvPurchase === undefined && !(process !== undefined && process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER")))
                                                            || (previewCategory !== undefined && previewCategory.premium)
                                                        ) 
                                                        &&
                                                        <>
                                                            <p className={classes.thinking}>
                                                                or
                                                            </p>
                                                            <p className={classes.thinking}>
                                                                Choose a Free Quest
                                                            </p>
                                                            {previewCategory !== undefined && previewCategory.premium
                                                                ?
                                                                    <Button
                                                                        onClick={this.handleCloseTopicView}
                                                                        variant='contained'
                                                                        >
                                                                        Show more Quests
                                                                    </Button>
                                                                :
                                                                    <div>
                                                                        {this.state.leadershipCategories!==undefined && this.state.leadershipCategories!==null
                                                                            && this.state.leadershipQualities!==undefined && this.state.leadershipQualities!==null
                                                                            &&
                                                                            <TopicSuggestions 
                                                                                user={this.context}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                handlerCloseAll={ () => { if(this.props.handlerCloseAll !== undefined ) {
                                                                                    this.props.handlerCloseAll();
                                                                                    this.setState({nonSubscriber: false});
                                                                                    } }}
                                                                                nonSubscriber={this.state.nonSubscriber || true}
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities} 
                                                                                handlerContinue={this.props.handlerContinue || undefined}
                                                                                allwaysFree1={freeTopics[0]}
                                                                                allwaysFree2={freeTopics[1]}
                                                                            />
                                                                        }
                                                                    </div>
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </>
                                    :
                                        <div style={{textAlign: 'center'}}>
                                            <p>
                                                <strong style={theme.textSupport}>Would you be interested in this program and its modules?</strong>
                                            </p>
                                            <p>
                                                <Button
                                                    onClick={() => {this.handleSubmit(this.state.qualityExpended, this.state.qualityExpendedText, true)}}
                                                    variant='contained'
                                                    style={{backgroundColor: theme.palette.themeAlertGreen}}
                                                    >
                                                    Activate All Quests
                                                </Button>
                                            </p>
                                        </div>
                                }
                                </div>
                                {previewCategory?.coach_name !== undefined && previewCategory?.coach_name !== ''
                                    &&
                                    <div>
                                        <h2 style={theme.CardContentItem.TextTitle}>
                                            {previewCategory.coach_name }
                                        </h2>
                                    </div>
                                }
                                {(
                                    (previewCategory?.youtubeId!==undefined && previewCategory?.youtubeId!=='') 
                                    ||
                                    (previewCategory?.coach_url !== undefined && previewCategory?.coach_url !== '')
                                ) &&
                                    <div style={{
                                        marginTop: 20,
                                        marginRight: 0,
                                        marginLeft: -20,
                                        width: 'calc(100% + 40px)',
                                        padding: 4
                                    }}>
                                        {previewCategory.youtubeId!==undefined && previewCategory.youtubeId!==''
                                            ?
                                                <div key={previewCategory?.value || 'empty'}>
                                                    <RenderMediaYoutube
                                                        contentData = {{
                                                            youtubeId: previewCategory.youtubeId, 
                                                            title: (previewCategory.text || 'Intro'),
                                                            url: (previewCategory.coach_url !== undefined && previewCategory.coach_url !== ''  ? previewCategory.coach_url : undefined)
                                                        }}
                                                        enabled = {true}
                                                    />
                                                </div>
                                            :
                                                <img style={theme.dialogImage} src={previewCategory.coach_url} alt={'My Coach'} />
                                        }
                                    </div>
                                }
                                {//this.state.nonSubscriber!== true &&
                                    previewCategory !== undefined && previewCategory.explanationShort !== undefined && previewCategory.definition !== undefined 
                                    &&
                                    <div style={{marginTop:30, marginBottom:30}}>
                                        {previewCategory.coach_title !== undefined  && previewCategory.coach_title !== ''
                                            &&
                                            <p>
                                                <strong style={theme.CardContentItem.TextSubTitle}>
                                                    {previewCategory.coach_title }
                                                </strong>
                                            </p>
                                        }
                                        {previewCategory.coach_text !== undefined  && previewCategory.coach_text !== ''
                                            &&
                                            <div 
                                                className={classes.innerHTML}
                                                onClick = {(event) => {
                                                    try{
                                                        const link = event.target.getAttribute('href');
                                                        if(link !== undefined && link !== null && link !== ""){
                                                            event.preventDefault();
                                                            window.open(link, "_blank")
                                                        }
                                                    }
                                                    catch(e){
                                                        console.log('No Link', e)
                                                    }
                                                }}
                                                dangerouslySetInnerHTML={{ __html: previewCategory.coach_text}} />
                                        }
                                        {previewCategory.coach_contact !== undefined  && previewCategory.coach_contact !== ''
                                            &&
                                            <div style={{marginTop: 20}}>
                                                <span style={theme.CardContentItem.TextSubTitle}>
                                                    {previewCategory.coach_contact }&nbsp;
                                                    {previewCategory.coach_contact_url !== undefined  && previewCategory.coach_contact_url !== ''
                                                        &&  
                                                        //<a href={previewCategory.coach_contact_url} target="_blank" rel="noopener noreferrer"><PublicIcon/></a> 
                                                        <span style={{color: theme.palette.secondary.main}}
                                                            onClick={ () => window.open(previewCategory.coach_contact_url, "_blank")}>
                                                            &nbsp; <PublicIcon/>
                                                        </span>
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </div>
                                }

                                {(this.props.user?.programs?.includes('default') && this.props.user?.programs[0] === 'default' && (!(this.props?.defaultSet?.programMinPrograms?.length > 0) || this.props.defaultSet.programMinPrograms.includes('default') ))
                                    ?
                                        (previewCategory.motherCategory !== undefined && previewCategory.motherCategory !== '')
                                            ?   this.premiumContentFinder(previewCategory.motherCategory, true)
                                            :   this.premiumContentFinder(this.state.chosenCategory, true)
                                    :
                                        ''
                                }
                                <Grid item xs={12}>
                                    {!this.state.nonSubscriber && this.context.programs?.includes('default') &&
                                        <div style={{textAlign: 'center'}}>
                                            <p>
                                                <strong style={theme.textSupport}>Looking for other topics?</strong>
                                            </p>
                                            <p>
                                                <Button
                                                    onClick={this.handleCloseTopicView} 
                                                    variant='contained'
                                                    style={{backgroundColor: theme.palette.themeRed}}
                                                    >
                                                    Show more Quests
                                                </Button>
                                            </p>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12} style={{ minHeight: 200, textAlign: 'center' }}>
                                </Grid>
                            </DialogContent>
                        :
                            <DialogContent> loading </DialogContent>
                        }
                        <DialogActions style={theme.dialogAction}>
                            <Grid container spacing={0} direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item >
                                    <Button onClick={this.handleCloseTopicView} variant="contained">
                                        Show Categories
                                    </Button>
                                </Grid>
                                <Grid item>
                                </Grid>
                                <Grid item>
                                    { 
                                        //!this.state.nonSubscriber   && 
                                        ( (this.state.qualityExpended !== undefined  && this.state.qualityExpended !== '' && this.state.qualityExpended !== 'no_quality_selected' )
                                            ||  ( this.props.user!== undefined && this.state.chosenCategory === this.props.user.currentCategory && this.state.qualityExpended !== 'no_quality_selected'))
                                        ?
                                            this.state.unlockAllButton && !this.state.premiumSubscriber && this.state.trialSubscriber !== true
                                            ?
                                                <Button
                                                    onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                                    variant='contained'
                                                    style={{backgroundColor: theme.palette.themeRed}}
                                                    >
                                                    Unlock All
                                                </Button>
                                            :
                                                <Button variant="contained" onClick={() => {this.handleSubmit(this.state.qualityExpended, this.state.qualityExpendedText, true)}}>
                                                    Activate All Quests
                                                </Button>
                                        :
                                            ""
                                    }
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </RemoveScroll>  
	        </div>
        );
    }

    handleStepChange = (step, keyWord, motherCategory) => {
        //console.log(step, keyWord, 'handler')
        if(step !== undefined && keyWord !== undefined && this._isMounted) {
            this.setState({['activeStep_'+motherCategory+keyWord] : step});
        }
    };

    premiumContentFinder = (motherCategory='', flagSubtopic = false, flagDefaults = false) => {
        //const {theme} = this.props;
        //console.log('LQP premeiuContentFinder',motherCategory, "mother")
        var premiumItems = [];
        var key = "premiumContent_"+this.state.chosenCategory;
        this.state.leadershipCategories?.map((entryPremium) => {
            if(motherCategory === '' && (entryPremium?.motherCategory?.length > 1)){
                //console.log('empty')
            }
            else if(flagDefaults && entryPremium.program !== 'default'){
                //console.log('hide', entryPremium.program)
            }
            else if(!flagDefaults && entryPremium.value === this.state.chosenCategory){
                //console.log('choose categeory double', this.state.chosenCategory)
            }
            else if(this.context.hidePublicContent === true && !this.context.programs?.includes(entryPremium.program) && !this.context.programs?.includes('default')){
                //console.log('entryPremium.program' , entryPremium.program)
            }
            else if(this.state.userRoleFilter !== undefined && entryPremium.requirements?.length>0 && !entryPremium.requirements?.includes(this.state.userRoleFilter) && !(this.props.user?.programs?.includes(entryPremium.program))){
                //console.log('skip content', entryPremium.value)
            }
            else if(motherCategory?.length > 2){
                if(entryPremium.motherCategory === motherCategory && entryPremium.active && (entryPremium.premium || flagDefaults) && entryPremium.public 
                    //&& entryPremium.value !== this.state.chosenCategory
                    ){
                    //console.log("adding premium", entryPremium)
                    if(!entryPremium.premium)
                        premiumItems.unshift(entryPremium)
                    else
                        premiumItems.push(entryPremium)
                }
            }
            else if(entryPremium.active && (entryPremium.premium) && entryPremium.public){
                if(this.context.currentCategory === entryPremium.motherCategory)
                    premiumItems.unshift(entryPremium)
                else
                    premiumItems.push(entryPremium)
            }
            //console.log(motherCategory, premiumItems)
            return premiumItems
        })
        var topicsPlus = [];
        if (flagDefaults && motherCategory?.length > 2) {
            this.state.leadershipQualities?.map((entryPlus) => {
                if(entryPlus.active && entryPlus.asCategory && entryPlus.category === motherCategory){
                    topicsPlus.push(entryPlus)
                }
                return true
            })
        }
        if((topicsPlus?.length > 0 || premiumItems?.length > 0) && this.state.leadershipCategories!== undefined && this.context.programs!==undefined && this.context.programs.includes('default') ){
            return(
                <div style={{marginBottom:0}} key={("motherCategory_"+motherCategory)}>
                    {premiumItems.length>=0
                        &&  
                        <>  
                            {flagSubtopic
                                ?   <p><strong>
                                        {motherCategory?.length> 0 && flagDefaults
                                            ?   FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, motherCategory, 'text')
                                            :   'Related Programs:'
                                        }
                                    </strong></p>
                                :   <h4>{this.state.premiumSubscriber ? 'Other ' : 'Premium '} Programs:</h4>
                            }
                        </>
                    }
                    <SwipeableViews
                        key={key}
                        axis={'x'}
                        index={this.state['activeStep_'+motherCategory+key+topicsPlus.length]}
                        onChangeIndex={(indexPremium) => {
                            try{
                                this.handleStepChange(indexPremium, key, motherCategory)
                            }
                            catch (err) {console.log('Swipe Error')}
                        }}
                        enableMouseEvents = {this.state.allowRefresh === false? false : true}
                        disabled = {this.state.allowRefresh === false? true : false}
                        resistance = {true}
                        style={customWidthStyle('70vw', (IsMobileDevice()? '70vw' : '300px'), (premiumItems.length + topicsPlus.length))}
                        slideStyle={customWidthSlideStyle('70vW', (IsMobileDevice()? '70vw' : '300px'), (premiumItems.length + topicsPlus.length))}
                    >

                        {premiumItems.map((entryPremium, indexPremium)=> (
                            //!(this.props.user.programs.includes(entryPremium.program)) &&
                            entryPremium.active //&& entryPremium.premium
                            && entryPremium.public 
                            &&
                                this.renderPremiumCard(entryPremium, indexPremium)
                        ))}
                        {topicsPlus.map((entryPlus, indexPlus)=> (
                            entryPlus.active
                            &&
                                this.renderPremiumCard(entryPlus, (premiumItems.length + indexPlus), true, motherCategory)
                        ))}
                    </SwipeableViews>
                    <SwipeStepper
                        handlerSteps = {(step) => this.setState({ ['activeStep_'+motherCategory+key+topicsPlus.length] :step}) }
                        steps={ (premiumItems.length + topicsPlus.length) > 0 ? (premiumItems.length + topicsPlus.length) :  0}
                        activeStep = { this.state['activeStep_'+motherCategory+key+topicsPlus.length] || 0}
                    />
                </div>
            )
        }
    }

    renderPremiumCard (entryPremium, indexPremium, qualityAsCategory = false, motherCategory = undefined) {
        const {theme} = this.props;
        return(
            <Card key={entryPremium.value+'_'+indexPremium} 
                onClick={() => {
                    qualityAsCategory 
                        ?   this.handleSubmitQualityPlus(entryPremium.value, motherCategory)
                        :   this.handleSubmitCategory(entryPremium.value)
                    }} 
                style={theme.CardContentItem.rootSwipe}>
                    <GridList spacing={0} cols={1} rows={1} style={{height:'20vh', minHeight:180}}>
                        <GridListTile  style={{height: '20vh', minHeight:180}} >
                            {(entryPremium.coach_url || entryPremium.url)
                                ?
                                <CardMedia
                                    style={{...theme.CardContentItem.CardMedia, height: "100%"}}
                                    image={entryPremium.url?.length > 5 ? entryPremium.url : entryPremium.coach_url}
                                >
                                    <div style={{position:'absolute',
                                        top:10,
                                        left:10,
                                        color:entryPremium.backgroundColor}}
                                        >
                                        {entryPremium.premium ? "Premium" : "Essentials"}
                                    </div>
                                </CardMedia>
                                :
                                <CardMedia
                                    style={{...theme.CardContentItem.CardMedia, height: "100%", maxHeight:400, 
                                        background: 'linear-gradient(215deg, #FFFFFF 10%, '+entryPremium.backgroundColor+' 90%)', 
                                    }}
                                >
                                    <div style={{position:'absolute',
                                        top:10,
                                        left:10,
                                        color:entryPremium.backgroundColor}}
                                        >
                                        {entryPremium.premium ? "Premium" : "Essentials"}
                                    </div>
                                </CardMedia>
                            }
                            <GridListTileBar
                                title={
                                    <span>
                                        {entryPremium.premium &&
                                            <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeGold}} />
                                        }
                                        {
                                            entryPremium.text
                                        }
                                    </span>
                                    }
                                subtitle={
                                    <span>{entryPremium.coach_name !== undefined && entryPremium.coach_name !== '' 
                                    ?   entryPremium.coach_name
                                    :   entryPremium.explanationShort
                                    }</span>
                                }
                                style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px'}}
                                actionIcon={
                                    <IconButton aria-label={`Choose ${entryPremium.text}`} >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                }
                            />
                        </GridListTile>
                    </GridList>
            </Card>
        )
    }


    renderQualityCard = (quality, qid, categoryKey, showDetails=false) => {
        const {theme, engagementStats} = this.props;
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        if(quality.backgroundColor!== undefined && quality.backgroundColor!== "" ){
            secondColor = quality.backgroundColor;
        }
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        return(
            <Grid item key={qid} style={{marginTop: 20}}>
                <GridListTile key={qid} 
                    style={{
                        ...theme.cardJournalDayHighlight, 
                        height: showDetails ? 160: 200,
                        minWidth: showDetails ? '100%' : 200,
                        background: quality.url?.length>1
                            ?  'url("'+quality.url+'")'
                            :   background,
                        backgroundPosition: quality.url?.length>1 ? 'right 40%' : undefined,
                        backgroundRepeat: quality.url?.length>1 ? 'no-repeat' : undefined,
                        backgroundSize: quality.url?.length>1 ? 'cover' : undefined,
                        listStyleType: 'none'
                    }} 
                    onClick={this.state.qualityExpended !== quality.value 
                        ?   () => {
                                this.handleCatExpandClick(quality.value, quality.text);
                                if(qid === 0 && this.topicRef!== undefined){
                                    this.topicRef.current.scrollIntoView({behaviour: 'smooth', block: 'start' });
                                }
                            }
                        :   () => {
                                this.handleSubmit(quality.value, quality.text)
                            }
                    }
                >
                    <div style={{padding: 16}}>
                        {quality.icon!== undefined && quality.icon!== ""
                            ?
                                <AvatarImage squareSize={'8vh'} type={'imageIcon'} image={quality.icon} item={quality.value} backgroundColor={quality.backgroundColor} />  
                            :
                                <AvatarImage squareSize={'8vh'} type='quality' item={quality.value} backgroundColor={quality.backgroundColor} />  
                        }
                    </div>
                    <GridListTileBar
                        title={
                            <span>
                                {quality.text}
                            </span>
                            }
                        subtitle={<span>{quality.explanationShort}</span>}
                        style={{ borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px', maxHeight:"35%", background: "#000000B3"}}
                        actionIcon={
                            <IconButton aria-label={`Choose ${quality.text}`} >
                                {showDetails
                                    ?   
                                        this.state.qualityExpended === quality.value
                                            ?   <AddCircleOutlineIcon fontSize="large" style={{ color: theme.palette.themeRed}} />
                                            :   <SearchIcon fontSize="large" style={{ color: theme.palette.secondary.main}} />
                                    :   <AddCircleOutlineIcon />
                                }
                            </IconButton>
                        }
                    />
                </GridListTile>
                {showDetails &&
                    <Collapse style={{width: '100%'}} in={this.state.qualityExpended === quality.value} key={quality.value} timeout="auto" unmountOnExit>
                        <CardContent 
                            style={{
                                marginTop: -10,
                                paddingTop: 40,
                                marginBottom: 20,
                                marginRight: 10,
                                marginLeft: 10,
                                border: 1, 
                                borderColor: 'black',
                                borderStyle: 'solid',
                                borderBottomLeftRadius: theme.borderRadius,
                                borderBottomRightRadius: theme.borderRadius,
                                borderTop: 0, 
                            }}
                        >
                            <span style={{color: theme.palette.secondary.main }}>{quality.definition}</span>  
                            {engagementStats?.['leadership_quality']?.[quality.value]?.["points"] > 0
                                && 
                                    <Grid item xs={12}  style={{width: '100%', textAlign: 'right', fontSize: 'small', marginBottom: 10}}> 
                                        <span style={{width: '100%', textAlign: 'right', fontSize: 'small'}}>
                                            {(engagementStats?.['leadership_quality']?.[quality.value]?.["points"] / this.props.engagementBenchmarkQuality * 100) > (99)
                                                ?   "Pro Level"
                                                :   engagementStats?.['leadership_quality']?.[quality.value]?.["points"] > 0
                                                    ?
                                                        " (" + 
                                                        Math.min(Math.round(engagementStats['leadership_quality'][quality.value]["points"] / this.props.engagementBenchmarkQuality * 100 ), 100)
                                                        + "% completed)"
                                                    :   ""
                                            }
                                        </span>
                                        <LinearProgress 
                                            variant="determinate" 
                                            style={{
                                                width:'100%',
                                                height: 5,
                                            }}
                                            value={
                                                engagementStats?.['leadership_quality']?.[quality.value]?.["points"]
                                                    ?   Math.min((Math.round(engagementStats['leadership_quality'][quality.value]["points"] / this.props.engagementBenchmarkQuality * 100)), 100)
                                                    :   0
                                                } 
                                            color="secondary" 
                                            />
                                    </Grid>
                            }                                                             
                        </CardContent>
                    </Collapse>
                }
            </Grid>
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true }),
)(LeadershipQualityPicker);