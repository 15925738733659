import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingProgress from '../../Modules/LoadingProgress';
//import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

import {ConvertTimeZoneStampToPresentingString} from '../../Modules/ConvertTimestampToDateString';

// import Messages from '../../Messages';

// MUI stuff
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogTaskFollowUps from '../Tasks/DialogTaskFollowUp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {SetStateLoadCategoriesAndQualities} from '../../Modules/LoadCategoriesAndQualities';

const styles = {
    table: {
        minWidth: 100,
        marginTop: 20,
    },
    subtitle: {
        marginTop: 20,
        marginLeft: 20,
        fontWeight: 700,
    }
};

var zonesNatives = [
    'Pacific/Honolulu',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Los_Angeles',
    'America/New_York',
    'America/New_York',
    'America/New_York',
    'America/Indianapolis',
    'America/Edmonton',
    'America/Phoenix',
    'America/Toronto',
    'America/Vancouver',
    'America/Winnipeg',
    'America/Regina',
    'America/Halifax',
    'America/Whitehorse',
    'America/Anchorage',
    'Europe/London',
    'Europe/Dublin',
    'Australia/Adelaide',
    'Australia/Perth',
    'Australia/Sydney',
    'Australia/Brisbane',
    'Australia/Melbourne',
    'Australia/Darwin',
    'Pacific/Auckland'
]

class AdminDialoguePage extends Component {
    render() {
        return (
            <div>
                <Switch>
                <Route exact path={ROUTES.ADMIN_DIALOGUES_DETAILS} component={DialogueItem} />
                <Route exact path={ROUTES.ADMIN} component={DialogueList} />
                <Route exact path={ROUTES.ADMIN_DIALOGUES} component={DialogueList} />
                </Switch>
            </div>
        );
    }
}

class DialogueListBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialogues: null,
            dialoguesPhaseCount: null,
            userData: [],
            messages: [],
            showTab: "ended"
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        this.getLast100();
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
        SetStateLoadCategoriesAndQualities(this, this.props.firebase);
    }

    getLast100() {
        var filterActive = false;
        if(this.unsubscribe !== undefined) {
            this.unsubscribe();
            this.setState({loading: true});
        }
        var lastestDate = new Date();
        this.unsubscribe = this.props.firebase
            .dialogues()
        if(this.state.filterQuality && this.state.filterQuality !== 'default'){
            filterActive = true;
            this.unsubscribe = this.unsubscribe
            .where('currentLeadershipQuality', '==', this.state.filterQuality )
        }
        else if(this.state.filterCategory && this.state.filterCategory !== 'default'){
            filterActive = true;
            this.unsubscribe = this.unsubscribe
            .where('currentCategory', '==', this.state.filterCategory )
        }
        if(this.state.xpStart && (this.state.xpStart === 'pro')){
            filterActive = true;
            this.unsubscribe = this.unsubscribe
            .limit(2000)
        }
        else if(this.state.xpStart && (['advanced','beginner', 'native'].includes(this.state.xpStart))){
            filterActive = true;
            this.unsubscribe = this.unsubscribe
            .limit(300)
        }
        else{
            this.unsubscribe = this.unsubscribe
            .limit(100)
        }
        this.unsubscribe = this.unsubscribe
            .orderBy('createdAt', 'desc')
            .onSnapshot(snapshot => {
                if(this._isMounted ){
                    var counter = 0;
                    let buildStages = ["ended", "extended", "endedEarlier", "runningEnd", "runningInsights", "runningActivities",  "runningAwareness", "runningMindset", "runningMindsetFirst", "sessionRequested", "sessionRequestedFirst", "onboarding", "running", "followUp", "other", "runningNonNative", "connectionError",  "alexa"]
                    let dialogues = {}
                    let dialoguesPhaseCount = {}
                    buildStages.map(item => {
                        dialogues[item] = [];
                        dialoguesPhaseCount[item] = 0;
                        return true;
                    })
                    snapshot.forEach(doc =>{
                        var phase = "other";
                        var dialogSet = doc.data();
                        if(dialogSet.dialogueType !== 'SUPPORT'){
                            var dialogDate = new Date(dialogSet?.createdAt?.seconds * 1000);
                            if(dialogDate < lastestDate){
                                lastestDate = dialogDate;
                                //console.log(lastestDate)
                            }
                            //////// tab count
                            if (dialogSet.channel === 'alexa') { phase = "alexa" }
                            else if (dialogSet.channel === 'alexa') { phase = "alexa" }
                            else if (dialogSet.connectionError) { phase = 'connectionError'}
                            else if (dialogSet.xpStart <10 && dialogSet.phase === 'created') {phase = "onboarding"}
                            //else if (dialogSet.lastIntentUser === "request_change_topics" && dialogSet.phase === 'created') {phase = "topic"}
                            else if (dialogSet.phase === "session_ended_earlier" && dialogSet.dialogueStage?.awareness !== undefined ){  phase = "extended" }
                            else if (dialogSet.phase === "session_ended_earlier"){ phase = "endedEarlier" }
                            else if (dialogSet.phase === "created" && dialogSet.postprocessed === true) {phase = "followUp"}
                            else if (dialogSet.phase === "ended" || dialogSet.phase === "session_ended" || dialogSet.dialogueEnded === true ){  phase = "ended" }
                            else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.end !== undefined ) {phase = "runningEnd"}
                            else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.insights !== undefined ) {phase = "runningInsights"}
                            else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.activities !== undefined ) {phase = "runningActivities"}
                            else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.awareness !== undefined ) {phase = "runningAwareness"}
                            else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.mindset !== undefined && dialogSet?.xpStart > 20 ) {phase = "runningMindset"}
                            else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.mindset !== undefined ) {phase = "runningMindsetFirst"}
                            //else if (dialogSet.phase === "running" && dialogSet.timezone && !zonesNatives.includes(dialogSet.timezone)) {phase = "runningNonNative"}
                            else if (dialogSet.phase === "session_requested" && dialogSet?.xpStart > 20){ phase = "sessionRequested"}
                            else if (dialogSet.phase === "session_requested" ){ phase = "sessionRequestedFirst"}
                            //else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.awareness === undefined ) {phase = "runningMindset"}
                            else if (dialogSet.phase === "running") {phase = "running"}
                            else if (dialogSet.phase === "created") {phase = "other"}
                            else {phase = "other"}
                            if (filterActive && phase === "followUp"){
                                //counter = counter;
                            }
                            else{
                                if(this.state.xpStart === 'native'){
                                    if(dialogSet.timezone !== undefined && zonesNatives.includes(dialogSet.timezone)){
                                        //console.log('xpstart', dialogSet.xpStart  )
                                        counter += 1;
                                        dialoguesPhaseCount[phase] +=1;
                                        ////// add dialogues
                                        dialogues[phase].push({
                                            ...dialogSet,
                                            uid: doc.id,
                                            //user: this.getUserData(dialogSet.userId)
                                        })
                                    }
                                }
                                else if(this.state.xpStart === 'pro'){
                                    if(dialogSet.xpStart >= 200){
                                        //console.log('xpstart', dialogSet.xpStart  )
                                        counter += 1;
                                        dialoguesPhaseCount[phase] +=1;
                                        ////// add dialogues
                                        dialogues[phase].push({
                                            ...dialogSet,
                                            uid: doc.id,
                                            //user: this.getUserData(dialogSet.userId)
                                        })
                                    }
                                }
                                else if(this.state.xpStart === 'advanced'){
                                    if(dialogSet.xpStart >= 40){
                                        //console.log('xplow', dialogSet.xpStart  )
                                        counter += 1;
                                        dialoguesPhaseCount[phase] +=1;
                                        ////// add dialogues
                                        dialogues[phase].push({
                                            ...dialogSet,
                                            uid: doc.id,
                                            //user: this.getUserData(dialogSet.userId)
                                        })
                                    }
                                }
                                else if(this.state.xpStart === 'beginner'){
                                    if(dialogSet.xpStart < 40){
                                        //console.log('xplow', dialogSet.xpStart  )
                                        counter += 1;
                                        dialoguesPhaseCount[phase] +=1;
                                        ////// add dialogues
                                        dialogues[phase].push({
                                            ...dialogSet,
                                            uid: doc.id,
                                            //user: this.getUserData(dialogSet.userId)
                                        })
                                    }
                                }
                                else{
                                    counter += 1;
                                    dialoguesPhaseCount[phase] +=1;
                                    ////// add dialogues
                                    dialogues[phase].push({
                                        ...dialogSet,
                                        uid: doc.id,
                                        //user: this.getUserData(dialogSet.userId)
                                    })
                                }
                            }
                        }
                    });
                    //console.log('counter', counter)
                    this.setState({
                        totalDialogues: counter,
                        dialogues,
                        lastestDate : lastestDate.toLocaleString('en-US',  { timeZone: 'UTC' }),
                        dialoguesPhaseCount,
                        loading: false,
                    });
                }//end ifmounted
            });
    }

    getLastFailed() {
        if(this.unsubscribe !== undefined) {
            this.unsubscribe();
        }
        var lastestDate = new Date();
        this.unsubscribe = this.props.firebase
            .dialogues()
            .orderBy('createdAt', 'desc')
            .limit(300)
            .onSnapshot(snapshot => {
                if(this._isMounted ){
                    var counterFailed = 0;
                    let dialogues = {'other':[]};
                    let dialoguesPhaseCount = {'other':0 };
                    snapshot.forEach(doc =>{
                        var phase = "other";
                        var dialogSet = doc.data();
                        var dialogDate = new Date(dialogSet.createdAt.seconds * 1000);
                        if(dialogDate < lastestDate){
                            lastestDate = dialogDate;
                            //console.log(lastestDate)
                        }
                        //////// tab count
                        if (dialogSet.channel === 'alexa') { phase = "alexa" }
                        else if (dialogSet.connectionError) { phase = 'connectionError'}
                        else if (dialogSet.lastIntentUser === "request_change_topics" && dialogSet.phase === 'created') {phase = "topic"}
                        else if (dialogSet.phase === "session_requested" && dialogSet?.xpStart > 30){ phase = "sessionRequested"}
                        else if (dialogSet.phase === "session_requested" ){ phase = "sessionRequestedFirst"}
                        else if (dialogSet.phase === "session_ended_earlier" && dialogSet.dialogueStage?.awareness !== undefined ){  phase = "extended" }
                        else if (dialogSet.phase === "session_ended_earlier"){ phase = "endedEarlier" }
                        else if (dialogSet.phase === "created" && dialogSet.postprocessed === true) {phase = "followUp"}
                        else if (dialogSet.phase === "ended" || dialogSet.phase === "session_ended" || dialogSet.dialogueEnded === true ){  phase = "ended" }
                        else if (dialogSet.phase === "running" && dialogSet.timezone && !zonesNatives.includes(dialogSet.timezone)) {phase = "runningNonNative"}
                        else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.mindset !== undefined && dialogSet?.xpStart > 20 ) {phase = "runningMindset"}
                        else if (dialogSet.phase === "running" && dialogSet.dialogueStage?.mindset !== undefined ) {phase = "runningMindset"}
                        else if (dialogSet.phase === "running") {phase = "running"}
                        else if (dialogSet.phase === "created") {phase = "other"}
                        if(phase!== 'ended' && dialogSet.teamAnalysisFailure?.length>0){
                            counterFailed += 1;
                            for (const item of dialogSet.teamAnalysisFailure){
                                //item = item.replaceAll(' ', '_') 
                                if(!(item in dialoguesPhaseCount)){
                                    dialoguesPhaseCount[item] = 0;
                                }
                                if(!(item in dialogues)){
                                    dialogues[item] = [];
                                    //console.log(item, 'cdxx', dialogues)
                                }
                                //console.log(item, 'dialogues', dialogues)
                                dialoguesPhaseCount[item] +=1;
                                ////// add dialogues
                                dialogues[item].push({
                                    ...dialogSet,
                                    uid: doc.id,
                                    //user: this.getUserData(dialogSet.userId)
                                })
                            }
                        }
                        else if (phase!== 'ended'){
                            if(!(phase in dialoguesPhaseCount)){
                                dialoguesPhaseCount[phase] = 0;
                            }
                            if(!(phase in dialogues)){
                                dialogues[phase] = [];
                            }
                            counterFailed += 1;
                            dialoguesPhaseCount[phase] +=1;
                            ////// add dialogues
                            dialogues[phase].push({
                                ...dialogSet,
                                uid: doc.id,
                                //user: this.getUserData(dialogSet.userId)
                            })
                        }
                    });
                    //const sortedList = Object.keys(dialoguesPhaseCount).sort().reduce((r, k) => (r[k] = dialoguesPhaseCount[k], r), {});
                    //console.log(sortedList)
                    var sortedList ={}
                    const sortedKeys = (Object.keys(dialoguesPhaseCount).sort((a, b) => dialoguesPhaseCount[b] - dialoguesPhaseCount[a]));
                    sortedKeys.map(i => {
                        sortedList[i] = dialoguesPhaseCount[i];
                        return true;
                    })
                    //console.log(sortedList)
                    this.setState({
                        dialogues,
                        lastestDate : lastestDate.toLocaleString('en-US',  { timeZone: 'UTC' }),
                        dialoguesPhaseCount: sortedList,
                        loading: false,
                        counterFailed
                    });
                }//end ifmounted
            });
    }

    getUserData = userId => {
        let that = this;

        this.props.firebase
        .user(userId)
        .get()
        .then(doc => {
            if (doc.exists) {
            let userData = that.state.userData;
            userData.push({
                id: userId,
                username: doc.data().username,
                email: doc.data().email,
                timezone: doc.data().timezone,
                currentCategory: doc.data().currentCategory,
                dialogueCounter: doc.data().dialogueCounter+'/'+doc.data().freeDialogues,
                lastTitle: doc.data().lastTitle,
                userBatch: doc.data().userBatch,
            });
            that.setState({ userData: userData });
            } else {
            console.log('no user data found');
            }
        })
        .catch(function(error) {
            console.log("Error getting document", error);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined) {
            this.unsubscribe();
        }
    }

    convertTimestampToDate = ts => {
        let date = new Date(ts * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        minute = (minute < 10 ? "0" : "") + minute;

        return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
    }

    getleadershipCategoriesArray() {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
                if(this.context?.programs?.includes(doc.data().program)){
                    myArray.push(doc.data().value);
                    myArrayText.push(doc.data().text);
                }
            });
            if(this._isMounted){
                this.setState({
                    leadershipCategoriesValues: myArray,
                    leadershipCategoriesText: myArrayText
                });
            }
        });
    }
    getleadershipQualitiesArray() {
        var myArray = [];
        var myArrayText = [];
        let leadershipQualitiesPerCategory = {}
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
                const quality = doc.data();
                if(this.context.programs.includes(doc.data().program)){
                    myArray.push(doc.data().value);
                    myArrayText.push( "(" + doc.data().category + ") " + doc.data().text);
                };
                if(quality.category){
                    if(!Object.keys(leadershipQualitiesPerCategory).includes(quality.category)){
                        leadershipQualitiesPerCategory[quality.category] = [];
                    }
                    leadershipQualitiesPerCategory[quality.category].push({value: quality.value, text: ( "(" + quality.category + ") " + quality.text)});
                };
            });
            if(this._isMounted){
                this.setState({
                    leadershipQualitiesValues: myArray,
                    leadershipQualitiesText: myArrayText,
                    leadershipQualitiesPerCategory
                });
            }
            //console.log('leadershipQualitiesPerCategory', leadershipQualitiesPerCategory)
        });
    }

    render() {
        const { dialogues, loading, showTab, dialoguesPhaseCount } = this.state;
        
        if (dialoguesPhaseCount!== undefined && dialoguesPhaseCount!== null)
            return (
                <div>
                    {this.state.showFailedOnly
                        ?   <Button onClick={() => {this.getLast100(); this.setState({showFailedOnly: false})}}>Show All</Button>
                        :   <Button onClick={() => {this.getLastFailed(); this.setState({showFailedOnly: true})}}>Show Failed</Button>
                    }
                    &nbsp;&nbsp;
                    <Select
                        id="cateogry"
                        value={(this.state.filterCategory || 'default')}
                        onChange={ (e) => {
                            this.setState({filterCategory: e.target.value, filterQuality: 'default', showFailedOnly: false}, () => this.getLast100());
                        }}
                        >
                        <MenuItem value={'default'}>All</MenuItem>
                        {this.state.leadershipCategoriesValues && this.state.leadershipCategoriesValues.map( (item, index) => (
                            <MenuItem value={item} key={item}>{this.state.leadershipCategoriesText[index]}</MenuItem>
                        ) )}
                    </Select>
                    &nbsp;&nbsp;
                    <Select
                        id="quality"
                        value={(this.state.filterQuality || 'default')}
                        onChange={ (e) => {
                            this.setState({filterQuality: e.target.value}, () => this.getLast100());
                           
                        }}
                        >
                        <MenuItem value={'default'}>All</MenuItem>
                        {this.state.leadershipQualitiesPerCategory && this.state.filterCategory!==undefined && this.state.filterCategory!=='default' && this.state.leadershipQualitiesPerCategory[this.state.filterCategory] && this.state.leadershipQualitiesPerCategory[this.state.filterCategory].map( (item, index) => (
                            <MenuItem value={item.value} key={item.value}>{item.text}</MenuItem>
                        ) )}
                    </Select>
                    &nbsp;&nbsp;
                    <Select
                        id="chatnumber"
                        value={(this.state.xpStart || 'default')}
                        onChange={ (e) => {
                            this.setState({xpStart: e.target.value}, () => this.getLast100());
                           
                        }}
                        >
                        <MenuItem value={'default'}>All</MenuItem>
                        <MenuItem value={'pro'} key={'xpPro'}>Pro Users</MenuItem>
                        <MenuItem value={'advanced'} key={'xpStart'}>Advanced Users</MenuItem>
                        <MenuItem value={'beginner'} key={'xpstop'}>Beginner</MenuItem>
                        <MenuItem value={'native'} key={'native'}>English Natives</MenuItem>
                    </Select>
                    {this.state.showFailedOnly
                        ?   <h4 style={{ marginLeft: 20 }}>{this.state.counterFailed}/300 Failed Dialogues - since {this.state.lastestDate}</h4>
                        :   <h4 style={{ marginLeft: 20 }}>{this.state.totalDialogues || 100} Most Recent Dialogues - since {this.state.lastestDate}</h4>
                    }
                    {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={this.handleTabChange}
                        initialselectedindex={0}
                        value={showTab}
                        >
                        {Object.keys(dialoguesPhaseCount).map( (key) => (
                            <Tab 
                                value={key} 
                                key={key}
                                label={key + " ("+ Math.round(dialoguesPhaseCount[key] / (this.state.totalDialogues || 100) * 100) +"%)"}/>
                            ))
                        }
                    </Tabs>
                    {Object.keys(dialoguesPhaseCount).map( (phase)  => (
                        <div 
                            value={phase} 
                            key={phase} 
                            hidden={showTab !==phase}>
                                
                            <Table style={styles.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Dialogues</TableCell>
                                        <TableCell>Channel</TableCell>
                                        <TableCell>User Timezone</TableCell>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>Summary</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogues[phase].map((dialogue, index) => {
                                        var userInfo = this.state.userData.find(user => user.id === dialogue.userId);
                                        return(
                                            <TableRow key={dialogue.uid} onClick={
                                                    this.handleOpen(dialogue.userId, dialogue, phase, index )
                                                }
                                                style={{backgroundColor: dialogue.analyzed? '#6bd28a' : 'white'}}>
                                                {userInfo
                                                    ?
                                                        <TableCell>{userInfo.username.slice(0,3)}***</TableCell>
                                                    :
                                                        <TableCell>Reading...</TableCell>
                                                }
                                                {userInfo
                                                    ?
                                                        <TableCell>{dialogue.currentCategory}</TableCell>
                                                    :
                                                        <TableCell>{dialogue.currentCategory}</TableCell>
                                                }
                                                {userInfo
                                                    ?
                                                        <TableCell>{dialogue.channel || 'n/a'}</TableCell>
                                                    :
                                                        <TableCell>...</TableCell>
                                                }
                                                {userInfo
                                                    ?
                                                        <TableCell>{userInfo.dialogueCounter}</TableCell>
                                                    :
                                                        <TableCell>Reading...</TableCell>
                                                }
                                                {userInfo
                                                    ?
                                                        <TableCell>{userInfo.timezone}</TableCell>
                                                    :
                                                        <TableCell>Reading...</TableCell>
                                                }
                                                {userInfo
                                                    ?
                                                        <TableCell> 
                                                            {ConvertTimeZoneStampToPresentingString(dialogue.createdAt)}
                                                            &nbsp;
                                                            {ConvertTimeZoneStampToPresentingString(dialogue.createdAt, userInfo.timezone, 'weekday')}
                                                            &nbsp;({dialogue.dialogueType})
                                                        </TableCell>
                                                    :
                                                        <TableCell>
                                                            {ConvertTimeZoneStampToPresentingString(dialogue.createdAt)}
                                                            &nbsp;({dialogue.dialogueType})
                                                        </TableCell>
                                                }
                                                {userInfo
                                                    ?
                                                        <TableCell>{userInfo.lastTitle?.length > 0 ? userInfo.lastTitle : dialogue.title}</TableCell>
                                                    :
                                                        <TableCell>{dialogue.title}</TableCell>
                                                }
                                                <TableCell>
                                                    {/* 
                                                    <Link
                                                        to={{
                                                        pathname: `${ROUTES.ADMIN_DIALOGUES}/${dialogue.uid}`,
                                                        state: { dialogue },
                                                        }}
                                                    >
                                                        Details
                                                    </Link>
                                                    */}
                                                    Details
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    ))}
                    {this.state.dialogTaskOpen && this.state.openUserId !== undefined &&
                        <DialogTaskFollowUps 
                            handleOpen={this.state.dialogTaskOpen || false}
                            handleClose={() => {this.setState({dialogTaskOpen: false})}}
                            userId= {this.state.openUserId}
                            showFailureReport={true}
                            leadershipCategories={this.state.leadershipCategories}
                            leadershipQualities={this.state.leadershipQualities}
                            openDialogue = {this.state.openDialogue}
                            
                        />
                    }
                </div>
            );
        else{
            return (
                <div>
                    {loading && 
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'} 
                            loadingText={"many, many, data"} 
                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                            allowRefresh={true}
                        />
                    }
                </div>
            )
        }
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
      }

    handleOpen = (userId, dialogue, phase, index) => async () => {
        //await this.getUser(userId);
        //console.log(dialogue)
        var userInfo = this.getUserData(dialogue.userId)
        var dialogues = this.state.dialogues;
        dialogues[phase][index]['user'] = userInfo;
        this.setState({
            dialogues,
            dialogTaskOpen: true,
            openDialogue: dialogue,
            openUserId: userId,
        });
        this.props.firebase.dialogue(dialogue.uid).update({analyzed: true});
    }
}

class DialogueItemBase extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialogue: null,
            messages: [],
            ...props.location.state,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });

        if (!this.state.dialogue) {
            this.unsubscribe = this.props.firebase
            .dialogue(this.props.match.params.id)
            .onSnapshot(snapshot => {
                if(this._isMounted ){
                    this.setState({
                        dialogue: snapshot.data(),
                        loading: false,
                    });
                }
            });
        }

        this.unsubscribe2 = this.props.firebase
        .messages()
        .orderBy('createdAt', 'asc')
        .where('dialogueId', '==', this.props.match.params.id)
        .limit(100)
        .onSnapshot(snapshot => {
            if(this._isMounted){
                let messages = [];
                snapshot.forEach(doc =>
                    messages.push({ ...doc.data(), uid: doc.id }),
                );
                this.setState({
                    messages,
                    loading: false,
                });
            }//end if mounted
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe && this.unsubscribe();
        this.unsubscribe2 && this.unsubscribe2();
    }

    onToggleStatus = (event, dialogueId, dialogue) => {
        this.props.firebase.dialogue(dialogueId).update({
            ...dialogue,
            status: !dialogue.status,
        });
        event.preventDefault();
    };

  render() {
    const { dialogue, messages, loading } = this.state;

    return (
      <div>
	<h5 style={styles.subtitle}>Dialogue ({this.props.match.params.id})</h5>
	<span style={{ marginLeft: 20 }}><strong>Coach: {this.context.uid}</strong></span>
	{/*
	   <form onSubmit={event => this.onToggleStatus(event, this.props.match.params.id, dialogue)}>
	   <button type="submit">Toggle Status</button>
	   </form>
	 */}
	{loading && 
        <LoadingProgress 
            defaultSet={this.props.defaultSet}
            type={'LoadingPageAnimated'} 
            loadingText={"many, many, data"} 
            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
            allowRefresh={true}
        />
    }

	{dialogue && (
	  <Table style={styles.table}>
	    <TableHead>
	      <TableRow>
		<TableCell>Property</TableCell>
		<TableCell>Value</TableCell>
	      </TableRow>
	    </TableHead>
	    <TableBody>
	      <TableRow>
                <TableCell>User ID</TableCell>
		<TableCell>{dialogue.userId}</TableCell>
	      </TableRow>
	      <TableRow>
                <TableCell>Coach ID</TableCell>
                <TableCell>{dialogue.coachId}</TableCell>
              </TableRow>
	      <TableRow>
                <TableCell>Ask More</TableCell>
                <TableCell>{dialogue.askMore}</TableCell>
              </TableRow>
	      <TableRow>
                <TableCell>Ask More Processed</TableCell>
		{dialogue.askMoreProcessed
		 ?
		 <TableCell>{dialogue.askMoreProcessed.toString()}</TableCell>
		 :
		 <TableCell>N/A</TableCell>
		}
	      </TableRow>
	    </TableBody>
	  </Table>
	)}

	<Table style={styles.table}>
	  <TableHead>
	    <TableRow>
	      <TableCell>User</TableCell>
	      <TableCell>Utterance</TableCell>
	    </TableRow>
	  </TableHead>
	  <TableBody>
	    {messages && (
	      messages.map((message) => {
		if (message.dialogueId === dialogue.uid) {
		  return (
		    <TableRow key={message.uid}>
		      {message.isCoach
		       ?
		       <TableCell>Bot/Coach</TableCell>
		       :
		       <TableCell>User</TableCell>
		      }
		      <TableCell>{message.text}</TableCell>
		    </TableRow>
		  );
		} else {
		  return (
		    null
		  );
		}})
	    )}
	  </TableBody>
	</Table>

      </div>
    );
  }
}

AdminDialoguePage.propTypes = {
  classes: PropTypes.object.isRequired
}


const DialogueList = withFirebase(DialogueListBase);
const DialogueItem = withFirebase(DialogueItemBase);

const condition = authUser =>
      authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
  withStyles(styles),
  // withEmailVerification,
  withAuthorization(condition),
)(AdminDialoguePage);
