import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import {LoadCoachingGroups} from '../Coach/CoachGroups';
import { withFirebase } from '../Firebase';
import Avatar from '@material-ui/core/Avatar';
import CoachGroupUsersAdmin from '../Coach/CoachGroupUsersAdmin';
import CardTotalStatsTopics from '../Charts/CardTotalStatsTopics';

import DialogAssessment from '../Assessments/DialogAssessment';
import {LoadQuestionnaires} from '../Assessments/CardAssessmentShop';

import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import {AvatarCard} from '../Modules/AvatarImage';
import DateBadge from '../Modules/DateBadge';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import CardProgramProgress from '../Charts/CardProgramProgress';
import OneOnOneSupport from '../Support/OneOnOneSupport';
import Logo from "../../images/rocky-logo-square.png";
import _ from "lodash";

import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//Panels
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Modules for user admin
import CoachUserSetting from '../Coach/CoachUserSetting';
import CoachUserActions from '../Coach/CoachUserActions';
import DayList from '../Days/DayList';

// Swipe
import VizSensor from 'react-visibility-sensor';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Icons
import Button from '@material-ui/core/Button';

import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import SchoolIcon from '@material-ui/icons/School';
import HotelIcon from '@material-ui/icons/Hotel';
import RowingIcon from '@material-ui/icons/Rowing';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import UpdateIcon from '@material-ui/icons/Update';

import Slide from '@material-ui/core/Slide';
import CardAssessmentFactors from '../Charts/CardAssessmentFactors';
import { AccessCheckCoachCreator, AccessUsersPanel } from '../../constants/roles';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CoachUsersWindows extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    today = new Date();
    last2Weeks = new Date(Date.now() - 12096e5);
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading users',
            coachees: null,
            parentGroup: 'Unsorted'
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        LoadCoachingGroups(this, this.context);
        LoadQuestionnaires(this, this.props.user);
        this.loadInteractiveContent();
    }

    loadCoachees = (coachGroupsIds, parentGroup, forceNewUserLoad=true) => {
        if(this.unsubscribe!== undefined ){ this.unsubscribe()}
        if(this._isMounted ){
            this.setState({
                loadingCoachees: true,
                parentGroup,
                showTeamKey: undefined
            });
        }
        //// preselected user from last group
        var showClientDetailsDict = this.context;
        var activeKey = undefined;
        var ownedGroups = [].concat(coachGroupsIds[parentGroup]);
        var ownedFullGroups = coachGroupsIds[parentGroup];
        if(ownedGroups!== undefined && ownedGroups!==null && ownedGroups.length>10){
            ownedGroups = ownedGroups.slice(0,9);
        }
        if(ownedGroups.length>0){
            console.log('New loadCoachees', forceNewUserLoad, parentGroup)
            this.unsubscribe = this.props.firebase
            .users()
            .where('groups', 'array-contains-any', (ownedGroups || []))
            //.where('roles', 'array-contains', 'COACHEE')
            //.where('code', '==', 'PMCOACHING')
            .onSnapshot(snapshot => {
                let coachees = {};
                if(this._isMounted){
                    snapshot.forEach(doc => {
                        var group = "Undefined";
                        // *** HN adding filtering to users only allowed to be seen by coach who has same program
                        // any kind of program would work, but default program is filtered
                        var coacheeUser = doc.data();
                        if(coacheeUser.email !== "DELETED ACCOUNT"){
                            if(coacheeUser?.lastEngagement !== undefined && (showClientDetailsDict === undefined || showClientDetailsDict?.uid === this.context.uid || coacheeUser?.lastEngagement > showClientDetailsDict?.lastEngagement)){
                                showClientDetailsDict = coacheeUser;
                                showClientDetailsDict['uid'] = doc.id;
                                coacheeUser.groups?.map(itemGroupId => {
                                    if(ownedGroups.includes(itemGroupId)){
                                        activeKey = 'showKey_'+itemGroupId;
                                    }
                                    return true
                                })
                                //console.log('activeKey', activeKey, coacheeUser)
                            }
                            if (coacheeUser.groups!== undefined && coacheeUser.groups.length> 0){
                                coacheeUser.groups.map(groupId => {
                                    group = groupId;
                                    if(ownedGroups.includes(groupId) || this.context.roles?.includes("COACHADMIN") ){
                                        if(groupId===""){groupId = "Undefined"}
                                        if(groupId !== "$$EXTEND" && ownedFullGroups.includes(groupId)){
                                            if(Object.keys(coachees).includes(groupId)){
                                                coachees[groupId].push({ ...coacheeUser, uid: doc.id });
                                            }
                                            else{
                                                coachees[groupId] = [{ ...coacheeUser, uid: doc.id }];
                                            } 
                                        }
                                    }
                                    return true
                                })
                            }
                            else{
                                if(Object.keys(coachees).includes(group)){
                                    coachees[group].push({ ...coacheeUser, uid: doc.id });
                                }
                                else{
                                    coachees[group] = [{ ...coacheeUser, uid: doc.id }];
                                }
                            }
                            if(coachees[group]?.length > 0){
                                coachees[group] = _.orderBy(coachees[group], ['username'], ['asc']);
                            }
                        }
                    });
                }
                if (this._isMounted) {
                    if(forceNewUserLoad === true){
                        /// overwrite the loader
                        forceNewUserLoad = false;
                        //console.log('coachees', coachees.length, showClientDetailsDict.uid)
                        this.setState({
                            coachees,
                            loading: false,
                            showClientDetailsDict,
                            activeKey,
                            loadingCoachees : false,
                        });
                    }
                    else{
                        //console.log('update coachees update', coachees.length, showClientDetailsDict.uid)
                        this.setState({
                            coachees,
                            loading: false,
                            loadingCoachees : false,
                        });
                    }
                }
            });
        }//owndes groupd > 1
        else{
            //console.log(coachees)
            if (this._isMounted) {
                this.setState({
                    coachees: {},
                    loading: false,
                    loadingCoachees: false
                });
            }
        }
    }

    loadUser = (user, key) => {
        //console.log('load user', user, key)
        if(this.unsubscribeUser!==undefined) {this.unsubscribeUser()}
        if(user?.uid?.includes("@")){
            this.unsubscribeUser = this.props.firebase
            .users()
            .where('email', '==', user.uid)
            .limit(1)
            .onSnapshot(snapshot => {
                snapshot.forEach(doc =>{
                    let showClientDetailsDict = doc.data();
                    if(showClientDetailsDict === undefined){
                        this.unsubscribeUser();
                        this.setState({showClientDetailsDict: undefined});
                    }
                    else{
                        showClientDetailsDict['uid'] = doc.id;
                        if(this._isMounted){
                            this.setState({showClientDetailsDict, showClientCode : key, showTeamKey : undefined});
                        }
                    }
                })
            })
        }
        else if(user!== undefined && user.uid!==undefined){
            this.unsubscribeUser = this.props.firebase
            .user(user.uid)
            .onSnapshot(snapshot => {
                let showClientDetailsDict = snapshot.data();
                if(showClientDetailsDict === undefined){
                    this.unsubscribeUser();
                    this.setState({showClientDetailsDict: undefined});
                }
                else{
                    showClientDetailsDict['uid'] = user.uid;
                    if(this._isMounted){
                        this.setState({showClientDetailsDict, showClientCode : key, showTeamKey : undefined});
                    }
                }
            })
        }
        else{
            if(this._isMounted){
                this.setState({showClientDetailsDict: undefined, showClientCode : undefined});
            }
        }
    }


    combineDicts(dict1, dict2) {
        if(dict1 === undefined || dict1 === null) dict1 = {};
        if(dict2 === undefined || dict2 === null) dict2 = {};
        for (let key in dict2) {
            if (dict2?.hasOwnProperty(key)) {
                if (dict1?.hasOwnProperty(key)) {
                    if (typeof dict1[key] === 'number' && typeof dict2[key] === 'number') {
                        dict1[key] += dict2[key];
                    } else if (typeof dict1[key] === 'object' && typeof dict2[key] === 'object') {
                        this.combineDicts(dict1[key], dict2[key]);
                    }
                } else {
                    dict1[key] = dict2?.[key];
                }
            }
        }
        return dict1;
    }

    loadTeamStats = (teamKey) => {
        this.setState({insightsTeam: undefined, teamRanking: undefined});
        //console.log('stat', this.state)
        let coacheeSets = this.state.coachees?.[teamKey] || [];
        let teamRanking = [];
        //console.log('user', coacheeSets)
        let insightsTeam = undefined;
        coacheeSets.map((userSet, index) => {
            var userId = userSet.uid;
            this.props.firebase.insight(userId).get()
            .then(doc => {
                let showClientDetailsDict = doc.data();
                if(index === 1){
                    insightsTeam = showClientDetailsDict;
                }
                else{
                    insightsTeam = this.combineDicts(insightsTeam, showClientDetailsDict);
                }
                if(userSet.engagementCounter === undefined){
                    userSet['engagementCounter'] = 0;
                }
                teamRanking.push(userSet);
                if(index === (coacheeSets.length - 1)){
                    teamRanking = _.orderBy(teamRanking, ['engagementCounter'], ['desc']);
                    this.setState({insightsTeam, teamRanking});
                    //console.log('state', this.state, insightsTeam)
                }
                //showClientDetailsDict?.engagements?.leadershipCategories
                //console.log('deta', showClientDetailsDict, insightsTeam)
            })
            //console.log('userSet', userSet)
            return true
        })
        this.setState({showTeamKey: teamKey});
    }

    loadInteractiveContent = () => {
        var reviewContentList = [];
        var reviewContentDicts = {};
        this.props.firebase.contents()
        .where('program', 'in', (this.context.programs || ["FASLE"]))
        .where('isReviewable', '==', true)
        .get()
        .then((snapshot) => {
            snapshot.forEach(doc => {
                reviewContentList.push(doc.id);
                reviewContentDicts[doc.id] = doc.data();
                console.log('isReviewable', doc.id)
            })
            this.setState({
                reviewContentList,
                reviewContentDicts
            })
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        if(this.unsubscribeGroups!==undefined){this.unsubscribeGroups();}
        if(this.unsubscribeUser!==undefined) {this.unsubscribeUser();}
        this.setState({ loading: false, coachees: null });
    }

    render(){
        //console.log('quesitons', this.state)
        const {coachees, coachGroupsIds, parentGroup} = this.state;
        const {theme} = this.props;
        return(
            this.state.loading 
                ?
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LinearProgress'} loadingText={this.state.loadingText} />
                :
                    <div style={{marginBottom: 40}}>
                        <div style={{marginBottom: 20}}>
                            <span 
                                onClick={() => this.setState({parentGroup: undefined, showTeamKey: undefined, showClientDetailsDict: undefined})}
                                style={{
                                    textDecorationLine : this.state.parentGroup?.length > 1 ? "underline" : "none",
                                    color : this.state.parentGroup?.length > 1 ? theme.palette.secondary.main : undefined
                                }}
                            >
                                My Users
                            </span>
                            {this.state.parentGroup?.length>0
                                &&
                                <>
                                    {" > "}
                                    <span 
                                        onClick={() => this.setState({activeKey: undefined, showClientDetailsDict: undefined})}
                                        style={{
                                            textDecorationLine : this.state.activeKey?.length>0 ? "underline" : undefined,
                                            color :this.state.activeKey?.length>0 ? theme.palette.secondary.main : undefined
                                        }}
                                    >
                                    {this.state.parentGroup}
                                    </span>
                                </>
                            }
                            {this.state.activeKey?.length>0 && this.state.activeKey !== 'hidethem'
                                &&
                                <>
                                    {" > "}
                                    <span 
                                        onClick={() => this.setState({showClientDetailsDict: undefined})}
                                        style={{
                                            textDecorationLine : this.state.showClientDetailsDict?.uid?.length>0 ? "underline" : undefined,
                                            color : this.state.showClientDetailsDict?.uid?.length>0 ? theme.palette.secondary.main : undefined
                                        }}
                                    >
                                        {this.state.activeKey?.replace("showKey_", "")}
                                    </span>
                                </>
                            }
                            {this.state.showClientDetailsDict?.uid?.length > 2
                                &&
                                <>
                                    {" > "}
                                    <span 
                                        onClick={
                                            this.state.uid?.length>0
                                            ?   () => this.setState({showUtter: ""})
                                            :   () => this.setState({showUtter: "", ["selection_"+this.state.activeKey?.replace('showKey_', "")] : ""})
                                        }
                                        style={{
                                            textDecorationLine : this.state.showUtter?.length>0 ? "underline" : undefined,
                                            color : this.state.showUtter?.length>0 ? theme.palette.secondary.main : undefined
                                        }}
                                    >
                                        {this.state.showClientDetailsDict.username}
                                    </span>
                                </>
                            }
                        </div>
                        {this.context?.roles?.includes('ADMIN') &&
                            <div style={{width: '100%', textAlign: 'right'}}>
                                Search by UserId &nbsp;
                                <TextField
                                    name="userId"
                                    type="text"
                                    //label="userId"
                                    placeholder="UserId"
                                    value={this.state.searchUserId || ''}
                                    onChange={(e) => this.setState({searchUserId: e.target.value})}
                                    style={ {fontSize: '16px'} }
                                />
                                <Button
                                    onClick={() => this.loadUser({uid: this.state.searchUserId}, 'Unsorted')}
                                >
                                    Search
                                </Button>
                            </div>
                        }
                        {this.state.parentGroup === undefined
                        ?
                            <p>
                                <strong>Select an organization:</strong>
                            </p>
                        :
                            <p>
                                Organization: <span style={{color: theme.palette.themeAlertRed}}>
                                    {this.state.parentGroup}
                                </span> 
                            </p>
                        }
                        {coachGroupsIds !== undefined && coachGroupsIds !== null
                            &&
                                <div component="fieldset">
                                    <Chip
                                        key={"chip_myself"}
                                        style={{
                                            marginRight: 8,
                                            marginBottom: 8,
                                            color: 'Myself' === this.state.parentGroup ? theme.palette.white : theme.palette.black, 
                                            backgroundColor: 'Myself' === this.state.parentGroup ? theme.palette.themeAlertRed : theme.palette.themeGrey 
                                        }}
                                        label={'Myself'}
                                        onClick={() => {
                                            //console.log('seleced myself')
                                            this.setState({
                                                parentGroup: 'Myself', 
                                                coachees: undefined, 
                                                showClientDetailsDict: this.context, 
                                                showTeamKey: undefined
                                            })
                                        }}
                                    />
                                    {Object.keys(coachGroupsIds).map( (parent, parentIndex) => (
                                        <Chip
                                            key={"chip_"+parentIndex}
                                            style={{
                                                marginRight: 8,
                                                marginBottom: 8,
                                                color: parent === this.state.parentGroup ? theme.palette.white : theme.palette.black, 
                                                backgroundColor: parent === this.state.parentGroup ? theme.palette.themeAlertRed : theme.palette.themeGrey 
                                            }}
                                            label={parent}
                                            onClick={() => this.loadCoachees(coachGroupsIds, parent)}
                                        />
                                    ))}
                                </div>
                            }
                        {this.state.ownedGroupsCodes?.length>0
                            ?   
                                this.state.parentGroup === undefined
                                ?   ""
                                :
                                    <div style={{marginTop: 10}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <div>
                                                    {false && coachGroupsIds !== undefined && coachGroupsIds !== null
                                                    &&
                                                        <FormControl component="fieldset">
                                                            <Select
                                                                style={{width:'100%', textAlign: "left"}}
                                                                value={parentGroup}
                                                                onChange={(event) => {
                                                                    console.log('initiated event')
                                                                    this.loadCoachees(coachGroupsIds, event.target.value)
                                                                }}
                                                            >
                                                                {Object.keys(coachGroupsIds).map( (parent, parentIndex) => (
                                                                    <MenuItem 
                                                                        key={"select_"+parentIndex}
                                                                        value={parent}
                                                                        selected={parentGroup === parent}
                                                                    >
                                                                        {
                                                                            parentGroup === parent
                                                                            ?
                                                                                <h5 style={{color: 'red'}}>{parent}</h5>
                                                                            :
                                                                                <strong>{parent}</strong>
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                    {this.state.parentGroup !== "Myself" && Object.keys((coachees || {})).length > 0
                                                    &&
                                                        <p style={theme.textSupport}><strong>Choose Teams & Users:</strong></p>
                                                    }
                                                    {
                                                    this.state.loadingCoachees
                                                    ?
                                                        <div>Loading teams!</div>
                                                    :
                                                        coachees!==undefined && coachees!==null && Object.keys(coachees).length > 0
                                                        ?
                                                            <>
                                                                {this.state.activeKey?.length > 0 && this.state.activeKey !== 'hidethem' && coachees?.[(""+ this.state.activeKey.replace('showKey_', ""))] !== undefined
                                                                    &&
                                                                    this.renderGroups((""+ this.state.activeKey.replace('showKey_', "")), 0)
                                                                }
                                                                {Object.keys(coachees).map((key, index) => (
                                                                    (key.length > 0 && ('showKey_'+key) !== this.state.activeKey)
                                                                    ?
                                                                        this.renderGroups(key, (index+1) )
                                                                    :
                                                                        ""
                                                                ))}
                                                            </>
                                                        :
                                                            this.state.parentGroup !== "Myself"
                                                            ?
                                                                <div> No users assigned to any team in: <strong>{this.state.parentGroup !== undefined? this.state.parentGroup : 'this group'}</strong>! Please assign users to your teams.</div>
                                                            :   ""
                                                    }
                                                </div>
                                            </Grid>
                                            {this.state.showTeamKey?.length > 2
                                                ?   
                                                    <Grid item xs={9}>
                                                        {this.renderTeamCard(this.state.showTeamKey)}
                                                    </Grid>
                                                :   
                                                    <Grid item xs={6}>
                                                        {this.state.showClientDetailsDict !== undefined && (Object.keys((coachees || {})).length > 0 || this.state.parentGroup === "Myself")
                                                        ?
                                                            this.renderUserCard(this.state.showClientDetailsDict, this.state.showClientDetailsDict.uid)
                                                        :
                                                            <div>
                                                                {false &&
                                                                    <Skeleton variant="rect" width={'400' } height={200} />
                                                                }
                                                                <p style={{color: 'red'}}><br/> Click on a member name on the 'Teams / Users' list on the left side!</p>
                                                            </div>
                                                        }
                                                    </Grid>
                                            }
                                            {this.state.showTeamKey?.length > 2
                                                ?
                                                    <>
                                                    </>
                                                :
                                                    <Grid item xs={3}>
                                                        {this.state.showClientDetailsDict !== undefined && this.state.showClientDetailsDict.uid !== undefined && Object.keys((coachees || {})).length > 0  && this.state.parentGroup !== "Myself"
                                                            &&
                                                            <OneOnOneSupport
                                                                userId={this.state.showClientDetailsDict.uid} 
                                                                user={this.state.showClientDetailsDict} 
                                                                groupId={this.state.showClientCode || ''}
                                                                isCoach={true}
                                                            />
                                                        }
                                                        {this.state.showClientDetailsDict !== undefined && this.state.showClientDetailsDict.uid !== undefined && (Object.keys((coachees || {})).length > 0 || this.state.parentGroup === "Myself")
                                                            ?   
                                                                <>
                                                                    <p style={theme.textSupport}>User Chat Diary:</p>
                                                                    <DayList 
                                                                        userId={this.state.showClientDetailsDict.uid} 
                                                                        user={this.state.showClientDetailsDict} 
                                                                        isCoach={true}
                                                                    />
                                                                </>
                                                            :   ''
                                                        }
                                                    </Grid>
                                            }
                                        </Grid>
                                    </div>
                                :
                                    <div>
                                        <p style={{paddingTop: 10}}>Please create your first Team!</p>
                                    </div>
                        }
                        {
                            //this.dialogueChats()
                        }
                        {
                            this.dialogueUserSettings()
                        }
                        {
                            this.dialogueUserAdmin()
                        }
                        {
                            this.dialogueNotificationSettings()
                        }
                    </div>
        )
    }

    renderGroups = (key, index) => {
        const {coachees} = this.state;
        const {theme} = this.props;
        return(
            <VizSensor 
                partialVisibility={true}
                key={key+'_'+index}
                onChange={(isVisible) => { 
                    if(isVisible && this.state['isVisible_'+key]===undefined) { 
                        this.setState({ ['isVisible_'+key] : true})
                        //console.log('t.leadership_category', t.leadership_category)
                    }}
                }
            >
                <div key={key+'_'+index}>
                    <Accordion key="clients_add"  id={`clients-clients_add`}  
                        style={{
                            borderRadius: theme.borderRadius, 
                            marginBottom: 10,
                            opacity: (this.state.activeKey === undefined || this.state.activeKey === 'hidethem' || this.state.activeKey === ('showKey_'+key)) ? 1 : 0.4
                        }}
                        TransitionProps={{ mountOnEnter: true }}
                        expanded={this.state.activeKey === ('showKey_'+key)}
                    >
                        <AccordionSummary
                            onClick={() => {this.setState({
                                activeKey: this.state.activeKey === ('showKey_'+key) ? 'hidethem' : 'showKey_'+key,
                                showClientDetailsDict: undefined
                            })}}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="clients-clients_add"
                            id="add"
                        >
                            <div style={theme.divFullWidth}>
                                {key}
                                <br/>
                                <span style={theme.textSupport}>{""+ (coachees[key]?.length || "...") +" team members"} </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} direction='column' >
                                <Grid item style={{textAlign: 'center', paddingBottom: 30}}>
                                    <Button onClick={() => this.loadTeamStats(key)}>
                                        <span><LibraryBooksIcon style={theme.inlineIconLeft}/>Team Stats</span>
                                    </Button>
                                </Grid>
                                <Grid item >
                                    {this.renderClients(coachees[key], key)}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </VizSensor>
        )
    }
    
    handleStepChange = (step, keyWord) => {
        //console.log(step, keyWord, 'handler')
        if(step !== undefined && keyWord !== undefined) {
            this.setState({['activeStep_'+keyWord] : step});
        }
    };

    renderClients = (newCoachees, key='') => {
        const {coachGroups} = this.state;
        const {theme} = this.props;
        if(coachGroups!==undefined && coachGroups!==null && Object.keys(coachGroups).length>0){
            return(
                <Grid container spacing={0} direction='row' justify="space-between"  alignItems="center">
                    {newCoachees!==undefined && newCoachees!==null && newCoachees.length>0
                        ?
                            newCoachees.map((user, index) => (
                                <Card 
                                    key={'key_'+index+key}
                                    style={{width: '100%', marginBottom: 5, cursor: 'pointer'}} 
                                    //onClick={() => this.setState({showClientDetailsDict: user})}
                                    onClick= {() => this.loadUser(user, key)}
                                    onMouseEnter={() => {this.setState({ hoverItem: (key+'_'+index)}) }}
                                    onMouseLeave={() => {this.setState({ hoverItem: undefined}) }}
                                    >
                                    {user !== null && user !== undefined
                                        &&
                                            <Grid container direction='row' justify="space-between" alignItems="center" 
                                                key={"user"+index} id={"user"+index} 
                                                style={{
                                                    padding:8,
                                                    backgroundColor: 
                                                        this.state.hoverItem === (key+'_'+index) 
                                                        ?   theme.palette.themeGold
                                                        :   (index%2)===0
                                                            ? theme.palette.primary.light
                                                            : theme.palette.primary.main
                                                }} >
                                                <Grid item xs={2} style={{textAlign: 'center'}}>
                                                    {user.programLogo !== undefined && user.programLogo !== ''
                                                        ?
                                                            <Avatar
                                                                style={{
                                                                    marginLeft: 4,
                                                                    border: '0.1px solid lightgray'
                                                                }}
                                                                alt={user.username} 
                                                                src={user.programLogo} 
                                                            />
                                                        :
                                                            <Avatar
                                                                style={{
                                                                    marginLeft: 4,
                                                                    border: '0.1px solid lightgray'
                                                                }}
                                                                alt={user.username} 
                                                                src={Logo} 
                                                            />
                                                    }
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Grid container style={{padding:8}} direction='column'>
                                                        <Grid item>
                                                            <Tooltip title={( user.roles?.includes("default") && user.roles?.length ===1 )? "Anonymous account" : user.email}>
                                                                <span>{( user.roles?.includes("default") && user.roles?.length ===1 )?user.username.substring(0, 9)+"*****" : user.username }</span>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            {(user.roles?.includes("REMOTECOACH") || user.roles?.includes("COACHADMIN") )  &&
                                                                <Tooltip title='Coach/Consultant Account'>
                                                                    <SchoolIcon style={{color:"blue"}} fontSize={"small"}/>
                                                                </Tooltip>
                                                            }
                                                            {user.purchasedProduct !== undefined && user.purchasedProduct.owned 
                                                                ?
                                                                    <Tooltip title={'Subscription Status until: ' + ConvertTimestampToDateString(user.purchasedProduct.expiryDate, true)}>
                                                                        <LocalAtmIcon style={{color: 
                                                                            user.purchasedProduct?.expiryDate?.seconds !== undefined && new Date(user.purchasedProduct.expiryDate.seconds * 1000) > this.today ? "green" : "red"}} 
                                                                            fontSize={"small"} />

                                                                    </Tooltip>
                                                                :
                                                                    <Tooltip title={'No Subscription!'}>
                                                                        <AvTimerIcon style={{
                                                                            color: "orange"}} 
                                                                            fontSize={"small"} />
                                                                    </Tooltip>
                                                                
                                                            }
                                                            {user.purchasedProduct?.autoAssignFlag &&
                                                                <Tooltip title={'Monthly Auto-Subscription active'}>
                                                                    <UpdateIcon style={{color: "black"}} 
                                                                        fontSize={"small"} />

                                                                </Tooltip>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Tooltip title={'Last Engagement: ' + ConvertTimestampToDateString(user.lastEngagement, true) + ', AI Chats ' + user.dialogueCounter + ', Experience Points (XP): ' + user.engagementCounter }>
                                                        {(user.dialogueCounter === undefined || user.dialogueCounter < 1)
                                                            ?   <AirplanemodeInactiveIcon style={{color: "red"}} fontSize={"small"}/>
                                                            :  ( user.lastEngagement?.seconds !== undefined && user.lastEngagement !== null && new Date(user.lastEngagement.seconds * 1000 ) > this.last2Weeks )
                                                                ?    <RowingIcon style={{color: "green"}} fontSize={"small"}/>
                                                                :    <HotelIcon style={{color: "orange"}} fontSize={"small"}/>
                                                                    
                                                        }
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                    }
                                </Card>
                            ))
                        :
                            <div>No users available or no group added yet!</div>
                    }

                </Grid>
            )
        }
        else{
            return(
                <div>No group added yet!</div>
            )
        }
    }

    renderUserCard = (user, uid) => {
        const {theme} = this.props;
        var date = new Date();
        if(user.lastEngagement?.seconds !== undefined){
            //console.log('user.lastEngagement', user.lastEngagement)
            var lastActionDate = user.lastEngagement && ConvertTimestampToDateString(user.lastEngagement);
            //console.log('lastActionDate', lastActionDate)
            date = new Date(lastActionDate.substring(0, 10));
        }
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        secondColor = theme.palette.themeAlertOrange;
        var action = {done:true};
        var dt = new Date(Date.now() - 12096e5); // minus 2 weeks
        var dtSeconds = dt.getTime() / 1000;
        if(user.lastEngagement && user.lastEngagement?.seconds> dtSeconds){
            secondColor = theme.palette.themeAlertGreen;
        }
        else if(action.offTrack ) secondColor = theme.palette.themeAlertOrange;
        else if(action.important ) secondColor = theme.palette.themeAlertBlue;
        else if(action.urgent ) secondColor = theme.palette.themeAlertRed;
        else if(action.failed ) secondColor = theme.palette.themeGrey;
        var background = 'linear-gradient(to bottom, '+secondColor+' 0px, '+theme.palette.white+' 180px)';
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //console.log(user)
        return(
            <Card key={uid}  style={{...theme.cardJournalDayHighlight, background: background }}   >
                <CardContent>
                    <Grid container 
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            {user.programLogo !== undefined && user.programLogo !== ''
                                ?
                                    <Avatar
                                        style={{
                                            border: '0.1px solid lightgray'
                                        }}
                                        alt={user.username} 
                                        src={user.programLogo} 
                                    />
                                :
                                    <Avatar
                                        style={{
                                            border: '0.1px solid lightgray'
                                        }}
                                        alt={user.username} 
                                        src={Logo} 
                                    />
                            }
                        </Grid>
                        <Grid item>
                            <span style={theme.textSupport}>Timezone:&nbsp;</span>{user.timezone}
                        </Grid>
                    </Grid>
                    <div style={{textAlign: 'left'}}>
                        <h3>
                            {(!(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("COACHADMIN")) && (user.roles?.includes("default") && user.roles?.length === 1 )) 
                                ? user.username?.substring(0, 9)+"*****" 
                                : user.username 
                            }
                        </h3>
                    </div>
                    <p> 
                        {(user.roles?.length !== undefined && !(user.roles?.length === 1 && user.roles?.includes("default")))
                            ?
                                <>
                                    <span style={theme.textSupport}></span>{( user.roles?.includes("default") && user.roles?.length ===1  ) ? "*****" : user.email }
                                    <IconButton
                                        onClick={() => {window.location = ('mailto:'+user.email)}}
                                    >
                                        <EmailIcon/>
                                    </IconButton>
                                </>
                            :
                                'Anonymous account'
                        }
                    </p>
                    {user.profile &&
                        <p>
                            {Object.keys(user.profile)?.map( (item, index) => (
                                <span key={'profile_'+index}>
                                    {item}:&nbsp;<strong>{user.profile[item]}</strong> &nbsp;|&nbsp;
                                </span>
                            ))}
                        </p>
                    }
                    {
                        RenderCoachCard(this, user)
                    }

                    <Grid container 
                        direction="row"
                        spacing={2}
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Button onClick={() => this.handleOpenUserSettings(user, uid)}>
                                <span style={{inlineSize: 'max-content'}}><LibraryBooksIcon style={theme.inlineIconLeft}/>Quests</span>
                            </Button>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'center'}}>
                            <Button onClick={() => this.handleFollowUpSettings(user, uid)}>
                                <span style={{inlineSize: 'max-content'}}><FormatListNumberedIcon style={theme.inlineIconLeft}/>Goals</span>
                            </Button>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'center'}}>
                            <Button onClick={() => this.handleOpenNotificationSettings(user, uid)}>
                                <span style={{inlineSize: 'max-content'}}><NotificationsActiveIcon style={theme.inlineIconLeft}/>Alerts</span>
                            </Button>
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <Button onClick={() => this.handleOpenUserAdmin(user, uid)}>
                                <span style={{inlineSize: 'max-content'}}><SettingsIcon style={theme.inlineIconLeft}/>Profile</span>
                            </Button>
                        </Grid>
                        {user.purchasedProduct !== undefined && user.purchasedProduct.owned 
                        ?
                            <>
                                <Grid item xs={2}>
                                    <LocalAtmIcon 
                                        style={{
                                            fontSize: 50,
                                            color: user.purchasedProduct.expiryDate?.seconds !== undefined && new Date(user.purchasedProduct.expiryDate.seconds * 1000) > this.today ? "green" : "red",
                                        }} 
                                    />
                                    {user.purchasedProduct?.autoAssignFlag &&
                                        <UpdateIcon
                                            style={{ fontSize: 50}} 
                                        />
                                    }
                                </Grid>
                                <Grid item xs={7}>
                                    Subscription Status until: {ConvertTimestampToDateString(user.purchasedProduct.expiryDate, true)}
                                    {user.purchasedProduct?.autoAssignFlag &&
                                        <strong style={{color:'red'}}><br/>MONTHLY subscription</strong>
                                    }
                                </Grid>
                            </>
                        :   
                            user.purchasedProduct?.owned === false && user.purchasedProduct?.purchaseDate?.length > 0
                            ?
                                <>
                                    <Grid item xs={2}>
                                        <AvTimerIcon style={{ color: "orange", fontSize: 50 }} />
                                    </Grid>
                                    <Grid item xs={7}>
                                        Cancelled subscription!
                                    </Grid>
                                </>
                            :
                                <>
                                    <Grid item xs={2}>
                                        <AvTimerIcon style={{ color: "grey", fontSize: 50 }} />
                                    </Grid>
                                    <Grid item xs={7}>
                                        User has no subscription!
                                    </Grid>
                                </>
                        }
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <Button onClick={() => this.handleOpenUserSeats(user, uid)}>
                                <span style={{inlineSize: 'max-content'}}><AddShoppingCartIcon style={theme.inlineIconLeft}/>Seats</span>
                            </Button>
                        </Grid>
                    </Grid>
                    <hr/>
                    {this.renderUserAssessments(user)}
                    {this.state.reviewContentList?.length > 0 && this.renderUserContent(user)}
                    <CardProgramProgress
                        isCoach= {true}
                        engagementStats= {undefined}
                        engagementBenchmarkQuality= {400}
                        engagementBenchmarkCategory= {undefined}
                        leadershipCategories= {this.props.leadershipCategories}
                        leadershipQualities= {this.props.leadershipQualities}
                        user= {user}
                    />
                    <hr/>
                    { user.lastEngagement!==undefined
                        ? <DateBadge valueMonth={months[date.getMonth()]} valueDay={date.getDate()}/> 
                        : <h1>-</h1>
                    }
                    <Grid container 
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={2}>
                            {(user.dialogueCounter === undefined || user.dialogueCounter < 2 || user.lastEngagement?.seconds === undefined)
                                ?   <AirplanemodeInactiveIcon style={{color: "red"}} fontSize={"small"}/>
                                :  ( user.lastEngagement?.seconds !== undefined && new Date(user.lastEngagement.seconds * 1000 ) > this.last2Weeks )
                                    ?    <RowingIcon style={{color: "green"}} fontSize={"small"}/>
                                    :    <HotelIcon style={{color: "orange"}} fontSize={"small"}/>
                                        
                            }
                        </Grid>
                        <Grid item xs={10}>
                            {'Last Engagement: ' + ConvertTimestampToDateString(user.lastEngagement, true)}
                            <br/>AI Chats: {user.dialogueCounter}
                            <br/>Experience Points (XP): {user.engagementCounter }
                        </Grid>
                    </Grid>
                    <p> <span  style={theme.textSupport}>Last Priming:&nbsp;</span>{user.primingSessionFinishedAt && ConvertTimestampToDateString(user.primingSessionFinishedAt, true)}</p>
                    <p> <span  style={theme.textSupport}>Last Reflection:&nbsp;</span>{user.reflectionSessionFinishedAt && ConvertTimestampToDateString(user.reflectionSessionFinishedAt, true)}</p>
                    <p> <span  style={theme.textSupport}>Last Engagement:&nbsp;</span>{lastActionDate}</p>
                    <p> <span  style={theme.textSupport}>Last Reminder:&nbsp;</span>{user.lastEmailNotificationSentAt && ConvertTimestampToDateString(user.lastEmailNotificationSentAt, true)}</p>
                    { user.mobileDevicePushNotificationAllowed === true &&
                        <p> <span  style={theme.textSupport}>User allowed smart phone push notifications</span></p>
                    }
                    <p> <span  style={theme.textSupport}># of AI Dialogues:&nbsp;</span>{user.dialogueCounter}
                        {user.purchasedProduct!==undefined && user.purchasedProduct.owned===true
                            ? ' / ' + user.purchasedProduct.title + ' > ' + ConvertTimestampToDateString(user.purchasedProduct.expiryDate, true)
                            : ' / (Trial) '+ (user.freeDialogues?user.freeDialogues:'0')
                        }
                    </p>
                    {(( user.roles?.includes("COACHEE") || user.roles?.includes("OBSERVE") ))
                        ?
                            <Button onClick={() => this.handleOpenNotificationSettings(user, uid)}>
                                <span><NotificationsActiveIcon style={theme.inlineIconLeft}/>Nudges</span>
                            </Button>
                        :
                            <Button disabled>
                                <span><NotificationsActiveIcon style={theme.inlineIconLeft}/>Nudges</span>
                            </Button>
                    }
                    <hr/>
                    <p> <span  style={theme.textSupport}>Current Call To Action:</span><br/>{user.ctaText }</p>
                    <p> <span  style={theme.textSupport}>Vision/North Star Goal:</span><br/>{user.achievementGoal }</p>
                    {(true ||  user.roles?.includes("COACHEE") || user.roles?.includes("OBSERVE") )
                        ?
                            <Button onClick={() => this.handleFollowUpSettings(user, uid)}>
                                <span><FormatListNumberedIcon style={theme.inlineIconLeft}/>Coachee</span>
                            </Button>
                        :
                            <Button disabled>
                                <span><FormatListNumberedIcon style={theme.inlineIconLeft}/>Coachee</span>
                            </Button>
                    }
                    <hr/>
                    <Grid container spacing={0} direction='row' justify="space-between"  alignItems="center">
                        <Grid item xs={4}> 
                            <span  style={theme.textSupport}>Current Category:&nbsp;</span>
                        </Grid>
                        <Grid item xs={8}> 
                            <AvatarCard fallbackText={true} key={user.currentCategory} dict={this.props.leadershipCategories} type='category' item={user.currentCategory} squareSize='20' />
                        </Grid>
                        <Grid item xs={4}> 
                            <span  style={theme.textSupport}>Current main Quest:&nbsp;</span>
                        </Grid>
                        <Grid item xs={8}> 
                            <AvatarCard fallbackText={true} key={'_'+user.currentLeadershipQuality} dict={this.props.leadershipQualities} type='quality' item={user.currentLeadershipQuality} squareSize='20' />
                        </Grid>
                        {user.todaysLeadershipQuality !== undefined && user.todaysLeadershipQuality !== ''
                            &&
                            <>
                                <Grid item xs={4}> 
                                    <span  style={theme.textSupport}>Current 2nd Quest:&nbsp;</span>
                                </Grid>
                                <Grid item xs={8}> 
                                    <AvatarCard fallbackText={true} key={'_'+user.todaysLeadershipQuality} dict={this.props.leadershipQualities} type='quality' item={user.todaysLeadershipQuality} squareSize='20' />
                                </Grid>
                            </>
                        }
                    </Grid>
                    
                    {(true || user.roles?.includes("COACHEE") || user.roles?.includes("OBSERVE") )
                        ?
                            <Button onClick={() => this.handleOpenUserSettings(user, uid)}>
                                <span><LibraryBooksIcon style={theme.inlineIconLeft}/>Plan</span>
                            </Button>
                        :
                            <Button disabled>
                                <span><LibraryBooksIcon style={theme.inlineIconLeft}/>Plan</span>
                            </Button>
                    }
                </CardContent>
            </Card>
        )
    }
    
    renderTeamCard = (key) => {
        const {theme} = this.props;
        const {coachees, parentGroup, insightsTeam} = this.state;
        //var date = new Date();
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        //var action = {done:true};
        var dt = new Date(Date.now() - 12096e5); // minus 2 weeks
        //var dtSeconds = dt.getTime() / 1000;
        var background = 'linear-gradient(to bottom, '+secondColor+' 0px, '+theme.palette.white+' 180px)';
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //console.log('team state', this.state)
        return(
            <Card key={key} style={{...theme.cardJournalDayHighlight, background: background }}   >
                <CardContent>
                    <p>
                        {parentGroup} {">"} Team {key}
                    </p>
                    {/***
                    <p>
                        Owners
                        {coachGroupsOwners?.[key]}
                    </p>
                     */}
                    <p>
                        {""+(coachees?.[key]?.length || '') +" team members"}
                    </p>
                    <hr/>
                    {insightsTeam?.engagements?.total !== undefined
                    &&
                    <>
                        <CardProgramProgress
                            isCoach= {true}
                            engagementStats= {insightsTeam}
                            engagementBenchmarkQuality= {400}
                            engagementDivider= {this.state.teamRanking?.length || 1}
                            engagementBenchmarkCategory= {undefined}
                            leadershipCategories= {this.props.leadershipCategories}
                            leadershipQualities= {this.props.leadershipQualities}
                            user= {undefined}
                        />
                        {this.state.teamRanking?.length > 0 &&
                            <>
                                <h4>
                                    Team Ranking
                                </h4>
                                <Grid container spacing={0} direction='row' justify="space-between"  alignItems="center">
                                    <Grid item xs={1}>
                                        <strong>Rank</strong>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <strong>Name</strong> 
                                    </Grid>
                                    <Grid item xs={7}>
                                        <strong>Engagemenmt / Experience Points</strong>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <strong></strong>
                                    </Grid>
                                    {this.state.teamRanking.map((user, index) =>  (
                                        <React.Fragment key={"rankt_"+index}>
                                            <Grid item xs={1}>
                                                No. {index+1}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {user.username}
                                            </Grid>
                                            <Grid item xs={7}>
                                                {'XP: ' + user.engagementCounter + ', Last: ' + ConvertTimestampToDateString(user.lastEngagement, true) + ', AI Chats ' + user.dialogueCounter}
                                            </Grid> 
                                            <Grid item xs={1}>
                                                 {(user.dialogueCounter === undefined || user.dialogueCounter < 1)
                                                    ?   <AirplanemodeInactiveIcon style={{color: "red"}} fontSize={"small"}/>
                                                    :  ( user.lastEngagement?.seconds !== undefined && user.lastEngagement !== null && new Date(user.lastEngagement.seconds * 1000 ) > this.last2Weeks )
                                                        ?    <RowingIcon style={{color: "green"}} fontSize={"small"}/>
                                                        :    <HotelIcon style={{color: "orange"}} fontSize={"small"}/>
                                                }
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </>
                        }
                        <h4>
                        eXperience Points (XP)
                        </h4>
                        <p style={theme.textSupportCenter}>
                            Engagement Map &bull; Topics and XP gained
                        </p>
                        <CardTotalStatsTopics
                            engagementStats= {insightsTeam.engagements || undefined}
                            engagementBenchmarkQuality= {400}
                            //engagementBenchmarkCategory={ this.state.engagementBenchmarkCategory || undefined}
                            leadershipCategories= {this.props.leadershipCategories}
                            leadershipQualities= {this.props.leadershipQualities}
                            //user={undefined}
                            noMargin={true}
                            statspage={true}
                            //rerender={value !== "Engagement"? false : true}
                        />
                    </>
                    }
                </CardContent>
            </Card>
        )
    }

    renderUserAssessments(user) {
        //console.log('props set', this.state.questionnaireSets)
        return(
            <Grid container 
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={1}
            >   
                <DialogAssessment
                    defaultSet={this.props.defaultSet || undefined}
                    isCoach={true}
                    handleOpen={this.state.openAssessment || false}
                    assessmentId = {this.state.assessmentId || "NOASSESS"}
                    questionnaireDetails = {(this.state.questionnaireDetails || undefined)}
                    eggupAssessment = {false}
                    handleClose={() => {
                        this.setState({openAssessment: false});
                    }}
                    user={user}
                />
                {this.state.questionnaireSets?.map((set, index) => {
                    if(user?.userAssessment?.O >=0 && set.id === "5pEXdA6wH9eYIyFHTFlx"){
                        return(
                            <Grid item xs={6} key={'set1'}>
                                <CardAssessmentFactors 
                                    user={user}
                                    isCoach={true}
                                    noMargin={true}
                                    statspage={true}
                                    leadershipCategories= {this.props.leadershipCategories}
                                    leadershipQualities= {this.props.leadershipQualities}
                                    handlerNext={() => this.setState({openAssessment: true, assessmentId: set.id, questionnaireDetails: set})}
                                />
                            </Grid>
                        )
                    }
                    else if (user?.userAssessment?.pP >=0 && set.id === "jk0HXnxdDJ2fCmqswkYS" ){
                        return(
                            <Grid item xs={6} key={'set2'}>
                                <CardAssessmentFactors 
                                    user={user}
                                    isCoach={true}
                                    showPERMA={true}
                                    noMargin={true}
                                    statspage={true}
                                    leadershipCategories= {this.props.leadershipCategories}
                                    leadershipQualities= {this.props.leadershipQualities}
                                    handlerNext={() => this.setState({openAssessment: true, assessmentId: set.id, questionnaireDetails: set})}
                                />
                            </Grid>
                        )
                    }
                    else if(set.custom && user.programs.includes(set.program)){
                        return(
                            <Grid item xs={6} key={'setx_'+index}>
                                <CardAssessmentFactors 
                                    user={user}
                                    isCoach={true}
                                    showCustom={true}
                                    noMargin={true}
                                    statspage={true}
                                    leadershipCategories= {this.props.leadershipCategories}
                                    leadershipQualities= {this.props.leadershipQualities}
                                    questionnaireDetails= {set}
                                    handlerNext={() => this.setState({openAssessment: true, assessmentId: set.id, questionnaireDetails: set})}
                                />
                            </Grid>
                        )
                    }
                })

                }
            </Grid>
        )
    }

    renderUserContent(user){
        const {theme} = this.props;
        if(this.state.reviewContentList?.length > 0 && user?.uid !== undefined){
            return(
                <>
                    <Grid container 
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                    >   
                        <Dialog
                            open={this.state.openInteractiveArticle || false}
                            onClose={() => this.setState({openInteractiveArticle: false})}
                            fullWidth
                            maxWidth="sm"
                            >
                            <DialogTitle>Interactive Content</DialogTitle>
                            {this.state.openInteractiveArticle &&
                                <DialogContent>
                                    {this.state.contentData?.interactiveQuestions?.length > 0
                                        &&
                                        <>
                                            <h2>
                                                Results
                                            </h2>
                                            <p></p>
                                            {this.state.contentData && this.state.contentData.interactiveQuestions.map( (iQ, indexIQ) => (
                                                <div key={"iqItem_"+indexIQ}>
                                                    {iQ.description !== undefined && iQ.description !== '' &&
                                                        <p style={{color: theme.palette.secondary.light}}> {iQ.description}</p>
                                                    }
                                                    {iQ.question !== undefined &&
                                                        <strong style={{color: theme.palette.secondary.light}}>{iQ.question}</strong>
                                                    }
                                                    {(iQ.question !== undefined || iQ.description!== undefined ) &&
                                                        <p>
                                                            <br/>
                                                            {this.state.loadingInteractive
                                                                ?   <span>"...loading"</span>
                                                                :   <strong>
                                                                        {(this.state.dataInteractive?.["interactiveQuestionsResponse_"+indexIQ] || "n/a")}
                                                                    </strong>
                                                            }
                                                        </p>
                                                    }
                                                    <>
                                                </>
                                                </div>
                                            ))}
                                            <div>
                                                <h2>Add Comment</h2>
                                                {this.state.loadingInteractive
                                                    ?   ""
                                                    :
                                                        <TextField
                                                            //name={"iqItem_"+indexIQ}
                                                            //label="Interactive Content"
                                                            style={ {fontSize: '16px'} }
                                                            type="text"
                                                            variant="outlined"
                                                            placeholder="Write down your thoughts ..."
                                                            fullWidth
                                                            multiline
                                                            defaultValue={(this.state.dataInteractive?.coachResponse || '')}
                                                            value={(this.state["comment_"+this.state.contentId+user.uid] || this.state.dataInteractive?.coachResponse || '')}
                                                            onChange={(e) => {this.setState({ ["comment_"+this.state.contentId+user.uid] : e.target.value, ["commentUpload_"+this.state.contentId+user.uid]: false})}}
                                                            InputProps={{
                                                                style:{fontSize: '16px'},
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {this.state["comment_"+this.state.contentId+user.uid]?.length > 2
                                                                            ?   this.state["commentUpload_"+this.state.contentId+user.uid]
                                                                                ?   <CheckCircleIcon style={{ color: theme.palette.themeGold }} />
                                                                                :   <IconButton
                                                                                        edge="end"
                                                                                        aria-label="Send message"
                                                                                        onClick={ () => {
                                                                                            this.props.firebase.contentBookmark(this.state.contentId, user.uid).set({
                                                                                                coachResponse: (this.state["comment_"+this.state.contentId+user.uid] || "")
                                                                                            }, {merge:true} );
                                                                                            this.setState({["commentUpload_"+this.state.contentId+user.uid]: true})
                                                                                        }}
                                                                                        >
                                                                                        <SendIcon />
                                                                                    </IconButton>
                                                                            :            
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    aria-label="Write response"
                                                                                    disabled={true}
                                                                                    >
                                                                                    <SendIcon />
                                                                                </IconButton>
                                                                        }
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </>
                                        }
                                </DialogContent>
                            }
                            <DialogActions>
                                <Button onClick={() => this.setState({openInteractiveArticle: false})}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {this.state.reviewContentList.map((cid, index) => {
                            var contentDict = (this.state.reviewContentDicts?.[cid] || {});
                            if(user.programs?.includes(contentDict?.program || "FALSECONFEONT")){
                                return(
                                    <React.Fragment key={'ksit'+index}>
                                        <Grid item xs={2} style={{paddingLeft:5, paddingBottom:5, textAlign: 'left'}}>
                                            {contentDict.url
                                                ?
                                                    <div style={{
                                                        backgroundImage: `url(${contentDict.url})`,
                                                        backgroundPosition: "center",
                                                        borderRadius: theme.borderRadius,
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        width: "auto",
                                                        height: 40,
                                                        margin: 5,
                                                        }}>
                                                    </div>
                                                :
                                                    "xx"
                                            }
                                        </Grid>
                                        <Grid item xs={8}>
                                            <strong>{contentDict?.title}</strong>
                                            <br/>
                                            {contentDict?.subtitle}
                                        </Grid>
                                        <Grid item xs={2} style={{paddingLeft:5, textAlign: 'right'}}
                                            onClick={() => {
                                                this.setState({loadingInteractive: true, openInteractiveArticle: true, contentId: cid, contentData: (contentDict || {})});
                                                this.props.firebase.contentBookmark(this.state.contentId, user.uid).get().then(doc => {
                                                    var userDict = doc.data();
                                                    //console.log('userDict', userDict, this.state.contentId, user.uid);
                                                    this.setState({loadingInteractive: false, dataInteractive: userDict});
                                                })
                                            }}
                                        >
                                            <span style={{color: theme.palette.secondary.main}}>Show</span>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                        })}
                    </Grid>
                </>
            )
        }
    }

    handleClose = () => {
        this.setState({
            openVariant: undefined,
            openDialogueChats: false, 
            openUserSettings: false, 
            openUserAdmin: false, 
            openNotificationSettings: false,
        })
    }
    handleOpenDialogueChats = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openDialogueChats: true} )
    }
    handleOpenUserSettings = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openUserSettings: true} )
    }
    handleOpenUserAdmin = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openUserAdmin: true, openVariant: 'admin'} )
    }
    handleOpenUserSeats = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openUserAdmin: true, openVariant: 'seats'} )
    }
    handleOpenNotificationSettings = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openNotificationSettings: true, openVariant: 'reminders'} )
    }
    handleFollowUpSettings = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openNotificationSettings: true, openVariant: 'followups'} )
    }

    dialogueChats = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        return(
            <RemoveScroll enabled={this.state.openDialogueChats || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openDialogueChats || false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            AI Chats
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { selectedId !== undefined && selectedId !== ""
                            ? <DayList userId={selectedId} user={selectedUser} isCoach={true}/>
                            : <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueUserSettings = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openUserSettings || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openUserSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}>
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Personal Coaching Plan
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.handleClose}>
                                    {"Done"}
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        <h4 style={theme.textSupport}>
                            Personal Coaching Plan
                        </h4>
                        {selectedId !== undefined && selectedId !== ""
                            ?   <CoachUserSetting 
                                    defaultSet={this.props.defaultSet || undefined}
                                    userId={selectedId} user={selectedUser}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                />
                            :   <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueUserAdmin = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openUserAdmin || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openUserAdmin|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}>
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Advanced user settings
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.handleClose}>
                                    Done
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        <h4 style={theme.textSupport}>
                            Advanced user settings
                        </h4>
                        { selectedId !== undefined && selectedId !== ""
                            ?   <CoachGroupUsersAdmin 
                                    defaultSet={this.props.defaultSet || undefined}
                                    userId={selectedId} 
                                    user={selectedUser}
                                    newUser={false}
                                    handleReload={(clearFields) => {
                                        if(clearFields){
                                            this.setState({showClientDetailsDict: undefined});
                                        }
                                        if(this.state.parentGroup?.length > 2 && this.state.coachGroupsIds?.[this.state.parentGroup]?.length > 0){
                                            console.log('reload the same')
                                            this.loadCoachees(this.state.coachGroupsIds, this.state.parentGroup, false);
                                        }
                                        else{
                                            console.log('reload all', this.state)
                                            LoadCoachingGroups(this, this.context);
                                        }
                                    }}
                                    handleClose={this.handleClose}
                                    variant={this.state.openVariant || undefined}
                                    coachGroupsOwners={this.state.coachGroupsOwners || undefined}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                    ownedGroupsCodes={this.state.ownedGroupsCodes}
                                    ownedGroupsCodesNames={this.state.ownedGroupsCodesNames}
                                    ownedCompetitionIds={this.state.ownedCompetitionIds}
                                />
                            :   <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
    dialogueNotificationSettings = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openNotificationSettings|| false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openNotificationSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Goals / Call To Action
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.state.openNotificationSettings && selectedId !== undefined && selectedId !== ""
                            ?   <CoachUserActions 
                                    defaultSet={this.props.defaultSet || undefined}
                                    userId={selectedId} 
                                    variant={this.state.openVariant || undefined}
                                    user={selectedUser}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                />
                            :   <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

}//end class

export function RenderCoachCard(that, user){
    const {theme} = that.props;
    if(AccessCheckCoachCreator(user) || AccessUsersPanel(user) ){
        return(
            <Card style={{padding:20, backgroundColor:"white", marginBottom: 10}}> 
                <p >
                    <SchoolIcon style={{...theme.inlineIconLeft, color:"blue"}} fontSize={"small"}/>
                    {AccessCheckCoachCreator(user)
                        ?   'ADMIN CREATOR ACCOUNT'
                        :   'REMOTE COACH ACCOUNT'
                    }
                </p>
                {AccessCheckCoachCreator(user) &&
                    <p style={theme.textErrorCenter}>
                        <strong>Creator Tool access</strong>
                        <br/>
                        <strong style={theme.textSettings}>
                            <span style={theme.textSettings.settingsTextValue}>{user?.programCode || "n/a"}</span>
                            <span >Coach-code:</span>
                            <br/>
                        </strong>
                    </p>
                }
                {AccessUsersPanel(user) &&
                    <p style={theme.textErrorCenter}>
                        <strong>Teams Panel access </strong>
                        <br/>
                        {user?.seatsMonthlyToGive > 0
                        && 
                            <strong style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextValue}>{user?.seatsMonthlyToGive || 0}</span>
                                <span >Monthly Seats Available:</span>
                                <br/>
                            </strong>
                        }
                        {user?.seatsToGive > 0
                        && 
                            <strong style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextValue}>{user?.seatsToGive || 0}</span>
                                <span >Extra Seats Available (Not Time-Bound):</span>
                                <br/>
                            </strong>
                        }
                        {(true || user?.seatsToGive > 0)
                        && 
                            <span style={theme.textSettings}>
                                <span style={{...theme.textSettings.settingsTextValue, color: theme.palette.black}}>{user?.seatsPurchasedTotal || "n/a"}</span>
                                <span>Past purchases:</span>
                                <br/>
                            </span>
                        }
                    </p>
                }
            </Card>
        )
    }
    else{
        return ""
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CoachUsersWindows);
