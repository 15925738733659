import React, { Component } from 'react';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import {DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
import CircularSlider from '@fseehawer/react-circular-slider';
//import SentimentVeryDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentVeryDissatisfiedTwoTone';
import MoodBadTwoToneIcon from '@material-ui/icons/MoodBadTwoTone';
import SentimentDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentDissatisfiedTwoTone';
import SentimentSatisfiedTwoToneIcon from '@material-ui/icons/SentimentSatisfiedTwoTone';
import EmojiEmotionsTwoToneIcon from '@material-ui/icons/EmojiEmotionsTwoTone';
import SentimentVerySatisfiedTwoToneIcon from '@material-ui/icons/SentimentVerySatisfiedTwoTone';
import {AddEngagementPoints} from '../Modules/AddEngagementPoints';

import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
});


const StyledSlider = withStyles({
    root: {
        color: '#fcd750',
        height: 9,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#2d2c4c',
        border: '3px solid #2d2c4c',
        marginTop: -6,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    mark:{
        backgroundColor: 'red',
        height: '20%',
    },
    active: {},
    track: {
        backgroundColor: '#fcd750',
        opacity: 0.38,
        height: 9,
        borderRadius: 10,
    },
    rail: {
        height: 9,
        borderRadius: 10,
    }
})(Slider);

class AssessmentSlider extends Component {
    
    _isMoundted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };    
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.assessmentKey !== undefined && this.props.assessmentKey  !== "" && this.props.user !== undefined){
            var cacheStore = null;
            var category = 'goals';
            if(this.props.variant === 'followups'){
                category = 'followups'
            }
            cacheStore = DataCacheRead('newAssess_'+category+'_'+this.props.assessmentKey +'_'+this.props.user.uid)
            //console.log("read",cacheStore)
            if(cacheStore!== null && cacheStore.isCacheExpired!== true && cacheStore.cacheData > 0){
                if(this._isMounted) this.setState({['measure_'+this.props.assessmentKey ] : cacheStore.cacheData, ['lastMeasure_'+this.props.assessmentKey ] : cacheStore.cacheData}) ;
            }
        }
        else if(this.props.assessmentArchive?.length>0){
            //console.log('archive', this.props.assessmentArchive)
            if(this._isMounted) {
                this.setState({
                    ['measure_'+this.props.assessmentKey ] : undefined, 
                    ['lastMeasure_'+this.props.assessmentKey ] : undefined}
                );
            }
        }
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    render() {
        const {assessmentKey, assessmentText, assessmentQuestion, assessmentAction} = this.props;
        if(assessmentAction !== undefined && (assessmentText !== undefined || assessmentQuestion !== undefined)){
            return this.returnSlider(assessmentKey, assessmentText, assessmentQuestion, assessmentAction)
        }
        else if(assessmentKey !== undefined && (assessmentText !== undefined || assessmentQuestion !== undefined)){
            return this.returnSlider(assessmentKey, assessmentText, assessmentQuestion)
        }
        else return ""
    }

    returnSlider(key, item, question, action=undefined){
        //console.log('question', question, this.props)
        if(key===undefined && action.messageId !== undefined){
            key = 'random_'+action.leadership_category;
        }
        const {theme} = this.props;
        return(
            <div>
                <Grid
                    container
                    key={"gridforbar_"+key}
                    direction="row"
                    justify="space-between"
                    alignItems="stretch"
                    //className={classes.chat}
                >
                    <Grid item xs={12}>
                        {this.props.hideAssementQuestion!==true &&
                            <p style={{textAlign: 'center'}}>
                                <strong>
                                    {
                                        question
                                    }    
                                </strong>
                            </p>
                        }
                        {this.props.hideAssementText !== true &&
                            <p style={{textAlign: 'center'}}>
                                <span>
                                    {item?.length > 79
                                        ?
                                            <span>{item.substring(0, 79) + "..."}</span>
                                        :
                                            item
                                    }    
                                </span>
                            </p>
                        }
                    </Grid>
                    {!this.props.circular
                        &&
                        <Grid item xs={12} key={"name"+key}>
                            <div style={{position:'relative',
                                textAlign: 'center',
                                zIndex: 2,
                                top: 0,
                                height: 10,
                                width: '100%',
                                color: theme.palette.secondary.light}}
                                >
                                <span>
                                    {this.props.textMeasure
                                        ?   this.props.textMeasure
                                        :   'Satisfaction'
                                    }
                                </span>
                            </div>
                        </Grid>
                    }
                    <Grid item xs={12} key={"sgridder_"+key}>
                        {this.props.circular
                            ?
                                <div style={{width: '100%', textAlign: 'center', position: 'relative'}}>
                                    <div style={{position: 'absolute', left: 'calc(50% - 100px)', top: 'calc(50% - 100px - 2px)'}}>
                                        {this.state['measure_'+key] > 90
                                            ?   <SentimentVerySatisfiedTwoToneIcon
                                                    style={{ 
                                                        fontSize: 200,
                                                        color: theme.palette.themeAlertGreen
                                                    }}
                                                />
                                            :   this.state['measure_'+key] > 55
                                                    ? <EmojiEmotionsTwoToneIcon  
                                                            style={{ 
                                                                fontSize: 200,
                                                                color: theme.palette.themeAlertGreen
                                                            }}
                                                        />
                                                    :   (this.state['measure_'+key] >= 45 || this.state['measure_'+key] === undefined)
                                                        ?   <SentimentSatisfiedTwoToneIcon 
                                                                style={{ 
                                                                    fontSize: 200,
                                                                    color: theme.palette.themeAlertGreen
                                                                }}
                                                            />
                                                        :   (this.state['measure_'+key] >= 15)
                                                            ?   <MoodBadTwoToneIcon 
                                                                    style={{ 
                                                                        fontSize: 200,
                                                                        color: theme.palette.themeAlertOrange
                                                                    }}
                                                                />
                                                            :   <SentimentDissatisfiedTwoToneIcon 
                                                                    style={{ 
                                                                        fontSize: 200,
                                                                        color: theme.palette.themeAlertRed
                                                                    }}
                                                                />
                                        }
                                    </div>
                                    <CircularSlider
                                        key={"slider_"+key}
                                        labelTop={'Satisfaction'}
                                        labelBottom={'Level'}
                                        width={170}
                                        maxWidth={"100%"}
                                        min={0}
                                        max={100}
                                        //dataIndex={this.state['lastMeasure_'+key] || 50}
                                        dataIndex={this.state['measure_'+key] !== undefined ? this.state['measure_'+key] : this.props.assessmentStartValue ? this.props.assessmentStartValue : 50}
                                        //hideLabelValue={true}
                                        //offsetAngle={180}
                                        //limit={320}
                                        hideLabelValue={true}
                                        progressSize={9}
                                        knobPosition={'bottom'}
                                        progressColorFrom={theme.palette.themeAlertGreen}
                                        progressColorTo={theme.palette.themeAlertGreen}
                                        //trackColor={theme.palette.themeGold}
                                        trackColor={this.state['measure_'+key] > 45 ? theme.palette.themeGold : 'transparent'}
                                        knobColor={theme.palette.themeBlue}
                                        step={1}
                                        labelStep={100}
                                        knobSize={48}
                                        isDragging={(dragged) => {
                                            var value = this.state['measure_'+key] || 50;
                                            //this.setState({isDragging: dragged});
                                            if(!dragged){
                                                //console.log('dragged', dragged, this.state['measure_'+key])
                                                this.saveUserAssessmentByKey(key, item, value, question);
                                                if (this.props.onRelease!== undefined){
                                                    this.props.onRelease()
                                                }
                                            }
                                        }}
                                        onChange={(set) => {
                                            this.setState({['measure_'+key]: set})
                                            if(this.state.isDragging){
                                                //console.log('dragging', this.state.isDragging, set)
                                            }
                                            else{
                                                //console.log('release', this.state.isDragging, set)
                                                //this.setState({['measure_'+key]: set.value})
                                            }
                                        }}
                                    >
                                    </CircularSlider>
                                </div>
                            :
                                <StyledSlider
                                    key={"slider_"+key}
                                    value={this.state['measure_'+key] !== undefined ? this.state['measure_'+key] : this.props.assessmentStartValue ? this.props.assessmentStartValue : 50}
                                    marks={this.state['lastMeasure_'+key] !== undefined ? [{value: this.state['lastMeasure_'+key], label: 'recently' }] : false}
                                    min={0}
                                    max={100}
                                    //marks={[{value: 0}, {value:100}, {value:this.state.botTraits[trait]}]}
                                    aria-labelledby={'Satisfaction'}
                                    onChange={ (event, value) => this.setState({['measure_'+key]  : value})}
                                    //valueLabelDisplay="auto"
                                    //getAriaValueText={(value) => this.showText(trait, value) }
                                    onChangeCommitted={ (event, value) => this.saveUserAssessmentByKey(key, item, value, question)} 
                                />
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{marginTop: -15, fontSize:12, color: theme.palette.themeRed}}>
                            {this.props.textMin
                                ?   this.props.textMin
                                :   'Needs work'
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{marginTop: -15, fontSize:12, color: theme.palette.themeRed, textAlign:"right"}}>
                            {this.props.textMax
                                ?   this.props.textMax
                                :   "Doing great"
                            }
                        </div>
                    </Grid>
                    {this.props.circular
                        &&
                        <Grid item xs={12} key={"name"+key}>
                            <div style={{
                                position:'relative',
                                textAlign: 'center',
                                zIndex: 2,
                                marginTop: 10,
                                height: 10,
                                width: '100%',
                                color: theme.palette.secondary.light}}
                            >
                                <span>
                                    {this.props.textMeasure
                                        ?   this.props.textMeasure
                                        :   'Satisfaction'
                                    }
                                </span>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        )   
    }

    saveUserAssessmentByKey(key, response, value, question){
        if(this.props.user?.uid?.length>1){
            var assessmentText = 'I am very disappointed with: ';
            if(value >=90) assessmentText = 'I am extremely satisfied with: ';
            else if(value >=70) assessmentText = 'I am doing well with: ';
            else if(value >=55) assessmentText = 'It is kind of okay with: ';
            else if(value >=35) assessmentText = 'I should improve: ';
            else if(value >=20) assessmentText = 'I am challenged with: ';
            var d = new Date();    
            var date = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0,10);
            var category = 'goals';
            var updateSet = {
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                date: date,
                scale: '100%',
                measure: 'satisfaction',
                value: value,
                key: key,
                category: category,
                type: 'scale',
                question: question,
                response: response,
                source: 'self',
                userId: this.props.user.uid
            }
            //console.log(updateSet, value, question);
            var cacheKey = 'newAssess_goals_'+key+'_'+this.props.user.uid;
            if(this.props.variant === 'followups'){
                category = 'followups'
                updateSet['category'] = category;
                updateSet['messageId'] = this.props.assessmentKey;
                cacheKey = 'newAssess_followups_'+key+'_'+this.props.user.uid;
            }
            //console.log('updateSet', updateSet)
            this.props.firebase.insightAssessment(this.props.user.uid, date+'_'+category+'_'+key ).set( updateSet , {merge:true});
            DataCacheWrite(cacheKey, value, 600);
            if(this._isMounted ){
                var followedUpCounter = this.state.followedUpCounter;
                if(followedUpCounter < 1) followedUpCounter = 0;
                this.setState({followedUpCounter : followedUpCounter+1, initFollowedUpCounter : this.state.initFollowedUpCounter+1});
                if (this.props.updateScore !== undefined) {
                    this.props.updateScore(value);
                }
            }
            if(this.props.variant === 'followups'){

            }
            else{
                ExtendDialogueMessage(this.props.firebase, this.props.user, assessmentText+response,  question, 'utter_ask_assessment', '', 'profile');
            }
            var engagement = {
                userId: this.props.user.uid,
                points:1,
                type:'followUp',
                message: assessmentText+response,
                origin: 'days',
            }
            if(this.props.user?.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.user.leadership_category;
            }
            if(this.props.user?.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.user.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);
        }
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(AssessmentSlider);
