import {DataCacheRead} from "../Modules/DataCache";
import {GetTimeZone} from '../Modules/LoadUserTimezone';
import { FindTextValueInDict, FindDictByKey, ReturnFilteredDict, OverlapQualityFilter } from '../Modules/LoadCategoriesAndQualities';
//import { DataCacheWrite} from "../Modules/DataCache";

export async function SetStateLoadUserData(that, db, userId){
    let userData = {};
    //.where('active', '==', true)
    var failedLoadingUser = false;
    var isConnected = false;
    checkInternetNavigator((result) => {
        isConnected = result
    })
    //console.log('SetStateLoadUserData', isConnected)
    if (isConnected) {
        var cacheStoreUser = DataCacheRead('authUser')
        //console.log('cacheStoreUser', cacheStoreUser)
        if(cacheStoreUser.cacheAvailable && cacheStoreUser.cacheData.roles !== undefined
            && cacheStoreUser.cacheData !== undefined && cacheStoreUser.cacheData.uid === userId) {
            userData = cacheStoreUser.cacheData;
            //console.log('user',cacheStoreUser);
            if(that._isMounted !== false){
                that.setState({user:userData});
            }
            var userTimezone = GetTimeZone(db, userData);
            if(userTimezone !== 'UTC'){
                userData['timezone'] = userTimezone;
            }
            //console.log('prereturn', userData)
            return userData
        }
        else if(db!==undefined && userId!==undefined) { 
            await db
            .user(userId)
            .get().then(snapshot => {
            //.onSnapshot(snapshot => {
                if(snapshot.empty){
                    // deleted or missing user content, start new signup
                    //console.log('no user push to signup')
                    userData = {}
                    //////// duplicate already done now by authentiatate context
                    //DataCacheWrite('authUser', userData, 1)
                    failedLoadingUser = true;
                    if(that._isMounted !== false){
                        that.setState({failedLoadingUser});
                    }
                    //console.log('prereturn', userData)
                    return userData
                }
                else{
                    userData = {...snapshot.data(), uid:userId}
                    //console.log('reloaded user data')
                    //////// duplicate already done now by authentiatate context
                    //DataCacheWrite('authUser', userData, 30);
                    if(that._isMounted !== false){
                        that.setState({user:userData});
                    }
                    // this operation only every 6 hours necessary
                    var dt = new Date(Date.now() - 21600); // minus 4 weeks
                    var dtSeconds = dt.getTime() / 1000;
                    if('lastEngagement' in userData && userData.lastEngagement!==undefined && userData.lastEngagement!==null && userData.lastEngagement.seconds !== undefined
                        && userData.lastEngagement.seconds < dtSeconds ) {
                            //console.log('add time')
                        var userTimezone = GetTimeZone(db, userData);
                        if(userTimezone !== 'UTC'){
                            userData['timezone'] = userTimezone;
                        }
                    }
                    //console.log('prereturn', userData)
                    return userData
                }
            })
            .catch((error) => {
                console.log("Error getting document:", error);
                if(that._isMounted !== false){
                    that.setState({failedLoadingFirestore:true});
                }
                userData = {}
                return userData
            }); 
        }
    } else {
        console.log("Error with internet connection");
        if(that._isMounted !== false){
            that.setState({failedLoadingFirestore:true});
        }
        userData = {}
        return userData
    }
} 

export function LoadUserCategoryAndQualities(that, userId, userData, leadershipCategories, leadershipQualities) {
    if(that._isMounted !== false && userId!== undefined && userData!== undefined && leadershipCategories!== undefined && leadershipQualities!== undefined){
        var mainCategory = undefined;
        var sisterQualities = [];
        var mainQualitiesDicts = undefined;
        var mainQualitiesIds = [];
        var programQualities = [];
        var oldCommentList = {}
        var cacheStore = undefined;
        var updateQualityValuesFlag = false;
        var updateQualityUserDict = {};
        try {
            mainCategory = leadershipCategories.find(c => c.value === userData.currentCategory);
            if(mainCategory !== undefined && mainCategory.personalityTraitsTarget!== undefined){
                //mainCategory.personalityTraitsTarget["N"]  = Math.abs(100-mainCategory.personalityTraitsTarget["N"] )
            }
            var mainQuality = FindDictByKey(that.props.firebase, leadershipQualities, userData.currentLeadershipQuality);
            mainQualitiesDicts = [mainQuality];
            //console.log('mainQuality', mainQuality)
            if(mainQuality === undefined){
                mainQuality = {value: userData.currentLeadershipQuality, valueID: userData.currentLeadershipQuality}
            }
            mainQualitiesIds = [mainQuality.value];
            ///// Update userset to be latest quality inheritance
            if(mainQuality?.value !== userData.currentLeadershipQuality){
                updateQualityValuesFlag = true;
                updateQualityUserDict['currentLeadershipQuality'] = mainQuality.value;
            }
            if(userData.todaysLeadershipQuality !== undefined && userData.todaysLeadershipQuality !== '' && userData.todaysLeadershipQuality.length > 3){
                if(!OverlapQualityFilter(userData.todaysLeadershipQuality, mainQualitiesIds)){
                    var todaysQuality = FindDictByKey(that.props.firebase, leadershipQualities, userData.todaysLeadershipQuality);
                    if(todaysQuality !== undefined ){
                        mainQualitiesIds.push(todaysQuality.value);
                        mainQualitiesDicts.push(todaysQuality);
                        if(todaysQuality.value !== userData.todaysLeadershipQuality){
                            updateQualityValuesFlag = true;
                            updateQualityUserDict['todaysLeadershipQuality'] = todaysQuality;
                        }
                    }
                }
            }
            cacheStore = DataCacheRead('newComment_'+mainQuality.valueID+'_'+userId)
            //console.log("read",cacheStore)
            if(cacheStore!== null && cacheStore.isCacheExpired!== true){
                oldCommentList['newComment_'+mainQuality.valueID] = cacheStore.cacheData ;
            }
            //that.setState({ mainCategory, mainQuality });
        }
        catch (err) {
            console.log('issue with finding the sister modules', err);
        }
        try {
            var getSister = true;
            var addQualityDict = undefined;
            var tempList = [];
            [1,2,3,4,5,6].forEach( number => {
                if(userData['additionalLeadershipQuality'+number] !==undefined && userData['additionalLeadershipQuality'+number] !== ""){
                    if(!OverlapQualityFilter(userData['additionalLeadershipQuality'+number], mainQualitiesIds)){
                        //console.log('adding add', userData['additionalLeadershipQuality'+number])
                        addQualityDict = FindDictByKey(that.props.firebase, leadershipQualities, userData['additionalLeadershipQuality'+number]);
                        if (addQualityDict !== undefined) { 
                            tempList.push(userData['additionalLeadershipQuality'+number] );
                            mainQualitiesDicts.push(addQualityDict);
                            if(addQualityDict.value !== userData['additionalLeadershipQuality'+number]){
                                updateQualityValuesFlag = true;
                                updateQualityUserDict['additionalLeadershipQuality'+number] = addQualityDict.value;
                            }
                            mainQualitiesIds.push(addQualityDict.value);
                            getSister = false;
                            cacheStore = DataCacheRead('newComment_'+addQualityDict.valueID+'_'+userId)
                            //console.log("read",cacheStore)
                            if(cacheStore!== null && cacheStore.isCacheExpired!== true){
                                oldCommentList['newComment_'+addQualityDict.valueID] = cacheStore.cacheData ;
                            }
                        }
                    }
                    else if(mainQualitiesIds.length >= 2){
                        getSister = false;
                    }
                }
                return true;
            })
            if(getSister){
                sisterQualities = FindTextValueInDict(that.props.firebase, leadershipQualities, userData.currentLeadershipQuality, 'sisterQualities');
                if (sisterQualities !== undefined && sisterQualities.length>0){
                    sisterQualities.map((item, index) => {
                    //for (var index = 0; index < sisterQualities.length; index++){
                        if(item !== undefined && item !== '' && !OverlapQualityFilter(item, mainQualitiesIds)){
                            //console.log('siser add', item)
                            var extraQuality = FindDictByKey(that.props.firebase, leadershipQualities, sisterQualities[index]);
                            if(!OverlapQualityFilter(extraQuality.value, mainQualitiesIds) && extraQuality!==undefined && extraQuality!==null && extraQuality.active){
                                mainQualitiesDicts.push(extraQuality);
                                mainQualitiesIds.push(extraQuality.value);
                                cacheStore = DataCacheRead('newComment_'+extraQuality.valueID+'_'+userId)
                                if(cacheStore!== null && cacheStore.isCacheExpired!== true){
                                    oldCommentList['newComment_'+extraQuality.valueID] = cacheStore.cacheData ;
                                }
                            }
                        }
                        return true;
                    })
                }
            }
            //Array.prototype.push.apply(relatedQualities, sisterQualities)
            //that.setState({ sisterQualities: sisterQualities });
        }
        catch (err) {
            console.log('issue with finding the category');
        }
        try {
            programQualities = ReturnFilteredDict(that.props.firebase, leadershipQualities, userData.currentCategory, 'category');
            //console.log("found programs modules", programQualities)
        }
        catch (err) {
            console.log('issue with finding the program qualities', err);
        }
        var newStateData = {oldCommentList, mainCategory, mainQuality, programQualities, mainQualitiesDicts, mainQualitiesIds, sisterQualities}
        if(that._isMounted !== false){
            //console.log('LUD newestate' , newStateData)
            that.setState(newStateData);
        }
        if(updateQualityValuesFlag){
            //console.log('LUD updating on latest inheritance', updateQualityUserDict);
            try{
                that.props.firebase.user(userId).update(updateQualityUserDict);
            }
            catch (err) {
                console.log('Need to update inherited modules', err);
            }
        }
        return newStateData;
    }
    else {
        return {};
    }
}

async function checkInternetNavigator(cb) {
    var ifConnected = window.navigator.onLine;
    if (ifConnected) {
        cb(true);
    } else {
        cb(false);
    }
}
