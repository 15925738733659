
import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";

import logoAnimated from "../../images/rocky-logo-animated.gif";
import SignalCellularConnectedNoInternet1BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet1Bar';

const styles = theme => ({
    grow: {
      flexGrow: 1,
    },
    root: {
        textAlign: 'center',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    logo: {
        width: "20%",
        marginTop: 50,
        maxWidth: 100,
    },
    button: {
        textTransform: 'none',
        borderRadius: 16,
        backgroundColor: 'white'
    }
});

class LoadingProgress extends Component {
    _isMounted=false;
    constructor(props) {
        super(props)
        this.state = {
            readyExtraInfo: false,
            readyReloadInfo: false
        }
    }
    componentDidMount () {
        this._isMounted = true;
        setTimeout(() => {
            if(this._isMounted){
                this.setState({readyExtraInfo: true});
            }
        }, 2000);
        setTimeout(() => {
            if(this._isMounted){
                this.setState({readyReloadInfo: true});
            }
        }, 5000);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    reloadClick = () => {
        if(this.props.refreshAction!== undefined){
            this.props.refreshAction();
        }
        console.log('LoadingProgress Reload Click')
        window?.location?.reload(true);
    }

    render() {
        const { classes, theme } = this.props;
        //console.log('theme/', theme.palette)
        if(this.props.type === 'LinearProgress'){
            return(
                <div className={classes.root}>
                    {this.props.loadingText !== undefined && this.props.loadingText !== ''
                    &&
                        <p style={{
                            color: this.props.color === 'light' ? theme.palette.primary.main :  theme.palette.themeAltTeal ,
                            textShadow: ("0px 0px 1px "+theme.palette.white)
                         } }>
                        ... {this.props.loadingText}</p>
                    }
                    <LinearProgress 
                        style={{color: theme.palette.themeAltTeal}}
                        //color={this.props.color === 'light' ? "primary" : "secondary"}
                    />
                    {this.props.firebaseConnectionSpeed === 'low' && this.state.readyExtraInfo &&
                        <>
                            <p style={theme.textErrorCenterPadding}>Checking internet!</p>
                            <SignalCellularConnectedNoInternet1BarIcon style={{color: 'red'}}/>
                        </>
                    }
                    {this.props.allowRefresh && this.state.readyReloadInfo &&
                        <p>
                            <Button className={classes.button} onClick={this.reloadClick}>Reload Page!</Button>
                        </p>
                    }
                </div>
            )
        }
        else if(this.props.type === 'LoadingPageAnimated'){
            return(
                <div className={classes.root}>
                    {this.props?.defaultSet?.programLogo?.length > 5 
                        ?
                            <img src={this.props.defaultSet.programLogo} className={classes.logo} alt="My AI Coach" />
                        :
                            <img src={logoAnimated} className={classes.logo} alt="AI Coaching with Rocky" />
                    }
                    {this.props.loadingText !== undefined && this.props.loadingText !== ''
                    &&
                        <p style={{
                            color: this.props.color === 'light' ? theme.palette.primary.main :  theme.palette.secondary.light ,
                            textShadow: ("0px 0px 1px "+theme.palette.white)
                        }}>
                        ... {this.props.loadingText}</p>
                    }
                    <br/>
                    <CircularProgress 
                        style={{color: theme.palette.secondary.light}}
                        //color={this.props.color === 'light' ? "primary" : "secondary"}
                    />
                    <br/>
                    {this.props.firebaseConnectionSpeed === 'low' && this.state.readyExtraInfo &&
                        <>
                            <p style={theme.textErrorCenterPadding}>Checking internet!</p>
                            <SignalCellularConnectedNoInternet1BarIcon style={{color: 'red'}}/>
                        </>
                    }
                    {this.props.allowRefresh && this.state.readyReloadInfo &&
                        <p>
                            <Button className={classes.button} onClick={this.reloadClick}>Reload Page!</Button>
                        </p>
                    }
                </div>
            )
        }
        else{
            return(
                <div className={classes.root}>
                    {this.props.loadingText !== undefined && this.props.loadingText !== ''
                    &&
                        <p style={{
                            color: this.props.color === 'light' ? theme.palette.primary.main :  theme.palette.themeAltTeal, 
                            textShadow: ("0px 0px 1px "+theme.palette.white) 
                        } }>
                        ... {this.props.loadingText}</p>
                    }
                    <br/>
                    <CircularProgress 
                        style={{color: theme.palette.themeAltTeal}}
                        //color={this.props.color === 'light' ? "primary" : "secondary"}
                    />
                    <br/>
                    {this.props.firebaseConnectionSpeed === 'low' && this.state.readyExtraInfo &&
                        <>
                            <p style={theme.textErrorCenterPadding}>Checking internet!</p>
                            <SignalCellularConnectedNoInternet1BarIcon style={{color: 'red'}}/>
                        </>
                    }
                    {this.props.allowRefresh && this.state.readyReloadInfo &&
                        <p>
                            <Button className={classes.button} onClick={() => {window?.location?.reload(true)}}>Reload Page!</Button>
                        </p>
                    }
                </div>
            )
        }
    }
}

export default compose(
    withStyles(styles, { withTheme: true })
)(LoadingProgress);
