import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

//https://www.npmjs.com/package/react-images-upload
import ImageUploader from 'react-images-upload';
import pixel from "../../../images/pixel.png";

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

//import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentUploadImage extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: '',
            openSetting: false,
            // image upload
            pictures: [], 
            uploadDone: false,
        };
        this.onDrop = this.onDrop.bind(this);
        //this.storeUploadedFile = this.storeUploadedFile.bind(this);
    }

    // image upload
    onDrop(param, picture) {
        //console.log('picture1', param, picture)
        try{
            var file = URL.createObjectURL(picture[0]);
            var fileWidth = 0;
            var fileHeight = 0;
            var img = document.createElement('img');
            img.src = file;
            img.onload = () => {
                fileWidth = img.width;
                fileHeight = img.height;
                console.log("NEW IMAGE width", fileWidth);
                console.log("NEW IMAGE height: ", fileHeight);
                this.setState({
                    [('width')+param]: fileWidth>0 ? fileWidth : undefined,
                    [('height')+param]: fileHeight>0 ? fileHeight : undefined,
                });
                if(this.props.imgFormat === "square" && fileWidth !== fileHeight){
                    this.setState({
                        errorMessage: 'File is does not have a sqare format'
                    }); 
                }
                else if(this.props.imgFormat === "rectangle" && fileHeight >= fileWidth){
                    this.setState({
                        errorMessage: 'File is does not have an ideal 16:9 rectangle format'
                    }); 
                }
                else if(this.props.imgMinWidth > 0 && fileWidth < this.props.imgMinWidth ){
                    this.setState({
                        errorMessage: ('File is does not have the min. width of: ' + this.props.imgMinWidth )
                    }); 
                }
                else{
                    this.setState({
                        errorMessage: undefined
                    }); 
                }
                if(this.props.imgWidth > 0 && fileWidth !== this.props.imgWidth){
                    this.setState({
                        errorMessage: 'Width of image has to be exactly ' + this.props.imgWidth + "px!"
                    }); 
                }
                
            }
            this.setState({
                //pictures: this.state.pictures.concat(picture),
                [('picture')+param]: picture,
                [('preview')+param]: file
            });
            console.log('picture2', picture, file )
        }
        catch(error){
            console.log('error image upload', error)
        }
    }

    storeUploadedFile = (param) => {
        if(this.state[('picture')+param] !== undefined){
            var litrato = this.state[('picture')+param][0];
            var dateUpload = new Date();
            var timestampUpload = dateUpload.getTime();
            var newMetadata = {
                cacheControl: 'public, max-age=4838400'
            }
            var name = param+'_'+timestampUpload+"_" + (this.state[('width')+param] || "w") + "x" + (this.state[('height')+param] || "h")+'_'+litrato.name;
            //console.log('Preparing upload', name, newMetadata )
            //var storageref = this.props.uploadDatabase.ref('rockyai/images/content/' + name);
            var storageref = this.props.uploadDatabase.child(name);
            var uploadTask = storageref.put(litrato, newMetadata);
            uploadTask
            .then(uploadTaskSnapshot => {
                uploadTaskSnapshot.ref.updateMetadata(newMetadata)
                    .then(metadata => console.log('New Meta', metadata))
                return uploadTaskSnapshot.ref.getDownloadURL();
            })
            .then(url  => {
                this.setState({ [param]: url, uploadDone: true });
                console.log('File available at', url);   
            })   
            .catch(error => {
                console.log('Upload error', error);
            });
            /*
            uploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            
            }, function(error) {
            console.log('eror', error);
            }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);            
            });
            }); */
        }// end if file
    }

    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
            return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, event) => {
        this.setState({
            [param]: event.target.value
        });
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
            uploadDone: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue, [('preview')+p]: undefined, [('picture')+p] : undefined});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[p] !== null && this.state[p] !== "" && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                var updateSet = { [p]: this.state[p] };
                if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                    Object.assign(updateSet, this.props.updateExtraChange)
                }
                this.props.dbUpdateLink.update(updateSet);
                if(this.props.updateState !== undefined){
                    this.props.updateState(updateSet);
                }
            }
            return '';
        })        
        this.handleSettingClose();
    }

    drawRectangle = (imageId, canvasId) => {
        var img = document.getElementById(imageId);
        var cnvs = document.getElementById(canvasId);
        
        //console.log("img height", img.height/3)

        cnvs.style.position = "absolute";
        cnvs.style.left = img.offsetLeft + "px";
        cnvs.style.top = img.offsetTop + "px";
        
        var ctx = cnvs.getContext("2d");
        ctx.beginPath();
        //paddingTop: "56.25%",
        ctx.rect(0,(84.3*0.5625),150,84.3);
        ctx.lineWidth = 3;
        ctx.strokeStyle = '#00ff00';
        ctx.stroke();
    }

    render() {
        const { content, params, title } = this.props;
        const { theme, classes } = this.props;

        return (
        content
            ?
            <div className={classes.grow}>
                <Card style={{...theme.cardAdminSettings, minWidth: 100}}>
                    <Grid container>
                        <Grid item xs={9} ><p style={{color:"darkgreen"}}>{title}</p>  
                            {this.props.exampleImage?.length > 3
                            &&
                                <div>
                                    <span style={theme.textSupportCenter}>
                                        &nbsp;Example:
                                    </span>
                                    <br/>
                                    <img src={this.props.exampleImage} style={{maxHeight: 80, maxWidth: 200}} alt="Example"/>
                                </div>
                            }
                        </Grid>
                        <Grid item xs={1} >  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)
                            &&
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    {
                        params.map((p) => (
                            <div style={theme.textSettings} key={"current_"+p}>
                                {content[p]!== undefined
                                    ?   <div style={{ float: 'right', fontWeight: 700, maxWidth:'100%', borderRadius: 12, backgroundColor: this.props.backgroundColor?.includes("#")? this.props.backgroundColor : '#ecebe9' }}
                                            //onLoad={(e) => this.drawRectangle(("image_"+p),("canvas_"+p))}
                                            >
                                            <img id={"image_"+p} width={150} src={content[p]} alt={"image_"+p} />
                                        </div>
                                    :   <span style={{ float: 'right'}}>{"[n/a]"}</span>
                                }
                                {(this.props.noLabel !== true)
                                    &&
                                    <span>{p}</span>
                                }
                            </div>
                        ))
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogTitle>Add/Change Image</DialogTitle>
                    <form>
                    <DialogContent>
                        {
                            params.map((p) => (
                                <div style={{width: `100%`}} key={"change_"+p}> 
                                    {this.state[('preview')+p] !== undefined && this.state[('preview')+p] !== ''
                                        ?   <>
                                                <p>Image Preview</p>
                                                <div style={{textAlign: 'right', marginBottom: 30}}>
                                                    Click on background example:
                                                    <span onClick={() => this.setState({bgColor: "white"})}>
                                                        &nbsp;<img width={10} height={10} style={{backgroundColor: "white"}} src={pixel} alt={"White"}/>&nbsp;White &nbsp; 
                                                    </span>
                                                    <span onClick={() => this.setState({bgColor: "grey"})}>
                                                        &nbsp; <img width={10} height={10} style={{backgroundColor: "grey"}} src={pixel} alt={"grey"}/>&nbsp;Grey &nbsp;
                                                    </span>
                                                    <span onClick={() => this.setState({bgColor: "blue"})}>
                                                        &nbsp; <img width={10} height={10} style={{backgroundColor: "blue"}} src={pixel} alt={"blue"}/>&nbsp;Blue &nbsp;
                                                    </span>
                                                    <span onClick={() => this.setState({bgColor: "black"})}>
                                                        &nbsp;<img width={10} height={10} style={{backgroundColor: "black"}} src={pixel} alt={"black"}/>&nbsp;Black &nbsp; 
                                                    </span>
                                                </div>
                                            </>
                                        :
                                            this.props.previewImageSize === true
                                            ?
                                                <ImageUploader
                                                    withIcon={true}
                                                    singleImage={true}
                                                    buttonText='Choose image'
                                                    onChange={(picture) => this.onDrop(p, picture)}
                                                    withPreview={false}
                                                    label={this.props.imgDef || 'Max file size: 300kb, accepted: jpg, gif, png'}
                                                    imgExtension={this.props.imgExtensionList?.length > 0 ? this.props.imgExtensionList : ['.jpg', '.gif', '.png', '.jpeg']}
                                                    maxFileSize={449389}
                                                />
                                            :
                                                <ImageUploader
                                                    withIcon={true}
                                                    singleImage={true}
                                                    buttonText='Choose image'
                                                    onChange={(picture) => this.onDrop(p, picture)}
                                                    withPreview={false}
                                                    label={this.props.imgDef || 'Max file size: 700kb, accepted: jpg, gif, png'}
                                                    imgExtension={this.props.imgExtensionList?.length > 0 ? this.props.imgExtensionList : ['.jpg', '.gif', '.png', '.jpeg']}
                                                    maxFileSize={1048576}
                                                />
                                    }
                                    <Grid container 
                                        spacing={2} 
                                        direction="row"
                                        justify="center"
                                        alignItems="flex-start">
                                        <Grid item xs={4}>
                                            <p>Original</p>
                                            {content[p]!== undefined
                                                ? 
                                                    <div style={{ maxWidth:'100%', textAlign:'center'}}
                                                        //onLoad={(e) => this.drawRectangle(("image_view"+p),("canvas_view_"+p))}
                                                        >
                                                        <div style={{backgroundColor: (this.state.bgColor || '#d4d4cf'), width: "fit-content"}}>
                                                            <img id={"image_view"+p} width={150} src={content[p]} alt={"image_view"+p}/>
                                                        </div>
                                                        {/*** <canvas id={"canvas_view_"+p} width="150" height="150"></canvas>*/}
                                                    </div>
                                                :   <span>{"[n/a]"}</span>
                                            }
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p>Preview Image</p>
                                            {this.state[p]!== undefined && this.state[p]!== content[p] && this.state[p] !== ''
                                                ? 
                                                    <div style={{  maxWidth:'75%' }}
                                                        //onLoad={(e) => this.drawRectangle(("image_preview"+p),("canvas_preview_"+p))}
                                                        >
                                                        <div style={{backgroundColor: (this.state.bgColor || '#d4d4cf'), width: "fit-content"}}>
                                                            <img id={"image_preview"+p} width={150} src={this.state[p]} alt={"image_preview"+p}/>
                                                        </div>
                                                        {/*** <canvas id={"canvas_preview_"+p} width="150" height="150"></canvas>*/}
                                                    </div>
                                                :   <span >{"[n/a]"}</span>
                                            }
                                        </Grid>
                                        <Grid item xs={4}>
                                            <p>Preview Upload</p>
                                            {this.state[('preview')+p] !== undefined && this.state[('preview')+p] !== ''
                                                ? 
                                                    <div style={{  maxWidth:'75%'}}
                                                        //onLoad={(e) => this.drawRectangle(("image_preview_upload"+p),("canvas_preview_upload"+p))}
                                                        >
                                                        <div style={{backgroundColor: (this.state.bgColor || '#d4d4cf'), width: "fit-content"}}>
                                                            <img id={"image_preview_upload"+p} width={150} height={this.props.imgFormat === "square" ? 150 : this.props.imgFormat === "rectangle" ? 150*9/16 : "auto"} src={this.state[('preview')+p]} alt={"image_preview_upload"+p}/>
                                                        </div>
                                                        {this.state['width'+p] !== undefined &&
                                                            <div style={{paddingTop: 20}}>
                                                                Width: {this.state['width'+p]}px, Height {this.state['height'+p]}px
                                                            </div>
                                                        }
                                                        {!this.state.uploadDone &&
                                                            <div style={{paddingTop: 20}}>
                                                                <Button 
                                                                    disabled={this.state.errorMessage?.length > 0 ? true : false}
                                                                    onClick={() => this.storeUploadedFile(p)}> 
                                                                    Upload 
                                                                </Button>
                                                            </div>
                                                        }
                                                        {/*** <canvas id={"canvas_preview_upload"+p} width="150" height="150"></canvas>*/}
                                                    </div>
                                                :   <span>{"[n/a]"}</span>
                                            }
                                        </Grid>
                                        <Grid>
                                            {this.state[p]!== undefined && this.state[p]!== content[p] && this.state[p] !== ''
                                                &&
                                                    <Button onClick={this.handleSettingSubmit}>
                                                        Take over new picture
                                                    </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                    {(this.props.noLabel !== true || (this.context.roles?.includes('ADMIN')))
                                        &&
                                            <p key={"current_url_"+p}>
                                                {content[p]!== undefined
                                                    ?   <span style={{ float: 'right'}}>Previous URL:</span>
                                                    :   <span style={{ float: 'right'}}>{"[n/a]"}</span>
                                                }
                                            </p>
                                    } 
                                    {(this.props.showLink === true || this.state.showLink === true || (this.context.roles?.includes('ADMIN')))
                                        &&
                                            <TextField
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                                key={"change_"+p}
                                                id={"change_"+p}
                                                label={p}
                                                value={this.state[p]}
                                                onChange={(e) => this.handleSettingChange(p, e)}
                                            />
                                    }
                                </div>
                            ))
                        }
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?
                                <p style={theme.textErrorCenterPadding}>
                                    {this.state.errorMessage}
                                </p>
                            :   <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <span style={{color: 'white', textAlign: 'left', paddingLeft: 20, width: '100%'}} onClick={() => this.setState({showLink : true})}>
                            Link
                        </span>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentUploadImage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentUploadImage);
