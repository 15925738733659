import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../../Firebase';
import {DeleteExamplesFromOtherIntentes} from '../Training/TrainingDataManagement';
import {GetAlternativeRewriteArrayRockyAI} from '../../Coach/ManageQuestions'
import {WizardQuestion} from '../../Coach/ManageQuestions';
import {MongoUtterances} from '../QuestionUtterances';
import {QuestionIntentsItemDialog} from '../QuestionIntents';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
/// filter radio buttons
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const styles = {
    expansionPanel: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    expansionPanelSummary: {
        paddingLeft: 5,
        paddingRight: 15,
    },
    expansionPanelSummaryCoach: {
        backgroundColor: '#f2f2f2',
        paddingLeft: 5,
        paddingRight: 15,
    },
    label: {
        fontSize: '0.8em',
        fontWeight: 700,
        paddingTop: 3,
        paddingRight: 10,
        minWidth: 60,
    },
    right : {
        position: 'absolute',
        right: 5
    },
    messages: {
        padding: 0,
        maxHeight: 320,
        overflow: 'scroll',
    },
    iconVote: {
        color: '#20a5e6',
    },
    iconNoVote: {
        color: '#f2f2f2',
    },
};

class DialogIntents extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var selectedIntent = "";
        this.state = {
            selectedType: "all",
            selectedIntent,
            loading: true,
            messages: [],
            classFilter : (this.props.classFilter || '')
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined) this.unsubscribe();
    }

    componentDidUpdate(prevProps)  {
        var newValues = '';
        var oldValues = '';
        if(this.props.nluIntent !== undefined && this.props.nluIntent.intent !== undefined){
            newValues = this.props.nluIntent.intent 
        }
        if(prevProps.nluIntent !== undefined && prevProps.nluIntent.intent !== ""){
            oldValues = prevProps.nluIntent.intent;
        }
        //console.log('opened', this.props.nluIntent )
        if(newValues !== '' && newValues !== oldValues && this._isMounted) {
            this.setState({
                selectedIntent : this.props.nluIntent.intent,
                selectedSentiment : this.props.nluIntent.sentiment
            })
            //console.log(this.props.nluIntent.intent)
        }
        return true
    }

    render() {
        //console.log('props', this.props)
        return (
            <div>
                {this.props.openDialogueUtterance && 
                    this.dialogueUtterance()
                }
                {this.props.openDialogueIntents && 
                    this.dialogueIntents()
                }
            </div>
        );
    }

    closeDialogs = () => {
        this.props.closeDialogue()
        this.setState({
            //openDialogueUtterance:false, 
            //selectedSentiment:undefined
        })
    }

    dialogueUtterance = () => {
        const {theme} = this.props;
        const {selectedText} = this.props;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.props.openDialogueUtterance || false}>
                <Dialog
                    key={'dialogueutterance'}
                    open={this.props.openDialogueUtterance|| false}
                    onClose={this.closeDialogs}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.closeDialogs}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Update utterance questions
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        <MongoUtterances 
                            searchTextArray={selectedText} 
                            searchByText={true}
                            leadershipQualities={this.props.leadershipQualities}
                            leadershipCategories={this.props.leadershipCategories}
                        />
                        {this.state.wizardActive
                        ?
                            <Button
                                onClick={() => this.setState({wizardActive: false})}
                            >
                                Show Existing
                            </Button>
                        :
                            <Button
                                onClick={() => this.setState({wizardActive: true})}
                            >
                                Train Question
                            </Button>
                        }
                        {this.state.wizardActive
                        &&
                            <WizardQuestion
                                searchTextArray={selectedText} 
                                leadershipQualities={this.props.leadershipQualities}
                                leadershipCategories={this.props.leadershipCategories}
                                user={this.context}
                            />
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    loadIntents = (sentiment) => {
        //console.log('finding error 0')
        var selectedIntent = this.state.selectedIntent;
        this.setState({selectedSentiment : undefined, selectedIntent: undefined})
        //console.log('finding error 1', sentiment, selectedIntent)
        if(this._isMounted && sentiment!==undefined && sentiment!==""){
            this.setState({errorMessage : 'Loading intents list!'})
            //this.setState({selectedSentiment : undefined, selectedIntent: ""})
            //console.log('added: ', sentiment)
            if(!(this.state?.[sentiment]?.length > 0)){
                //console.log('finding error 2')
                var intentList = [];
                this.props.firebase
                .agentIntentClasses()
                .where('active', '==', true)
                .where('sentiment', '==', sentiment)
                .where('tenses', 'array-contains', 'default')
                .orderBy('description', 'asc')
                .get().then(snapshot => {
                    //console.log('finding error 3')
                    snapshot.forEach(doc =>{
                        //console.log('finding error 4')
                        var dataRead = doc.data();
                        if(dataRead.intent !== undefined && dataRead.intent !== "" && dataRead.description !== undefined && dataRead.description !== ""){
                            //intentList.push({...doc.data(), id: doc.id})
                            var exampleResponse = dataRead.intent;
                            if(dataRead.responses !== undefined && dataRead.responses.length>0){
                                exampleResponse = dataRead.responses[0];
                            }
                            intentList.push({intent: dataRead.intent, description: dataRead.description, exampleResponse : exampleResponse, id: doc.id})
                        }
                    })
                    if(this._isMounted && intentList.length>0){
                        //console.log('finding error 5')
                        //console.log('added: ', sentiment, intentList)
                        this.setState({[sentiment] : intentList})
                        this.setState({selectedSentiment : sentiment})
                    }
                })
            }
            else{
                this.setState({selectedSentiment : sentiment, selectedIntent})
            }
        }
        else if(this._isMounted ){
            this.setState({selectedSentiment : sentiment, selectedIntent})

        }
    }

    addExampletoIntent = (example, tense, sentiment=undefined, intent=undefined) => {
        if(this._isMounted){
            if(this.state.textAlternativesSelected?.length > 0){
                var removedItems = this.state.textAlternatives || [];
                var removedSelection = this.state.textAlternativesSelected || [];
                this.state.textAlternativesSelected.forEach(item => {
                    console.log('add exmple item', item)
                    var index = removedSelection.indexOf(item);    // <-- Not supported in <IE9
                    if (index !== -1) {
                        removedSelection.splice(index, 1);
                    }
                    index = removedItems.indexOf(item);    // <-- Not supported in <IE9
                    if (index !== -1) {
                        removedItems.splice(index, 1);
                    }
                    this.addExampletoFinal(item, tense, sentiment, intent, item);
                })
                if(this._isMounted){
                    this.setState({textAlternativesSelected: removedSelection, textAlternatives: removedItems});
                }
            }
            else{
                this.setState({textAlternatives: [], textAlternativesSelected: []});
                this.addExampletoFinal(example, tense, sentiment, intent);
                this.getParaphrasesRockyAI(example).then(result => {
                    console.log('paraPHRASE EXAMPLES', result)
                    this.setState({textAlternatives: result});
                    if(false){
                        result.forEach(item => {
                            this.addExampletoFinal(item, tense, sentiment, intent, item);
                        })
                    }
                })
            }
        }
    }

    addExampletoFinal = (example, tense, sentiment=undefined, intent=undefined, overwriteExample="") => {
        if(this._isMounted){
            if (sentiment === undefined){
                sentiment = this.state.selectedSentiment
            }
            if (intent === undefined){
                intent = this.state.selectedIntent
            }
            //console.log('home: ' + this.props.groupIntent, this.props.groupSentiment )
            if(sentiment!==undefined && intent!==undefined){
                //console.log('Searching for ('+tense+ '): ' + intent, sentiment)
                this.setState({errorMessage : 'Searching for ('+tense+ '): ' + intent});
                //ar index = 0;
                this.props.firebase
                .agentIntentClasses()
                .where('sentiment', '==', sentiment)
                .where('intent', '==', intent)
                .where('tenses', 'array-contains', tense)
                .get().then(snapshot => {
                    if(snapshot.empty){
                        console.log('no examples')
                        return false;
                    }
                    else{
                        var newIds = []
                        snapshot.forEach((doc) =>{
                            newIds.push(doc.id);
                            //console.log( tense + ":", example)
                            var upDateSet = {}
                            var key = 'examples';
                            if(this.props.selectedExplanation!== undefined &&  this.props.selectedExplanation!== ""){
                                upDateSet["responses"] = this.props.firebase.fieldValue.arrayUnion(this.props.selectedExplanation.trim());
                            }
                            if(tense === 'future'){
                                upDateSet["examplesFuture"] =  this.props.firebase.fieldValue.arrayUnion(example.trim());
                                key = 'examplesFuture';
                            }
                            else if (tense === 'past'){
                                upDateSet["examplesPast"] =  this.props.firebase.fieldValue.arrayUnion(example.trim());
                                key = 'examplesPast';
                            }
                            else{
                                upDateSet["examples"] = this.props.firebase.fieldValue.arrayUnion(example.trim());
                                key = 'examples';
                            }
                            this.props.firebase.agentIntent(doc.id).update(upDateSet)
                            if(this._isMounted){
                                //console.log('found '+ index + ":", doc.data(), upDateSet)
                                this.setState({errorMessage : 'Added '+example.trim() +' to ('+tense+ ') ' + intent})
                                if(this.props.addIntent!== undefined) {
                                    this.props.addIntent(intent, tense)
                                }
                                if(this.props.handlerRemoveItem!== undefined) {
                                    var removeText = example;
                                    if(overwriteExample !== ""){
                                        removeText = overwriteExample;
                                    }
                                    else if (this.props.nluIntent !== undefined && this.props.nluIntent.text !== undefined && this.props.nluIntent.text !== ""){
                                        removeText = this.props.nluIntent.text;
                                    }
                                    //console.log('remove', removeText)
                                    this.props.handlerRemoveItem(removeText, doc.id, key)
                                }
                            }
                            //index += 1;
                        })
                        var removeText = example;
                        if(overwriteExample !== ""){
                            removeText = overwriteExample;
                        }
                        else{
                            removeText = this.state.previewTextArray?.[0] || example;
                        }
                        //console.log('remove: ', removeText)
                        DeleteExamplesFromOtherIntentes(this, removeText, newIds);
                        if(this.props.cleanItem !== undefined){
                            this.props.cleanItem(removeText);
                        }
                        //console.log('added: ', sentiment, intentList)
                    }
                })
            }
            else{
                this.setState({errorMessage : 'Please choose sentiment and intent first!'})
            }
        }
    }


    getParaphrasesRockyAI = async (text) => {
        try{
            this.setState({loadingParaphrases: true});
            var url = 'https://us-central1-rocky-beta-dev.cloudfunctions.net/generate_sentences'
            let data = {
                'input': text,
                'rewrite_num' : 10,
                'uniqueness': 0.5,
                'phrase' : "thesaurus"
            }
            let formData = {
                method: 'post',
                body: JSON.stringify(data)
            }
            console.log('input sending', data)
            var response = await this.requestAPI(url, formData);
            let result = await response.json();
            //console.log('paraphrasee result', result)
            this.setState({loadingParaphrases: false});
            return result;
        }
        catch(e){
            this.setState({loadingParaphrases: false});
            return []
        }
    }
    requestAPI(url, options) {
        //console.log('options', options)
        //console.log(options)
        return fetch(url, {
            mode: 'cors',
            headers: {
                //'Access-Control-Allow-Origin': '*',
                //'Access-Control-Allow-Headers': "*",
                'Content-Type': 'application/json',
            },
            ...options
        });
    }
    

    updateMoveIntents(newSentiment, newIntent){
        this.setState({
            selectedSentiment: newSentiment,
            selectedIntent: newIntent
        });
        if(this.props.handlerMoveList!== undefined){
            var tense = this.state.nluTempTense;
            if(tense === undefined || tense === ''){
                tense = 'default';
            }
            this.props.handlerMoveList(newSentiment, newIntent, tense);
        }
    }

    dialogueIntents = () => {
        const {theme} = this.props;
        //const {selectedText} = this.props;
        this.previewText = "";
        this.previewTextArray = [];

        //console.log('props', this.props)
        //console.log('intent', this.props.nluIntent)
        return(
            <RemoveScroll enabled={this.props.openDialogueIntents || false}>
                <Dialog
                    key={'dialogueutterance'}
                    open={this.props.openDialogueIntents|| false}
                    onEnter={() => {
                        //console.log(this.props.selectedText)
                        if (Array.isArray(this.props.selectedText) && this.props.selectedText.length>1){
                            this.previewText = this.props.selectedText.join(" ");
                            this.previewTextArray = this.props.selectedText;

                        }
                        else if (Array.isArray(this.props.selectedText)){
                            this.previewText = this.props.selectedText[0];
                            this.previewTextArray = this.props.selectedText[0].split(".");

                        }
                        else if(this.props.selectedText!==undefined){
                            this.previewText = this.props.selectedText;
                            this.previewTextArray = this.props.selectedText.split(".");
                        }
                        /**
                         */
                        var preselectedSentiment = undefined;
                        var preselectedIntent = undefined;
                        var selectedType = 'all';
                        if (this.props.nluIntent !== undefined ){
                            preselectedSentiment =  this.props.nluIntent.sentiment;
                            preselectedIntent = this.props.nluIntent.intent;
                            this.loadIntents(preselectedSentiment) 
                            if(preselectedIntent.includes('assess')) selectedType = 'ssessment'
                            else if(preselectedIntent.includes('ctivity')) selectedType = 'ctivity'
                            else if(preselectedIntent.includes('ntention')) selectedType = 'ntention'
                            else if(preselectedIntent.includes('ccomplishment')) selectedType = 'ccomplishment'
                            else if(preselectedIntent.includes('inform_conclusion')) selectedType = 'onclusion'
                            else if(preselectedIntent.includes('hallenge')) selectedType = 'hallenge'
                            else if(preselectedIntent.includes('ndicator')) selectedType = 'ndicator'
                            else if(preselectedIntent.includes('eason')) selectedType = 'eason'
                            else if(preselectedIntent.includes('uestion')) selectedType = 'uestion'
                            else if(preselectedIntent.includes('request')) selectedType = 'request'
                        }
                        console.log('selectedType', selectedType)
                        this.setState({
                            textDefault: this.previewText, 
                            textPast: this.previewText, 
                            textFuture: this.previewText,
                            selectedType,
                            textAlternativesSelected: [],
                            textAlternatives: [],
                            //selectedSentiment: preselectedSentiment,
                            //selectedIntent: preselectedIntent,
                            previewTextArray : this.previewTextArray
                        })
                        //console.log(this.state)
                    }}
                    onClose={this.closeDialogs}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.closeDialogs}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Add Intent training data
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        {this.props.openDialogueIntents &&
                        <div>
                            Source:
                            {this.props.intentUser !== undefined &&
                                <span onClick={() => {
                                        this.updateMoveIntents(this.props.intentUser.sentiment,  this.props.intentUser.intent);
                                    }}>
                                    {
                                        " "+this.props.intentUser.sentiment +"-"+ this.props.intentUser.intent
                                    }
                                </span>
                            }
                            <br/> <br/> 
                            <QuestionIntentsItemDialog
                                searchSentiment = {this.state.searchSentiment}
                                searchClass = {this.state.searchClass}
                                //contentId={this.state.currentContentId}
                                //sentiments={this.state.contentsLabels}
                                openDialogQIntents={this.state.dialogIntentsOpen || false}
                                closeDialog={(e) => {this.setState({dialogIntentsOpen : false })}}
                                //contentsLabels={contentsLabels}
                                leadershipCategories={this.props.leadershipCategories || []}
                                leadershipQualities={this.props.leadershipQualities || []}
                            />
                            <p>Text:</p>
                            <div>
                                {this.state.previewTextArray!==undefined && 
                                    this.state.previewTextArray.map((textline, indextext) => (
                                        <p key={indextext} onClick={ () => this.setState({textInit: textline, textDefault: textline.trim(), textFuture: textline.trim(), textPast: textline.trim() }) }  >
                                            {this.props.nluIntent!== undefined && textline === this.props.nluIntent.text
                                                ? <strong>{textline}</strong>
                                                : textline
                                            }
                                        </p>
                                    ))
                                }
                            </div>
                            {this.props.nluIntent !== undefined && this.props.nluIntent.text!== undefined 
                            &&
                                <div>
                                    <>
                                        {this.props.nluIntent.intent ===  this.state.selectedIntent
                                            ?
                                                <span style={{color:"green"}}>
                                                    1. {this.props.nluIntent.sentiment} / {this.props.nluIntent.intent} 
                                                </span>
                                            :
                                                <span style={{color:"grey"}}
                                                        onClick={() => {
                                                            this.updateMoveIntents(this.props.nluIntent.sentiment,  this.props.nluIntent.intent);
                                                            //this.setState({  selectedIntent : this.props.nluIntent.intent,  selectedSentiment : this.props.nluIntent.sentiment   })
                                                        }}
                                                    >
                                                    1. {this.props.nluIntent.sentiment} / {this.props.nluIntent.intent}
                                                </span>
                                        }
                                        {this.props.nluIntent.approved 
                                            ?
                                                <span style={{color:"green"}}>
                                                    &nbsp; : {Math.round(this.props.nluIntent.accuracy*100)}%
                                                    {this.props.nluIntent?.setaccuracy &&
                                                        <>
                                                        &nbsp; / {Math.round(this.props.nluIntent.setaccuracy*100)}%
                                                        </>
                                                    }
                                                </span>
                                            :
                                                <span style={{color:"red"}}>
                                                    &nbsp; : {Math.round(this.props.nluIntent.accuracy*100)}%
                                                    {this.props.nluIntent?.setaccuracy &&
                                                        <>
                                                        &nbsp; / {Math.round(this.props.nluIntent.setaccuracy*100)}%
                                                        </>
                                                    }
                                                </span>
                                        }
                                        <IconButton onClick={() => {this.setState({searchSentiment: this.props.nluIntent.sentiment, searchClass :this.props.nluIntent.intent , dialogIntentsOpen : true })}}>
                                            <SettingsApplicationsIcon fontSize="small" />
                                        </IconButton>
                                        <div> Resp: {this.props.nluIntent.response}</div>
                                        {this.props.nluIntent.confidence !== undefined && this.props.nluIntent.confidence.criteria !== undefined &&
                                            <div> 
                                                Int.: {this.props.nluIntent.confidence.criteria.criteriaIntent? <span style={{color:"green"}}>Y</span> : <span style={{color:"red"}}>N</span> }&nbsp;
                                                Dist.: {this.props.nluIntent.confidence.criteria.criteriaIntentDistanceSecond? <span style={{color:"green"}}>Y</span> : <span style={{color:"red"}}>N</span> }&nbsp;
                                                Resp.: {this.props.nluIntent.confidence.criteria.criteriaResponse? <span style={{color:"green"}}>Y</span> : <span style={{color:"red"}}>N</span> }&nbsp;
                                                Dist.: {this.props.nluIntent.confidence.criteria.criteriaResponseDistanceSecond? <span style={{color:"green"}}>Y</span> : <span style={{color:"red"}}>N</span> }&nbsp;
                                                Over.: {this.props.nluIntent.confidence.criteria.criteriaIntentResponseOverlap? <span style={{color:"green"}}>Y</span> : <span style={{color:"red"}}>N</span> }
                                            </div>
                                        }
                                    </>
                                    <div>
                                        {this.props.nluIntent.confidence!== undefined && this.props.nluIntent.confidence.intent !== undefined && this.props.nluIntent.confidence.intent.length  > 1
                                        &&  
                                            <>                                         
                                                <span style={{color: this.state.selectedIntent === this.props.nluIntent.confidence.intent[1].name ? "green" : "darkgrey"}}
                                                    onClick={() => {
                                                        this.updateMoveIntents(this.props.nluIntent.confidence.intent[1].sentiment, this.props.nluIntent.confidence.intent[1].name);
                                                        //this.setState({selectedIntent : this.props.nluIntent.confidence.intent[1].name, selectedSentiment : this.props.nluIntent.confidence.intent[1].sentiment })
                                                    }}
                                                >
                                                2. {this.props.nluIntent.confidence.intent[1].sentiment } / {this.props.nluIntent.confidence.intent[1].name } : {Math.round(this.props.nluIntent.confidence.intent[1].confidence*100)}% 
                                                </span>
                                                <IconButton onClick={() => {this.setState({searchSentiment: this.props.nluIntent.sentiment, searchClass : this.props.nluIntent.confidence.intent[1].name , dialogIntentsOpen : true })}}>
                                                    <SettingsApplicationsIcon fontSize="small"/>
                                                </IconButton>
                                            </>
                                        }
                                    </div>
                                    {this.props.nluIntent.intent !== this.state.selectedIntent
                                        &&
                                        <div>
                                            <span style={{color: "blue" }}
                                            >
                                            3. {this.state.selectedSentiment } / {this.state.selectedIntent } : n/a % 
                                            </span>
                                            <IconButton onClick={() => {this.setState({searchSentiment: this.state.selectedSentiment, searchClass : this.state.selectedIntent , dialogIntentsOpen : true })}}>
                                                <SettingsApplicationsIcon fontSize="small"/>
                                            </IconButton>
                                        </div>
                                    }
                                    <div>
                                        {this.props.nluIntent.confidence!== undefined && this.props.nluIntent.confidence.intent !== undefined && this.props.nluIntent.confidence.intent.length  > 2
                                        &&  
                                            <>                                         
                                                <span style={{color: this.state.selectedIntent === this.props.nluIntent.confidence.intent[2].name ? "green" : "darkgrey"}}
                                                    onClick={() => {
                                                        this.updateMoveIntents(this.props.nluIntent.confidence.intent[2].sentiment, this.props.nluIntent.confidence.intent[2].name);
                                                        //this.setState({  selectedIntent : this.props.nluIntent.confidence.intent[2].name, selectedSentiment : this.props.nluIntent.confidence.intent[2].sentiment })
                                                    }}
                                                >
                                                   {this.props.nluIntent.confidence.intent[2].sentiment } / {this.props.nluIntent.confidence.intent[2].name } : {Math.round(this.props.nluIntent.confidence.intent[2].confidence*100)}% 
                                                </span>
                                                <IconButton onClick={() => {this.setState({searchSentiment: this.props.nluIntent.sentiment, searchClass : this.props.nluIntent.confidence.intent[2].name , dialogIntentsOpen : true })}}>
                                                    <SettingsApplicationsIcon fontSize="small"/>
                                                </IconButton>
                                            </>
                                        }
                                    </div>

                                    <div> Tense: {this.props.nluIntent.intent.includes("_past")? "past" : this.props.nluIntent.intent.includes("_future") ? "future" : "default"}</div>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Tense</FormLabel>
                                        <RadioGroup row aria-label="Type" name="type"
                                            value={this.state.nluTempTense? this.state.nluTempTense : this.props.nluIntent.intent.includes("_past")? "past" : this.props.nluIntent.intent.includes("_future") ? "future" : "default"}
                                            onChange={(e4) => {this.setState({nluTempTense: e4.target.value}) } }>
                                            <FormControlLabel value="default" control={<Radio color='secondary' />} label="default" />
                                            <FormControlLabel value="past" control={<Radio color='secondary' />} label="past" />
                                            <FormControlLabel value="future" control={<Radio color='secondary'/>} label="future" />
                                        </RadioGroup>
                                    </FormControl>
                                    <p>
                                        {this.props.nluIntent.intent ===  this.state.selectedIntent
                                            ?
                                                <>
                                                    <span>{this.props.nluIntent.sentiment}/{this.props.nluIntent.intent}/{this.state.nluTempTense}</span>
                                                    &nbsp;
                                                    <Button 
                                                        disabled={this.props.nluIntent.confidence !== undefined && this.props.nluIntent.confidence.criteria !== undefined && this.props.nluIntent.confidence.criteria.criteriaIntentResponseOverlap!==true? true : false}
                                                        onClick={() => {
                                                            var tempIntent = this.props.nluIntent.intent;
                                                            var tempTense = "default";
                                                            if(tempIntent.includes("_past")){
                                                                tempIntent = tempIntent.replace("_past", "");
                                                                tempTense = "past";
                                                            }
                                                            else if(tempIntent.includes("_future")){
                                                                tempIntent = tempIntent.replace("_future", "");
                                                                tempTense = "future";
                                                            }
                                                            if(this.state.nluTempTense!== undefined && this.state.nluTempTense!== ""){
                                                                tempTense = this.state.nluTempTense;
                                                            }
                                                            this.setState({
                                                                selectedSentiment: this.props.nluIntent.sentiment,
                                                                selectedIntent: tempIntent,
                                                            }, this.addExampletoIntent(this.props.nluIntent.text , tempTense, this.props.nluIntent.sentiment, tempIntent) )
                                                        }}
                                                    >Add this set</Button>
                                                </>
                                            :   ""
                                        }
                                    </p>
                                </div>
                            }
                            {this.state.errorMessage !== '' && 
                                <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                            }
                            <div style={{display:'flex'}}>
                                <TextField
                                    name="text past"
                                    style={ {fontSize: '16px'} }
                                    type="text"
                                    label="Past tense"
                                    placeholder={this.previewText}
                                    value={this.state.textPast || this.previewText}
                                    onChange={(event) => {this.setState({textDefault: event.target.value, textPast: event.target.value, textFuture: event.target.value})}}
                                    fullWidth
                                />
                            </div>
                            <div style={{display:'flex', textAlign: 'right', marginBottom: 20}}>
                                <br/>
                                <Button
                                    onClick={async () => {
                                        var result = await GetAlternativeRewriteArrayRockyAI(this.state.textDefault, 5)
                                        console.log('extra check', result)
                                        this.setState({textAlternatives: result})
                                    }}
                                    >rewrite
                                </Button>
                                &nbsp;
                                <Button onClick={() => {this.addExampletoIntent(this.state.textPast || this.previewText, "past", undefined, undefined) } }
                                    disabled={(this.state.selectedIntent===undefined || this.state.selectedIntent==="") ? true : false}
                                    >+past
                                </Button>
                                &nbsp;
                                <Button onClick={() => {this.addExampletoIntent(this.state.textFuture || this.previewText, "future", undefined, undefined) } }
                                    disabled={(this.state.selectedIntent===undefined || this.state.selectedIntent==="") ? true : false}
                                    >+future
                                </Button>
                                &nbsp;
                                <Button onClick={() => {this.addExampletoIntent(this.state.textDefault || this.previewText, "default", undefined, undefined) } }
                                    disabled={(this.state.selectedIntent===undefined || this.state.selectedIntent==="") ? true : false}
                                    >+default
                                </Button>
                            </div>
                            {this.state.loadingParaphrases === true
                                &&
                                <span style={{color: 'red'}}>loading paraphrases</span>
                            }
                            {this.state.textAlternatives?.length>0 && this.state.textAlternatives.map((item, index) => (
                                <div key={'extra_'+index} style={{padding: 10}} >   
                                    <FormControlLabel
                                        key={"currentTags_"+index}
                                        id={"currentTags_"+index}
                                        control={
                                            <Checkbox
                                                onClick={(event) => {
                                                    var textAlternativesSelected = this.state.textAlternativesSelected || [];
                                                    if (event.target.checked){
                                                        textAlternativesSelected.push(item);
                                                        this.setState({textAlternativesSelected, textDefault: item, textPast: item, textFuture: item});
                                                    }
                                                    else{
                                                        if(textAlternativesSelected.includes(item)){
                                                            //console.log('beforee',textAlternativesSelected )
                                                            var indexI = textAlternativesSelected.indexOf(item);
                                                            if (indexI !== -1) {
                                                                textAlternativesSelected.splice(indexI, 1);
                                                            }
                                                            //console.log('after',textAlternativesSelected )
                                                            this.setState({textAlternativesSelected});
                                                        }
                                                    }
                                                }}
                                                checked = {
                                                    this.state.textAlternativesSelected?.includes(item) 
                                                        ? true
                                                        : false
                                                }
                                                name={"currentTags_"+index} 
                                            />
                                        }
                                        label={item}
                                    />
                                </div>
                            )) }
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Filter intent</FormLabel>
                                <TextField
                                    name="classFilter"
                                    style={ {fontSize: '16px', color:'red'} }
                                    type="text"
                                    label="classFilter"
                                    placeholder="balance"
                                    value={this.state.classFilter || ''}
                                    onChange={(event) => this.setState({ classFilter : event.target.value})}
                                />
                                <RadioGroup row aria-label="Type" name="type"
                                    value={this.state.selectedType || 'onclusion'} onChange={(e2) => {this.setState({selectedType: e2.target.value}) } }>
                                    <FormControlLabel value="ssessment" control={<Radio color='secondary' />} label="assessment" />
                                    <FormControlLabel value="entiment" control={<Radio color='secondary'/>} label="pos. sentiment" />
                                    <FormControlLabel value="ctivity" control={<Radio color='secondary' />} label="activity" />
                                    <FormControlLabel value="ntention" control={<Radio color='secondary' />} label="intention" />
                                    <FormControlLabel value="ccomplishment" control={<Radio color='secondary' />} label="accomplishment" />
                                    <FormControlLabel value="onclusion" control={<Radio color='secondary'/>} label="conclusion" />
                                    <FormControlLabel value="hallenge" control={<Radio color='secondary' />} label="challenge" />
                                    <FormControlLabel value="oal" control={<Radio color='secondary'/>} label="goal" />
                                    <FormControlLabel value="ndicator" control={<Radio color='secondary' />} label="indicator" />
                                    <FormControlLabel value="eason" control={<Radio color='secondary'/>} label="reason" />
                                    <FormControlLabel value="uestion" control={<Radio color='secondary' />} label="question" />
                                    <FormControlLabel value="request" control={<Radio color='secondary' />} label="request" />
                                    <FormControlLabel value="all" control={<Radio color='secondary' />} label="all other" />
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset">
                                <FormLabel component="legend"><span style={{color: 'red'}}>Choose Sentiment</span></FormLabel>
                                <RadioGroup row aria-label="Sentiment" name="Sentiment" defaultValue={this.state.selectedSentiment || 'inform_neutral_long'} onChange={(e3) => {this.loadIntents( e3.target.value) } }>
                                    { ['ntention'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_intention_long" control={<Radio color='secondary'/>} label="Intentions (Detail)" />
                                    }
                                    { ['ssessment','ccomplishment', 'reason'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_positive_long" control={<Radio color='secondary'/>} label="Positive (Detail)" />
                                    }
                                    { ['ssessment','entiment'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_positive_short" control={<Radio color='secondary' />} label="Positive (ask follow up)" />
                                    }
                                    { ['ssessment','ctivity','goal'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_intention_short" control={<Radio color='secondary' />} label="Intention (ask follow up)" />
                                    }
                                    { ['eason','onclusion','ssessment','oal','ndicator','hallenge'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_neutral_long" control={<Radio color='secondary'/>} label="Neutral (Detail)" />
                                    }
                                    {  ['hallenge','ssessment','ndicator', 'oal'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_neutral_short" control={<Radio color='secondary' />} label="Neutral (ask follow up)" />
                                    }
                                    { ['ssessment','eason','ctivity'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_negative_long" control={<Radio color='secondary'/>} label="Negative (Detail)" />
                                    }
                                    {  ['ssessment','hallenge','ctivity'].includes(this.state.selectedType)
                                        &&
                                        <FormControlLabel value="inform_negative_short" control={<Radio color='secondary' />} label="Negative (ask follow up)" />
                                    }
                                    { (this.state.selectedType==="all" || this.state.selectedType==="uestion")
                                        &&
                                        <FormControlLabel value="question" control={<Radio color='secondary'/>} label="Questions asked" />
                                    }
                                    { (this.state.selectedType==="all" || this.state.selectedType==="request" || this.state.selectedType==="chat")
                                        &&
                                        <FormControlLabel value="chat" control={<Radio color='secondary'/>} label="Chat with Rocky" />
                                    }
                                    { this.state.selectedType==="all"
                                        &&
                                        <FormControlLabel value="inform_measurement" control={<Radio color='secondary' />} label="Only number or measure" />
                                    }
                                    { this.state.selectedType==="all"
                                        &&
                                        <FormControlLabel value="greet" control={<Radio color='secondary' />} label="Greetings" />
                                    }
                                    { this.state.selectedType==="all"
                                        &&
                                        <FormControlLabel value="default" control={<Radio color='secondary' />} label="Defaults" />
                                    }
                                </RadioGroup>
                            </FormControl>
                            {this.state.selectedSentiment?.length > 0 &&
                                <p style={{color:"green"}}>Select Intent: &nbsp; {this.state.selectedSentiment} / <strong> {this.state.selectedIntent}</strong> </p>
                            }
                            {this.state.selectedSentiment?.length > 0 && this.state[this.state.selectedSentiment] !== undefined  &&
                                    <Select
                                        value={""}
                                        onChange={(e) => {
                                            try{
                                                //console.log("change event first", e)
                                                if(e!== undefined && e.target !== undefined && e.target.value !== undefined && e.target.value !== ""){
                                                    if(this.state.selectedIntent===undefined || this.state.selectedIntent==='' || this.state.selectedIntent !== e.target.value ){
                                                        //this.setState({selectedIntent:undefined})
                                                        //console.log("change event", e.target.value)
                                                        //this.setState({selectedIntent: e.target.value});
                                                        this.updateMoveIntents(this.state.selectedSentiment, e.target.value);
                                                    }
                                                }
                                            }
                                            catch (err) {
                                                console.log('change errir', err)
                                            }
                                            //this.setState({selectedIntent:e.target.value, selectedListIndex: e.target.id})
                                        }}
                                        name='class_option'
                                        >
                                        <MenuItem key={"0000000"} id={"0000000"} value={""} >
                                            Please select
                                        </MenuItem>
                                        {
                                        this.state[this.state.selectedSentiment].length > 0 && this.state[this.state.selectedSentiment].map( (intent,index) => {
                                            var exampleText = intent.intent;
                                            if(intent.intent === undefined ){
                                                 console.log('th intent', index, intent.intent, intent, this.state.selectedIntent)
                                            }
                                            if((this.state.classFilter === undefined || this.state.classFilter === '' ) || 
                                                (intent.intent !== undefined && intent.intent.includes(this.state.classFilter))
                                                ){
                                                if(intent.intent !== undefined && this.state.selectedType !== undefined && this.state.selectedType !== "all" && intent.intent.includes(this.state.selectedType)){
                                                    exampleText = intent.exampleResponse;
                                                    if(exampleText.length > 30){
                                                        exampleText = exampleText.substring(0,30) + "..."
                                                    }
                                                    return (
                                                        <MenuItem key={index+"_add_"+intent.id} id={index} value={intent.intent} 
                                                            selected={intent.intent === this.state.selectedIntent} style={{backgroundColor: intent.intent === this.state.selectedIntent ? 'lightgreen' : this.props.nluIntent !== undefined && intent.intent === this.props.nluIntent.intent ? 'lightblue' : 'white'}}
                                                            >
                                                            {intent.description + " - Response: '" + exampleText + "'"}
                                                        </MenuItem>
                                                    );
                                                }
                                                else if (intent.intent !== undefined && this.state.selectedType !== "all"
                                                    && !['onclusion','ssessment','oal','ndicator','hallenge','entiment','ntention','ctivity', 'ccomplishment', 'onclusion', 'oal','eason', 'indset','uestion','request'].includes(this.state.selectedType)
                                                    ) {
                                                    
                                                    exampleText = intent.exampleResponse;
                                                    if(exampleText.length > 30){
                                                        exampleText = exampleText.substring(0,30) + "..."
                                                    }
                                                    return (
                                                        <MenuItem key={index+"_add_"+intent.id} id={index} value={intent.intent} >
                                                            {intent.description + " - Response: '" + exampleText + "'"}
                                                        </MenuItem>
                                                    );
                                                }
                                                else if (intent.intent !== undefined && this.state.selectedType === "all") {
                                                    exampleText = intent.exampleResponse;
                                                    if(exampleText.length > 30){
                                                        exampleText = exampleText.substring(0,30) + "..."
                                                    }
                                                    return (
                                                        <MenuItem key={index+"_add_"+intent.id} id={index} value={intent.intent} >
                                                            {intent.description + " - Response: '" + exampleText + "'"}
                                                        </MenuItem>
                                                    );
                                                }
                                                else return ""
                                            }
                                            else return ""
                                        })}
                                    </Select>
                            }
                            {this.state.classFilter?.length > 0 && this.state.selectedSentiment?.length > 2 && this.state[this.state.selectedSentiment]?.length > 0 && 
                                this.state[this.state.selectedSentiment].map( (intent, index, firstItem=false) => {
                                    var exampleText = intent?.exampleResponse || '';
                                    if(exampleText.length > 30){
                                        exampleText = exampleText.substring(0,30) + "..."
                                    }
                                    if(intent?.intent?.includes(this.state.classFilter)){
                                        return(
                                            <span
                                                key={'filter_'+index}
                                                style={{color: intent.intent === this.state.selectedIntent ? 'green' : 'grey'}}
                                                onClick = {() => this.updateMoveIntents(this.state.selectedSentiment, intent.intent)}
                                            >
                                                <br/>
                                                {"" + ((intent.examples?.length || 0) + (intent.examplesFuture?.length || 0) + (intent.examplesPast?.length || 0) + (intent.examplesAuto?.length || 0)) + " "}
                                                {intent.description + " - " + exampleText + ""}
                                            </span>
                                        )
                                    }
                                    else return ""
                                })
                            }
                            {this.state.selectedIntentExample !== undefined && this.state.selectedSentiment !== undefined && this.state.selectedIntentExample !== "" &&
                                <p>Examples: {this.state.selectedIntentExample} </p>
                            }
                        </div>
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(DialogIntents);
