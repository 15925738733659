import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import {LoadCoachingGroups} from '../Coach/CoachGroups';

// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import {AvatarCard} from '../Modules/AvatarImage';
import DateBadge from '../Modules/DateBadge';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import Chip from '@material-ui/core/Chip';

/// Radio
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

//Modules for user admin
import CoachUserSetting from '../Coach/CoachUserSetting';
import CoachUserActions from '../Coach/CoachUserActions';
import DayList from '../Days/DayList';

// Swipe
import VizSensor from 'react-visibility-sensor';
import SwipeableViews from 'react-swipeable-views';
import {customWidthStyle, customWidthSlideStyle} from '../Modules/SwipeStepper';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Icons
//import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SchoolIcon from '@material-ui/icons/School';

import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
})

class CoachUsers extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        this.state = {
            loading: false,
            loadingText: 'loading users',
            coachees: null,
            parentGroup: 'Unsorted'
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        LoadCoachingGroups(this, this.context);
    }

    loadCoachees = (coachGroupsIds, parentGroup) => {
        if(this.unsubscribe!== undefined ){ this.unsubscribe()}
        if(this._isMounted ){
            this.setState({
                loadingCoachees: true,
                parentGroup: parentGroup
            });
        }
        var ownedGroups = coachGroupsIds[parentGroup];
        if(ownedGroups!== undefined && ownedGroups!==null && ownedGroups.length>10){
            ownedGroups = ownedGroups.slice(0,9);
        }
        if(ownedGroups.length>0){
            this.unsubscribe = this.props.firebase
            .users()
            .where('groups', 'array-contains-any', (ownedGroups || []))
            //.where('roles', 'array-contains', 'COACHEE')
            //.where('code', '==', 'PMCOACHING')
            .onSnapshot(snapshot => {
                let coachees = {};
                if(this._isMounted){
                    snapshot.forEach(doc => {
                        var group = "Undefined";
                        // *** HN adding filtering to users only allowed to be seen by coach who has same program
                        // any kind of program would work, but default program is filtered
                        var coacheeUser = doc.data();
                        if(coacheeUser.email !== "DELETED ACCOUNT"){
                            if (coacheeUser.groups!== undefined && coacheeUser.groups.length> 0){
                                coacheeUser.groups.map(groupId => {
                                    if(ownedGroups.includes(groupId) || this.context.roles?.includes("COACHADMIN") ){
                                        if(groupId===""){groupId = "Undefined"}
                                        if(groupId !== "$$EXTEND"){
                                            if(Object.keys(coachees).includes(groupId)){
                                                coachees[groupId].push({ ...coacheeUser, uid: doc.id });
                                            }
                                            else{
                                                coachees[groupId] = [{ ...coacheeUser, uid: doc.id }];
                                            } 
                                        }
                                    }
                                    return ""
                                })
                            }
                            else{
                                if(Object.keys(coachees).includes(group)){
                                    coachees[group].push({ ...coacheeUser, uid: doc.id });
                                }
                                else{
                                    coachees[group] = [{ ...coacheeUser, uid: doc.id }];
                                }
                            }
                        }
                    });
                }
                console.log(coachees)
                if (this._isMounted) {
                    this.setState({
                        coachees,
                        loading: false,
                        loadingCoachees : false,
                    });
                }
            });
        }//owndes groupd > 1
        else{
            //console.log(coachees)
            if (this._isMounted) {
                this.setState({
                    coachees: {},
                    loading: false,
                    loadingCoachees: false
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!==undefined){this.unsubscribe();}
        if(this.unsubscribeGroups!== undefined ){ this.unsubscribeGroups();}
        if(this.unsubscribeUser!==undefined) {this.unsubscribeUser();}
        this.setState({ loading: false, coachees: null });
    }

    render(){
        const {coachees, coachGroupsIds} = this.state;
        const {theme} = this.props;
        return(
            <div>
                {this.state.loading 
                    ?
                        <LoadingProgress type={'LinearProgress'} loadingText={this.state.loadingText} />
                    :
                        <div>
                            <div>
                                <strong>Select an organization:</strong>
                                {coachGroupsIds !== undefined && coachGroupsIds !== null
                                &&
                                    <div component="fieldset">
                                        {Object.keys(coachGroupsIds).map( (parent, parentIndex) => (
                                            <Chip
                                                key={"chip_"+parentIndex}
                                                style={{
                                                    marginRight: 8,
                                                    marginBottom: 8,
                                                    color: parent === this.state.parentGroup ? theme.palette.white : theme.palette.black, 
                                                    backgroundColor: parent === this.state.parentGroup ? theme.palette.themeAlertRed : theme.palette.themeGrey 
                                                }}
                                                label={parent}
                                                onClick={() => this.loadCoachees(coachGroupsIds, parent)}
                                            />
                                        ))}
                                    </div>
                                }
                                <h4>Select a group:</h4>
                                {(false && coachGroupsIds !== undefined && coachGroupsIds !== null)
                                &&
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" name="position" 
                                            onChange={(event) => this.loadCoachees(coachGroupsIds, event.target.value)}
                                        >
                                            {Object.keys(coachGroupsIds).map( (parent, parentIndex) => (
                                                <FormControlLabel
                                                    key={"radio_"+parentIndex}
                                                    value={parent}
                                                    checked={this.state.parentGroup === parent}
                                                    control={<Radio color="secondary" />}
                                                    label={parent}
                                                    labelPlacement="top"
                                                    />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                }
                            </div>
                            <h4>Team Insights:</h4>
                            {
                            this.state.loadingCoachees
                            ?
                                <div>Loading teams!</div>
                            :
                                coachees!==undefined && coachees!==null && Object.keys(coachees).length > 0
                                ?
                                    Object.keys(coachees).map((key, index) => (
                                        key.length>0
                                        ?
                                            this.renderGroups(key,index)
                                        :
                                            ""
                                    ))
                                :
                                    <div> No users assigned yet in any team!</div>
                            }
                        </div>
                }
                {
                    this.dialogueChats()
                }
                {
                    this.dialogueUserSettings()
                }
                {
                    this.dialogueNotificationSettings()
                }
            </div>
        )
    }

    renderGroups = (key, index) => {
        const {coachees} = this.state;
        return(
            <VizSensor 
                partialVisibility={true}
                key={key+'_'+index}
                onChange={(isVisible) => { 
                    if(isVisible && this.state['isVisible_'+key]===undefined) { 
                        this.setState({ ['isVisible_'+key] : true})
                        //console.log('t.leadership_category', t.leadership_category)
                    }}
                }
            >
                <div key={key+'_'+index}  onClick={() => {this.setState({activeKey:'showKey_'+key})}}>
                    <Grid container spacing={2}  alignItems="center">
                        <Grid item>
                            <AvatarCard  title={true} textBlock={"Client group: "+key+ " ("+coachees[key].length+")"}  
                            type='icon' image={<PeopleAltIcon />} squareSize='20' backgroundColor={'black'}
                            />
                        </Grid>
                        {!IsMobileDevice() &&
                            <Grid item xs>
                                {this.state['activeStep_'+key]!== undefined && this.state['activeStep_'+key]>=1
                                    &&
                                        <ArrowBackIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+key]: (this.state['activeStep_'+key]-1)})}} />
                                }
                            </Grid>
                        }

                        {!IsMobileDevice() &&
                            <Grid item>
                                {this.state['activeStep_'+key]!== undefined 
                                    ?   (coachees[key].length-1) > this.state['activeStep_'+key]  &&
                                            <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+key]: (this.state['activeStep_'+key]+1)})}}  />
                                    :   coachees[key].length > 1  &&
                                            <ArrowForwardIosIcon fontSize="small" onClick={() => { this.setState({ ['activeStep_'+key]: 1})}}  />
                                        
                                }
                            </Grid>
                        }
                    </Grid>
                    {this.state.activeKey === 'showKey_'+key
                    &&
                        this.state['isVisible_'+key]
                            ?
                                this.renderViews(coachees[key], key)
                            :
                                //<Skeleton variant="rect" width={IsMobileDevice()?'60vw':'70%' } height={200} />
                                <div style={{marginBottom:50}}>Click to show more</div>
                        
                    }
                </div>
            </VizSensor>
        )
    }

    renderViews = (clients, keyWord) => {
        //console.log("swipe", clients)
        return(
            <SwipeableViews
                    key={keyWord}
                    axis={'x'}
                    index={this.state['activeStep_'+keyWord]}
                    onChangeIndex={(index) => {
                        try{
                            this.handleStepChange(index, keyWord)
                        }
                        catch (err) {console.log('Swipe Error')}
                    }}
                    enableMouseEvents = {this.state.open === true? false : true}
                    disabled = {this.state.open === true? true : false}
                    resistance = {true}
                    style={customWidthStyle('80vW', '400px', (clients.length))}
                    slideStyle={customWidthSlideStyle('80vW', '400px', (clients.length))}
                >
    
                {clients.map((client, index) => (
                    client.username
                        ?
                            <VizSensor 
                                partialVisibility={true}
                                key={keyWord+'_'+index}
                                onChange={(isVisible) => { 
                                    if(isVisible && this.state['isVisibleItem_'+keyWord+'_'+index]===undefined) { 
                                        this.setState({ ['isVisibleItem_'+keyWord+'_'+index] : true})
                                        //console.log('t.leadership_category', t.leadership_category)
                                    }}
                                }
                            >
                                {
                                    this.state['isVisibleItem_'+keyWord+'_'+index]
                                    ?
                                        this.renderUserCard(client, client.uid)
                                    :
                                        <Skeleton variant="rect" width={'400' } height={200} />
                                }
                            
                            </VizSensor>
                        :   <div>Failed to load client data</div>
                    ))
                }
            </SwipeableViews>
        )
    }
    
    handleStepChange = (step, keyWord) => {
        //console.log(step, keyWord, 'handler')
        if(step !== undefined && keyWord !== undefined) {
            this.setState({['activeStep_'+keyWord] : step});
        }
    };

    renderUserCard = (user, uid) => {
        const {theme} = this.props;
        var date = new Date();
        if(user.lastEngagement?.seconds !== undefined){
            //console.log('user.lastEngagement', user.lastEngagement)
            var lastActionDate = user.lastEngagement && ConvertTimestampToDateString(user.lastEngagement);
            //console.log('lastActionDate', lastActionDate)
            date = new Date(lastActionDate.substring(0, 10));
        }
        var secondColor = theme.cardJournalDayHighlight.backgroundColor;
        secondColor = theme.palette.themeAlertOrange;
        var action = {done:true};
        var dt = new Date(Date.now() - 12096e5); // minus 2 weeks
        var dtSeconds = dt.getTime() / 1000;
        if(user.lastEngagement?.seconds && user.lastEngagement.seconds> dtSeconds){
            secondColor = theme.palette.themeAlertGreen;
        }
        else if(action.offTrack ) secondColor = theme.palette.themeAlertOrange;
        else if(action.important ) secondColor = theme.palette.themeAlertBlue;
        else if(action.urgent ) secondColor = theme.palette.themeAlertRed;
        else if(action.failed ) secondColor = theme.palette.themeGrey;
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //console.log(user)
        return(
            <Card key={uid}  style={{...theme.cardJournalDayHighlight, background: background }}   >
                <CardContent>
                    { user.lastEngagement!==undefined
                        ? <DateBadge valueMonth={months[date.getMonth()]} valueDay={date.getDate()}/> 
                        : <h1>-</h1>
                    }

                    <h4>
                        {(user.roles.includes("REMOTECOACH") || user.roles.includes("COACHADMIN") ) &&
                            <SchoolIcon style={{color:"blue"}} fontSize={"small"}/>
                        }
                        {user.purchasedProduct !== undefined && user.purchasedProduct.owned && user.purchasedProduct?.expiryDate?.seconds &&
                            <LocalAtmIcon style={{color: 
                                user.purchasedProduct.expiryDate !== undefined && new Date(user.purchasedProduct.expiryDate.seconds * 1000) > new Date() ? "green" : "red"}} 
                                fontSize={"small"} />
                        }
                        
                        &nbsp;
                        {( user.roles.includes("default") && user.roles.length ===1  )? user.username.substring(0, 9)+"*****" : user.username }
                    </h4>
                    <p> <span  style={theme.textSupport}>Email:&nbsp;</span>{( user.roles.includes("default") && user.roles.length ===1  ) ? "Anonymous" : user.email }</p>
                    <p> <span  style={theme.textSupport}>Timezone:&nbsp;</span>{user.timezone}</p>
                    { (user.roles.includes('COACHADMIN') === true || user.roles.includes('REMOTECOACH') === true ) &&
                        <Card style={{padding:20, backgroundColor:"white"}}> 
                            <p >COACH ACCOUNT</p>
                            {this.context.roles?.includes('COACHADMIN') &&
                                <p  style={theme.textErrorCenter}>Seats purchased: &nbsp; <strong>{user.seatsPurchasedTotal || 'n/a'}</strong></p>
                            }
                        </Card>
                    }
                    <hr/>
                    <p> <span  style={theme.textSupport}>Last Priming:&nbsp;</span>{user.primingSessionFinishedAt && ConvertTimestampToDateString(user.primingSessionFinishedAt, true)}</p>
                    <p> <span  style={theme.textSupport}>Last Reflection:&nbsp;</span>{user.reflectionSessionFinishedAt && ConvertTimestampToDateString(user.reflectionSessionFinishedAt, true)}</p>
                    <p> <span  style={theme.textSupport}>Last Engagement:&nbsp;</span>{lastActionDate}</p>
                    <p> <span  style={theme.textSupport}>Last Reminder:&nbsp;</span>{user.lastEmailNotificationSentAt && ConvertTimestampToDateString(user.lastEmailNotificationSentAt, true)}</p>
                    { user.mobileDevicePushNotificationAllowed === true &&
                        <p> <span  style={theme.textSupport}>User allowed smart phone push notifications</span></p>
                    }
                    <p> <span  style={theme.textSupport}># of AI Dialogues:&nbsp;</span>{user.dialogueCounter}
                        {user.purchasedProduct!==undefined && user.purchasedProduct.owned===true
                            ? ' / ' + user.purchasedProduct.title + ' > ' + ConvertTimestampToDateString(user.purchasedProduct.expiryDate, true)
                            : ' / (Trial) '+ (user.freeDialogues?user.freeDialogues:'0')
                        }
                    </p>
                    <hr/>
                    <p> <span  style={theme.textSupport}>Current Call to Action:</span><br/>{user.ctaText }</p>
                    <p> <span  style={theme.textSupport}>Vision/North Star Goal:</span><br/>{user.achievementGoal }</p>
                    <p> <span  style={theme.textSupport}>Program:&nbsp;</span>{user.currentCategory}</p>
                    <p> <span  style={theme.textSupport}>Main Quest:&nbsp;</span>{user.currentLeadershipQuality}</p>
                    <p> <span  style={theme.textSupport}>Next 2nd Quest:&nbsp;</span>{user.todaysLeadershipQuality}</p>
                    <hr/>
                    <Grid item xs={12}>
                        <Grid container spacing={0} direction='row' justify="space-between" >
                            <Grid item xs={4}>
                                {(true || user.roles.includes("COACHEE") || user.roles.includes("OBSERVE") )
                                    ?
                                        <div style={{fontSize:"small", color:'green', textAlign:'center'}} 
                                            onClick={() => this.handleOpenUserSettings(user, uid)}>
                                            <IconButton color="secondary" style={{padding: 2}}>
                                                <EditIcon fontSize="small" style={{color: "green"}}/>
                                            </IconButton>
                                            <br/>Modules 
                                        </div>
                                    :
                                        <div style={{fontSize:"small", color:'grey', textAlign:'center'}}>
                                            <IconButton disabled color="secondary" style={{padding: 2}}>
                                                <EditIcon fontSize="small" style={{color: "grey"}}/>
                                            </IconButton>
                                            <br/>Modules 
                                        </div>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                {( user.roles.includes("COACHEE") || user.roles.includes("OBSERVE") )
                                    ?
                                        <div style={{fontSize:"small", color:'green', textAlign:'center'}}
                                            onClick={() => this.handleOpenNotificationSettings(user, uid)}>
                                            <IconButton color="secondary" style={{padding: 2}}>
                                                <LowPriorityIcon fontSize="small" style={{color: "green"}}/>
                                            </IconButton>
                                            <br/>Reminders 
                                        </div>
                                    :
                                        <div style={{fontSize:"small", color:'grey', textAlign:'center'}} onClick={this.handleOpen}>
                                            <IconButton disabled color="secondary" style={{padding: 2}}>
                                                <LowPriorityIcon fontSize="small" style={{color: "grey"}}/>
                                            </IconButton>
                                            <br/>Reminders 
                                        </div>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{fontSize:"small", color:'green', textAlign:'center'}} 
                                    onClick={() => this.handleOpenDialogueChats(user, uid)}>
                                    <IconButton color="secondary" style={{padding: 2}}>
                                        <AssignmentIcon fontSize="small" style={{color: "green"}}/>
                                    </IconButton>
                                    <br/>Chats 
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
    

    handleClose = () => {
        this.setState({openDialogueChats: false, openUserSettings: false, openNotificationSettings: false, open:false} )
    }
    handleOpenDialogueChats = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openDialogueChats: true, openUserSettings: false, openNotificationSettings: false, open:true} )
    }
    handleOpenUserSettings = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openDialogueChats: false, openUserSettings: true, openNotificationSettings: false, open:true} )
    }
    handleOpenNotificationSettings = (user, uid) => {
        this.setState({selectedId : uid, selectedUser: user, openDialogueChats: false, openUserSettings: false, openNotificationSettings: true, open:true} )
    }

    dialogueChats = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        return(
            <RemoveScroll enabled={this.state.openDialogueChats || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openDialogueChats || false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            AI Chats
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { selectedId !== undefined && selectedId !== ""
                            ? <DayList userId={selectedId} user={selectedUser} isCoach={true}/>
                            : <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    dialogueUserSettings = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openUserSettings || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openUserSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    TransitionComponent={Transition}
                    PaperProps={{style:theme.dialogWindow}}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Categories and Quests
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { selectedId !== undefined && selectedId !== ""
                            ? <CoachUserSetting 
                                defaultSet={this.props.defaultSet || undefined}
                                userId={selectedId} user={selectedUser}
                                leadershipCategories={this.props.leadershipCategories}
                                leadershipQualities={this.props.leadershipQualities}
                                />
                            : <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

    dialogueNotificationSettings = () => {
        const {theme} = this.props;
        const {selectedId, selectedUser} = this.state;
        //console.log(this.state)
        return(
            <RemoveScroll enabled={this.state.openNotificationSettings|| false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openNotificationSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    TransitionComponent={Transition}
                    PaperProps={{style:theme.dialogWindow}}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <IconButton onClick={this.handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <span style={theme.dialogTitle.titleText}>
                            Notifications and Goals
                        </span>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { selectedId !== undefined && selectedId !== ""
                            ?   <CoachUserActions 
                                    defaultSet={this.props.defaultSet || undefined}
                                    userId={selectedId} user={selectedUser}
                                    leadershipCategories={this.props.leadershipCategories}
                                    leadershipQualities={this.props.leadershipQualities}
                                />
                            :   <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
            </RemoveScroll>
        )
    }

}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CoachUsers);
