import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
//import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentNumber extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: 0,
            openSetting: false,
        };
    }

  // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
            return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }
    handleSettingChange = (param,  value, event) => {
        //console.log('numb', value)
        if(typeof value ==='number'){
            this.setState({
                [param]: value
            });
        }
    }
    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue});
        })
    }
    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            if(this.state[p] !== null && this.state[p] !== "" && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                this.props.dbUpdateLink.update({
                    [p]: this.state[p]
                });
            }
            return '';
        })        
        this.handleSettingClose();
    }

    render() {
        const { content, params, title, labels } = this.props;
        const { theme, classes } = this.props;

        return (
        content
            ?
            <div className={classes.grow}>
            <Card style={theme.cardAdminSettings}>
                <Grid container>
                    <Grid item xs={9} ><p style={{color:"darkgreen"}}>{title}</p>  </Grid>
                    <Grid item xs={1}>  </Grid>
                    <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                        {(this.props.access === undefined || this.props.access === true)  &&
                            <Tooltip title={'Edit/Change'}>
                                <IconButton onClick={this.handleSettingOpen}> 
                                    <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
                {
                    params.map((p, index) => (
                        <p style={theme.textSettings} key={"current_"+p}>
                            {content[p]!== undefined
                                ? <span style={{ float: 'right', fontWeight: 700, maxWidth:'75%' }}>{content[p]}</span>
                                : <span style={{ float: 'right'}}>{"[n/a]"}</span>
                            }
                            {this.props.noLabel!==true &&
                                <span>{labels!==undefined? labels[index]: p}</span>
                            }
                        </p>
                    ))
                }
            </Card>
            <Dialog
                open={this.state.openSetting}
                onClose={this.handleSettingClose}
                fullWidth
                maxWidth="sm">
                <DialogTitle>Change Setting</DialogTitle>
                <form>
                    <DialogContent>
                        {
                            params.map((p) => (
                                <div style={{width: `100%`} } key={"change_"+p}> 
                                    
                                    <Slider
                                    value={this.state[p]}
                                    color = "secondary"
                                    step={1}
                                    onChange={(e, value) => this.handleSettingChange(p, value, e)}
                                    valueLabelDisplay="on"
                                    min={this.props.min}
                                    max={this.props.max}
                                    style={{top: 20, valueLabel: {color:'white'}} }
                                    marks={[
                                        {
                                        value: (2),
                                        label: 'Min (1)',
                                        },
                                        {
                                        value: (this.props.max/2),
                                        label: 'Mean  (' + this.props.max/2 + ')',
                                        },
                                        {
                                        value: (this.props.max-3),
                                        label: 'Max  (' + this.props.max + ')',
                                        },
                                    ]}
                                    />
                                </div>
                            ))
                        }
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                        ?
                        <p style={theme.textErrorCenterPadding}>
                            {this.state.errorMessage}
                        </p>
                        : <p> </p>
                        }
                    </DialogContent>
                </form>
                <DialogActions>
                    <Button onClick={this.handleSettingClose}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSettingSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            </div>
        : <div> </div>
                
        );
    }
}

SettingContentNumber.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withFirebase
)(SettingContentNumber);
