import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';

import _ from "lodash";

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DialogTaskFollowUps from '../Tasks/DialogTaskFollowUp';

import {SetStateLoadCategoriesAndQualities} from '../../Modules/LoadCategoriesAndQualities';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const styles = {
    dialogContent: {
      marginTop: 20,
    },
    dialogTitle: {
      backgroundColor: '#fcd750',
      fontSize: 13,
    },
    loading: {
      marginTop: 50,
      textAlign: 'center',
    },
    showUser: {
      cursor: 'pointer',
    },
    title: {
      fontSize: 18,
      fontWeight: 700,
      marginTop: 10,
      marginLeft: 15,
    }
};

function convertTimestampToDate(ts) {
    let date = new Date(ts * 1000);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    hour = (hour < 10 ? "0" : "") + hour;
    minute = (minute < 10 ? "0" : "") + minute;

    return year + "-" + month + "-" + day + " [" + hour + ":" + minute + "]";
}

class AdminFeedbacksPage extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            feedbacksOther: [],
            feedbacksPositive: [],
            feedbacksNegative: [],
            feedbacksCommentPositive: [],
            feedbacksCommentNegative: [],
            open: false,
            username: "",
            email: "",
            showTab: 'feedbacksOther'
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        let feedbacksOther = [];
        let feedbacksPositive = [];
        let feedbacksNegative = [];
        let feedbacksCommentPositive = [];
        let feedbacksCommentNegative = [];

        this.props.firebase
        .dialogues()
        .where('vote', 'in', ['up', 'down'])
        .orderBy('createdAt', 'desc')
        .limit(100)
        .get()
        .then(snapshot => {
            snapshot.forEach(doc =>{
                const dialogue = doc.data();
                if(dialogue.vote === 'up'){
                    feedbacksPositive.push({ ...dialogue, uid: doc.id })
                }
                else if(dialogue.vote === 'down'){
                    feedbacksNegative.push({ ...dialogue, uid: doc.id })
                }
                else if(dialogue.feedback !== undefined && dialogue.feedback !== ''){
                    feedbacksOther.push({ ...dialogue, uid: doc.id })
                }
                else if(dialogue.askFeedback !== undefined && dialogue.askFeedback !== ''){
                    feedbacksOther.unshift({ ...dialogue, feedback: dialogue.askFeedback, uid: doc.id})
                }
            });
            //feedbacksOther.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
            feedbacksOther.reverse();
            feedbacksPositive.reverse();
            feedbacksNegative.reverse();
            this.setState({
                feedbacksOther, feedbacksPositive, feedbacksNegative, 
                loading: false,
            });    
        });

        this.props.firebase
        .dialogues()
        .orderBy('createdAt', 'desc')
        .limit(300)
        .get()
        .then(snapshot => {
            snapshot.forEach(doc =>{
                const dialogue = doc.data();
                if(dialogue.askFeedback !== undefined && dialogue.askFeedback !== ''){
                    feedbacksOther.unshift({ ...dialogue, feedback: dialogue.askFeedback, uid: doc.id })
                }
                else if(dialogue.feedback !== undefined && dialogue.feedback !== ''){
                    feedbacksOther.unshift({ ...dialogue, feedback: dialogue.feedback, uid: doc.id })
                }
            });
            //feedbacksOther.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
            feedbacksOther.reverse();
            feedbacksPositive.reverse();
            feedbacksNegative.reverse();
            this.setState({
                feedbacksOther, feedbacksPositive, feedbacksNegative, 
                loading: false,
            });
        });

        this.props.firebase
        .dialogues()
        .where('vote', 'in', ['up', 'down'])
        .where('feedback', '>', '')
        .orderBy('feedback')
        .orderBy('createdAt', 'desc')
        //.limit(1000)
        .get()
        .then(snapshot => {
            snapshot.forEach(doc =>{
                const dialogue = doc.data();
                if(dialogue.vote === 'up'){
                    feedbacksCommentPositive.push({ ...dialogue, uid: doc.id })
                }
                else if(dialogue.vote === 'down'){
                    feedbacksCommentNegative.push({ ...dialogue, uid: doc.id })
                }
            });
            feedbacksCommentPositive = _.orderBy(feedbacksCommentPositive, feedbacksCommentPositive => feedbacksCommentPositive.createdAt, ['desc']);
            feedbacksCommentNegative = _.orderBy(feedbacksCommentNegative, feedbacksCommentNegative => feedbacksCommentNegative.createdAt, ['desc']);
            //feedbacksCommentPositive.reverse();
            //feedbacksCommentNegative.reverse();
            this.setState({
                feedbacksCommentPositive, feedbacksCommentNegative,
                loading: false,
            });    
        });

        SetStateLoadCategoriesAndQualities(this, this.props.firebase);
  }

  /*
  componentWillUnmount() {
    this.unsubscribe();
  }
  */

    getUser = (userId) => {
      this.props.firebase
        .user(userId)
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log('no data');
          } else {
            this.setState({
              username: doc.data().username,
              email: doc.data().email,
            });
          }
        })
        .catch(err => {
          console.log('error getting document', err);
        });
    }

    handleOpenOld = (userId) => async () => {
        await this.getUser(userId);
        this.setState({
        open: true,
        });
    }

    handleOpen = (userId, dialogue) => async () => {
        //await this.getUser(userId);
        this.setState({
            dialogTaskOpen: true,
            openDialogue: dialogue,
            openUserId: userId,
        });
    }

    handleClose = () => {
        this.setState({
        open: false,
        });
    }


    render() {
        const { loading, feedbacksOther, feedbacksPositive, feedbacksNegative, feedbacksCommentPositive, feedbacksCommentNegative, showTab } = this.state;
        const feedbacks = this.state[showTab];
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <h1 style={styles.title}>User Feedbacks</h1>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(event, newValue) => {
                        //console.log('new tab', newValue)
                        this.setState({
                            showTab: newValue
                        });
                    }}
                    initialselectedindex={0}
                    value={showTab}
                    >
                    <Tab 
                        value={'feedbacksPositive'} 
                        key={'feedbacksPositive'}
                        label={"Positive ("+feedbacksPositive.length+")"}/>
                    ))
                    <Tab 
                        value={'feedbacksNegative'} 
                        key={'feedbacksNegative'}
                        label={"Negative ("+feedbacksNegative.length+")"}/>
                    ))
                    <Tab 
                        value={'feedbacksOther'} 
                        key={'feedbacksOther'}
                        label={"Comment ("+feedbacksOther.length+")"}/>
                    ))
                    <Tab 
                        value={'feedbacksCommentPositive'} 
                        key={'feedbacksCommentPositive'}
                        label={"More Positive ("+feedbacksCommentPositive.length+")"}/>
                    ))
                    <Tab 
                        value={'feedbacksCommentNegative'} 
                        key={'feedbacksCommentNegative'}
                        label={"More Negative ("+feedbacksCommentNegative.length+")"}/>
                    ))
                </Tabs>
                {loading &&
                    <div style={styles.loading}>
                        <CircularProgress color="secondary"/>
                    </div>
                }
                {feedbacks && 
                    <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>User (click ID to see user info)</TableCell>
                                    <TableCell>Feedback</TableCell>
                                    <TableCell>Conversation Vote</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor: "#DDDDDD"}}>
                            {feedbacks.map(f => (
                                <TableRow key={f.uid}>
                                    <TableCell>
                                    {convertTimestampToDate(f.createdAt.seconds)}
                                    </TableCell>
                                    <TableCell
                                                onClick={this.handleOpen(f.userId,f )}
                                                style={styles.showUser}
                                            >
                                        {f.userId}
                                    </TableCell>
                                    <TableCell>
                                        {f.feedback}
                                    </TableCell>
                                    <TableCell>
                                    { f.vote}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                    </Table>
                }
                <Dialog
                    open={this.state.open}
                    >
                    <DialogTitle
                        disableTypography={true}
                        style={styles.dialogTitle}
                    >
                            User Info
                    </DialogTitle>
                    <DialogContent
                    style={styles.dialogContent}
                    >
                            <p>Username: <strong>{this.state.username}</strong></p>
                            <p>Email: <strong>{this.state.email}</strong></p>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose}>
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.dialogTaskOpen && this.state.openUserId !== undefined &&
                    <DialogTaskFollowUps 
                        handleOpen={this.state.dialogTaskOpen || false}
                        handleClose={() => {this.setState({dialogTaskOpen: false})}}
                        userId= {this.state.openUserId}
                        leadershipCategories={this.state.leadershipCategories}
                        leadershipQualities={this.state.leadershipQualities}
                        openDialogue = {this.state.openDialogue}
                    />
                }
            </div>
        )
    }
}

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withFirebase,
    withAuthorization(condition),
)(AdminFeedbacksPage);
