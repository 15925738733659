import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//expansion
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ReactRichEditor from 'jodit-react';

import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

const joditConfig = {
        cleanHTML: {
            cleanOnPaste: true,
            replaceOldTags: {
                h1: 'h5',
                h2: 'h6',
                h3: 'h6',
                h4: 'h6',
                h5: 'h5',
            },
            denyTags: {
                script: false,
            },
        },
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        defaultActionOnPaste: "insert_clear_html",
        defaultActionOnPasteFromWord: 'insert_clear_html',
        //defaultActionOnPaste: "insert_only_text",
        //defaultActionOnPasteFromWord: 'insert_only_text',
        buttons: [
            "fullsize","|", 
            {name: "paragraph", list: {h5: 'Heading', h6: 'Heading2', p: "Normal", blockquote: "Quote"}}, 
            "bold", "underline", "italic", "|", 
            "align", "indent", "outdent", "|", 
            "ul", "ol", "|", 
            'symbols', "|", 
            "eraser", "brush", "|", 
            "link" , 'table', "image",  "|", 
            "source"
        ],
        showCharsCounter: true,
        showWordsCounter: true,
        uploader: { insertImageAsBase64URI: true },
        imageeditor: {
            "min_width": 20,
            "min_height": 20,
            "closeAfterSave": false,
            "width": "85%",
            "height": "85%",
            "crop": true,
            "resize": true,
            "resizeUseRatio": true,
            "resizeMinWidth": 20,
            "resizeMinHeight": 20,
            "cropUseRatio": true,
            "cropDefaultWidth": "70%",
            "cropDefaultHeight": "70%"
        },
        //removeButtons: ['file', 'print'],
        /***
        extraButtons: [
            {
                name: "prepare",
                tooltip: "Load Changes", 
                icon: '',
                exec: (editor) => {
                    this.setState({changesTaken:false, errorMessage:'not changed yet, in process.'})
                }
            }, 
        ],
        */
        toolbarAdaptive: false,
        //toolbarStickyOffset: 100,
        toolbarSticky: true,
        readonly: false,
};

class SettingContentRichText extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        this.state = {
            defaultValue: '',
            openSetting: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
        })
        this.setState({
            openSetting: true,
        });
    }

    handleSettingChange = (param, event) => {
        var newText = "";
        //console.log("blur", param, event)
        if(event!== undefined && event.srcElement !== undefined && event.srcElement.innerHTML !== undefined){
            newText = event.srcElement.innerHTML;
        }
        else if(event!== undefined && (typeof event === 'string' || event instanceof String)) {
            newText = event;
        }
        else if(event!== undefined && event.target !== undefined) {
            newText = event.target.value;
        }
        if(newText !== ""){
            // replace &nbsp; with normal space
            newText = newText.replace(/<a/g, " <a");
            newText = newText.replace(/<span/g, " <span");
            newText = newText.replace(/<strong/g, " <strong");
            newText = newText.replace(/strong>/g, "strong> ");
            newText = newText.replace(/<em/g, " <em");
            newText = newText.replace(/em>/g, "em> ");
            newText = newText.replace(/&nbsp;/g, ' ');
            newText = newText.replace(/<br>\n/g, ' ');
            // replace double space with normal space
            newText = newText.replace(/  +/g, ' ');
            newText = newText.replace(/&rsquo;/g, "'");
            newText = newText.replace(/\bwidth="(\d+)"/g, 'width="100%"');
            newText = newText.replace(/\bheight="(\d+)"/g, 'height="auto"');
            newText = newText.replace(/\bWIDTH="(\d+)"/g, 'width="100%"');
            newText = newText.replace(/\bHEIGHT="(\d+)"/g, 'height="auto"');
            //console.log("blured", newText)
            var textToCount = newText + "";
            textToCount = textToCount.replace(/<[^>]*>/g, " ");
            var wordCount = textToCount.split(" ").length;
            //console.log("text", textToCount, wordCount)
            this.setState({
                wordCount, 
                [param]: newText,
                changesTaken:true,
                errorMessage:'Processed changes!'
            });
        }
    }

    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            return this.setState({[p] : this.state.defaultValue});
        })
    }

    handleSettingSubmit = () => {
        this.props.params.map((p) => {
            //console.log('preupdate', p, this.state)
            if(this.state[p] !== null && this.state[p] !== "" && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                var updateSet = { [p]: this.state[p] };
                if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                    Object.assign(updateSet, this.props.updateExtraChange)
                }
                if(this.props.addWordCount && this.state.wordCount >= 0){
                    Object.assign(updateSet, {[p+'WordCount']: this.state.wordCount})
                }
                //console.log('updateset', p, updateSet)
                this.props.dbUpdateLink.update(updateSet);
                if(this.props.updateState !== undefined){
                    this.props.updateState(updateSet);
                }
                if(this.props.updateVariants !== undefined){
                    var variantIndex = this.state.variantIndex || 0;
                    updateSet[variantIndex] = variantIndex;
                    this.props.updateVariants(updateSet, variantIndex);
                }
            }
            return '';
        })        
        this.handleSettingClose();
    }

    takeOverChanges = () => {
        this.setState({changesTaken:true})
    }

    render() {
        const { content, params, title } = this.props;
        const { theme, classes } = this.props;
        //console.log(joditConfig)
        return (
            content
            ?
            <div className={classes.grow}>
                <Card style={theme.cardAdminSettings}>
                    <Grid container>
                        <Grid item xs={9} ><p style={{color:"darkgreen"}}>{title}</p>  </Grid>
                        <Grid item xs={1} >  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)
                            &&
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item xs={12} >
                        {
                            params.map((p, labelsindex) => (
                                <p style={theme.textSettings} key={"current_"+p}>
                                    {content[p]!== undefined
                                        ?   <span style={{ float: 'right', fontWeight: 300, maxWidth:'75%' }}>{content[p]?.substring(0,150)} ...</span>
                                        :   <span style={{ float: 'right'}}>{"[n/a]"}</span>
                                    }
                                    {(this.props.showShort!==false && this.props.noLabel!== true)
                                        &&  <span>{this.props.labels?.[labelsindex] || p}</span>
                                    }
                                </p>
                            ))
                        }
                        </Grid>
                </Grid>
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm"
                    /// needed for the input fiels in the jodit editor
                    disableEnforceFocus={true}
                    >
                    <DialogTitle>Change Rich Text</DialogTitle>
                    <form>
                    <DialogContent>
                        <div ref={el => this.dialogRef = el}>
                            {/**
                            <p>1. Copy text into external RichText HTML Editor</p>
                            <p>2. Process text and modify it to create beautified HTML verions</p>
                            <p>3. Copy formated text back into this field and save it</p>
                            <p><a href="https://wordtohtml.net/" rel="noopener noreferrer" target="_blank"> https://wordtohtml.net/</a></p>
                            */}
                            {params.map((p) => (
                                <div style={{width: `100%`}} key={"change_"+p}>
                                    {this.props.contentVariants?.length > 0
                                    &&
                                        <div style={{paddingBottom: 10}}>
                                            {this.props.contentVariants.map((text, textIndex) => (
                                                <Button key={"buttin_"+textIndex}
                                                    onClick={() => {
                                                        this.setState({variantIndex: textIndex});
                                                        this.handleSettingChange(p, text);
                                                    }}
                                                    disabled={this.state.variantIndex === textIndex || (this.state.variantIndex === undefined && textIndex === 0)}
                                                >
                                                    Variant {textIndex}
                                                </Button>
                                            ))}
                                        </div>
                                    }
                                    <div style={{paddingBottom: 10}}>
                                        <Button onClick={this.handleSettingClose}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => {this.takeOverChanges()}}>
                                            Takeover changes
                                        </Button>
                                        {(this.state.changesTaken === true && (this.state[p] !== this.props.content[p]))
                                        ?
                                            <Button onClick={this.handleSettingSubmit}>
                                                Save
                                            </Button>
                                        :
                                            <Button disabled >
                                                Save
                                            </Button>
                                        }
                                    </div>
                                    <ReactRichEditor 
                                        height={600} 
                                        ref={this.dialogRef}
                                        value= {this.state[p] || ''}
                                        tabIndex={1}
                                        config={joditConfig}
                                        onBlur={(newContent) => this.handleSettingChange(p, newContent)}
                                        /***
                                         */
                                        onChange={(newContent) => {
                                            //console.log('change', newContent)
                                            if(this.state.changesTaken){
                                                //this.setState({changesTaken:false, errorMessage:'not changed yet, in process.'})
                                            }
                                        }}
                                    />
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label="About-Expand"
                                            aria-controls="About-content"
                                            id="About-header"
                                        >
                                            HTML Editor
                                        </AccordionSummary>
                                        <AccordionDetails>        
                                            <TextField
                                                fullWidth
                                                style={ {fontSize: '16px'} }
                                                key={"change_"+p}
                                                id={"change_"+p}
                                                multiline
                                                InputProps={{
                                                    style:{fontSize: '16px'}
                                                }}
                                                rows="12"
                                                label={p}
                                                value={this.state[p]}
                                                onChange={(e) => this.handleSettingChange(p, e)}
                                                //onChange={this.handleSettingChange(p)}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                            {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?
                            <p style={theme.textErrorCenterPadding}>
                                {this.state.errorMessage}
                            </p>
                            : <p> </p>
                            }
                        </div>
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => {this.setState({changesTaken:true})}}>
                            Takeover changes
                        </Button>
                        {(this.state.changesTaken === true)
                        &&
                            <Button onClick={this.handleSettingSubmit}>
                                Save
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
            : <div> </div>
                
        );
    }
}

SettingContentRichText.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase
)(SettingContentRichText);
