import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as ROUTES from "../../constants/routes";

import SettingSubscriptionWebBlue from './SettingSubscriptionWebBlue';
import SettingSubscriptionStripe from './SettingSubscriptionStripe';
import SettingSubscriptionPaytm from './SettingSubscriptionPaytm';
import SettingSubscriptionMobile from './SettingSubscriptionMobile';
import BadgeCard from '../Modules/BadgeCard';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import ShowCurrentSubscription from '../Profile/ShowCurrentSubscription';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
//import TextField from '@material-ui/core/TextField';

//import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingPlan extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.context.purchasedProduct === undefined ){
            this.props.firebase.user(this.context.uid).update({purchasedProduct: {owned : false}})
        }
        ///// listener for any new purchase that is succesful
        var selectDate = new Date();
        this.unsubscribe = this.props.firebase
        .purchases(this.context.uid)
        .where('createdAt', '>=', selectDate)
        .orderBy('createdAt', 'desc')
        .limit(5)
        .onSnapshot(snapshot => {
            if(this._isMounted ){
                snapshot.forEach(doc => {
                    var purchaseItem = doc.data();
                    if(purchaseItem.transactionRocky !== undefined && purchaseItem.transactionRocky.owned === true && this._isMounted){
                        //console.log('purchase was succesfull', purchaseItem.transactionRocky);
                        this.setState({
                            purchaseSuccess: purchaseItem.transactionRocky
                        })
                        if(this.props.purchaseSuccessFlag !== undefined){
                            this.props.purchaseSuccessFlag();
                        }
                    }
                })
            }
        })
        //// This is a test
        //this.setState({ purchaseSuccess: {owned: true} })
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe !== undefined){
            this.unsubscribe();
        }
    }

    render() {
        const { theme, classes } = this.props;
        let purchasedProduct = undefined;
        if (this.context!==undefined && this.context.purchasedProduct){
            purchasedProduct = this.context.purchasedProduct;
        }
        //console.log('user',this.context, "product", purchasedProduct)
        //console.log(process.env.REACT_APP_DEVICE_PLATFORM)
        return (
            this.context !== undefined
                ?
                    <div className={classes.grow}>
                        <Card style={theme.cardSettings}>
                            {(this.context.purchasedProduct?.owned || 
                                (this.props.showCurrentSubscription !== false &&
                                    (
                                        (purchasedProduct !== undefined && purchasedProduct.state !== undefined && purchasedProduct.price !== undefined) 
                                        ||
                                        ((process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER") && (this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH") || this.props.coach===true))
                                    )
                                )
                            )
                            &&
                                <div style={{...theme.textSettings, marginBottom:50}}>
                                    <ShowCurrentSubscription 
                                        user={this.context} 
                                        coach={this.props.coach || false}
                                    />
                                </div>
                            }
                            {(this.context.roles?.includes("COACHADMIN") || this.context.roles?.includes("REMOTECOACH") || this.props.coach===true ) &&
                                (process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER") 
                                ?
                                    <p style={theme.textSupport}>
                                        <span>Business subscriptions!</span>
                                    </p>
                                :
                                    <p style={theme.textSupport}>
                                        <span>Personal AI Coach subscriptions</span>
                                    </p>
                            }
                            {this.props.coach !== true 
                            &&
                                <>
                                    {this.context.dialogueCounter>1 && this.props.coach!==true &&
                                        <p style={theme.textSettings}>
                                            <span style={theme.textSettings.settingsTextValue}>{this.context.dialogueCounter}</span>
                                            <span style={theme.textSettings.settingsTextDescription}>Chats Completed:</span>
                                        </p>
                                    }
                                    {this.context.freeDialogues>0 && !(purchasedProduct?.owned) &&
                                        <p style={theme.textSettings}>
                                            <span style={theme.textSettings.settingsTextValue}>{this.context.freeDialogues}</span>
                                            <span style={theme.textSettings.settingsTextDescription}>Free Chats:</span>
                                        </p>
                                    }
                                    {this.context.dialoguesPurchased!== undefined && this.context.dialoguesPurchased.totalDialoguesPurchased > 0 &&
                                        <p style={theme.textSettings}>
                                            <span style={theme.textSettings.settingsTextValue}>(+{this.context.dialoguesPurchased.freeDialoguesPurchased}) {this.context.dialoguesPurchased.totalDialoguesPurchased} </span>
                                            <strong style={theme.textSupport}>Purchased Chats:</strong>
                                        </p>
                                    }
                                </>
                            }
                            {this.state.error !== undefined && this.state.error !== "" &&
                                <p style={theme.textSettings}>
                                    <span style={theme.textSettings.settingsTextValue}>{this.state.error}</span>
                                    <span style={theme.textSettings.settingsTextDescription}>Info:</span>
                                </p>
                            }
                            {(this.state.purchaseSuccess?.owned)
                                ?
                                    <>
                                        <BadgeCard
                                            key={'pruchasesuccess'}
                                            backgroundColor ={theme.palette.themeGold}
                                            image={undefined} 
                                            avatarImage = {undefined}
                                            title = { 'Congratulations' }
                                            subtitle = { 'Transaction Successful!' }
                                            strongSubtitle = {false}
                                            onClick={ () => {
                                                if(this.props.purchaseSuccessHandler !== undefined){
                                                    this.props.purchaseSuccessHandler();
                                                }
                                                else{
                                                    this.props.history.push(ROUTES.HOME);
                                                }
                                            }}
                                            clickText= { undefined } 
                                            button={'Continue'}
                                        />
                                        {!(process?.env?.REACT_APP_DEVICE_PLATFORM === "BROWSER") && !(this.props.variant === 'externalLink' && this.props.externalLink?.length > 2)
                                        &&
                                            <SettingSubscriptionMobile
                                                coach={false}
                                                sandbox={false}
                                                premiumOnly={this.props.premiumOnly}
                                                userId={this.context.uid} user={this.context}
                                                showTerms={this.props.showTerms? true: false}
                                                showOffers={false}
                                            />
                                        }
                                    </>
                                :
                                    this.props.variant === 'externalLink' && this.props.externalLink?.length > 2
                                    ?
                                        <div style={{textAlign: 'center'}}>
                                            {process?.env?.REACT_APP_DEVICE_PLATFORM!=="BROWSER"
                                            ?
                                                <>
                                                    <span style={theme.textSupport}>Get access to our services:</span>
                                                    <br/><br/>
                                                    {this.props.defaultSet?.programURL?.includes("http")
                                                        ?
                                                            <Button onClick={() => {window.open(this.props.defaultSet.programURL + "/#/profile?uid=" + this.context.uid, "_blank")}}>
                                                                Check Service Offers
                                                            </Button>
                                                        :
                                                            <Button onClick={() => {window.open(this.props.externalLink.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email), "_blank")}}>
                                                                Check Service Offers
                                                            </Button>
                                                    }
                                                </>
                                            :   this.props.externalLink?.includes("stripe.")
                                                ?
                                                    <SettingSubscriptionStripe
                                                        coach={this.props.coach ? this.props.coach : false}
                                                        sandbox={false}
                                                        defaultSet={this.props.defaultSet}
                                                        coachCode={this.props.defaultSet.programCode}
                                                        userId={this.context.uid}
                                                        fallbackURL={this.props.externalLink.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email)}
                                                        user={this.context}
                                                        premiumOnly={this.props.premiumOnly}
                                                        showTerms={this.props.showTerms? true: false}
                                                    />
                                                :   this.props.externalLink?.includes("paytm.")
                                                    ?
                                                        <SettingSubscriptionPaytm
                                                            coach={this.props.coach ? this.props.coach : false}
                                                            sandbox={false}
                                                            defaultSet={this.props.defaultSet}
                                                            coachCode={this.props.defaultSet.programCode}
                                                            userId={this.context.uid}
                                                            fallbackURL={this.props.externalLink.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email)}
                                                            user={this.context}
                                                            premiumOnly={this.props.premiumOnly}
                                                            showTerms={this.props.showTerms? true: false}
                                                        />
                                                    :
                                                        <>
                                                            <span style={theme.textSupport}>Get access to our services:</span>
                                                            <br/><br/>
                                                            <Button onClick={() => {window.open(this.props.externalLink.replace("{{uid}}", this.context.uid).replace("{{email}}", this.context.email), "_blank")}}>
                                                                Open {this.props.defaultSet.programAvatarName} Shop
                                                            </Button>
                                                        </>
                                            }
                                        </div>
                                    :   process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"
                                        ?
                                            <div style={{textAlign: 'center'}}>
                                                {/***
                                                 * || (purchasedProduct === undefined || purchasedProduct === null
                                                        || (purchasedProduct.state !== undefined && purchasedProduct.owned===false) 
                                                        || (purchasedProduct.state !== undefined && purchasedProduct.owned!==true) 
                                                        )
                                                */}
                                                {this.context.purchasedProduct?.owned 
                                                &&
                                                    <Button
                                                        style={{marginBottom : 20}}
                                                        onClick={() => this.setState({showAllSubscriptions : (!(this.state.showAllSubscriptions) || true)})}
                                                    >
                                                        Show subscriptions
                                                    </Button>
                                                
                                                }
                                                {(this.state.showAllSubscriptions || !(this.context.purchasedProduct?.owned ) )
                                                &&
                                                    <SettingSubscriptionStripe
                                                        coach={this.props.coach ? this.props.coach : false}
                                                        sandbox={false}
                                                        userId={this.context.uid}
                                                        user={this.context}
                                                        premiumOnly={this.props.premiumOnly}
                                                        showTerms={this.props.showTerms? true: false}
                                                    />
                                                }
                                                {purchasedProduct?.receiptLink?.includes('https://') 
                                                    &&
                                                    <div>
                                                        <div style={{textAlign:'center', marginTop:10}}>
                                                            <Button onClick={() => {window.open(purchasedProduct.receiptLink, "_blank")}}>
                                                            Manage Subscriptions</Button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        :
                                            <>
                                                {
                                                    <SettingSubscriptionMobile
                                                        coach={false}
                                                        sandbox={false}
                                                        premiumOnly={this.props.premiumOnly}
                                                        userId={this.context.uid} user={this.context}
                                                        showTerms={this.props.showTerms? true: false}
                                                        />
                                                }
                                            </>
                            }
                        </Card>
                    </div>
                :   <div> loading shop </div>  
        );
    }
  
}

SettingPlan.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingPlan);
