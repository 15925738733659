export function DataCacheRead(dataSetName) {
    var cacheDataSet = {
        cacheAvailable:false,
        cacheData: null,
        isCacheExpired: true,
        expirationDate: null
    }
    var timestampNow = Date.now() / 1000 | 0;
    let data = localStorage.getItem(dataSetName);
    let timestampCache = localStorage.getItem(dataSetName+'_TimeToLive');
    try{
        if(data!==undefined && data!==null){
            cacheDataSet.cacheAvailable = true
            cacheDataSet.cacheData = JSON.parse(data)
        }
        //console.log(timestampNow, timestampToLive)
        if(timestampCache !==undefined && timestampCache !==null){
            var timestampToLive = parseInt(timestampCache)
            cacheDataSet.expirationDate = timestampToLive
            if(timestampToLive>timestampNow){
                //console.log(timestampNow, timestampToLive)
                cacheDataSet.isCacheExpired = false;
            }
            else{
                cacheDataSet.isCacheExpired = true;
            }
        }
    }
    catch(err){
        console.log(err)
        cacheDataSet.cacheAvailable = false
    }
    //console.log(cacheDataSet)
    return cacheDataSet
}

export function DataCacheWrite(dataSetName, data, minutesToLive) {
    if(data !== undefined && data !== null){
        var time = new Date();
        time.setMinutes(time.getMinutes() + minutesToLive);
        var setTime = Math.round(time.getTime()/1000) + minutesToLive*10;
        //console.log('new time', setTime)
        try{
            localStorage?.setItem(dataSetName, JSON.stringify(data))
            localStorage?.setItem(dataSetName+'_TimeToLive', setTime)
        }
        catch(error){
            console.log("Removing cache")
            try{
                var myAuthUser = localStorage.getItem('authUser');
                localStorage.clear();
                console.log("Restore Session")
                localStorage?.setItem('authUser', myAuthUser);
                localStorage?.setItem(dataSetName, JSON.stringify(data))
                localStorage?.setItem(dataSetName+'_TimeToLive', setTime)
            }
            catch(error){
                console.log("Removing cache")
            }
        }
    }
}