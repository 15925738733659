//import palette from '../palette';

function muiStyle (palette) { return {
    root: {
        backgroundColor: palette.backPrimary
    },
    dotActive: {
        backgroundColor: palette.themeGold
    }
}}

export default muiStyle;