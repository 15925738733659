//import { min } from "lodash";

export function SetAgentStatus(propsUser) {
    var routineStateData = {};
    var resultPriming = setAgentPrimingReflectionStatus(propsUser, 'priming');
    var resultReflection = setAgentPrimingReflectionStatus(propsUser, 'reflection');
    //console.log("pr", resultPriming , 'ref', resultReflection)
    Object.assign(routineStateData,resultPriming );
    Object.assign(routineStateData,resultReflection );
    //setReflectionStatus();
    if (!propsUser.firstSessionDone) 
    {
        Object.assign(routineStateData, {scope: 'current'});
    }
    return routineStateData;
}

export async function SetAgentDialogue(propsUser, database, checkCreation = false) {
    var routineStateData = {};
    routineStateData = checkLast45Minutes(propsUser);
    var dialCreationPhase = localStorage.getItem('dialoguecreation_'+propsUser.uid);
    if(routineStateData !== {} && routineStateData.currentDialogueId !== undefined && routineStateData.currentDialogueId !== ''){
        //console.log('session in last 45 min', routineStateData)
        return routineStateData;
    }
    else if(checkCreation && dialCreationPhase === 'inprocess') {
        var dialogueIdAvailable = propsUser.currentDialogue;
        if(dialogueIdAvailable===undefined ){
            dialogueIdAvailable = 'temp' + propsUser.uid + ''
        }
        routineStateData['currentDialogueId'] = dialogueIdAvailable;
        routineStateData['primingInTimeRange'] = false;
        routineStateData['reflectionInTimeRange'] = true;
        routineStateData['primingStatus'] = 'CONTINUE';
        routineStateData['reflectionStatus'] = 'CONTINUE';
        var currentPhase = propsUser.phase;
        if(currentPhase === "priming"){
            routineStateData['primingInTimeRange'] = true;
            routineStateData['reflectionInTimeRange'] = false;
        }
        else if(currentPhase === "reflection"){
            routineStateData['primingInTimeRange'] = true;
            routineStateData['reflectionInTimeRange'] = false;
        }
        return routineStateData;
    }
    else {
        localStorage?.setItem('dialoguecreation_'+propsUser.uid, 'inprocess');
        var resultPriming = setAgentPrimingReflectionStatus(propsUser, 'priming');
        var resultReflection = setAgentPrimingReflectionStatus(propsUser, 'reflection');
        //console.log("pr", resultPriming , 'ref', resultReflection)
        Object.assign(routineStateData, resultPriming );
        Object.assign(routineStateData, resultReflection );
        //console.log("pr", routineStateData , 'ref',resultPriming, resultReflection)
        if (!propsUser.firstSessionDone) 
        {
            Object.assign(routineStateData, {scope: 'current'});
        }
        return await checkAndCreateDialogue(routineStateData, propsUser, database)
        .then( (dialogueId) => {
            localStorage?.setItem('dialoguecreation_'+propsUser.uid, 'ready');
            routineStateData['currentDialogueId'] = dialogueId;
            //console.log(dialogueId, "pr", resultPriming , 'ref', resultReflection, 'setting in work', routineStateData)
            return routineStateData;
        })
    }
}

function checkLast45Minutes(propsUser){
    var routineStateData={};
    var existingDialogueId = '';
    var type = '';
    var dialogDate = new Date();
    var now = new Date();
    var nowMinus45 = new Date();
    nowMinus45 = nowMinus45.setMinutes(now.getMinutes() - 45);
    if(propsUser.currentDialogue !== undefined && propsUser.currentDialogue !== ''){
        //console.log('refl', propsUser.currentDialogue )
        var flagFoundChat = false;
        if(propsUser.primingSessionStartedAt !== undefined && propsUser.primingSessionStartedAt !== ''){
            dialogDate = new Date(propsUser.primingSessionStartedAt?.seconds * 1000);
            dialogDate = dialogDate.setMinutes(dialogDate.getMinutes() + 0);
            //console.log('primin now', dialogDate)
            //console.log('pr minu 45', nowMinus45)
            if(dialogDate > nowMinus45){
                //console.log('Choose existing priming dialogue')
                existingDialogueId = propsUser.currentDialogue;
                type='priming';
                routineStateData = setAgentPrimingReflectionStatus(propsUser, type, true);
                routineStateData['currentDialogueId'] = existingDialogueId;
                routineStateData['primingInTimeRange'] = true;
                routineStateData['reflectionInTimeRange'] = false;
                flagFoundChat = true;
            }
        }
        if(!flagFoundChat && propsUser.reflectionSessionStartedAt !== undefined && propsUser.reflectionSessionStartedAt !== ''){
            dialogDate = new Date(propsUser.primingSessionStartedAt?.seconds * 1000);
            dialogDate = dialogDate.setMinutes(dialogDate.getMinutes() + 0);
            //console.log('refl', dialogDate,  nowMinus45)
            if(dialogDate > nowMinus45){
                //console.log('Choose existing reflection dialogue')
                existingDialogueId = propsUser.currentDialogue;
                type='reflection';
                routineStateData = setAgentPrimingReflectionStatus(propsUser, type, true);
                routineStateData['currentDialogueId'] = existingDialogueId;
                routineStateData['primingInTimeRange'] = false;
                routineStateData['reflectionInTimeRange'] = true;
                flagFoundChat = true;
            }
        }
    }
    return routineStateData;
}

function setAgentPrimingReflectionStatus(propsUser, type='priming', inTimeRange=undefined) {	
    if(inTimeRange === undefined){
        inTimeRange = checkInTimeRange(propsUser[type+'From'], propsUser[type+'Until']);
    }
    var routineStateData = {[type+'InTimeRange'] :  inTimeRange};
    var currentTime = (new Date()).getTime();
    //console.log('currentTime', currentTime )
    if (inTimeRange) {
        if (propsUser[type+'SessionFinishedAt'] && propsUser[type+'SessionStartedAt'] ) 
        {
            // HN adding comparison for the case the a new chat was started
            if(propsUser[type+'SessionFinishedAt'] && propsUser[type+'SessionStartedAt']  && propsUser[type+'SessionFinishedAt'].seconds > propsUser[type+'SessionStartedAt'].seconds)
            {
                //console.log('finsihed', propsUser[type+'SessionFinishedAt'].seconds * 1000 )
                if (currentTime - propsUser[type+'SessionFinishedAt'].seconds * 1000 < (86400000 / 2)) 
                {
                    //console.log('stopped at a - sessionfinsihed at')
                    Object.assign(routineStateData, {  [type+'Status']: 'DONE' });
                    return routineStateData;
                }
            }
 
        }
        if ((propsUser.dialogueStatus === 'created' ||
                propsUser.dialogueStatus === 'session_requested' ||
                propsUser.dialogueStatus === 'utterance_sent' ||
                propsUser.dialogueStatus === 'running') 
                && (propsUser.phase === type)) 
        {
            if (propsUser[type+'SessionStartedAt']) 
            {
                //console.log('started', propsUser[type+'SessionStartedAt'].seconds * 1000 )
                if (currentTime - propsUser[type+'SessionStartedAt'].seconds * 1000 > (86400000 / 2)) 
                {
                    Object.assign(routineStateData, {  [type+'Status']: 'START' });
                    return routineStateData;
                } 
                else 
                {                        
                    if(propsUser[type+'SessionFinishedAt'] && propsUser[type+'SessionFinishedAt'].seconds > propsUser[type+'SessionStartedAt'].seconds)
                    {
                        //console.log('done after comparing started bigger', propsUser[type+'SessionFinishedAt'].seconds, propsUser[type+'SessionStartedAt'].seconds)
                        Object.assign(routineStateData, {  [type+'Status']: 'DONE' });
                        return routineStateData;
                    }
                    else{
                        Object.assign(routineStateData, {  [type+'Status']: 'CONTINUE' });
                        return routineStateData;
                    }
                }
            } 
            else 
            {
                Object.assign(routineStateData,{  [type+'Status']: 'START' });
                return routineStateData;
            }
        } 
        else 
        {
            Object.assign(routineStateData, {  [type+'Status']: 'START' });
            return routineStateData;
        }
    } 
    else {
        if (propsUser[type+'SessionFinishedAt']) 
        {
            //console.log('started 2', propsUser[type+'SessionFinishedAt'].seconds * 1000)   
            if (currentTime - propsUser[type+'SessionFinishedAt'].seconds * 1000 < (86400000 / 2)) {
                if (type === 'reflection' && (new Date()).getDate() === new Date(propsUser[type+'SessionFinishedAt'].seconds * 1000).getDate()) 
                    {
                        Object.assign(routineStateData,{  [type+'Status']: 'TIME' });
                        return routineStateData;
                    }
                else
                {
                    Object.assign(routineStateData,{  [type+'Status']: 'DONE' });
                    return routineStateData;
                }
            }
        }
        if (checkForPassed(type, propsUser)) 
        {
            Object.assign(routineStateData, {  [type+'Status']: 'PASSED' });
            return routineStateData;
        } 
        else 
        {
            Object.assign(routineStateData,{  [type+'Status']: 'TIME' });
            return routineStateData;
        }
    }
}

export function checkForPassed(sessionType, propsUser) {
    var primingFrom = "04:00";
    if(propsUser.primingFrom!==undefined) primingFrom = propsUser.primingFrom;
    var reflectionFrom = "15:00";
    if(propsUser.reflectionFrom!==undefined) reflectionFrom = propsUser.reflectionFrom;
    let now = new Date();
    let nowStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();
    if (sessionType === 'priming') 
    {
        let startMilliseconds = 1000 * (3600 * parseInt(primingFrom.substring(0, 2)) + 60 * parseInt(primingFrom.substring(3)));
        if (propsUser.primingSessionFinishedAt) 
        {
            if ((now.getTime() - propsUser.primingSessionFinishedAt.seconds * 1000) > (86400000 / 2)) 
            {
                if (now.getTime() > (nowStart + startMilliseconds))  return true;
                else return false;
            } 
            else return false;
        } 
        else 
        {
            if (now.getTime() > (nowStart + startMilliseconds)) return true;
            else return false;
        }
    } 
    else 
    {
        let startMilliseconds = 1000 * (3600 * parseInt(reflectionFrom.substring(0, 2)) + 60 * parseInt(reflectionFrom.substring(3)));
        if (propsUser.reflectionSessionFinishedAt) 
        {
            if ((now.getTime() - propsUser.reflectionSessionFinishedAt.seconds * 1000) > (86400000 / 2)) 
            {
                if (now.getTime() > (nowStart + startMilliseconds))  return true;
                else  return false; 
            } 
            else  return false;
        } 
        else 
        {
            if (now.getTime() > (nowStart + startMilliseconds)) return true;
            else return false;
        }
    }
}

export function minSessionTimeStamp(sessionType, propsUser) {
    var primingFrom = "04:00";
    if(propsUser.primingFrom!==undefined) primingFrom = propsUser.primingFrom;
    var reflectionFrom = "15:00";
    if(propsUser.reflectionFrom!==undefined) reflectionFrom = propsUser.reflectionFrom;
    let now = new Date();
    let nowStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    let nowStartTS = nowStartDate.getTime();
    //console.log("newStart", nowStartDate, nowStartTS)
    let startMilliseconds  = 0;
    var combinedTime = nowStartTS;
    if (sessionType === 'priming')  {
        startMilliseconds = 1000 * (3600 * parseInt(primingFrom.substring(0, 2)) + 60 * parseInt(primingFrom.substring(3)));
        //console.log("prime", startMilliseconds);
        combinedTime=nowStartTS + startMilliseconds;
        return new Date(combinedTime)
    } 
    else  {
        var minHoursPriming = 60*parseInt(reflectionFrom.substring(0, 2)) + parseInt(primingFrom.substring(3))
        var nowHours = 60*now.getHours() + now.getMinutes();
        //this will make the date base for yesterday when reflection is done after midnight
        if(nowHours < minHoursPriming){
            nowStartDate = new Date(nowStartTS - (24*60*60*1000) );
            nowStartTS = nowStartDate.getTime();
            //console.log("yesterday", new Date(nowStartTS))
        }
        startMilliseconds = 1000 * (3600 * parseInt(reflectionFrom.substring(0, 2)) + 60 * parseInt(reflectionFrom.substring(3)));
        combinedTime = nowStartTS + startMilliseconds;
        //console.log("reflec", nowStartTS, startMilliseconds, combinedTime)
        return new Date(combinedTime)
    }
}

export function checkInTimeRange(fromTime, untilTime) {
    if(fromTime=== undefined) fromTime = "04:00"
    if(untilTime=== undefined) untilTime = "15:00"
    //console.log(fromTime, untilTime)
    let now = new Date();
    //console.log('now', now)
    let nowStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();

    //console.log('nowStart', nowStart)
    let startMilliseconds = 1000 * (3600 * parseInt(fromTime.substring(0, 2)) + 60 * parseInt(fromTime.substring(3)));
    let endMilliseconds = 1000 * (3600 * parseInt(untilTime.substring(0, 2)) + 60 * parseInt(untilTime.substring(3)));
    // check if reflection time range goes over midnight
    if (startMilliseconds > endMilliseconds) {
        // check if current time is past midnight
        if (now.getTime() - nowStart < (86400000 / 2)) startMilliseconds = 0;
        else endMilliseconds += 86400000;
    }
    let startTimestamp = nowStart + startMilliseconds;
    let endTimestamp = nowStart + endMilliseconds;
    if (startTimestamp < now.getTime() && now.getTime() < endTimestamp) return true;
    else return false;
}

async function checkAndCreateDialogue(agentStatus, propsUser, database) {
    //console.log('time',agentStatus,propsUser )
    //console.log('time from', minSessionTimeStamp(agentStatus.reflectionInTimeRange? "reflection" : "priming", propsUser))
    //// initial parameters
    var dialogueIdAvailable = ''
    //// return or create dialogue id
    if(agentStatus.primingInTimeRange){
        //console.log('agentstats', agentStatus, propsUser.phase, propsUser.currentDialogue)
        if(propsUser.phase === 'priming' && propsUser.currentDialogue!==undefined && propsUser.currentDialogue!=="" && agentStatus.primingStatus !== 'PASSED'){
            if(agentStatus.primingStatus === 'DONE' ||  agentStatus.primingStatus === 'CONTINUE'){
                dialogueIdAvailable = propsUser.currentDialogue
                if(agentStatus.primingStatus === 'DONE'){
                    database.dialogue(dialogueIdAvailable).update({
                        status: false,
                        extend: 'add',
                    })
                }
            }
        }
    }
    else if(agentStatus.reflectionInTimeRange ){
        if(propsUser.phase === 'reflection' && propsUser.currentDialogue!==undefined && propsUser.currentDialogue!=="" && agentStatus.reflectionStatus !== 'PASSED'){
            if(agentStatus.reflectionStatus === 'DONE' ||  agentStatus.reflectionStatus === 'CONTINUE'){
                dialogueIdAvailable = propsUser.currentDialogue;
                if(dialogueIdAvailable!=='' && agentStatus.reflectionStatus === 'DONE'){
                    //console.log('CALLING ANother TIME');
                    database.dialogue(dialogueIdAvailable).update({
                        status: false,
                        extend: 'add',
                    })
                }
            }
        }
    }
    //console.log('agentStatus', agentStatus)
    // if no dialogueid found search for it or create it
    if(dialogueIdAvailable === ""){
        //set min time
        var sessionTimeStart = minSessionTimeStamp(agentStatus.reflectionInTimeRange? "reflection" : "priming", propsUser)
        //console.log('seARCH NEW     dialog', dialogueIdAvailable, sessionTimeStart)
        return await database.dialogues()
        .where('userId', '==', propsUser.uid)
        .where('createdAt', '>=', sessionTimeStart)
        .where('dialogueType', '==', agentStatus.reflectionInTimeRange? "reflection" : "priming")
        //.orderBy('createdAt', 'desc')
        .limit(1)
        .get()
        .then(snapshot => {
            if (snapshot.size) {
                // found 
                snapshot.forEach(doc => {
                    dialogueIdAvailable = doc.id;
                    //console.log('found existing dialogue in db', dialogueIdAvailable)
                })
                database.user(propsUser.uid).update({
                    currentDialogue: dialogueIdAvailable,
                    phase: agentStatus.reflectionInTimeRange? "reflection" : "priming"
                })
                return dialogueIdAvailable
            }
            else{
                var todaysLeadershipQuality = '';
                var currentLeadershipQuality = '';
                var currentCategory = '';
                var xpStart = 0;
                if(propsUser.engagementCounter !== undefined && propsUser.engagementCounter > 0){
                    xpStart = propsUser.engagementCounter;
                }
                if(propsUser.todaysLeadershipQuality !== undefined) 
                    todaysLeadershipQuality = propsUser.todaysLeadershipQuality;
                if(propsUser.currentLeadershipQuality !== undefined) 
                    currentLeadershipQuality = propsUser.currentLeadershipQuality;
                if(propsUser.currentCategory !== undefined) 
                    currentCategory = propsUser.currentCategory;
                //console.log('adding dialoge')
                var newDialogueSet = {
                    coachId: 'agent',
                    createdAt: database.fieldValue.serverTimestamp(),
                    dialogueType: agentStatus.reflectionInTimeRange? "reflection" : "priming",
                    todaysLeadershipQuality: todaysLeadershipQuality,
                    currentLeadershipQuality: currentLeadershipQuality,
                    currentCategory: currentCategory,
                    status: false,
                    phase: 'created',
                    xpStart,
                    title: '',
                    userId: propsUser.uid,
                    currentExplanation: ''
                }
                ////// Add the mobile versus browser as channel
                if(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
                    newDialogueSet['channel'] = 'browser'
                }
                else{
                    newDialogueSet['channel'] = 'mobile'
                }
                //////
                ////// Use the last title
                if(propsUser.lastTitle !== undefined && propsUser.lastTitle.length > 1){
                    newDialogueSet['lastTitle'] = propsUser.lastTitle;
                }
                /////
                return database.dialogues()
                    .add( newDialogueSet )
                    .then( (docRef) => {
                        console.log('created new chat', docRef.id)
                        dialogueIdAvailable = docRef.id
                        if( agentStatus.reflectionInTimeRange ){
                            database.user(propsUser.uid).update({
                                currentDialogue: docRef.id,
                                phase: "reflection",
                                reflectionSessionStartedAt: database.fieldValue.serverTimestamp(),
                                lastEngagement:  database.fieldValue.serverTimestamp()
                            })
                        }
                        else{
                            database.user(propsUser.uid).update({
                                currentDialogue: docRef.id,
                                phase: "priming",
                                primingSessionStartedAt: database.fieldValue.serverTimestamp(),
                                lastEngagement:  database.fieldValue.serverTimestamp()
                            })
                        }
                        return dialogueIdAvailable
                    })
            }
        })
    }
    else{
        return dialogueIdAvailable
    }
}