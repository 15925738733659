import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import BadgeCard from '../Modules/BadgeCard';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import logoHotline from "../../images/Badge_hotline.png";
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ForumIcon from '@material-ui/icons/Forum';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Grid from '@material-ui/core/Grid';

import TextField from "@material-ui/core/TextField";

import LoadingProgress from '../Modules/LoadingProgress';

import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import DialogSupport from '../Support/DialogSupport';
import { CreateAlert } from '../Modules/CreateAlert';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    }
});

class MessagesUser extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialogues: [],
        };
    }


    componentDidMount() {
        this._isMounted = true;
        this.loadSupportMessages();
        if(this.props.isCoach){
            this.loadCoachClients();
        }
        
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribeDialogues!== undefined) this.unsubscribeDialogues()
        if(this.dbLatestMessage !== undefined)  this.dbLatestMessage()
    }

    loadSupportMessages(){
        //console.log('opened', this.props.groupId)
        if(this.props.groupId !== undefined && this.props.groupId !== ""){
            if(this.props.groupId === 'Latest'){
                this.dbLatestMessage = this.props.firebase
                .dialogues()
                .where('dialogueType', '==', "SUPPORT")
                .where('userId', '==', this.context.uid)
                .orderBy('createdAt', 'desc')
                .limit(80)
                //.get().then(snapshot => {
                .onSnapshot(snapshot => {
                    let dialogues = [];
                    let dialoguesArchive = [];
                    let latestDialogueIds = [];
                    snapshot.forEach(doc =>{
                        latestDialogueIds.push(doc.id);
                        if(doc.data().phase === "session_ended"){
                            dialoguesArchive.push({ ...doc.data(), id: doc.id })
                        }
                        else{
                            dialogues.push({ ...doc.data(), id: doc.id })
                        }
                    });
                    if(this.context.newMessages!== undefined){
                        var extraMessages = this.context.newMessages.filter(q => q.type === "SUPPORT");
                        var extraIds = [];
                        extraMessages.forEach(item => {
                            if(item.dialogueId!== undefined && item.dialogueId!=='' && !latestDialogueIds.includes(item.dialogueId) && extraIds.length<9){
                                extraIds.push(item.dialogueId)
                            }
                        })
                        //console.log('extraIds', extraIds)
                        if(extraIds.length>0){
                            this.props.firebase
                            .dialogues()
                            .where(this.props.firebase.fieldPath.documentId(), 'in', extraIds)
                            .get().then(extraSnapshot => {
                                extraSnapshot.forEach(doc =>{
                                    if(!latestDialogueIds.includes(doc.id)){
                                        latestDialogueIds.push(doc.id);
                                        //console.log('extramessage', doc.data())
                                        var supportChat = doc.data();
                                        if(doc.data().phase === "session_ended"){
                                            dialoguesArchive.push({ ...supportChat, id: doc.id })
                                        }
                                        else{
                                            dialogues.push({ ...supportChat, id: doc.id })
                                        }
                                    }
                                });
                            });
                        }
                    }
                    if(this._isMounted){
                        this.setState({dialogues, dialoguesArchive, loading:false})
                        //console.log('subscribed', dialogues, dialoguesArchive)
                    }
                });
            }
            else if(this.props.isCoach){
                this.props.firebase
                .dialogues()
                .where('coachId', '==', this.props.groupId)
                .where('phase', 'in', ['created','session_ended'])
                .orderBy('createdAt', 'desc')
                .limit(30)
                .get().then(snapshot => {
                //.onSnapshot(snapshot => {
                    let dialogues = []
                    let dialoguesArchive = []
                    snapshot.forEach(doc =>{
                        if(doc.data().phase === "session_ended"){
                            dialoguesArchive.push({ ...doc.data(), id: doc.id })
                        }
                        else{
                            dialogues.push({ ...doc.data(), id: doc.id })
                        }
                    });
                    if(this._isMounted){
                        this.setState({dialogues, dialoguesArchive, loading:false})
                        //console.log('subscribed', dialogues)
                    }
                });
            }
            else{
                this.props.firebase
                .dialogues()
                .where('userId', '==', this.context.uid)
                .where('coachId', '==', this.props.groupId)
                .orderBy('createdAt', 'desc')
                .limit(15)
                .get().then(snapshot => {
                //.onSnapshot(snapshot => {
                    let dialogues = [];
                    let dialoguesArchive = [];
                    snapshot.forEach(doc =>{
                        if(doc.data().phase === "session_ended"){
                            dialoguesArchive.push({ ...doc.data(), id: doc.id })
                        }
                        else{
                            dialogues.push({ ...doc.data(), id: doc.id })
                        }
                    });
                    if(this._isMounted){
                        this.setState({dialogues, dialoguesArchive, loading:false})
                        //console.log('subscribed', dialogues)
                    }
                });
            }
        }
    }

    loadCoachClients(){
        if(this.props.groupId !== undefined && this.props.groupId !== ''  && this.props.groupId !== 'default' ){
            this.unsubscribe = this.props.firebase
            .users()
            .where('groups', 'array-contains', this.props.groupId)
            //.orderBy('username')
            .get().then(snapshot => {
                let coachees = {};
                if(this._isMounted){
                    snapshot.forEach(doc => {
                        var coacheeUser = doc.data();
                        if(coacheeUser.roles!==undefined && doc.id !== this.context.uid ){
                            var userSet = {
                                username : coacheeUser.username,
                                uid : doc.id,
                                notification: "in-app"
                            }
                            if(coacheeUser.mobileDeviceToken!== undefined && coacheeUser.mobileDeviceToken!== '' && coacheeUser.mobileNotifications!== 'inactive' ){
                                userSet['notification'] = 'mobile'
                                userSet['mobileNotifications'] = coacheeUser.mobileNotifications
                                userSet['mobileDeviceToken'] = coacheeUser.mobileDeviceToken
                            }
                            else if(coacheeUser.emailNotifications !== 'never'){
                                userSet['notification'] = 'email'
                                userSet['emailNotifications'] = coacheeUser.emailNotifications
                            }
                            coachees[doc.id] = userSet
                        }
                    })
                    //console.log('subscribed', coachees)
                    this.setState({groupCoachees: coachees})
                }

            })
        }
    }

    render() {
        const {theme} = this.props;
        const {dialogues, dialoguesArchive, loading} = this.state;
        if(this.props.groupId !== undefined && this.props.groupId!== ""){
            return(
                <div>
                    <p>
                        Conversations with: 
                    </p>
                    <h4>
                        {this.props.groupId === "Latest" 
                            ?   'Latest'
                            :   (this.props.groupId === "default")
                                    ?   "Service Call" 
                                    :   this.props.isCoach 
                                        ?   "My clients"
                                        :   "My coach"
                        }
                    </h4>
                    {loading &&
                        <div>
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LinearProgress'} loadingText={"loading conversations"} />
                        </div>
                    }
                    {dialogues!== undefined && dialogues.length>0 
                        ?
                            <>
                                <p>
                                    <strong>Active Conversations</strong>
                                </p>
                                {
                                    dialogues.map( (item, index) => (
                                        <div key={"dialog_"+index}>
                                            <Grid container spacing={1} direction="row"
                                                alignItems="center"
                                                justify="space-between"
                                            >
                                                <Grid item xs={3}>
                                                    <span style={{fontSize:'small'}}>{ConvertTimestampToDateString(item.createdAt, true)}</span>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <strong style={theme.textSupport}>
                                                    {item.userId !== this.context.uid
                                                        ?
                                                                <span>
                                                                    {item.username}
                                                                </span>
                                                        :
                                                            
                                                                <span>
                                                                    {item.coachId === 'default' ? "Support Request" : item.coachId }
                                                                </span>
                                                                
                                                    }
                                                    </strong>
                                                    <br/>
                                                    <strong >
                                                        {item.title}
                                                    </strong>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {(this.props.isCoach )
                                                    //|| item.userId !== this.context.uid
                                                        ?
                                                        <Badge 
                                                            badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.dialogueId === item.id)).length > 0 ? 1 : 0 }
                                                            //badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.userId === item.userId && q.groupId === this.props.groupId )).length > 0 ? 1 : 0 }
                                                            //badgeContent={1}
                                                            color="error">
                                                            <IconButton onClick={ () => this.setState({dialogueArchive: false, dialogueId: item.id, selectedUserId: item.userId, dialogueTitle:item.title,  openMessaging: true}) }>
                                                                {item.phase === "created"
                                                                    ?<ForumIcon />
                                                                    :<FilterNoneIcon />
                                                                }
                                                            </IconButton>
                                                        </Badge>
                                                        :
                                                        <Badge 
                                                            badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.dialogueId === item.id)).length > 0 ? 1 : 0 }
                                                            //badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.userId === this.context.uid && q.groupId === this.props.groupId )).length > 0 ? 1 : 0 }
                                                            //badgeContent={1}
                                                            color="error">
                                                            <IconButton onClick={ () => this.setState({dialogueArchive: false, dialogueId: item.id, selectedUserId: this.context.uid, dialogueTitle:item.title,  openMessaging: true}) }>
                                                                {item.phase === "created"
                                                                    ?<ForumIcon />
                                                                    :<FilterNoneIcon />
                                                                }
                                                            </IconButton>
                                                        </Badge>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))
                                }
                            </>
                        :
                            'No new messages in your inbox'
                    }
                    {this.props.groupId !== 'Latest'
                        &&
                        <>
                            <hr/>
                            {this.props.isCoach 
                                ?
                                    this.state.groupCoachees !== undefined && Object.keys(this.state.groupCoachees).length>0 
                                    ?
                                        <Grid container spacing={3} direction="row">
                                            <Grid item> 
                                                <p>
                                                    Start a new conversation
                                                </p>
                                                <InputLabel shrink>
                                                    Select Client:
                                                </InputLabel>
                                                <Select
                                                    value={this.state.selectedUserId || ""}
                                                    onChange={(e) => this.setState({ selectedUserId: e.target.value })}
                                                    name='program'
                                                    >
                                                    {Object.keys(this.state.groupCoachees).map(key => {
                                                        var entry = this.state.groupCoachees[key];
                                                        return (
                                                            <MenuItem key={"add_"+entry.uid} value={entry.uid}
                                                                selected={entry.uid === this.state.selectedUserId}
                                                            >
                                                            {entry.username}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </Grid>
                                            <Grid item> 
                                                <Button variant="contained"  onClick={() => {this.setState({openAlert: true})}}
                                                    disabled={this.state.selectedUserId === undefined || this.state.selectedUserId === ""}
                                                >
                                                    Start New Conversation&nbsp;<ForumIcon fontSize='small'/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    :   'No team members in this group'
                                :   ''
                            }
                            {this.props.isCoach !== true 
                                ?
                                    this.props.groupId === 'default'
                                    ?
                                        <>
                                            {/***
                                            <Button variant="contained"  onClick={() => {this.setState({openAlert: true})}}>
                                                Request Support&nbsp;<ForumIcon fontSize='small'/>
                                            </Button> 
                                            */}
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={logoWhatsNext} 
                                                avatarImage = {undefined}
                                                title = {'Create'}
                                                subtitle = {'Support Request'}
                                                strongSubtitle = {true}
                                                onClick={() => {this.setState({openAlert: true})}}
                                                button={<>Message&nbsp;<HeadsetMicIcon /></>}
                                                />
                                            {false && 
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={logoHotline} 
                                                avatarImage = {undefined}
                                                title = {'Book 1-on-1'}
                                                subtitle = {'Service Call'}
                                                strongSubtitle = {true}
                                                onClick={ () => window.open("https://calendly.com/rocky-ai/30min", "_blank") }
                                                button={<>Meeting&nbsp;<HeadsetMicIcon /></>}
                                                /> 
                                            }
                                        </>
                                    :
                                        <>
                                            {/***
                                                <Button variant="contained"  onClick={() => {this.setState({openAlert: true})}}>
                                                    Start New Conversation&nbsp;<ForumIcon fontSize='small'/>
                                                </Button>
                                            */}
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={logoWhatsNext} 
                                                avatarImage = {undefined}
                                                title = {'Message'}
                                                subtitle = {'Team Coach'}
                                                strongSubtitle = {true}
                                                onClick={() => {this.setState({openAlert: true})}}
                                                button={<>Message&nbsp;<HeadsetMicIcon /></>}
                                                /> 

                                        </>
                                :   
                                    this.props.groupId === 'default'
                                    ?
                                        <>
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={logoHotline} 
                                                avatarImage = {undefined}
                                                title = {'Book 1-on-1'}
                                                subtitle = {'Service Call'}
                                                strongSubtitle = {true}
                                                onClick={ () => window.open("https://calendly.com/rocky-ai/30min", "_blank") }
                                                button={<>Zoom&nbsp;<HeadsetMicIcon /></>}
                                                /> 
                                        </>   
                                
                                    :   ''
                            }
                        </>
                    }
                    {dialoguesArchive!== undefined && dialoguesArchive.length>0 &&
                        <>
                            <hr/>
                            <p>
                                <strong>Conversation Archive</strong>
                            </p>
                            {
                                dialoguesArchive.map( (item, index) => (
                                    <div key={"dialog_"+index}>
                                        <Grid container spacing={1} direction="row"
                                            alignItems="center"
                                        >
                                            <Grid item xs={3}>
                                                <span style={{fontSize:'small'}}>{ConvertTimestampToDateString(item.createdAt, true)}</span>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <strong style={theme.textSupport}>
                                                {item.userId !== this.context.uid
                                                    ?
                                                            <span>
                                                                {item.username}
                                                            </span>
                                                    :
                                                        
                                                            <span>
                                                                {item.coachId === 'default' ? "Support Request" : item.coachId }
                                                            </span>
                                                            
                                                }
                                                </strong>
                                                <br/>
                                                <strong >
                                                    {item.title}
                                                </strong>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {this.props.isCoach
                                                    ?
                                                    <Badge 
                                                        badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.dialogueId === item.id)).length > 0 ? 1 : 0 }
                                                        //badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.userId === item.userId && q.groupId === this.props.groupId )).length > 0 ? 1 : 0 }
                                                        //badgeContent={1}
                                                        color="error">
                                                        <IconButton onClick={ () => this.setState({dialogueArchive: true, dialogueId: item.id, selectedUserId: item.userId, dialogueTitle:item.title,  openMessaging: true}) }>
                                                            {item.phase === "created"
                                                                ?<ForumIcon />
                                                                :<FilterNoneIcon />
                                                            }
                                                        </IconButton>
                                                    </Badge>
                                                    :
                                                    <Badge 
                                                        badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.dialogueId === item.id)).length > 0 ? 1 : 0 }
                                                        //badgeContent={ this.context.newMessages!== undefined && this.context.newMessages.filter(q => (q.userId === this.context.uid && q.groupId === this.props.groupId )).length > 0 ? 1 : 0 }
                                                        //badgeContent={1}
                                                        color="error">
                                                        <IconButton onClick={ () => this.setState({dialogueArchive: true, dialogueId: item.id, selectedUserId: this.context.uid, dialogueTitle:item.title,  openMessaging: true}) }>
                                                            {item.phase === "created"
                                                                ?<ForumIcon />
                                                                :<FilterNoneIcon />
                                                            }
                                                        </IconButton>
                                                    </Badge>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))
                            }
                            <hr/>
                        </>
                    }
                    <DialogSupport 
                        dialogueId = {this.state.dialogueId || ''}
                        dialogueTitle = {this.state.dialogueTitle || ''}
                        userId = {this.state.selectedUserId}
                        userData = {this.state.groupCoachees !== undefined && this.state.groupCoachees[this.state.selectedUserId] !== undefined
                            ? this.state.groupCoachees[this.state.selectedUserId] : {} }
                        dialogueArchive = {this.state.dialogueArchive}
                        isCoach = {this.props.isCoach}
                        groupOwners = {this.props.groupOwners}
                        username = {this.context.username !== undefined ? this.context.username.split("/")[0] : 'User'}
                        groupId = {this.props.groupId}
                        openMessaging = {this.state.openMessaging || false}
                        handleCloseMessaging = {this.handleCloseMessaging}
                    />
                    <RemoveScroll enabled={this.state.openAlert||false }>
                        <Dialog
                            key={this.state.actionId}
                            open={this.state.openAlert || false}
                            onClose={() => this.setState({openAlert: false})}
                            fullScreen={IsMobileDevice()}
                            PaperProps={{style:theme.dialogWindow}}
                            maxWidth="sm"
                        >
                            <DialogTitle style={theme.dialogTitle}  >
                                <Grid container spacing={0} direction="row"
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item >
                                        <IconButton onClick={() =>  {this.setState({openAlert: false})} }>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleText}>
                                            Add personal conversation
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleLink} onClick={() =>  {this.setState({openAlert: false})} }>
                                            {"Cancel"}
                                        </span>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            {this.state.openAlert &&
                                <div >
                                    <DialogContent style={{...theme.dialogContent}}>
                                        <p>Start a new conversation</p>
                                        {this.props.isCoach && this.state.selectedUserId !== undefined && this.state.selectedUserId !== ""
                                        &&
                                            <div>
                                                <p>
                                                    Client: <strong>{this.state.groupCoachees[this.state.selectedUserId].username}</strong>
                                                    <br/>
                                                    Notification: {this.state.groupCoachees[this.state.selectedUserId].notification}
                                                </p>
                                            </div>
                                        }
                                        <span>Title of topic or request</span>
                                        <TextField
                                            name="title"
                                            style={ {fontSize: '16px'} }
                                            //label="topic"
                                            type="text"
                                            variant="outlined"
                                            placeholder="Add a topic..."
                                            fullWidth
                                            multiline
                                            InputProps={{
                                                style:{fontSize: '16px'}
                                            }}
                                            value={this.state["topic"]}
                                            onChange={(e) => {this.setState({ topic : e.target.value})}}
                                        />
                                        &nbsp;<br/>
                                        <span>Question or comment</span>
                                        <TextField
                                            name="comment"
                                            style={ {fontSize: '16px'} }
                                            //label="Your comment"
                                            type="text"
                                            variant="outlined"
                                            placeholder="Add a question..."
                                            fullWidth
                                            multiline
                                            InputProps={{
                                                style:{fontSize: '16px'}
                                            }}
                                            value={this.state["comment"]}
                                            onChange={(e) => {this.setState({ comment : e.target.value})}}
                                        />
                                        <hr/>
                                        {
                                            this.state.comment!== undefined && this.state.comment.length>2
                                            && this.state.topic!== undefined && this.state.topic.length>3
                                            ?   this.props.isCoach
                                                ?
                                                    <Button onClick={() => {
                                                            //this.addConversation(this.context.uid, this.props.groupId, this.state.selectedUserId)
                                                            AddConversation(this, this.context.uid, this.props.groupId, this.state.selectedUserId, this.state.groupCoachees[this.state.selectedUserId])
                                                        }}>
                                                        Start
                                                    </Button>
                                                :
                                                    <Button onClick={() => {
                                                            //this.addConversation(this.context.uid, this.props.groupId, this.context.uid)
                                                            AddConversation(this, this.context.uid, this.props.groupId, this.state.selectedUserId)
                                                        }}>
                                                        Start
                                                    </Button>
                                            :
                                                <> 
                                                    <p>Please provide a topic and comment</p>
                                                    <Button 
                                                        disabled={true}>
                                                        Start
                                                    </Button>
                                                </>
                                        }
                                    </DialogContent>
                                </div>
                            }
                        </Dialog>
                    </RemoveScroll>
                </div>
            ); // end return
        }
        else{ return ""}
    }// end render

    handleCloseMessaging = () => {
        //console.log('closed')
        if(this._isMounted){
            this.loadSupportMessages();
            this.setState({openMessaging: false, dialogueId:'' , dialogueTitle: '', selectedUserId:''});
        }
    }

} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(MessagesUser);

export function AddConversation(that, userId, groupId, receiverId, endUserData = undefined, specialMessage = undefined, specialTopic = undefined ){
//export function addConversation(userId, groupId, receiverId){
    if(receiverId === undefined || receiverId === ''){
        receiverId = groupId;
    }
    if(that.props.isCoach!== true){
        receiverId = userId;
    }
    //console.log('usersets for saving', that.props, userId, groupId, receiverId)
    var todaysLeadershipQuality = '';
    var currentLeadershipQuality = '';
    var currentCategory = '';
    if(that.context.todaysLeadershipQuality !== undefined) 
        todaysLeadershipQuality = that.context.todaysLeadershipQuality;
    if(that.context.currentLeadershipQuality !== undefined) 
        currentLeadershipQuality = that.context.currentLeadershipQuality;
    if(that.context.currentCategory !== undefined) 
        currentCategory = that.context.currentCategory;
    var message = (specialMessage || that.state.comment || "Please help me");
    var topic = (specialTopic || that.state.topic || "Messages and Notes");
    console.log('message', message)
    var date = that.props.firebase.fieldValue.serverTimestamp();
    var username  = that.context.username !== undefined ? that.context.username.split("/")[0] : 'user';
    //var receiverName = 'Team member';
    if(endUserData !== undefined && endUserData !== null && endUserData.username !== undefined){
        username = endUserData.username;
    }
    var dialogueSet = {
        coachId: groupId,
        createdAt: date,
        dialogueType: "SUPPORT",
        todaysLeadershipQuality: todaysLeadershipQuality,
        currentLeadershipQuality: currentLeadershipQuality,
        currentCategory: currentCategory,
        askHelp: message,
        phase: 'created',
        username : username,
        title: topic,
        userId: receiverId,
    }
    //console.log('dialogueSet', dialogueSet)
    that.props.firebase.dialogues().add( dialogueSet )
    .then( (docRef) => {
        console.log('created new chat', docRef.id)
        var dialogueIdAvailable = docRef.id;
        if(that.props.isCoach!== true){
            that.props.firebase.messages().add({
                userId: receiverId,
                groupId: groupId,
                isCoach: true,
                createdAt: date,
                addFollowUp: false,
                dialogueId: dialogueIdAvailable,
                text: ['How can we help you?']
            });/// need extra time
            that.props.firebase.messages().add({
                userId: receiverId,
                groupId: groupId,
                isCoach: false,
                createdAt: date,
                username : username,
                addFollowUp: false,
                dialogueId: dialogueIdAvailable,
                text: [topic]
            });/// need extra time
            that.props.firebase.messages().add({
                userId: receiverId,
                groupId: groupId,
                isCoach: false,
                createdAt: that.props.firebase.fieldValue.serverTimestamp(),
                username : username,
                addFollowUp: false,
                dialogueId: dialogueIdAvailable,
                text: [message]
            });
            if (that.props.groupOwners!== undefined && that.props.groupOwners.length > 0){
                that.props.groupOwners.map((coachId) => {
                    var alertDict = {
                        coachId : coachId,
                        userId : receiverId,
                        groupId : groupId,
                        dialogueId : dialogueIdAvailable,
                        type : "SUPPORT"
                    }
                    //console.log(alertDict, coachId, message, topic, username)
                    CreateAlert(that.props.firebase, {uid: coachId, username: 'Coach', emailNotifications:'daily'}, message, topic, username, new Date(), alertDict )
                    //that.updateAlerts (dialogueIdAvailable, coachId, userId, groupId, username, message, topic);
                    return true;
                })
            }
            else{
                var alertDict = {
                    coachId : 'default',
                    userId : receiverId,
                    groupId : groupId,
                    dialogueId : dialogueIdAvailable,
                    type : "SUPPORT"
                }
                CreateAlert(that.props.firebase, {uid: 'AwWa9CbyOMY5hcSyeo68ihVbRnx1', username: 'User Service', emailNotifications:'daily'}, message, topic, username, new Date(), alertDict )
                //that.updateAlerts (dialogueIdAvailable, 'default', userId, groupId, username, message, topic);
            }
        }
        else{ /// for coach
            var coachName = that.context.username;
            that.props.firebase.messages().add({
                coachId : userId,
                userId: receiverId,
                groupId: groupId,
                isCoach: true,
                createdAt: date,
                username : coachName,
                addFollowUp: false,
                dialogueId: dialogueIdAvailable,
                text: [topic]
            });/// need extra time
            that.props.firebase.messages().add({
                coachId : userId,
                userId: receiverId,
                groupId: groupId,
                isCoach: true,
                createdAt: that.props.firebase.fieldValue.serverTimestamp(),
                username : coachName,
                addFollowUp: false,
                dialogueId: dialogueIdAvailable,
                text: [message]
            });
            var alertDict2 = {
                coachId : userId,
                userId : receiverId,
                groupId : groupId,
                dialogueId : dialogueIdAvailable,
                type : "SUPPORT"
            }
            //console.log(alertDict2, receiverId, message, topic, username, coachName, endUserData)
            CreateAlert(that.props.firebase, {uid: receiverId, ...endUserData}, message, topic, coachName, new Date(), alertDict2 )
            //that.updateAlerts (dialogueIdAvailable, userId, receiverId, groupId, coachName, message, topic);
        }
        
        if(that._isMounted){
            that.setState({openAlert: false, topic: undefined, comment: undefined})
            if(that.loadSupportMessages!== undefined){
                that.loadSupportMessages();
            }
        }
    })
}
  
