import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import LoadingProgress from '../../Modules/LoadingProgress';

import { withFirebase } from '../../Firebase';
import { withAuthorization,
	 // withEmailVerification
       } from '../../Session';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

// MUI stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonSwitch from '@material-ui/core/Switch';


import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// Icons
import AddIcon from '@material-ui/icons/Add';

// Modules for parameter change
import SettingContentBase from '../Modules/SettingContentBase';
import SettingContentActive from '../Modules/SettingContentActive';
//import SettingContentNumber from '../Modules/SettingContentNumber';
//import SettingContentArray from '../Modules/SettingContentArray';
//import SettingContentImage from '../Modules/SettingContentImage';
import SettingContentUploadImage from '../Modules/SettingContentUploadImage';
//import SettingContentRichText from '../Modules/SettingContentRichText';
import SettingContentArraySelect from '../Modules/SettingContentArraySelect';
import SettingContentSelect from '../Modules/SettingContentSelect';
import SettingContentArrayCheckbox from '../Modules/SettingContentArrayCheckbox';

const styles = {
    table: {
        minWidth: 100,
    },
    subtitle: {
        marginTop: 20,
        fontWeight: 700,
    },
    addButton: {
        marginTop: 20,
    }
};

class AdminLeadershipProgramsPage extends Component {
    render() {
        return (
            <div style={{backgroundColor: "#DDDDDD"}}>
                <Switch>
                    <Route exact path={ROUTES.ADMIN} component={LeadershipCategoryList} />
                    <Route exact path={ROUTES.ADMIN_LEADERSHIP_PROGRAMS} component={LeadershipCategoryList} />
                </Switch>
            </div>
        );
    }
}

class LeadershipProgramsListBase extends Component {

    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        var errorMessage = '';
        this.state = {
            loading: false,
            contents: [],
            contentsTab: [],
            contentsLabels: [],
            errorMessage: errorMessage,
            text: '',
            value: '',
            program: '',
            open: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
        loading: true,
        open: false, 
        text: '',
        value: '',
        program: '',
        showTab: '',
        });

        var name = '';

        this.unsubscribe = this.props.firebase
        .collectionByName('leadership_programs')
        .orderBy('group', 'asc')
        .onSnapshot(snapshot => {
            let contentsTab = [];
            let contentsLabels = [];
            var keySorting = 'program'
            if(this._isMounted ){
                snapshot.forEach(doc =>{
                    if(this.context.roles?.includes('ADMIN')){
                        if(this.context.programs?.includes(doc.data().program) || doc.data().program===undefined){
                            name = doc.data()[keySorting];
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                contentsTab[name] = []
                                contentsLabels.push(name );
                            }
                            contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        } // end if true
                    } // end of if ADMIN
                    else if(this.context.roles?.includes('COACHADMIN')){
                        if(this.context.programs?.includes(doc.data().program) && doc.data().program !=='default'){
                            name = doc.data()[keySorting];
                            if(name === undefined){
                                name = '---undefined---'
                            }
                            if(!(name in contentsTab)){
                                //contentsTab[doc.data().category]={name:doc.data().category, contents: []};
                                contentsTab[name] = []
                                contentsLabels.push(name );
                            }
                            contentsTab[name].push({ ...doc.data(), uid: doc.id });
                        }//end if program / no default
                    } // end of if ADMIN
                });//end of snapshot
                //console.log('contents', contentsTab)
                if(this.state.showTab === undefined || this.state.showTab === ''){
                    this.setState({showTab: name})
                }
                this.setState({
                    contentsTab: contentsTab,
                    contentsLabels,
                    loading: false,
                });
            }//end ismounted
        });
    }

    handleOpen = () => {
        //console.log('handleOpen');
        this.setState({ open: true });
    };
    handleClose = () => {
        //console.log('handleClose');
        this.setState({ open: false });
    };
    handleChange = (event) => {
        //console.log([event.target.name] + ": " + event.target.value);
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = () => {
        if(this.state.text !== '' && this.state.value !== '' && this.state.program !== '')  {
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.collectionByName('leadership_programs').add({
              text: this.state.text,
              value: this.state.value,
              program: this.state.program,
              active: false,
              createdAt: timestamp
            });
            this.setState({errorMessage : 'Added category'})
        }
        else{
            this.setState({errorMessage : 'All three values have to be defined to add a category'})
        }
        this.setState({
            text: '',
            value: '',
            program: '',
        });
        this.handleClose();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    }

    handleTabChange = (event, newValue) => {
        //console.log('new tab', newValue)
        this.setState({
            showTab: newValue
        });
    }

    render() {
        const { showTab, contentsTab, contentsLabels, loading } = this.state;
        //console.log('tabs', contentsLabels)
        return (
            <div style={{height: '100vh'}}>
                {loading && 
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={"many, many, data"} 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                        allowRefresh={true}
                    />
                }
                <div>
                    <IconButton style={styles.addButton} onClick={this.handleOpen}>
                    <AddIcon />
                    </IconButton>
                    <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="sm">
                    <DialogContent>
                        <DialogTitle>Add a new category (only masters)</DialogTitle>
                        <form>
                        <TextField
                            name="text"
                            type="text"
                            label="1. Title"
                            placeholder="Enter name"
                            value={this.state.text}
                            onChange={this.handleChange}
                            fullWidth
                            style={ {fontSize: '16px'} }
                            />
                        <TextField
                            name="value"
                            type="text"
                            label="2. internal ID value (cannot be changed/no duplicates)"
                            placeholder="Enter value (small letters, no space)"
                            value={this.state.value}
                            onChange={this.handleChange}
                            fullWidth
                            style={ {fontSize: '16px'} }
                            />
                        <p>3. Select program</p>
                        <Select
                            value={this.state.program}
                            onChange={this.handleChange}
                            name='program'
                        >
                            {this.context.programs.map(entry => {
                                return (
                                    <MenuItem key={"add_"+entry} value={entry}
                                    >
                                    {entry}
                                    </MenuItem>
                                    );
                                })
                            
                            }
                            
                        </Select>
                        
                        </form>
                        <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit}>
                            Save
                        </Button>
                        </DialogActions>
                    </DialogContent>
                    </Dialog>
                </div>
                {this.state.errorMessage !== '' && 
                    <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                }
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={this.handleTabChange}
                    initialselectedindex={0}
                    value={showTab}
                >
                    {contentsLabels.length>0 && 
                    contentsLabels.map( (key) => (
                        <Tab 
                        value={key} 
                        key={key}
                        label={key + " ("+contentsTab[key].length+")"}/>
                    ))
                    }
                </Tabs>
                
                {contentsLabels.length>0 && 
                    contentsLabels.map( (key) => (
                    <div 
                        value={key} 
                        key={key} 
                        hidden={showTab !==key}>
                        <Table size="small">
                        <TableHead>
                            <TableRow>
                            <TableCell>Active</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell>Roles</TableCell>
                            <TableCell>Cat</TableCell>
                            <TableCell>Short Explanation</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell align="right">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contentsTab[key].map(content => (
                            <TableRow key={content.uid}>
                                <TableCell  onClick={(e) => {this.setState({['dialog_'+content.uid] : true })}} > 
                                {content.active!== undefined
                                    ?
                                    content.active===true
                                    ?<ButtonSwitch disabled color="secondary" checked
                                        value={content.active} size="small" 
                                        style={{ backgroundColor: 'blue' || 'blue' }}/>
                                    :<ButtonSwitch 
                                        disabled checked={false} 
                                        value={content.active} size="small" />
                                    :
                                        <span>{"-"}</span>
                                }
                                </TableCell>
                                <TableCell><strong>Text:</strong> {content.text}</TableCell>
                                <TableCell><strong></strong> {content.group}</TableCell>
                                <TableCell><strong></strong> {content.requirements? content.requirements.join(', '): 'n/a'}</TableCell>
                                <TableCell>{content.explanationShort}</TableCell>
                                
                                <TableCell><strong>value:</strong> {content.value}</TableCell>
                                <TableCell align="right"  onClick={(e) => {this.setState({['dialog_'+content.uid] : true })}} >
                                Edit
                                {/**
                                 <Link
                                    to={{
                                    pathname: `${ROUTES.ADMIN_LEADERSHIP_CATEGORIES}/${content.uid}`,
                                    state: { content },
                                    }}
                                    >
                                    Details
                                </Link>
                                */}
                                </TableCell>
                                <LeadershipCategoryItemDialog 
                                contentId={content.uid}
                                openDialog={this.state['dialog_'+content.uid] || false}
                                closeDialog={(e) => {this.setState({['dialog_'+content.uid] : false })}}
                                />
                            </TableRow>
                            
                            ))}
                        </TableBody>
                        </Table>
                    </div>
                    ))
                }
            </div>
        );
    }
}

class LeadershipProgramsItemBaseDialog extends Component {
    _isMounted = false;
    static contextType = AuthUserContext;
    constructor(props) {
        super(props);
        var cid = this.props.contentId;
        var errorMessage = '';
        //console.log('location',cid)
        this.state = {
            loading: false,
            content: null,
            cid: cid,
            errorMessage: errorMessage,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.cid) {
            this.setState({ loading: true });
            this.unsubscribe = this.props.firebase
            .documentIdByCollection('leadership_programs', this.state.cid)
            .onSnapshot(snapshot => {
                //console.log('data',snapshot.data())
                if(this._isMounted ){
                    this.setState({
                        content: snapshot.data(),
                        loading: false,
                    });
                }
            });
        }
        this.getleadershipCategoriesArray();
        this.getleadershipQualitiesArray();
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
        this.setState({});
    }

    getleadershipCategoriesArray() {
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipCategories()
        .orderBy('text', 'asc')
        .where('active', '==', true)
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push(doc.data().text);
            }
            });
        });
        if(this._isMounted){
            //console.log(myArray, myArrayText)
            this.setState({
                leadershipCategories: myArray,
                leadershipCategoriesText: myArrayText
            });
        }
    }
    getleadershipQualitiesArray(){
        var myArray = [];
        var myArrayText = [];
        this.props.firebase
        .leadershipQualities()
        .where('active', '==', true)
        .orderBy('category', 'asc')
        .orderBy('text', 'asc')
        .get()
        .then(snapshot => {
            if (snapshot.empty) {
            return;
            }
            snapshot.forEach(doc => {
            if(this.context.programs.includes(doc.data().program)){
                myArray.push(doc.data().value);
                myArrayText.push( "(" + doc.data().category + ") " + doc.data().text);
            };
            });
        });
        if(this._isMounted){
            this.setState({
                leadershipQualities: myArray,
                leadershipQualitiesText: myArrayText
            });
        }
         
    }

    render() {
        const { content, loading } = this.state;
        
         
        //console.log('my context', this.context)
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={this.props.closeDialog}
                >
                <DialogTitle >
                    <IconButton onClick={this.props.closeDialog}>
                        (X)
                    </IconButton>
                    Modify Content
                </DialogTitle>
                <DialogContent >
                    <div>
                        <h5 style={styles.subtitle}>Program Admin: ({this.props.contentId})</h5>
                        {loading && 
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} 
                                loadingText={"many, many, data"} 
                                firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                allowRefresh={true}
                            />
                        }
                        {this.state.errorMessage !== '' && 
                            <h4 style={{color :'red'}}>{this.state.errorMessage}</h4>
                        }
                        {content && (
                            <div>
                                {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                                &&
                                    <h4 style={{color :'blue'}}>Unique ID value: <strong>{content.value}</strong></h4>  
                                }
                                <SettingContentActive 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Active Content and Premium"} 
                                    content={content}
                                    labels={['Active in the app', 'Premium Content', 'Available as fallback for any Profile']}
                                    params={['active', 'premium', 'public']}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Title of category"} 
                                    content={content} 
                                    params={['text']}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Short Explanation"} 
                                    content={content} 
                                    params={['explanationShort']}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Longer definition"} 
                                    content={content} 
                                    params={['definition']}
                                    />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Assigned program"} 
                                    content={content} 
                                    params={['program']}
                                    selectOptions={this.context.programs}
                                    />
                                <SettingContentSelect 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Main connected category of program"} 
                                    content={content} 
                                    params={['motherCategory']}
                                    selectOptions={this.state.leadershipCategories || []}
                                    selectOptionsText={this.state.leadershipCategoriesText || []}
                                    />
                                <SettingContentArraySelect
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Selection Group"} 
                                    content={content} 
                                    params={['group']}
                                    selectOptions={['people', 'tasks', 'ideas', 'lifestyle']}
                                    />
                                <SettingContentArraySelect
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Sister Modules"} 
                                    content={content} 
                                    params={['sisterQualities']}
                                    selectOptions={this.state.leadershipQualities}
                                    selectOptionsText={this.state.leadershipQualitiesText}
                                    />
                                <SettingContentSelect
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Default Start Module"} 
                                    content={content} 
                                    params={['initialLevelQuality']}
                                    selectOptions={this.state.leadershipQualities}
                                    selectOptionsText={this.state.leadershipQualitiesText}
                                    />
                                <SettingContentBase 
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"Choice for quizes"} 
                                    content={content} 
                                    params={['answerQuestionInterest', 'answerQuestionIssueShortTerm', 'answerQuestionIssueLongTerm']}
                                    />
                                <SettingContentUploadImage
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"App Icon"} 
                                    previewImageSize={true}
                                    content={content} 
                                    params={['icon']}
                                    uploadDatabase={this.props.firebase.storageImages('icons/'+this.state.cid)}
                                    />
                                <SettingContentArrayCheckbox
                                    dbUpdateLink={this.props.firebase.documentIdByCollection('leadership_programs', this.state.cid)}
                                    title={"For what user profile is this set"} 
                                    content={content} 
                                    params={['requirements']}
                                    selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                    selectOptionsText={['Question for every situation', 'Executive/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'K-12/Teenager' ]}
                                    />
                                {this.context.roles?.includes("ADMIN") &&
                                    <>
                                        Duplicate into: &nbsp;
                                        <TextField
                                            name="text"
                                            type="text"
                                            label="id title"
                                            placeholder="id text"
                                            value={this.state.idTitle || ''}
                                            onChange={(e) => this.setState({idTitle: e.target.value})}
                                            fullWidth
                                            style={ {fontSize: '16px'} }
                                            />
                                        &nbsp;
                                        {this.state.idTitle!== undefined && this.state.idTitle!=='' && this.state.idTitle &&
                                            <Button onClick={() => { this.handleDuplicateContent(this.state.idTitle, content ) }}>
                                                Duplicate
                                            </Button>
                                        }
                                    </>
                                }
                            </div>
                        )}
                    </div>
                
                </DialogContent>
            </Dialog> 
        );
    }

    handleDuplicateContent = (title, content) => {
        if(content!== undefined ){
            var newContent = {...content};
            newContent['active'] = false;
            newContent['public'] = false;
            newContent['value'] = title;
            newContent['createdAt'] = this.props.firebase.fieldValue.serverTimestamp();
            console.log('newContent', newContent)
            this.props.firebase.collectionByName('leadership_programs').add(newContent)
            //this.props.firebase.documentIdByCollection('leadership_programs', title).set(newContent, {merge:false })
            this.setState({errorMessage : 'Copied content without tags and status inactive'})
        }
        else{
            this.setState({errorMessage : 'Original data missing for a copy'})
        }
    }
}

AdminLeadershipProgramsPage.propTypes = {
    classes: PropTypes.object.isRequired
}
  
const LeadershipCategoryList = withFirebase(LeadershipProgramsListBase);
//const LeadershipCategoryItem = withFirebase(LeadershipCategoryItemBase);
const LeadershipCategoryItemDialog = withFirebase(LeadershipProgramsItemBaseDialog);

const condition = authUser =>
    authUser && authUser.roles?.includes(ROLES.ADMIN);

export default compose(
    withStyles(styles),
    withAuthorization(condition),
)(AdminLeadershipProgramsPage);
