import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingProgress from '../Modules/LoadingProgress';
import { FindTextValueInDict } from '../Modules/LoadCategoriesAndQualities';
import DialogSettingPlan from '../Profile/DialogSettingPlan';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import Button from '@material-ui/core/Button';
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublicIcon from '@material-ui/icons/Public';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import StarHalfIcon from '@material-ui/icons/StarHalf';

import DialogFlagError from '../Modules/DialogFlagError';
//// List lements
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import Divider from '@material-ui/core/Divider';
import AdjustIcon from '@material-ui/icons/Adjust';

//import AvatarImage from '../Modules/AvatarImage';
import {AvatarCard} from '../Modules/AvatarImage';
import BadgeCard from '../Modules/BadgeCard';
import {CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';
import SpeechToTextButton from '../Modules/SpeechToTextButton';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Card from '@material-ui/core/Card';

//import Fab from '@material-ui/core/Fab';

/////// the different players to try out
//import {default as RockyWebMediaPlayer} from 'react-web-media-player';
//import {default as RockyWebMediaPlayer} from "plyr-react";
//import "plyr-react/dist/plyr.css";
//import {default as RockyWebMediaPlayer} from 'react-player'
import {RenderMediaYoutube, RenderMediaVideo, RenderMediaAudio} from  '../Content/RenderMedia';


/// Tutorials
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import SchoolIcon from '@material-ui/icons/School';
//HN added thumbs up
import BookMarkVote from './BookMarkVote';
//import { createGradientImage } from '../Modules/createGradientImage';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
import {AddEngagementPoints} from '../Modules/AddEngagementPoints';
import ContentComments from './ContentComments';
//import ContentCommentHighlight from './ContentCommentHighlight';
import JournalActivities from '../Days/JournalActivities';

import TextField from "@material-ui/core/TextField";

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
//import Rating from '@material-ui/lab/Rating';
import LeadershipQualityPicker from '../Goals/LeadershipQualityPicker';

/***
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 */
import Slide from '@material-ui/core/Slide';
import { Storefront } from '@material-ui/icons';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        backgroundColor: 'transparent',
    },
    innerHTML: theme.innerHTML,
    accordion : {
        marginTop: 20,
        //marginRight: -20,
        //marginLeft: -20,
        paddingRight: 0,
        paddingLeft: 0,
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
    }
});

class DialogContentItem extends Component { 
    _isMounted = false;
    constructor(props) {
        super(props);
        this.refComment = React.createRef();
        this.refRead = React.createRef();
        this.refCommitments = React.createRef();
        var shortText = undefined;
        var premium = false;
        this.state = {
            loading: true,
            shortText,
            premium
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loading: false,
            loadingEngagements: true,
        });
        if(this.props.contentId !== undefined && this.props.contentId !== ""){
            this.getBookMarks(this.props.contentId)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.unsubscribeBookmark !== undefined){
            this.unsubscribeBookmark()
        }
    }

    componentDidUpdate(prevProps)  {
        var newContentId = '';
        var oldContentId = '';
        if(this.props.contentId !== undefined && this.props.contentId !== ""){
            newContentId = this.props.contentId
        }
        if(prevProps.contentId !== undefined && prevProps.contentId !== ""){
            oldContentId = prevProps.contentId
        }
        if(newContentId !== oldContentId ) {
            if (this.unsubscribeBookmark !== undefined){
                this.unsubscribeBookmark()
            }
            var shortText = undefined;
            if (this.props.contentData.explanation !== undefined && this.props.contentData.explanation.length > 0 ){
                shortText =  this.props.contentData.explanation[Math.floor(Math.random() * this.props.contentData.explanation.length)];
            }
            else if(this.props.contentData.text !== undefined && this.props.contentData.text!==""){
                shortText = this.props.contentData.text.substring(0,199).replace(/<\/?[^>]+(>|$)/g, "").replace(/&.*;/g,'')
            }
            else{
                shortText = this.props.contentData.title;
            }
            this.setState({openAlertMessage : '', shortText, activeRecommendStep:0} )
            this.getBookMarks(newContentId)
            this.checkSetSubscriptionFlag();
            //console.log('mount')
        }
        return true
    }

    checkSetSubscriptionFlag(){
        if(this.state.skipSubscriptionCheck && this.state.nonSubscriber===false && this.state.premium){
            console.log('active for other items')
        }
        else{
            //console.log('checking subcsrib', this.props.contentData)
            var premium = false;
            if (this.props.contentData?.category === 'tips' && (this.props.contentData?.premium === true || (this.props.contentData?.program !== 'default' && !(this.props.contentData?.premium === false))) ){
                premium = true;
                //console.log('premium active', this.props.contentData)
            }
            var nonSubscriber = false;
            if(premium && this.props.user !== undefined && this.props.contentData!== undefined){
                //// as an extra protection if content is public or within the programs
                var availableCheck = false;
                try{
                    var selectedCatPremiumFlag = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.props.contentData.leadership_category, 'premium');
                    //console.log('selectedCatPremiumFlag', selectedCatPremiumFlag, this.props.contentData.leadership_category)
                    if((selectedCatPremiumFlag!== undefined && selectedCatPremiumFlag) || (this.props.contentData.program!==undefined && this.props.user.programs !== undefined && this.props.user.programs.includes(this.props.contentData.program))){
                        availableCheck =  true;
                    }
                }
                catch(e){

                }
                //console.log('checking premium')
                if(availableCheck === true && this.props.user !== undefined && this.props.contentData !== undefined ){
                    let subscriptionStatus = CheckSubscriptionStatus(this.props.user, this.props.contentData!== undefined ? this.props.contentData.program : undefined);
                    if(subscriptionStatus.premiumSubscriber 
                        || subscriptionStatus.premiumContentAccess 
                        //|| subscriptionStatus.trialPeriod
                    ){
                        nonSubscriber = false;
                        ///// premium content is not free even for trial
                        if(premium && !subscriptionStatus.premiumSubscriber && subscriptionStatus.trialPeriod){
                            nonSubscriber = true;
                        }
                        //console.log('access due to demo and module')
                    }
                    else{
                        nonSubscriber = true;
                        //console.log('non subsceribs')
                    }
                    //console.log('checking premium', subscriptionStatus)
                }
                //console.log('subscription', nonSubscriber, dialogueCounter, freeDialogues)
                if(this._isMounted){
                    //console.log('subscription', 'add non sub ceiber')
                    this.setState({
                        nonSubscriber,
                        loadPicker:  nonSubscriber,
                        premium,
                    });
                }
            }
            else if(this._isMounted){
                //console.log('subscription', 'add non sub ceiber')
                this.setState({nonSubscriber: false, premium: false});
            }
        }
    }

    scrollToComments() {
        if(this.refComment !== undefined && this.refComment !== null){
            try{
                this.refComment.current.scrollIntoView({behaviour: 'smooth'});
                //window.scrollTo(0, this.refComment.current.offsetTop)
                this.setState({hideCommentButton:true})
            }
            catch (err) {
                console.log(err, 'No scroll to top');
            }
        }
    }
    scrollToCommitments() {
        if(this.refCommitments !== undefined && this.refCommitments !== null){
            try{
                this.refCommitments.current.scrollIntoView({behaviour: 'smooth'});
                this.setState({hideCommentButton:true})
            }
            catch (err) {
                console.log(err, 'No scroll to top');
            }
        }
    }
    scrollToRead() { 
        if(this.refRead !== undefined && this.refRead !== null){
            try{
                this.refRead.current.scrollIntoView({behaviour: 'smooth'});
            }
            catch (err) {
                console.log(err, 'No scroll to top');
            }
        }
    }
    openQualityShop = () => {
        //console.log('shop', this.props)
        if(this._isMounted && this.props.contentData !== undefined){
            //console.log('second', this.props.contentData )
            this.setState({
                //chosenCategory: this.props.contentData.leadership_category,
                //openLeadershipQualityPicker2: true,
                //openLeadershipQualityPicker: false,
                DialogSettingPlanOpen: true
            })

        }

    }
    
    render(){
        const { theme, classes } = this.props;
        //console.log('dialogcontentit', this.props)
        return(
            <>
            <RemoveScroll enabled={this.props.open === undefined ? false : this.props.open}>
                <DialogTutorialPage 
                    handleOpen={this.state.handleOpenTutorial || false}
                    handleBadgeFamily = {'content'}
                    handleClose={() => {this.setState({handleOpenTutorial: false})}}
                />
                <Dialog
                    open={this.props.open === undefined ? false : this.props.open}
                    onClose={this.props.handleClose}
                    onEntered={() => {
                        this.openedFirstHandler(this.props.contentId);
                        if(this.props.jumpToComment){ this.scrollToComments()}
                        else if(this.props.jumpToRead){ this.scrollToRead()}
                    }}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    >
                    <DialogTitle style={theme.dialogTitle} >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.props.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    {this.props.contentData !== undefined && this.props.contentData.category === "quotes" 
                                        ?   'Quote'
                                        :   'Content'
                                    }
                                </span>
                                {/***
                                <Rating name="read-only" style={{paddingTop:10}} 
                                    value={this.state.contentEngagementRating !== undefined ? this.state.contentEngagementRating:0 } 
                                    readOnly precision={0.5} icon={<CheckCircleIcon/>}/>
                                */}
                            </Grid>
                            <Grid item>
                                <IconButton style={{marginRight: 2}} onClick={() => {this.setState({handleOpenTutorial: true})}}>
                                    <HelpOutlineIcon />
                                </IconButton>
                                {/**
                                <span style={theme.dialogTitle.titleLink} onClick={this.props.handleClose}>
                                    Close
                                </span>
                                 */}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent} id="stage-animate">
                        {this.props.open && this.props.contentData!== undefined && this.props.contentData.title !== undefined 
                            ? 
                            <div>
                                <div>
                                    {this.props?.overWriteQualityValue?.length > 3
                                        ?
                                            <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.overWriteQualityValue} squareSize='20' />
                                        :
                                            this.props?.contentData?.leadership_quality?.length === 1
                                            ?   <AvatarCard dict={this.props.leadershipQualities} type='quality' item={this.props.contentData.leadership_quality[0]} squareSize='20' />
                                            :   <AvatarCard dict={this.props.leadershipCategories} type='category' item={this.props.contentData.leadership_category} squareSize='20' />
                                    }
                                </div>
                                {(this.props.contentData?.category === 'tips' && (this.props.contentData?.premium === true || (this.props.contentData?.program !== 'default' && !(this.props.contentData?.premium === false))) )
                                    &&
                                    <div style={{width:'100%', textAlign:'right'}}>
                                        <strong>
                                            <ShoppingBasketIcon style={{ ...theme.inlineIconLeft , color:theme.palette.themeGold}} />
                                            Premium
                                        </strong>
                                    </div>
                                }
                                <div style={theme.divCenterPadding}>
                                    <h3 style={theme.CardContentItem.TextTitle}>
                                        {this.props.contentData.category === "quotes" 
                                            ?   <q>{this.props.contentData.title}</q>
                                            :   this.props.contentData.title
                                        }
                                    </h3>
                                </div>
                                <Grid container spacing={2} direction='row' alignItems="center" justify="space-between" >
                                    <Grid item xs={9}>
                                        <div>
                                            <span style={theme.CardContentItem.TextSubTitle}>{this.props.contentData.subtitle} </span>
                                            {false && this.props?.contentData?.requirements?.length > 0 && !(this.props?.contentData?.requirements?.includes('default'))
                                                &&
                                                <span style={theme.textSupport}>
                                                    <br/><br/>
                                                    Best for 
                                                    {this.props.contentData.requirements.includes('executive') ? ' | Executives & Leaders' : '' }
                                                    {this.props.contentData.requirements.includes('professional') ? ' | Professionals' : '' }
                                                    {this.props.contentData.requirements.includes('seeker') ? ' | Available or Searching' : '' }
                                                    {this.props.contentData.requirements.includes('student') ? ' | Higher Education Students' : '' }
                                                    {this.props.contentData.requirements.includes('teenager') ? ' | K-12 & Teenager' : '' }
                                                </span>
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {true //(this.props.contentData.youtubeId === undefined || this.props.contentData.youtubeId === '')
                                        && this.props.contentData.url !== undefined && this.props.contentData.url !== "" 
                                        &&
                                            <div style={{
                                                //marginRight: 0,
                                                //marginLeft: -20,
                                                //width: 'calc(100% + 40px)'
                                            }}>
                                                <img style={{...theme.dialogImage, borderRadius: theme.borderRadius}} src={this.props.contentData.url} alt={this.props.contentData.title} />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                                {this.props.contentData.youtubeId !== undefined && this.props.contentData.youtubeId !== ''
                                && 
                                    <>
                                        <div 
                                            style={{
                                                marginRight: 0,
                                                marginLeft: -20,
                                                marginTop: 10,
                                                width: 'calc(100% + 40px)',
                                                filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                            }}
                                            onClick={this.state.nonSubscriber? this.openQualityShop : undefined}
                                        >
                                            {
                                                <RenderMediaYoutube
                                                    contentData = {this.props.contentData}
                                                    enabled = {this.state.nonSubscriber? false: true}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                                {this.props.contentData.mediaType !== undefined && ['video'].includes(this.props.contentData.mediaType)
                                && this.props.contentData.s3Url !== undefined
                                && 
                                    <>
                                        <div 
                                            style={{
                                                marginRight: 0,
                                                marginLeft: -20,
                                                marginTop: 10,
                                                width: 'calc(100% + 40px)',
                                                filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                            }}
                                            onClick={this.state.nonSubscriber? this.openQualityShop : undefined}
                                        >
                                            {
                                                <RenderMediaVideo
                                                    contentData = {this.props.contentData}
                                                    enabled = {this.state.nonSubscriber? false: true}
                                                />
                                                //this.renderMediaVideo()
                                            }
                                        </div>
                                    </>
                                }
                                {this.props.contentData.mediaType !== undefined && ['audio'].includes(this.props.contentData.mediaType)
                                && this.props.contentData.s3Url !== undefined
                                && 
                                    <>
                                        <div 
                                            style={{
                                                marginRight: 0,
                                                marginLeft: -20,
                                                marginTop: 10,
                                                width: 'calc(100% + 40px)',
                                                filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                            }}
                                            onClick={this.state.nonSubscriber? this.openQualityShop : undefined}
                                        >
                                            {
                                                <RenderMediaAudio
                                                    contentData = {this.props.contentData}
                                                    enabled = {this.state.nonSubscriber? false: true}
                                                />
                                                //this.renderMediaAudio()
                                            }
                                        </div>
                                    </>
                                }
                                {this.props.contentData.text!== undefined && this.props.contentData.text!== '' 
                                &&
                                    <div>
                                        <div style={{ paddingTop: 20 }}>
                                            <h4>
                                                Read Content
                                                <span style={{float: 'right'}} > 
                                                    {this.state['userEngagement' + this.props.contentId] !== undefined 
                                                    && this.state['userEngagement' + this.props.contentId].read
                                                        ?   <CheckCircleIcon style={{ color: theme.palette.themeGold }} />
                                                        :   '' //<CheckCircleIcon  color="disabled" />
                                                    }
                                                </span>
                                            </h4>
                                            <Divider />
                                        </div>
                                        <div style={{
                                            //marginTop: 20
                                            }}>
                                            &nbsp;
                                        </div>
                                        {this.state.nonSubscriber 
                                            ?   ''
                                            :   this.props.contentData.validated && this.props.contentData.explanation !== undefined &&  this.props.contentData.explanation.length > 0
                                                ?
                                                    <Card style={{...theme.CardGoals, width:"100%", padding:0, textAlign: "center"}}> 
                                                        <SwipeableViews
                                                            axis={'x'}
                                                            index={this.state.activeRecommendStep || 0}
                                                            onChangeIndex={(step) => {  
                                                                this.setState({activeRecommendStep : step});
                                                                //this.expandHandler(this.props.contentId)
                                                            }}
                                                            //enableMouseEvents = {this.state.open === true? false : true}
                                                            //disabled = {this.state.open === true? true : false}
                                                            style={{
                                                                width: '100%',
                                                                overflow: 'hidden',
                                                                textAlign: 'center',
                                                            }}
                                                            slideStyle={{
                                                                flex: 'none',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {this.props.contentData.explanation.map( (item, index) => (
                                                                <Grid container key={'swup_'+index} spacing={0} direction='row' alignItems="center"
                                                                    style={
                                                                        this.state['swuphover'+index] === true
                                                                        ?   {width:"100%", margin:0, paddingTop:8, backgroundColor: theme.palette.themeGold, borderRadius: theme.borderRadius}
                                                                        :   {width:"100%", margin:0, paddingTop:8, backgroundColor: 'transparent' }
                                                                    }
                                                                    onClick={
                                                                        this.props.messageSubmit !== undefined
                                                                        ?   () => {
                                                                                this.props.messageSubmit(item)
                                                                                this.expandHandler(this.props.contentId)
                                                                                //this.scrollToRead()
                                                                            }
                                                                        :   () => {
                                                                                this.expandHandler(this.props.contentId)
                                                                                //this.scrollToRead()
                                                                            }
                                                                    }
                                                                    onMouseEnter={(this.props.messageSubmit !== undefined) ? () => {this.setState({['swuphover'+index]: true})} : void(0)}
                                                                    onMouseLeave={(this.props.messageSubmit !== undefined) ? () => {this.setState({['swuphover'+index]: false})} : void(0)}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <div style={{flexGrow:1}} >
                                                                            
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <div >
                                                                            
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs> 
                                                                        <p style={{...theme.textCenter, color: this.props.messageSubmit !== undefined ? theme.palette.secondary.main : theme.themeText}}>
                                                                            {item!== undefined && item.length>150 ? (item.substring(0,149)+'...') : item }
                                                                        </p>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <div >
                                                                            {this.props.messageSubmit !== undefined && this.props.messageFastSubmit
                                                                                ?
                                                                                    <SendIcon fontSize='small' style={{color: theme.palette.themeRed}} />
                                                                                :
                                                                                    ""
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </SwipeableViews>
                                                        <MobileStepper
                                                            steps={this.props.contentData.explanation.length}
                                                            position="static"
                                                            variant="text"
                                                            activeStep={this.state.activeRecommendStep!==undefined &&
                                                                this.state.activeRecommendStep < this.props.contentData.explanation.length
                                                                ? this.state.activeRecommendStep
                                                                : 0}
                                                            nextButton={
                                                                <IconButton size="small" 
                                                                    onClick={() => { this.setState({activeRecommendStep : 
                                                                        (this.state.activeRecommendStep===undefined || this.state.activeRecommendStep >= this.props.contentData.explanation.length)
                                                                        ? 1
                                                                        : this.state.activeRecommendStep+1}) 
                                                                    }} 
                                                                    disabled={this.state.activeRecommendStep === this.props.contentData.explanation.length - 1}>
                                                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                                                </IconButton>
                                                            }
                                                            backButton={
                                                                <IconButton size="small" 
                                                                    onClick={() => { this.setState({activeRecommendStep : 
                                                                        (this.state.activeRecommendStep===undefined || this.state.activeRecommendStep >= this.props.contentData.explanation.length)
                                                                        ? 0
                                                                        : this.state.activeRecommendStep-1}) 
                                                                    }}
                                                                    disabled={this.state.activeRecommendStep === 0 || this.state.activeRecommendStep === undefined }>
                                                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                                                </IconButton>
                                                            }
                                                    />
                                                    </Card>
                                                : ''
                                        }
                                    <div
                                        style={{padding:0}}
                                        ref = {this.refRead}
                                    >
                                        <div style={{
                                            width:"100%", 
                                            marginTop: 20
                                        }}
                                        >
                                            <h3 style={{...theme.CardContentItem.TextTitle, textAlign: 'center', paddingBottom: 20}}>
                                                {this.props.contentData.category === "quotes" 
                                                    ?   <q>{this.props.contentData.title}</q>
                                                    :   this.props.contentData.title
                                                }
                                            </h3>
                                            <div style={{
                                                width: "100%",
                                                height: 
                                                    ((!this.props.jumpToRead && !this.state['contentExpanded'+this.props.contentId]) || this.state.nonSubscriber )
                                                        ? 370 : 'auto',
                                                overflowY: 
                                                    ((!this.props.jumpToRead && !this.state['contentExpanded'+this.props.contentId]) || this.state.nonSubscriber )
                                                        ? 'hidden' : 'auto',
                                                //filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                                //'-webkit-mask': this.state.nonSubscriber 
                                                WebkitMask: 
                                                    ((!this.props.jumpToRead && !this.state['contentExpanded'+this.props.contentId]) || this.state.nonSubscriber )
                                                        ?    '-webkit-gradient( linear, left 0%, left 350, from(#fefffe), to(rgba(0, 0, 0, 0)) )'
                                                        :   'none'
                                                }}>
                                                <div 
                                                    className={classes.innerHTML}
                                                    onClick = {(event) => {
                                                        try{
                                                            if(event!== undefined && event.target!==undefined){
                                                                const link = event.target.getAttribute('href');
                                                                if(link !== undefined && link !== null && link !== ""){
                                                                    event.preventDefault();
                                                                    window.open(link, "_blank")
                                                                }
                                                            }
                                                        }
                                                        catch(e){
                                                            event.preventDefault();
                                                            console.log('No Link', e)
                                                        }
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: this.props.contentData.text }} 
                                                />
                                                <div style={{height: 50}}>
                                                </div>
                                                {this.props.contentData.interactiveQuestions !== undefined && this.props.contentData.interactiveQuestions.length > 0
                                                    &&
                                                    <>
                                                        <h4>
                                                            Interactive Content
                                                        </h4>
                                                        <Divider />
                                                        <p></p>
                                                        {this.props.contentData.interactiveQuestions.map( (iQ, indexIQ) => (
                                                            <div key={"iqItem_"+indexIQ} style={{textAlign : 'center'}}>
                                                                {iQ.description !== undefined && iQ.description !== '' &&
                                                                    <h4><strong> {iQ.description}</strong></h4>
                                                                }
                                                                {iQ.question !== undefined &&
                                                                    <h5 style={theme.textSupportCenter}>{iQ.question}</h5>
                                                                }
                                                                {(iQ.question !== undefined || iQ.description!== undefined ) &&
                                                                    <>
                                                                        <TextField
                                                                            name={"iqItem_"+indexIQ}
                                                                            //label="Interactive Content"
                                                                            style={ {fontSize: '16px'} }
                                                                            type="text"
                                                                            variant="outlined"
                                                                            placeholder="Write down your thoughts ..."
                                                                            fullWidth
                                                                            multiline
                                                                            defaultValue={this.state['userEngagement' + this.props.contentId] !== undefined ? (this.state['userEngagement' + this.props.contentId]['interactiveQuestionsResponse_'+indexIQ] || '') : ''}
                                                                            value={this.state["iqItem_"+indexIQ]  }
                                                                            onChange={(e) => {this.setState({ ["iqItem_"+indexIQ] : e.target.value, ["iqUpload_"+indexIQ]: false})}}
                                                                            InputProps={{
                                                                                style:{fontSize: '16px'},
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        {this.state["iqItem_"+indexIQ] !== undefined && this.state["iqItem_"+indexIQ].length > 2
                                                                                            ?   this.state["iqUpload_"+indexIQ]
                                                                                                ?   <CheckCircleIcon style={{ color: theme.palette.themeGold }} />
                                                                                                :   <IconButton
                                                                                                        edge="end"
                                                                                                        aria-label="Send message"
                                                                                                        onClick={ () => this.saveInteractiveQuestionReponse(this.state["iqItem_"+indexIQ], indexIQ)}
                                                                                                        >
                                                                                                        <SendIcon />
                                                                                                    </IconButton>
                                                                                            :            
                                                                                                <IconButton
                                                                                                    edge="end"
                                                                                                    aria-label="Write response"
                                                                                                    disabled={true}
                                                                                                    >
                                                                                                    <SendIcon />
                                                                                                </IconButton>
                                                                                        }
                                                                                    </InputAdornment>
                                                                                ),
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <SpeechToTextButton 
                                                                                            renderKey={'contenth_'}
                                                                                            returnText={(result) => this.setState({["iqItem_"+indexIQ] : result})}
                                                                                            existingText={this.state["iqItem_"+indexIQ] || ""}
                                                                                        />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </>
                                                                }
                                                            </div>
                                                        ))}
                                                    </>
                                                }
                                            </div>
                                            { ((this.props.jumpToRead || this.state['contentExpanded'+this.props.contentId]) && !this.state.nonSubscriber )
                                                &&
                                                <div style={theme.divSpaceVertical}>
                                                    {this.props.contentData.source !== undefined && this.props.contentData.source !== '' &&
                                                        <>
                                                            <span style={theme.cardJournalDayHighlight.iconDesign}>
                                                                Inspired by
                                                                <br/>
                                                            </span>
                                                            <span>{this.props.contentData.source + " "}
                                                            &nbsp;
                                                                {this.props.contentData.sourceTitle !== undefined && this.props.contentData.sourceTitle !== '' &&
                                                                    <q>
                                                                        {this.props.contentData.sourceTitle + ""}
                                                                    </q>
                                                                }
                                                            </span>
                                                        </>
                                                    }
                                                    {((this.props.contentData.sourceUrl !== undefined && this.props.contentData.sourceUrl !== "" )
                                                        || (this.props.contentData.sourceUrl !== undefined && this.props.contentData.sourceUrl !== ""))
                                                        &&
                                                        <>
                                                            <span style={{color: theme.palette.secondary.main}}>
                                                                {this.props.contentData.sourceUrl !== undefined && this.props.contentData.sourceUrl !== ""
                                                                    ? 
                                                                        this.props.contentData.sourceUrl.includes('outube')
                                                                        ?
                                                                            // <a href={this.props.contentData.sourceUrl} target="_blank" rel="noopener noreferrer"><YouTubeIcon/></a> 
                                                                            <span style={{color: theme.palette.secondary.main}}
                                                                                onClick={ () => window.open(this.props.contentData.sourceUrl, "_blank")}>
                                                                                <YouTubeIcon/>
                                                                            </span>
                                                                        :
                                                                            // <a href={this.props.contentData.sourceUrl} target="_blank" rel="noopener noreferrer"><PublicIcon/></a>  
                                                                            <span style={{color: theme.palette.secondary.main}}
                                                                            onClick={ () => window.open(this.props.contentData.sourceUrl, "_blank")}>
                                                                                <PublicIcon/>
                                                                            </span>
                                                                    : ""
                                                                }
                                                                {this.props.contentData.amazonUrl !== undefined && this.props.contentData.amazonUrl !== ""
                                                                    &&
                                                                        <span style={{color: theme.palette.secondary.main}}
                                                                            onClick={ () => window.open(this.props.contentData.amazonUrl, "_blank")}>
                                                                                <Storefront/>
                                                                         </span>
                                                                }
                                                                <DialogFlagError
                                                                    title="Report issue with external links or the text"
                                                                    issue={"Issue with text/links - " + this.props.contentData.title}
                                                                    item={"Content text/links - " + this.props.user?.userName + " - " + this.props.user?.uid + " - " + this.props.contentId}
                                                                    dict={this.props.contentData}
                                                                />
                                                            </span>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {this.state.nonSubscriber 
                                        ?
                                            <BadgeCard
                                                key={'buy'}
                                                backgroundColor ={theme.palette.themeGold}
                                                color ={theme.palette.black}
                                                image={undefined} 
                                                avatarImage = {undefined}
                                                title = { 'Premium Content' }
                                                subtitle = { 'Please subscribe to access all articles!'}
                                                strongSubtitle = {false}
                                                onClick={this.openQualityShop}
                                                clickText= {undefined}
                                                button={'Show'}
                                            /> 
                                        :
                                            (this.props.jumpToRead || this.state['contentExpanded'+this.props.contentId])
                                            ?   ''
                                            :
                                                <div> 
                                                    {this.props.contentData?.textWordCount > 90 &&
                                                        <div 
                                                            style={theme.textSupportCenter}
                                                            onClick={() =>  {this.expandHandler(this.props.contentId)}}
                                                        >
                                                            { ""+ Math.round(this.props.contentData.textWordCount/100*0.6) + " min read" } 
                                                        </div>
                                                    }
                                                    <strong>
                                                    ...
                                                    </strong> 
                                                    <br />
                                                    <div style={{textAlign: 'right'}}
                                                        onClick={() =>  {this.expandHandler(this.props.contentId)}}
                                                        >
                                                        <Button variant="contained" style={{backgroundColor: theme.palette.themeRed}} endIcon={<SchoolIcon/>}>
                                                            Read more
                                                        </Button>
                                                    </div>
                                                </div>
                                    }
                                    </div>
                                }
                                {this.props.contentData.resources !== undefined && this.props.contentData.resources.length > 0
                                    &&
                                    <div>
                                        <div style={{ paddingTop: 20 }}>
                                            <h4>
                                                More
                                            </h4>
                                            <Divider />
                                            <Grid container spacing={2} alignItems="center" direction='row' style={{marginTop: 20}}>
                                                {this.props.contentData.resources.map((resources, indexR) => (
                                                    <React.Fragment key={indexR}
                                                    >
                                                        {resources.type === 'link'
                                                        && 
                                                        <>
                                                            <Grid item xs={3}>
                                                                <Button 
                                                                    onClick={() => { window.open(resources.url, "_blank")  }}
                                                                    > 
                                                                    <PublicIcon />
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={9} key={'action'+indexR}> 
                                                                <span 
                                                                    onClick={ () => window.open(resources.url, "_blank")}
                                                                    >
                                                                    {resources.text} 
                                                                </span>
                                                            </Grid>
                                                        </>
                                                        }
                                                    </React.Fragment>
                                                    ))
                                                }
                                            </Grid>
                                        </div>
                                    </div>
                                }
                                
                                <div>
                                    {this.props.contentData?.validated && this.props.contentData?.actions?.length > 0
                                    &&
                                        <>
                                            <div style={{ paddingTop: 20 }} ref={this.refCommitments}>
                                                <h4>
                                                    Commitments
                                                    <span style={{float: 'right'}} > 
                                                        {this.state['userEngagement' + this.props.contentId] !== undefined 
                                                        && this.state['userEngagement' + this.props.contentId].actions !== undefined  
                                                        && this.state['userEngagement' + this.props.contentId].actions.length >= 1
                                                            ?   <CheckCircleIcon style={{ color: theme.palette.themeGold }} />
                                                            :   '' //<CheckCircleIcon  color="disabled" />
                                                        }
                                                    </span>
                                                </h4>
                                                <Divider />
                                            </div>
                                            <Grid container spacing={2} alignItems="center" direction='row' 
                                                style={{
                                                    marginTop: 20,
                                                    height: this.state.nonSubscriber ? 60 : 'auto',
                                                    overflowY: this.state.nonSubscriber ? 'hidden' : 'auto',
                                                    //filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                                    WebkitMask: this.state.nonSubscriber 
                                                    //'-webkit-mask': this.state.nonSubscriber 
                                                        ?   '-webkit-gradient( linear, left 0%, left 140, from(#fefffe), to(rgba(0, 0, 0, 0)) )'
                                                        :   'none'
                                                }}
                                                >
                                                {this.props.contentData.actions !== undefined && this.props.contentData.actions.length>0 
                                                && this.props.contentData.actions.map((action, indexaction) => (
                                                    (this.props.contentData.validated || indexaction === 0)
                                                    ?
                                                        <React.Fragment key={indexaction}>
                                                            {this.state['userEngagement' + this.props.contentId] === undefined 
                                                            || 
                                                            (this.state['userEngagement' + this.props.contentId] !== undefined && this.state['userEngagement' + this.props.contentId].actions === undefined )
                                                            ||
                                                            (this.state['userEngagement' + this.props.contentId].actions !== undefined 
                                                                && !(this.state['userEngagement' + this.props.contentId].actions.includes(action)))
                                                                ?
                                                                <Grid item xs={3} onClick={() => {this.addAction(action)}}> 
                                                                    <Button> 
                                                                        <AddCircleIcon />
                                                                    </Button>
                                                                </Grid>
                                                                : 
                                                                <Grid item xs={3}>
                                                                    <Button variant="contained"  onClick={() => {this.addExistingAction(action)}}> 
                                                                        <AssignmentTurnedInIcon />
                                                                    </Button>
                                                                </Grid>
                                                            }
                                                            <Grid item xs={9} key={'action'+indexaction}> {action} </Grid>
                                                        </React.Fragment>
                                                    :   ''
                                                ))}
                                                <>   
                                                    {this.state.addExtraAction
                                                        ?
                                                            <Grid item xs={3} onClick={() => {
                                                                    this.addAction(this.state.extraActionText);
                                                                    this.setState({addExtraAction:false})
                                                                    }}> 
                                                                <Button> 
                                                                    Save
                                                                </Button>
                                                            </Grid>
                                                        :   this.state.extraActionText!== undefined && this.state.extraActionText.length>3
                                                            ? 
                                                                <Grid item xs={3} onClick={() => {this.setState({addExtraAction:true})}}>
                                                                    <Button variant="contained" > 
                                                                        <AssignmentTurnedInIcon />
                                                                    </Button>
                                                                </Grid>
                                                            :  
                                                                <Grid item xs={3} onClick={() => {this.setState({addExtraAction:true})}}>
                                                                    <Button> 
                                                                        <AddCircleIcon />
                                                                    </Button>
                                                                </Grid>
                                                    }
                                                    {this.state.addExtraAction
                                                        ?
                                                            <Grid item xs={9}> 
                                                                <TextField
                                                                    name="extraction"
                                                                    //label="My action"
                                                                    style={ {fontSize: '16px'} }
                                                                    type="text"
                                                                    variant="outlined"
                                                                    placeholder="My follow up activity"
                                                                    fullWidth
                                                                    multiline
                                                                    InputProps={{
                                                                        style:{fontSize: '16px'}
                                                                    }}
                                                                    value={this.state.extraActionText}
                                                                    onChange={(e) => {this.setState({ extraActionText : e.target.value})}}
                                                                />
                                                            </Grid>
                                                        : 
                                                            this.state.extraActionText!== undefined && this.state.extraActionText.length>3
                                                            ?  
                                                                <Grid item xs={9} key={'actionextra'}> {this.state.extraActionText} </Grid>
                                                            :  
                                                                <Grid item xs={9} key={'actionextra'}> 
                                                                    <span style={{color: theme.palette.secondary.main}}
                                                                        onClick={() => {this.setState({addExtraAction:true})}}
                                                                    >
                                                                        Add another commitment...
                                                                    </span>
                                                                </Grid>
                                                    }
                                                </>
                                            </Grid>
                                            {this.state.nonSubscriber 
                                                &&
                                                <BadgeCard
                                                    key={'buyaction'}
                                                    backgroundColor ={theme.palette.themeGold}
                                                    color ={theme.palette.black}
                                                    image={undefined} 
                                                    avatarImage = {undefined}
                                                    title = { 'Premium Content' }
                                                    subtitle = { 'Check out our Premium Programs'}
                                                    strongSubtitle = {false}
                                                    onClick={this.openQualityShop}
                                                    clickText= {undefined}
                                                    button={'Check'}
                                                /> 
                                            }
                                        </>
                                    }
                                    <p style={theme.textErrorCenterPadding}>
                                        {this.state.openAlertMessage} &nbsp;
                                    </p>
                                    <JournalActivities 
                                        leadershipCategories={this.props.leadershipCategories}
                                        leadershipQualities={this.props.leadershipQualities} 
                                        specialQuery={'contentByIds'}
                                        style={{padding: 0}}
                                        contentIds = {[this.props.contentId]}
                                        user={this.props.user} 
                                        preview={true}
                                        checkMode={true}
                                        groupItems={true}
                                        showTips={false}
                                    />
                                </div>
                            </div>
                            :
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} 
                                    loadingText={"loading article"} 
                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                    allowRefresh={true}
                                />
                        }
                        <div>
                            {!(this.props.contentData?.isNonCommunity === true) &&
                            <>
                                <div style={theme.divCenterPadding}>
                                    <span style={theme.CardContentItem.TextTitle}> . . .</span>
                                </div>
                                <div style={{ paddingTop: 20 }} ref={this.refComment}>
                                    <h4>Community
                                        {this.state['userEngagement' + this.props.contentId] !== undefined &&
                                            <span style={{float: 'right'}} > 
                                                {this.state['userEngagement' + this.props.contentId] !== undefined 
                                                && this.state['userEngagement' + this.props.contentId].comment !== undefined  
                                                && this.state['userEngagement' + this.props.contentId].comment.length >= 3
                                                    ? this.state['userEngagement' + this.props.contentId].comment.length >= 20
                                                        ? <CheckCircleIcon style={{ color: theme.palette.themeGold }} />
                                                        : <StarHalfIcon style={{ color: theme.palette.themeGold }} />
                                                    :   '' //<CheckCircleIcon  color="disabled" />
                                                }
                                            </span>
                                        }
                                    </h4>
                                    <Divider />
                                </div>
                                {this.state.loadingEngagements
                                    &&
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"searching comments..."} />
                                }
                                {this.state['userEngagement' + this.props.contentId] !== undefined &&
                                    <ContentComments
                                        sorting={'commentVotes'}
                                        contentData={this.props.contentData}
                                        contentId={this.props.contentId}
                                        userId={this.props.user.uid}
                                        userEngagement={this.state['userEngagement' + this.props.contentId]}
                                        highlights={true}
                                        messageSubmit={ 
                                            this.props.messageSubmit !== undefined
                                                ?
                                                    (comment) => this.props.messageSubmit(comment)
                                                :
                                                    undefined
                                        }
                                        typingCommentFieldOpen={(value) => {
                                            if(value){
                                                this.setState({typingCommentFieldOpen: true})
                                            }
                                            else{
                                                this.setState({typingCommentFieldOpen: false})
                                            }
                                        } }
                                    />
                                }
                            </>
                            }
                            {this.props.contentData!== undefined && this.props.contentData.validated && this.props.contentData.explanation !== undefined && this.props.contentData.explanation.length > 0 
                                &&
                                    <div>
                                        <div style={{ paddingTop: 20 }}></div>
                                        <h4>Ideas</h4>
                                        <Divider />
                                        <List 
                                            style={{
                                                height: this.state.nonSubscriber ? 120 : 'auto',
                                                overflowY: this.state.nonSubscriber ? 'hidden' : 'auto',
                                                //filter: this.state.nonSubscriber? 'blur(4px)': 'none'
                                                WebkitMask: this.state.nonSubscriber 
                                                //'-webkit-mask': this.state.nonSubscriber 
                                                    ?   '-webkit-gradient( linear, left 0%, left 140, from(#fefffe), to(rgba(0, 0, 0, 0)) )'
                                                    :   'none'
                                            }}>
                                            { this.props.contentData.explanation.map((content, index) => {
                                                return(
                                                    <ListItem key={'expl_'+index} 
                                                        style={
                                                            this.state['hover'+index] === true
                                                            ?   {paddingLeft:0, paddingRight:0, backgroundColor: theme.palette.themeGold, borderRadius: theme.borderRadius}
                                                            :   {paddingLeft:0, paddingRight:0, backgroundColor: 'transparent' }
                                                        }
                                                        onClick={
                                                            this.props.messageSubmit !== undefined
                                                                ?   () => {
                                                                        this.props.messageSubmit(content);
                                                                        this.props.handleClose();
                                                                    }
                                                                :   void(0)    
                                                            }
                                                        onMouseEnter={(this.props.messageSubmit !== undefined) ? () => {this.setState({['hover'+index]: true})} : void(0)}
                                                        onMouseLeave={(this.props.messageSubmit !== undefined) ? () => {this.setState({['hover'+index]: false})} : void(0)}
                                                        >
                                                        {this.props.messageSubmit === undefined
                                                            &&
                                                            <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:"green"}}>
                                                                <AdjustIcon /> 
                                                            </ListItemIcon>
                                                        }
                                                        <ListItemText disableTypography style={{color: theme.palette.themeText, paddingLeft:8, paddingRight: 8}}>
                                                            {content}
                                                        </ListItemText>
                                                        {this.props.messageSubmit !== undefined
                                                            &&
                                                            <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:"green"}}>
                                                                <SendIcon fontSize='small' style={{color: theme.palette.themeRed}}  />
                                                            </ListItemIcon>
                                                        }
                                                    </ListItem>
                                                )                    
                                            })}
                                        </List>
                                        {this.state.nonSubscriber 
                                            &&
                                            <BadgeCard
                                                key={'buy3'}
                                                backgroundColor ={theme.palette.themeGold}
                                                color ={theme.palette.black}
                                                image={undefined} 
                                                avatarImage = {undefined}
                                                title = { 'Premium Content' }
                                                subtitle = { 'Check out our Premium Programs'}
                                                strongSubtitle = {false}
                                                onClick={this.openQualityShop}
                                                clickText= {undefined}
                                                button={'Check'}
                                            /> 
                                        }
                                    </div>
                            }
                            {false && this.props.contentId 
                                && !this.state.addExtraAction 
                                && !this.state.typingCommentFieldOpen 
                                ?
                                    <BookMarkVote
                                        buttonStyle='fabs'
                                        contentId={this.props.contentId}
                                        userEngagement = {this.state['userEngagement' + this.props.contentId]}
                                        userId={this.props.user.uid}
                                        contentData={this.props.contentData}
                                        handleClose={this.props.handleClose}
                                        unbookMarkOption={this.props.unbookMarkOption || false}
                                        removeItemFromList={ 
                                            this.props.removeItemFromList !== undefined
                                                ? (removeContentId) => this.props.removeItemFromList(removeContentId)
                                                : undefined
                                        }
                                        //hideCommentButton={this.state.hideCommentButton || false}
                                        hideCommentButton={false}
                                        handleOpenComment={() => this.scrollToComments()}
                                    />
                                :
                                    <div> </div>
                            }
                        </div>
                        <div style={{ paddingBottom: 100 }}></div>
                    </DialogContent>
                    <DialogActions>
                        <BookMarkVote
                            buttonStyle='DialogActions'
                            contentId={this.props.contentId}
                            userEngagement = {this.state['userEngagement' + this.props.contentId]}
                            userId={this.props.user.uid}
                            contentData={this.props.contentData}
                            handleClose={this.props.handleClose}
                            unbookMarkOption={this.props.unbookMarkOption || false}
                            removeItemFromList={ 
                                this.props.removeItemFromList !== undefined
                                    ? (removeContentId) => this.props.removeItemFromList(removeContentId)
                                    : undefined
                            }
                            //hideCommentButton={this.state.hideCommentButton || false}
                            hideCommentButton={false}
                            handleOpenComment={() => this.scrollToComments()}
                            handleOpenCommitments={() => this.scrollToCommitments()}
                        />
                    </DialogActions>
                </Dialog>

                <DialogSettingPlan 
                    defaultSet={this.props.defaultSet || undefined}
                    user={this.context}
                    handleOpen={this.state.DialogSettingPlanOpen}
                    premiumOnly={true}
                    purchaseSuccessFlag={() => {
                        this.setState({nonSubscriber: false, premiumSubscriber: true, skipSubscriptionCheck: true})
                    }}
                    purchaseSuccessHandler={() => {
                        this.setState({DialogSettingPlanOpen: false, nonSubscriber: false, premiumSubscriber: true, skipSubscriptionCheck: true})
                    }}
                    handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                />
            </RemoveScroll>

            {this.state.loadPicker &&
                <LeadershipQualityPicker
                    defaultSet={this.props.defaultSet || undefined}
                    scope={'current'}
                    user={this.props.user}
                    chosenCategory={this.state.chosenCategory || undefined }
                    preselectQuality={this.state.preselectQuality || undefined}
                    openChooseTopic1={this.state.openLeadershipQualityPicker || false}
                    openChooseTopic2={this.state.openLeadershipQualityPicker2 || false}
                    leadershipCategories={this.props.leadershipCategories}
                    leadershipQualities={this.props.leadershipQualities}
                    handlerCloseIgnoreProgramsFlag={true}
                    handler={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
                    handlerOpen2={(entry) =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: true })}}
                    handlerClose2={() =>  {this.setState({  openLeadershipQualityPicker: true,  openLeadershipQualityPicker2: false })}}
                    handlerCloseAll={() => {this.setState({  openLeadershipQualityPicker: false,  openLeadershipQualityPicker2: false })}}
                    purchaseSuccessHandler={() => this.setState({nonSubscriber: false, premiumSubscriber: true})}
                    closeDialog={() => {}}
                    />
            }
            </>
        )// end return
    }//end render



    getBookMarks = (contentId) => {
        if ( contentId!== undefined && contentId!== "") {
            this.unsubscribeBookmark = this.props.firebase.contentBookmark(contentId, this.props.user.uid)
            //this.props.firebase.contentBookmark(contentId, this.props.user.uid)
                //.get().then(docEngagement => {
                .onSnapshot(docEngagement => {
                    if(this._isMounted){
                        //console.log('loaded engagement:', docEngagement.data());
                        if (docEngagement.exists) {
                            var userEngagement = docEngagement.data();
                            var contentEngagementRating = 1;
                            if(userEngagement.read) {contentEngagementRating +=1}
                            if(userEngagement.comment !== undefined && userEngagement.comment.length > 20  ) {contentEngagementRating +=1}
                            else if (userEngagement.comment !== undefined && userEngagement.comment.length > 2  ) {contentEngagementRating +=0.5}
                            if(userEngagement.actions !== undefined && userEngagement.actions.length >= 1 ) {contentEngagementRating +=1}
                            if(userEngagement.vote !== undefined && userEngagement.vote !== '' ) {contentEngagementRating +=1}
                            //this.setState({ tips: shuffle(tips) });
                            if(this._isMounted){
                                this.setState({
                                    ['userEngagement' + contentId]: userEngagement,
                                    contentEngagementRating,
                                    loadingEngagements: false
                                });
                            }
                        }//end if exists
                        else if(this._isMounted) {
                            this.setState({
                                ['userEngagement' + contentId]: [],
                                loadingEngagements: false
                            });
                        }//end else
                        if( this.state['userEngagement' + contentId] === undefined
                            || ( this.state['userEngagement' + contentId] !== undefined && !( this.state['userEngagement' + contentId].opened) ) ){
                            var engagement = {
                                userId: this.props.user.uid,
                                points: 1,
                                type:   'content',
                                message: 'Opened: ' + this.props.contentData.title,
                                origin: 'resources',
                                }
                            if(this.props.contentData.leadership_category !== undefined){
                                engagement['leadership_category'] = this.props.contentData.leadership_category;
                            }
                            if(this.props.contentData.leadership_quality !== undefined){
                                engagement['leadership_quality'] = this.props.contentData.leadership_quality;
                            }
                            AddEngagementPoints(this.props.firebase, engagement);
                            this.props.firebase.contentBookmark(contentId, this.props.user.uid).set({
                                createdAt: this.props.firebase.fieldValue.serverTimestamp(), 
                                origin: 'resources',
                                opened: true,
                                contentId: this.props.contentId,
                                userId: this.props.user.uid
                            }, {merge:true }); 
                        }//end addengagement
                    }//end if ismounted
                });
        }
    }

    openedFirstHandler = (contentId) => {
        if(!(this.state['contentOpened'+contentId]) ){
            if(this.props.user?.currentDialogue?.length > 2){
                this.props.firebase.dialogue(this.props.user.currentDialogue).update({
                    contentOpened : this.props.firebase.fieldValue.arrayUnion(contentId)
                })
            }
        }
        this.setState({
            ['contentOpened'+contentId] : true
        });
    }

    expandHandler = (contentId) => {
        if( !(this.state['contentExpanded'+contentId]) 
            // && (this.state['userEngagement'+contentId] !== undefined && !(this.state['userEngagement'+contentId].read) ) 
        ){
            var engagement = {
                userId: this.props.user.uid,
                points: 1,
                type:   'content',
                message: 'Read: ' + this.props.contentData.title,
                origin: 'resources',
                }
            if(this.props.contentData.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.contentData.leadership_category;
            }
            if(this.props.contentData.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.contentData.leadership_quality;
            }
            ExtendDialogueMessage(this.props.firebase, this.props.user, 'I want to learn more about ' + this.props.contentData.title, "What are your thoughts on: "+this.props.contentData.title, 'utter_ask_awareness', '', 'content');
            AddEngagementPoints(this.props.firebase, engagement);
            this.props.firebase.contentBookmark(contentId, this.props.user.uid).set({
                createdAt: this.props.firebase.fieldValue.serverTimestamp(), 
                origin: 'resources',
                read: true,
                contentId: contentId,
                userId: this.props.user.uid
            }, {merge:true });
            if(this.props.user?.currentDialogue?.length > 2){
                this.props.firebase.dialogue(this.props.user.currentDialogue).update({
                    contentRead : this.props.firebase.fieldValue.arrayUnion( {id: contentId, title: this.props.contentData.title})
                })
                //console.log('diloague', this.props.user.currentDialogue)
            }
        }
        this.setState({
            ['contentExpanded'+contentId] : true,
        });
    }

    addAction = (action) => {
        if (action !== undefined && action.length > 15) {
            var messageToAdd = 'I want to apply ' + this.props.contentData.title;
            ExtendDialogueMessage(this.props.firebase, this.props.user, messageToAdd + '. ' + action, this.props.contentData.title, 'utter_ask_resources_goal', this.props.contentId, 'activities');

            var engagement = {
                userId: this.props.user.uid,
                points:2,
                type:'content',
                message: messageToAdd,
                intention: action,
                origin:'resources',
                contentId: this.props.contentId
                }
            if(this.props.contentData.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.contentData.leadership_category;
            }
            if(this.props.contentData.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.contentData.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);

            //GetExternalRecommendation(action, this.props.contentData.title ,'Productivity')
            
            //console.log('contentid', this.props.contentId)
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.contentBookmark( this.props.contentId, this.props.user.uid).set({
                origin: 'resources',
                createdAt: timestamp,
                actions: this.props.firebase.fieldValue.arrayUnion( action ),
                bookmark: true,
                contentId: this.props.contentId,
                userId: this.props.user.uid,
            }, {merge:true }); 
            this.props.firebase.content( this.props.contentId).set({
                contentVotes: this.props.firebase.fieldValue.increment(2)
            }, {merge:true }); 
            this.props.firebase.user(this.props.user.uid).update({
                ctaText : action,
                contentBookmarked : this.props.firebase.fieldValue.arrayUnion( this.props.contentId)
            });
            this.setState({openAlertMessage: "Added Action to Goals"})
            engagement['question'] = this.props.contentData.subtitle || 'What is your follow up?';
            engagement['important'] = true;
            engagement['questionTitle'] = this.props.contentData.title; 
            //engagement['intentionApproved'] = true;
            this.props.firebase.journeyFollowUps().add(engagement);
            if(this.props.messageSubmit !== undefined){
                this.props.messageSubmit(action);
                //console.log('submit', this.props)
                if(this.props.handleClose !== undefined){
                    //console.log('closed')
                    this.props.handleClose()
                }
            }
            else{
                ExtendDialogueMessage(this.props.firebase, this.props.user, action, 'What do you think about: '+ this.props.contentData.title, 'utter_ask_activity', '', 'content');
            }
        }
        else{
            this.setState({openAlertMessage:"Description is too short", extraActionText:''})
        }
    }
    
    addExistingAction = (action) => {
        if (action !== undefined && action.length > 15) {

            var messageToAdd = 'I want to improve ' + this.props.contentData.title;
            ExtendDialogueMessage(this.props.firebase, this.props.user, messageToAdd + '. ' + action, this.props.contentData.title, 'utter_ask_resources_goal', this.props.contentId, 'activities');

            var engagement = {
                userId: this.props.user.uid,
                points:2,
                type:'content',
                message: messageToAdd,
                intention: action,
                origin:'resources',
                contentId: this.props.contentId
                }
            if(this.props.contentData.leadership_category !== undefined){
                engagement['leadership_category'] = this.props.contentData.leadership_category;
            }
            if(this.props.contentData.leadership_quality !== undefined){
                engagement['leadership_quality'] = this.props.contentData.leadership_quality;
            }
            AddEngagementPoints(this.props.firebase, engagement);

            //GetExternalRecommendation(action, this.props.contentData.title ,'Productivity')
            
            //console.log('contentid', this.props.contentId)
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.contentBookmark( this.props.contentId, this.props.user.uid).set({
                origin: 'resources',
                createdAt: timestamp,
                actions: this.props.firebase.fieldValue.arrayUnion( action ),
                bookmark: true,
                contentId: this.props.contentId,
                userId: this.props.user.uid,
            }, {merge:true }); 
            this.props.firebase.content( this.props.contentId).set({
                contentVotes: this.props.firebase.fieldValue.increment(2)
            }, {merge:true }); 
            this.props.firebase.user(this.props.user.uid).update({
                ctaText : action,
                contentBookmarked : this.props.firebase.fieldValue.arrayUnion( this.props.contentId)
            });
            this.setState({openAlertMessage:"Updated action in Goals"})
            
            engagement['question'] = this.props.contentData.subtitle || 'What is your follow up?';
            engagement['important'] = true;
            engagement['questionTitle'] = this.props.contentData.title;
            engagement['intentionApproved'] = true;

            var followUpId = "";
            this.props.firebase.journeyFollowUps()
            .where('userId', '==', this.props.user.uid)
            .where('intention', '==', action)
            .limit(1)
            .get()
            .then(snapshot => {
                if (snapshot.size) {
                    snapshot.forEach(doc => {
                        //console.log('date found', doc);
                        followUpId = doc.id;
                        var dateOriginal = doc.data().createdAt;
                        if(doc.data().createdAtInit !== undefined ){
                            dateOriginal = doc.data().createdAtInit;
                        }
                        this.props.firebase.journeyFollowUpAction( followUpId).update({
                            createdAt: timestamp,
                            createdAtInit: dateOriginal,
                            important: true,
                            hide: false,    
                            intentionApproved: true,
                            failed: false,    
                            activityArchive: this.props.firebase.fieldValue.arrayUnion( {date: this.getDateStringFormat(), message: messageToAdd})
                        }); 
                    })
                    //console.log('days data',this.state.days);
                } else {
                    followUpId = '';
                    this.props.firebase.journeyFollowUps().add(engagement);
                }
            })
            if(this.props.messageSubmit!== undefined){
                this.props.messageSubmit(action);
                if(this.props.handleClose !== undefined){
                    this.props.handleClose()
                }
            }
            else{
                ExtendDialogueMessage(this.props.firebase, this.props.user, action, 'What do you think about: '+ this.props.contentData.title, 'utter_ask_activity', '', 'content');
            }
        }
        else{
            this.setState({openAlertMessage:"Description is too short", extraActionText:''})
        }
    }

    saveInteractiveQuestionReponse = (comment, iq_position) => {
        comment = comment.trim();
        if(comment !== ''){
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            this.props.firebase.contentBookmark( this.props.contentId, this.props.user.uid).set({
                origin: 'resources',
                createdAt: timestamp,
                contentId: this.props.contentId,
                userId: this.props.user.uid,
                ["interactiveQuestionsResponse_"+iq_position]: comment
            }, {merge:true }); 
            this.setState({["iqUpload_"+iq_position]: true})
        }
    }
    
    getDateStringFormat(){
        var selectDate = new Date()
        const dayOfWeek = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
        var dateString = 
            selectDate.getFullYear() +"-"+
            ("0" +  (selectDate.getMonth()+1)).slice(-2) +"-"+ 
            ("0" + selectDate.getDate()).slice(-2) + " " + 
            dayOfWeek[selectDate.getDay()] + " " + 
            ("0" + selectDate.getHours()).slice(-2) + ":" + 
            ("0" + selectDate.getMinutes()).slice(-2) + ":" + 
            ("0" + selectDate.getSeconds()).slice(-2);
        return dateString
    }

};//end class


export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(DialogContentItem);
