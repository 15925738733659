import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
// needed if signup and empty user data
import { StoreMobileDeviceToken } from '../Modules/StoreMobileDeviceInfo';

import {  withAuthorization } from '../Session';
import * as ROUTES from "../../constants/routes";
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { AddAnalytics } from '../Modules/AddAnalytics';
import {CheckSubscriptionStateOwned} from '../Modules/CheckSubscriptionStatus';
import DrawerGoalOverview from '../Goals/DrawerGoalOverview';
import PropTypes from 'prop-types';

import InfiniteContent from '../Content/InfiniteContent';
import DialogContentItem from '../Content/DialogContentItem';
import {GetTimeZone} from '../Modules/LoadUserTimezone';
import DialogQuickTopicSetting from '../Goals/DialogQuickTopicSetting';
import DialogTutorialPage from '../Onboarding/DialogTutorialPage';
import ShowGoalSuggestion from '../Routine/ShowGoalSuggestion';
import {ExtendDialogueMessage} from '../Routine/DiaryManual';
import {AvatarCard} from '../Modules/AvatarImage';
import Task4Chats from '../Home/Task4Chats';
import TaskNext3D from '../Home/TaskNext3D';
import {ContentProgramOverwrite} from '../Content/ContentItemCard';

import {freeTopics, CheckSubscriptionStatus} from '../Modules/CheckSubscriptionStatus';

import Card from '@material-ui/core/Card';
import imageSettingGoal from "../../images/settinggoal.png";
import BadgeCard from '../Modules/BadgeCard';
//chat features
import * as chatAgent from '../../chatAgent';
import ChatBlock from '../Routine/ChatBlock';
import ContentSuggestCarousel from '../Content/ContentSuggestCarousel';
import _, { stubTrue } from "lodash";
import DialogueHistory from '../Dialogues/DialogueHistory';

import VizSensor from 'react-visibility-sensor';
import SnackbarInfo from '../Modules/SnackbarInfo';
import IconSentence from '../Routine/IconSentence';
import CardTopicSquare from '../Goals/CardTopicSquare';

import { FindTextValueInDict, FindDictByKey, QualityID } from '../Modules/LoadCategoriesAndQualities';
import GridList from '@material-ui/core/GridList';
//import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import LoadingProgress from '../Modules/LoadingProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//// DialogBase
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LinearProgress from '@material-ui/core/LinearProgress';
import badgeCongrats from "../../images/congrats.png";
import TopicSuggestions from '../Goals/TopicSuggestions';

//import Rating from '@material-ui/lab/Rating';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
//import MicIcon from '@material-ui/icons/Mic';
//import MicOffIcon from '@material-ui/icons/MicOff';
//import HearingIcon from '@material-ui/icons/Hearing';
//import CachedIcon from '@material-ui/icons/Cached';
//import BathtubIcon from '@material-ui/icons/Bathtub';
//import Zoom from '@material-ui/core/Zoom';

import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import FastForwardIcon from '@material-ui/icons/FastForward';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CardBadges from '../Charts/CardBadges';
import WidgetsIcon from '@material-ui/icons/Widgets';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import WrapTextIcon from '@material-ui/icons/WrapText';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import TuneIcon from '@material-ui/icons/Tune';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
/// For the scroll down action
//import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import StarsIcon from '@material-ui/icons/Stars';
// for chat anchoring
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FirstPageIcon from '@material-ui/icons/FirstPage';
//import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
//import logo from "../../images/Rocky_round_yellow.png";
//import logo from "../../images/rocky_icon_gold.png";
import logoCaption from "../../images/rocky-logo.png";
//import PageviewIcon from '@material-ui/icons/Pageview';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
//import AssistantIcon from '@material-ui/icons/Assistant';
//import CancelIcon from '@material-ui/icons/Cancel';
import FlightIcon from '@material-ui/icons/Flight';
//import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

// Draggable Switch
import Switch from "react-switch";
///// SPEED DIAL OPTIONS
import SpeedDial from '@material-ui/lab/SpeedDial';
//import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
//import HelpIcon from '@material-ui/icons/Help';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Backdrop from '@material-ui/core/Backdrop';
import RockyIcon from '../Modules/RockyIcon';
import ForumIcon from '@material-ui/icons/Forum';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import {IsMobileDevice} from '../Modules/IsMobileDevice';
import {SetStateLoadCategoriesAndQualities} from '../Modules/LoadCategoriesAndQualities';
//import {SetStateLoadUserData} from '../Modules/LoadUserData';
import AppRating from '../Modules/AppRating';
import ShareSocialFeedback from '../Modules/ShareSocialFeedback';
//import DiaryManual from './DiaryManual';
import DialogSettingPlan from '../Profile/DialogSettingPlan';
import MindfulnessScore from '../Charts/MindfulnessScore';
//import MindfulnessScore, {IdeasSection} from '../Charts/MindfulnessScore';
// for content
import HomeCardContentItems from '../Content/HomeCardContentItems';
import JournalActivities from '../Days/JournalActivities';
import {SetAgentDialogue} from '../Routine/SetAgentStatus';
//import {SetAgentStatus, SetAgentDialogue} from '../Routine/SetAgentStatus';
import Slide from '@material-ui/core/Slide';
/// smileys instead of slider
import SentimentVeryDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentVeryDissatisfiedTwoTone';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentDissatisfiedTwoTone';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedTwoToneIcon from '@material-ui/icons/SentimentSatisfiedTwoTone';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import EmojiEmotionsTwoToneIcon from '@material-ui/icons/EmojiEmotionsTwoTone';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVerySatisfiedTwoToneIcon from '@material-ui/icons/SentimentVerySatisfiedTwoTone';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import logoAnimated from "../../images/rocky-logo-animated.gif";
//import backgroundImageLoading from "../../images/login-background.jpg";
import logoWhatsNext from "../../images/Badge_XP_whatsnext.png";

import StatsBar from '../Charts/StatsBar';
import SpeechToTextButton from '../Modules/SpeechToTextButton';

import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import DialogueStages from '../Routine/DialogueStages'; 
import Task4Topics from '../Home/Task4Topics';
import { DialogActions } from '@material-ui/core';
//import { LocationCityTwoTone } from '@material-ui/icons';
//import { Favorite } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
}); 

const styles = theme => ({
    root: {
        //backgroundColor: theme.palette.primary.dark,
        //backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
        //flexGrow: 1,
        color:  theme.palette.themeText,
        minHeight: '100vh',
        height: '100vh',
        backgroundColor: theme.palette.backPrimary,
        //backgroundImage: `url(${backgroundImageSignup})`,
        //backgroundPosition: "center",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",,
        overflowY: 'scroll'
    },
    rootApp: {
        color:  theme.palette.themeText,
        backgroundColor: theme.palette.backPrimary,
        minHeight: '100vh',
        height: '100vh',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        webkitUserSelect: 'none',
        userSelect: 'none',
        overflowY: 'scroll'
    },
    background: {
        background: IsMobileDevice()
            ?   theme.palette.backPrimary
            :   theme.palette.backPrimary // 'linear-gradient(34deg, rgba(0,107,190,1) 0%, rgba(0,50,117,1) 100%)'
        ,
        //height: '100vH',
        minHeight: "100vh",
        //opacity: '90%',
        //color: theme.palette.white
    },
    backgroundLoading: {
        //backgroundImage: `url(${backgroundImageLoading})`,
        //backgroundPosition: "center",
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        backgroundColor: theme.palette.themeAltLoading, 
        height: '100%',
    },
    button: {
        float: 'right',
        marginTop: 20
    },
    buttonLeft: {
        float: 'left',
        marginTop: 20
    },
    chat: {
        backgroundColor: theme.palette.backPaper,
        paddingLeft: 20,
        paddingRight: 20,
        //paddingTop: 'calc( 80px + env(safe-area-inset-top))',
        paddingTop: 'calc( 40px + env(safe-area-inset-top))',
        //paddingBottom: 130,
        paddingBottom: 'calc( 5px + env(safe-area-inset-bottom))',
        //flexGrow: 1,
        //flexDirection: "column",
        //minHeight: '100vh',
        //minHeight: 'calc(100vH - 0px)',
        minHeight: 'calc(100% - 45px)',
        overflow: 'hidden',
        maxWidth: '100vw'
        //height: '90vh',
        //minheight: 'calc( 100vh - 150px)',
        //height: '100vh',
    },
    containerMeasurement: {
        textAlign: 'center',
        //paddingTop: 50,
        paddingTop: 20,
        cursor: 'pointer',
    },
    dialogueId: {
        fontSize: 8,
    },
    explanation: {
        marginTop: 30,
        color: theme.palette.secondary.light,
        //fontFamily: '"Nunito Sans", sans-serif',
        cursor: 'pointer',
    },
    gridPadding: {
        //backgroundColor: 'transparent',
        marginTop: 40,
        //opacity: '0.90',
        //minHeight: '5vh',
        paddingTop: 'calc(0px + env(safe-area-inset-bottom))',
        //height: '20vh',
        //maxWidth: 500,
    },
    gridPaddingButtonHeight: {
        //backgroundColor: 'transparent',
        opacity: '0.90',
        //minHeight: '5vh',
        paddingTop: 'calc(0px + env(safe-area-inset-bottom))',
        height: '20px',
        //maxWidth: 500,
    },
    grid: {
        //backgroundColor: theme.palette.primary.main,
        //opacity: '0.90',
        borderRadius: 3,
        marginTop: 0,
        paddingTop: 0,
        maxWidth: 900,
    },
    gridMobile: {
        //backgroundColor: theme.palette.primary.main,
        //opacity: '0.95',
        borderRadius: 3,
        marginTop: 0,
        paddingTop: 0,
        maxWidth: "100vw",
    },
    iconVote: {
        fontSize: '2em',
        color: theme.palette.secondary.light,
        marginLeft: 20,
    },
    iconNoVote: {
        fontSize: '2em',
        color: theme.palette.themeGrey,
        marginLeft: 20,
    },
    loading: {
        marginTop: 50,
        textAlign: 'center',
        marginBottom: 50,
    },
    measurement: {
        fontSize: 30,
        fontWeight: 700,
        color: theme.palette.secondary.light,
        marginBottom: 20,
        textAlign: 'center',
    },
    buttonTitle: {
        color: theme.palette.black,
        fontSize: 12,
        paddingTop: 0,
        paddingBottom: 0,
        flexDirection: 'column',
        textAlign: "center",
    },
    textInputFrame: {
        backgroundColor: theme.palette.themeNaviGrey,
        //borderRadius: 5,
        color: 'black',
        width: '100%',
        fontSize: '16px',
    },
    textInputHidden: {
        display: 'none',
        width: '100%',
        paddingBottom: 10,
        marginTop: -6
    },
    textInputVisible: {
        display: 'block',
        paddingBottom: 10,
        width: '100%',
        marginTop: -6
    },
    fabHidden: {
        position: 'absolute',
        zIndex: 0,
        top: -20,
        left: 0,
        right: 0,
        lineHeight: 0.4,
        display: 'none',
    },
    fabButtonCenter: {
        position: 'absolute',
        zIndex: 0,
        top: -20,
        left: 0,
        right: 0,
        lineHeight: 0.4,
        textTransform: 'none',
        margin: '0 auto',
        backgroundColor: theme.palette.secondary.main
    },
    thinking: {
        color: theme.palette.secondary.dark,
        fontWeight: 700,
        marginTop: 20,
        textAlign: 'center',
    },
    toolBar: {
        backgroundColor: theme.palette.primary.light,
        margin: "auto",
        maxWidth: 500,
        width: "100%",
        position : "fixed",
        borderRadius: 2,
        zIndex: 100
    },
    logoAnimated: {
        width: "20%",
        marginTop: 50,
        maxWidth: 100,
        },
    logoCaption: {
        marginBottom: 2,
        marginTop: 8,
        marginLeft: 6,
        marginRight: 6,
        maxHeight: 21,
        maxWidth: '20vW'
    },
    logoContainer: {
        backgroundColor: theme.palette.primary.dark,
        borderRadius: '50%',
        minWidth: 33,
        minHeight: 33,
        maxWidth: 33,
        maxHeight: 33,
    },
    vote: {
        color: '#2d2c4c',
        fontSize: 18,
        textAlign: 'center',
    },
    voteIcons: {
        textAlign: 'center',
    },
    "@global": {
        "@keyframes pulse": {
            "0%": {
                opacity: 1,
                //transform: "translateY(5rem)"
            },
            "50%": {
                opacity: 0.2,
                //transform: "translateY(5rem)"
            },
            "100%": {
                opacity: 1,
                //transform: "translateY(0)"
            }
        }
    },
});

const StyledSlider = withStyles(theme => ({
    root: {
        color: 'darkgrey',
        height: 9,
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: theme.palette.secondary.main,
        border: "3px solid "+theme.palette.secondary.main,
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
        },
    },
    active: {},
    track: {
        backgroundColor: '#fcd750',
        height: 9,
        borderRadius: 10,
    },
    rail: {
        height: 9,
        borderRadius: 10,
    }
}))(Slider);

const wordsMood = {
    priming: {
        "1" : "lousy",
        "2" : "not the best",
        "3" : "normal",
        "4" : "good",
        "5" : "excellent"
    },
    reflection: {
        "1" : "lousy",
        "2" : "not the best",
        "3" : "normal",
        "4" : "good",
        "5" : "excellent"
    },
    assessment: {
        "1" : "very bad",
        "2" : "not good",
        "3" : "normal",
        "4" : "good",
        "5" : "excellent"
    },
    progress: {
        "1" : "Nothing at all",
        "2" : "Not much",
        "3" : "Something",
        "4" : "I'm On-Track",
        "5" : "It's Done"
    }
}

const icebreakers = [
    "What would you like to talk about?", 
    "How can I help you today?",
    "Ready to tackle something awesome?",
    "What's on your mind today?",
    "How may I be of service?"
]
const randomIndex = Math.floor(Math.random() * icebreakers.length);
const icebreakerSentence = icebreakers[randomIndex];

const ignoreTexts = ['skip', 'Skip', 'next', 'Repeat', 'Change Question', 'Retry Question', 'Goodbye', 'Guide me!', 'Next', 'Next!', 'Guide me', 'Mentor me!', 'Mentor me'];

class RoutineWithContent extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.refTextInput = React.createRef();
        this.refPageScroll = React.createRef();
        this.refDialogueResults = React.createRef();
        this.state = {
            loading: true,
            open: false,
            dialogue: undefined,
            messages: [],
            tabSelection: 'Coach',
            messageListById: [],
            turn: 0,
            currentDialogueId: '',
            botUtterance: [],
            value: 3,
            text: '',
            showTextInput: false,
            feedback: '',
            vote: '',
            openTask: false,
            saveTask: '',
            askHelp: '',
            askReflectionTopic: '',
            askFeedback: '',
            spinner: false,
            flagShowGoalSuggestion: undefined,
            changeQuestionAvailable: 3
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.refPageScroll.current.scrollTo(0,0);
        this.checkSetFremiumFlag();
        this.loadCategoriesAndQualities();
        /////// ROLE Fitler
        var userRoleFilter = this.getUserRole();
        this.setState({userRoleFilter});
        if(this.props.location?.state?.guidedChat === true){
            this.setState(this.props.location.state);
        }
        //console.log('m context',this.context, this.props);
        if (window.CdvPurchase !== undefined && !(window.CdvPurchas?.store?.ready()) ){
            document.addEventListener("deviceready", () => this.appStoreSubscriber(), false);
        }
        else if(window.CdvPurchase!== undefined && window.CdvPurchase?.store?.ready()){
            this.appStoreSubscriber()
        }
        else if(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM!==undefined && process.env.REACT_APP_DEVICE_PLATFORM==="BROWSER"){
            this.appStoreSubscriber() 
        }
        this.initiateEmojiScroller();
        //var handleOpenTutorial = false;
        //if(this.context !== undefined && (this.context.dialogueCounter === undefined || this.context.dialogueCounter <1)) handleOpenTutorial = true;
        //this.setState({ loading: true, handleOpenTutorial });

        //var userDataSet = {};
        //Object.assign(userDataSet, this.context);
        //this.initiateChatSettings(this.context);
        this.internetConnectionCheck();
        AddAnalytics('screen_view', 'PageRoutine');
        this.quoteGenerator();
        if(!(this.context?.dialogueCounter >= 2)){
            StoreMobileDeviceToken(this.props.firebase.user(this.context.uid), true);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        //this.unsubscribeUser();
        if(this.unsubscribeDialogue!== undefined){
            this.unsubscribeDialogue();
        }
        if(this.unsubscribeMessages!== undefined){
            this.unsubscribeMessages();
        }
    }

    checkForAppSubmittedMessages = async () => {
        /// flag for ended
        var flagChatFinished = false;
        if(this.context.dialogueStatus === 'ended'){
            flagChatFinished = true;
        }
        ////// Loading new chat from request
        if(this.props.location?.state?.startNewChat === true){
            console.log('this.props.location?.state', this.props.location?.state)
            if(this.props.location?.state?.openChat && this.props.location?.state?.startText?.length > 0){
                this.setState({extraQuestioning: true, tabSelection: 'Mentor', guidedChat: false});
                await this.requestContinue(true);
                await this.onSubmit(this.props.location.state.startText)
                return true
            }
            else if(this.props.location?.state?.openChat){
                this.setState({extraQuestioning: true, tabSelection: 'Mentor', guidedChat: false});
                await this.requestContinue(true);
                return true
            }
            else if(this.props.location?.state?.smallTalk && this.props.location?.state?.startText?.length > 0){
                this.setState({tabSelection: 'Coach', guidedChat: true, introMessageDone: true});
                if(flagChatFinished){
                    await this.request(true, true);
                }
                else{
                    await this.request(true);
                }
                await this.onSubmit(this.props.location.state.startText)
                return true
            }
            else if(this.props.location?.state?.smallTalk){
                this.setState({tabSelection: 'Coach', guidedChat: true, introMessageDone: true});
                if(flagChatFinished){
                    await this.request(true, true);
                }
                else{
                    await this.request(true);
                }
                return true
            }
            else if(this.props.location?.state?.startText?.length > 0){
                console.log('startnew with Text', this.props.location.state.startText)
                if(flagChatFinished){
                    await this.request(true, true);
                }
                await this.onSubmit(this.props.location.state.startText)
                return true
            }
            else{
                console.log('startnew new request')
                this.handleTopicChange(true)
                return true
            }
        }
        else if(this.props.location?.state?.startText?.length > 0){
            console.log('startText', this.props.location.state.startText)
            if(flagChatFinished){
                await this.request(undefined, true);
            }
            await this.onSubmit(this.props.location.state.startText)
            return true
        }
        else return true
    }

    internetConnectionCheck() {
        const {theme} = this.props;
        try{
            this.props.firebase.onDisconnectSimple(this, (connected) => {
                var latestConnectionErrorStats = this.state.firebaseConnectionError ;
                if(connected === false && latestConnectionErrorStats === undefined && this._isMounted){
                    this.setState({firebaseConnectionMessage : 'Connecting with you ...'});
                    if(window.StatusBar !== undefined){
                        window.StatusBar.overlaysWebView(false);
                        window.StatusBar.backgroundColorByHexString(theme.palette.backTertiary);
                        window.StatusBar.styleLightContent();
                        window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiary, true);
                    } 
                }
                else if(connected === true && this._isMounted ){
                    if(window.StatusBar !== undefined){
                        window.StatusBar.overlaysWebView(false);
                        window.StatusBar.backgroundColorByHexString(theme.palette.backTertiary);
                        window.StatusBar.styleLightContent();
                        window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiary, true);
                    }
                    if((this.state.leadershipQualities===undefined || this.state.leadershipQualities===null || this.state.leadershipQualities.length<1 ) && latestConnectionErrorStats === true){
                        this.loadCategoriesAndQualities() ;
                    }
                    //if((this.state.dialogue===undefined || this.state.dialogue===null || this.state.dialogue===undefined ) && latestConnectionErrorStats === true){
                    if((this.state.dialogue===undefined || this.state.dialogue===null || this.state.dialogue===undefined )){
                        //console.log('initiated to restart check after connection error');
                        this.initiateChatSettingsDialogues(this.context);
                    }
                }
                else if(connected === false && this._isMounted){
                    this.setState({firebaseConnectionMessage : 'Trying to connect with the internet!'});
                    if(window.StatusBar !== undefined){
                        window.StatusBar.overlaysWebView(false);
                        window.StatusBar.backgroundColorByHexString(theme.palette.backTertiary);
                        window.StatusBar.styleLightContent();
                        window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiary, true);
                    }  
                }
            });
        }
        catch(err){
            this.loadCategoriesAndQualities();
            this.initiateChatSettingsDialogues(this.context);
        }
    }

    initiateEmojiScroller(){
        this.timeoutInitiateEmojiScroller = setTimeout(() => {
            if(this._isMounted){
                if(this.state.hoverAuto !== false){
                    //console.log('this.state', this.state)
                    var hoverItem = this.state.hoverItem || 1;
                    var hoverMove = this.state.hoverMove || 'up';
                    if(this.state.hoverItem === undefined || this.state.hoverItem < 1){
                        hoverItem = 1;
                        hoverMove = 'up';
                    }
                    else if(hoverItem === 1){
                        if(this.state.hoverMove === 'down') {
                            hoverItem = 2;
                            hoverMove = 'up';
                        }
                        else hoverItem += 1;
                    }
                    else if(hoverItem > 1 && hoverItem < 5){
                        if(this.state.hoverMove === 'down') hoverItem -= 1;
                        else hoverItem += 1;
                    }
                    else{
                        hoverItem -= 1;
                        hoverMove = 'down';
                    }
                    this.setState({hoverItem, hoverMove});
                }
                if((this.state.dialogue === null || this.state.dialogue === undefined ) || this.state.dialogue?.phase === undefined || this.state.dialogue?.phase === 'session_requested' || this.state.dialogue?.phase === 'created' || this.state.dialogue?.phase === 'utterance_sent'){
                    this.initiateEmojiScroller();
                }
            }
        }, 1500);
    }

    getUserRole(){
        var userRoleFilter = 'default';
        if(this.context.onboardingResponses?.length > 0){
            /// 'executive', 'professional', 'seeker', 'student']}
            if(this.context.onboardingResponses?.includes('Student'))
                userRoleFilter = 'student';
            if(this.context.onboardingResponses?.includes('Teenager'))
                userRoleFilter = 'teenager';
            if(this.context.onboardingResponses?.includes('Available'))
                userRoleFilter = 'seeker';
            if(this.context.onboardingResponses?.includes('Professional'))
                userRoleFilter = 'professional';
            if(this.context.onboardingResponses?.includes('Executive'))
                userRoleFilter = 'executive';
        }
        return userRoleFilter;
    }

    render() {
        const { loading, messages, tabSelection } = this.state;
        var { nonSubscriber } = this.state;
        //nonSubscriber = true;////TESTING
        const { classes, theme } = this.props;
        //console.log('messages', messages)
        //console.log('RoutineWichContent Render', this.state)
        // make this check if the subscribe window is open -> else it requires resources
        //console.log('nonSubscriber', nonSubscriber)
        if(nonSubscriber){
            if(this.state.DialogSettingPlanOpen && CheckSubscriptionStateOwned(this.context)){
                nonSubscriber = false;
            }
            else{
                //console.log('params', this.state.textPromptHistory, this.state.dialogue?.dialogueStage)
                if(this.state.freemiumSubscriber){
                    nonSubscriber = false;
                }
                ////// This is relevant for the mentor mode after 3 questions it is empty
                else if(this.state.textPromptHistory?.length > 3 && this.state.tabSelection === 'Mentor'){
                    //console.log('3 prompts stages', this.state.textPromptHistory?.length)
                    nonSubscriber = true;
                }
                else if(this.context && freeTopics.includes(QualityID(this.context.currentLeadershipQuality))){
                    nonSubscriber = false;
                }
                else if(this.state.dialogue?.dialogueStage?.awareness?.phase === 'ended'){
                    nonSubscriber = true;
                    //console.log('awareness phase schecked',this.state.dialogue?.dialogueStage)
                }
                else{
                    nonSubscriber = false;
                }
            }
        }
        /// flag for ended
        var flagChatFinished = false;
        if(this.state.dialogue !== undefined && this.state.dialogue !== null &&
            ( this.state.dialogue?.phase === 'session_ended' 
                || this.state.dialogue?.phase === 'session_ended_earlier' 
                || this.state.dialogue?.phase === 'feedback'
                || this.state.dialogue?.phase === 'ended' // this is a heritage phase mode ended does not existi anymore
            )
        ){
            flagChatFinished = true;
            nonSubscriber = false;
        }
        //////// END

        var chatResultScore = this.getChatResultScore();
        //console.log(chatResultScore)
        //console.log("messages", messages)
        //console.log('Spiiner:', this.state.spinner, this.state.showTextInput)
        return (
            <div 
                className={(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM !== undefined && process.env.REACT_APP_DEVICE_PLATFORM === "BROWSER")
                    ? classes.root  
                    : classes.rootApp
                }  
                ref={this.refPageScroll}
                id="scrollableDiv"
        >
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    className={classes.background
                        //(loading || this.state.firebaseConnectionError)? classes.backgroundLoading : classes.background
                    }
                    >
                    <DialogTutorialPage 
                        handleOpen={this.state.handleOpenTutorial || false}
                        handleBadgeFamily = {this.state.tutorialFamily?.length>1 ? this.state.tutorialFamily : 'chat'}
                        handleClose={() => {this.setState({handleOpenTutorial: false, tutorialFamily : 'chat'})}}
                    /> 
                    <DialogSettingPlan 
                        user={this.context}
                        defaultSet={this.props.defaultSet || undefined}
                        infoText={this.state.subscriptionPlanInfoText}
                        handleOpen={this.state.DialogSettingPlanOpen || false}
                        handleClose={() => this.setState({DialogSettingPlanOpen : false})}
                        purchaseSuccessFlag={() => this.setState({
                            nonSubscriber: false, 
                            missingSubscription: false, 
                            subscriptionPlanInfoText: undefined,
                            mute: this.state.subscriptionActivateAudio ? true : this.state.mute
                        })}
                        purchaseSuccessHandler={() => this.setState({
                            nonSubscriber: false, 
                            missingSubscription: false, 
                            subscriptionPlanInfoText: undefined,
                            mute: this.state.subscriptionActivateAudio ? true : this.state.mute
                        })}
                    />
                    {this.state.leadershipCategories?.length>0
                    &&
                        <DialogQuickTopicSetting
                            defaultSet={this.props.defaultSet || undefined}
                            openCurrentAchievementGoal = {this.state.openCurrentAchievementGoal || false}
                            openCurrentShopFlag = {this.state.openCurrentShopFlag || false}
                            handleClose = {() => this.setState({openCurrentAchievementGoal: false, openCurrentShopFlag: false, openCurrentShopFlag: false})}
                            handleOpen = {
                                () => {
                                    this.setState({openCurrentAchievementGoal: true, typingBotFinishTypingNow: true});
                                    ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my coaching module', 'Are you fine with your current module?', 'utter_ask_help', '', 'profile');
                                }
                            }
                            handleSubmitCurrentAchievementGoal = { this.handleTopicChange }
                            unselectTopics={this.state.unselectTopics || false}
                            user={this.context}
                            //renderPreview={true}
                            leadershipCategories={this.state.leadershipCategories}
                            leadershipQualities={this.state.leadershipQualities} 
                        />
                    }
                    <Grid item xs={12} className={IsMobileDevice() ? classes.gridMobile : classes.grid}>
                        <div style={{maxWidth:'inherit'}} ref={(el) => { this.messagesStart = el; }}>
                            {this.toolbarHeaderChat(chatResultScore)}
                        </div>
                        {!(IsMobileDevice())
                            &&
                                <DrawerGoalOverview 
                                    user={this.context}
                                    defaultSet={this.props.defaultSet || undefined}
                                    leadershipCategories={this.state.leadershipCategories}
                                    leadershipQualities={this.state.leadershipQualities} 
                                    bottomPosition={false}
                                    openSettings = {() => {
                                            this.setState({speedDialOpen: false, dialogSettingsOpen: true})
                                        }
                                    }
                                    openTutorial = {() => {
                                            this.setState({speedDialOpen: false, dialogSettingsOpen: false, handleOpenTutorial: true})
                                        }
                                    }
                                    openOptions = {
                                        (this.state.typingBotDone && this.state.messages?.length >= 4 &&
                                            this.state.dialogue!==null && this.state.dialogue?.phase !== 'session_requested') 
                                        ?   () => {
                                                if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                    console.log('done message scrooll')
                                                    this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                }
                                                this.setState({speedDialOpen: true})
                                            }
                                        :   undefined
                                    }
                                /> 
                        }
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="stretch"
                            className={classes.chat}
                        >
                            <Grid item xs={12}>
                                <div className={classes.gridPadding}>&nbsp;</div>
                            </Grid>
                            {(loading || this.state.firebaseConnectionError || this.state.loadingRequest) 
                                ?
                                    this.renderLoading()
                                :   <>
                                        <div value={"Coach"} index={1} hidden={tabSelection !== "Coach" && !flagChatFinished} style={{width: '100%'}}>
                                            <React.Fragment key="Coach">
                                                {nonSubscriber!==true && this.state.dialogue && this.state.dialogue!==null && this.state.dialogue!==undefined && !this.state.firebaseConnectionError
                                                ?  (() => {
                                                    if (flagChatFinished){
                                                        //console.log('message before', messages)
                                                        return (
                                                            <React.Fragment>
                                                                <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                    {
                                                                        ((messages!== undefined && messages!== null && messages.length>=1 && messages[messages.length-1].isCoach===true)
                                                                        || this.state.typingBotDone)
                                                                        ?   
                                                                            <ChatBlock
                                                                                variant={"chatEnd"}
                                                                                user={this.context}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                showAvatar={true}
                                                                                allowFeedback={false}
                                                                                allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                addSpokenSentenceList={(spokenTextSamples) => {this.setState({spokenTextSamples})}}
                                                                                spokenTextSamples={this.state.spokenTextSamples || []}
                                                                                currentDialogueId={this.state.currentDialogueId}
                                                                                messages={messages} 
                                                                                chatResultScore={chatResultScore}
                                                                                showTextInputHandler={this.showTextInputHandler} 
                                                                                typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                //currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                                typingDoneHandler={() => {
                                                                                    if(this.messagesStart !== null && this.messagesStart !== undefined){
                                                                                        //console.log('done message scrooll')
                                                                                        this.messagesStart.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                                    }
                                                                                }}
                                                                            />
                                                                        :   
                                                                            <div>
                                                                                <ChatBlock 
                                                                                    user={this.context} 
                                                                                    variant={"chatLoading"} 
                                                                                    allowFeedback={false}
                                                                                    allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                    showTextInputHandler={this.showTextInputHandler} 
                                                                                    typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                />
                                                                            </div>
                                                                    }
                                                                    {this.quoteRender()}
                                                                </Grid>
                                                                <Grid item xs={12} style={{paddingTop: 20 , width: "100%"}}>
                                                                    {(this.state.dialogue?.wordCollection !== undefined || this.state.dialogue?.dialogueStage?.awareness?.phase === 'ended')
                                                                        &&
                                                                        <h4 style={theme.textSupportCenter}>
                                                                            {this.state.dialogue?.connectionError === true
                                                                                ?   //'I will be back in 30 minutes.'
                                                                                    'Thank you for your patience and hard work.'
                                                                                :   'Well done!'
                                                                            }
                                                                        </h4>
                                                                    }
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="space-between"
                                                                        alignItems="stretch"
                                                                        style={{ width: "100%"}}
                                                                    >
                                                                        <Grid item xs={6}>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color='secondary'
                                                                                onClick={ () => {
                                                                                        this.request(undefined, true);
                                                                                        this.setState({extraQuestioning: true, tabSelection: 'Coach', guidedChat: undefined});
                                                                                        //this.setState({extraQuestioning: true, tabSelection: 'Mentor'});
                                                                                        //this.requestContinue();
                                                                                    }
                                                                                }
                                                                            >
                                                                                Chat More&nbsp;
                                                                                {this.props.defaultSet?.programLogo?.includes('http')
                                                                                    ?   <ForumIcon fontSize="small" />
                                                                                    :   <RockyIcon fontSize="small" />
                                                                                }
                                                                            </Button>
                                                                        </Grid> 
                                                                        <Grid item xs={6} style={{textAlign: 'right'}}>
                                                                            <Button onClick={this.scrollDown}>
                                                                                Tips&nbsp;<EmojiObjectsIcon fontSize="small"  />
                                                                            </Button>
                                                                            {/***
                                                                            <div style={{...theme.divCenter, color: 'black'}}
                                                                                onClick={this.scrollDown}
                                                                                >
                                                                                <ArrowDownwardIcon 
                                                                                    fontSize= "large"
                                                                                />
                                                                                <p style={theme.textSupportCenter}>Scroll down for Ideas</p>
                                                                            </div>
                                                                            */}
                                                                        </Grid> 
                                                                    </Grid>
                                                                </Grid>
                                                                <SnackbarInfo 
                                                                    alertText = 'Well done! You finished the chat. Check your tips and ideas of the day or give Rocky a feedback. You finished your reflection and you can continue every morning and evening for a new chat.'
                                                                    alertName = 'chatoutro'
                                                                    userId = {this.context.uid}
                                                                />
                                                                <VizSensor 
                                                                    key={'tips_visible'}
                                                                    partialVisibility={false}
                                                                    delayedCall={true}
                                                                    offset={{bottom: 100}}
                                                                    onChange={(isVisible) => { 
                                                                        //console.log('onchange viz', isVisible)
                                                                        if(isVisible && this._isMounted && this.state.scrollDownDone !== true) { 
                                                                            //console.log('onflag viz', isVisible)
                                                                            this.setState({scrollDownDone: true});
                                                                        }}
                                                                    }
                                                                >
                                                                </VizSensor>
                                                                {this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined
                                                                    ?
                                                                        <Grid item xs={12} style={{  paddingTop: 40, width: "100%" }}>
                                                                            <JournalActivities 
                                                                                user={this.context}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                checkMode={true}
                                                                                groupItems={true}
                                                                                showTips={true}
                                                                                setPrioCheck={true}
                                                                                style={{paddingLeft:0, paddingRight:0}}
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities} 
                                                                                historyLimit={12}
                                                                                //showOnlyOneItem={true}
                                                                                visualLimit={4}
                                                                                handlerMoment={(moment) => this.setState({animateMomentByName: moment})}
                                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                            />
                                                                        </Grid>
                                                                    : ""
                                                                }
                                                                {this.state.dialogue !== undefined && this.state.dialogue?.wordCollection !== undefined 
                                                                    &&
                                                                        <Grid item xs={12} style={{  paddingTop: 0, width: "100%" }}>
                                                                            <MindfulnessScore 
                                                                                dialogue={this.state.dialogue}  
                                                                                user={this.context}
                                                                                showIdeas={true}
                                                                                showMindset={false}
                                                                                showSentiments={false}
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities}
                                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                                />
                                                                        </Grid>
                                                                }
                                                                {(this.context.dialogueCounter === undefined || this.context.dialogueCounter < 3) 
                                                                    ?
                                                                        <Grid item xs={12} style={{paddingBottom:0, paddingTop:20}}>
                                                                            <BadgeCard
                                                                                backgroundColor ={"white"}
                                                                                image={logoWhatsNext} 
                                                                                avatarImage = {undefined}
                                                                                title = {'Open'}
                                                                                subtitle = {'Tutorial'}
                                                                                strongSubtitle = {true}
                                                                                onClick={ () => { this.setState({handleOpenTutorial: true, tutorialFamily : 'total'}) } }
                                                                                clickText= {'Show'}
                                                                                //button={<>Show&nbsp;<HelpOutlineIcon /></>}
                                                                            />
                                                                        </Grid>
                                                                    :   (this.state.dialogue?.wordCollection !== undefined || this.state.dialogue?.dialogueStage?.awareness?.phase === 'ended')
                                                                        ?
                                                                            true
                                                                            ?   <></>
                                                                            :
                                                                                <Grid item xs={12} style={{paddingBottom:0, paddingTop:20}}>
                                                                                    {true //this.context.dialogueCounter <= 6
                                                                                        ?
                                                                                            <CardBadges
                                                                                                variant={'XPLevel'}
                                                                                                visualLimit={1}
                                                                                                badgeFamilyDetailSelection={'all'}
                                                                                                noAlert={false}
                                                                                                //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                                                                                            />
                                                                                        :
                                                                                            <p style={theme.textErrorCenterPadding}>
                                                                                                <img src={ badgeCongrats} 
                                                                                                    style={{maxWidth:120, width:"25%", height: 'auto', verticalAlign: 'middle'}} 
                                                                                                    alt='well done'  />
                                                                                            </p>
                                                                                    }
                                                                                </Grid>
                                                                        :
                                                                            <Grid item xs={12} style={{paddingBottom:40, paddingTop:40}}>
                                                                                <p style={theme.textErrorCenterPadding}>
                                                                                    <img src={logoAnimated} className={classes.logoAnimated} alt="Rocky Smart Journaling" />
                                                                                </p>
                                                                                <h4 style={theme.textSupportCenter}>
                                                                                    I would like to talk more with you!
                                                                                </h4>
                                                                            </Grid>
                                                                }
                                                                <TaskNext3D 
                                                                    defaultSet={this.props.defaultSet}
                                                                    user={this.context}
                                                                    finishedChat={true}
                                                                    cardMargin={0}
                                                                    leadershipCategories={this.state.leadershipCategories}
                                                                    leadershipQualities={this.state.leadershipQualities} 
                                                                    tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                />
                                                                <div key="tips_ref" ref={this.refDialogueResults}>&nbsp;</div>
                                                                {this.state.dialogue !== undefined && this.state.dialogue?.contentSelection !== undefined 
                                                                    && this.state.dialogue?.contentSelection[1]!== undefined 
                                                                    &&
                                                                        <Grid item xs={12} style={{ paddingTop: 40, width: "100%" }}>
                                                                            <HomeCardContentItems
                                                                                user={this.context}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                visualLimit={5}
                                                                                raised = {true}
                                                                                specialQuery={'contentByIds'}
                                                                                direction={'column'}
                                                                                titleOfGroup={"General Tips"}
                                                                                allowUpdate={false}
                                                                                qualityFilter = {this.state.dialogue?.contentSelection.map(a => a.contentId)}
                                                                                swipe={true}
                                                                                //qualityFilter = {[this.state.dialogue?.contentSelection[0].contentId]}
                                                                                //qualityFilter = {[this.state.dialogue?.contentSelection[0].contentId, this.state.dialogue?.contentSelection[1].contentId]}
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities}
                                                                                allQualitiesLoaded = { [] }
                                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                                //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                                                                            />
                                                                        </Grid>
                                                                }
                                                                <Grid item xs={12} style={{ paddingTop: 40 , width: "100%"}}>
                                                                    <p style={theme.textSupportCenter}>Want to chat more?</p>
                                                                    {false && this.state.dialogue?.phase === 'session_ended_earlier'
                                                                        ?
                                                                            <Button
                                                                                onClick={() => {
                                                                                    this.setState({extraQuestioning: true, tabSelection: 'Mentor'});
                                                                                    this.requestContinueUnfinished();
                                                                                }}
                                                                                variant="contained"
                                                                                className={classes.button}
                                                                            >
                                                                                Continue chat
                                                                            </Button>
                                                                        : 
                                                                            <Button
                                                                                onClick={ () => {
                                                                                        this.request(undefined, true);
                                                                                        this.setState({extraQuestioning: true, tabSelection: 'Coach', guidedChat: undefined});
                                                                                        //this.setState({extraQuestioning: true, tabSelection: 'Mentor'});
                                                                                        //this.requestContinue();
                                                                                    }
                                                                                }
                                                                                className={classes.button}
                                                                            >
                                                                                Dive into another round!
                                                                                &nbsp;
                                                                                {this.props.defaultSet?.programLogo?.includes('http')
                                                                                    ?   <ForumIcon fontSize="small" />
                                                                                    :   <RockyIcon fontSize="small" />
                                                                                }
                                                                            </Button>
                                                                    }
                                                                </Grid>
                                                                {this.context?.features?.showChallenges && (this.context.dialogueCounter === undefined || this.context.dialogueCounter <4) &&
                                                                    <Task4Chats
                                                                        noMargin={true}
                                                                        user={this.context}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities}
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                }
                                                                {this.context?.features?.showChallenges && (this.context.dialogueCounter >= 4 && this.context.dialogueCounter <= 30) &&
                                                                    <Task4Topics
                                                                        noMargin={true}
                                                                        loadStats={true}
                                                                        user={this.context}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities}
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                }
                                                                {this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined
                                                                    //&& this.context.engagementCounter!== undefined && this.context.engagementCounter>=15
                                                                    &&
                                                                    <Grid item xs={12} style={{ paddingTop: 40 , paddingBottom: 35, width: "100%"}}>
                                                                        <HomeCardContentItems
                                                                            user={this.context}
                                                                            defaultSet={this.props.defaultSet || undefined}
                                                                            visualLimit={1}
                                                                            raised = {true}
                                                                            specialQuery={'randomQuotes'}
                                                                            direction={'column'}
                                                                            titleOfGroup={"Quote of the Day"}
                                                                            showDisclaimer={false}
                                                                            allowUpdate={false}
                                                                            qualityFilter = {
                                                                                this.state.todaysLeadershipQuality!== undefined && this.state.todaysLeadershipQuality !==''
                                                                                    ?   this.state.todaysLeadershipQuality
                                                                                    :   this.context.currentLeadershipQuality
                                                                            }
                                                                            leadershipCategories={this.state.leadershipCategories}
                                                                            leadershipQualities={this.state.leadershipQualities}
                                                                            allQualitiesLoaded = {
                                                                            this.context.contentUnliked !== undefined
                                                                                ? this.context.contentUnliked 
                                                                                : []
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                }
                                                                {false && this.context.dialogueCounter !== undefined && this.context.dialogueCounter > 6
                                                                    &&
                                                                    <Grid item xs={12} style={{  paddingTop: 40, width: "100%" }}>
                                                                        <CardBadges
                                                                            visualLimit={2}
                                                                            noAlert={true}
                                                                            tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                            //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    //console.log('Dialogue phase: ', this.state.dialogue?.phase)
                                                    else if ( (this.state.spinner === true && this.state.dialogue?.phase !== 'running') || (this.state.dialogue?.phase === 'created')
                                                        || (this.state.dialogue?.phase === 'utterance_sent' && (messages?.[messages?.length-1]?.isCoach!==true) )
                                                        ){
                                                        return (
                                                            <React.Fragment >
                                                                <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                    {this.renderChatContext()}
                                                                    {(!window.navigator.onLine || this.state.firebaseConnectionError )
                                                                        ?
                                                                            <div >
                                                                                <LoadingProgress 
                                                                                    defaultSet={this.props.defaultSet}
                                                                                    type={'LoadingPageAnimated'} 
                                                                                    loadingText={this.state.firebaseConnectionMessage} 
                                                                                    firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                                                                    allowRefresh={true}
                                                                                />
                                                                                <p style={theme.textErrorCenterPadding}>
                                                                                    &nbsp; {this.state.firebaseConnectionMessage}
                                                                                </p>
                                                                                <MindfulnessScore 
                                                                                    dialogue={this.state.dialogue}  
                                                                                    user={this.context}
                                                                                    showSentiments={false}
                                                                                    showIdeas={false}
                                                                                    leadershipCategories={this.state.leadershipCategories}
                                                                                    leadershipQualities={this.state.leadershipQualities}
                                                                                    tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                                    />
                                                                            </div>
                                                                        :
                                                                            <div>
                                                                                <ChatBlock 
                                                                                    user={this.context} 
                                                                                    variant={"chatLoading"}
                                                                                    allowFeedback={false}
                                                                                    allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                    showTextInputHandler={this.showTextInputHandler} 
                                                                                    typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                />
                                                                            </div>
                                                                    }
                                                                    {this.quoteRender()}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    else if (// this.state.dialogue?.phase === 'session_requested' &&
                                                        (messages?.[messages?.length-1]?.isCoach===true) && 
                                                        ((messages?.[messages?.length-1]?.utteranceClass?.includes('utter_ask_energy_level')) || (messages?.[messages?.length-1]?.utteranceClass?.includes('utter_ask_satisfaction_level')))
                                                    ){
                                                        //console.log('Dialogue energy render: ', this.state.dialogue?.phase, messages)
                                                        return (
                                                            <React.Fragment>
                                                                {(this.state.guidedChat === undefined 
                                                                    //&& !(this.context.features?.hideopenchat) 
                                                                    //&& !(this.context.dialogueCounter > 3)
                                                                    )
                                                                ?
                                                                    this.renderButtonSimpleIntro()
                                                                :
                                                                    <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                        {this.renderChatContext()}
                                                                        {this.state.introMessages?.length>0 &&
                                                                            <>
                                                                                {this.state.skipIntroMessage !==true && this.context.dialogueStatus !== 'open' &&
                                                                                    <ChatBlock
                                                                                        user={this.context}
                                                                                        allowAudio={false}
                                                                                        variant={"chatIntro"}
                                                                                        currentDialogueId={this.state.currentDialogueId}
                                                                                        messages={this.state.introMessages}
                                                                                        currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                                        //showTextInputHandler={this.showTextInputHandler} 
                                                                                        typingBotFinishTypingNow={
                                                                                            this.state.introMessageDone 
                                                                                                ?   true 
                                                                                                :   this.context.features?.fastmode 
                                                                                                    ?   this.context.features.fastmode
                                                                                                    :   this.state.typingBotFinishTypingNow 
                                                                                                        ?   this.state.typingBotFinishTypingNow
                                                                                                        :   false
                                                                                            }
                                                                                        typingDoneHandler={() => {
                                                                                            this.setState({showTopicOptions: true});
                                                                                            //console.log('message end, ', this.state.typingBotFinishTypingNow, this.context.muteAudio)
                                                                                            setTimeout(() => {
                                                                                                this.setState({introMessageDone: true, showTextInput: true});
                                                                                            }, (this.state.typingBotFinishTypingNow || this.context.muteAudio || this.state.mute)
                                                                                                ?   100 
                                                                                                :   200
                                                                                            );
                                                                                            if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                                //console.log('message scrooll')
                                                                                                this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                            }
                                                                                        }}
                                                                                        typingMoveOnHandler={() => {
                                                                                            //console.log('message move')
                                                                                            if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                                //console.log('message scrooll')
                                                                                                this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </>
                                                                        }
                                                                        {(this.state.introMessages === undefined || this.state.introMessageDone || this.state.introMessages?.length === 0)
                                                                            &&
                                                                            <ChatBlock
                                                                                user={this.context}
                                                                                allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                allowFeedback = {false}
                                                                                variant={"chatIntro"}
                                                                                currentDialogueId={this.state.currentDialogueId}
                                                                                messages={messages} 
                                                                                chatResultScore={chatResultScore}
                                                                                showTextInputHandler={this.showTextInputHandler} 
                                                                                currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                                typingBotFinishTypingNow={
                                                                                    this.context.features?.fastmode  
                                                                                        ?   this.context.features.fastmode 
                                                                                        :   this.state.typingBotFinishTypingNow !== undefined 
                                                                                            ?   this.state.typingBotFinishTypingNow 
                                                                                            :   this.state.messages?.[0]?.utteranceClass?.[0] === 'inform_loading'
                                                                                                ?   true
                                                                                                :   false
                                                                                }
                                                                                typingDoneHandler={() => {
                                                                                    this.setState({
                                                                                        typingBotDone:true, 
                                                                                        showTextInput:true
                                                                                    });
                                                                                    //console.log('message end')
                                                                                    if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                        //console.log('message scrooll')
                                                                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                    }
                                                                                }}
                                                                                typingMoveOnHandler={() => {
                                                                                    //console.log('message move')
                                                                                    if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                        //console.log('message scrooll')
                                                                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                    }
                                                                                }}
                                                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                            />
                                                                        }
                                                                        {(((messages!== undefined && messages!== null && messages.length>=1 && messages[messages.length-1].isCoach===true)
                                                                                || this.state.typingBotDone))
                                                                            ?   ""
                                                                            :   
                                                                                <div>
                                                                                    <ChatBlock 
                                                                                        user={this.context} 
                                                                                        variant={"chatLoading"} 
                                                                                        allowFeedback={false}
                                                                                        allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                        showTextInputHandler={this.showTextInputHandler} 
                                                                                        typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                        {this.state.typingBotDone && (this.context.dialogueCounter === undefined || this.context.dialogueCounter < 2) && this.state.messages?.[0]?.text?.length > 0 && (this.state.messages[0].utteranceClass?.[0] !== 'inform_loading') 
                                                                            &&
                                                                            <div style={theme.textSupportCenter}>
                                                                                Choose an emoji to express your mood!
                                                                            </div>
                                                                        }
                                                                        {true &&
                                                                        (this.state.messages?.[0]?.text?.length > 0 && (this.state.messages[0].utteranceClass?.[0] !== 'inform_loading') && this.state.typingBotDone)
                                                                            ?
                                                                                <div id="send-measurementSmileys" className={classes.containerMeasurement}>
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        justify="space-between"
                                                                                        alignItems="stretch"
                                                                                        //className={classes.chat}
                                                                                        >
                                                                                            <Grid item xs={2}>
                                                                                                <div onClick={() => {this.setState({ value:1, hoverItem: 1, hoverAuto: false}, this.sendMeasurement)}} 
                                                                                                    onMouseEnter={() => {this.setState({ hoverItem: 1, hoverAuto: false}) }}
                                                                                                    onMouseLeave={() => {this.setState({ hoverItem: 3, hoverAuto: true}) }}
                                                                                                >
                                                                                                    <>
                                                                                                        {this.state.hoverItem===1
                                                                                                        ?   <SentimentVeryDissatisfiedTwoToneIcon style={{ 
                                                                                                                fontSize: this.state.hoverItem===1?60: 40, 
                                                                                                                color: (this.state.value < 1.5 || this.state.hoverItem===1 )?  theme.palette.themeRed : "grey" }} />
                                                                                                        :   <SentimentVeryDissatisfiedIcon style={{ 
                                                                                                                fontSize: this.state.hoverItem===1?60: 40, 
                                                                                                                color: (this.state.value < 1.5 || this.state.hoverItem===1 )?  theme.palette.themeRed : "grey" }} />
                                                                                                        }
                                                                                                    </>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div onClick={() => {this.setState({ value:2, hoverItem: 2, hoverAuto: false }, this.sendMeasurement)}} 
                                                                                                    onMouseEnter={() => {this.setState({ hoverItem: 2, hoverAuto: false}) }}
                                                                                                    onMouseLeave={() => {this.setState({ hoverItem: 3, hoverAuto: true}) }}>
                                                                                                    <>
                                                                                                        {this.state.hoverItem===2
                                                                                                            ?   <SentimentDissatisfiedTwoToneIcon style={{ 
                                                                                                                    fontSize:this.state.hoverItem===2?60: 40, 
                                                                                                                    color: ( Math.round(this.state.value) === 2 || this.state.hoverItem===2 )? 'orange':'grey' }}  />
                                                                                                            :   <SentimentDissatisfiedIcon style={{ 
                                                                                                                    fontSize:this.state.hoverItem===2?60: 40, 
                                                                                                                    color: ( Math.round(this.state.value) === 2 || this.state.hoverItem===2 )? 'orange':'grey' }}  />
                                                                                                        }
                                                                                                    </>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={2}>
                                                                                                <div onClick={() => {this.setState({ value:3, hoverItem: 3, hoverAuto: false }, this.sendMeasurement)}} 
                                                                                                    onMouseEnter={() => {this.setState({ hoverItem: 3, hoverAuto: false}) }}
                                                                                                    onMouseLeave={() => {this.setState({ hoverItem: 3, hoverAuto: true}) }}>
                                                                                                    <>
                                                                                                        {this.state.hoverItem===3
                                                                                                            ?   
                                                                                                                <SentimentSatisfiedTwoToneIcon style={{ 
                                                                                                                    fontSize: this.state.hoverItem===3 ?60: 40, 
                                                                                                                    color: (Math.round(this.state.value) === 3 && (this.state.hoverItem===3 || this.state.hoverItem === undefined) )? theme.palette.secondary.main : "grey"}} />
                                                                                                            :  
                                                                                                                <SentimentSatisfiedIcon style={{ 
                                                                                                                    fontSize: this.state.hoverItem===3 ?60: 40, 
                                                                                                                    color: (Math.round(this.state.value) === 3 && (this.state.hoverItem===3 || this.state.hoverItem === undefined) )? theme.palette.secondary.main : "grey"}} />
                                                                                                        }
                                                                                                    </>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div onClick={() => {this.setState({ value:4, hoverItem: 4, hoverAuto: false}, this.sendMeasurement)}}
                                                                                                    onMouseEnter={() => {this.setState({ hoverItem: 4, hoverAuto: false}) }}
                                                                                                    onMouseLeave={() => {this.setState({ hoverItem: 3, hoverAuto: true}) }} >
                                                                                                    <>
                                                                                                        {this.state.hoverItem===4
                                                                                                            ?    <EmojiEmotionsTwoToneIcon style={{ 
                                                                                                                    fontSize: this.state.hoverItem===4?60: 40, 
                                                                                                                    color: (Math.round(this.state.value) === 4 || this.state.hoverItem===4 )? theme.palette.secondary.light : "grey" }} />
                                                                                                            :    <SentimentSatisfiedAltIcon style={{ 
                                                                                                                    fontSize: this.state.hoverItem===4?60: 40, 
                                                                                                                    color: (Math.round(this.state.value) === 4 || this.state.hoverItem===4 )? theme.palette.secondary.light : "grey" }} />
                                                                                                        }
                                                                                                    </>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={2}>
                                                                                                <div 
                                                                                                    onClick={() => {this.setState({ value:5, hoverItem: 5, hoverAuto: false }, this.sendMeasurement)}} 
                                                                                                    onMouseEnter={() => {this.setState({ hoverItem: 5, hoverAuto: false}) }}
                                                                                                    onMouseLeave={() => {this.setState({ hoverItem: 3, hoverAuto: true}) }}
                                                                                                >
                                                                                                    <>
                                                                                                        {this.state.hoverItem===5
                                                                                                            ?   <SentimentVerySatisfiedTwoToneIcon 
                                                                                                                    style={{ 
                                                                                                                        fontSize: this.state.hoverItem===5?60:40, 
                                                                                                                        color: (this.state.value >= 4.5 || this.state.hoverItem===5 )? theme.palette.themeAlertGreen : "grey",
                                                                                                                    }} />
                                                                                                            :   <SentimentVerySatisfiedIcon 
                                                                                                                    style={{ 
                                                                                                                        fontSize: this.state.hoverItem===5?60:40, 
                                                                                                                        color: (this.state.value >= 4.5 || this.state.hoverItem===5 )? theme.palette.themeAlertGreen : "grey",
                                                                                                                    }} />
                                                                                                        }
                                                                                                    </>
                                                                                                </div>
                                                                                            </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            :  (this.state.introMessageDone || this.state.skipIntroMessage)
                                                                                ?   ""
                                                                                :  <div>
                                                                                        <ChatBlock 
                                                                                            user={this.context} 
                                                                                            variant={"chatLoading"} 
                                                                                            allowFeedback={false}
                                                                                            allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                            showTextInputHandler={this.showTextInputHandler} 
                                                                                            typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                        />
                                                                                    </div>
                                                                        }
                                                                        {this.quoteRender()}
                                                                        <div id="tutporial" style={{textAlign: 'left', marginTop: 20, marginBottom: 20}}>
                                                                            {false && (this.context.dialogueCounter === undefined || this.context.dialogueCounter < 1) && this.state.typingBotDone 
                                                                                &&
                                                                                    <Button
                                                                                        onClick ={() => {this.setState({handleOpenTutorial: true})}}
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                    >
                                                                                        Open Tutorial
                                                                                        &nbsp; 
                                                                                        <HelpOutlineIcon fontSize='small' style={{ color:  '#e53935' }}/>
                                                                                    </Button>
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                                <div ref={(el) => { this.refMessagesEnd = el; }}>&nbsp;</div>
                                                                <SnackbarInfo 
                                                                    alertText = 'This is your main coaching chat with Rocky. Start with describing your mood. Rocky will ask you new questions each turn and help you to reflect. The chat is usually 6 rounds long.'
                                                                    alertName = 'chatintro'
                                                                    userId = {this.context.uid}
                                                                    />
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    else if ( // this.state.dialogue?.phase === 'session_requested' &&
                                                        (messages?.[messages?.length-1]?.isCoach===true) && 
                                                        ((messages?.[messages?.length-1]?.utteranceClass?.includes('utter_ask_assessment')) || (messages?.[messages?.length-1]?.utteranceClass?.includes('utter_ask_progress')))
                                                    ){
                                                        //console.log('Dialogue assess render: ', this.state.dialogue?.phase, messages)
                                                        return (
                                                            <React.Fragment>
                                                                {(this.state.guidedChat === undefined 
                                                                    //&& !(this.context.features?.hideopenchat) 
                                                                    //&& !(this.context.dialogueCounter > 3)
                                                                    )
                                                                ?
                                                                    this.renderButtonSimpleIntro()
                                                                :
                                                                    <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                        {this.renderChatContext()}
                                                                        {this.state.introMessages?.length>0 &&
                                                                            <>
                                                                                {this.state.skipIntroMessage !==true && this.context.dialogueStatus !== 'open' &&
                                                                                    <ChatBlock
                                                                                        user={this.context}
                                                                                        allowAudio={false}
                                                                                        variant={"chatIntro"}
                                                                                        currentDialogueId={this.state.currentDialogueId}
                                                                                        messages={this.state.introMessages}
                                                                                        currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                                        //showTextInputHandler={this.showTextInputHandler} 
                                                                                        typingBotFinishTypingNow={
                                                                                            this.state.introMessageDone 
                                                                                                ?   true 
                                                                                                :   this.context.features?.fastmode 
                                                                                                    ?   this.context.features.fastmode
                                                                                                    :   this.state.typingBotFinishTypingNow 
                                                                                                        ?   this.state.typingBotFinishTypingNow
                                                                                                        :   false
                                                                                            }
                                                                                        typingDoneHandler={() => {
                                                                                            this.setState({showTopicOptions: true, showTextInput: true});
                                                                                            //console.log('message end, ', this.state.typingBotFinishTypingNow, this.context.muteAudio)
                                                                                            setTimeout(() => {
                                                                                                this.setState({introMessageDone: true});
                                                                                            }, (this.state.typingBotFinishTypingNow || this.context.muteAudio || this.state.mute)
                                                                                                ?   100 
                                                                                                :   200
                                                                                            );
                                                                                            if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                                //console.log('message scrooll')
                                                                                                this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                            }
                                                                                        }}
                                                                                        typingMoveOnHandler={() => {
                                                                                            //console.log('message move')
                                                                                            if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                                //console.log('message scrooll')
                                                                                                this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </>
                                                                        }
                                                                        {(this.state.introMessages === undefined || this.state.introMessageDone || this.state.introMessages?.length === 0)
                                                                            &&
                                                                            <ChatBlock
                                                                                user={this.context}
                                                                                allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                allowFeedback = {false}
                                                                                variant={"chatIntro"}
                                                                                currentDialogueId={this.state.currentDialogueId}
                                                                                messages={messages} 
                                                                                chatResultScore={chatResultScore}
                                                                                showTextInputHandler={this.showTextInputHandler} 
                                                                                currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                                typingBotFinishTypingNow={
                                                                                    this.context.features?.fastmode  
                                                                                        ?   this.context.features.fastmode 
                                                                                        :   this.state.typingBotFinishTypingNow !== undefined 
                                                                                            ?   this.state.typingBotFinishTypingNow 
                                                                                            :   this.state.messages?.[0]?.utteranceClass?.[0] === 'inform_loading'
                                                                                                ?   true
                                                                                                :   false
                                                                                }
                                                                                typingDoneHandler={() => {
                                                                                    this.setState({
                                                                                        typingBotDone:true, 
                                                                                        showTextInput:true
                                                                                    });
                                                                                    //console.log('message end')
                                                                                    if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                        //console.log('message scrooll')
                                                                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                    }
                                                                                }}
                                                                                typingMoveOnHandler={() => {
                                                                                    //console.log('message move')
                                                                                    if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                        //console.log('message scrooll')
                                                                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                                                                    }
                                                                                }}
                                                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                            />
                                                                        }
                                                                        {(((messages!== undefined && messages!== null && messages.length>=1 && messages[messages.length-1].isCoach===true)
                                                                                || this.state.typingBotDone))
                                                                            ?   ""
                                                                            :   
                                                                                <div>
                                                                                    <ChatBlock 
                                                                                        user={this.context} 
                                                                                        variant={"chatLoading"} 
                                                                                        allowFeedback={false}
                                                                                        allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                        showTextInputHandler={this.showTextInputHandler} 
                                                                                        typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                        {this.state.typingBotDone && (this.context.dialogueCounter === undefined || this.context.dialogueCounter < 2) && this.state.messages?.[0]?.text?.length > 0 && (this.state.messages[0].utteranceClass?.[0] !== 'inform_loading') 
                                                                            &&
                                                                            <div style={theme.textSupportCenter}>
                                                                                Move the slider to express your mood!
                                                                            </div>
                                                                        }
                                                                        {//messages?.[messages.length-1]?.buttons === undefined &&
                                                                        (this.state.messages?.[0]?.text?.length > 0 && (this.state.messages[0].utteranceClass?.[0] !== 'inform_loading') && this.state.typingBotDone)
                                                                            ?   messages?.[messages.length-1]?.buttons?.length>0 
                                                                                ?   <div style={{textAlign: 'right', marginBottom: 10}}>
                                                                                        {this.renderQuestionButtons()}
                                                                                    </div>
                                                                                :
                                                                                    <div id="send-measurement" className={classes.containerMeasurement}>
                                                                                        <p style={{textAlign: 'center'}}>
                                                                                            {this.state.value >=4.5 
                                                                                                ?   <SentimentVerySatisfiedTwoToneIcon style={{textAlign: 'center', fontSize: 50, color: theme.palette.themeAlertGreen }}  />
                                                                                                :   this.state.value >=3.5 
                                                                                                    ?   <EmojiEmotionsTwoToneIcon style={{textAlign: 'center', fontSize: 50, color: theme.palette.themeBlue }}  />
                                                                                                    :   this.state.value >=2.5
                                                                                                        ?   <SentimentSatisfiedTwoToneIcon style={{textAlign: 'center', fontSize: 50, color: theme.palette.secondary.light}}  />
                                                                                                        :   this.state.value >=1.5
                                                                                                            ?    <SentimentDissatisfiedTwoToneIcon style={{textAlign: 'center', fontSize: 50, color: 'orange' }}  />
                                                                                                            :    <SentimentVeryDissatisfiedTwoToneIcon style={{textAlign: 'center', fontSize: 50, color: theme.palette.themeRed  }}  />
                                                                                            }
                                                                                        </p> 
                                                                                        <StyledSlider
                                                                                            value={this.state.value*5}
                                                                                            min={5}
                                                                                            max={25}
                                                                                            aria-labelledby="label"
                                                                                            onChange={this.handleChangeSlider}
                                                                                            onChangeCommitted={this.handleChangeSlCommit}
                                                                                        />
                                                                                        <p className={classes.measurement}>
                                                                                            {(messages?.[messages?.length-1]?.utteranceClass?.includes('utter_ask_progress'))
                                                                                            ?
                                                                                                this.state.value >=4.5 
                                                                                                ?   <span>{wordsMood["progress"]["5"]}
                                                                                                    </span>
                                                                                                :   this.state.value >=3.5 
                                                                                                    ?   <span> {wordsMood["progress"]["4"]}
                                                                                                        </span> 
                                                                                                    :   this.state.value >=2.5
                                                                                                        ?    <span> {wordsMood["progress"]["3"]}
                                                                                                            </span> 
                                                                                                        :   this.state.value >=1.5
                                                                                                            ?    <span> {wordsMood["progress"]["2"]}
                                                                                                                </span> 
                                                                                                            :    <span>{wordsMood["progress"]["1"]}
                                                                                                                </span>
                                                                                            :
                                                                                                this.state.value >=4.5 
                                                                                                ?   <span> {wordsMood["assessment"]["5"]}
                                                                                                    </span>
                                                                                                :   this.state.value >=3.5 
                                                                                                    ?   <span>{wordsMood["assessment"]["4"]}
                                                                                                        </span> 
                                                                                                    :   this.state.value >=2.5
                                                                                                        ?    <span>{wordsMood["assessment"]["3"]}
                                                                                                            </span> 
                                                                                                        :   this.state.value >=1.5
                                                                                                            ?    <span> {wordsMood["assessment"]["2"]}
                                                                                                                </span> 
                                                                                                            :    <span>{wordsMood["assessment"]["1"]}
                                                                                                                </span>
                                                                                            }
                                                                                        </p> 
                                                                                    </div>
                                                                            :  (this.state.introMessageDone || this.state.skipIntroMessage)
                                                                                ?   ""
                                                                                :  <div>
                                                                                        <ChatBlock 
                                                                                            user={this.context} 
                                                                                            variant={"chatLoading"} 
                                                                                            allowFeedback={false}
                                                                                            allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                            showTextInputHandler={this.showTextInputHandler} 
                                                                                            typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                        />
                                                                                    </div>
                                                                        }
                                                                        {this.quoteRender()}
                                                                        <div id="tutporial" style={{textAlign: 'left', marginTop: 20, marginBottom: 20}}>
                                                                            {false && (this.context.dialogueCounter === undefined || this.context.dialogueCounter < 1) && this.state.typingBotDone 
                                                                                &&
                                                                                    <Button
                                                                                        onClick ={() => {this.setState({handleOpenTutorial: true})}}
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                    >
                                                                                        Open Tutorial
                                                                                        &nbsp; 
                                                                                        <HelpOutlineIcon fontSize='small' style={{ color:  '#e53935' }}/>
                                                                                    </Button>
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                                <div ref={(el) => { this.refMessagesEnd = el; }}>&nbsp;</div>
                                                                <SnackbarInfo 
                                                                    alertText = 'This is your main coaching chat with Rocky. Start with describing your mood. Rocky will ask you new questions each turn and help you to reflect. The chat is usually 6 rounds long.'
                                                                    alertName = 'chatintro'
                                                                    userId = {this.context.uid}
                                                                    />
                                                                {this.dialogShowExplanation(true)}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    else if (this.state.dialogue?.phase === 'running' || this.state.dialogue?.phase === 'session_requested' || (messages?.[messages?.length-1]?.isCoach===true)){
                                                        //console.log('Dialogue normal render: ', this.state.dialogue?.phase, messages)
                                                        return(
                                                            <React.Fragment>
                                                                <Grid item xs={12} style={{minHeight: 200}}>
                                                                    {true || (!(this.state.extraQuestioning) && this.state.guidedChat !== false && (this.context.dialogueCounter > 1) )
                                                                        ?
                                                                            this.renderChatContext()
                                                                        :
                                                                            <Card style={{...theme.CardDialogueButton, backgroundColor: theme.palette.backPrimary, padding: 8}} >
                                                                                <Grid container spacing={2} direction="row"
                                                                                    justify="space-between"
                                                                                    alignItems="center"
                                                                                    >
                                                                                    <Grid item xs={2}>
                                                                                        {this.props.defaultSet?.programLogo?.includes('http')
                                                                                            ?   <ForumIcon style={{height: 60, width: 60}}/>
                                                                                            :   <RockyIcon style={{height: 60, width: 60}} />
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item xs={10}>
                                                                                        <div style={{paddingLeft: 14, paddingRight: 10, marginTop: 0, marginBottom: 0}}>
                                                                                            <FormControlLabel
                                                                                                onClick={() => this.setState({showFeedback: true})}
                                                                                                aria-label="Coaching"
                                                                                                control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                                                                                label= {
                                                                                                    <span style={theme.textSupport}>
                                                                                                        {this.state.showFeedback
                                                                                                            ?   "Answer challenging questions, designed to make you think and express your thoughts in the coach mode."
                                                                                                            :   "Reflective sparring challenge ..."
                                                                                                        }
                                                                                                    </span>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Card>
                                                                    }
                                                                    {(messages!== undefined && messages!== null && messages.length>=1)
                                                                        &&
                                                                            <ChatBlock
                                                                                variant={"chatMain"}
                                                                                user={this.context}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                showAvatar={true}
                                                                                feedbackFeature={true}
                                                                                allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                addSpokenSentenceList={(spokenTextSamples) => {this.setState({spokenTextSamples : spokenTextSamples})}}
                                                                                spokenTextSamples={this.state.spokenTextSamples || []}
                                                                                allowFeedback = {(this.state.messages?.[0].utteranceClass?.[0] !== 'inform_loading') ? true : false}
                                                                                //autoHideHistory = {(this.context.dialogueCounter === undefined || this.context.dialogueCounter < 4) ? true : false}
                                                                                allowInteraction = {!(this.context?.features?.mentormode === false)}
                                                                                autoHideHistory = {this.state.expandedState ? !(this.state.expandedState) : true}
                                                                                expandedState = {(state) => this.setState({expandedState: state})}
                                                                                currentDialogueId={this.state.currentDialogueId}
                                                                                messages={messages}
                                                                                dialogue={this.state.dialogue}
                                                                                showTextInputHandler={this.showTextInputHandler} 
                                                                                currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                                typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                chatResultScore={chatResultScore}
                                                                                showExtraTips={(tabSelection === "Mentor" || this.context?.features === undefined || this.context?.features?.mentormode !== false) ? true : false}
                                                                                filterQuality = {this.state.dialogue?.currentLeadershipQuality?.length > 2 ? this.state.dialogue.currentLeadershipQuality : this.props.user?.currentLeadershipQuality }
                                                                                filterCategory = {this.state.dialogue?.currentCategory?.length > 2 ? this.state.dialogue.currentCategory : this.props.user?.currentCategory}
                                                                                showRecommendations={(this.context?.features === undefined || this.context?.features?.mentormode !== false) ? (this.state.showResponseTipsInChat || false) : false}
                                                                                recommendationSummary = {this.state.responseTipsSummary || undefined}
                                                                                recommendationSelection={this.state.responseRecommendation || undefined}
                                                                                //showContentSelection = {(this.context?.features === undefined || this.context?.features?.mentormode !== false ) ? (this.state.showQuestionTipsInChat || false) : false}
                                                                                //contentSelection={this.state.responseContent || undefined}
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities} 
                                                                                typingDoneHandler={() => {
                                                                                    if(tabSelection === "Coach"){
                                                                                        //console.log('message end')
                                                                                        var dictStateUpdate = {
                                                                                            typingBotDone: true, 
                                                                                            showTextInput: true
                                                                                        };
                                                                                        this.setState(dictStateUpdate);
                                                                                        if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                            //console.log('done message scrooll')
                                                                                            this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                                        }
                                                                                        var latest_userMessage = undefined;
                                                                                        // move this section to another flow in the chat
                                                                                        if((this.context?.features === undefined || this.context?.features?.mentormode !== false) && this.state.showQuestionTipsInChat !== true && this.state.messages.length>=2 && this.state.messages[this.state.messages.length-2] !== undefined && this.state.messages[this.state.messages.length-2].isCoach === false && this.state.messages[this.state.messages.length-1].buttons===undefined){
                                                                                            latest_userMessage = this.state.messages[this.state.messages.length-2];
                                                                                            //console.log('latest_userMessage',latest_userMessage)
                                                                                            if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                                (latest_userMessage.intentClass.includes('question_conclusions'))
                                                                                                && this.state.showQuestionTipsInChat !== true){
                                                                                                    //console.log('set questions tips flag',latest_userMessage)
                                                                                                    //this.searchContentRecommendationsForQuestion(false, false);
                                                                                            }
                                                                                            else if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                                (
                                                                                                    latest_userMessage.intentClass.includes('assessment')
                                                                                                )
                                                                                                && this.state.showQuestionTipsInChat !== true){
                                                                                                    //console.log('set questions tips flag assess',latest_userMessage)
                                                                                                    //this.searchContentRecommendationsForQuestion(true, false);
                                                                                                    //this.setState({showQuestionTipsInChat : true})
                                                                                            }
                                                                                            else if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                                (
                                                                                                    latest_userMessage.intentClass.includes('challenge')
                                                                                                    || latest_userMessage.intentClass.includes('activity')
                                                                                                    || latest_userMessage.intentClass.includes('failure')
                                                                                                    || latest_userMessage.intentClass.includes('indicator')
                                                                                                    //|| latest_userMessage.intentClass.includes('sentiment')
                                                                                                )
                                                                                                && this.state.showQuestionTipsInChat !== true){
                                                                                                    //console.log('set questions tips flag 2',latest_userMessage)
                                                                                                    //this.searchContentRecommendationsForQuestion(false, false);
                                                                                                    //this.setState({showQuestionTipsInChat : true})
                                                                                            }
                                                                                            else{
                                                                                                //this.searchContentRecommendationsForQuestion(false, false);
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                typingMoveOnHandler={() => {
                                                                                    if(tabSelection === "Coach"){
                                                                                        //console.log('message mobe');
                                                                                        if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                            //console.log('message scroll moveon')
                                                                                            //this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'end' });
                                                                                            this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'start' });
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                ///// remove the repeathandler
                                                                                //repeatHandler = {() => {this.onSubmit('Retry Question')}}
                                                                                switchHandler = {
                                                                                    this.state.changeQuestionAvailable > 0
                                                                                    ?   () => {this.onChangeQuestion()}
                                                                                    :   undefined
                                                                                }
                                                                                tipsHandler = {
                                                                                    () => this.handleTipsOpen()
                                                                                }
                                                                                showFavorite = {false}
                                                                                itemsSubmit = {(items) => {
                                                                                    if(items?.length > 1){ 
                                                                                        //console.log('itemsSubmit', items)
                                                                                        this.setState({responseContent: items})
                                                                                    }
                                                                                }}
                                                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                                contentOpened = {(contentId) => {
                                                                                    //this.setState({tabSelection: 'Mentor', waitingForResponseTimeout: true })
                                                                                    this.setState({ waitingForResponseTimeout: true })
                                                                                }}
                                                                            />
                                                                    }
                                                                    {(messages!== undefined && messages!== null && messages.length>=2 && messages[messages.length-1].isCoach===false)
                                                                        ?   
                                                                            <div>
                                                                                <ChatBlock 
                                                                                    user={this.context} 
                                                                                    variant={"chatLoading"}
                                                                                    typingMoveOnHandler={() => {
                                                                                        //console.log('reacted on moveon', tabSelection, this.refMessagesEnd)
                                                                                        if(tabSelection === "Coach"){
                                                                                            if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                                console.log('message scroll moveon')
                                                                                                //this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'end' });
                                                                                                this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'start' });
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    messages={messages}
                                                                                    allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                                                                    showTextInputHandler={this.showTextInputHandler} 
                                                                                    typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                                />
                                                                            </div>
                                                                        :   ""
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} style={{ minHeight: 50, marginTop: 20, textAlign:'right', width:"100%"}}>
                                                                    {(this.state.typingBotDone && this.state.messages[0].utteranceClass?.[0] !== 'inform_loading')
                                                                    //// SO THAT IT WILL EVERTYIME RELOAD WITH NEW DATA
                                                                        ?   <div style={{textAlign: 'right', marginBottom: 10}}>
                                                                                {messages?.[messages.length-1]?.buttons?.length>0 
                                                                                    &&
                                                                                    this.renderQuestionButtons(messages)
                                                                                }
                                                                                {this.renderAutoButtons()}
                                                                                {this.dialogShowExplanation(true)}
                                                                            </div>
                                                                        :   ''
                                                                    }
                                                                    {this.state.typingBotDone
                                                                        && this.dialogShowTips()
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {this.quoteRender()}
                                                                </Grid>
                                                                <div ref={(el) => { this.refMessagesEnd = el; }}>&nbsp;</div>
                                                                {this.state.typingBotDone === true && this.state.dialogue?.phase !== 'session_ended' && this.state.dialogue?.phase !== 'session_requested' &&
                                                                    <SnackbarInfo 
                                                                        alertText = {(this.state.dialogueStagesMessage || '') + ' With every answer, you will increase your reflection score and get better matching learning content!'} 
                                                                        alertName = 'startingChats'
                                                                        userId = {this.context.uid}
                                                                    />
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    else{
                                                        return (
                                                            <React.Fragment>
                                                                <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                    <div >
                                                                        <LoadingProgress 
                                                                            defaultSet={this.props.defaultSet}
                                                                            type={'LoadingPageAnimated'} 
                                                                            loadingText={this.state.firebaseConnectionMessage} 
                                                                            firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} 
                                                                            allowRefresh={true}
                                                                        />
                                                                        <p style={theme.textErrorCenterPadding}>
                                                                            &nbsp; {this.state.firebaseConnectionMessage}
                                                                        </p>
                                                                    </div>
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    })()//end this state dialogue
                                                :   (nonSubscriber && !this.state.firebaseConnectionError ) 
                                                    ?
                                                        <React.Fragment key="NonSub">
                                                            <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                {this.renderChatContext()}
                                                                <ChatBlock
                                                                    variant={"chatMain"}
                                                                    user={this.context}
                                                                    defaultSet={this.props.defaultSet || undefined}
                                                                    showAvatar={true}
                                                                    feedbackFeature={false}
                                                                    hideResponse={true}
                                                                    allowAudio={false} 
                                                                    allowFeedback = {false}
                                                                    allowInteraction = {!(this.context?.features?.mentormode === false)}
                                                                    autoHideHistory = {true}
                                                                    currentDialogueId={this.state.currentDialogueId}
                                                                    messages={messages}
                                                                    dialogue={this.state.dialogue}
                                                                    showTextInputHandler={undefined} 
                                                                    currentExplanation={''}
                                                                    typingBotFinishTypingNow={true}
                                                                    chatResultScore={undefined}
                                                                    showRecommendations={false}
                                                                    recommendationSummary = { undefined}
                                                                    recommendationSelection={undefined}
                                                                    showContentSelection = {false}
                                                                    contentSelection={undefined}
                                                                    leadershipCategories={this.state.leadershipCategories}
                                                                    leadershipQualities={this.state.leadershipQualities} 
                                                                    typingDoneHandler={() => {
                                                                        if(true){
                                                                            //console.log('message end')
                                                                            var dictStateUpdate = {
                                                                                typingBotDone: true, 
                                                                                showTextInput: true
                                                                            };
                                                                            this.setState(dictStateUpdate);
                                                                            if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                //console.log('done message scrooll')
                                                                                this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                            }
                                                                            var latest_userMessage = undefined;
                                                                            // move this section to another flow in the chat
                                                                            if((this.context?.features === undefined || this.context?.features?.mentormode !== false) && this.state.showQuestionTipsInChat !== true && this.state.messages.length>=2 && this.state.messages[this.state.messages.length-2] !== undefined && this.state.messages[this.state.messages.length-2].isCoach === false && this.state.messages[this.state.messages.length-1].buttons===undefined){
                                                                                latest_userMessage = this.state.messages[this.state.messages.length-2];
                                                                                //console.log('latest_userMessage',latest_userMessage)
                                                                                if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                    (latest_userMessage.intentClass.includes('question_conclusions'))
                                                                                    && this.state.showQuestionTipsInChat !== true){
                                                                                        //console.log('set questions2 tips flag',latest_userMessage)
                                                                                        //this.searchContentRecommendationsForQuestion(false, false);
                                                                                }
                                                                                else if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                    (
                                                                                        latest_userMessage.intentClass.includes('assessment')
                                                                                    )
                                                                                    && this.state.showQuestionTipsInChat !== true){
                                                                                        //console.log('set questions tips flag assess',latest_userMessage)
                                                                                        //this.searchContentRecommendationsForQuestion(true, false);
                                                                                        //this.setState({showQuestionTipsInChat : true})
                                                                                }
                                                                                else if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                    (
                                                                                        latest_userMessage.intentClass.includes('challenge')
                                                                                        || latest_userMessage.intentClass.includes('activity')
                                                                                        || latest_userMessage.intentClass.includes('failure')
                                                                                        || latest_userMessage.intentClass.includes('indicator')
                                                                                        //|| latest_userMessage.intentClass.includes('sentiment')
                                                                                    )
                                                                                    && this.state.showQuestionTipsInChat !== true){
                                                                                        //console.log('set questions tips flag 2',latest_userMessage)
                                                                                        //this.searchContentRecommendationsForQuestion(false, false);
                                                                                        //this.setState({showQuestionTipsInChat : true})
                                                                                }
                                                                                else{
                                                                                    //this.searchContentRecommendationsForQuestion(false, false);
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                    typingMoveOnHandler={() => {
                                                                        //console.log('message mobe');
                                                                        if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                            //console.log('message scroll moveon')
                                                                            //this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'end' });
                                                                            //this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'start' });
                                                                        }
                                                                    }}
                                                                    showFavorite = {false}
                                                                />
                                                                <p style={{...theme.textSupportCenter, marginTop: 30}}>
                                                                    You reached your daily free coaching limit!
                                                                    <br/>You can subscribe or come back tomorrow to get more free mentoring.
                                                                </p>
                                                                <p className={classes.voteIcons}>
                                                                    <Button
                                                                        onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                                                        variant='contained'
                                                                        >
                                                                        Unlock All
                                                                    </Button>
                                                                </p>
                                                                {this.context.ratedApp!== true && window.CdvPurchase !== undefined &&
                                                                    <p style={theme.textSupportCenter}>
                                                                        or
                                                                    </p>
                                                                }
                                                                {this.context.ratedApp !== true && window.CdvPurchase !== undefined &&
                                                                    <p className={classes.voteIcons}>
                                                                        <AppRating 
                                                                            buttonText="Recommend this app" 
                                                                            freeDialoguesRequest={true}
                                                                            feedbackEvent={() => this.setState({feedbackOpen: true})}
                                                                            user={this.context} 
                                                                            dialogueId = {this.state.currentDialogueId || ''}
                                                                            db={this.props.firebase}
                                                                            purchaseSuccessHandler={() => this.setState({nonSubscriber: false, missingSubscription: false})}
                                                                            />
                                                                    </p>
                                                                }
                                                                {this.renderSubscriptionFree()}
                                                                {(this.context.dialogueCounter === undefined || this.context.dialogueCounter <4) &&
                                                                    <Task4Chats
                                                                        noMargin={true}
                                                                        user={this.context}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities}
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                }
                                                                {this.context?.features?.showChallenges && (this.context.dialogueCounter >= 4 && this.context.dialogueCounter <= 30) &&
                                                                    <Task4Topics
                                                                        noMargin={true}
                                                                        loadStats={true}
                                                                        user={this.context}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities}
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                }
                                                                {this.context.dialogueCounter > 30 &&
                                                                    <MindfulnessScore 
                                                                        dialogue={this.state.dialogue}
                                                                        showIdeas={false}
                                                                        showPhase={false}
                                                                        showSentiments={false}
                                                                        user={this.context}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities}
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                    />
                                                                }
                                                                <p className={classes.voteIcons}>
                                                                    {/*** 
                                                                    <Button
                                                                        onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                                                        variant='contained'
                                                                        >
                                                                        Unlock All
                                                                    </Button>
                                                                    */}
                                                                    <Button
                                                                        key='finishnow'
                                                                        onClick={() => {
                                                                            this.setState({speedDialOpen : false});
                                                                            this.saveFeedbackAndEnd(true);
                                                                        }}
                                                                        >
                                                                            Show Results &nbsp;
                                                                            <OfflinePinIcon fontSize='small'/>
                                                                    </Button>
                                                                </p>
                                                            </Grid>
                                                        </React.Fragment>
                                                    :
                                                        ''
                                                }
                                            </React.Fragment>
                                        </div>
                                        <div value={"Goals"} index={3} hidden={tabSelection !== "Goals" || flagChatFinished} style={{width: '100%'}}>
                                            {(tabSelection === 'Goals' || this.state['Goals']) &&
                                                <React.Fragment key="Goals">
                                                    <Card style={{...theme.CardDialogueButton, backgroundColor: theme.palette.backPrimary, padding: 8}} >
                                                        <Grid container spacing={2} direction="row"
                                                            justify="space-between"
                                                            alignItems="center"
                                                            >
                                                            <Grid item xs={2}>
                                                                <AssignmentTurnedInIcon style={{height: 60, width: 60}}/>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <div style={{paddingLeft: 14, paddingRight: 10, marginTop: 0, marginBottom: 0}}>
                                                                    <FormControlLabel
                                                                        onClick={() => this.setState({showFeedback: true})}
                                                                        aria-label="Inspiration"
                                                                        control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                                                        label= {
                                                                            <span style={theme.textSupport}>
                                                                                {this.state.showFeedback
                                                                                    ?   "To manage a goal card, click on it or swipe it to quickly update it. Add a new goal in the textbox."
                                                                                    :   "Add, track and manage your goals"
                                                                                }
                                                                            </span>
                                                                        }
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                    <div ref={(el) => { this.refHabitsStart = el; }}></div>
                                                    {(messages!== undefined && messages!== null && messages.length>=2 && messages[messages.length-1].isCoach===false)
                                                        ?
                                                            <React.Fragment>
                                                                <ChatBlock 
                                                                    user={this.context} 
                                                                    variant={"chatLoading"} 
                                                                    allowAudio={false}
                                                                />
                                                            </React.Fragment>
                                                        :   
                                                            this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined
                                                            ?
                                                                <Grid item xs={12} style={{  paddingTop: 40, width: "100%"}}>
                                                                    <JournalActivities 
                                                                        user={this.context}
                                                                        defaultSet={this.props.defaultSet || undefined}
                                                                        checkMode={true}
                                                                        style={{paddingLeft:0, paddingRight:0}}
                                                                        groupItems={true}
                                                                        reflectionMode={true}
                                                                        hideDone={true}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities} 
                                                                        historyLimit={20}
                                                                        showEmptyMessage={true}
                                                                        visualLimit={3}
                                                                        handlerMoment={(moment) => {
                                                                            this.setState({animateMomentByName: moment});
                                                                            if(this.refHabitsStart !== null && this.refHabitsStart !== undefined){
                                                                                //console.log('habits moment scrooll')
                                                                                this.refHabitsStart.scrollIntoView({ behavior: "auto", block: 'start' });
                                                                            }
                                                                        }}
                                                                        messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                        tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                        />
                                                                </Grid>
                                                            : ""
                                                    }
                                                    {this.quoteRender()}
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div value={"Mentor"} index={2} hidden={flagChatFinished || tabSelection !== "Mentor"} style={{width: '100%'}}>
                                            {(tabSelection === 'Mentor' || this.state['Mentor']) &&
                                                <React.Fragment key="Mentor">
                                                    <Card style={{...theme.CardDialogueButton, backgroundColor: theme.palette.backPrimary, padding: 8}} >
                                                        <Grid container spacing={2} direction="row"
                                                            justify="space-between"
                                                            alignItems="center"
                                                            >
                                                            <Grid item xs={2}>
                                                                <WidgetsIcon style={{height: 60, width: 60}}/>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <div style={{paddingLeft: 14, paddingRight: 10, marginTop: 0, marginBottom: 0}}>
                                                                    <FormControlLabel
                                                                        onClick={() => this.setState({showFeedback: true})}
                                                                        aria-label="Inspiration"
                                                                        control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                                                        label= {
                                                                            <span style={theme.textSupport}>
                                                                                {true //this.state.showFeedback
                                                                                    ?   "You ask the questions in the mentor mode. Responses will be more detailed and longer."
                                                                                    :   "Get answers to your questions ..."
                                                                                }
                                                                            </span>
                                                                        }
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                    <DialogContentItem
                                                        user={this.context}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                        open={this.state.DialogContentPreviewOpen || false}
                                                        handleClose={() => this.setState({DialogContentPreviewOpen : false})}
                                                        specialQuery={'contentByIds'}
                                                        jumpToComment={this.state.openContentType === 'comment' || false}
                                                        jumpToRead={this.state.openContentType === 'read' || false}
                                                        contentData={this.state.contentData || undefined}
                                                        contentId = {this.state.contentData?.id}
                                                        leadershipCategories={this.state.leadershipCategories}
                                                        leadershipQualities={this.state.leadershipQualities}
                                                        messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                        closeAfterActionAdded = {true}
                                                    />
                                                    {(messages!== undefined && messages!== null && messages.length>=1)
                                                        &&
                                                        <React.Fragment>
                                                            {(nonSubscriber && !this.state.firebaseConnectionError ) 
                                                            ?
                                                                <Grid item xs={12} style={{ minHeight: 200 }}>
                                                                    {/*** <ChatBlock  
                                                                        variant={"chatLastTwoMessages"}
                                                                        user={this.context}
                                                                        noAnimation={true}
                                                                        //allowAudio={false}
                                                                        allowAudio={this.state.tabSelection === 'Mentor' && (!this.state.mute || true)} 
                                                                        currentDialogueId={this.state.currentDialogueId}
                                                                        messages={this.state.messages || undefined} 
                                                                        typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                    />
                                                                    **/}
                                                                    <ChatBlock
                                                                        variant={"chatMain"}
                                                                        user={this.context}
                                                                        defaultSet={this.props.defaultSet || undefined}
                                                                        showAvatar={true}
                                                                        feedbackFeature={false}
                                                                        hideResponse={true}
                                                                        allowAudio={false} 
                                                                        allowFeedback = {false}
                                                                        allowInteraction = {!(this.context?.features?.mentormode === false)}
                                                                        autoHideHistory = {true}
                                                                        currentDialogueId={this.state.currentDialogueId}
                                                                        messages={messages}
                                                                        dialogue={this.state.dialogue}
                                                                        showTextInputHandler={undefined} 
                                                                        currentExplanation={''}
                                                                        typingBotFinishTypingNow={true}
                                                                        chatResultScore={undefined}
                                                                        showRecommendations={false}
                                                                        recommendationSummary = { undefined}
                                                                        recommendationSelection={undefined}
                                                                        showContentSelection = {false}
                                                                        contentSelection={undefined}
                                                                        leadershipCategories={this.state.leadershipCategories}
                                                                        leadershipQualities={this.state.leadershipQualities} 
                                                                        typingDoneHandler={() => {
                                                                            if(true){
                                                                                //console.log('message end')
                                                                                var dictStateUpdate = {
                                                                                    typingBotDone: true, 
                                                                                    showTextInput: true
                                                                                };
                                                                                this.setState(dictStateUpdate);
                                                                                if(this.state.typingBotFinishTypingNow !== true && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                    //console.log('done message scrooll')
                                                                                    this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                                }
                                                                                var latest_userMessage = undefined;
                                                                                // move this section to another flow in the chat
                                                                                if((this.context?.features === undefined || this.context?.features?.mentormode !== false) && this.state.showQuestionTipsInChat !== true && this.state.messages.length>=2 && this.state.messages[this.state.messages.length-2] !== undefined && this.state.messages[this.state.messages.length-2].isCoach === false && this.state.messages[this.state.messages.length-1].buttons===undefined){
                                                                                    latest_userMessage = this.state.messages[this.state.messages.length-2];
                                                                                    //console.log('latest_userMessage',latest_userMessage)
                                                                                    if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                        (latest_userMessage.intentClass.includes('question_conclusions'))
                                                                                        && this.state.showQuestionTipsInChat !== true){
                                                                                            //console.log('set questions3 tips flag',latest_userMessage)
                                                                                            //this.searchContentRecommendationsForQuestion(false, false);
                                                                                    }
                                                                                    else if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                        (
                                                                                            latest_userMessage.intentClass.includes('assessment')
                                                                                        )
                                                                                        && this.state.showQuestionTipsInChat !== true){
                                                                                            //console.log('set questions tips flag assess',latest_userMessage)
                                                                                            //this.searchContentRecommendationsForQuestion(true, false);
                                                                                            //this.setState({showQuestionTipsInChat : true})
                                                                                    }
                                                                                    else if(latest_userMessage!== undefined && latest_userMessage.intentClass!== undefined &&
                                                                                        (
                                                                                            latest_userMessage.intentClass.includes('challenge')
                                                                                            || latest_userMessage.intentClass.includes('activity')
                                                                                            || latest_userMessage.intentClass.includes('failure')
                                                                                            || latest_userMessage.intentClass.includes('indicator')
                                                                                            //|| latest_userMessage.intentClass.includes('sentiment')
                                                                                        )
                                                                                        && this.state.showQuestionTipsInChat !== true){
                                                                                            //console.log('set questions tips flag 2',latest_userMessage)
                                                                                            //this.searchContentRecommendationsForQuestion(false, false);
                                                                                            //this.setState({showQuestionTipsInChat : true})
                                                                                    }
                                                                                    else{
                                                                                       //this.searchContentRecommendationsForQuestion(false, false);
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                        showFavorite = {false}
                                                                    />
                                                                    <p style={{...theme.textSupportCenter, marginTop: 30}}>
                                                                        You reached your daily free mentoring limit!
                                                                        <br/>You can subscribe or come back tomorrow to get more AI mentoring.
                                                                    </p>
                                                                    <p className={classes.voteIcons}>
                                                                        <Button
                                                                            onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                                                            variant='contained'
                                                                            >
                                                                            Unlock All
                                                                        </Button>
                                                                    </p>
                                                                    {this.context.ratedApp!== true && window.CdvPurchase !== undefined &&
                                                                        <p style={theme.textSupportCenter}>
                                                                            or
                                                                        </p>
                                                                    }
                                                                    {this.context.ratedApp !== true && window.CdvPurchase !== undefined &&
                                                                        <p className={classes.voteIcons}>
                                                                            <AppRating 
                                                                                buttonText="Recommend this app"
                                                                                feedbackEvent={() => this.setState({feedbackOpen: true})}
                                                                                freeDialoguesRequest={true} 
                                                                                user={this.context} 
                                                                                dialogueId = {this.state.currentDialogueId || ''}
                                                                                db={this.props.firebase}
                                                                                purchaseSuccessHandler={() => this.setState({nonSubscriber: false, missingSubscription: false})}
                                                                                />
                                                                        </p>
                                                                    }
                                                                    <p style={theme.textSupportCenter}>
                                                                        or
                                                                    </p>
                                                                    <p className={classes.voteIcons}>
                                                                        <Button
                                                                            key='finishnow'
                                                                            onClick={() => {
                                                                                this.setState({speedDialOpen : false});
                                                                                this.saveFeedbackAndEnd(true);
                                                                            }}
                                                                            >
                                                                                Show Results &nbsp;
                                                                                <OfflinePinIcon fontSize='small'/>
                                                                        </Button>
                                                                    </p>
                                                                </Grid>
                                                            : 
                                                                <React.Fragment>
                                                                    <VizSensor 
                                                                        key={'mentortext_visible'}
                                                                        partialVisibility={true}
                                                                        onChange={(isVisible) => { 
                                                                            //console.log('onchange viz', isVisible, this.state.mentortextVisible)
                                                                            if(this._isMounted && this.state.mentortextVisible !== isVisible) { 
                                                                                if(this.state.typingBotDone === true){
                                                                                    this.setState({mentortextVisible: true});
                                                                                }
                                                                                else{
                                                                                    //console.log('onflag viz', isVisible)
                                                                                    this.setState({mentortextVisible: isVisible});
                                                                                }
                                                                            }}
                                                                        }
                                                                    >
                                                                        <ChatBlock
                                                                            variant={"chatMain"}
                                                                            user={this.context}
                                                                            defaultSet={this.props.defaultSet || undefined}
                                                                            showAvatar={true}
                                                                            feedbackFeature={false}
                                                                            allowAudio={this.state.tabSelection === 'Mentor' && (!this.state.mute || true)} 
                                                                            addSpokenSentenceList={(spokenTextSamples) => {this.setState({spokenTextSamples : spokenTextSamples})}}
                                                                            spokenTextSamples={this.state.spokenTextSamples || []}
                                                                            allowFeedback = {false}
                                                                            allowInteraction = {!(this.context?.features?.mentormode === false)}
                                                                            autoHideHistory = {true}
                                                                            currentDialogueId={this.state.currentDialogueId}
                                                                            messages={messages}
                                                                            dialogue={this.state.dialogue}
                                                                            showTextInputHandler={this.showTextInputHandler} 
                                                                            currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                                                            typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                                                            chatResultScore={undefined}
                                                                            showExtraTips={(tabSelection === "Mentor" || this.context?.features === undefined || this.context?.features?.mentormode !== false) ? true : false}
                                                                            filterQuality = {this.state.dialogue?.currentLeadershipQuality?.length > 2 ? this.state.dialogue.currentLeadershipQuality : this.props.user?.currentLeadershipQuality }
                                                                            filterCategory = {this.state.dialogue?.currentCategory?.length > 2 ? this.state.dialogue.currentCategory : this.props.user?.currentCategory}
                                                                            showRecommendations={(this.context?.features === undefined || this.context?.features?.mentormode !== false) ? (this.state.showResponseTipsInChat || false) : false}
                                                                            recommendationSummary = {this.state.responseTipsSummary || undefined}
                                                                            recommendationSelection={this.state.responseRecommendation || undefined}
                                                                            showContentSelection = {false}
                                                                            contentSelection={ undefined}
                                                                            leadershipCategories={this.state.leadershipCategories}
                                                                            leadershipQualities={this.state.leadershipQualities} 
                                                                            typingDoneHandler={() => {
                                                                                if(tabSelection === "Mentor"){
                                                                                    //console.log('message end')
                                                                                    var dictStateUpdate = {
                                                                                        typingBotDone: true, 
                                                                                        showTextInput: true
                                                                                    };
                                                                                    this.setState(dictStateUpdate);
                                                                                    if(this.state.typingBotDone !== true && this.refTipsStart !== null && this.refTipsStart !== undefined){
                                                                                        //console.log('done message scrooll')
                                                                                        //this.refTipsStart.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                                    }
                                                                                }
                                                                            }}
                                                                            typingMoveOnHandler={() => {
                                                                                if(tabSelection === "Mentor"){
                                                                                    //console.log('message mobe', this.state.spinner);
                                                                                    if(this.state.spinner !== true && this.state.typingMoveOnInterruptOpen !== true){
                                                                                        ////// THIS FUNCTIONS ALLOWS THO SHOW FOR INFINITE SCROLL TO END TEXT ANIMATION
                                                                                        this.setState({typingMoveOnInterruptOpen: true});
                                                                                        //console.log('message moveactive', this.state.spinner);
                                                                                    }
                                                                                    if(this.state.typingBotFinishTypingNow !== true && this.refTipsStart !== null && this.refTipsStart !== undefined){
                                                                                        //console.log('message scroll moveon')
                                                                                        //this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'end' });
                                                                                        this.refTipsStart.scrollIntoView({ behavior: "smooth", block: 'start' });
                                                                                    }
                                                                                }
                                                                            }}
                                                                            switchHandler = {
                                                                                this.state.changeQuestionAvailable > 0
                                                                                ?   () => {this.onChangeQuestion()}
                                                                                :   undefined
                                                                            }
                                                                            showFavorite = {false}
                                                                            messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                            contentOpened = {(contentId) => {
                                                                                //this.setState({tabSelection: 'Mentor', waitingForResponseTimeout: true })
                                                                                this.setState({ waitingForResponseTimeout: true })
                                                                            }}
                                                                        />
                                                                    </VizSensor>
                                                                    {this.state.typingBotDone
                                                                        //// SO THAT IT WILL EVERTYIME RELOAD WITH NEW DATA
                                                                        ? (messages?.length>0 && messages?.[messages.length-1]?.isCoach===true && messages?.[messages.length-1]?.buttons?.length>0)
                                                                            ?   
                                                                                <div style={{textAlign: 'right'}}>
                                                                                    {this.renderQuestionButtons()}
                                                                                </div>
                                                                            :   ''
                                                                        :   ""
                                                                    }
                                                                    {this.state.typingBotDone &&
                                                                        <div style={{textAlign: 'right'}}>
                                                                            {this.renderAutoButtons()}
                                                                        </div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    <div style={{position: 'relative'}}>
                                                        <div ref={(el) => { this.refMentorMiddle= el; }} style={{position: 'absolute', top: '50vh'}}></div>
                                                    </div>
                                                    <div style={{position: 'relative'}}>
                                                        <div ref={(el) => { this.refTipsStart = el; }} style={{position: 'absolute', top: '-50vh'}}></div>
                                                    </div>
                                                    {(this.state.suggestionLoading 
                                                        || (messages!== undefined && messages!== null && messages.length>=2 && messages[messages.length-1].isCoach===false)
                                                    )
                                                        ?   
                                                            <React.Fragment>
                                                                <ChatBlock 
                                                                    user={this.context} 
                                                                    variant={"chatLoading"} 
                                                                    allowAudio={false}
                                                                />
                                                            </React.Fragment>
                                                        :   this.state.typingBotDone
                                                            ?   this.state.dialogue?.contentSelection?.length > 0
                                                                ?   
                                                                    <div value={'scroll'} key={'scroll'}>
                                                                        <div style={{marginTop: 20, minHeight: '30vh', position: 'relative'}}>
                                                                            <HomeCardContentItems
                                                                                user={this.context}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                visualLimit={5}
                                                                                raised = {true}
                                                                                specialQuery={'contentByIds'}
                                                                                direction={'column'}
                                                                                titleOfGroup={"General Tips"}
                                                                                allowUpdate={false}
                                                                                qualityFilter = {this.state.dialogue?.contentSelection?.map(a => a.contentId)}
                                                                                swipe={true}
                                                                                linkToContent={false}
                                                                                messageSubmit = {(text) => {
                                                                                    this.submitInspiration(text);
                                                                                }}
                                                                                messageSubmitFull = {(text) => {
                                                                                    ///// SEND IT DIRECTLY
                                                                                    this.onSubmit(text);
                                                                                }}
                                                                                closeAfterActionAdded={true}
                                                                                //qualityFilter = {[this.state.dialogue?.contentSelection[0].contentId]}
                                                                                //qualityFilter = {[this.state.dialogue?.contentSelection[0].contentId, this.state.dialogue?.contentSelection[1].contentId]}
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities}
                                                                                allQualitiesLoaded = { [] }
                                                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                                                                //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                                                                            />
                                                                        </div>
                                                                        <div style={{textAlign: 'right', paddingTop: 20}}>
                                                                            {this.renderAutoButtons()}
                                                                        </div>
                                                                        <div style={{marginBottom: 40}}>

                                                                        </div>
                                                                    </div>
                                                                :
                                                                    <div value={'scroll'} key={'scroll'}>
                                                                        <div style={{marginTop: 20, minHeight: '80vh', position: 'relative'}}>
                                                                            <p style={{paddingLeft: 0}}><WidgetsIcon style={theme.inlineIconLeft}/>Mentoring & Tips</p>
                                                                            <InfiniteContent 
                                                                                user = {this.context}
                                                                                showMinimal = {true}
                                                                                screenWidth = {IsMobileDevice() ? 300 : 800}
                                                                                defaultSet={this.props.defaultSet || undefined}
                                                                                scrollIntoViewDisabled = {true}
                                                                                dialogRefParent = {this.refMentorMiddle !== undefined
                                                                                    ?   () => {
                                                                                            //console.log('habits message scrooll')
                                                                                            this.refMentorMiddle.scrollIntoView({ behavior: "smooth", block: 'end' });
                                                                                        }
                                                                                    :   undefined
                                                                                }
                                                                                visualLimit = {nonSubscriber ? 2 : 6}
                                                                                raised = {true}
                                                                                showActions = {true}
                                                                                messageSubmit = {(text) => {
                                                                                    this.submitInspiration(text);
                                                                                }}
                                                                                messageSubmitFull = {(text) => {
                                                                                    ///// SEND IT DIRECTLY
                                                                                    this.onSubmit(text);
                                                                                }}
                                                                                closeAfterActionAdded={true}
                                                                                scrollEvent = {(scrollEvent) => {
                                                                                    if(this.state.mentortextVisible === false && (tabSelection === "Mentor")){
                                                                                        //console.log('scroll event', this.state.typingMoveOnInterruptOpen, this.state.spinner, this.state.typingBotDone, this.state.typingBotFinishTypingNow )
                                                                                        if(this.state.typingMoveOnInterruptOpen && this.state.typingBotDone === false && this.state.spinner !== true && this.state.typingBotFinishTypingNow !== true){
                                                                                            this.setState({typingBotFinishTypingNow: true, typingMoveOnInterruptOpen: false});
                                                                                            //console.log('scroll event pushed')
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                handleOpen = {(contentData, type) => this.setState({contentData, DialogContentPreviewOpen: true, openContentType: type})}
                                                                                resultOfContentSearch = {
                                                                                    this.state.responseContent?.length > 0 
                                                                                        ?   this.state.responseContent.map(a => a.contentId)
                                                                                        :   this.state.responseRecommendation?.length > 0
                                                                                            ?   this.state.responseRecommendation.map(a => a.contentId)
                                                                                            :   this.state.dialogue?.contentSelection?.length > 0 
                                                                                                ?   this.state.dialogue.contentSelection.map(a => a.contentId)
                                                                                                :   undefined
                                                                                    }
                                                                                filterQuality = {
                                                                                    (this.state.responseContent?.length > 0 || this.state.responseRecommendation?.length > 0 || this.state.dialogue?.contentSelection?.length > 0 )
                                                                                        ?   undefined
                                                                                        :   this.context.currentLeadershipQuality 
                                                                                    }
                                                                                leadershipCategories={this.state.leadershipCategories}
                                                                                leadershipQualities={this.state.leadershipQualities}
                                                                                rerender={tabSelection !== "Mentor"? false : true}
                                                                            />
                                                                        </div>
                                                                        {/**
                                                                        <p className={classes.voteIcons}>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    this.setState({tabSelection: 'Coach'})
                                                                                    this.onSubmit("Next!");
                                                                                }}
                                                                                //variant='contained'
                                                                                >
                                                                                Continue Coach Mode
                                                                            </Button>
                                                                        </p>
                                                                        */}
                                                                        <div style={{textAlign: 'right'}}>
                                                                            {this.renderAutoButtons()}
                                                                        </div>
                                                                        {false && nonSubscriber &&
                                                                            <p className={classes.voteIcons}>
                                                                                <Button
                                                                                    onClick={() => this.setState({DialogSettingPlanOpen : true})}
                                                                                    variant='contained'
                                                                                    >
                                                                                    Unlock All
                                                                                </Button>
                                                                            </p>
                                                                        }
                                                                        <div style={{marginBottom: 40}}>

                                                                        </div>
                                                                    </div>
                                                            :   ''
                                                    }
                                                    {this.quoteRender()}
                                                    <div ref={(el) => { this.refMessagesEndMentor = el; }}>&nbsp;</div>
                                                    {this.dialogShowExplanation(true)}
                                                </React.Fragment>
                                            }
                                        </div>
                                    </>
                            }
                            <Grid item xs={12}>
                                <div className={classes.gridPadding}>&nbsp;</div>
                            </Grid>
                        </Grid>
                        {(true || !this.state.firebaseConnectionError )&&
                            this.textInputUserChatElement(nonSubscriber, chatResultScore)
                        }
                        <div style={{height:40}}>&nbsp;</div>
                    </Grid>
                    {
                        this.dialogShowFeedback()
                    }
                    {
                        this.dialogShowSettings()
                    }
                </Grid>
            </div>
        );
    }

    renderSubscriptionFree(){
        const {theme} = this.props;
        if(this.context.programs?.includes('default') && this.context.hidePublicContent!==true && ((this.context.ratedApp === true) || (window.CdvPurchase === undefined) || (process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER"))
            // && this.context.programs !== undefined && this.context.programs.includes('default') 
        )
        return(
            <>
                <p style={theme.textSupportCenter}>
                    or
                </p>
                <p style={theme.textSupportCenter}>
                    Choose a subscription-free module
                </p>
                <div>
                    {this.state.leadershipCategories!==undefined && this.state.leadershipCategories!==null
                        && this.state.leadershipQualities!==undefined && this.state.leadershipQualities!==null
                        &&
                        <TopicSuggestions 
                            user={this.context}
                            defaultSet={this.props.defaultSet || undefined}
                            leadershipCategories={this.state.leadershipCategories}
                            leadershipQualities={this.state.leadershipQualities}
                            handlerContinue={() => {
                                this.setState({nonSubscriber: false});
                                this.setMute(true);
                            }} 
                            allwaysFree1={freeTopics[0]}
                            allwaysFree2={freeTopics[1]}
                        />
                    }
                </div>
            </>
        )
    }

    quoteGenerator() {
        if(this._isMounted && this.state.stillLoadingQuoteFlag === true){
            this.setState({stillLoadingQuoteFlag: false});
        }
        var itemListQuotes = [];
        var oldQuotesList = this.state.oldQuotesList || [];
        var text = '';
        var title = '';
        var source = '';
        var defaultQuote = {
            text: this.context.achievementGoal,
            title: this.context.achievementSelf?.text?.length> 0 ? "Future-Self" : "North Star Goal",
            source: this.context.achievementSelf?.text || '',
        }
        try{
            if(this.state.dialogue?.contentSelection?.[0]?.explanation?.length > 0){
                itemListQuotes = this.state.dialogue.contentSelection[0].explanation;
                title = this.state.dialogue.contentSelection[0].title;
                if(this.state.dialogue?.contentSelection?.[0]?.source?.length > 2){
                    source = this.state.dialogue.contentSelection[0].source;
                }
                itemListQuotes = itemListQuotes.filter(val => !oldQuotesList.includes(val));
                if(itemListQuotes.length> 0){
                    text = itemListQuotes[Math.floor(Math.random() * itemListQuotes.length)];
                    oldQuotesList.push(text);
                    this.setState({
                        dynamicQuote: {text, title, source},
                        oldQuotesList
                    })
                }
                else if(this.state.dialogue?.contentSelection?.[1]?.explanation?.length > 0){
                    console.log('going into the second dimension')
                    itemListQuotes = this.state.dialogue.contentSelection[0].explanation;
                    title = this.state.dialogue.contentSelection[1].title;
                    if(this.state.dialogue?.contentSelection?.[1]?.source?.length > 2){
                        source = this.state.dialogue.contentSelection[1].source;
                    }
                    itemListQuotes = itemListQuotes.filter(val => !oldQuotesList.includes(val));
                    if(itemListQuotes.length> 0){
                        text = itemListQuotes[Math.floor(Math.random() * itemListQuotes.length)];
                        oldQuotesList.push(text);
                        this.setState({
                            dynamicQuote: {text, title, source},
                            oldQuotesList
                        })
                    }
                    else{
                        this.setState({dynamicQuote: defaultQuote, oldQuotesList})
                    }
                }
                else{
                    this.setState({dynamicQuote: defaultQuote, oldQuotesList})
                }
                //console.log('itemListQuotes', itemListQuotes)
            }
            else{
                this.setState({dynamicQuote: defaultQuote, oldQuotesList})
            }
        }
        catch (e){
            this.setState({dynamicQuote: defaultQuote})
        }
    }

    quoteRender() {
        const {theme} = this.props;
        var quote = this.state.dynamicQuote || {};
        //console.log(quote?.text?.length > 0, !(this.state.showTextInput), (this.context?.features?.mentormode))
        var stillLoadingQuoteFlag = this.state.stillLoadingQuoteFlag || false;
        if((this.state.messages?.length>=2 && this.state.messages[this.state.messages.length-1].isCoach === false)){
            stillLoadingQuoteFlag = true;
            if(this._isMounted && this.state.stillLoadingQuoteFlag !== true && this.state.showTextInput){
                console.log('ipdate stillLoadingQuoteFlag')
                this.setState({stillLoadingQuoteFlag: true});
            }
        }
        if(this._isMounted && quote?.text?.length > 0 && !(this.context?.features?.mentormode === false)
            ///// this option shows the text for some sesconds guarnateed
            //&& (!(this.state.showTextInput) || stillLoadingQuoteFlag)
            ///// this option shows the text only after some seconds of loading
            && (stillLoadingQuoteFlag && (this.state.showTextInput))
            ){
            return(
                <div style={{width: '100%', marginTop: 20, textAlign: 'center'}}>
                    <q style={{ width: '100%', color: theme.palette.themeText}}>
                        <strong>{quote.text}</strong>
                    </q>
                    <p style={{textAlign: 'right', paddingTop: 20, paddingRight: 0, color: theme.palette.themeText }}>
                        {quote.title}
                        {quote?.title?.length > 1 && quote?.source?.length > 1 ? ' | ': ''}
                        {quote.source}
                    </p>
                </div>
            )
        }
    }

    getChatResultScore(){
        var chatResultScore = 0.0;
        var chatResultKeys = 0;
        var chatResultActions = 0;
        if (this.state.dialogue!== undefined && this.state.dialogue!== null && 'wordCollection' in this.state.dialogue) {
            if ('keys' in this.state.dialogue?.wordCollection) {
                chatResultKeys =  Object.keys(this.state.dialogue?.wordCollection.keys).length;
                chatResultActions = Object.keys(this.state.dialogue?.wordCollection.actions).length;
            }
            chatResultScore = chatResultKeys*0.1 + chatResultActions*0.1;
        }
        if( chatResultScore > 10) {
            chatResultScore = 9;
        }
        else if(this.state.dialogue?.activities?.phase === 'ended' && chatResultScore < 7.5 ){
            chatResultScore = 7.5;
        }
        else if(this.state.dialogue?.awareness?.phase === 'ended' && chatResultScore < 5 ){
            chatResultScore = 5;
        }
        else if(this.state.dialogue?.mindset?.phase === 'ended' && chatResultScore < 3 ){
            chatResultScore = 3;
        }
        else if(this.state.dialogue?.mindset?.phase === 'started' && chatResultScore < 1 ){
            chatResultScore = 1.5;
        }
        return chatResultScore;
    }

    renderLoading(){
        const { classes, theme } = this.props;
        //console.log('renderLoading', this.state)
        return(
            <React.Fragment>
                <Grid item xs={12} style={{ minHeight: 200}}>
                    <div className={classes.loading}>
                    <LoadingProgress 
                        defaultSet={this.props.defaultSet}
                        type={'LoadingPageAnimated'} 
                        loadingText={this.state.dialogue!== undefined && this.state.dialogue!== null && (this.state.dialogue?.phase === 'session_ended' || this.state.dialogue?.phase === 'session_ended_earlier' || this.state.dialogue?.phase === 'feedback')
                            ? "thinking"
                            : "getting ready"
                        } 
                        firebaseConnectionSpeed={this.state.firebaseConnectionSpeed}
                        allowRefresh={this.state.tabSelection === 'Coach'}
                        refreshAction={() => this.request()}
                    />
                    </div>
                    { (!window.navigator.onLine || this.state.firebaseConnectionError) && 
                        <div >
                            <p style={theme.textErrorCenterPadding}>
                            &nbsp; {this.state.firebaseConnectionMessage}
                            </p> 

                        </div>
                    }
                    <div style={{height: "100%"}}>
                        &nbsp;
                    </div>
                </Grid>
            </React.Fragment>
        )
    }

    renderButtonSimpleIntro(){
        const { theme } = this.props;
        return(
            <React.Fragment>
                <Grid item xs={12} style={{ minHeight: 200 }}>
                    {this.state.introMessages?.length>0 
                        ?
                        <>
                            <ChatBlock
                                user={this.context}
                                defaultSet={this.props.defaultSet || undefined}
                                showAvatar={true}
                                allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                variant={"chatIntro"}
                                currentDialogueId={this.state.currentDialogueId}
                                messages={this.state.introMessages}
                                currentExplanation={this.state.dialogue?.lastTitle?.length > 10 ? "" : (this.state.dialogue?.currentExplanation || '')}
                                //showTextInputHandler={this.showTextInputHandler} 
                                typingBotFinishTypingNow={
                                    this.state.introMessageDone 
                                        ?   true 
                                        :   this.context.features?.fastmode 
                                            ?   this.context.features.fastmode
                                            :   this.state.typingBotFinishTypingNow 
                                                ?   this.state.typingBotFinishTypingNow
                                                :   false
                                    }
                                typingDoneHandler={() => {
                                    this.setState({showTopicOptions: true, showTextInput: true});
                                    //console.log('message end, ', this.state.typingBotFinishTypingNow, this.context.muteAudio)
                                    setTimeout(() => {
                                        this.setState({introMessageDone: true});
                                    }, (this.state.typingBotFinishTypingNow || this.context.muteAudio || this.state.mute)
                                        ?   100 
                                        :   200
                                    );
                                    if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                        //console.log('message scrooll')
                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                                typingMoveOnHandler={() => {
                                    //console.log('message move')
                                    if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                        //console.log('message scrooll')
                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                            />
                            {this.state.dialogue?.lastTitle?.length > 10
                                && 
                                    <div style={{marginLeft: 10}}>
                                        <FormControlLabel
                                            onClick={() => this.setState({showLastTime: true})}
                                            aria-label="Inspiration"
                                            control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                            label= {
                                                <span style={theme.textSupport}>
                                                    Last time we talked about:&nbsp;{!this.state.showLastTime && this.state.dialogue?.lastTitle?.length > 65 ? this.state.dialogue.lastTitle.substring(0, 62) + "..." : this.state.dialogue.lastTitle}
                                                </span>
                                            }
                                        />
                                    </div>
                            }
                        </>
                        :
                            <div>
                                <ChatBlock 
                                    user={this.context} 
                                    variant={"chatLoading"} 
                                    allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                    showTextInputHandler={this.showTextInputHandler} 
                                    typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                />
                            </div>
                    }
                </Grid>
                <Grid item xs={12}  id="changetopic" style={{textAlign: 'right', marginTop: 20, marginBottom: 20}}>
                    {
                    (this.state.typingBotDone || this.state.introMessageDone || this.state.showTopicOptions) && this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined 
                        ?
                            <React.Fragment>
                                <Button 
                                    style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
                                    color="secondary"
                                    //variant="contained"
                                    variant="outlined"
                                    onClick = {() => {      
                                        //this.request(true); 
                                        this.setState({extraQuestioning: true, tabSelection: 'Mentor', guidedChat: false});
                                        this.requestContinue(true);
                                        ExtendDialogueMessage(this.props.firebase, this.context, 'I need a mentor', 'What do you need right now?', 'utter_ask_help', '', 'profile');
                                    }}
                                >
                                    I need Answers
                                    &nbsp;
                                    <WidgetsIcon fontSize='small'/>
                                </Button>
                                <Button 
                                    style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
                                    color="secondary"
                                    //variant="contained"
                                    variant="outlined"
                                    onClick = {() => {
                                        this.request(true);
                                        this.setState({tabSelection: 'Coach', guidedChat: true});
                                        ExtendDialogueMessage(this.props.firebase, this.context, 'I want some small talk', 'What do you need right now?', 'utter_ask_help', '', 'profile');
                                    }}
                                >
                                    Small Talk
                                    &nbsp;
                                    {this.props.defaultSet?.programLogo?.includes('http')
                                        ?   <ForumIcon fontSize="small" />
                                        :   <RockyIcon fontSize="small" />
                                    }
                                </Button>
                                <DialogQuickTopicSetting
                                    buttonsOnly={true}
                                    openShop={true}
                                    handleClose = {() => this.setState({openCurrentAchievementGoal: false, openCurrentShopFlag: false})}
                                    handleOpen = { () => {
                                        this.setState({openCurrentAchievementGoal: true, openCurrentShopFlag: true, typingBotFinishTypingNow: true});
                                        ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my coaching modules', 'Are you fine with your current module?', 'utter_ask_help', '', 'profile');
                                    }}
                                    handleSubmitCurrentAchievementGoal = { this.handleTopicChange }
                                    unselectTopics={this.state.unselectTopics || false}
                                    user={this.context}
                                    //renderPreview={true}
                                    leadershipCategories={this.state.leadershipCategories}
                                    leadershipQualities={this.state.leadershipQualities} 
                                />
                            </React.Fragment>
                        :   ''
                    }
                </Grid>
                <Grid item xs={12}>
                    {this.quoteRender()}
                </Grid>
            </React.Fragment>
        )
    }

    renderMentorIntro(){
        const { theme } = this.props;
        return(
            <React.Fragment>
                <Grid item xs={12} style={{ minHeight: 200 }}>
                    <div style={{height: 84}}> &nbsp;</div>
                    {this.state.introMessages?.length>0
                        ?
                        <>
                            <ChatBlock
                                user={this.context}
                                allowAudio={false}
                                variant={"chatIntro"}
                                currentDialogueId={this.state.currentDialogueId}
                                messages={this.state.introMessages}
                                currentExplanation={this.state.dialogue?.currentExplanation || ''}
                                //showTextInputHandler={this.showTextInputHandler} 
                                typingBotFinishTypingNow={
                                    this.state.introMessageDone 
                                        ?   true 
                                        :   this.context.features?.fastmode 
                                            ?   this.context.features.fastmode
                                            :   this.state.typingBotFinishTypingNow 
                                                ?   this.state.typingBotFinishTypingNow
                                                :   false
                                    }
                                typingDoneHandler={() => {
                                    this.setState({showTopicOptions: true, showTextInput: true});
                                    //console.log('message end, ', this.state.typingBotFinishTypingNow, this.context.muteAudio)
                                    setTimeout(() => {
                                        this.setState({introMessageDone: true});
                                    }, (this.state.typingBotFinishTypingNow || this.context.muteAudio || this.state.mute)
                                        ?   100 
                                        :   200
                                    );
                                    if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                        //console.log('message scrooll')
                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                                typingMoveOnHandler={() => {
                                    //console.log('message move')
                                    if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                        //console.log('message scrooll')
                                        this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
                                    }
                                }}
                            />
                        </>
                        :
                            <div>
                                <ChatBlock 
                                    user={this.context} 
                                    variant={"chatLoading"} 
                                    allowAudio={this.state.tabSelection === 'Coach' && (!this.state.mute || true)} 
                                    showTextInputHandler={this.showTextInputHandler} 
                                    typingBotFinishTypingNow={this.context.features?.fastmode  ? this.context.features.fastmode : this.state.typingBotFinishTypingNow !== undefined ? this.state.typingBotFinishTypingNow : false}
                                />
                            </div>
                    }
                    {this.quoteRender()}
                </Grid>
                <Grid item xs={12}  id="changetopic" style={{textAlign: 'right', marginTop: 20, marginBottom: 20}}>
                    {
                    (this.state.typingBotDone || this.state.introMessageDone || this.state.showTopicOptions) && this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined 
                        ?
                            <div style={{width:'100%'}}
                                onClick={() => {
                                    this.setState({openCurrentAchievementGoal: true, openCurrentShopFlag: false, typingBotFinishTypingNow: true});
                                    ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my coaching module!', 'Are you fine with your current module?', 'utter_ask_help', '', 'profile');
                                }} >
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="stretch"
                                    >
                                    <Grid item xs={8} style={{textAlign: 'left'}}>
                                        <AvatarCard key={QualityID(this.context.currentLeadershipQuality)} dict={this.state.leadershipQualities} type='quality' item={QualityID(this.context.currentLeadershipQuality)} squareSize='20' />
                                    </Grid>
                                    <Grid item xs={4} style={{textAlign: 'right'}}>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                        >
                                            Change
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        :   ''
                    }
                </Grid>
                {(this.state.typingBotDone || this.state.introMessageDone || this.state.showTopicOptions)
                    &&
                    <Grid item xs={12} style={{marginTop: 12, textAlign: 'center', height:'100%'}}>
                        <GridList cols={2} style={{ height:'100%'}}>
                            <div style={{paddingLeft: 0, paddingRight: 5, height:'100%'}}
                                onClick = {() => { 
                                    var subscriberStatus = CheckSubscriptionStatus(this.context);
                                    //console.log('check subscirber', subscriberStatus)
                                    if(freeTopics.includes(QualityID(this.context.currentLeadershipQuality)) || this.props.nonSubscriber===false || this.state.nonSubscriber===false || subscriberStatus.nonSubscriber===false || subscriberStatus.trialPeriod ){
                                        this.setState({guidedChat: true});
                                    }
                                    else{
                                        this.setState({DialogSettingPlanOpen: true, subscriptionPlanInfoText: 'Please subscribe to a plan if you want to reflect on this module, or change to a free module instead'});
                                    }
                                }}
                            >
                                <CardTopicSquare
                                    showShop= {true}
                                    item= {FindDictByKey(this.props.db, this.state.leadershipQualities, QualityID(this.context.currentLeadershipQuality))}
                                    subtitle= 'Reflective Coach'
                                    type= {"quality"}
                                />
                                <strong style={{color:theme.palette.secondary.light}}><br/>Being challenged<br/>(Advanced)</strong>
                            </div>
                            <div style={{paddingLeft: 5, paddingRight: 0}}
                                onClick = { () => {        
                                    this.setState({extraQuestioning: true, tabSelection: 'Mentor', guidedChat: false});
                                    this.requestContinue();
                                    ExtendDialogueMessage(this.props.firebase, this.context, 'I need a mentor', 'What do you need right now?', 'utter_ask_help', '', 'profile');
                                }}
                            >
                                <CardTopicSquare
                                    item = {{
                                        value: 'freeflow',
                                        text: 'Answers & Tips',
                                        backgroundColor: theme.palette.secondary.main
                                    }}
                                    subtitle = 'Mentor Mode'
                                    type= {"quality"}
                                />
                                <strong style={{color:theme.palette.secondary.light}}><br/>Quick assistance<br/>(Beginner)</strong>
                            </div>
                        </GridList>
                    </Grid>
                }
            </React.Fragment>
        )
    }

    renderChatContext(){
        const {theme} = this.props;
        if(this.state.messages?.[0]?.utteranceClass?.[0] === 'inform_loading'){
            return ""
        }
        else{
            var bgImage = undefined;
            bgImage = FindTextValueInDict(this.props.firebase, this.state.leadershipQualities, QualityID(this.context.currentLeadershipQuality), 'url')
            if(bgImage?.includes("http")){
                return(
                    <React.Fragment>
                        <Card
                            style={{
                                ...theme.cardJournalDayHighlight, 
                                height: 80,
                                minWidth:  '100%',
                                background: bgImage?.length>1
                                    ?  'url("'+bgImage+'")'
                                    :   undefined,
                                backgroundPosition: bgImage?.length>1 ? 'right 30%' : undefined,
                                backgroundRepeat: bgImage?.length>1 ? 'no-repeat' : undefined,
                                backgroundSize: bgImage?.length>1 ? 'cover' : undefined,
                            }} 
                        >
                            <div id="changetopic" style={{textAlign: 'right', marginTop: 20, marginLeft: 20}}>
                                {//this.context.dialogueCounter >= 1 && 
                                //this.context.dialogueStatus !== 'open' &&
                                //(this.state.typingBotDone || this.state.introMessageDone || this.state.showTopicOptions) && 
                                this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined 
                                    ?
                                        <div style={{width:'100%'}}
                                            onClick={() => {
                                                this.setState({openCurrentAchievementGoal: true, typingBotFinishTypingNow: true});
                                                ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my coaching modules!', 'Are you fine with your current module?', 'utter_ask_help', '', 'profile');
                                            }} >
                                            <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="stretch"
                                            >
                                                <Grid item xs={8} style={{textAlign: 'left'}}>
                                                    {this.state.dialogue?.currentLeadershipQuality === 'default'
                                                        ?   ""
                                                        :   this.context.programs?.includes('default') && this.state.dialogue?.currentLeadershipQuality?.length > 2 && QualityID(this.context.currentLeadershipQuality) !== this.state.dialogue?.currentLeadershipQuality
                                                            ?
                                                                <AvatarCard key={this.context.currentCategory} dict={this.state.leadershipCategories} type='category' item={FindTextValueInDict(this.props.firebase, this.state.leadershipQualities, this.state.dialogue?.currentLeadershipQuality, 'category') || this.context.currentCategory} squareSize='20' />
                                                            :   
                                                                <AvatarCard key={QualityID(this.context.currentLeadershipQuality)} dict={this.state.leadershipQualities} type='quality' item={QualityID(this.context.currentLeadershipQuality)} squareSize='20' />
                                                    }
                                                </Grid>
                                                <Grid item xs={4} style={{textAlign: 'right'}}>
                                                    &nbsp;
                                                </Grid>
                                            </Grid>
                                        </div>
                                    :   ''
                                }
                            </div>
                        </Card>
                        {false && // in chatblogk
                            <div style={{marginBottom: 10}}>
                                <DialogueStages
                                    finished = {true}
                                    background = {"transparent"}
                                    emptyPadding = {true}
                                    stages = {this.state.dialogue?.dialogueStage || undefined}
                                    message = {(message) => {this.setState({ dialogueStagesMessage: message})}}
                                />
                            </div>
                        }
                    </React.Fragment>
                )
            }
            else return(
                <>
                    <div id="changetopic" style={{textAlign: 'right', marginTop: 20, marginBottom: 0}}>
                        {//this.context.dialogueCounter >= 1 && 
                        //this.context.dialogueStatus !== 'open' &&
                        //(this.state.typingBotDone || this.state.introMessageDone || this.state.showTopicOptions) && 
                        this.state.leadershipCategories !== undefined && this.state.leadershipQualities !== undefined 
                            ?
                                <div style={{width:'100%'}}
                                    onClick={() => {
                                        this.setState({openCurrentAchievementGoal: true, typingBotFinishTypingNow: true});
                                        ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my coaching modules!', 'Are you fine with your current module?', 'utter_ask_help', '', 'profile');
                                    }} >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="stretch"
                                        >
                                        <Grid item xs={8} style={{textAlign: 'left'}}>
                                            {this.context.programs?.includes('default') && this.state.dialogue?.currentLeadershipQuality?.length > 2 && QualityID(this.context.currentLeadershipQuality) !== this.state.dialogue?.currentLeadershipQuality
                                                ?
                                                    <AvatarCard key={this.context.currentCategory} dict={this.state.leadershipCategories} type='category' item={this.context.currentCategory} squareSize='20' />
                                                :   
                                                    <AvatarCard key={QualityID(this.context.currentLeadershipQuality)} dict={this.state.leadershipQualities} type='quality' item={QualityID(this.context.currentLeadershipQuality)} squareSize='20' />
                                            }
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: 'right'}}>
                                            &nbsp;
                                        </Grid>
                                    </Grid>
                                </div>
                            :   ''
                        }
                    </div>
                </>
            )
        }
    }

    renderQuestionButtons(){
        const {theme} = this.props;
        const {messages} = this.state;
        //console.log('render buttons')
        if(!(this.context?.features?.mentormode === false) && messages?.length>0 && messages[messages.length-1].isCoach===true && messages[messages.length-1].buttons?.length>0){
            return(
                <React.Fragment>
                    <span style={theme.textSupport}>Examples:&nbsp;</span>
                    {messages[messages.length-1].buttons.map((extraButton, indexButton) => (
                        <React.Fragment key={"response_"+indexButton}>
                            {extraButton?.payload?.startsWith('/')
                                ?   ''
                                :   extraButton?.title?.length>2 && extraButton?.payload?.length>2
                                    ?
                                        <Button 
                                            style={{marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                            key={"response_"+indexButton} 
                                            //onClick={() => this.onSubmit(extraButton.payload) }
                                            color="secondary"
                                            variant="outlined"
                                            onClick={
                                                () => {
                                                    if(extraButton.payload === extraButton.title && extraButton.title?.length < 20){
                                                        this.onSubmit(extraButton.payload) 
                                                    }
                                                    else{
                                                        this.submitInspiration(extraButton.payload) 
                                                    }
                                                }
                                            }
                                        >
                                            {(extraButton.payload !== extraButton.title || extraButton.title?.length >= 20) && !(extraButton.title.includes("..."))
                                                ?   extraButton.title?.length >= 20
                                                    ?   extraButton.title.substring(0,19) + ' ...'
                                                    :   extraButton.title + ' ...'
                                                :   <>
                                                        {extraButton.title}
                                                        &nbsp;
                                                        <ArrowRightIcon fontSize='small'/>
                                                    </>
                                                    
                                            }
                                        </Button>
                                    :   ''
                            }
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )
        }
    }

    renderAutoButtons(){
        const {theme} = this.props;
        const {messages} = this.state;
        var utterClassList = messages?.[messages.length-1]?.utteranceClass || [];
        var utterClass = utterClassList?.[utterClassList.length - 1] || "";
        //console.log('utter Button', utterClass)
        return(
            <React.Fragment>
                {/***
                <Button 
                    style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
                    onClick={() => {
                        this.setState({tabSelection: 'Mentor'})
                        this.onSubmit("How to start with the first activity?");
                    }}
                    //variant="contained"
                    variant="outlined"
                    color="secondary"
                    >
                    How to start?&nbsp;
                    <WidgetsIcon fontSize='small'/>
                </Button>
                    */}
                {this.state.tabSelection === 'Mentor'
                    ?
                    <Button 
                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4}}
                        onClick={() => {
                            this.setState({tabSelection: 'Mentor'})
                            this.submitInspiration("How can I ");
                        }}
                        //variant="contained"
                        variant="outlined"
                        color="secondary"
                        >
                        How can I ...?
                    </Button>
                    :
                        ""
                }
                {!(this.context?.features?.mentormode === false) && utterClass?.length > 1 
                    ?   this.state.tabSelection !== 'Mentor' && ['utter_ask_quiz', 'utter_ask_improvement', 'utter_ask_preparation'].includes(utterClass)
                        ?
                            <Button 
                                onClick={() => {
                                    this.setState({tabSelection: 'Mentor'})
                                    this.onSubmit("Guide me!")
                                    //this.submitInspiration("Guide me!")
                                }}
                                //variant="contained"
                                variant="outlined"
                                color="secondary"
                                style={{marginLeft: 8, marginTop: 4, marginBottom: 4, backgroundColor: theme.palette.secondary.main+'20'}} 
                            >
                                {false && this.context.dialogueCounter >= 3
                                    ?   'Inspire me'
                                    :   'Guide me'
                                }
                                &nbsp;
                                <WidgetsIcon fontSize='small'/>
                            </Button>
                        :   ['ask_progress', 'ask_assessment', 'ask_highlight', '_check', 'ask_habit_insight', 'ask_challenge', 'ask_rapport', 'ask_achievement', 'ask_mindset', 'ask_feedback', '_cta', 'ask_reflection', 'utter_inform_openchat'].some( x => utterClass.includes(x))
                            ?   ""
                            :
                                <Button 
                                    style={{marginLeft: 8, marginTop: 4, marginBottom: 4, backgroundColor: theme.palette.secondary.main+'20'}}
                                    onClick={() => {
                                        //this.setState({tabSelection: 'Mentor'})
                                        this.onSubmit("Tell me!");
                                    }}
                                    //variant="contained"
                                    variant="outlined"
                                    color="secondary"
                                    >
                                    Tell me
                                    &nbsp;
                                    <ContactSupportIcon fontSize="small" />
                                </Button>
                    :   ""
                }
                {this.state.tabSelection === 'Mentor'
                    ?
                        <Button 
                            style={{marginLeft: 8, marginTop: 4, marginBottom: 4, backgroundColor: theme.palette.secondary.main+'20'}} 
                            onClick={() => {
                                this.setState({tabSelection: 'Coach'})
                                this.onSubmit("Next!");
                            }}
                            //variant="contained"
                            variant="outlined"
                            color="secondary"
                            >
                            Continue coaching
                            &nbsp;
                            {this.props.defaultSet?.programLogo?.includes('http')
                                ?   <ForumIcon fontSize="small" />
                                :   <RockyIcon fontSize="small" />
                            }
                        </Button>
                    :   (!(['_check', 'utter_inform_openchat'].some( x => utterClass.includes(x)))
                            // && !(this.context?.features?.mentormode === false)
                        )
                        ?
                            <Button 
                                onClick={() => {
                                    this.onSkip()
                                }}
                                //variant="contained"
                                variant="outlined"
                                color="secondary"
                                style={{marginLeft: 8, marginTop: 4, marginBottom: 4, backgroundColor: theme.palette.secondary.main+'20'}} 
                            >
                                Skip
                                &nbsp;
                                {this.props.defaultSet?.programAvatarName?.length > 2
                                    ?   <SkipNextIcon fontSize="small" />
                                    :   <SkipNextIcon fontSize="small" />
                                }
                            </Button>
                        :   ""
                }
                {!(this.context?.features?.mentormode === false) && this.state.dialogue?.contentSelection?.length > 0 &&
                    <Button 
                        onClick={() => {
                            this.setState({speedDialOpen : false});
                            this.handleOpen();
                        }}
                        //variant="contained"
                        variant="outlined"
                        color="secondary"
                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4, backgroundColor: theme.palette.secondary.main+'20'}} 
                    >
                        Easy Write
                        &nbsp;
                        <WrapTextIcon fontSize='small'/>
                    </Button>
                }
            </React.Fragment>
        )
    }

    handleTopicChange = async (changes = false, quality = "") => {
        //console.log('input changes', changes, this.state, this.context)
        if(changes === true){
            console.log('start changes')
            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                currentCategory: this.context.currentCategory, 
                currentLeadershipQuality: QualityID(this.context.currentLeadershipQuality), 
                todaysLeadershipQuality: QualityID(this.context.currentLeadershipQuality)
            })
            .then( () => { 
                var userRoleFilter = this.getUserRole();
                this.setState({
                    spinner: true,
                    openCurrentAchievementGoal: false, 
                    openCurrentShopFlag: false, 
                    unselectTopics: false, 
                    introMessageDone: true, /// would make a fast forward and skipp the slow messagin
                    skipIntroMessage: true,  //// ensure that the Hi dissapeasr
                    //typingBotFinishTypingNow: false,
                    userRoleFilter: userRoleFilter,
                    guidedChat: true
                }, () => {
                    console.log('handleTopicChange start a new chat', this.context.currentLeadershipQuality)
                    this.request(false, true)
                });
            })
        }
        else{
            //console.log('saved changes')
            ///// Important that intromessagedone is not reset, else the question dissapears
            this.setState({
                openCurrentAchievementGoal: false, 
                openCurrentShopFlag: false, 
                introMessageDone: true, 
                guidedChat: true,
                skipIntroMessage: true,   //// ensure that the Hi dissapeasr
            });
        }
        return true
    }

    appStoreSubscriber () {
        //console.log(' suibser check')
        if(CheckSubscriptionStateOwned(this.context) && this._isMounted){
            this.setState({missingSubscription: false, nonSubscriber: false});
            //console.log('owned subscription', this.state)
        }
        else if(this._isMounted){
            this.setState({missingSubscription: true, nonSubscriber: true});
            //console.log('non subscription', this.state)
        }
    }

    checkSetFremiumFlag() {
        var dialogueCounter = 0;
        if(this.context.dialogueCounter !== undefined && this.context.dialogueCounter>1) {
            dialogueCounter = this.context.dialogueCounter
        }
        var freeDialogues = 0;
        if(this.context.freeDialogues !== undefined && this.context.freeDialogues>=1) {
            freeDialogues = this.context.freeDialogues
        }
        if((freeDialogues >= dialogueCounter )
            // || dialogueCounter <= 2
            ){
            if(this._isMounted) this.setState({freemiumSubscriber: true});
            return true;
        }
        else return false;
    }

    checkSetSubscriptionFlag = () => {
        var freemiumSubscriber = this.checkSetFremiumFlag();
        //console.log('checking new subsc')
        if(this.state.missingSubscription){
            var dialogueType = this.state.dialogueType;
            if(dialogueType === undefined || dialogueType==='') {
                dialogueType = 'priming'
            }
            //console.log('sion, ',dialogueCounter, freeDialogues)
            if(this.state.missingSubscription && !(freemiumSubscriber) && this._isMounted){
                //console.log('subscr dec sion, ',dialogueCounter, freeDialogues)
                //this.setState({nonSubscriber: true, loading: false, dialogueType});
                this.setState({nonSubscriber: true, dialogueType, audioInteraction: true});
                if(this.context.muteAudio !== true){
                    console.log('mute audio non subscriber')
                    this.props.firebase.user(this.context.uid).update({
                        muteAudio : true
                    });
                }
            }
        }
    }

    checkSubscriptionStateWindowStore(){
        if(this.state.missingSubscription !== false){
            //// every product is checked, of all go through without errors and have not
            var anySubscription = false;
            var allNonSubscription = true;
            try{
                const product1 = window.CdvPurchase?.store?.get('app.rocky.ai.subscription.basic') || {};
                //.log('loaded', product1)
                if(product1 !== undefined && product1.owned === true){
                    anySubscription = true;
                    allNonSubscription = false
                    //console.log('owned', product1)
                }
            }
            catch (error){
                allNonSubscription = false;
                console.log('Essentials Subscription not available')
            } 
            try{
                const product2 = window.CdvPurchase?.store?.get('app.rocky.ai.subscription.basic.yearly') || {};
                //console.log('loaded',  product2)
                if(product2 !== undefined && product2.owned === true){
                    anySubscription = true;
                    allNonSubscription = false;
                    //console.log('owned', product2)
                }
            }
            catch (error){
                allNonSubscription = false;
                console.log('Essentials Yearly Subscription not available')
            }
            ///// set the correct flag for the app
            if(allNonSubscription && this._isMounted){
                this.setState({missingSubscription: true});
                console.log('no subscriptions at all')
            }
            if(anySubscription && this._isMounted){
                this.setState({missingSubscription: false});
                console.log('we have a subscription')
            }
        }
    }

    initiateChatSettingsDialogues = async (userDataSetBackup) => {
        var userDataSet = this.context;
        //console.log('initite user', userDataSet, this.state)
        if(this._isMounted && window.navigator.onLine && userDataSet !== undefined && userDataSet.username!==undefined && userDataSet.username!==''){
            ///Phase 1 - check for existing chat or create one
            //console.log('check for dialogue', this.state)
            await SetAgentDialogue(userDataSet, this.props.firebase)
            .then( (agentStatus) => {
                //console.log('status returned', agentStatus)
                var dialogueIdAvailable = "";
                if(agentStatus.currentDialogueId!==undefined && agentStatus.currentDialogueId!==""){
                    dialogueIdAvailable = agentStatus.currentDialogueId;
                }
                this.setState(agentStatus);
                //console.log('returned diallgue', dialogueIdAvailable)
                //console.log('m context',userDataSet);
                
                //console.log('m agentstatus',agentStatus);
                /// Phase 2 - load dialogue and messages
                if(this._isMounted && dialogueIdAvailable !=='' && dialogueIdAvailable !== undefined && dialogueIdAvailable !== null){
                    //console.log('start loading things', dialogueIdAvailable)
                    var todaysLeadershipQuality = '';
                    if(userDataSet.todaysLeadershipQuality !== undefined) 
                        todaysLeadershipQuality = userDataSet.todaysLeadershipQuality;
                    this.setState({
                        currentDialogueId: dialogueIdAvailable,
                        todaysLeadershipQuality: todaysLeadershipQuality
                    }, () => {
                        this.subscribeDialogue();
                        this.checkForAppSubmittedMessages()
                        .then(() => {
                            //console.log('checkForAppSubmittedMessages then await messages')
                            this.subscribeMessages()
                        })
                    });
                }
                else{
                    this.setState({firebaseConnectionError : true})
                    this.timeoutReloadDialogueID = setTimeout(() => {
                        console.log('DialogueID Timeout, Restart in 1.5 sec')
                        if(this._isMounted){
                            this.initiateChatSettingsDialogues(this.context);
                        }
                    }, 1500);
                }
            })
        }
    }

    subscribeDialogue = () => {
        //console.log("starting subscribe dialouge", this.state.currentDialogueId)
        var dialogueInfo = {}
        if(this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
            if(this.unsubscribeDialogue!== undefined) this.unsubscribeDialogue();
            try{
                this.unsubscribeDialogue = this.props.firebase
                .dialogue(this.state.currentDialogueId)
                .onSnapshot((snapshot) => {
                    dialogueInfo = snapshot.data();
                    if(dialogueInfo !== undefined && dialogueInfo!== null ){
                        //console.log("dialogue update", dialogueInfo)
                        if(this._isMounted ){
                            //console.log("dialogue set", dialogueInfo)
                            this.setState({
                                dialogue: dialogueInfo,
                                firebaseConnectionError: false,
                                //loading: false
                            });
                        }
                        //console.log('dialogue phase', this.state.dialogue, dialogueInfo)
                        if (dialogueInfo.phase === undefined) {
                            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                                coachId: 'agent',
                                status: false,
                                phase: 'created',
                                timezone: (this.context.timezone || 'UTC')
                            })
                            this.request();
                        }
                        else if (dialogueInfo.phase === 'created') {
                            if(this.state.messages !== undefined && this.state.messages.length > 0){
                                if(this.state.messages[this.state.messages.length-1].isCoach===true){
                                    //console.log('Messages are loaded but stage wrong');
                                    this.props.firebase.dialogue(this.state.currentDialogueId).update({
                                        phase: 'session_requested',
                                        timezone: (this.context.timezone || 'UTC')
                                    })
                                    this.requestContinue();
                                }
                                else{
                                    this.request();
                                }
                            }
                            else{
                                //console.log('start create', this.state)
                                this.request();
                            }
                        }
                        else{
                            this.setState({
                                //dialogue: snapshot.data(),
                                phase: dialogueInfo.phase 
                            });
                        }
                        if (this.state.dialogue!== null && dialogueInfo.phase !== 'running' && dialogueInfo.extend === 'add' && this.state.dialogue?.phase !== 'session_ended_earlier'  && this.state.dialogue?.phase !== 'session_ended' && this.state.dialogue?.phase!== 'feedback') {
                            //console.log('started add more')
                            //this.requestContinue();
                            //////  we have now a button to add more
                            //this.requestAddMore(dialogueInfo.measurement);
                        }
                    }
                    else{
                        console.log('error empty snaphsot');
                        this.setState({firebaseConnectionError : true})
                        this.timeoutReloadDialogue = setTimeout(() => {
                            console.log('Dialogue Timeout 1, Restart in 2 sec')
                            if(this._isMounted && this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
                                this.subscribeDialogue();
                            }
                        }, 1200);
                    }
                },
                (error) => {
                    console.log('error with onsnapshot ERROR', error);
                    this.setState({firebaseConnectionError : true})
                    this.timeoutReloadDialogue = setTimeout(() => {
                        console.log('Dialogue Timeout 2, Restart in 2 sec')
                        if(this._isMounted && this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
                            this.subscribeDialogue();
                        }
                    }, 1200);
                });
                if(this.context.features !== undefined && this.context.features.questioning === true){
                    console.log('wakeup call')
                    try{
                        this.messageExchange({ "sender": this.context.uid, "story": 'Hello' }, 'questioningAI', this.props.firebase);
                    }
                    catch (e){ 
                        console.log('error with message to quesitoningAI', e);
                    };

                }
            }
            catch (e){ 
                console.log('error with subscribe dial', e);
                this.setState({firebaseConnectionError : true})
                this.timeoutReloadDialogue = setTimeout(() => {
                    console.log('Dialogue Timeout 3, Restart in 2 sec')
                    if(this._isMounted && this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
                        this.subscribeDialogue();
                    }
                }, 1200);
            };
        }
        else{
            this.setState({firebaseConnectionError : true})
            this.timeoutReloadDialogue = setTimeout(() => {
                console.log('Dialogue Timeout Base, Restart in 2 sec')
                if(this._isMounted && this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
                    this.subscribeDialogue();
                }
            }, 2000);
        }
    }

    subscribeMessages = () => {
        const greetings = ["Hello", "Hi", "Cheers", "Hey", "Hello",
                "Good morning", "Morning,", "Good morning", "Morning,", "Good morning",
                "Hi", "Cheers", "Hey",
                "Good afternoon", "Good afternoon", "Good afternoon",
                "Hello", "Cheers", "Hi",
                "Good evening", "Evening", "Good evening",
                "Hello", "Hi", "Cheers"];
        //// TIMEZONE MODULE IF ERROR UTC
        var userTimezone = GetTimeZone(this.props.firebase, this.context);
        //console.log('timezone receivecd', userTimezone)
        const today = new Date();
        var hours = today.getHours();
        if(userTimezone !== 'UTC'){
            hours = new Date(today.toLocaleString('en-US', { timeZone: userTimezone})).getHours();
        }
        //console.log('message subscribe to ',this.state.currentDialogueId)
        if(this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
            if(this.unsubscribeMessages!== undefined) this.unsubscribeMessages();
            try{
                this.unsubscribeMessages = this.props.firebase
                .messages()
                .orderBy('createdAt', 'asc')
                .where('messageFilter', '==', 'bot')
                .where('dialogueId', '==', this.state.currentDialogueId)
                .onSnapshot((snapshot) => {
                    if (snapshot.size) {
                        //let loadedMessages = this.state.messages;
                        //let messageListById = this.state.messageListById;
                        let loadedMessages = [];
                        let introMessages = [];
                        let messageListById = [];
                        var lastBotMessageId = undefined;
                        var lastBotQuestion = undefined;
                        var lastBotUtterance = undefined;
                        var emptyMessageCheck = false;
                        var lastBotIntent = undefined;
                        var counterQuestions = 0;
                        var counterResponses = 0;
                        var counterReflections = 0;
                        var newMessage = {}
                        //var messageCounter = 0;
                        snapshot.forEach( doc => {
                            //messageCounter +=1 ;
                            newMessage = doc.data();
                            // added this item, without check multiples of the messages are loaded
                            //if(!this.state.messageListById.includes(doc.id)){
                            if(!messageListById.includes(doc.id) && newMessage.text !== undefined && newMessage.text.length>0 ){
                                //.some((key) => obj[key] === value);
                                messageListById.push(doc.id);
                                var welcomeMessage = '';
                                if (newMessage?.utteranceClass?.includes("utter_welcome_to_program")){
                                    welcomeMessage = newMessage.text[0];
                                    this.setState({welcomeMessage: welcomeMessage});
                                    //console.log('newmessage',newMessage, welcomeMessage)
                                }
                                if (!['running', 'ended', 'feedback', 'open'].includes(this.state.dialogue?.phase) && (newMessage?.utteranceClass?.includes("utter_ask_satisfaction_level") || newMessage?.utteranceClass?.includes("utter_ask_energy_level") || newMessage?.utteranceClass?.includes("utter_ask_assessment") || newMessage?.utteranceClass?.includes("utter_ask_progress"))){
                                    //// SPLIT USERNAME
                                    var username = this.context.username!==undefined && this.context.username!=='' ? this.context.username : "Padawan";
                                    if(username.includes('@')){
                                        username = username.split('@')[0];
                                    }
                                    if(username.includes(' ')){
                                        username = username.split(' ')[0];
                                    }
                                    var welcomeGreeting = greetings[hours] + ' ' + username + "!"
                                    //// Get main TOPICS
                                    var currentTopic = FindTextValueInDict(this.props.firebase, this.state.leadershipQualities, QualityID(this.context.currentLeadershipQuality))
                                    var welcomeTopic = "Our topic for today is: '" + currentTopic + "'";
                                    welcomeTopic = welcomeTopic + '.';
                                    var welcomeLastTime;
                                    //console.log(welcomeTopic)
                                    /***
                                    if (newMessage.text!== undefined && newMessage.text.length > 0){
                                        newMessage.text.unshift(welcomeTopic)
                                        if (this.state.dialogue !== null && this.state.dialogue !== undefined && this.state.dialogue?.lastTitle !== undefined && this.state.dialogue?.lastTitle.length>0){
                                            welcomeLastTime = 'Last time we reflected on: ';
                                            welcomeLastTime = welcomeLastTime + this.state.dialogue?.lastTitle;
                                            newMessage.text.unshift(welcomeLastTime)
                                        }
                                        newMessage.text.unshift(welcomeGreeting)
                                    }
                                    */
                                    introMessages = [];
                                    var conversationStyle;
                                    var extraIntroMessage = {text: [], isCoach: true, messageFilter: 'bot'}
                                    if(this.state.extraQuestioning){
                                        var conversationExtra = 'I am happy to continue the chat. What would you like to talk about?'
                                        extraIntroMessage.text.unshift(conversationExtra)
                                    }
                                    else if(this.state.guidedChat === undefined 
                                        //&& !(this.context.features?.hideopenchat) 
                                        //&& !(this.context.dialogueCounter > 3)
                                        ){
                                        //var conversationStyle = (welcomeTopic?.length > 2 ? (welcomeTopic + " ") : "")  + 'What do you need right now?'
                                        conversationStyle = icebreakerSentence;
                                        extraIntroMessage.text.unshift(conversationStyle)
                                    }
                                    else if(false && this.state.guidedChat){
                                        ///// THIS IS NOW ALREADY WITH THE TOPIC ICON SOLVED AD THE TOP
                                        //var conversationStyle = (welcomeTopic?.length > 2 ? (welcomeTopic + " ") : "")  + 'What do you need right now?'
                                        conversationStyle = 'Our topic is:'
                                        extraIntroMessage.text.unshift(conversationStyle)
                                    }
                                    // current Module
                                    ////extraIntroMessage.text.unshift(welcomeTopic)
                                    ///
                                    if (this.state.extraQuestioning !== true && this.state.dialogue?.lastTitle?.length>10){
                                        welcomeLastTime = 'Last time we talked about, ';
                                        welcomeLastTime = welcomeLastTime + this.state.dialogue?.lastTitle;
                                        //extraIntroMessage.text.unshift(welcomeLastTime)
                                        //console.log(welcomeLastTime);
                                    }
                                    ////// ignore the welcome to productivty blabla chat 
                                    if(false && welcomeMessage?.length > 0 && this.state.guidedChat === undefined ){
                                        extraIntroMessage.text.unshift(welcomeMessage)
                                    }
                                    extraIntroMessage.text.unshift(welcomeGreeting)
                                    extraIntroMessage['utteranceClass'] = ['question_conclusions_mindset']
                                    introMessages.push({ ...extraIntroMessage, uid: doc.id, messageFilter: 'introMessage' })
                                    //console.log('Welcome Topic', welcomeTopic)
                                }
                                loadedMessages.push({ ...newMessage, uid: doc.id })
                                if(newMessage.isCoach){
                                    //console.log(newMessage)
                                    counterQuestions += 1;
                                    lastBotMessageId = doc.id;
                                    if(newMessage.utteranceClass !== undefined && newMessage.utteranceClass.length > 0){
                                        lastBotQuestion = newMessage.text[newMessage.text.length-1]
                                        lastBotUtterance = newMessage.utteranceClass[newMessage.utteranceClass.length -1]
                                    }
                                    //this.setState({lastBotMessageId : doc.id});
                                }
                                else{
                                    //console.log('Not from coach')
                                    if (newMessage.text[newMessage.text.length-1].length>15){
                                        counterResponses += 1;
                                    }
                                    if (newMessage.text[newMessage.text.length-1].length>50){
                                        counterReflections += 1;
                                    }
                                }
                            }
                            else if(!messageListById.includes(doc.id) && (newMessage.text === undefined || newMessage.text.length<1)){
                                messageListById.push(doc.id);
                                //console.log("problem with message",newMessage)
                                newMessage.text = ["Hi there."]
                                newMessage['isCoach'] = true
                                newMessage['messageFilter'] = 'bot';
                                newMessage.utteranceClass = ['question_conclusions_mindset']
                                //lastBotQuestion = "Hi there."
                                //lastBotUtterance = 'question_conclusions_mindset'
                                loadedMessages.push({ ...newMessage, uid: doc.id })
                                //emptyMessageCheck = true;
                            }
                        })
                        if(emptyMessageCheck){
                            console.log('no message text included')
                            //this.request();
                        }
                        if(this.state.dialogue!== null && this.state.dialogue!== undefined && lastBotUtterance!== undefined && lastBotUtterance!== null && lastBotUtterance!== ""){
                            lastBotIntent = 'will';
                            if( lastBotQuestion.includes("did") || lastBotQuestion.includes("was") ) lastBotIntent = 'did';
                            else if( lastBotQuestion.includes("will") || lastBotQuestion.includes("can")) lastBotIntent = 'will';
                            else if(lastBotUtterance.includes('mindse') || lastBotUtterance.includes('level') || lastBotUtterance.includes('rapport') ) lastBotIntent = 'feel';
                            else if(lastBotUtterance.includes('goal')  || lastBotUtterance.includes('achieve') || lastBotUtterance.includes('high') ) lastBotIntent = 'conclude';
                            else if( this.state.dialogue?.dialogueType === 'reflection' && (lastBotUtterance.includes('activ')  || lastBotUtterance.includes('prio') || lastBotUtterance.includes('achiev') 
                            || lastBotUtterance.includes('high') ) ) lastBotIntent = 'did';
                            else if (this.state.dialogue?.dialogueType === 'priming' && (lastBotUtterance.includes('activ')  || lastBotUtterance.includes('prio') || lastBotUtterance.includes('achiev') 
                            || lastBotUtterance.includes('high') )) lastBotIntent = 'will';
                            else if(lastBotUtterance.includes('improv') || lastBotUtterance.includes('feedb') || lastBotUtterance.includes('help')  || lastBotUtterance.includes('expec') || lastBotUtterance.includes('challe')  ) lastBotIntent = 'need';
                        }
                        //console.log('my messages', loadedMessages, lastBotUtterance, lastBotIntent, lastBotQuestion)
                        if(this._isMounted ){
                            this.setState({
                                lastBotMessageId: lastBotMessageId,
                                messages: loadedMessages,
                                introMessages,
                                messageListById : messageListById,
                                loading: false,
                                loadingRequest: (newMessage.isCoach? false: this.state.loadingRequest), // this is for new request so that loading screen stays
                                lastBotQuestion,
                                lastBotUtterance,
                                lastBotIntent,
                                spinner: false,
                                counterQuestions,
                                counterResponses,
                                counterReflections,
                                firebaseConnectionError : false,
                                userTimezone
                                //showTextInput:true
                            });
                            //////// this is a fall back if the chat has not text to provide a tet field
                            if(this.timeoutTextInput !== undefined && this.timeoutTextInput !== null){
                                try{
                                    //console.log('existing timeout kill')
                                    clearTimeout(this.timeoutTextInput);
                                }
                                catch(error ){
                                    console.log('timeout clearance', error)
                                }
                            }
                            this.timeoutTextInput = setTimeout(() => {
                                if(this._isMounted && this.state.showTextInput!==true){
                                    //console.log('existing time our kicked')
                                    this.setState({
                                        //loading: true,
                                        showTextInput: true,
                                        loadingRequest: false,
                                    });
                                }
                            }, 6000);
                            //console.log('updated messagexs')
                        }
                    } 
                    else {
                        if(this._isMounted ){
                            this.setState({ 
                                messages: [{
                                    text : ["Let's get started!"],
                                    utteranceClass : ['inform_loading'],
                                    isCoach: true,
                                    messageFilter: 'bot'
                                }], 
                                messageListById: ['preload_message'], 
                                loading: false,  
                                spinner: false,
                                showTextInput: false,
                                firebaseConnectionError : false
                            });
                        }
                        if(this.state.flagInitialRequest === undefined) {
                            console.log('no message, requesting')
                            this.request();
                        }
                    }
                    //console.log('subscribeMessages()', this.state.messages)
                },
                (error) => {
                    console.log('error with mssage conti', error);
                    this.setState({firebaseConnectionError : true})
                });
            }
            catch (e){ 
                console.log('error with messges request ERROR', e);
                this.setState({firebaseConnectionError : true});
            };
        }
        else{
            this.setState({firebaseConnectionError : true})
            this.timeoutReloadMessages = setTimeout(() => {
                console.log('Messages Timeout, Restart in 3 sec')
                if(this._isMounted && this.state.currentDialogueId !== undefined && this.state.currentDialogueId !== ''){
                    this.subscribeMessages();
                }
            }, 8500);
        }
    }

    showTextInputHandler = () => {
        //console.log(this.state.dialogue?.phase)
        if(this._isMounted){
            this.setState({waitingForResponseTimeout : false});
            //////// this is a fall back if the chat has not text to provide a tet field
            if(this.timeoutWaitingForResponse !== undefined && this.timeoutWaitingForResponse !== null){
                try{
                    //console.log('existing timeout kill')
                    clearTimeout(this.timeoutWaitingForResponse);
                }
                catch(error ){
                    console.log('timeoutWaitingForResponse clearance', error)
                }
            }
            if(this.context.dialogueCounter === undefined || this.context.dialogueCounter <= 3){
                this.timeoutWaitingForResponse = setTimeout(() => {
                    //console.log('timeoutWaitingForResponse, Restart in 10 sec')
                    if(this._isMounted){
                        this.setState({waitingForResponseTimeout : true});
                    }
                }, 10000);
            }
        }
        if(this._isMounted && this.state.dialogue!==null && this.state.dialogue?.phase !== 'created'){
        //if(this.state.dialogue?.phase !== 'created' &&  this.state.dialogue?.phase !== 'session_requested'){
            this.setState({
                //showTextInput: true,
                //showTextFieldButtons: true,
                showLoading: true
            });
        }
        //if(!IsMobileDevice() && this.refTextInput.current!==undefined  && this.refTextInput.current!==null){
        //    this.refTextInput.current.focus();
        //}
    }

    handleOpen = () => {
        //opening the help window
        if(this._isMounted ){
            if(!(this.state.responseContent?.length > 1)){
                this.searchContentRecommendationsForQuestion();
            }
            this.setState({ open: true});
        }
    }
    handleClose = () => {
        if(this._isMounted ){
            this.setState({
                open: false,
            });
        }
    }
    handleTipsOpen = () => {
        //opening the help window
        if(this._isMounted ){
            //console.log('tipsopen')
            this.setState({ tabSelection: 'Mentor' });
            this.searchContentRecommendationsForQuestion();
            //this.setState({ tipsOpen: true});
            this.handleTabChange(undefined, 'Mentor');
        }
    }
    handleTipsClose = () => {
        if(this._isMounted ){
            this.setState({
                tipsOpen: false,
            });
        }
    }
    handleSettingsClose = () => {
        if(this._isMounted ){
            this.setState({
                dialogSettingsOpen: false,
            });
        }
    }

    handleChangeSlider = (event, value) => {
        if (value<0.5 ) value = 1
        value = Math.round(value)/5;
        //console.log(value)
        var sliderText = "normal";
        if(this.state.messages?.[this.state.messages?.length-1]?.utteranceClass?.includes('utter_ask_progress')){
            sliderText = wordsMood["progress"][String(Math.round(value))];
        }
        else{
            sliderText = wordsMood["assessment"][String(Math.round(value))];
        }
        if(this._isMounted ){
            this.setState({ value, sliderText });
            //console.log(value,sliderText)
        }
    };
    handleChangeSlCommit = (event, value) => {
        if (value<0.5 ) value = 1
        value = Math.round(value)/5;
        //console.log(value)
        var sliderText = "normal";
        if(this.state.messages?.[this.state.messages?.length-1]?.utteranceClass?.includes('utter_ask_progress')){
            sliderText = wordsMood["progress"][String(Math.round(value))];
        }
        else{
            sliderText = wordsMood["assessment"][String(Math.round(value))];
        }
        if(this._isMounted ){
            this.setState({ value, sliderText });
            //console.log(value,sliderText)
            this.onSubmit(sliderText)
        }
    };

    async searchContentRecommendationsForQuestion(showQuestionTipsInChat = false, userInputBased = false) {
        if(this.state.messages !== undefined && this.state.messages!==null && this.state.messages.length>0 && this._isMounted){
            if(userInputBased){
                //console.log('suggestionLoading')
                this.setState({suggestionLoading: true, responseTipsSummary: undefined, responseRecommendation: undefined, showResponseTipsInChat: false});
            }
            try{
                //console.log('search tips started', 'userInputBased', userInputBased, 'showQuestionTipsInChat', showQuestionTipsInChat)
                var flagContentOnUserResponse = false;
                ///////// 1. This is the message to be copied for the intent
                //var latest_answer = this.state.messages[this.state.messages.length-2];
                var latest_message = this.state.messages[this.state.messages.length-1];
                if(this.state.messages.length>=2 && this.state.messages[this.state.messages.length-2] !== undefined && this.state.messages[this.state.messages.length-2].isCoach === false){
                    var latest_userMessage = this.state.messages[this.state.messages.length-2];
                    if(latest_userMessage?.intentClass !== undefined ){
                        //console.log('latest_userMessage',latest_userMessage)
                        if(latest_userMessage.intentClass.includes('question_conclusions') 
                            || latest_userMessage.intentClass.includes('request_consulting') 
                            || latest_userMessage.intentClass.includes('failure') 
                            || latest_userMessage.intentClass.includes('challenge')
                        ){
                                latest_message = latest_userMessage;
                                //console.log('user q/c')
                                flagContentOnUserResponse = true;
                                //this.setState({tabSelection: 'Mentor'});
                        }
                        else if(
                            latest_userMessage.intentClass.includes('indicator')
                            || latest_userMessage.intentClass.includes('activity')
                            || latest_userMessage.intentClass.includes('intention')
                            || latest_userMessage.intentClass.includes('sentiment')
                            //|| latest_userMessage.intentClass.includes('indicator')
                            //|| latest_userMessage.intentClass.includes('sentiment')
                            //|| latest_userMessage.intentClass.includes('indicator')
                        ){
                                latest_message = latest_userMessage;
                                //console.log('user short')
                                flagContentOnUserResponse = true;
                        }
                        else{
                            //console.log('user other')
                            //console.log('user short')
                            flagContentOnUserResponse = true;
                        }
                    }
                }
                //console.log(' message', latest_message)
                if(false && latest_message?.utteranceClass?.includes("utter_ask_cta_activities")){
                    //latest_message = this.state.messages[this.state.messages.length-2];
                    //console.log('override message', latest_message)
                }
                //console.log('messages', latest_message.utteranceClass, latest_message)
                const followUpUtterances = [
                    //"utter_ask_rapport_extended", 
                    "utter_ask_preparation",
                    "utter_ask_improvement", 
                    //"utter_ask_highlight",
                    //"utter_ask_achievement",
                    //"utter_ask_expectation",
                    "utter_ask_challenges",
                    //"utter_ask_habit_insight",
                    "utter_ask_priority"
                ]
                if(this.state.messages.length>1 
                    && latest_message.utteranceClass!==undefined 
                    && ((latest_message.utteranceClass.length>0 && followUpUtterances.includes(latest_message.utteranceClass[latest_message.utteranceClass.length-1]))
                        || latest_message.utteranceClass.length===0 
                        )
                    && userInputBased
                    ){
                    latest_message = this.state.messages[this.state.messages.length-2];
                    //console.log('override message', latest_message)
                }
                ///////// 2. This is the leadership quality of matter in the Coaching Plan for sorting
                var lastQuestionQualities = [this.context.currentLeadershipQuality]
                var qualityDict = FindDictByKey(this.props.db, this.state.leadershipQualities, this.context.currentLeadershipQuality) || {};
                /// can sort for last message
                if(this.context.programs?.includes('default') && latest_message !== undefined && latest_message.leadership_quality!==undefined && latest_message.leadership_quality.length>0){
                    //console.log('search special for: ', latest_message.leadership_quality)
                    lastQuestionQualities = latest_message.leadership_quality;
                }
                /// add again the main topic if cut from above message
                if(!lastQuestionQualities.includes(this.context.currentLeadershipQuality)) {
                    lastQuestionQualities.unshift(this.context.currentLeadershipQuality);
                }
                if(qualityDict?.motherQuality?.length > 2 && !lastQuestionQualities.includes(qualityDict.motherQuality)){
                    lastQuestionQualities.push(qualityDict.motherQuality);
                }
                /// add todays and sisters
                if(this.context.additionalLeadershipQuality1 !== undefined && this.context.additionalLeadershipQuality1 !== '' ){
                    if(this.context.additionalLeadershipQuality2 !== undefined && this.context.additionalLeadershipQuality2 !== '' ) lastQuestionQualities.push(this.context.additionalLeadershipQuality2);
                    if(this.context.additionalLeadershipQuality3 !== undefined && this.context.additionalLeadershipQuality3 !== '' ) lastQuestionQualities.push(this.context.additionalLeadershipQuality3);
                    if(this.context.additionalLeadershipQuality4 !== undefined && this.context.additionalLeadershipQuality4 !== '' ) lastQuestionQualities.push(this.context.additionalLeadershipQuality4);
                    if(this.context.additionalLeadershipQuality5 !== undefined && this.context.additionalLeadershipQuality5 !== '' ) lastQuestionQualities.push(this.context.additionalLeadershipQuality5);
                    if(this.context.additionalLeadershipQuality6 !== undefined && this.context.additionalLeadershipQuality6 !== '' ) lastQuestionQualities.push(this.context.additionalLeadershipQuality6);
                }
                else{ 
                    var sisterQualities = (qualityDict?.sisterQualities || []);
                    if (sisterQualities !== undefined && sisterQualities.length>0){
                        lastQuestionQualities = lastQuestionQualities.concat(sisterQualities);
                    }
                }
                ///// Add Todays if special
                if(this.context.todaysLeadershipQuality?.length > 2 && !lastQuestionQualities.includes(this.context.todaysLeadershipQuality)) {
                    lastQuestionQualities.push(this.context.todaysLeadershipQuality)
                }
                ///// cut length if needed
                if(lastQuestionQualities.length > 10){
                    lastQuestionQualities = lastQuestionQualities.slice(0,9);
                }
                //console.log('lastQuestionQualities', lastQuestionQualities)
                ///////// 3. This is question intent which defined the content search results
                //var intentClass = 'success_clarity';
                var intentClassSearch = [];
                var intentClassGroup = ['sentiment'];
                if(latest_message.intentClass!== undefined && latest_message.intentClass!== ''){
                    var intentClassElements = latest_message.intentClass.split("_");
                    //console.log('arrau built', intentClassElements)
                    if(intentClassElements.length > 2){
                        //intentClass = intentClassElements[intentClassElements.length-2] + "_" + intentClassElements[intentClassElements.length-1] ;
                        intentClassGroup = [ intentClassElements[1] ];
                        intentClassSearch = [...intentClassElements.slice(2)];  
                    }
                    else if(intentClassElements.length === 2){
                        //intentClass = intentClassElements[intentClassElements.length-1] ;
                        //intentClassSearch = intentClassElements.splice(0, 1); 
                        intentClassGroup = [intentClassElements[1]] ;
                    }
                }
                ///////// 4. Defines the programs to pull from data
                var contentPrograms = ['default'];
                if(this.context.programs !== undefined && this.context.programs.length >= 1 ){
                    contentPrograms = [...this.context.programs];
                    if(this.context.hidePublicContent !== true && !contentPrograms.includes('default')){
                        contentPrograms.unshift('default');
                    }
                    if(contentPrograms.length > 10){
                        contentPrograms = contentPrograms.slice(0,9);
                    }
                    //console.log('contentPrograms', contentPrograms)
                }
                //console.log('Search tags intents', latest_message.intentClass, intentClassSearch)
                
                ///////// 5. Build search query
                
                //console.log('lastQuestionQualities', intentClass, lastQuestionQualities);
                //console.log('utterances',  utteranceClasses);
                var db2 = null;
                var db2Results = null;
                var db2SearchKey = null;
                var db = this.props.firebase.contents()
                .where('active', '==', true)
                .where('category', '==', 'tips' )
                /***
                .where('leadership_quality', 'array-contains-any', lastQuestionQualities )
                if(this.context.hidePublicContent === true){
                    db = db.where('program', '!=', 'default')
                }
                 */
                if(!this.context.programs?.includes('default') && lastQuestionQualities?.length > 0){
                    db = db.where('leadership_quality', 'array-contains-any', lastQuestionQualities )
                    //console.log('show growth plan content', contentPrograms, lastQuestionQualities)
                }
                else if(this.context.hidePublicContent === true && !this.context.programs?.includes('default')){
                    db = db.where('program', 'in', contentPrograms )
                    //console.log('hide public', contentPrograms)
                    //db = db.where('program.default', '>=', '' )
                }
                else if(contentPrograms?.length > 1){
                    db = db.where('program', 'in', contentPrograms )
                    //console.log('programs avaialble', contentPrograms)
                }
                else if(contentPrograms?.length === 1 && contentPrograms.includes('default')){
                    db = db.where('public', '==', true )
                    //console.log('default user')
                    //db = db.where('program', '==', true)
                }
                else{
                    db = db.where('public', '==', true )
                    //console.log('public')
                    //db = db.where('program', '==', true)
                }
                //console.log('itemSearch', intentClassSearch, intentClassGroup)
                if(intentClassSearch !== undefined && intentClassSearch.length > 0){
                    false && intentClassSearch.forEach( (itemSearch) => {
                        //db = db.where(('intentTags.'+itemSearch), '==', true)
                    })
                    //// add last intent fragement in 2nd search and add
                    if(intentClassSearch.length > 1){
                        ///make the second search
                        db2 = db.where(('intentTags.'+intentClassSearch[intentClassSearch.length-1]), '==', true);
                        db2SearchKey = intentClassSearch[intentClassSearch.length-1];
                        //console.log('ignore: ', intentClassSearch[0], 'search: ', intentClassSearch[intentClassSearch.length-1], intentClassSearch[intentClassSearch.length-2])
                        db = db.where(('intentTags.'+intentClassSearch[intentClassSearch.length-2]), '==', true);
                        //print(db, db2)
                    }
                    else{
                        db = db.where(('intentTags.'+intentClassSearch[0]), '==', true);
                    }
                }
                else if(intentClassGroup !== undefined && intentClassGroup.length > 0){
                    intentClassGroup.forEach( (itemSearch) => {
                        //console.log('itemCate', itemSearch)
                        db = db.where(('intentCategory.'+itemSearch), '==', true)
                    })
                }
                /// preload results to add
                if(db2 !== null){
                    db2Results = await db2.limit(50).get();
                }
                db
                //.orderBy('program', 'desc')
                //.orderBy('createdAt', 'desc')
                //.orderBy('contentVotes', 'desc')
                .limit(100)
                .get()
                .then( (snapshot) => {
                    if(snapshot && this._isMounted){
                        this.setState({ suggestionLoading: true });
                    }
                    var responseContentLvl1 = [];
                    var responseContentLvl2 = [];
                    var responseContentLvl3 = [];
                    var responseContentLvl4 = [];
                    var responseContentLvl5 = [];
                    var responseContentLvl6 = [];
                    var responseSelectionIds = [];
                    //console.log('snapshot',snapshot, snapshot.size)
                    //console.log('db2', db2Results, db2Results?.size)
                    var snapshotsCollected = [];
                    if(db2Results!==null && db2Results?.size > 2){
                        snapshotsCollected.push(db2Results);
                        //snapshot = [].concat(db2Results.docs, snapshot);
                        //snapshot.data.documents.add(db2Results.data.documents);
                    }
                    var qualityExcludeFilter = [];
                    if(this.state.dialogue?.contentSelection?.length > 0){
                        //qualityExcludeFilter = this.state.dialogue.contentSelection.map(a => a.contentId);
                        //console.log('qualityExcludeFilter', qualityExcludeFilter)
                    }
                    //console.log('snapshot sdsf',db2Results?.size, snapshot?.size)
                    snapshotsCollected.push(snapshot);
                    snapshotsCollected.forEach(snap => {
                        snap.forEach(doc => {
                            var content = doc.data();
                            content = ContentProgramOverwrite(content, this.context);
                            content = {...content, contentId: doc.id, id : doc.id  };
                            if(!qualityExcludeFilter.includes(doc.id)){
                                if(content?.leadership_quality?.length > 0
                                    && lastQuestionQualities !== undefined && lastQuestionQualities.length > 0){
                                    var filter = _.intersection( content.leadership_quality , lastQuestionQualities );
                                    var filterItemSearch = [];
                                    if(content.intentSearchTags !== undefined) {
                                        filterItemSearch = _.intersection( intentClassSearch , content.intentSearchTags ) ;
                                        //console.log('filterItemSearch', filterItemSearch, content.intentTags)
                                    }
                                    var filterRoleFlagActive = false;
                                    /// REMOVE ITEMS BY THE ROLE FILTERS
                                    if(content.requirements?.length > 0 && !content.requirements?.includes('default')) {
                                        if(!content.requirements?.includes((this.state.userRoleFilter || 'default'))){
                                            filterItemSearch = [];
                                            filterRoleFlagActive = true;
                                            //console.log('userRoleFilter missing', this.state.userRoleFilter, content.requirements)
                                        }
                                    }
                                    if(!filterRoleFlagActive){
                                        content = ContentProgramOverwrite(content, this.context);
                                        //console.log(content.contentId, content.title, content. intentTags)
                                        //console.log('db2SearchKey', db2SearchKey, filterItemSearch, content.intentSearchTags, content.leadership_quality, this.context.currentLeadershipQuality)
                                        if(db2SearchKey?.length > 1 && content.intentSearchTags?.includes(db2SearchKey) && !responseSelectionIds.includes(doc.id) && content.leadership_quality.includes(this.context.currentLeadershipQuality)){
                                            //console.log('filter tribleX',filter, content.title, content.intentTags, content.leadership_quality);
                                            //console.log(content.title, content.intentTags, content.leadership_quality )
                                            content['prio'] = 1;
                                            responseContentLvl1.push(content)
                                            responseSelectionIds.push(doc.id)
                                        }
                                        else if(db2SearchKey?.length > 1 && content.intentSearchTags?.includes(db2SearchKey) && !responseSelectionIds.includes(doc.id)){
                                            //console.log('filter direct', db2SearchKey, content.title);
                                            content['prio'] = 2;
                                            responseContentLvl2.push(content)
                                            responseSelectionIds.push(doc.id)
                                        }
                                        else if(filterItemSearch.length >= 3 && filter.length >= 1 && !responseSelectionIds.includes(doc.id)){
                                            //console.log('filter trible',filter, content.title);
                                            //console.log(content.title, content.intentTags, content.leadership_quality )
                                            content['prio'] = 3;
                                            responseContentLvl2.push(content)
                                            responseSelectionIds.push(doc.id)
                                        }
                                        else if(filterItemSearch.length >= 2 && !responseSelectionIds.includes(doc.id)){
                                            //console.log('filter double',filter,  content.title);
                                            //console.log(content.title, content.intentTags, content.leadership_quality )
                                            content['prio'] = 4;
                                            responseContentLvl3.push(content)
                                            responseSelectionIds.push(doc.id)
                                        }
                                        else if(filter.length >= 2 && !responseSelectionIds.includes(doc.id)){
                                            //console.log('filter base',filter,  content.title);
                                            //console.log(content.title, content.intentTags, content.leadership_quality )
                                            content['prio'] = 5;
                                            responseContentLvl4.push(content)
                                            responseSelectionIds.push(doc.id)
                                        }
                                        else if(filter.length >= 1  && !responseSelectionIds.includes(doc.id)){
                                            //console.log('filter simple',filter,  content.title);
                                            content['prio'] = 6;
                                            responseContentLvl5.push(content)
                                            responseSelectionIds.push(doc.id)
                                        }
                                        else if( !responseSelectionIds.includes(doc.id) && !filterRoleFlagActive){
                                            //console.log('put it anywayt')
                                            content['prio'] = 7;
                                            responseContentLvl6.push(content)
                                            responseSelectionIds.push(doc.id)
                                            //this.setState({lastRecommendedContent: doc})
                                        }
                                    }
                                }
                            }
                        })
                    })
                    /***
                    console.log(1,responseContentLvl1,
                        2,responseContentLvl2,
                        3,responseContentLvl3,
                        4,responseContentLvl4,
                        5, responseContentLvl5,
                        6, responseContentLvl6)
                     */
                    ///// SHUFFLE AND REORGANIZE RESULTS
                    var finalContentSelection = [];
                    var finalResponseSelection = [];
                    if(responseContentLvl1.length > 0){
                        finalContentSelection = _.shuffle( responseContentLvl1 );
                        finalResponseSelection = finalResponseSelection.concat(finalContentSelection);
                        //console.log('addingg', (responseContentLvl1.length),' level 1')
                    }
                    if(finalContentSelection.length < 6 && responseContentLvl2.length>0){
                        responseContentLvl2 = _.shuffle( responseContentLvl2 );
                        finalContentSelection = finalContentSelection.concat(responseContentLvl2);
                        finalResponseSelection = finalResponseSelection.concat(finalContentSelection);
                        //console.log('addingg', (responseContentLvl2.length),' level 2')
                    }
                    if(finalContentSelection.length < 6 && responseContentLvl3.length>0){
                        responseContentLvl3 = _.shuffle( responseContentLvl3 );
                        finalContentSelection = finalContentSelection.concat(responseContentLvl3);
                        finalResponseSelection = finalResponseSelection.concat(finalContentSelection);
                        //console.log('addingg', (responseContentLvl3.length),' level 3')
                    }
                    if(finalContentSelection.length < 6 && responseContentLvl4.length>0){
                        responseContentLvl4 = _.shuffle( responseContentLvl4 );
                        finalContentSelection = finalContentSelection.concat(responseContentLvl4);
                        finalResponseSelection = finalResponseSelection.concat(finalContentSelection);
                        //console.log('addingg', (responseContentLvl4.length),' level 4')
                    }
                    if(finalContentSelection.length < 6 && responseContentLvl5.length>0){
                        responseContentLvl5 = _.shuffle( responseContentLvl5 );
                        finalContentSelection = finalContentSelection.concat(responseContentLvl5);
                        //console.log('addingg', (responseContentLvl5.length),' level 5')
                    }
                    if(finalContentSelection.length < 6 && responseContentLvl6.length>0){
                        responseContentLvl6 = _.shuffle( responseContentLvl6 );
                        finalContentSelection = finalContentSelection.concat(responseContentLvl6);
                        //console.log('addingg', (responseContentLvl6.length),' level 6')
                    }
                    /***
                    else if(responseContentLvl1.length > 3 && responseContentLvl2.length>3){
                        responseContentLvl1 = _.shuffle( responseContentLvl1 );
                        responseContentLvl1 = responseContentLvl1.slice(0,2);
                        responseContentLvl2 = _.shuffle( responseContentLvl2 );
                        finalContentSelection = responseContentLvl1.concat(responseContentLvl2);
                        //console.log('shuffle filter n qualit', finalContentSelection)
                    }
                    else if(responseContentLvl2.length > 0){
                        responseContentLvl2 = _.shuffle( responseContentLvl2 );
                        responseContent = _.shuffle( responseContent );
                        finalContentSelection = responseContentLvl2.concat(responseContent);
                        //console.log('shuffle only filter', finalContentSelection)
                    }
                    else{
                        finalContentSelection = _.shuffle( responseContent );
                        //console.log('shuffle rest', finalContentSelection)
                    }
                    */
                    //// slice array
                    if(finalContentSelection.length > 5){
                        finalContentSelection = finalContentSelection.slice(0,5);
                    }
                    else if(finalContentSelection.length < 3 && this.state.dialogue !== undefined 
                        && this.state.dialogue?.contentSelection !== undefined && this.state.dialogue?.contentSelection.length > 3 ){
                        //console.log('switch to content selection', finalContentSelection)
                        //finalContentSelection = this.state.dialogue?.contentSelection;
                    }
                    if(finalResponseSelection.length > 5){
                        finalResponseSelection = finalResponseSelection.slice(0,5);
                    }
                    //////// UPLOAD EVERYTHING
                    if(this._isMounted ){
                        //console.log('responseContent', finalResponseSelection, finalContentSelection)
                        if(true || this.context?.features === undefined || this.context?.features?.mentormode !== false){
                            showQuestionTipsInChat = true;
                        }
                        var responseSearchQuestion = latest_message;
                        if(finalContentSelection === undefined || finalContentSelection === null || finalContentSelection.length === 0){
                            showQuestionTipsInChat = false;
                            responseSearchQuestion = undefined;
                        }
                        //console.log('responseContent search',  responseSearchQuestion, latest_message)
                        var contentUpdateDict = { 
                            responseContent: finalContentSelection,
                            responseSearchQuestion, 
                            suggestionLoading: false, 
                            showQuestionTipsInChat: showQuestionTipsInChat,
                            showResponseTipsInChat: false
                        }
                        if(userInputBased || flagContentOnUserResponse){
                            //console.log('start extras flag')
                            var responseTipsSummary = [];
                            var responseTipsTitles = [];
                            finalResponseSelection.map((item, index) => {
                                if(item.explanation?.length > 0 && !responseTipsTitles.includes(item.title)){
                                    var items = item.explanation;
                                    responseTipsTitles.push(item.title);
                                    responseTipsSummary.push(items[Math.floor(Math.random()*items.length)]);
                                }
                                else{
                                    finalResponseSelection.splice(index, 1);
                                }
                                return true
                            })
                            if(responseTipsSummary?.length>0){
                                contentUpdateDict['responseTipsSummary'] = responseTipsSummary;
                                contentUpdateDict['responseRecommendation'] = finalResponseSelection;
                                contentUpdateDict['responseContent'] = undefined;
                                contentUpdateDict['responseSearchQuestion'] = undefined;
                                contentUpdateDict['showResponseTipsInChat'] = true;
                                contentUpdateDict['showQuestionTipsInChat'] = false;
                                //console.log('updatems',contentUpdateDict )
                            }
                            else if (userInputBased){
                                contentUpdateDict['responseTipsSummary'] = undefined;
                                contentUpdateDict['responseRecommendation'] = undefined;
                                contentUpdateDict['showResponseTipsInChat'] = false;
                                //console.log('no extra' )
                            }
                            //console.log('recommendation', finalResponseSelection, responseTipsSummary)
                        }
                        this.setState(contentUpdateDict);
                    }
                });
            }
            catch (error){
                if(this._isMounted ){
                    this.setState({responseContent: [], responseSearchQuestion: undefined, suggestionLoading: false, showQuestionTipsInChat: false});
                    console.log('failure load questions',error)
                }
            } 
        }
    }

    messageExchange = async (messageDict, chatSession, db) => {
        //console.log('messageDict', messageDict)
        let receiveMessage = await chatAgent.sendMessage(messageDict, chatSession, db);
        var addMessageFailure = {};
        var messages = this.state.messages || [];
        if(receiveMessage === undefined || receiveMessage.length === 0){
            //console.log('receiveMessage', receiveMessage, this.state.messages)
            addMessageFailure = {
                text : ['Sorry, the connection was bad and I missed what you said...'],
                utteranceClass : ['inform'],
                isCoach: true,
                uid: 'error',
                messageFilter: 'bot'
            };
            if(this._isMounted){
                messages.push(addMessageFailure);
                this.setState({
                    messages: messages,
                    messageListById: ['preload_message'], 
                    loading: false,  
                    spinner: false,
                    showTextInput: true
                });
            }
            //console.log('receiveMessage', receiveMessage, this.state.messages)
            return receiveMessage;
        }
        else if(chatSession === 'questioningAI' && receiveMessage[0]?.issue !== undefined){
            console.log('(wake up call ignore', receiveMessage[0].issue);
        }
        else if(receiveMessage[0]?.issue !== undefined){
            //console.log('(receiveMessage[0].issue', receiveMessage, receiveMessage[0].issue);
            receiveMessage[0]['text'] = 'I hear you. What else?';
            receiveMessage = [receiveMessage[0]]; /// Skip all the others
            //console.log('receiveMessage x', receiveMessage, this.state.messages)
            var data = {
                connectionError: true
            }
            this.props.firebase.dialogue(this.state.currentDialogueId).update(data);
            return receiveMessage;
        }
        else if(false && receiveMessage[0]?.issue !== undefined){
            console.log('(receiveMessage[0].issue', receiveMessage[0].issue);
            //console.log('receiveMessage x', receiveMessage, this.state.messages)
            var botMessageList = [];
            var utteranceClassList = [];
            receiveMessage.forEach(e => {
                //console.log('plus',e)
                e["text"] && botMessageList.push(e["text"] + " ");
                utteranceClassList.push('inform');
            });
            addMessageFailure = {
                text : botMessageList,
                utteranceClass : utteranceClassList,
                isCoach: true,
                uid: 'error',
                messageFilter: 'bot'
            };
            if(this._isMounted){
                messages.push(addMessageFailure);
                this.setState({
                    messages: messages,
                    messageListById: ['preload_message'], 
                    loading: false,  
                    spinner: false,
                    showTextInput: true
                });
            }
            //console.log('receiveMessage x', receiveMessage, this.state.messages)

            var phase = 'session_ended_earlier';
            var data = {
                phase: phase,
                dialogueEnded: true,
                connectionError: true
            }
            this.props.firebase.dialogue(this.state.currentDialogueId).update(data);
            return receiveMessage;
        }
        else{
            return receiveMessage;
        }
    }

    cutIntoOneSentence(text){
        var result = text;
        var checkresult = (result + ".").toLowerCase();
        //console.log('checkresult',checkresult)
        if(checkresult.includes("how") || checkresult.includes("who") || checkresult.includes("when") || checkresult.includes("where") || checkresult.includes("why") || checkresult.includes("which") || checkresult.includes("what")){
            console.log('return full question')
            return text;
        }
        else if(checkresult?.length > 20){
            try{
                //var sentenceArray = text.match(/\(?[^\.\?\!]+[\.\!\?]\)?/g);
                var sentenceArray = checkresult.match(/\(?[^.]+( but | But | or | Or | however | However )\)?|\(?[^\.\?\!]+[\.\?\!]\)?/g);
                if(sentenceArray){
                    sentenceArray = sentenceArray.filter(item => (item?.length > 4));
                    if(sentenceArray?.length>0){
                        var tempResult = sentenceArray[sentenceArray.length-1];
                        if(tempResult?.length > 20 && checkresult.length !== tempResult.length){
                            result = tempResult;
                            //console.log('split result', result, sentenceArray);
                        }
                    }
                }
                return result;
            }
            catch(e){
                return text;
            }
        }
        else return text;
    }

    onSkip = (text='skip') => {
        this.setState({speedDialOpen : false});
        this.onSubmit(text);
        //console.log('message skipped', this.state.lastBotQuestion)
    }
    onChangeQuestion = () => {
        this.setState({speedDialOpen : false , changeQuestionAvailable : this.state.changeQuestionAvailable - 1, animateMomentByName : 'spin'});
        this.onSubmit('Change Question');
    }
    createMetaData(text, sendHistory = false){
        var metadata = {}
        var textPrompt = '';
        var extraPrompt = '';
        var textPromptHistory = this.state.textPromptHistory || [];
        if(!ignoreTexts.includes(text)){
            if(this.state.tabSelection === 'Mentor' || sendHistory){
                metadata['mode'] = 'Mentor';
                var textChain = ''
                if(textPromptHistory.length>0){
                    textPromptHistory.map((item, index) => {
                        item = item.replace('I want ', 'to ');
                        item = item.replace('.', ' ');
                        if (textPromptHistory.length <=3 || (textPromptHistory.length > 3 && index > textPromptHistory.length-2) ){
                            textChain = (textChain === '' ? ( item ) : (item + ', and maybe ' + textChain ))
                        }
                        return true
                    })
                    textChain = 'first ' + text + ', and consider ' + textChain;
                    textChain = textChain.replace('I want ', 'to ');
                    textChain = textChain.replace('.', ' ');
                }
                else{
                    textChain = '' + text;
                    textChain = textChain.replace('I want ', 'to ');
                    textChain = textChain.replace('.', ' ');
                }
                metadata['textPromptHistory'] = textPromptHistory;
                if(!textPromptHistory.includes(text)){
                    textPromptHistory.push(text);
                }
                //textPrompt = 'What is the best way to improve ' + textChain +'?';
                textPrompt = ''+text;
                extraPrompt = textChain;
            }
            else if(this.state.tabSelection === 'Goals'){
                metadata['mode'] = 'Goals';
                extraPrompt = 'I want to improve, ' + text;
                textPrompt = '' + text;
                metadata['textPromptHistory'] = textPromptHistory;
                if(!textPromptHistory.includes(text)){
                    textPromptHistory.push(text);
                } 
            }
            else{
                metadata['mode'] = 'Coach';
                /// CUT TEXT
                textPrompt = this.cutIntoOneSentence(''+text);
                //textPrompt = '' + text;
                /// FULL TEXT
                extraPrompt = text;
                metadata['textPromptHistory'] = textPromptHistory;
                if(!textPromptHistory.includes(text)){
                    textPromptHistory = [text];
                }
            }
            this.setState({textPromptHistory});
        }
        if(textPrompt.length > 5){
            metadata['textPrompt'] = textPrompt;
        }
        if(extraPrompt.length > 5){
            metadata['extraPrompt'] = extraPrompt;
        }
        var latest_message = this.state.messages?.[this.state.messages?.length-1] || {};
        if(latest_message?.utteranceClass?.length > 0){
            metadata['utteranceClass'] = latest_message.utteranceClass[latest_message.utteranceClass.length-1];
        }
        if(this.context?.features?.mentormode === false){
            metadata['mode'] = 'OnlyCoaching';
        }
        //console.log('metadata', metadata, textPromptHistory)
        return metadata
    }

    checkforMentorNeedByIntent(e, failureCheck = false){
        //// detect any Intent Information to be added into the chat
        var latestUserIntent;
        var latestUserSentiment;
        if(e?.["attachment"]?.["intent"]?.length>0) {
            latestUserIntent = e["attachment"]["intent"];
            if(e["attachment"]?.["sentiment"]?.length>0) {
                latestUserSentiment = e["attachment"]["sentiment"];
            };
        };
        if(latestUserIntent?.length>1){
            var coachingOnly = (this.context?.features?.mentormode === false || false);
            ////// 
            //console.log('moment flag', latestUserIntent)
            if(!coachingOnly && latestUserIntent.includes('request_consulting') || latestUserIntent.includes('question') || latestUserIntent.includes('request_help') || latestUserIntent.includes('reason_ignorance')){
                this.setState({tabSelection: 'Mentor'})
                //console.log('question flag')
            }
            else if(!coachingOnly && failureCheck && latestUserIntent.includes('inform_failure')){
                this.setState({tabSelection: 'Mentor'})
                //this.setState({animateMomentByName : 'intention'}); 
            }
            //console.log('stats found last intent', engagementSet.latest.intentClass, engagementSet.latest);
            else if(latestUserIntent.includes('inform_intention')){
                this.setState({tabSelection: 'Coach'})
                //this.setState({animateMomentByName : 'intention'}); 
            }
            else if(latestUserIntent.includes('inform_accomplishment')){
                this.setState({animateMomentByName : 'accomplishment', tabSelection: 'Coach'}); 
            }
            else if(latestUserIntent.includes('inform_sentiment')){
                this.setState({animateMomentByName : 'happiness', tabSelection: 'Coach'}); 
            }
            else{
                this.setState({tabSelection: 'Coach'}); 
            }
        }
    }

    onSubmit = async (text) => {
        //this.setState({tabSelection: 'Coach'});
        //console.log('qupte')
        this.quoteGenerator();
        if(this.timeoutTextInput !== undefined && this.timeoutTextInput !== null){
            try{
                //console.log('reove timeout')
                clearTimeout(this.timeoutTextInput);
            }
            catch(error ){
                console.log('timeout clearance', error)
            }
        }
        if(this.state.typingBotFinishTypingNow === false){
            this.setState({typingBotFinishTypingNow: true, typingBotDone: true},
                () => {
                    this.setState({typingBotFinishTypingNow: false, typingBotDone: false});
                    //console.log('removing typing done')
                }
            );
            //console.log('removing typing done')
        }
        else if(this.state.typingBotFinishTypingNow === true){
            this.setState({typingBotFinishTypingNow: false, typingBotDone: false});
            //console.log('removing typing done')
        }
        if(false && !ignoreTexts.includes(text)){
            //this.setState({animatePlus1Trigger: true});
        }
        if(this.state.currentDialogueId !== undefined){
            if(this.state.dialogue?.phase === 'session_ended' || this.state.dialogue?.phase === 'created'){
                console.log('overwrite dialogue phase')
                this.props.firebase.dialogue(this.state.currentDialogueId).update({
                    phase: 'running'
                });
            }
        }
        if(this.context?.features?.questioning === true){
            //console.log('current stage', this.state.dialogue?.dialogueStage)
            if(this.state.dialogue !== undefined && this.state.dialogue?.questioningAIList !== undefined && this.state.dialogue?.questioningAIList !== null && this.state.dialogue?.questioningAIList.length > 4 ){
                this.requestConversationItem(text);
            }
            else if(!(this.state.dialogue?.dialogueStage?.awareness?.phase === 'ended')){
                console.log('questioning AI');
                this.requestQuestioningAi(text);
            }
            else{
                this.requestConversationItem(text);
            }
        }
        else{
            this.requestConversationItem(text);
        }
        if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
            //console.log('input message scrooll')
            //this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
            //console.log('input seond scrooll')
            this.refMessagesEnd.scrollIntoViewIfNeeded()
        }
        this.setState({inspirationText: ""});
        return true;
    }
    requestConversationItem = async (text, extraFlag='default') => {
        //var flagIgnoreText = false;
        //console.log('onsubmit',text)
        if(text!=="" && this._isMounted){
            //console.log('onsubmit',text)
            //console.log('saveTask: ', this.state.saveTask);
            var lastBotMessageId = this.state.lastBotMessageId;
            var stateUpdate = {
                inspirationText: "",
                showTextInput: false,
                text: '',
                spinner: true,
                responseContent: [],
                typingBotDone: false,
                //typingBotFinishTypingNow: false,
                showQuestionTipsInChat: false
            };
            if(ignoreTexts.includes(text)){
                stateUpdate['saveTask'] = '';
                //flagIgnoreText = true;
            }
            else{
                switch (this.state.saveTask) {
                    case 'askHelp':
                        stateUpdate['saveTask'] = '';
                        stateUpdate['askHelp'] = text;
                        stateUpdate['openTask'] = true;
                        break;
                    case 'askReflectionTopic':
                        stateUpdate['saveTask'] = '';
                        stateUpdate['askReflectionTopic'] = text;
                        stateUpdate['openTask'] = true;
                        break;
                    case 'askFeedback':
                        stateUpdate['saveTask'] = '';
                        stateUpdate['askFeedback'] = text;
                        stateUpdate['openTask'] = true;
                        break;
                    default:
                        stateUpdate['saveTask'] = '';
                        break;
                }
            }
            //var spinner = false;
            this.setState(stateUpdate);
            ////// these parameters are used to update the message with the userinbput
            var latestUserMessageId = '';
            var latestUserIntent = '';
            var latestUserSentiment = '';
            var latestUserCoachIntent = '';
            ////// END
            ///// Adding the new message into the DB
            if(extraFlag !== 'skipAddingMessage'){
                this.props.firebase.messages().add({
                    userId: this.context.uid,
                    isCoach: false,
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                    dialogueId: this.state.currentDialogueId,
                    text: [text],
                    messageFilter: 'bot',
                    timezone: (this.context.timezone || 'UTC')
                })
                .then(ref => {
                    latestUserMessageId = ref.id;
                });
            }
    
            let botMessage = '';
            let botMessageList = [];
            let botAttachmentEventList = [];
            let botUtteranceClassList = [];
            let botExplanationList = [];
            /////// Modify for mentor mode
            var metadata = this.createMetaData(text);
            //// Create message extensions
            var newMessageDict = { 
                "sender": this.context.uid, 
                "message": metadata.textPrompt?.length > 10 ? metadata.textPrompt: text, 
                "metadata": metadata 
            }
            //console.log('submit newMessageDict', newMessageDict)
            if (this.state.dialogue!==null  && this.state.dialogue?.dialogueType === 'priming') {
                botMessage = await this.messageExchange(newMessageDict, 'primingSession', this.props.firebase);
            } 
            else {
                botMessage = await this.messageExchange(newMessageDict, 'reflectionSession', this.props.firebase);
            }
            //// Empty button
            var buttons = [];
            //// Get out all extra information from the AI responses including intents, buttons, events and so on
            botMessage.forEach(e => {
                //console.log('plus',e)
                e["text"] && botMessageList.push(e["text"] + " ");
                e["attachment"] && e["attachment"]["event"] && botAttachmentEventList.push(e["attachment"]);
                e["attachment"] && e["attachment"]["utterance_class"] && botUtteranceClassList.push(e["attachment"]["utterance_class"]);
                e["attachment"] && e["attachment"]["explanation"] && botExplanationList.push(e["attachment"]["explanation"]);
                //// detect any buttons to be added into the chat
                if(e["buttons"] && e["buttons"].length>0) {
                    buttons = (e["buttons"])
                };
                this.checkforMentorNeedByIntent(e);
            });
            //// UPDATE ARTICLE SEARCH
            this.searchContentRecommendationsForQuestion(false, true);
            //// update engagements stats with upgraded info
            //console.log('article search', latestUserMessageId)
            if(!ignoreTexts.includes(text) && text.length>2 && latestUserMessageId !== ''){
                var latestUserMessageSet = {
                    points: 1,
                    userId: this.context.uid,
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                    type:'chat',
                    leadership_category: this.context.currentCategory,
                    leadership_quality: [QualityID(this.context.currentLeadershipQuality)],
                    dialogueId: this.state.currentDialogueId,
                    messageId: latestUserMessageId,
                    message: [text],
                };
                if(latestUserIntent !== ''){
                    latestUserMessageSet['intentClass'] = latestUserIntent;
                    latestUserMessageSet['intentUser'] = {sentiment: latestUserSentiment};
                    var updateMessageDict = {
                        intentClass: latestUserIntent, 
                        intentUser: {sentiment: latestUserSentiment}
                    };
                    if(latestUserCoachIntent !== ''){
                        updateMessageDict['intentCoach'] = {intent: latestUserCoachIntent};
                        latestUserMessageSet['intentCoach'] = {intent: latestUserCoachIntent};
                    }
                    //console.log('added the update setting with intent', latestUserMessageSet)
                    this.props.firebase.message(latestUserMessageId).set(updateMessageDict, {merge: true});
                }
                this.props.firebase.insightsEngagement(latestUserCoachIntent).doc(latestUserMessageId).set(latestUserMessageSet, {merge:true });
            }
    
            // Task handling
            if (botUtteranceClassList.length > 0 && this._isMounted) {
                if (botUtteranceClassList.includes('utter_ask_help')) {
                    //console.log('utter_ask_help');
                    this.setState({ saveTask: 'askHelp' });
                } else if (botUtteranceClassList.includes('utter_ask_reflection_topic')) {
                    //console.log('utter_ask_reflection_topic');
                    this.setState({ saveTask: 'askReflectionTopic' });
                } else if (botUtteranceClassList.includes('utter_ask_feedback')) {
                    //console.log('utter_ask_feedback');
                    this.setState({ saveTask: 'askFeedback' });
                } else {
                    //console.log('no utterance class selected');
                    this.setState({ saveTask: '' });
                }
            }
    
            // Dialogue ending handling
            //console.log('botAttachmentEventList', text, botAttachmentEventList)
            // event: "dialogue_end"
            // utterance_class: "utter_goodbye"
            var dataDialogueUpdate = {}
            if (botAttachmentEventList.length > 0) {
                var spinner = false;
                if(this._isMounted ){
                    //console.log("coming from event")
                    this.setState({
                        spinner: spinner,
                    });
                }
                var xpEnd = 0;
                if(this.context.engagementCounter !== undefined && this.context.engagementCounter>0){
                    xpEnd = this.context.engagementCounter;
                }
                dataDialogueUpdate = {
                    phase: 'session_ended',
                    openTask: this.state.openTask,
                    askHelp: this.state.askHelp,
                    askReflectionTopic: this.state.askReflectionTopic,
                    askFeedback: this.state.askFeedback,
                    dialogueEnded: true,
                    xpEnd
                }
                if(this.state.flagEndChatEarlier){
                    dataDialogueUpdate['phase'] = 'session_ended_earlier';
                }
                if(this.state.counterQuestions > 1){
                    dataDialogueUpdate["dialogueStats"] = {counterQuestions : this.state.counterQuestions, counterResponses: this.state.counterResponses, counterReflections: this.state.counterReflections}
                }
                await this.props.firebase.dialogue(this.state.currentDialogueId).update(dataDialogueUpdate);
                var ctaText = '';

                if(this.state.askHelp!== '' && this.state.askHelp.length>30) ctaText = this.state.askHelp;
                if(this.state.askReflectionTopic!== '' && this.state.askReflectionTopic.length>30) ctaText = this.state.askReflectionTopic;

                if (this.state.dialogue!==null  && this.state.dialogue?.dialogueType === 'priming') {
                    await this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'primingSession', this.props.firebase);
                    this.props.firebase.user(this.context.uid).update({
                        firstSessionDone: true,
                        dialogueStatus: 'ended',
                        primingSessionFinishedAt: this.props.firebase.fieldValue.serverTimestamp(),
                    });
                } else {
                    await this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'reflectionSession', this.props.firebase);
                    this.props.firebase.user(this.context.uid).update({
                        firstSessionDone: true,
                        dialogueStatus: 'ended',
                        reflectionSessionFinishedAt: this.props.firebase.fieldValue.serverTimestamp(),
                    });
                }
                //HN for the quotes generation
                if (this.state.leadershipCategories === undefined){
                    this.loadCategoriesAndQualities()
                };
                if (ctaText !== ''){
                    this.props.firebase.user(this.context.uid).update({
                        ctaText: ctaText
                    });
                }
                this.props.firebase.dialoguePostprocess(this.state.currentDialogueId, 'dialogueEnded').set(dataDialogueUpdate, {merge:true});
                if(this.context.dialogueCounter === undefined || this.context.dialogueCounter < 1){
                    AddAnalytics('goal_completion', 'GoalCompleteChat');
                }
                else if(this.context.dialogueCounter >= 1 && this.context.dialogueCounter < 4){
                    AddAnalytics('returning', 'GoalCompleteChat');
                }
            }
            // Explanation handling
            else if(botExplanationList.length > 0){
                dataDialogueUpdate = {
                    currentExplanation: botExplanationList.join(' ')
                }
                if(this.state.counterQuestions > 1){
                    dataDialogueUpdate["dialogueStats"] = {counterQuestions : this.state.counterQuestions, counterResponses: this.state.counterResponses, counterReflections: this.state.counterReflections}
                }
                await this.props.firebase.dialogue(this.state.currentDialogueId).update(dataDialogueUpdate);
            }
            else{
                dataDialogueUpdate = {
                    currentExplanation: ''
                }
                if(this.state.counterQuestions > 1){
                    dataDialogueUpdate["dialogueStats"] = {counterQuestions : this.state.counterQuestions, counterResponses: this.state.counterResponses, counterReflections: this.state.counterReflections}
                }
                await this.props.firebase.dialogue(this.state.currentDialogueId).update(dataDialogueUpdate);
            }
    
            //HN added utterance and qualities class into section
            //console.log('current quality', QualityID(this.context.currentLeadershipQuality))
            //console.log('current category', this.context.currentCategory)
            //console.log('current leadership', QualityID(this.context.todaysLeadershipQuality))
            var leadershipQualities = [];
            if(this.context.todaysLeadershipQuality?.length > 0){
                leadershipQualities = [
                    QualityID(this.context.currentLeadershipQuality), 
                    QualityID(this.context.todaysLeadershipQuality)
                ];
            }
            else{
                leadershipQualities = [QualityID(this.context.currentLeadershipQuality)];
            }
            var messageDict = {
                userId: this.context.uid,
                isCoach: true,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                dialogueId: this.state.currentDialogueId,
                text: botMessageList,
                utteranceClass: botUtteranceClassList,
                // quality and category question comes here in most context
                leadership_category: this.context.currentCategory,
                leadership_quality: QualityID(leadershipQualities),
                messageFilter: 'bot',
                timezone: (this.context.timezone || 'UTC')
            }
            if(buttons !== null && buttons !== undefined && buttons?.length > 0){
                messageDict['buttons'] = buttons;
            }
            // Save bot message
            this.props.firebase.messages().add(messageDict);
            if(this._isMounted ){
                this.setState({ spinner: true, botUtteranceClassList: botUtteranceClassList});
            }
            this.checkSetSubscriptionFlag();
            //HN upvote downvote message in relation to answer 
            this.voteQuestion(text, lastBotMessageId);
            if (this.state.leadershipCategories === undefined){ 
                this.loadCategoriesAndQualities() ;
                //console.log('loading categories')
            };

        }
        else if (this._isMounted) {
            this.setState({text:"I need help with "})
        }
        return true;
    }
    sendMeasurement = async (text='') => {
        //console.log('sendmeasure')
        this.quoteGenerator();
        if(this._isMounted ){
            this.setState({
                spinner: true,
                showTextInput: false,
                inspirationText: "",
                text: "",
                typingBotDone: false,
                typingBotFinishTypingNow: false,
                animatePlus1Trigger: true 
            });
        }
        let botMessage = '';
        let botMessageList = [];
        let botExplanationList = [];
        //HN added utterance class into section
        let botUtteranceClassList = [];
        let dialogueId = this.state.currentDialogueId;
        var lastBotMessageId = this.state.lastBotMessageId;
        if(text!==''){
            this.props.firebase.messages().add({
                userId: this.context.uid,
                isCoach: false,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                dialogueId: dialogueId,
                text: [text],
                messageFilter: 'bot',
                timezone: (this.context.timezone || 'UTC')
            })
            .then(ref => {
                if(true){
                    this.props.firebase.insightsEngagement().doc(ref.id).set(
                        {
                            points :1,
                            userId: this.context.uid,
                            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                            type:'chat',
                            leadership_category: this.context.currentCategory,
                            leadership_quality: [QualityID(this.context.currentLeadershipQuality)],
                            dialogueId: this.state.currentDialogueId,
                            messageId: ref.id,
                            message: [text],
                        }, {merge:true }
                    );
                }
            });
            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                measurement: Math.round(3),
                phase: 'utterance_sent'
            });
            if (this.state.dialogue!==null && this.state.dialogue?.dialogueType === 'priming') {
                botMessage = await this.messageExchange({ "sender": this.context.uid, "message": text }, 'primingSession', this.props.firebase);
            }
            else {
                botMessage = await this.messageExchange({ "sender": this.context.uid, "message": text }, 'reflectionSession', this.props.firebase);
            }

        }
        else{
            var measurementValue = Math.round(this.state.value)
            var newTextSent = measurementValue.toString()
            if (measurementValue>=1 && measurementValue<=5){
                if(this.state.dialogue?.dialogueType === 'priming'){
                    newTextSent = wordsMood["priming"][newTextSent]
                }
                else{
                    newTextSent = wordsMood["reflection"][newTextSent]
                }
            }

            this.props.firebase.messages().add({
                userId: this.context.uid,
                isCoach: false,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                dialogueId: dialogueId,
                text: [newTextSent],
                messageFilter: 'bot',
                timezone: (this.context.timezone || 'UTC')
            })
            .then(ref => {
                if(true){
                    this.props.firebase.insightsEngagement().doc(ref.id).set(
                        {
                            points :1,
                            userId: this.context.uid,
                            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                            type:'chat',
                            leadership_category: this.context.currentCategory,
                            leadership_quality: [QualityID(this.context.currentLeadershipQuality)],
                            dialogueId: this.state.currentDialogueId,
                            messageId: ref.id,
                            message: ['start'],
                        }, {merge:true }
                    );
                }
            });
            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                measurement: measurementValue,
                phase: 'utterance_sent'
            });

            if (this.state.dialogue!==null && this.state.dialogue?.dialogueType === 'priming') {
                let measurementInfo = `/inform_measurement{"energy_slot":${measurementValue.toString()}}`;
                botMessage = await this.messageExchange({ "sender": this.context.uid, "message": measurementInfo }, 'primingSession', this.props.firebase);
            } 
            else {
                let measurementInfo = `/inform_measurement{"satisfaction_slot":${measurementValue.toString()}}`;
                botMessage = await this.messageExchange({ "sender": this.context.uid, "message": measurementInfo }, 'reflectionSession', this.props.firebase);
            }
        }
        var buttons = [];
        botMessage.forEach(e => { 
            e["text"] && botMessageList.push(e["text"]); 
            //HN added utterance class into section
            e["attachment"] && e["attachment"]["utterance_class"] && botUtteranceClassList.push(e["attachment"]["utterance_class"]);
            if(e["buttons"] && e["buttons"].length>0) {
                buttons = (e["buttons"])
            };
            this.checkforMentorNeedByIntent(e, true);
        });

        // Explanation handling
        botMessage.forEach(e => {
            e["attachment"] && e["attachment"]["explanation"] && botExplanationList.push(e["attachment"]["explanation"]);
        });
        if (botExplanationList.length > 0) {
            localStorage?.setItem('explanation', botExplanationList.join(' '));
        } else {
            localStorage?.setItem('explanation', '');
        }

        //console.log('START botMessageList', botMessageList)
        var messageDict = {
            userId: this.context.uid,
            isCoach: true,
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            dialogueId: this.state.currentDialogueId,
            text: botMessageList,
            isBotStart: true,
            //HN added utterance and category class into section as this is the mindset questions
            //leadership_category: this.context.currentCategory,
            utteranceClass: botUtteranceClassList,
            messageFilter: 'bot',
            timezone: (this.context.timezone || 'UTC')
        }

        if(buttons?.length > 0){
            messageDict['buttons'] = buttons;
        }
    
        await this.props.firebase.messages().add(messageDict);

        await this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'running'
        });
        
        if(this._isMounted ){
            this.setState({
                spinner: true,
                value: undefined
            });
        }
        if(this.context.journeyStartedAt) {
            this.props.firebase.user(this.context.uid).update({
                dialogueStatus: 'running',
            });
        } else {
            this.props.firebase.user(this.context.uid).update({
                dialogueStatus: 'running',
                journeyStartedAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
        }
        this.voteQuestion('Start reflection.', lastBotMessageId);
        return true;
    }
    request = async (openChat = false, restartChat = false) => {
        /// request openchat
        if(openChat){
            //console.log('overwrite default')
            this.props.firebase.user(this.context.uid).update({
                dialogueStatus: 'open'
            });
        }
        else if(this.context.dialogueStatus === 'open'){
            this.props.firebase.user(this.context.uid).update({
                dialogueStatus: 'running'
            });
        }
        //console.log('Request initiated for a new chat!');
        let botMessage = '';
        let botMessageList = [];
        //HN added utterance class into section
        let botUtteranceClassList = [];
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'session_requested',
            timezone: (this.context.timezone || 'UTC'),
            userRoleFilter : (this.state.userRoleFilter || 'default')
        });
        if(this._isMounted ){
            this.setState({ 
                botUtterance: botMessageList,
                inspirationText: "",
                loadingRequest: true, // ensure that is has an extra format to show case
                typingBotDone: false,
                showTextInput: false,
                animatePlus1Trigger: true,
                flagInitialRequest: (restartChat ? false : true)
            });
        }
        if (this.state.dialogue!==null && this.state.dialogue?.dialogueType === 'priming') {
            await this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'primingSession', this.props.firebase);
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": "/request_priming_session" }, 'primingSession', this.props.firebase);
        } 
        else {
            await this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'reflectionSession', this.props.firebase);
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": "/request_reflection_session" }, 'reflectionSession', this.props.firebase);
        }
        var buttons = [];
        botMessage.forEach(e => { 
            e["text"] && botMessageList.push(e["text"]); 
            //HN added utterance class into section
            e["attachment"] && e["attachment"]["utterance_class"] && botUtteranceClassList.push(e["attachment"]["utterance_class"]);
            if(e["buttons"] && e["buttons"].length>0) {
                buttons = (e["buttons"])
            };
            if(openChat === false) this.checkforMentorNeedByIntent(e);
        });
        if(this._isMounted ){
            this.setState({ 
                spinner: true,
                botUtterance: botMessageList, 
                typingBotDone: false,
                showTextInput: false,
                animatePlus1Trigger: false 
            });
        }
        //this.props.firebase.user(this.context.uid).update({ dialogueStatus: 'session_requested'});
        var messageDict = {
            userId: this.context.uid,
            isCoach: true,
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            dialogueId: this.state.currentDialogueId,
            text: botMessageList,
            //HN added utterance class into section
            utteranceClass: botUtteranceClassList,
            messageFilter: 'bot',
            timezone: (this.context.timezone || 'UTC')
        }
        if(buttons !== null && buttons !== undefined && buttons.length > 0){
            messageDict['buttons'] = buttons;
        }
        //console.log('request new messageDict', messageDict)
        this.props.firebase.messages().add(messageDict)
        .then(() => {
            if(this._isMounted ){
                console.log('stop spinner')
                this.setState({ 
                    spinner: false
                });
            }
        });
        return true;
    }
    requestAddMore = async (measurement = 3) => {
        //console.log('request add more initiated')
        let botMessage = '';
        let botMessageList = [];
        let botExplanationList = [];
        //console.log('measure', measurement, this.state.currentDialogueId)
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'running', extend: 'running',
        });
        if(this._isMounted ){
            this.setState({
                inspirationText: "",
                //loading: false, 
                spinner: true, 
                animatePlus1Trigger: true 
            });
        }
        //HN added utterance class into section
        let botUtteranceClassList = [];
        if (this.state.dialogue!==null && this.state.dialogue?.dialogueType === 'priming') {
            await this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'primingSession', this.props.firebase);
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": "/request_priming_session" }, 'primingSession', this.props.firebase);
            //console.log('botMessage', botMessageStart)
            let measurementInfo = `/inform_measurement{"energy_slot":${Math.round(measurement).toString()}}`;
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": measurementInfo }, 'primingSession', this.props.firebase);
        //console.log('botMessage2', botMessage)
        } 
        else {
            await this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'reflectionSession', this.props.firebase);
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": "/request_reflection_session" }, 'reflectionSession', this.props.firebase);
            let measurementInfo = `/inform_measurement{"satisfaction_slot":${Math.round(measurement).toString()}}`;
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": measurementInfo }, 'reflectionSession', this.props.firebase);
        }
        botMessage.forEach(e => { 
            e["text"] && botMessageList.push(e["text"]); 
            //HN added utterance class into section
            e["attachment"] && e["attachment"]["utterance_class"] && botUtteranceClassList.push(e["attachment"]["utterance_class"]);
            this.checkforMentorNeedByIntent(e, true);
        });

        // Explanation handling
        botMessage.forEach(e => {
            e["attachment"] && e["attachment"]["explanation"] && botExplanationList.push(e["attachment"]["explanation"]);
        });
        if (botExplanationList.length > 0) {
            localStorage?.setItem('explanation', botExplanationList.join(' '));
        } else {
            localStorage?.setItem('explanation', '');
        }

        //console.log('botMessage3', botMessageList)
    
        /**/
        //await this.props.firebase.messages().add({
        this.props.firebase.messages().add({
            userId: this.context.uid,
            isCoach: true,
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            dialogueId: this.state.currentDialogueId,
            text: botMessageList,
            isBotStart: true,
            //HN added utterance and category class into section as this is the mindset questions
            //leadership_category: this.context.currentCategory,
            utteranceClass: botUtteranceClassList,
            messageFilter: 'bot',
            timezone: (this.context.timezone || 'UTC')
        });
        if(this._isMounted ){
            this.setState({ spinner: true, botUtteranceClassList: botUtteranceClassList});
        }
        //this.setState({ botUtterance: botMessageList, spinner: false});
        this.props.firebase.user(this.context.uid).update({
            dialogueStatus: 'running'
        });
        //console.log('finished', botMessageList)
        return true;
    }
    requestContinue = async (openChat = false) => {
        if(openChat){
            console.log('overwrite default')
            this.props.firebase.user(this.context.uid).update({
                dialogueStatus: 'open'
            });
        }
        let botMessage = '';
        let botMessageList = [];
        let botExplanationList = [];
        //console.log('measure', this.state)
        if(this._isMounted ){
            this.setState({
                inspirationText: "",
                loading: true, 
                spinner: true,
                animatePlus1Trigger: true 
            });
        }
        //this.setState({ botUtterance: botMessageList, spinner: false});
        this.props.firebase.user(this.context.uid).update({
            //'features.advancedmode': true,
            dialogueStatus: (openChat? 'open' : 'running')
        });
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'running', extend: 'running'
        });
        this.props.firebase.messages().add({
            userId: this.context.uid,
            isCoach: false,
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            dialogueId: this.state.currentDialogueId,
            text: ['Please start the open chat.'],
            //HN added utterance and category class into section as this is the mindset questions
            //leadership_category: this.context.currentCategory,
            utteranceClass: 'inform_activity_learning_reflection',
            messageFilter: 'bot',
            timezone: (this.context.timezone || 'UTC')
        })
        .then( () => {
            //this.props.firebase.dialogue(this.state.currentDialogueId).update({ phase: 'running', extend: 'running' });
        })
        //HN added utterance class into section
        let botUtteranceClassList = [];
        if (this.state.dialogue!==null && this.state.dialogue?.dialogueType === 'priming') {
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": "/request_continue_session" }, 'primingSession', this.props.firebase);
        } 
        else {
            botMessage = await this.messageExchange({ "sender": this.context.uid, "message": "/request_continue_session" }, 'reflectionSession', this.props.firebase);
        }
        var buttons = [];
        botMessage.forEach(e => { 
            e["text"] && botMessageList.push(e["text"]); 
            //HN added utterance class into section
            e["attachment"] && e["attachment"]["utterance_class"] && botUtteranceClassList.push(e["attachment"]["utterance_class"]);
            // Explanation handling
            e["attachment"] && e["attachment"]["explanation"] && botExplanationList.push(e["attachment"]["explanation"]);
            //// detect any buttons to be added into the chat
            if(e["buttons"] && e["buttons"].length>0) {
                buttons = (e["buttons"])
            };
            if(openChat === false) this.checkforMentorNeedByIntent(e, true);
        });
        if (botExplanationList.length > 0) {
            localStorage?.setItem('explanation', botExplanationList.join(' '));
        } else {
            localStorage?.setItem('explanation', '');
        }
        var messageDict = {
            userId: this.context.uid,
            isCoach: true,
            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            dialogueId: this.state.currentDialogueId,
            text: botMessageList,
            isBotStart: true,
            utteranceClass: botUtteranceClassList,
            messageFilter: 'bot',
            timezone: (this.context.timezone || 'UTC')
        }
        if(buttons !== null && buttons !== undefined && buttons?.length > 0){
            messageDict['buttons'] = buttons;
        }
        await this.props.firebase.messages().add(messageDict);
        if(this._isMounted ){
            this.setState({ spinner: true, botUtteranceClassList: botUtteranceClassList});
        }
        return true;
    }
    requestContinueUnfinished = async () => {
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'running', extend: 'running'
        });
        this.props.firebase.user(this.context.uid).update({
            dialogueStatus: 'running'
        });
        return true;
    }
    requestQuestioningAi = async (text) => {
        //console.log('onsubmit',text)
        if(text!=="" && this._isMounted){
            //console.log('onsubmit',text)
            //console.log('saveTask: ', this.state.saveTask);
            var lastBotMessageId = this.state.lastBotMessageId;
            if(ignoreTexts.includes(text)){
                this.setState({ saveTask: '' });
            }
            else{
                switch (this.state.saveTask) {
                case 'askHelp':
                    this.setState({ askHelp: text, saveTask: '', openTask: true });
                    break;
                case 'askReflectionTopic':
                    this.setState({ askReflectionTopic: text, saveTask: '', openTask: true });
                    break;
                case 'askFeedback':
                    this.setState({ askFeedback: text, saveTask: '', openTask: true });
                    break;
                default:
                    this.setState({ saveTask: '' });
                    break;
                }
            }
            //var spinner = false;
            this.setState({
                inspirationText: '',
                showTextInput: false,
                text: '',
                spinner: true,
                typingBotDone: false,
                //typingBotFinishTypingNow: false,
                showQuestionTipsInChat: false
            });
    
            this.props.firebase.messages().add({
                userId: this.context.uid,
                isCoach: false,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                dialogueId: this.state.currentDialogueId,
                text: [text],
                messageFilter: 'bot',
                timezone: (this.context.timezone || 'UTC')
            })
            .then(ref => {
                if(text !== "skip" && text.length>10){
                    this.props.firebase.insightsEngagement().doc(ref.id).set({
                            points :1,
                            userId: this.context.uid,
                            createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                            type:'chat',
                            leadership_category: this.context.currentCategory,
                            leadership_quality: [QualityID(this.context.currentLeadershipQuality)],
                            dialogueId: this.state.currentDialogueId,
                            messageId: ref.id,
                            message: [text],
                        }, {merge:true }
                    );
                }
            });
    
            let botMessage = '';
            let botMessageList = [];
            //let botAttachmentEventList = [];
            let botUtteranceClassList = [];
            
            var textCombi = 'What do you think about it? ' + text;
            if(this.state.messages !== undefined && this.state.messages[this.state.messages.length-1]?.text !== undefined){
                textCombi = this.state.messages[this.state.messages.length-1]?.text?.slice(-1)[0] +' '+text;
            }
            ///// SENDING BOT MESSAGE
            //console.log('before QAI', text)
            if(text.length < 10){
                botMessage = await this.messageExchange({ "sender": this.context.uid, "story": textCombi }, 'questioningAI', this.props.firebase);
            }
            else{
                botMessage = await this.messageExchange({ "sender": this.context.uid, "story": text }, 'questioningAI', this.props.firebase);
            }
            //console.log('botMessage', botMessage, this.state.dialogue)
            ///// WORK WITH REPONSE
            if(botMessage !== undefined && botMessage !== null && botMessage.output !== undefined && botMessage.output.questions !== undefined ){
                var dataDialogueUpdate = {}

                let botExplanationList = [];
                let questioningAIList = [];
                if(this.state.dialogue !== undefined && this.state.dialogue?.questioningAIList !== undefined && this.state.dialogue?.questioningAIList !== null && this.state.dialogue?.questioningAIList.length >=1 ){
                    questioningAIList = this.state.dialogue?.questioningAIList;
                    //console.log('overwrite', questioningAIList, this.state.dialogue)
                }
                var followUpQuestions = botMessage.output.questions;
                for (var i = 0; i < followUpQuestions.length; i++) {
                    var e = followUpQuestions[i];
                    var questionAIText = e["markdown"] ;
                    questionAIText = questionAIText.replaceAll('**', "'").trim();
                    //console.log('e', e)
                    if( questionAIText!== undefined && questionAIText.length > 1 && !questioningAIList.includes(questionAIText)){
                        botMessageList.push(questionAIText + " ");
                        questioningAIList.push(questionAIText);
                        dataDialogueUpdate['questioningAIList'] = questioningAIList;
                        //console.log('e questioningAIList', questionAIText, questioningAIList)
                        if(e["statement"] && e["statement"].length > 1){
                            botExplanationList.push(e["statement"]);
                        }
                        break;
                    }
                };

                if(botMessageList.length === 0){
                    console.log('skipped questiolnAI went to standard')
                    this.requestConversationItem(text, 'skipAddingMessage')
                }
                else{
                    // Dialogue ending handling
                    //console.log('botAttachmentEventList',botAttachmentEventList)
                    // event: "dialogue_end"
                    // utterance_class: "utter_goodbye"
                    if(botExplanationList.length > 0){
                        dataDialogueUpdate['currentExplanation'] = botExplanationList.join(' ');
                        if(this.state.counterQuestions > 1){
                            dataDialogueUpdate["dialogueStats"] = {counterQuestions : this.state.counterQuestions, counterResponses: this.state.counterResponses, counterReflections: this.state.counterReflections}
                        }
                        //console.log('dataDialogueUpdate XXXX', dataDialogueUpdate)
                        await this.props.firebase.dialogue(this.state.currentDialogueId).update(dataDialogueUpdate);
                    }
                    else{
                        dataDialogueUpdate['currentExplanation'] = '';
                        if(this.state.counterQuestions > 1){
                            dataDialogueUpdate["dialogueStats"] = {counterQuestions : this.state.counterQuestions, counterResponses: this.state.counterResponses, counterReflections: this.state.counterReflections}
                        }
                        //console.log('dataDialogueUpdate', dataDialogueUpdate)
                        await this.props.firebase.dialogue(this.state.currentDialogueId).update(dataDialogueUpdate);
                    }
                    //console.log('dataDialogueUpdate after',dataDialogueUpdate)
            
                    //HN added utterance and qualities class into section
                    //console.log('current quality', QualityID(this.context.currentLeadershipQuality))
                    //console.log('current category', this.context.currentCategory)
                    //console.log('current leadership', QualityID(this.context.todaysLeadershipQuality))
                    var leadershipQualities = [];
                    if(this.context.todaysLeadershipQuality?.length > 0){
                        leadershipQualities = [QualityID(this.context.currentLeadershipQuality), QualityID(this.context.todaysLeadershipQuality)];
                    }
                    else{
                        leadershipQualities = [QualityID(this.context.currentLeadershipQuality)];
                    }
                    // Save bot message
                    this.props.firebase.messages().add({
                        userId: this.context.uid,
                        isCoach: true,
                        createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                        dialogueId: this.state.currentDialogueId,
                        text: botMessageList,
                        utteranceClass: botUtteranceClassList,
                        // quality and category question comes here in most context
                        leadership_category: this.context.currentCategory,
                        leadership_quality: QualityID(leadershipQualities),
                        messageFilter: 'bot',
                        timezone: (this.context.timezone || 'UTC')
                    });
                    if(this._isMounted ){
                        this.setState({ spinner: true, botUtteranceClassList: botUtteranceClassList});
                    }
                    this.checkSetSubscriptionFlag();
                    //HN upvote downvote message in relation to answer 
                    this.voteQuestion(text, lastBotMessageId);
                    if (this.state.leadershipCategories === undefined){ 
                        this.loadCategoriesAndQualities() ;
                        //console.log('loading categories')
                    };
                }
            }
            else{
                //console.log('skipped questiolnAI went to straight')
                this.requestConversationItem(text, 'skipAddingMessage')
            }

        }
        else if (this._isMounted) {
            this.setState({text:"I need help with "})
        }
        return true;
    }

    voteQuestion(text, lastBotMessageId){
        //console.log('vote message',text, lastBotMessageId)
        if(lastBotMessageId !== undefined && lastBotMessageId!==''){
            //HN upvote downvote message in relation to answer
            if(text === 'Retry Question'){

            }
            else if(ignoreTexts.includes(text) && lastBotMessageId!==undefined){
                this.props.firebase.message(lastBotMessageId).update({
                    vote: 'down',
                    voteSource: 'skip'
                });
                //console.log('vote skip', lastBotMessageId, text)
            }
            else if(text.length >= 50 && lastBotMessageId!==undefined){
                this.props.firebase.message(lastBotMessageId).update({
                    vote: 'up',
                    voteSource: '>50'
                });
                //console.log('vote 50', lastBotMessageId, text)
            }
            else if(text.length >= 1 && lastBotMessageId!==undefined){
                this.props.firebase.message(lastBotMessageId).update({
                    vote: 'up',
                    voteSource: 'response'
                });
                //console.log('vote response', lastBotMessageId, text)
            }
        }
    }

    saveRoutineBeforeFeedback = async () => {
        this.props.firebase.user(this.context.uid).update({
            dialogueStatus: 'ended'
        });
        if (this.state.dialogue!==null  && this.state.dialogue?.dialogueType === 'priming') {
            this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'primingSession', this.props.firebase);
        } 
        else {
            this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'reflectionSession', this.props.firebase);
        }
        if(this.context.engagementCounter!== undefined && this.context.engagementCounter<=15){
            this.props.history.push(ROUTES.PROGRAM);
        }
        else{
            this.props.history.push(ROUTES.PROGRAM);
        }
    }
    saveRoutineAndFeedback = async () => {
        await this.props.firebase.user(this.context.uid).update({
            dialogueStatus: 'feedback'
        });
        if (this.state.dialogue!==null  && this.state.dialogue?.dialogueType === 'priming') {
            this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'primingSession', this.props.firebase);
        } 
        else {
            this.messageExchange({ "sender": this.context.uid, "message": "/request_reset" }, 'reflectionSession', this.props.firebase);
        }
        var xpEnd = 0;
        if(this.context.engagementCounter !== undefined && this.context.engagementCounter>0){
            xpEnd = this.context.engagementCounter;
        }
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'feedback',
            xpEnd
        });
    }
    saveFeedbackAndEnd = async (earlier=true) => {
        this.setState({feedbackOpen:false, flagEndChatEarlier: true, tabSelection: 'Chat'})
        //this.onSubmit('Goodbye');
        this.props.firebase.user(this.context.uid).update({
            dialogueStatus: 'ended'
        });
        var xpEnd = 0;
        if(this.context.engagementCounter !== undefined && this.context.engagementCounter>0){
            xpEnd = this.context.engagementCounter;
        }
        if(earlier){
            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                phase: 'session_ended_earlier',
                feedback: this.state.feedback,
                xpEnd
            });
        }
        else{
            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                phase: 'session_ended',
                feedback: this.state.feedback,
                xpEnd
            });
        }
        if(this.state.dialogue?.dialogueType === 'reflection')  {
            this.props.firebase.user(this.context.uid).update({
                firstSessionDone: true,
                dialogueStatus: 'ended',
                reflectionSessionFinishedAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
        }
        else if(this.state.dialogue?.dialogueType === 'priming')  {
            this.props.firebase.user(this.context.uid).update({
                firstSessionDone: true,
                dialogueStatus: 'ended',
                primingSessionFinishedAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
        }
    }
    saveFeedbackAndLeave = async () => {
        this.props.firebase.user(this.context.uid).update({
            dialogueStatus: 'ended'
        });
        var xpEnd = 0;
        if(this.context.engagementCounter !== undefined && this.context.engagementCounter>0){
            xpEnd = this.context.engagementCounter;
        }
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
            phase: 'session_ended',
            feedback: this.state.feedback,
            xpEnd
        });
        this.setState({feedbackOpen:false})
        this.props.history.push(ROUTES.PROGRAM);
    }

    handleFeedbackClose = () => {
        if(this.state.feedback !== undefined && this.state.feedback !== ''){
            this.props.firebase.dialogue(this.state.currentDialogueId).update({
                feedback: this.state.feedback,
            });
        }
        if(this.state.feedbackOnCancel){
            this.props.history.push(ROUTES.PROGRAM);
        }
        else{
            this.setState({feedbackOpen : false})
        }
    }

    onChange = event => {
        this.setState({ text: event.target.value });
        if(this.state.texfieldisNonFocus === undefined && this._isMounted) {
            this.setState({texfieldisNonFocus: false})
        }
    };

    onFeedbackChange = event => {
        if(this._isMounted ){
            this.setState({ feedback: event.target.value });
        }
    };

    upvote = () => {
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
        vote: 'up',
        });
    }
    
    downvote = () => {
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
        vote: 'down',
        });
    }

    devote = () => {
        this.props.firebase.dialogue(this.state.currentDialogueId).update({
        vote: '',
        });
    }

    dialogShowExplanation = (hiddenButton=false) => {
        const { classes, theme } = this.props;                 
        return(
            (this.state.dialogue!==null && (this.state.dialogue?.currentExplanation || (this.state.dialogue?.contentSelection !== undefined 
                && this.state.dialogue?.contentSelection.length > 0) || this.state.dialogue?.phase !== 'session_requested' ))
                ?
                    <div className={this.state.showTextInput ? classes.textInputVisible : classes.textInputHidden}>
                        <RemoveScroll enabled={this.state.open || false}>  
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleClose}
                                fullScreen={IsMobileDevice()}
                                PaperProps={{style:theme.dialogWindow}}
                                TransitionComponent={Transition}
                                maxWidth="sm"
                                >
                                <DialogTitle style={theme.dialogTitle} >
                                    <Grid container spacing={0} direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item >
                                            <IconButton onClick={this.handleClose}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <span style={theme.dialogTitle.titleText}>
                                                Inspiration
                                            </span>
                                        </Grid>
                                        <Grid item>
                                            <span style={theme.dialogTitle.titleLink} onClick={this.handleClose}>
                                                Cancel
                                            </span>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent style={theme.dialogContentMain}>
                                    <div style={{ marginTop: 0 }}>&nbsp;</div> 

                                    {this.state.lastBotQuestion!==undefined && this.state.lastBotQuestion!== "" 
                                        && 
                                        <p>{this.state.lastBotQuestion}</p>
                                    }
                                    
                                    {this.state.dialogue!==null && this.state.dialogue?.currentExplanation!==undefined && this.state.dialogue?.currentExplanation!==""
                                        && 
                                            <FormControlLabel
                                                onClick={() => this.setState({showFeedback: true})}
                                                aria-label="Inspiration"
                                                control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                                label= {
                                                    <span style={theme.textSupport}>
                                                        {!this.state.showFeedback && this.state.dialogue?.currentExplanation?.length > 100 ? this.state.dialogue.currentExplanation.substring(0, 97) + "..." : this.state.dialogue.currentExplanation}
                                                    </span>
                                                }
                                            />
                                    }
                                    {/**
                                    <strong>{this.state.dialogue?.currentExplanation}</strong>
                                    */}
                                    {this.state.suggestionLoading !== true
                                        ?
                                            <>
                                                {this.state.flagShowGoalSuggestion === true && 
                                                    ((this.state.dialogue?.contentSelection !== undefined  && this.state.dialogue?.contentSelection.length > 0)
                                                        ||
                                                    (this.state.responseContent!== undefined  && this.state.responseContent.length > 0) )
                                                        &&
                                                            <div>
                                                                <Grid container spacing={0} direction="row"
                                                                    onClick={() => this.setState({flagShowGoalSuggestion: undefined}) }
                                                                    justify="space-between"
                                                                    alignItems="center">
                                                                    <Grid item >
                                                                        <p className={classes.explanation}>Build your response:
                                                                        </p> 
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Button>
                                                                            Build &nbsp;<SpellcheckIcon fontSize='small' style={{color: theme.palette.themeRed}}   />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                <table width="100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><hr /></td>
                                                                            <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                                                <span className={classes.socialLoginPrompt}>or</span>
                                                                            </td>
                                                                            <td><hr /></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                }
                                                {this.state.flagShowGoalSuggestion && 
                                                    ((this.state.dialogue?.contentSelection !== undefined  && this.state.dialogue?.contentSelection.length > 0)
                                                        ||
                                                    (this.state.responseContent!== undefined  && this.state.responseContent.length > 0) )
                                                    ?
                                                    <div>
                                                        <p className={classes.explanation}>Choose response ideas: </p> 
                                                        <ShowGoalSuggestion
                                                            questionIntent = {
                                                                this.state.messages !== undefined && this.state.messages!==null && this.state.messages.length>0
                                                                ? this.state.messages[this.state.messages.length-1]
                                                                : undefined
                                                            }
                                                            submitInspiration = { (text) => this.submitInspiration(text)}
                                                            contentSelection = {
                                                                this.state.responseContent!== undefined && this.state.responseContent.length > 0
                                                                ? this.state.responseContent
                                                                : this.state.dialogue?.contentSelection
                                                            }
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.state.flagShowGoalSuggestion !== false &&
                                                        ((this.state.dialogue?.contentSelection !== undefined  && this.state.dialogue?.contentSelection.length > 0)
                                                            ||
                                                        (this.state.responseContent!== undefined  && this.state.responseContent.length > 0) )
                                                            ?
                                                                <>
                                                                    <Accordion 
                                                                        expanded={this.state.expandedSuggestions || false}
                                                                        TransitionProps={{ mountOnEnter: true }}
                                                                        onChange={() => {this.setState({expandedSuggestions: true})}}
                                                                        style={{padding: 0, backgroundColor: 'transparent', boxShadow: "none"}}
                                                                    >
                                                                        <AccordionSummary
                                                                            //expandIcon={<ExpandMoreIcon />}
                                                                            style={{padding: 0, backgroundColor: 'transparent', width:'100%'}}
                                                                            aria-label="Code-Expand"
                                                                            aria-controls="Code-content"
                                                                            id="Code-header"
                                                                            >
                                                                            <div style={{width: '100%'}}>
                                                                                <div className={classes.explanation}>
                                                                                    Choose Commitment:<br/>
                                                                                    {this.state.expandedSuggestions !== true &&
                                                                                        <div style={{textAlign:'center'}}>
                                                                                            <Button
                                                                                                //variant="contained"
                                                                                                style={{
                                                                                                    marginTop: 12,
                                                                                                    marginRight: 5,
                                                                                                    marginLeft: 5,
                                                                                                    animation: this.state.waitingForResponseTimeout? 'pulse 4s infinite ease-in-out' : 'none',
                                                                                                }}
                                                                                                onClick={() => this.setState({commitType: 'should'})}
                                                                                            >
                                                                                                I should ...
                                                                                            </Button>
                                                                                            <Button
                                                                                                //variant="contained"
                                                                                                style={{
                                                                                                    marginTop: 12,
                                                                                                    marginRight: 5,
                                                                                                    marginLeft: 5,
                                                                                                    animation: this.state.waitingForResponseTimeout? 'pulse 4s infinite ease-in-out' : 'none',
                                                                                                }}
                                                                                                onClick={() => this.setState({commitType: 'wish'})}
                                                                                            >
                                                                                                I wish ...
                                                                                            </Button>
                                                                                            <Button
                                                                                                //variant="contained"
                                                                                                style={{
                                                                                                    marginTop: 12, 
                                                                                                    marginRight: 5,
                                                                                                    marginLeft: 5,
                                                                                                    animation: this.state.waitingForResponseTimeout? 'pulse 4s infinite ease-in-out' : 'none',
                                                                                                }}
                                                                                                onClick={() => this.setState({commitType: 'will'})}
                                                                                            >
                                                                                                I will ...
                                                                                            </Button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails 
                                                                            style={{padding: 0, backgroundColor: 'transparent' }}
                                                                        >
                                                                            <div style={{with: "100%"}}>
                                                                                <ShowGoalSuggestion
                                                                                    questionIntent = {
                                                                                        this.state.messages !== undefined && this.state.messages!==null && this.state.messages.length>0
                                                                                        ? this.state.messages[this.state.messages.length-1]
                                                                                        : undefined
                                                                                    }
                                                                                    actionFlag={true}
                                                                                    commitType={this.state.commitType || undefined}
                                                                                    submitInspiration = { (text) => this.submitInspiration(text)}
                                                                                    contentSelection = {
                                                                                        this.state.responseContent!== undefined && this.state.responseContent.length > 0
                                                                                        ? this.state.responseContent
                                                                                        : this.state.dialogue?.contentSelection
                                                                                    }
                                                                                />
                                                                                <p>
                                                                                    <br/>
                                                                                    <br/>
                                                                                    <Button
                                                                                        onClick = {() => this.setState({expandedSuggestions: false})}
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                    >
                                                                                        Restart
                                                                                    </Button>
                                                                                </p>
                                                                            </div>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    {false &&
                                                                        <table width="100%">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><hr /></td>
                                                                                    <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                                                        <span className={classes.socialLoginPrompt}>or</span>
                                                                                    </td>
                                                                                    <td><hr /></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    }
                                                                </>
                                                            :
                                                                <>
                                                                    <p className={classes.explanation}>Build your Response: </p> 
                                                                    <IconSentence
                                                                        messages={this.state.messages}
                                                                        currentCategory={this.context.currentCategory}
                                                                        showAnswers={this.state.flagShowGoalSuggestion}
                                                                        userId={this.context.uid}
                                                                        handleActivateSentence={(activate) => {
                                                                            this.setState({flagShowGoalSuggestion : activate});
                                                                            if(activate === undefined){
                                                                                this.setState({expandedSuggestions: false});
                                                                            }
                                                                        }}
                                                                        contextQuestion={this.state.lastBotQuestion!==undefined && this.state.lastBotQuestion!== "" ? this.state.lastBotQuestion : this.state.dialogue?.currentExplanation}
                                                                        selectorContext={ this.state.lastBotIntent }
                                                                        messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                                        messageSend = {(text) => {this.setState({open: false, expandedSuggestions: false}); this.onSubmit(text)}}
                                                                        contentSelection = {
                                                                            this.state.responseContent!== undefined && this.state.responseContent.length > 0
                                                                            ?   this.state.responseContent
                                                                            :   this.state.dialogue!== undefined && this.state.dialogue!== null && this.state.dialogue?.contentSelection!== undefined
                                                                                ? this.state.dialogue?.contentSelection
                                                                                : undefined
                                                                        }
                                                                    /> 
                                                                </>
                                                        }
                                                    </div>
                                                }
                                            </>
                                        :
                                            <div>
                                                <LoadingProgress 
                                                    defaultSet={this.props.defaultSet}
                                                    type={'LoadingPageAnimated'} loadingText={"loading inspiration"} firebaseConnectionSpeed={this.state.firebaseConnectionSpeed} />
                                            </div>
                                    }
                                    
                                    <div style={{ marginTop: 10 }}>&nbsp;</div> 
                                </DialogContent>
                            </Dialog>
                        </RemoveScroll>
                        <Backdrop 
                            open={this.state.speedDialOpen || false}
                            onClick={() => this.setState({speedDialOpen : false})}
                            style={{ zIndex: 30, backgroundColor: theme.palette.themeDarkBlue, opacity: 0.95 }}
                        />
                        {true &&
                            <SpeedDial
                                ariaLabel="Chat Options"
                                //sx={{ position: 'relative', right: 16 }}
                                style={{
                                    zIndex: 100, 
                                    //marginTop: -400, 
                                    marginTop: -280, 
                                    // (this.refTextInput !== undefined && this.refTextInput.measure !== undefined)? 
                                    position:  'relative',
                                    alignItems: 'flex-end',
                                    visibility: ((hiddenButton && this.state.speedDialOpen !== true )? 'hidden': 'visible')
                                }}
                                FabProps={{
                                    style: {
                                        //backgroundColor: this.state.speedDialOpen? theme.palette.themeGold : theme.palette.secondary.light
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none'
                                    }
                                }}
                                icon={<></>}
                                onClick={() => this.setState({speedDialOpen : this.state.speedDialOpen ? false : true})}
                                open={this.state.speedDialOpen || false}
                            >
                                {false // (this.context.dialogueCounter > 2 )
                                    ?
                                        <SpeedDialAction
                                            style={{
                                                //marginBottom: 'calc(-25vh + 27px)',
                                                paddingLeft: 60
                                            }}
                                            icon={this.props.defaultSet?.programLogo?.includes('http')
                                                    ?   <ForumIcon style={{color: theme.palette.black}}/>
                                                    :   <RockyIcon style={{color: theme.palette.black}}/>
                                                }
                                            tooltipTitle={
                                                <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.black}}>Continue Chat</strong>
                                            }
                                            tooltipOpen = {true}
                                            onClick={() =>  {
                                                this.setState({speedDialOpen: false, handleOpenTutorial: false});
                                            }}
                                        />
                                    :
                                        <SpeedDialAction
                                            style={{paddingLeft: 60}}
                                            icon={<SkipNextIcon style={{color: theme.palette.black}}/>}
                                            tooltipTitle={
                                                <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.black}}>Skip Question</strong>
                                            }
                                            //tooltipOpen = {IsMobileDevice()? true : false}
                                            tooltipOpen = {true}
                                            onClick={() => {
                                                this.setState({speedDialOpen : false});
                                                this.sendMeasurement('skip');
                                            }}
                                        />
                                }
                                {this.state.dialogue?.contentSelection?.length > 0 
                                    ?
                                        <SpeedDialAction
                                            style={{
                                                paddingLeft: 60,
                                            }}
                                            icon={<AddCircleIcon 
                                                style={{
                                                    color: theme.palette.black,
                                                    animation: this.state.waitingForResponseTimeout? 'pulse 4s infinite ease-in-out' : 'none',
                                                }}
                                            />}
                                            tooltipTitle={
                                                <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.black}}>Build Response</strong>
                                            }
                                            //tooltipOpen = {IsMobileDevice()? true : false}
                                            tooltipOpen = {true}
                                            onClick={() => {
                                                this.setState({speedDialOpen : false});
                                                this.handleOpen();
                                            }}
                                        />
                                    :   ""
                                }
                                {this.state.tabSelection === 'Mentor'
                                    ?
                                        <SpeedDialAction
                                            style={{
                                                paddingLeft: 60,
                                            }}
                                            icon={<WidgetsIcon style={{color: theme.palette.themeAlertGreen}}/>}
                                            tooltipTitle={
                                                <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.themeAlertGreen}}>Next Question</strong>
                                            }
                                            tooltipOpen = {true}
                                            onClick={() => {
                                                this.setState({speedDialOpen : false, tabSelection: 'Coach'});
                                                this.onSubmit("Next!");
                                            }}
                                        />
                                    :
                                        <SpeedDialAction
                                            style={{
                                                paddingLeft: 60,
                                            }}
                                            icon={<WidgetsIcon style={{color: theme.palette.themeAlertGreen}}/>}
                                            tooltipTitle={
                                                <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.themeAlertGreen}}>Mentoring & Tips</strong>
                                            }
                                            tooltipOpen = {true}
                                            onClick={() => {
                                                this.setState({speedDialOpen : false});
                                                this.handleTipsOpen();
                                            }}
                                        />
                                }
                                <SpeedDialAction
                                    style={{
                                        paddingLeft: 60,
                                    }}
                                    icon={<OfflinePinIcon style={{color: theme.palette.themeAlertRed}}/>}
                                    tooltipTitle={
                                        <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.themeAlertRed}}>Show Results</strong>
                                    }
                                    //tooltipOpen = {IsMobileDevice()? true : false}
                                    tooltipOpen = {true}
                                    onClick={() => {
                                        this.setState({speedDialOpen : false});
                                        this.saveFeedbackAndEnd(true);
                                    }}
                                />
                                <SpeedDialAction
                                    style={{
                                        paddingLeft: 60,
                                    }}
                                    icon={<TuneIcon style={{color: 'black'}}/>}
                                    tooltipTitle={
                                        <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.black}}>Open Settings</strong>
                                    }
                                    //tooltipOpen = {IsMobileDevice()? true : false}
                                    tooltipOpen = {true}
                                    onClick={() =>  {
                                        this.setState({speedDialOpen : false, dialogSettingsOpen: true, handleOpenTutorial: false});
                                    }}
                                />
                                {this.context.dialogueCounter > 4
                                ?
                                    <SpeedDialAction
                                        //style={{paddingBottom: 60}}
                                        style={{paddingLeft: 60}}
                                        icon={<AssessmentIcon style={{color: theme.palette.themeGrey}}/>}
                                        tooltipTitle={
                                            <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.themeGrey}}>Show Feedback</strong>
                                        }
                                        //tooltipOpen = {IsMobileDevice()? true : false}
                                        tooltipOpen = {true}
                                        onClick={() =>  {
                                            this.setState({speedDialOpen : false, feedbackOpen: true});
                                        }}
                                    />
                                :
                                    <SpeedDialAction
                                        style={{
                                            paddingLeft: 60,
                                        }}
                                        icon={<HelpOutlineIcon style={{color: 'green'}}/>}
                                        tooltipTitle={
                                            <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: "green"}}>Open Tutorial</strong>
                                        }
                                        //tooltipOpen = {IsMobileDevice()? true : false}
                                        tooltipOpen = {true}
                                        onClick={() =>  {
                                            this.setState({speedDialOpen : false});
                                            this.setState({handleOpenTutorial: true});
                                        }}
                                    />
                                }
                                {/***
                                <SpeedDialAction
                                    style={{
                                        //marginBottom: 'calc(-25vh + 27px)',
                                        //marginBottom: '30vh',
                                        paddingLeft: 60,
                                    }}
                                    icon={<TuneIcon style={{color: 'black'}}/>}
                                    tooltipTitle={
                                        <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.black}}>Open Settings</strong>
                                    }
                                    //tooltipOpen = {IsMobileDevice()? true : false}
                                    tooltipOpen = {true}
                                    onClick={() =>  {
                                        this.setState({speedDialOpen : false, dialogSettingsOpen: true, handleOpenTutorial: false});
                                    }}
                                />
                                */}
                                {/***
                                <SpeedDialAction
                                    icon={<SkipNextIcon style={{color: theme.palette.black}}/>}
                                    tooltipTitle={
                                        <strong style={{whiteSpace:'nowrap', fontSize: 16, backgroundColor: theme.palette.white, color: theme.palette.black}}>Skip Question</strong>
                                    }
                                    //tooltipOpen = {IsMobileDevice()? true : false}
                                    tooltipOpen = {true}
                                    onClick={() => {
                                        this.setState({speedDialOpen : false});
                                        this.sendMeasurement('skip');
                                    }}
                                />
                                 */}
                            </SpeedDial>
                        }
                    </div>
                :
                    ""
            
        )
    }

    dialogShowTips = () => {
        const { classes, theme } = this.props;                 
        return(
            <RemoveScroll enabled={this.state.tipsOpen || false}>  
                <Dialog
                    open={this.state.tipsOpen || false}
                    onClose={this.handleTipsClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                >
                    <DialogTitle style={theme.dialogTitle} >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.handleTipsClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Tips and Articles
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.handleTipsClose}>
                                    Close
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {this.state.tipsOpen &&
                        <DialogContent style={theme.dialogContentMain}>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                >

                                {this.state.lastBotQuestion!==undefined && this.state.lastBotQuestion!== "" 
                                    && 
                                    <p>{this.state.lastBotQuestion}</p>
                                }

                                {this.state.dialogue!==null && this.state.dialogue?.currentExplanation!==undefined && this.state.dialogue?.currentExplanation!==""
                                    && 
                                    <strong>{this.state.dialogue?.currentExplanation}</strong>
                                }

                                {this.context !== undefined && this.context !== null
                                    && this.state.responseContent !== undefined 
                                    && this.state.responseContent.length > 0 
                                    && this.state.suggestionLoading===false
                                    ?
                                        <Grid item xs={12} >
                                            <p className={classes.explanation}>Following articles and tips are related to the recent questions and comments. Click a picture to read them.</p> 
                                            <HomeCardContentItems
                                                user={this.context}
                                                defaultSet={this.props.defaultSet || undefined}
                                                visualLimit={5}
                                                raised = {true}
                                                specialQuery={'contentByIds'}
                                                direction={'column'}
                                                titleOfGroup={"Recommended Tips"}
                                                allowUpdate={false}
                                                qualityFilter = {this.state.responseContent.map(a => a.contentId)}
                                                swipe={true}
                                                linkToContent={false}
                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                leadershipCategories={this.state.leadershipCategories}
                                                leadershipQualities={this.state.leadershipQualities}
                                                allQualitiesLoaded = { [] }
                                            />
                                        </Grid>
                                    :   
                                        <p className={classes.explanation}>
                                            To find more tips and articles, I need more answers from you.
                                        </p>
                                }
                                {false && this.context !== undefined && this.context !== null
                                    && this.state.responseContent !== undefined 
                                    && this.state.responseContent.length > 0 
                                    && this.state.suggestionLoading===false
                                    &&
                                        <Grid item xs={12}>
                                            <p className={classes.explanation}>Read Tips: </p> 
                                            <div style={{marginLeft: -20, marginRight: -20}}>
                                                <ContentSuggestCarousel
                                                    user={this.context}
                                                    contentSelection={this.state.responseContent}
                                                    leadershipCategories={this.state.leadershipCategories}
                                                    leadershipQualities={this.state.leadershipQualities}
                                                    messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                    closeAfterActionAdded = {true}
                                                    />
                                            </div>
                                        </Grid>
                                }

                                {this.props.showScore && this.state.dialogue !== undefined && this.state.dialogue?.wordCollection !== undefined 
                                        &&
                                        <Grid item xs={12} >
                                            <p className={classes.explanation}>Following tips and ideas are related to your personal development plan:</p> 
                                            <MindfulnessScore 
                                                dialogue={this.state.dialogue}  
                                                user={this.context}
                                                leadershipCategories={this.state.leadershipCategories}
                                                leadershipQualities={this.state.leadershipQualities}
                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                            />
                                        </Grid>
                                }
                            </Grid>
                        </DialogContent>
                    }
                </Dialog>
            </RemoveScroll> 
        )
    }

    dialogShowFeedback = () => {
        const { classes, theme } = this.props;                 
        return(
            <RemoveScroll enabled={this.state.feedbackOpen || false}>  
                <Dialog
                    open={this.state.feedbackOpen || false}
                    onClose={this.handleFeedbackClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={theme.dialogTitle} >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.handleFeedbackClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Feedback
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.handleFeedbackClose}>
                                    Close
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {this.state.feedbackOpen &&
                        <DialogContent style={theme.dialogContentMain}>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                >
                                <Grid item xs={12} style={{ minHeight: 100 }}>
                                    <p style={theme.textSupportCenter}>
                                        {this.state.feedbackOnCancel
                                        ?
                                            "Is there anything wrong? Please let me know so that I can improve."
                                        :
                                            "What is your feedback and what do you wish to be enhanced?"
                                        }
                                    </p>
                                    {this.state.feedbackOnCancel
                                        ?
                                            <div style={{marginTop: 50}}>
                                                <Button
                                                    style={{marginBottom: 20}}
                                                    onClick ={() => {this.setState({handleOpenTutorial: true, feedbackOnCancel: false})}}
                                                >
                                                    Explain me this product!
                                                </Button>
                                            </div>
                                        :
                                            <p className={classes.voteIcons}>
                                                {this.state.dialogue?.vote === 'up'
                                                ?
                                                <ThumbUpIcon
                                                                className={classes.iconVote}
                                                                onClick={this.devote} />
                                                :
                                                <ThumbUpIcon
                                                                className={classes.iconNoVote}
                                                                onClick={this.upvote} />
                                                }
                                                {this.state.dialogue?.vote === 'down'
                                                ?
                                                <ThumbDownIcon
                                                                className={classes.iconVote}
                                                                onClick={this.devote} />
                                                :
                                                <ThumbDownIcon
                                                                className={classes.iconNoVote}
                                                                onClick={this.downvote} />
                                                }
                                            </p>
                                    }
                                </Grid>
                                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                                    <TextField
                                        variant="outlined"
                                        //autoFocus
                                        type="text"
                                        onChange={this.onFeedbackChange}
                                        placeholder="Please share your feedback with the Technical Support"
                                        rows={2}
                                        rowsMax={4}
                                        value={this.state.feedback}
                                        multiline
                                        className={classes.textInputFrame}
                                        InputProps={{
                                            style:{fontSize: '16px'},
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Send message"
                                                        onClick={this.handleFeedbackClose}
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ), 
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SpeechToTextButton 
                                                        renderKey={'feedback_'}
                                                        returnText={(result) => this.setState({feedback : result})}
                                                        existingText={this.state.feedback || ""}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    {(this.state.dialogue?.phase === 'session_ended' || this.state.dialogue?.phase === 'session_ended_earlier')
                                    ?
                                        <Button
                                            onClick={this.saveFeedbackAndLeave}
                                            //variant="contained"
                                            className={classes.buttonLeft}
                                        >
                                            Leave Chat
                                        </Button>

                                    :   ''
                                    }
                                    {
                                        /***
                                         * 
                                        
                                        <Button
                                            onClick={() => this.saveFeedbackAndEnd(true)}
                                            //variant="contained"
                                            className={classes.buttonLeft}
                                        >
                                            Finish Chat
                                        </Button>

                                         */
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    {false && this.context.ratedApp !== true && window.CdvPurchase !== undefined && this.state.dialogue?.vote === 'up' 
                                        ?
                                            <div>
                                                <AppRating 
                                                    className={classes.button}
                                                    buttonText="Share Feedback"
                                                    simpleMode={true}
                                                    freeDialoguesRequest={false} 
                                                    dialogueId = {this.state.currentDialogueId || ''}
                                                    user={this.context} 
                                                    db={this.props.firebase}
                                                    afterRating = {() => this.handleFeedbackClose()}
                                                    />
                                            </div>
                                        :
                                            <Button
                                                onClick={this.handleFeedbackClose}
                                                variant="contained"
                                                className={classes.button}
                                            >
                                                Save Feedback 
                                            </Button>
                                    }
                                </Grid>
                                {this.state.dialogue?.vote === 'up' &&
                                    <Grid item xs={12} style={{padding:20}}>
                                        <p style={theme.textSupportCenter}>Share your AI Coach experience with others:</p>
                                        <ShareSocialFeedback 
                                            feedback={this.state.feedback || ''}
                                            user={this.context} 
                                            db={this.props.firebase}
                                        />
                                    </Grid>
                                }
                                {this.state.dialogue?.vote === 'down' &&
                                    <Grid item xs={12} style={{padding:20}}>
                                        <p style={theme.textSupportCenter}>The Technical Support will receive this feedback. <br/>If there is anything you would like Rocky to improve, please let us know.</p>
                                    </Grid>
                                }
                                <Grid item xs={12} >
                                    <h4 style={theme.CardContent.titleText}>Rate Questions</h4>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.rateMessage
                                        ?
                                            <DialogueHistory 
                                                dialogue={{...this.state.dialogue, uid: this.state.currentDialogueId}} 
                                                //rateOnly={true}
                                                messageFilter={'bot'}
                                                style={{height:'100vh'}} 
                                            />
                                        :
                                            <div style={{textAlign: 'right'}}>
                                                <Button 
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => {this.setState({'rateMessage' : true})}}>
                                                    Rate Questions
                                                </Button>
                                            </div>

                                    }
                                </Grid>
                                {this.state.dialogue !== undefined && this.state.dialogue?.wordCollection !== undefined 
                                    ?
                                        <Grid item xs={12} style={{  paddingTop: 60 }}>
                                            <MindfulnessScore 
                                                dialogue={this.state.dialogue}  
                                                user={this.context}
                                                showIdeas={true}
                                                leadershipCategories={this.state.leadershipCategories}
                                                leadershipQualities={this.state.leadershipQualities}
                                                messageSubmit = {(text) => {this.submitInspiration(text)}}
                                                tutorialOpen = {(tutorialFamily) => {this.setState({tutorialFamily: tutorialFamily, handleOpenTutorial: true})}}
                                            />
                                        </Grid>
                                    :   
                                        this.state.guidedChat !== false
                                        ?
                                            <Grid item xs={12} style={{  paddingTop: 60 }}>
                                                <h4>Conversation </h4>
                                                <DialogueStages
                                                    stages = {this.state.dialogue?.dialogueStage || undefined}
                                                    //message = {(message) => {this.setState({ dialogueStagesMessage: message})}}
                                                />
                                            </Grid>
                                        :   ''
                                }
                                {this.context?.features?.showChallenges && (this.context.dialogueCounter === undefined || this.context.dialogueCounter <=3) 
                                &&
                                    <Task4Chats
                                        noMargin={true}
                                        user={this.context}
                                        leadershipCategories={this.state.leadershipCategories}
                                        leadershipQualities={this.state.leadershipQualities} 
                                    />
                                }
                            </Grid>
                        </DialogContent>
                    }
                </Dialog>
            </RemoveScroll> 
        )
    }   
    
    dialogShowSettings = () => {
        const { classes, theme } = this.props;
        var username = "Padawan";
        if(this.state.dialogSettingsOpen){
            username = this.context.username!==undefined && this.context.username!== '' ? this.context.username : "Padawan";
            if(username.includes('@')){
                username = username.split('@')[0];
            }
            if(username.includes(' ')){
                username = username.split(' ')[0];
            }
        }
        return(
            <RemoveScroll enabled={this.state.dialogSettingsOpen || false}>  
                <Dialog
                    open={this.state.dialogSettingsOpen || false}
                    onClose={this.handleSettingsClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style:theme.dialogWindow}}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    >
                    <DialogTitle style={theme.dialogTitle} >
                        <Grid container spacing={0} direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item >
                                <IconButton onClick={this.handleSettingsClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleText}>
                                    Chat Settings
                                </span>
                            </Grid>
                            <Grid item>
                                <span style={theme.dialogTitle.titleLink} onClick={this.handleSettingsClose}>
                                    Close
                                </span>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {this.state.dialogSettingsOpen &&
                        <DialogContent style={theme.dialogContentMain}>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                >
                                <Grid item xs={12} style={{ minHeight: 100 }}>
                                    <p style={theme.textSupportCenter}>
                                        Advanced chat settings
                                    </p>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <div style={{width: "100%", textAlign: 'center'}}
                                            onClick={ () => {
                                                this.setState({openCurrentAchievementGoal: true, typingBotFinishTypingNow: true, speedDialOpen : false, dialogSettingsOpen : false, handleOpenTutorial: false});
                                                ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my name.', 'What is your name?', 'utter_ask_help', '', 'profile');
                                            }}
                                        >
                                            <AccountCircleIcon />
                                            <br/>
                                            <p style={{color: theme.palette.secondary.main}}>{username || 'Padawan'}</p>
                                        </div>
                                    </ul>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                uncheckedIcon={false}
                                                checked={(this.context.muteAudio === undefined || this.context.muteAudio===false) ? true : false}
                                                onChange={(c, event, id) => {
                                                    this.setMute(!(c));
                                                }}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            {this.context.muteAudio === false 
                                                ?   <VolumeUpIcon style={theme.inlineIconLeft}/>
                                                :   <VolumeOffIcon style={theme.inlineIconLeft}/>
                                            }
                                            Audio Off/On:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Choose if you want to listen to audio (normal speed mode).</p>
                                        </div>
                                        {this.context.muteAudio !== true &&
                                        <div style={{width: "100%", textAlign: "center"}}>
                                            <span
                                                onClick={() => {
                                                    this.props.firebase.user(this.context.uid).update({
                                                        ['features.voiceId'] : 'Champ',
                                                    });
                                                }}
                                                style={{paddingLeft: 6, paddingRight:6, color: (this.context.features?.voiceId === 'Champ' ? theme.palette.themeAlertGreen : theme.palette.secondary.main)}}
                                            >
                                                Champ
                                            </span>
                                            &bull;
                                            <span 
                                                onClick={() => {
                                                    this.props.firebase.user(this.context.uid).update({
                                                        ['features.voiceId'] : 'Maven',
                                                    });
                                                }}
                                                style={{paddingLeft: 6, paddingRight:6, color: (this.context.features?.voiceId === 'Maven' ? theme.palette.themeAlertGreen : theme.palette.secondary.main)}}
                                            >
                                                Maven
                                            </span>
                                            &bull;
                                            <span 
                                                onClick={() => {
                                                    this.props.firebase.user(this.context.uid).update({
                                                        ['features.voiceId'] : 'Chap',
                                                    });
                                                }}
                                                style={{paddingLeft: 6, paddingRight:6, color: ((!(this.context.features?.voiceId?.length > 1) || this.context.features?.voiceId === 'Chap') ? theme.palette.themeAlertGreen : theme.palette.secondary.main)}}
                                            >
                                                Chap
                                            </span>
                                            &bull;
                                            <span 
                                                onClick={() => {
                                                    this.props.firebase.user(this.context.uid).update({
                                                        ['features.voiceId'] : 'Belle',
                                                    });
                                                }}
                                                style={{paddingLeft: 6, paddingRight:6, color: (this.context.features?.voiceId === 'Belle' ? theme.palette.themeAlertGreen : theme.palette.secondary.main)}}
                                            >
                                                Belle
                                            </span>
                                            &bull;
                                            <span 
                                                onClick={() => {
                                                    this.props.firebase.user(this.context.uid).update({
                                                        ['features.voiceId'] : 'Sheila',
                                                    });
                                                }}
                                                style={{paddingLeft: 6, paddingRight:6, color: (this.context.features?.voiceId === 'Sheila' ? theme.palette.themeAlertGreen : theme.palette.secondary.main)}}
                                            >
                                                Sheila
                                            </span>
                                            &bull;
                                            <span 
                                                onClick={() => {
                                                    this.props.firebase.user(this.context.uid).update({
                                                        ['features.voiceId'] : 'Yaar',
                                                    });
                                                }}
                                                style={{paddingLeft: 6, paddingRight:6, color: (this.context.features?.voiceId === 'Yaar' ? theme.palette.themeAlertGreen : theme.palette.secondary.main)}}
                                            >
                                                Yaar
                                            </span>
                                        </div>
                                        }
                                    </ul>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                uncheckedIcon={false}
                                                checked={this.context?.features?.fastmode ? this.context.features.fastmode : false}
                                                onChange={(c, event, id) => {
                                                    if(c){
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.fastmode'] : true,
                                                            muteAudio : true
                                                        });
                                                    }
                                                    else{
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.fastmode'] : false
                                                        });
                                                    }
                                                }}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <FastForwardIcon style={theme.inlineIconLeft}/>
                                            Fast Mode:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Rocky scrolls quickly through questions (no audio).</p>
                                        </div>
                                    </ul>
                                    {/***
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                checked={this.context?.features?.mentormode !== true || false}
                                                uncheckedIcon={false}
                                                checkedIcon={<AccessibilityNewIcon/>}
                                                //offColor={theme.palette.themeGold}
                                                onColor={theme.palette.themeBlue}
                                                //value={this.state.currentNotificationFrequency}
                                                onChange={(c, event, id) => {
                                                    if(c){
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.mentormode'] : false
                                                        });
                                                    }
                                                    else{
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.mentormode'] : true
                                                        });
                                                    }
                                                }}
                                                //disabled={this.context.dialogueCounter >= 6 ? true : false}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <AccessibilityNewIcon style={theme.inlineIconLeft}/>
                                            Coach Mode:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>In coach mode, tips and advice will appear less and questions will take center stage.</p>
                                        </div>
                                    </ul>
                                    */}
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                checked={this.context?.features?.mentormode === false || false}
                                                uncheckedIcon={false}
                                                checkedIcon={<EmojiObjectsIcon style={{marginTop: 2}}/>}
                                                //offColor={theme.palette.themeGold}
                                                onColor={theme.palette.secondary.main}
                                                //value={this.state.currentNotificationFrequency}
                                                onChange={(c, event, id) => {
                                                    if(c){
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.mentormode'] : false,
                                                            ['features.gamification'] : false
                                                        });
                                                    }
                                                    else{
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.mentormode'] : true,
                                                        });
                                                    }
                                                }}
                                                //disabled={this.context.dialogueCounter >= 6 ? true : false}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <EmojiObjectsIcon style={theme.inlineIconLeft}/>
                                            Pure Coaching Mode:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Focus mainly on coaching questions 'without' mentoring, instructions, articles, buttons, tips and rewards during the chat.</p>
                                        </div>
                                    </ul>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                checked={this.context?.features?.gamification || false}
                                                uncheckedIcon={false}
                                                checkedIcon={<StarsIcon style={{marginTop: 2}}/>}
                                                //offColor={theme.palette.themeGold}
                                                onColor={theme.palette.secondary.main}
                                                //value={this.state.currentNotificationFrequency}
                                                onChange={(c, event, id) => {
                                                    if(c){
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.gamification'] : true
                                                        });
                                                    }
                                                    else{
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.gamification'] : false
                                                        });
                                                    }
                                                }}
                                                //disabled={this.context.dialogueCounter >= 6 ? true : false}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <StarsIcon style={theme.inlineIconLeft}/>
                                            Show rewards:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>To focus better, you can hide the rewards from popping up during chat.</p>
                                        </div>
                                    </ul>
                                    {this.context?.features?.gamification &&
                                        <CardBadges
                                            variant={'XPLevel'}
                                            visualLimit={1}
                                            badgeFamilyDetailSelection={'all'}
                                            noAlert={false}
                                            //setParentAllQualitiesLoaded={ v => this.setState({allQualitiesLoaded :[...this.state.allQualitiesLoaded, v] }) }
                                        />
                                    }
                                    {true &&
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <span
                                                style={{color: theme.palette.secondary.main}}
                                                onClick={ () => {
                                                    this.setState({openCurrentAchievementGoal: true, typingBotFinishTypingNow: true, speedDialOpen : false, dialogSettingsOpen : false, handleOpenTutorial: false});
                                                    ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my current life stage setting.', 'What is your current life stage?', 'utter_ask_job', '', 'profile');
                                                }}
                                            >
                                                {this.context?.onboardingResponses?.includes('Executive')
                                                    ?   this.props.defaultSet?.programLifeStages?.['Executive'] !== undefined ? this.props.defaultSet.programLifeStages['Executive'] : 'Leader'
                                                    :   this.context?.onboardingResponses?.includes('Teenager')
                                                        ?   this.props.defaultSet?.programLifeStages?.['Teenager'] !== undefined ? this.props.defaultSet.programLifeStages['Teenager'] : 'Youngster'
                                                        :   this.context?.onboardingResponses?.includes('Student')
                                                            ?   this.props.defaultSet?.programLifeStages?.['Student'] !== undefined ? this.props.defaultSet.programLifeStages['Student'] : 'Student'
                                                            :   this.context?.onboardingResponses?.includes('Professional')
                                                                ?   this.props.defaultSet?.programLifeStages?.['Professional'] !== undefined ? this.props.defaultSet.programLifeStages['Professional'] : 'Professional'
                                                                    :   this.context?.onboardingResponses?.includes('Available')
                                                                    ?   this.props.defaultSet?.programLifeStages?.['Available'] !== undefined ? this.props.defaultSet.programLifeStages['Available'] : 'Available'
                                                                :   
                                                                    <Button variant="outlined" color="secondary">
                                                                        Add
                                                                    </Button>
                                                }
                                            </span>
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <img src={imageSettingGoal} alt='Life-Stage' style={{...theme.inlineIconLeft, width: 15}}/>
                                            Life Stage:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Depending on the stage of your life and the role you play, Rocky will talk to you differently.</p>
                                        </div>
                                    </ul>
                                    }
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            {this.context.dialogueCounter >= 6 
                                                ?   'Advanced'
                                                :   this.context.dialogueCounter >= 3
                                                    ?   'Intermediate'
                                                    :   'Beginner'
                                            }
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <FlightIcon style={theme.inlineIconLeft}/>
                                            Difficulty Level:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>There are different levels of difficulty for the questions.</p>
                                        </div>
                                    </ul>
                                    {/***
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                uncheckedIcon={false}
                                                checked={this.context.dialogueCounter >= 6 ? true : this.context.features?.advancedmode ? this.context.features.advancedmode : false}
                                                //value={this.state.currentNotificationFrequency}
                                                onChange={(c, event, id) => {
                                                    if(c){
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.advancedmode'] : true
                                                        });
                                                    }
                                                    else{
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.advancedmode'] : false
                                                        });
                                                    }
                                                }}
                                                disabled={this.context.dialogueCounter >= 6 ? true : false}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <FlightIcon style={theme.inlineIconLeft}/>
                                            Advanced Mode:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>There are different levels of difficulty for the questions.</p>
                                        </div>
                                    </ul>
                                     */}
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            Current main Quest:
                                            <br/><br/>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="stretch"
                                                style={{width: '100%'}}
                                                >
                                                <Grid item xs={8} style={{textAlign: 'left'}}>
                                                    <AvatarCard key={QualityID(this.context.currentLeadershipQuality)} dict={this.state.leadershipQualities} type='quality' item={QualityID(this.context.currentLeadershipQuality)} squareSize='20' />
                                                </Grid>
                                                <Grid item xs={4} style={{textAlign: 'right'}}>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={ () => {
                                                            this.setState({openCurrentAchievementGoal: true, typingBotFinishTypingNow: true, speedDialOpen : false, dialogSettingsOpen : false, handleOpenTutorial: false});
                                                            ExtendDialogueMessage(this.props.firebase, this.context, 'I want to change my coaching module!', 'Are you fine with your current module?', 'utter_ask_help', '', 'profile');
                                                        }}
                                                    >
                                                        Change
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Restart the chat with a different module.</p>
                                        </div>
                                    </ul>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <Switch
                                                checked={this.context?.features?.darkmode === true || false}
                                                uncheckedIcon={false}
                                                //checkedIcon={<EmojiObjectsIcon style={{marginTop: 2}}/>}
                                                //offColor={theme.palette.themeGold}
                                                onColor={theme.palette.secondary.main}
                                                //value={this.state.currentNotificationFrequency}
                                                onChange={(c, event, id) => {
                                                    if(c){
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.darkmode'] : true
                                                        })
                                                        .then( () => {
                                                            /// the color is backTertiaryInv bacause it is not laoded with the same color as on startup and uses the inversee versions
                                                            if(window.StatusBar !== undefined){
                                                                window.StatusBar.overlaysWebView(false);
                                                                window.StatusBar.backgroundColorByHexString(theme.palette.backTertiaryInv);
                                                                window.StatusBar.styleLightContent();
                                                                window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiaryInv, true);
                                                            }  
                                                        });
                                                    }
                                                    else{
                                                        this.props.firebase.user(this.context.uid).update({
                                                            ['features.darkmode'] : false,
                                                        })
                                                        .then( () => {
                                                            if(window.StatusBar !== undefined){
                                                                window.StatusBar.overlaysWebView(false);
                                                                window.StatusBar.backgroundColorByHexString(theme.palette.backTertiaryInv);
                                                                window.StatusBar.styleLightContent();
                                                                window.NavigationBar?.backgroundColorByHexString(theme.palette.backTertiaryInv, true);
                                                            }  
                                                        });
                                                    }
                                                }}
                                                //disabled={this.context.dialogueCounter >= 6 ? true : false}
                                            />
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <EmojiObjectsIcon style={theme.inlineIconLeft}/>
                                            Dark Mode:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Change the app to dark mode.</p>
                                        </div>
                                    </ul>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <span style={{ float: 'right'}}>
                                            <IconButton 
                                                onClick ={() => {this.setState({speedDialOpen : false, dialogSettingsOpen : false, handleOpenTutorial: true})}}>
                                                <HelpOutlineIcon 
                                                    color={"secondary"}
                                                />
                                            </IconButton>
                                        </span>
                                        <span style={theme.textSettings.settingsTextDescription}>
                                            <HelpOutlineIcon style={theme.inlineIconLeft}/>
                                            Open Tutorial:
                                        </span>
                                        <div style={{width: "60%"}}>
                                            <p style={theme.textSupportGrey}>Learn more about the design of Rocky's reflection practice.</p>
                                        </div>
                                    </ul>
                                    <ul style={{...theme.textSettings, paddingLeft: 0}}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="stretch"
                                            style={{width: '100%'}}
                                            >
                                            <Grid item xs={8} style={{textAlign: 'left'}}>
                                                <span style={theme.textSettings.settingsTextDescription}>
                                                    <OfflinePinIcon style={theme.inlineIconLeft}/>
                                                    Show Results:
                                                    <br/><br/>
                                                </span>
                                                <div style={{width: "60%"}}>
                                                    <p style={theme.textSupportGrey}>End the chat now, and show tips and new goals.</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} style={{textAlign: 'right'}}>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={ () => {
                                                        this.setState({openCurrentAchievementGoal: false, openCurrentShopFlag: false, typingBotFinishTypingNow: true, speedDialOpen : false, dialogSettingsOpen : false, handleOpenTutorial: false});
                                                        this.saveFeedbackAndEnd(true);
                                                    }}
                                                >
                                                    Finish
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ul>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    }
                </Dialog>
            </RemoveScroll> 
        )
    }

    submitInspiration = (text) => {
        this.setState({
            open:false,
            tipsOpen: false,
            feedbackOpen: false,
            expandedSuggestions: false,
            inspirationText: text
        });
    }

    textInputUserChatElement = (nonSubscriber, chatResultScore=0) => {
        const { classes, theme } = this.props;
        //var focusParameter = IsMobileDevice() ? "" :"autoFocus"
        //console.log('XXXX SHow INPUT',this.state.showTextInput)
        if(this.state.dialogue !== undefined && this.state.dialogue !== null &&
            (this.state.dialogue?.phase === 'session_ended' 
                || this.state.dialogue?.phase === 'session_ended_earlier' 
                || this.state.dialogue?.phase === 'feedback'
                || this.state.dialogue?.phase === 'ended' // this is a heritage phase mode ended does not existi anymore
            ) ) {
            return(
                <Toolbar id="bottom_chat" style={{...theme.MobileAppBarRoutine, marginTop: 0, paddingBottom: 15}}>
                    { this.state.dialogue?.phase !== 'feedback'
                    &&
                        <Button 
                            variant="outlined" 
                            //onClick={this.saveRoutineAndFeedback} 
                            onClick={ () => this.setState({ feedbackOpen: true , feedbackShown: true })}
                            color="primary"
                            >
                            Rate this chat
                        </Button>
                    }
                    <span style={{flex:1}}>
                        &nbsp;
                    </span>
                    <Button  
                        onClick={false && (this.state.scrollDownDone !== true && this.state.dialogue !== undefined && this.state.dialogue?.wordCollection !== undefined )
                            ?   this.scrollDown
                            :   this.saveRoutineBeforeFeedback
                        }   
                    >
                        {false && (this.state.scrollDownDone !== true && this.state.dialogue !== undefined && this.state.dialogue?.wordCollection !== undefined )
                            ?   "Tips"
                            :   "Home"
                        }
                    </Button>
                </Toolbar>
            )
        }
        else if ((nonSubscriber && !this.state.firebaseConnectionError )){
            return(
                <Toolbar id="bottom_chat" style={{...theme.MobileAppBarRoutine, marginTop: 0,paddingBottom: 15}}>
                    <Button
                        onClick={() => this.setState({DialogSettingPlanOpen : true})}
                        //variant='outlined'
                        //color="secondary"
                        >
                        Unlock All
                    </Button>
                    <span style={{flex:1}}>
                        &nbsp;
                    </span>
                    <Button
                        key='finishnow'
                        variant="outlined"
                        onClick={() => {
                            this.setState({speedDialOpen : false});
                            this.saveFeedbackAndEnd(true);
                        }}
                        color="secondary"
                        style={{
                            position:  'relative',
                            alignItems: 'center'
                        }}
                        >
                            Show Results &nbsp;
                            <OfflinePinIcon fontSize='small'/>
                    </Button>
                </Toolbar>
            )
        }
        //else if(!this.state.showTextInput || this.state.dialogue?.phase === 'session_requested' ) {
        else if(this.state.firebaseConnectionError || nonSubscriber || (!this.state.typingBotFinishTypingNow && !this.state.showTextInput) || (this.state.dialogue?.phase === undefined)) {
            return(
                <Toolbar id="bottom_chat" style={{...theme.MobileAppBarRoutine, marginTop: 0}} 
                    onClick={this.state.openAlert!== true 
                        ?   () => {this.setState({openAlert: true})}
                        :   void(0)
                    }
                    >
                    <div className={classes.textInputVisible}>
                        {(this.state.showTextFieldButtons)
                            ?
                                <div style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default', marginBottom:5}} > 
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="stretch"
                                        >
                                        <Grid item onClick={() => {this.setState({openAlert: true})}}>
                                            <div 
                                                style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                                <span >
                                                    Loading...
                                                </span>
                                            </div>
                                        </Grid>
                                        <Grid item>
                                        </Grid>
                                        <Grid item>
                                            <div 
                                                style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                                <HourglassEmptyIcon fontSize='small'/>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            :
                                <div style={{height: 10}}></div>
                        }
                        <div style={{height: 56, width:'100%'}}
                            key='loading_textfield'
                        >
                            {!nonSubscriber && (this.state.dialogue!==null && this.state.dialogue?.phase !== undefined && !this.state.firebaseConnectionError )
                            &&
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    >
                                    <Grid item>
                                        <div 
                                            style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                            <HourglassEmptyIcon fontSize='small'/>
                                            {!this.state.extraQuestioning && chatResultScore > 0 ? Math.floor(chatResultScore*10) + '%' : '' } 
                                        </div>
                                    </Grid>
                                    <Grid item>
                                    </Grid>
                                    <Grid item>
                                        {(!(this.state.loading || this.state.firebaseConnectionError || this.state.loadingRequest)
                                            && this.state.messages?.[this.state.messages?.length-1]?.isCoach===true) 
                                            && this.state.typingBotDone !== true && this.state.typingBotFinishTypingNow !== true 
                                            &&
                                            <Button
                                                onClick={
                                                    this.state.typingBotDone !== true && this.state.typingBotFinishTypingNow !== true
                                                    ?   () => {
                                                            if(this.context.muteAudio !== true){
                                                                this.setState({typingBotFinishTypingNow: true, introMessageDone: true, audioInteraction: true});
                                                                if(false && this.context.muteAudio !== true){
                                                                    this.props.firebase.user(this.context.uid).update({
                                                                        //['features.fastmode'] : true,
                                                                        muteAudio : true
                                                                    });
                                                                }
                                                            }
                                                            else{
                                                                this.setState({typingBotFinishTypingNow: true, introMessageDone: true});
                                                            }
                                                        }
                                                    :   void(0)
                                                }
                                                variant={"outlined"}
                                                >
                                                Fast &nbsp;
                                                <FastForwardIcon fontSize='small'/>
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    </div>
                </Toolbar>
            )
        }
        //else if (this.state.dialogue?.phase !== 'session_requested' || (this.context !== undefined && this.context.dialogueCounter > 2 ) ) {
        else if (this.context !== undefined && this.context !== null) {
            return(
                <Toolbar id="bottom_chat" style={{...theme.MobileAppBarRoutine, marginTop: 0}}>
                    <div
                        className={this.state.showTextInput && (this.state.dialogue !== undefined || this.state.dialogue!==null )
                        //&& this.state.dialogue?.phase !== 'session_requested' 
                            ? classes.textInputVisible 
                            : classes.textInputHidden
                        }
                    >
                        {this.state.showTextFieldButtons
                            ?
                                <div style={{
                                    display: 'inline-flex', 
                                    width:'100%', verticalAlign: 'middle', cursor: 'default', marginBottom:5}} > 
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="stretch"
                                        >
                                        <Grid item onClick={() => {this.setState({openAlert: true})}}>
                                            {this.state.dialogue?.phase !== 'session_requested' && (chatResultScore > 0.3 || (this.state.dialogue!==null && this.state.dialogue!==undefined && this.state.dialogue?.phase === 'session_ended') || this.state.dialoguedialogueEnded)
                                                ?   
                                                    <div onClick={ () =>  {this.leaveChat(false)}}
                                                        style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                                        <span >
                                                            End Chat&nbsp;
                                                        </span>
                                                        <AssessmentIcon fontSize='small'/>
                                                    </div>
                                                :   
                                                    <div>
                                                        <span>  &nbsp;  </span>
                                                        {/***
                                                        {chatResultScore>0
                                                            ? Math.round(chatResultScore*30) + " % "
                                                            : "0 % "
                                                        }

                                                        <StyledRating name="chatscore" style={{paddingTop:0}} value={chatResultScore} readOnly precision={0.5} icon={<CheckCircleIcon fontSize='small'/>}/>
                                                        */}
                                                    </div>
                                            }
                                        </Grid>
                                        <Grid item>
                                        </Grid>
                                        <Grid item>
                                            {(this.state.showTextInput || this.state.showTextInput === undefined)
                                                ?
                                                    this.state.dialogue!==null && this.state.dialogue?.phase === 'session_requested'
                                                        ?
                                                            <div  onClick={ () => this.sendMeasurement('skip')}
                                                                style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                                                <span >
                                                                    Next question&nbsp;
                                                                </span>
                                                                <SkipNextIcon fontSize='small'/>
                                                            </div>
                                                        :
                                                            <div  onClick={() => this.onSkip()}
                                                                style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                                                <span >
                                                                    Next question&nbsp;
                                                                </span>
                                                                <SkipNextIcon fontSize='small'/>
                                                            </div>
                                                :
                                                    <div 
                                                        style={{display: 'inline-flex', width:'100%', verticalAlign: 'middle', cursor: 'default'}}>
                                                        <HourglassEmptyIcon fontSize='small'/>
                                                    </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            :
                                <div style={{height: 10, width: '100%'}}> 
                                    {(  // this.state.tabSelection === 'Coach' &&
                                        //(this.state.inspirationText === undefined || this.state.inspirationText === '') && 
                                        this.state.dialogue!==null && this.state.dialogue?.phase !== 'session_requested') 
                                        &&
                                        <>
                                            {this.state.typingBotDone 
                                                && (this.state.tabSelection === 'Mentor' || this.state.tabSelection === 'Goals')
                                                ?   this.state.tabSelection === 'Mentor'
                                                    ?
                                                        this.state.speedDialOpen
                                                        ?
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    if(this.state.speedDialOpen){
                                                                        this.setState({speedDialOpen : false, dialogSettingsOpen: true, handleOpenTutorial: false})
                                                                    }
                                                                    else{
                                                                        this.onSkip()
                                                                    }
                                                                }}
                                                                color="secondary"
                                                                style={{
                                                                    zIndex: 100, 
                                                                    marginTop: -80,
                                                                    backgroundColor: theme.palette.white,
                                                                    position:  'relative',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                Settings &nbsp;
                                                                <TuneIcon fontSize='small'/>
                                                            </Button>
                                                        :
                                                            <Button
                                                                key='finishnow'
                                                                //variant="outlined"
                                                                variant="contained"
                                                                onClick={() => {
                                                                    if(this.refMessagesEndMentor !== null && this.refMessagesEndMentor !== undefined){
                                                                        console.log('done message scrooll Mentor')
                                                                        this.refMessagesEndMentor.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                    }
                                                                    this.setState({speedDialOpen: true})
                                                                }}
                                                                //color="secondary"
                                                                style={{
                                                                    //backgroundColor: this.state.inspirationText?.length > 1 ? theme.palette.secondary.main : theme.palette.themeAlertRed, 
                                                                    backgroundColor: theme.palette.secondary.main+'E0',
                                                                    zIndex: 100, 
                                                                    marginTop: -80, 
                                                                    // (this.refTextInput !== undefined && this.refTextInput.measure !== undefined)? 
                                                                    position:  'relative',
                                                                    alignItems: 'center'
                                                                }}
                                                                >
                                                                    Results&nbsp;
                                                                    {this.props.defaultSet?.programAvatarName?.length > 2
                                                                        ?   <HelpOutlineIcon fontSize='small'/>
                                                                        :   <HelpOutlineIcon fontSize='small'/>
                                                                    }
                                                            </Button>
                                                    :
                                                        <Button
                                                            key='finishnow'
                                                            //variant="outlined"
                                                            variant="contained"
                                                            onClick={() => {
                                                                this.setState({speedDialOpen : false});
                                                                this.saveFeedbackAndEnd(true);
                                                            }}
                                                            color="secondary"
                                                            style={{
                                                                zIndex: 100, 
                                                                marginTop: -80, 
                                                                // (this.refTextInput !== undefined && this.refTextInput.measure !== undefined)? 
                                                                position:  'relative',
                                                                alignItems: 'center'
                                                            }}
                                                            >
                                                                {this.context.dialogueCounter === undefined || this.context.dialogueCounter <=4 ? 'Results' : 'Finish'} &nbsp;
                                                                <OfflinePinIcon fontSize='small'/>
                                                        </Button>
                                                :
                                                    (!this.state.typingBotDone)
                                                    ?
                                                        (!(this.state.loading || this.state.firebaseConnectionError || this.state.loadingRequest) 
                                                            && this.state.messages?.[this.state.messages?.length-1]?.isCoach===true) 
                                                            && this.state.typingBotDone !== true && this.state.typingBotFinishTypingNow !== true
                                                        ? 
                                                            <Button
                                                                key='faster'
                                                                onClick={
                                                                    this.state.typingBotDone !== true && this.state.typingBotFinishTypingNow !== true
                                                                    ?   () => {
                                                                            //this.setMute(true);
                                                                            if(this.context.muteAudio !== true){
                                                                                this.setState({typingBotFinishTypingNow: true, introMessageDone: true, audioInteraction: true});
                                                                                if(false && this.context.muteAudio !== true){
                                                                                    this.props.firebase.user(this.context.uid).update({
                                                                                        //['features.fastmode'] : true,
                                                                                        muteAudio : true
                                                                                    });
                                                                                }
                                                                            }
                                                                            else{
                                                                                this.setState({typingBotFinishTypingNow: true, introMessageDone: true});
                                                                            }
                                                                        }
                                                                    :   void(0)
                                                                }
                                                                //variant="outlined"
                                                                //color="secondary"
                                                                style={{
                                                                    zIndex: 100, 
                                                                    marginTop: -50, 
                                                                    // (this.refTextInput !== undefined && this.refTextInput.measure !== undefined)? 
                                                                    position:  'absolute',
                                                                    alignItems: 'center',
                                                                    right: 20
                                                                }}
                                                                >
                                                                Fast &nbsp;
                                                                <FastForwardIcon fontSize='small'/>
                                                            </Button>
                                                        :   ''
                                                    :
                                                        this.state.typingBotDone
                                                        ?
                                                            this.state.speedDialOpen
                                                            ?
                                                                true
                                                                ?   
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            if(this.state.speedDialOpen){
                                                                                this.setState({speedDialOpen : false, dialogSettingsOpen: false, handleOpenTutorial: false})
                                                                            }
                                                                            else{
                                                                                this.onSkip()
                                                                            }
                                                                        }}
                                                                        color="secondary"
                                                                        style={{
                                                                            zIndex: 100, 
                                                                            marginTop: -80,
                                                                            backgroundColor: theme.palette.white,
                                                                            position:  'relative',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        Continue Chat &nbsp;
                                                                        <ForumIcon fontSize='small'/>
                                                                    </Button>
                                                                :
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            if(this.state.speedDialOpen){
                                                                                this.setState({speedDialOpen : false, dialogSettingsOpen: true, handleOpenTutorial: false})
                                                                            }
                                                                            else{
                                                                                this.onSkip()
                                                                            }
                                                                        }}
                                                                        color="secondary"
                                                                        style={{
                                                                            zIndex: 100, 
                                                                            marginTop: -80,
                                                                            backgroundColor: theme.palette.white,
                                                                            position:  'relative',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        Settings &nbsp;
                                                                        <TuneIcon fontSize='small'/>
                                                                    </Button>
                                                                
                                                                :   
                                                                    <>
                                                                        {this.context.dialogueCounter >= 2
                                                                            ?
                                                                                <IconButton 
                                                                                    style={{
                                                                                        backgroundColor: theme.palette.secondary.main+'E0',
                                                                                        zIndex: 100, 
                                                                                        marginTop: -80,
                                                                                        position:  'relative',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                            console.log('done message scrooll')
                                                                                            this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                                        }
                                                                                        this.setState({speedDialOpen: true})
                                                                                    }}
                                                                                >
                                                                                    <TuneIcon fontSize='small' style={{color: 'white'}}/>
                                                                                </IconButton>
                                                                            :
                                                                                <Button 
                                                                                    style={{
                                                                                        //backgroundColor: this.state.inspirationText?.length > 1 ? theme.palette.secondary.main : theme.palette.themeAlertRed, 
                                                                                        backgroundColor: theme.palette.secondary.main+'E0',
                                                                                        zIndex: 100, 
                                                                                        marginTop: -80,
                                                                                        position:  'relative',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                                                                            console.log('done message scrooll')
                                                                                            this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'center'  });
                                                                                        }
                                                                                        this.setState({speedDialOpen: true})
                                                                                    }}
                                                                                >
                                                                                    Help
                                                                                    &nbsp;
                                                                                    <HelpOutlineIcon fontSize='small'/>
                                                                                </Button>
                                                                        }
                                                                    </>
                                                        :   ""
                                            }
                                        </>
                                    }
                                </div>
                        }
                        {(process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER")
                            ?
                                ""
                            :
                                <Fab 
                                    className={this.state.speechRecognitionAvailable ? classes.fabButtonCenter : classes.fabHidden} 
                                    size={"large"}
                                >
                                    <div>
                                        <SpeechToTextButton
                                            renderKey={'fab_chat_'}
                                            size={"large"}
                                            confirmRecognitionAvailable={() => this.setState({speechRecognitionAvailable : true})}
                                            returnText={(result) => {
                                                this.setState({inspirationText: result, typingBotFinishTypingNow: true, introMessageDone: true, audioInteraction: true});
                                                if(this.state.mute !== false && this.context.dialogueCounter < 2){
                                                    console.log('over writing mute')
                                                    this.setMute(false, false);
                                                }
                                            }}
                                            existingText={this.state.inspirationText || ""}
                                        />
                                    </div>
                                </Fab>
                        }
                        <MyInput 
                            ref={this.refTextInput}
                            phase={this.state.dialogue!== undefined && this.state.dialogue!== null ? this.state.dialogue?.phase : ""} 
                            classes={classes}
                            theme={this.props.theme}
                            microphone={
                                // this.state.speechRecognitionAvailable ? false : true
                                (process?.env?.REACT_APP_DEVICE_PLATFORM==="BROWSER") ? true : false
                            }
                            specialFabActive={this.state.speechRecognitionAvailable || false}
                            defaultText={this.state.tabSelection === 'Mentor' ? "Ask a question" : this.state.tabSelection === 'Goals' ? "Add a goal" : undefined }
                            inspirationText={this.state.inspirationText}
                            sendMeasurement={(text) => this.sendMeasurement(text)}
                            onSubmit={(text) => {
                                this.setState({typingBotFinishTypingNow: true, introMessageDone: true, inspirationText: ""});
                                this.onSubmit(text);
                            }}
                            onChange={(text, overwrite) =>  {
                                if(overwrite){
                                    this.setState({inspirationText: text, typingBotFinishTypingNow: true, introMessageDone: true});
                                }
                                else if(this.state.inspirationText === undefined || this.state.inspirationText === ''){
                                    this.setState({inspirationText: text, typingBotFinishTypingNow: true, introMessageDone: true});
                                }
                                if(this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                                    this.refMessagesEnd.scrollIntoViewIfNeeded()
                                }
                            }}
                        />
                    </div>
                </Toolbar>
            )
        }
        else {
            return (
                <Toolbar id="bottom_chat" style={{...theme.MobileAppBarRoutine, marginTop: 0,paddingBottom: 45}} >
                    <span style={{flex:1}}>
                        &nbsp;
                    </span>
                </Toolbar>
            )
        }
    }
  
    leaveChat = (exitToHome=true) => {
        //console.log('exiting', exitToHome)
        var lastBotMessageId = this.state.lastBotMessageId;
        if(lastBotMessageId!==undefined && this.state.dialogue!==null && this.state.dialogue!==null && this.state.dialogue?.phase !== 'session_ended'){
            //console.log('called without a message',lastBotMessageId)
            this.props.firebase.message(lastBotMessageId).update({
                vote: 'down',
                voteSource: 'left'
            });
            if(exitToHome && this.state.feedbackShown !== true && (this.context.dialogueCounter === undefined || this.context.dialogueCounter<2)){
                this.setState({ feedbackOpen: true, feedbackShown: true , openLeaveCheck: false})
            } 
        }
        if(exitToHome === false && this.state.currentDialogueId!== undefined && this.state.currentDialogueId!== '' && this.state.dialogue!==null && this.state.dialogue?.phase !== 'session_ended'){
            //console.log('this is strnage',this.state.dialogue?.phase)
            //if(this.state.messages.length >= 5 || exitToHome===false){
            if(this.state.messages !== undefined && this.state.messages.length >= 1){
                //console.log('ended earlier update chat',exitToHome)
            //if( this.context.dialogueCounter === undefined ||this.context.dialogueCounter<=0 ){
                if(this._isMounted){
                    this.setState({loadingRequest: true, flagEndChatEarlier: true, openLeaveCheck: false });
                }
                //this.onSubmit('Goodbye');
                var phase = 'session_ended_earlier';
                var xpEnd = 0;
                if(this.context.engagementCounter !== undefined && this.context.engagementCounter>0){
                    xpEnd = this.context.engagementCounter;
                }
                var data = {
                    phase: phase,
                    openTask: this.state.openTask,
                    askHelp: this.state.askHelp,
                    askReflectionTopic: this.state.askReflectionTopic,
                    askFeedback: this.state.askFeedback,
                    dialogueEnded: true,
                    xpEnd
                }
                this.props.firebase.dialogue(this.state.currentDialogueId).update(data);
                this.props.firebase.dialoguePostprocess(this.state.currentDialogueId, 'dialogueEnded').set(data, {merge:true});
                if (this.state.dialogue!==null  && this.state.dialogue?.dialogueType === 'priming') {
                    this.props.firebase.user(this.context.uid).update({
                        firstSessionDone: true,
                        dialogueStatus: 'ended',
                        primingSessionFinishedAt: this.props.firebase.fieldValue.serverTimestamp(),
                    });
                } 
                else if (this.state.dialogue!==null  && this.state.dialogue?.dialogueType === 'reflection')  {
                    this.props.firebase.user(this.context.uid).update({
                        firstSessionDone: true,
                        dialogueStatus: 'ended',
                        reflectionSessionFinishedAt: this.props.firebase.fieldValue.serverTimestamp(),
                    });
                }
            }
        }
        if(exitToHome && this.state.feedbackShown ){
        //if(exitToHome){
            //this.props.history.goBack();
            this.props.history.push(ROUTES.PROGRAM);
        }
        else if (exitToHome===false && this.state.dialogue !== undefined && this.state.dialogue !== null && this.state.dialogue?.phase !== 'session_ended' && this.state.messages !== undefined && this.state.messages.length < 6){
            this.setState({loadingRequest: true, flagEndChatEarlier: true, openLeaveCheck: false});
            // this.setState({ feedbackOpen: true, feedbackShown: true, feedbackOnCancel:true })
        }
        else if(exitToHome){
            this.props.history.push(ROUTES.PROGRAM);
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            typingBotFinishTypingNow: true,
            tabSelection: newValue,
            [newValue] : true,
        }, () => {
            try{
                if(newValue === 'Coach' && this.refMessagesEnd !== null && this.refMessagesEnd !== undefined){
                    if(this.state.ignoreMindset !== true && this.state.dialogue?.dialogueStage?.mindset === undefined ){
                        this.request();
                        this.setState({guidedChat: true, ignoreMindset: true});
                        console.log('request a new session beacuse no mindset')
                    }
                    //console.log('coach message scrooll')
                    this.refMessagesEnd.scrollIntoView({ behavior: "smooth", block: 'start' });
                }
                else if( newValue === 'Mentor' ){
                    //opening the help window
                    if(this.refMentorMiddle !== null && this.refMentorMiddle !== undefined){
                        //console.log('habits message scrooll')
                        this.refMentorMiddle.scrollIntoView({ behavior: "smooth", block: 'end' });
                    }
                    this.setState({waitingForResponseTimeout: false});
                    AddAnalytics('screen_view', 'PageRoutineTips');
                }
                if(newValue === 'Goals' && this.refHabitsStart !== null && this.refHabitsStart !== undefined){
                    //console.log('habits message scrooll')
                    this.refHabitsStart.scrollIntoView({ behavior: "auto", block: 'start' });
                    AddAnalytics('screen_view', 'PageRoutineGoals');
                }
            }
            catch (err) {
                //console.log('No scroll to top');
            }
        });
        
    }
    toolbarHeaderChat = (chatResultScore=0) => {
        const { classes, theme } = this.props;
        var chatShowResultScore = chatResultScore*20;
        if(chatShowResultScore>95){
            chatShowResultScore = 95;
        }
        var flagChatFinished = false;
        if(this.state.dialogue !== undefined && this.state.dialogue !== null &&
            (this.state.dialogue?.phase === 'session_ended' 
                || this.state.dialogue?.phase === 'session_ended_earlier' 
                || this.state.dialogue?.phase === 'feedback'
                || this.state.dialogue?.phase === 'ended' // this is a heritage phase mode ended does not existi anymore
            )
        ){
            flagChatFinished = true;
        }
        //console.log('chatResultScore', chatResultScore, chatShowResultScore)
        return (
            <Toolbar style={{
                ...theme.CardTopCompactMobileNavi, maxWidth:'inherit', paddingLeft:0, paddingRight:0, paddingBottom:0,
                //background: 'none', 
                opacity: 1,
                //backgroundColor: theme.palette.themeDarkBlue, 
                background : 'linear-gradient(to top, transparent 7px, '+theme.palette.backTertiary+' 7px)',
            }}>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    >
                    <Grid item xs={12} style={{display: "flex",  alignItems: "center"}}> 
                        <Grid container spacing={0} direction="row" alignItems="center">
                            <Grid item xs={5} style={{ alignItems: "center", verticalAlign:'bottom'}}>
                                <>
                                    <RemoveScroll enabled={this.state.openLeaveCheck || false}>  
                                        <Dialog
                                            open={this.state.openLeaveCheck}
                                            onClose={() => {this.setState({openLeaveCheck: false})}}
                                            //PaperProps={{style:theme.dialogWindow}}
                                            PaperProps={{style: {borderRadius: theme.borderRadius}}}
                                            TransitionComponent={Transition}
                                            maxWidth="sm"
                                        >
                                            {false &&
                                            <DialogTitle style={theme.dialogTitle} >
                                                <Grid container spacing={0} direction="row"
                                                    justify="space-between"
                                                    alignItems="center">
                                                    <Grid item >
                                                        <IconButton onClick={() => {this.setState({openLeaveCheck: false})}}>
                                                            <ArrowBackIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={theme.dialogTitle.titleText}>
                                                            Leave the chat
                                                        </span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={theme.dialogTitle.titleLink} onClick={() => {this.setState({openLeaveCheck: false})}}>
                                                            Cancel
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </DialogTitle>
                                            }
                                            <DialogContent style={theme.dialogContentMain}>
                                                <div style={{ marginTop: 0 }}>&nbsp;</div> 
                                                <p>Do you want to leave this chat?</p>
                                                <span style={theme.textSupport}>
                                                    Invest 5-10 minutes daily to set goals and priorities or to reflect on your day's outcomes and learnings.
                                                    <br/>
                                                    <br/>
                                                </span>
                                                <div style={{width: '100%', textAlign: 'right'}}>
                                                    <Button 
                                                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                                        onClick={() => this.leaveChat(true)}
                                                        color="secondary"
                                                        variant="outlined"
                                                    >
                                                        Leave unfinished chat
                                                        &nbsp;
                                                        <FirstPageIcon fontSize="small"/>
                                                    </Button> 
                                                    <Button 
                                                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                                        onClick={() => {this.setState({openLeaveCheck: false})}}
                                                        color="secondary"
                                                        variant="outlined"
                                                    >
                                                        Continue chat
                                                        &nbsp;
                                                        {this.props.defaultSet?.programLogo?.includes('http')
                                                            ?   <ForumIcon fontSize="small" />
                                                            :   <RockyIcon fontSize="small" />
                                                        }
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{marginTop: 20, marginLeft: 4}}
                                                >
                                                    <FormControlLabel
                                                        aria-label="Coaching"
                                                        control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                                        label= {
                                                            <span style={theme.textSupport}>
                                                                You can also skip questions or finish the reflection earlier.
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                                <DialogueStages
                                                    stages = {this.state.dialogue?.dialogueStage || undefined}
                                                />
                                                <br/>
                                                <div style={{width: '100%', textAlign: 'right'}}>
                                                    <Button 
                                                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                                        onClick={() => this.setState({openLeaveCheck: false}, () => this.onSkip())}
                                                    >
                                                        Skip question
                                                        &nbsp;
                                                        <SkipNextIcon fontSize="small"/>
                                                    </Button>
                                                    <Button 
                                                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                                        onClick={() => this.leaveChat(false)}
                                                    >
                                                        Finish and show results
                                                        &nbsp;
                                                        <OfflinePinIcon fontSize="small"/>
                                                    </Button>
                                                    {false &&
                                                    <Button 
                                                        style={{marginLeft: 8, marginTop: 4, marginBottom: 4}} 
                                                        //onClick={() => {this.setState({feedbackOpen: true, openLeaveCheck: false})}}
                                                        color="secondary"
                                                        variant="outlined"
                                                    >
                                                        Give feedback
                                                        &nbsp;
                                                        <AssessmentIcon fontSize="small"/>
                                                    </Button>
                                                    }
                                                </div>
                                                <div
                                                    style={{marginTop: 20, marginLeft: 4}}
                                                >
                                                    <FormControlLabel
                                                        onClick={() => {this.setState({dialogSettingsOpen: true, openLeaveCheck: false})}}
                                                        aria-label="Coaching"
                                                        control={<InfoIcon style={{...theme.textSupport, marginRight: 10}}/>}
                                                        label= {
                                                            <span style={theme.textSupport}>
                                                                Adjust the chat settings to personalize the style of your coaching conversation.
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                                <div style={{ marginTop: 10 }}>&nbsp;</div> 
                                            </DialogContent>
                                            <DialogActions>
                                                <div style={{width: '100%', height: '100%', textAlign: 'center'}}>
                                                    <span 
                                                        style={{textDecoration: 'underline', color: theme.palette.secondary.main}}
                                                        onClick={() => {this.setState({feedbackOpen: true, openLeaveCheck: false})}}
                                                    >
                                                        Give feedback
                                                    </span>
                                                    &nbsp;|&nbsp;
                                                    <span 
                                                        style={{textDecoration: 'underline', color: theme.palette.secondary.main}}
                                                        onClick={() => {this.setState({dialogSettingsOpen: true, openLeaveCheck: false})}}
                                                    >
                                                        Chat settings
                                                    </span>
                                                    &nbsp;|&nbsp;
                                                    <span 
                                                        style={{textDecoration: 'underline', color: theme.palette.secondary.main}}
                                                        onClick={() => {this.setState({handleOpenTutorial: true, openLeaveCheck: false})}}
                                                    >
                                                        Tutorial
                                                    </span>
                                                </div>
                                            </DialogActions>
                                        </Dialog>
                                    </RemoveScroll>
                                    <Tooltip title='Leave the chat'>
                                        {!(this.state.dialogue?.dialogueStage?.mindset?.phase) && this.state.dialogue?.phase === 'session_requested'
                                            ?
                                                <IconButton
                                                    style={{color: theme.palette.themeLightGrey}}
                                                    onClick={() => {
                                                        ///// safeFeedbacnWould End the chat directly
                                                        //this.saveFeedbackAndEnd(true)
                                                        ///// leave chat and just go home
                                                        this.leaveChat(true)
                                                    }}
                                                >
                                                    <ArrowBackIcon />
                                                </IconButton>
                                        
                                            :   (this.state.feedbackShown || (this.state.dialogue!== null && (this.state.dialogue?.phase === 'session_ended' || this.state.dialogue?.phase === 'session_ended_earlier' || this.state.dialogue?.phase === 'feedback')))
                                                    ?   
                                                        <IconButton 
                                                            style={{color: theme.palette.themeLightGrey}}
                                                            onClick={() => {this.leaveChat(true)}}
                                                        >
                                                            <AccountBalanceIcon />
                                                        </IconButton>
                                                    :   
                                                        <IconButton onClick={() => {
                                                                ///// safeFeedbacnWould End the chat directly
                                                                //this.saveFeedbackAndEnd(true)
                                                                ///// leave chat and just go home
                                                                //this.leaveChat(true);
                                                                this.setState({speedDialOpen : false, openLeaveCheck: true});
                                                            }}
                                                            style={{color: theme.palette.themeLightGrey}}
                                                        >
                                                            <FirstPageIcon />
                                                        </IconButton>
                                        }
                                    </Tooltip>
                                    {false && flagChatFinished &&
                                        <div style={{display:"inline-block", alignItems:"center", verticalAlign:'middle'}}>
                                            <img
                                                src={this.props.defaultSet?.programBrand?.length > 10 ? this.props.defaultSet?.programBrand : logoCaption}
                                                alt="Rocky Home"
                                                onClick={() => {this.props.history.push(ROUTES.PROGRAM)}}
                                                className={classes.logoCaption}
                                                >
                                            </img>
                                        </div>
                                    }
                                </>
                            </Grid>
                            <Grid item xs={2} style={{  alignItems: "center", textAlign: 'center', verticalAlign:'bottom'}}>
                                {(true || this.context.dialogueCounter === undefined || this.context.dialogueCounter<3)
                                    &&
                                    <>
                                        <div style={{width:'100%', textAlign: 'center', verticalAlign:'bottom'}}>
                                            {this.context !== undefined && this.context !== null && (this.context.muteAudio === false || this.context.muteAudio === undefined) &&
                                                <IconButton 
                                                    style={{color: theme.palette.themeLightGrey}}
                                                    onClick = {() => {
                                                        this.setState({speedDialOpen : false, dialogSettingsOpen : true});
                                                        AddAnalytics('screen_view', 'PageRoutineSettings');
                                                    }}
                                                >
                                                    {(true || this.state.audioInteraction || this.context.muteAudio === false )
                                                        ?   <VolumeUpIcon />
                                                        :   <TuneIcon   />
                                                    }
                                                </IconButton>
                                            }
                                            {this.context?.muteAudio === true &&
                                                <IconButton 
                                                    style={{color: theme.palette.themeLightGrey}}
                                                    onClick = {() => {
                                                        this.setState({speedDialOpen : false, dialogSettingsOpen : true});
                                                        AddAnalytics('screen_view', 'PageRoutineSettings');
                                                    }}
                                                >
                                                    {(this.context.muteAudio !== true || this.state.audioInteraction)
                                                        ?   <VolumeOffIcon />
                                                        :   <TuneIcon   />
                                                    }
                                                </IconButton>
                                            }
                                        </div>
                                    </>
                                }
                            </Grid>
                            <Grid item xs={5} style={{textAlign: 'right', alignItems: "center"}}>
                                <>
                                    <div style={{display: 'inline-flex', paddingRight:10, alignItems: "center", verticalAlign:'bottom'}} >
                                        <div>
                                            {this.context.features?.gamification &&
                                                <StatsBar
                                                    type='realtime'
                                                    darkmode={true}
                                                    //hide={(this.context.dialogueCounter === undefined || this.context.dialogueCounter < 5) ? true : false}
                                                    //hide={true}
                                                    //hide={this.state.showTextInput && !(this.state.dialogue!== null && (this.state.dialogue?.phase === 'session_ended' || this.state.dialogue?.phase === 'session_ended_earlier' || this.state.dialogue?.phase === 'feedback')) ? true : false}
                                                    animateEveryPoint = {this.context.features?.gamification || false}
                                                    animatePlus1Trigger = {(this.context.features?.gamification && this.state.animatePlus1Trigger) || false}
                                                    animateMomentByName = {(this.context.features?.gamification && this.state.animateMomentByName) || undefined}
                                                    animateMoments = {this.context.features?.gamification || false}
                                                    animationDoneHandler = {() => this.setState({animatePlus1Trigger: false, animateMomentByName: undefined})}
                                                    handleClick = {() => this.setState({speedDialOpen : false, dialogSettingsOpen : true})}
                                                    />
                                            }
                                        </div>
                                        {false && (this.state.dialogue?.phase !== undefined && !['session_ended', 'session_ended_earlier', 'feedback', 'ended'].includes(this.state.dialogue.phase))
                                        &&
                                            <div style={{paddingRight: 0}}>
                                                <IconButton 
                                                    style={{color: theme.palette.themeLightGrey}}
                                                    onClick= {() => {
                                                        this.setState({speedDialOpen : false, dialogSettingsOpen : true});
                                                        AddAnalytics('screen_view', 'PageRoutineSettings');
                                                    }}>
                                                    <TuneIcon   />
                                                </IconButton>
                                            </div>
                                        }
                                        {false && (this.context.dialogueCounter === undefined || this.context.dialogueCounter < 5)
                                            && 
                                            //(this.state.showTextInput && !(this.state.dialogue!== null && (this.state.dialogue?.phase === 'session_ended' || this.state.dialogue?.phase === 'session_ended_earlier' || this.state.dialogue?.phase === 'feedback'))) &&
                                            <div style={{paddingRight: 0}}>
                                                {/***
                                                <HelpOutlineIcon 
                                                    color={"secondary"}
                                                    style={{verticalAlign:'middle'}}
                                                    onClick ={() => {this.setState({handleOpenTutorial: true})}}
                                                />
                                                    */}
                                                <IconButton 
                                                    style={{color: theme.palette.themeLightGrey}}
                                                    onClick ={() => {
                                                        this.setState({speedDialOpen : false, dialogSettingsOpen : true});
                                                        AddAnalytics('screen_view', 'PageRoutineSettings');
                                                    }}>
                                                    <TuneIcon   />
                                                </IconButton>
                                            </div>
                                        }
                                    </div>
                                </>
                            </Grid>
                        </Grid>
                    </Grid>
                    {flagChatFinished 
                        ?   ""
                        :
                            <React.Fragment>
                                <Grid item xs={6} style={{
                                        textAlign: 'center',
                                        fontSize: this.state.tabSelection === 'Coach' ? 'larger' : 'initial',
                                        borderTopLeftRadius: theme.borderRadius,
                                        borderTopRightRadius: theme.borderRadius,
                                        marginTop: this.state.tabSelection === 'Coach' ? 0 : 6,
                                        cursor: this.state.tabSelection === 'Coach' ? 'zoom-in' : 'pointer',
                                        backgroundColor: this.state.tabSelection === 'Coach' ? theme.palette.themeAltLightGrey : theme.palette.secondary.main,
                                        color: this.state.tabSelection === 'Coach' ? theme.palette.secondary.main : theme.palette.white,
                                    }}
                                    onClick={() => this.handleTabChange(undefined, 'Coach')}
                                >
                                {this.props.defaultSet?.programLogo?.includes('http')
                                    ?   <ForumIcon style={theme.inlineIconLeft}/>
                                    :   <RockyIcon style={theme.inlineIconLeft}/>
                                }
                                    <strong>Coach</strong>
                                </Grid>
                                {/***
                                (this.state.dialogue === undefined 
                                    || this.state.dialogue?.phase === undefined 
                                    // || this.state.dialogue?.phase === 'session_requested' 
                                    // || !(this.context.dialogueCounter > 2)
                                    )
                                    ?
                                        <Grid item xs={4}></Grid>
                                    :
                                        <Grid item xs={4}  style={{
                                                textAlign: 'center',
                                                fontSize: this.state.tabSelection === 'Goals' ? 'larger' : 'initial',
                                                borderTopLeftRadius: theme.borderRadius,
                                                borderTopRightRadius: theme.borderRadius,
                                                marginTop: this.state.tabSelection === 'Goals' ? 0 : 6,
                                                cursor: this.state.tabSelection === 'Goals' ? 'zoom-in' : 'pointer',
                                                backgroundColor: this.state.tabSelection === 'Goals' ? theme.palette.themeLightGrey : theme.palette.secondary.main,
                                                color: this.state.tabSelection === 'Goals' ? theme.palette.secondary.main : theme.palette.white,
                                            }}
                                            onClick={() => this.handleTabChange(undefined, 'Goals')}
                                            >
                                            <AssignmentTurnedInIcon style={theme.inlineIconLeft}/><strong>Goals</strong>
                                        </Grid>
                                */
                                }
                                {(this.state.dialogue === undefined 
                                    || this.state.dialogue?.phase === undefined 
                                    || this.state.dialogue?.phase === 'session_requested'
                                    //|| this.state.tabSelection !== 'Mentor'
                                    || (this.context?.features?.mentormode === false && this.state.tabSelection !== 'Mentor')
                                )
                                    ?
                                        <Grid item xs={6}></Grid>
                                    :
                                        <Grid item xs={6} style={{
                                                textAlign: 'center',
                                                fontSize: this.state.tabSelection === 'Mentor' ? 'larger' : 'initial',
                                                borderTopLeftRadius: theme.borderRadius,
                                                borderTopRightRadius: theme.borderRadius,
                                                //animation: this.state.waitingForResponseTimeout? 'pulse 4s infinite ease-in-out' : 'none',
                                                marginTop: this.state.tabSelection === 'Mentor' ? 0 : 6,
                                                cursor: this.state.tabSelection === 'Mentor' ? 'zoom-in' : 'pointer',
                                                backgroundColor: this.state.tabSelection === 'Mentor' ? theme.palette.themeAltLightGrey : theme.palette.secondary.main,
                                                color: this.state.tabSelection === 'Mentor' ? theme.palette.secondary.main : theme.palette.white,
                                            }}
                                            onClick={() => this.handleTabChange(undefined, 'Mentor')}
                                            >
                                            <WidgetsIcon style={theme.inlineIconLeft}/><strong>Mentor</strong>
                                        </Grid>
                                }
                            </React.Fragment>
                    }
                    {flagChatFinished 
                        ?
                            <Grid item xs={12} >
                                <LinearProgress 
                                    variant="determinate" 
                                    value={flagChatFinished 
                                            ?   100
                                            :   chatShowResultScore
                                        } 
                                    color="secondary" 
                                    style={{
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        height: 6,
                                        borderRadius: '0 0 '+theme.borderRadius+'px '+theme.borderRadius+'px'      
                                    }} 
                                />
                            </Grid>
                        :
                            <Grid item xs={12} 
                                style={{
                                    height: 6,
                                    //opacity: 0.8,
                                    backgroundColor: theme.palette.themeAltLightGrey,
                                    //borderBottomLeftRadius: theme.borderRadius,
                                    //borderBottomRightRadius: theme.borderRadius,
                                }}
                                >
                            </Grid>
                    }
                </Grid> 
            </Toolbar>
        )
    }

    loadCategoriesAndQualities = () => {
        SetStateLoadCategoriesAndQualities(this, this.props.firebase, true, false, this.context.programs);
        //console.log('mycats',this.state)
    } 

    setMute(mutingFlag, mandatoryCallSubscription = true){
        //console.log('Muting Audio: ', mutingFlag, this.state)
        if(mutingFlag === true){
            this.setState({mute: mutingFlag});
            this.props.firebase.user(this.context.uid).update({muteAudio: true});
        }
        else if(mutingFlag === false){
            if(this.state.missingSubscription && !this.state.freemiumSubscriber){
                if(mandatoryCallSubscription===true){
                    this.setState({DialogSettingPlanOpen : true, subscriptionPlanInfoText: 'Audio requires a subscription!', subscriptionActivateAudio: true});
                }
                //console.log('Muting Audio: ', mutingFlag, this.state.missingSubscription)
            }
            else{
                this.setState({mute: mutingFlag});
                this.props.firebase.user(this.context.uid).update({
                    //dialogueSettings: this.props.firebase.fieldValue.arrayRemove('fastmode'),
                    ['features.fastmode'] : false,
                    muteAudio: false, 
                }); 
                //console.log('Muting Audio: ', mutingFlag)
            }
        }
    }

    scrollDown = () => {
        if(this._isMounted){
            this.setState({scrollDownDone: true})
        }
        try{
            //document.body.scrollTop = document.documentElement.scrollTop = 0;
            if(this.refDialogueResults !== undefined ) this.refDialogueResults.current.scrollIntoView({behaviour: 'smooth'});
        }
        catch (err) {
            console.log('No scroll down');
        }
    }
}

class MyInput extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.refTextInput = React.createRef();
        var inspirationText = '';
        if (this.props.inspirationText!== undefined && this.props.inspirationText!==''){
            inspirationText = this.props.inspirationText;
        }
        this.state = {
            text: '',
            inspirationText,
            animateForward: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if(!IsMobileDevice() && this.refTextInput.current!==undefined  && this.refTextInput.current!==null){
            this.refTextInput.current.focus();
        }
        this.blinker();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        if(prevProps.inspirationText !== this.props.inspirationText && this.props.inspirationText!==undefined && this.props.inspirationText.length > 1){
            //console.log('update', this.props.inspirationText, this.props)
            this.setState({text : this.props.inspirationText, animateForward: true})
        }
    }

    render () {
        var defaultText = this.props.defaultText || "Start typing";
        const { classes, theme } = this.props; 
        return (
            <>
                <SnackbarInfo 
                    alertText = {'Try to be concise and clear in your message. Rocky can only comprehend one message at a time.'}
                    openOnClick = {true}
                    alertOpenEvent = {this.state.flagOverLength || false}
                    closeHandler = {() => {this.setState({flagOverLength: false})}}
                />
                <TextField
                    variant="outlined"
                    //autoFocus
                    type="text"
                    onChange={(e) => this.onChange(e)}
                    onFocus={(e) => this.onChange(e)}
                    value={this.state.text}
                    inputRef={this.refTextInput}
                    multiline
                    placeholder = {this.state.placeholderText || (defaultText+" ...")}
                    className={classes.textInputFrame}
                    InputProps={{
                        style: {
                            fontSize: '16px',
                            color: this.state.flagOverLength ? (theme?.palette?.themeAlertRed || "red") : 'black'
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {this.props.phase === 'session_requested'
                                ?
                                    this.state.text?.length > 1
                                    ?
                                        <IconButton
                                            edge="end"
                                            aria-label="Send message"
                                            onClick={ () => this.props.sendMeasurement(this.state.text)}
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    :                                
                                        <IconButton
                                            edge="end"
                                            aria-label="Send message"
                                            onClick={ () => {this.updateText("I am normal today")}}
                                        >
                                            <SendIcon 
                                                style={{
                                                    color: (theme?.palette?.themeAltLightGrey || "grey")
                                                }} 
                                            />
                                        </IconButton>
                                :
                                    this.state.text!==""
                                    ?
                                        <IconButton
                                            edge="end"
                                            aria-label="Send message"
                                            onClick={ () => this.onSubmit()}
                                        >
                                            {(this.state.text.length > 10 || this.state.animateForward)
                                                ?   <SendIcon 
                                                        style={{
                                                            color: (theme?.palette?.themeAlertRed || "red"), 
                                                            animation: this.state.animateForward? 'pulse 4s infinite ease-in-out' : 'none',
                                                        }} 
                                                    />
                                                :   <SendIcon  />
                                            }
                                            
                                        </IconButton>
                                    :            
                                        <IconButton
                                            edge="end"
                                            aria-label="Send message"
                                            onClick={ () => { this.updateText("I need help with ") }}
                                            //onClick={this.state.text!=="" ?  this.props.onSubmit(this.state.text) : () =>  this.updateText("I need help with ")}
                                        >
                                            <SendIcon 
                                                style={{
                                                    color: (theme?.palette?.themeAltLightGrey || "grey")
                                                }} 
                                            />
                                        </IconButton>
                                }
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start">
                                {this.props.microphone 
                                ?
                                    <SpeechToTextButton 
                                        renderKey={'main_input_'}
                                        isNonFocus={this.state.texfieldisNonFocus!== undefined ? this.state.texfieldisNonFocus : true} 
                                        returnText={(result) => this.updateText(result)}
                                        existingText={this.state.text || ""}
                                        setFocusHandler={() => this.refTextInput.current.focus()} 
                                    />
                                :   this.props.specialFabActive && this.state.text?.length > 1
                                    ?
                                        <IconButton
                                            aria-label="Reset text app"
                                            onClick={() => {
                                                try{
                                                    this.setState({text: "", flagOverLength: false });
                                                    if(this.props.onChange !== undefined){
                                                        this.props.onChange("", (true))
                                                    }
                                                    //this.onChange({event: {target: {value:""}}})
                                                }
                                                catch(e){
                
                                                }
                                            }}
                                        >
                                            <ClearIcon 
                                                size={'small'}
                                            />
                                        </IconButton>
                                    :   ""
                                }
                            </InputAdornment>
                        ),
                    }}
                />
            </>
        )
    }

    onSubmit(text=''){
        this.setState({flagOverLength: false, open: false});
        if(text === ''){
            text = this.state.text;
        }
        if(this.props.onSubmit !== undefined){
            this.props.onSubmit(text);
            this.setState({text: ""});
        }
    }
    
    onChange = event => {
        //console.log('update', event)
        var maxStringLength= 160;
        if(this._isMounted){
            if(this.state.texfieldisNonFocus === undefined) {
                this.setState({texfieldisNonFocus: false})
            }
            var text = event.target.value 
            if(text!=='' && text.length < maxStringLength) {
                this.setState({ text: text, flagOverLength: false });
                if(this.props.onChange !== undefined){
                    this.props.onChange(event.target.value, (this.props.specialFabActive || false))
                }
            }
            else if(text === ''){
                this.setState({text, flagOverLength: false });
            }
            else if(text !== undefined && text !== null){
                text = text.slice(0, maxStringLength-1)
                this.setState({text, flagOverLength: true });
            }
        }
    };
    updateText = text => {
        if(this._isMounted){
            this.setState({ text: text});
        }
    };

    blinker = () => {
        var defaultText = this.props.defaultText || "Start typing";
        //console.log('text', this.state.text)
        if(this.state.text === '' && this._isMounted){
            if(this.state.placeholderText === defaultText){
                this.setState({placeholderText: (defaultText+' .')});
                setTimeout(this.blinker, 1000);
            }
            else if(this.state.placeholderText === (defaultText+' .')){
                this.setState({placeholderText: (defaultText+' ..')});
                setTimeout(this.blinker, 1000);
            }
            else if(this.state.placeholderText === (defaultText+' ..')){
                this.setState({placeholderText: (defaultText+' ...')});
                setTimeout(this.blinker, 1000);
            }
            else{
                this.setState({placeholderText: defaultText});
                setTimeout(this.blinker, 1000);
            }
        }
    }
}


RoutineWithContent.propTypes = {
    classes: PropTypes.object.isRequired
};

const condition = authUser => !!authUser;

export default compose(
    withRouter,
    withStyles(styles, { withTheme: true}),
    withFirebase,
    withAuthorization(condition),
)(RoutineWithContent);
