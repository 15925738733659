import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import withStyles from '@material-ui/core/styles/withStyles';
import { AddAnalytics } from '../Modules/AddAnalytics';

import {AvatarCard} from '../Modules/AvatarImage';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import ProgressDoughnut from '../Charts/ProgressDoughnut';

import LinearProgress from '@material-ui/core/LinearProgress';

//// List lements
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
import Slider from '@material-ui/core/Slider';

import Button from '@material-ui/core/Button';
import GradeIcon from '@material-ui/icons/Grade';

import {IsMobileDevice} from '../Modules/IsMobileDevice';

import DialogAssessment from '../Assessments/DialogAssessment';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import {RemoveScroll} from 'react-remove-scroll';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import NaturePeopleIcon from '@material-ui/icons/NaturePeople';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SnackbarInfo from '../Modules/SnackbarInfo';

import {ExtendDialogueMessage} from '../Routine/DiaryManual';

import Slide from '@material-ui/core/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});

const StyledSlider = withStyles({
    root: {
        color: '#fcd750',
        height: 9
    },
    disabled :{
        thumb: {
            "height": 20,
            "width": 20,
            "marginTop": -6,
            "marginLeft": -12
          }
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#2d2c4c',
        border: '3px solid #2d2c4c',
        marginTop: -6,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',    
        },
        "&$disabled": {
            "height": 20,
            "width": 20,
            "marginTop": -6,
            "marginLeft": -12
        }
    },
    active: {},
    track: {
        backgroundColor: '#fcd750',
        opacity: 0.38,
        height: 9,
        borderRadius: 10,
    },
    rail: {
        height: 9,
        borderRadius: 10,
    }
})(Slider);

const resultTexts = {
    O : {
        "75": "You are open-minded and creative.",
        "55": "You are often curious.",
        "50": "You are down to earth but appreciate innovation.",
        "45": "You prefer to have routines.",
        "25": "You prefer predictable outlooks.",
        text : "Openness",
        lowText : "Closed",
        highText : "Open",
        description : "Openness to experience refers to willingness to try new things as well as engage in imaginative and intellectual activities.",
    },
    C: {
        "75": "You are disciplined.",
        "55": "You are pretty organized.",
        "50": "You switch between focus and multitasking.",
        "45": "You are rather spontaneous.",
        "25": "You prefer to be flexible in your plans.",
        text : "Conscientiousness",
        lowText : "Careless",
        highText : "Organized",
        description : "Conscientiousness describes a person’s ability to regulate their impulse control in order to engage in goal-directed behaviors."
    },
    E: {
        "75": "You are outgoing and extrovert.",
        "55": "You appreciate to work with others.",
        "50": "You sometimes like to work with others.",
        "45": "You prefer to work independently.",
        "25": "You prefer to stay formal and serious.",
        text : "Extroversion",
        lowText : "Quiet",
        highText : "Outgoing",
        description : "Extraversion reflects the tendency and intensity to which someone seeks interaction with their environment, particularly socially."
    },
    A: {
        "75": "You are forgiving and compassionate.",
        '55': "You are willing to co-operate.",
        "50": "Sometimes you can agree to a win-win situation.",
        "45": "You prefer competition.",
        "25": "You can be demanding and sceptical.",
        text : "Agreeableness",
        lowText : "Critical",
        highText : "Warm",
        description : "Agreeableness refers to how people tend to treat relationships with others." 
    },
    N: {
        "75": "You are thoughtful and sensitive to threats.",
        "55": "You could be impulsive and motivated by emotions.",
        "50": "You have a moderate threshold to handle stress.",
        "45": "You are rather a relaxed person.",
        "25": "You are calm and resilient.",
        text : "Neuroticism",
        lowText : "Calm",
        highText : "Anxious",
        description : "Neuroticism describes the overall emotional stability of an individual through how they perceive the world."
    }
}

class CardBigFive extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        var loadResultTexts = resultTexts;
        if(this.props.resultTexts !== undefined){
            loadResultTexts = {...resultTexts, ...this.props.resultTexts};
        }
        this.state = {
            loading: true,
            openAlert: false,
            userTraits: {},
            //userTraits: {O: 50, C:50, E: 50, A: 50, N : 50},
            resultTexts: loadResultTexts,
            alertText: "This self assessment is based on the OCEAN / Big Five personality traits."
        };    
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('pops', this.props)
        if(this.props.user!== undefined){
            this.loadPersonalityTrait(this.props.user.uid); 
        }
        else{
            this.loadPersonalityTrait(this.context.uid);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.unsubscribe!== undefined) this.unsubscribe();
    }
    componentDidUpdate(prevProps)  {
        if(this._isMounted ){
            if(this.props.openHandler && this.state.openDialog!==true ){
                this.setState({openDialog : true})
                //console.log('updatedate bigfive props', prevProps,this.props )
            }
            if(this.props.openHandler === false && this.state.openDialog ){
                this.setState({openDialog : false})
                //console.log('close bigfive props', prevProps,this.props )
            }
            if(this.props.assessmentResults !== undefined && this.props.assessmentResults !== prevProps.assessmentResults ){
                //console.log('close bigfive props', prevProps, this.props )
                if(this.props.user!== undefined){
                    this.loadPersonalityTrait(this.props.user.uid); 
                }
                else{
                    this.loadPersonalityTrait(this.context.uid);
                }
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {  
        if (nextProps.rerender === false) {  
            //console.log('no rerender')
            return false;  
        }  
        //console.log(' rerender')
        return true;  
    }

    loadPersonalityTrait(userId)  {
        //console.log('loadingtraits', this.props, this.state)
        if(this.props.assessmentResults !== undefined){
            if(this.props.assessmentResults.scores !== undefined ){
                this.setState({ 
                    userScores: this.props.assessmentResults.scores,
                    createdAt: this.props.assessmentResults.createdAt,
                    loading: false,
                    assessmentAvailable: true,
                });
                if(this.props.isCoach !== true){
                    AddAnalytics('goal_completion', 'GoalCompleteAssessment');
                }
                //console.log('score laoded', this.props.assessmentResults.scores)
            }
            else if(this.props.assessmentResults.userAssessment !== undefined ){
                //console.log('userTratis',this.props.assessmentResults.userAssessment )
                this.setState({ 
                    //botTraits: this.props.assessmentResults.userAssessment ,
                    userTraits: this.props.assessmentResults.userAssessment ,
                    createdAt: this.props.assessmentResults.createdAt,
                    loading: false,
                    assessmentAvailable: true,
                });
                if(this.props.isCoach !== true){
                    AddAnalytics('goal_completion', 'GoalCompleteAssessment');
                }
            }
        }
        else{
            this.unsubscribe = this.props.firebase
            .personalityTrait(userId)
            //.get().then(snapshot => {
            .onSnapshot(snapshot => {
                if (!snapshot.empty) {
                    var userData = snapshot.data();
                    console.log('userData', userId, userData)
                    if(this._isMounted) {
                        if(userData !== undefined && userData.userAssessment !== undefined){
                            //var botTraits = userData;
                            var userTraits = userData.userAssessment;
                            this.setState({ 
                                //botTraits,
                                createdAt: userData.createdAt,
                                userTraits,
                                loading: false,
                                assessmentAvailable: true,
                            });
                        }
                        else{
                            this.setState({ 
                                loading: false,
                                assessmentAvailable: false,
                            });
                        }
                    }
                    //console.log('traits loaded', userData)
                }
            });
        }
    }  

    changeUserTrait = (trait, value) => {
        var userTraits = this.state.userTraits;
        userTraits[trait] = value;
        this.setState({userTraits});
    }

    XXXsaveUserTrait = (trait, value) => {
        var userTraits = this.state.userTraits;
        userTraits[trait] = value;
        //this.setState({userTraits});
        
        var answer = ""
        if(value >= 75){
            answer = this.state.resultTexts[trait]['75']
        }
        else if(value >= 55){
            answer = this.state.resultTexts[trait]['55']
        }
        else if(value <= 25){
            answer = this.state.resultTexts[trait]['25']
        }
        else if(value <= 45){
            answer = this.state.resultTexts[trait]['45']
        }
        else {
            answer = this.state.resultTexts[trait]['50']
        }
        if(answer !== ""){
            ExtendDialogueMessage(this.props.firebase, this.context, answer, "How would you describe your personality?", 'utter_ask_assessment', '', 'profile');
        }
    }

    handleClose = () => {
        /***
         */
        var uid = '';
        if(this.props.user!== undefined){
            uid = this.props.user.uid; 
        }
        else{
            uid = this.context.uid; 
        }
        var data = {
            createdAt : this.props.firebase.fieldValue.serverTimestamp(),
            type: 'self-assessment',
            //O :  this.state.userTraits["O"],
            //C :  this.state.userTraits["C"],
            //E :  this.state.userTraits["E"],
            //A :  this.state.userTraits["A"],
            //N :  this.state.userTraits["N"],
            userAssessment : {
                //O :  this.state.userTraits["O"],
                //C :  this.state.userTraits["C"],
                //E :  this.state.userTraits["E"],
                //A :  this.state.userTraits["A"],
                //N :  this.state.userTraits["N"],
            },
            //quality : 1,
            userId: uid
        }
        //console.log('close assess')
        //this.props.firebase.personalityTrait(uid).set(data, {merge:true }); 
        //this.props.firebase.personalityTraitArchive(uid).add(data); 

        if(this.props.userTraitHandler && this.props.isCoach !== true){
            //console.log("updating user tratis from bigfive")
            var OCEANtraits = [
                this.state.userTraits['O'],
                this.state.userTraits['C'],
                this.state.userTraits['E'],
                this.state.userTraits['A'],
                this.state.userTraits['N']
            ]
            //this.props.userTraitHandler(this.state.userTraits);
        }
        if(this.props.handleClose){
            //console.log("updating user tratis from bigfive")
            this.props.handleClose();
        }
        this.setState({openDialog : false});
        //console.log("clsoing big 5", this.props)
    }

    render() {
        const { theme } = this.props;
        return(
            <div>
                {this.props.showGraphs &&
                    this.renderResults(true) 
                }
                {this.props.showSummary &&
                    this.renderResults(false) 
                }
                {this.props.allowDialog && this.props.showButton!==false &&
                    <>
                        <Button onClick={() => { this.setState({openDialog: true}) }} style={{margin : 15}}>
                            Assessment Results
                        </Button>
                        <Button onClick={() => this.setState({handleShortAssessmentOpen: true})} style={{margin : 15}}>Short Assessment</Button>
                        <Button onClick={() => this.setState({handleLongAssessmentOpen: true})} style={{margin : 15}}>Long Assessment</Button>

                        {/***
                        <Button onClick={() => this.setState({handleEggupDefaultOpen: true})} style={{margin : 15}}>Eggup Assessment</Button>
                    */}
                    
                    </>
                }
                {this.props.allowDialog && this.props.isCoach !== true
                    &&
                    <>
                        <DialogAssessment
                            defaultSet={this.props.defaultSet || undefined}
                            handleOpen={this.state.handleShortAssessmentOpen || false}
                            assessmentId = {'5pEXdA6wH9eYIyFHTFlx'}
                            handleClose={() => {this.setState({handleShortAssessmentOpen: false})}}
                            user={this.context}
                        />
                        <DialogAssessment
                            defaultSet={this.props.defaultSet || undefined}
                            handleOpen={this.state.handleLongAssessmentOpen || false}
                            assessmentId = {'imNF11vDbNhqYsvDs2WK'}
                            handleClose={() => {this.setState({handleLongAssessmentOpen: false})}}
                            user={this.context}
                        />
                        {/***
                        <DialogAssessment
                            defaultSet={this.props.defaultSet || undefined}
                            handleOpen={this.state.handleEggupDefaultOpen || false}
                            assessmentId = {'ADF99F088A695252C9DBEB5311B612C2'}
                            eggupAssessment = {true}
                            handleClose={() => {this.setState({handleEggupDefaultOpen: false})}}
                            user={this.context}
                        />
                         */}
                    </>
                }


                {this.props.allowDialog &&
                    <RemoveScroll enabled={this.state.openDialog || false}> 
                        <Dialog
                            open={this.state.openDialog || false}
                            onClose={this.handleClose}
                            fullScreen={IsMobileDevice()}
                            PaperProps={{style:theme.dialogWindow}}
                            TransitionComponent={Transition}
                            maxWidth="sm"
                            >
                            <DialogTitle style={theme.dialogTitle} >
                                <Grid container spacing={0} direction="row"
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item >
                                        <IconButton onClick={this.handleClose}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleText}>
                                            Personality Traits
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <span style={theme.dialogTitle.titleLink} onClick={this.handleClose}>
                                            Close
                                        </span>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent style={theme.dialogContent}>
                                {this.state.openDialog 
                                &&
                                    <div>
                                        <div style={{textAlign: "center"}}>
                                            {this.state.openDialog &&
                                                this.renderResults(true) 
                                            }
                                        </div>
                                        {this.props.isCoach !== true
                                            ?
                                                <div style={{textAlign: "center", marginTop: 40}}>
                                                    <Button onClick={() => this.setState({handleShortAssessmentOpen: true})}>Short Assessment</Button>
                                                    &nbsp;&nbsp;
                                                    <Button onClick={() => this.setState({handleLongAssessmentOpen: true})}>Long Assessment</Button>
                                                </div>
                                            :
                                                this.props.user !== undefined && this.props.user.roles !== undefined && (this.props.user.roles.includes("COACHEE") || this.props.user.roles.includes("OBSERVE") )
                                                    ?
                                                        <div style={{textAlign: "center", marginTop: 40}}>
                                                            {this.state.allowTraitChange
                                                                ?   <Button onClick={() => this.setState({allowTraitChange: false})}>Save User Traits</Button>
                                                                :   <Button onClick={() => this.setState({allowTraitChange: true})}>Change User Traits</Button>
                                                            }
                                                        </div>
                                                    :
                                                        ''
                                        }
                                    </div>
                                }
                            </DialogContent>
                        </Dialog>
                    </RemoveScroll>  
                }
            </div>
        )
    }

    renderResults(showSliders){
        const {theme} = this.props;
        //console.log('rederresults', this.state)
        if(this.state.userScores !== undefined){
            return(
                <div>
                    {Object.keys(this.state.userScores).map( (key, index) => {
                        if(index===0) return (
                            <div key={index}>
                                {this.props.type === 'quick'
                                ?
                                    <p style={theme.textBigTitleText}>
                                        <NaturePeopleIcon style={theme.cardSettings.iconDesign} />
                                        {this.props.questionnaireDetails?.title?.length>0 ? this.props.questionnaireDetails.title : key}
                                    </p>
                                :
                                    <AvatarCard title={true} 
                                        textBlock={this.props.questionnaireDetails?.title?.length>0 ? this.props.questionnaireDetails.title : key}
                                        type={'icon'} image={<NaturePeopleIcon/>} squareSize='20' backgroundColor={'black'}/>
                                }
                                {this.state.createdAt?.seconds > 0 
                                    &&
                                    <p style={{marginBottom: 10}}>
                                        {ConvertTimestampToDateString(this.state.createdAt , true)}
                                    </p>
                                }
                                {   this.renderScores(key, this.state.userScores[key])   }
                            </div>
                        )
                        return(
                            <div key={index}>
                                {   this.renderScores(key, this.state.userScores[key])   }
                            </div>
                        )
                    })}
                </div>
            )
        }
        else if(this.state.userTraits !== undefined){
           return this.renderTraitsResults(showSliders);
        }
    }

    renderTraitsResults(showSliders) {
        const { theme } = this.props;
        var title = 'Traits';
        var traitsList = [];
        if(this.state.userTraits?.['O'] !== undefined){
            traitsList = ["O", "C", "E", "A", "N"];
        }
        else if(this.state.userTraits?.['pP'] !== undefined){
            traitsList = ["pP", "pE", "pR", "pM", "pA", "pV"];
        }
        else if(this.state.userTraits !== undefined){
            traitsList = Object.keys(this.state.userTraits);
        }
        //console.log('traitsList', traitsList, this.state, this.props)
        return(
            <div >
                {this.props.type === 'quick'
                    ?
                        <p style={theme.textBigTitleText}>
                            <NaturePeopleIcon style={theme.cardSettings.iconDesign} />
                            {this.props.questionnaireDetails?.title?.length>0 ? this.props.questionnaireDetails.title : title}
                            
                        </p>
                    :
                        <AvatarCard title={true} 
                            textBlock=
                            {this.props.questionnaireDetails?.title?.length>0 ? this.props.questionnaireDetails.title : title}
                            type={'icon'} image={<NaturePeopleIcon/>} squareSize='20' backgroundColor={'black'}/>
                        
                }
                {this.state.createdAt?.seconds > 0 
                    &&
                    <p>
                        {ConvertTimestampToDateString(this.state.createdAt , true)}
                    </p>
                }
                {false && showSliders &&
                    <p><strong>Self-assessment</strong></p>
                }
                
                {showSliders
                    ?
                        <div>
                            <SnackbarInfo 
                                alertText = {this.state.alertText}
                                openOnClick = {true}
                                alertOpenEvent = {this.state.openAlert || false}
                                closeHandler = {() => {this.setState({openAlert: false})}}
                            />
                            {this.state.userTraits !== undefined && this.state.resultTexts !== undefined && traitsList.map(trait => (
                                <Grid
                                    container
                                    key={"gridforbar_"+trait}
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    //className={classes.chat}
                                    > 
                                        <Grid item xs={12}>
                                            <h4 style={{...theme.textSupportCenterPadding, paddingTop: 10, marginBottom:0, color: theme.palette.secondary.main}}
                                                onClick={() => this.setState({ alertText: (this.state.resultTexts[trait]?.['description'] || trait), openAlert: true}) }
                                            >
                                                {this.state.resultTexts[trait]?.['text'] || trait}
                                                &nbsp;
                                                {this.state.resultTexts[trait]?.['description']?.length>1
                                                    &&
                                                    <HelpOutlineIcon 
                                                        style={{verticalAlign: 'middle', color: theme.palette.secondary.main}}
                                                    />
                                                }
                                            </h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{marginBottom: -20, textAlign:"left"}}>
                                                {this.state.resultTexts[trait]?.['lowText'] || "Low"}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{marginBottom: -20, textAlign:"right"}}>
                                                {this.state.resultTexts[trait]?.['highText'] || "High"}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} key={"sgridder_"+trait}>
                                            <div style={{position:'relative',
                                                textAlign: 'center',
                                                zIndex: 2,
                                                top: 0,
                                                height: 10,
                                                width: '100%',
                                                color: theme.palette.themeRed}}
                                                >
                                            </div>
                                            {this.state.allowTraitChange
                                                ?
                                                    <StyledSlider
                                                        disabled={this.state.allowTraitChange !== undefined ? !this.state.allowTraitChange : true}
                                                        key={"slider_"+trait}
                                                        value={this.state.userTraits[trait] !== undefined ? this.state.userTraits[trait] : 50}
                                                        min={0}
                                                        max={100}
                                                        //marks={[{value: 0}, {value:100}, {value:this.state.botTraits[trait]}]}
                                                        aria-labelledby={this.state.resultTexts[trait]?.['text'] || trait}
                                                        onChange={ 
                                                            this.state.allowTraitChange && this.props.isCoach
                                                                ? (event, value) => this.changeUserTrait(trait, value)
                                                                : void(0)
                                                        }
                                                    />
                                                :
                                                    <LinearProgress 
                                                        key={"linear_"+trait}
                                                        variant="determinate"
                                                        color="secondary"
                                                        style={{height: 8, marginBottom: 10, marginTop: 10, borderRadius: 12}}
                                                        value={this.state.userTraits[trait] !== undefined ? this.state.userTraits[trait] : 50}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ProgressDoughnut
                                                percentage={this.state.userTraits[trait] !== undefined ? this.state.userTraits[trait]: 50}
                                                //colorMain = {bgColor}
                                                //colorMain={theme.palette.green+'44'}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <div style={{textAlign: 'left', paddingLeft: 20}}>
                                                {this.props.user?.currentAssessment === undefined && this.state.assessmentAvailable!==true && this.props.isCoach !== true
                                                    ?   <span style={{color: 'red'}}>Please scroll down and start your self-assessment!<br/></span>
                                                    :   <strong> 
                                                            {this.state.resultTexts[trait] === undefined
                                                            ?   ''
                                                            :   this.state.userTraits[trait] >= 75
                                                                    ? this.state.resultTexts[trait]['75']
                                                                    : this.state.userTraits[trait] > 55
                                                                        ? this.state.resultTexts[trait]['55']
                                                                        : this.state.userTraits[trait]<= 25
                                                                            ? this.state.resultTexts[trait]['25']
                                                                            : this.state.userTraits[trait] < 45
                                                                                ? this.state.resultTexts[trait]['45']
                                                                                : this.state.resultTexts[trait]['50']
                                                            }
                                                            <br/>
                                                        </strong>
                                                }
                                                <br/>
                                                {this.state.resultTexts?.[trait]?.['description']}
                                            </div>
                                        </Grid>
                                </Grid>
                            ))}
                        </div>
                    :
                        <div>
                            <List onClick={() => { this.setState({openDialog: true}) }}>
                            {  this.state.userTraits !== undefined &&   ["O", "C", "E", "A", "N"].map(trait => (
                                <ListItem key={"list_"+trait}  style={{paddingLeft:0, paddingRight:0}}>
                                    <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:theme.palette.themeGold}}>
                                        <GradeIcon /> 
                                    </ListItemIcon>
                                    <ListItemText>
                                    { this.state.userTraits[trait] >= 55
                                        ? 
                                            this.state.userTraits[trait] >=75
                                            ?
                                                this.state.resultTexts[trait]['75'] 
                                            :
                                                this.state.resultTexts[trait]['55'] 
                                        
                                        :   this.state.userTraits[trait] <=25
                                            ? 
                                                this.state.resultTexts[trait]['25'] 
                                            :
                                                this.state.userTraits[trait]<=45
                                                ? 
                                                    this.state.resultTexts[trait]['45']
                                                :   
                                                   this.state.resultTexts[trait]['50'] 
                                    }
                                    </ListItemText>
                                </ListItem>
                            )) }
                            </List>
                            {this.props.showNumbers
                            &&
                                <p style={{fontSize:'small', color: theme.palette.themeBlue}}>O : { this.state.userTraits.O > 100 ? 95 :  Math.ceil( this.state.userTraits.O)}%,&nbsp;
                                C : { this.state.userTraits.C > 100 ? 95 :  Math.ceil( this.state.userTraits.C)}%,&nbsp;
                                E : { this.state.userTraits.E > 100 ? 95 :  Math.ceil( this.state.userTraits.E)}%,&nbsp;
                                A : { this.state.userTraits.A > 100 ? 95 :  Math.ceil( this.state.userTraits.A)}%,&nbsp;
                                N : { this.state.userTraits.N > 100 ? 95 :  Math.ceil( this.state.userTraits.N)}%
                                </p>
                            }
                        </div>
                }
            </div>
        );
    }

    renderScores(title, score, showSliders=true) {
        //console.log('scores', title, score)
        const { theme } = this.props;
        return(
            <div style={{marginTop: 30}}>
                {showSliders
                    ?
                        <div>
                            <SnackbarInfo 
                                alertText = {this.state.alertText}
                                openOnClick = {true}
                                alertOpenEvent = {this.state.openAlert || false}
                                closeHandler = {() => {this.setState({openAlert: false})}}
                            />
                            { score!== undefined && Object.keys(score).map(trait => (
                                <Grid
                                    container
                                    key={"gridforbar_"+trait}
                                    direction="row"
                                    justify="space-between"
                                    alignItems="stretch"
                                    //className={classes.chat}
                                    > 
                                        <Grid item xs={12}>
                                            <h4 style={{...theme.textSupportCenterPadding, paddingTop: 10, marginBottom:0, marginTop:16}}>
                                                {score[trait]['trait_name']}
                                                &nbsp;
                                                <HelpOutlineIcon 
                                                    style={{verticalAlign: 'middle'}}
                                                    onClick={() => this.setState({ alertText: score[trait]['test_name'], openAlert: true}) }
                                                    />
                                            </h4>
                                        </Grid>
                                        <Grid item xs={12} key={"sgridder_"+trait}>
                                            <div style={{position:'relative',
                                                textAlign: 'center',
                                                zIndex: 2,
                                                top: 0,
                                                height: 10,
                                                width: '100%',
                                                color: theme.palette.themeRed}}
                                                >
                                                    {
                                                        //score[trait]['trait_description']
                                                    }
                                                </div>
                                            {this.state.allowTraitChange
                                                ?
                                                    <StyledSlider
                                                        disabled={this.state.allowTraitChange!== undefined ? !this.state.allowTraitChange : true}
                                                        key={"slider_"+trait}
                                                        value={score[trait]['value'] !== undefined ? score[trait]['value']: 50}
                                                        min={0}
                                                        max={100}
                                                        //marks={[{value: 0}, {value:100}, {value:this.state.botTraits[trait]}]}
                                                        aria-labelledby={score[trait]['trait_name']}
                                                        onChange={ 
                                                            this.state.allowTraitChange && this.props.isCoach
                                                                ? (event, value) => this.changeUserTrait(trait, value)
                                                                : void(0)
                                                        }
                                                    />
                                                :
                                                    <LinearProgress 
                                                        key={"linear_"+trait}
                                                        variant="determinate"
                                                        color="secondary"
                                                        style={{height: 8, marginBottom: -12, marginTop: 4, borderRadius: 12}}
                                                        value={this.state.userTraits[trait] !== undefined ? this.state.userTraits[trait] : 50}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{marginTop: -15, marginBottom: 20, textAlign:'left'}}>
                                                Low
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div style={{marginTop: -15, marginBottom: 20, textAlign:"right"}}>
                                                High
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ProgressDoughnut
                                                percentage={score[trait]['value'] !== undefined ? score[trait]['value'] : 50}
                                                //colorMain = {bgColor}
                                                //colorMain={theme.palette.themeGreen+'44'}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <div style={{textAlign: 'left', paddingLeft: 10}}>
                                                {score[trait]['trait_description']}
                                            </div>
                                        </Grid>
                                </Grid>
                            ))}
                        </div>
                    :
                        <div>
                            <List onClick={() => { this.setState({openDialog: true}) }}>
                            { score!== undefined && Object.keys(score).map(trait => (
                                <ListItem key={"list_"+trait}  style={{paddingLeft:0, paddingRight:0}}>
                                    <ListItemIcon style={{marginRight:10, minWidth:'auto',  color:theme.palette.themeGold}}>
                                        <GradeIcon /> 
                                    </ListItemIcon>
                                    <ListItemText>
                                        {score[trait]['trait_description']}
                                    </ListItemText>
                                </ListItem>
                            )) }
                            </List>
                        </div>
                }
            </div>
        );
    }
}

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(CardBigFive);
