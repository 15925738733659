import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { AddAnalytics } from '../Modules/AddAnalytics';
import {stylesSignin, pageText} from '../SignIn';

import {SignInSocialForward} from "./SignInPage";

// auto push after reload with social
//import ChatBlock from '../Routine/ChatBlock';

//import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import {StoreMobileDeviceInfo } from '../Modules/StoreMobileDeviceInfo';
//modules from other file
import { CreateUserWithEmail } from '../SignUp';
import { MyInstallationOfflineDataSet, GetDefaultUserSetup } from '../Modules/DefaultCode';
import { DataCacheWrite} from "../Modules/DataCache";

//import {BackgroundTextSupportColor} from "../Modules/BackgroundMode";
//import backgroundImage from "../../images/login-background.jpg";
//import backgroundImage from "../../images/login-background.png";
//import backgroundImageApp from "../../images/login-background.gif";
//import backgroundImageSignupCoach from "../../images/signup_coach.png";
//import backgroundImageSignup from "../../images/bluerunningtrack3.jpg";
//import backgroundImageSignupCoach from "../../images/backgroundlogin_rocky3.jpg";
import EmailIcon from '@material-ui/icons/Email';
//import logo from "../../images/rockyai-logo-image-only.png";
import logo from "../../images/rocky-logo-animated.gif";

// MUI stuff
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingProgress from '../Modules/LoadingProgress';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { IsMobileDevice } from "../Modules/IsMobileDevice";

class SignInPage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        var loginType = 'normal';

        this.state = { ...INITIAL_STATE , loginType};
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('this.shrinkBackTrick', this.shrinkBackTrick)
        AddAnalytics('screen_view', 'PageSignIn', 'SignInTeams');
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render () {
        const { classes, theme } = this.props;
        //const { error } = this.state;
        //console.log('state reload', this.state.redirectLoginDetected, this.state)
        if(this.state.firebaseConnectionError===true){
            return(
                <div style={theme.rootFrame}>
                    <div className={ 
                            //(process?.env !== undefined && process.env.REACT_APP_DEVICE_PLATFORM !== undefined && process.env.REACT_APP_DEVICE_PLATFORM === "BROWSER")
                            !IsMobileDevice()
                            ? classes.root : classes.rootAnimated}>
                        <div style={{paddingTop: '50%', paddingBottom:20}}>
                            <LoadingProgress 
                                defaultSet={this.props.defaultSet}
                                type={'LoadingPageAnimated'} color='light' loadingText={"I'm getting ready"} />
                        </div>
                        <p style={theme.textCenterPadding}>
                            &nbsp; {this.state.firebaseConnectionMessage}
                        </p>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={theme.rootFrame}>
                    <div className={classes.rootSignupCoach}>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} style={{ maxWidth: 500 }}>
                                <div style={theme.WelcomeLogin}>
                                    <div style={theme.logoContainer}>
                                        <img src={logo} className={classes.logo} alt="Rocky.ai Logo Chatbot Journaling" />
                                        <h4>Welcome to ROCKY for COACHES</h4>
                                        <p style={theme.divCenter}>
                                            Login if you are a <strong>Coach</strong> or if you have a <strong>ROCKY for Coaches/Teams management account</strong>.<br /> <br /> 
                                            Or click <Link style={{color: theme.palette.themeAlertRed}} to={ROUTES.SIGN_UP} onClick={() => {window.open('https://app.rocky.ai', '_self'); window.name = 'Rocky.ai APP';}}>here</Link> if you want to use Rocky only for yourself!
                                        </p>
                                    </div>
                                    
                                    <div>
                                    </div>
                                    <div style={{paddingTop: 40, paddingBottom: 80}}>
                                        <div className={classes.socialLogins}>
                                            <SignInForm 
                                                loginType={this.state.loginType}
                                            /> 
                                        </div>
                                        <table width="100%" style={{paddingTop: 10, paddingBottom: 10}}>
                                            <tbody>
                                                <tr>
                                                    <td><hr /></td>
                                                    <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                                        <span className={classes.socialLoginPrompt}>or</span>
                                                    </td>
                                                    <td><hr /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className={classes.socialLogins}>
                                            <Button onClick={() => {this.props.history.push( {pathname: ROUTES.COACH_SIGN_UP})}} >
                                                Create Coach Account
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }
    };//end render
}

const INITIAL_STATE = {
    email: "",
    password: "",
    error: null
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;
        var error = {};
        if(password!== undefined && password!=='' && password.length>1){
            this.setState({redirectLoginDetected: true, error: null})
            console.log('loging')
            this.props.firebase
                .doSignInWithEmailAndPassword(email, password)
                .then((authUser) => {
                    //console.log(authUser)
                    var receivedUserId = authUser.user.uid;
                    StoreMobileDeviceInfo(this.props.firebase.user(receivedUserId));
                    //console.log('listener esle results', result);
                    //this.setState({redirectHome : true});
                    this.doublecheckCoachUserSignin('email login', email, undefined, receivedUserId);
                })
                .catch(error => {
                    if (error.code === pageText.ERROR_CODE_USER_NOT_FOUND) {
                        error.message = pageText.ERROR_MSG_USER_NOT_FOUND;
                    }
                    if (error.code === pageText.ERROR_CODE_WRONG_PASSWORD) {
                        error.message = pageText.ERROR_MSG_WRONG_PASSWORD;
                        this.props.firebase.fetchSignInMethodsForEmail(email)
                        .then(result => {
                            if(result.includes('google.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_GOOGLE;
                                this.setState({ error });
                            }
                            else if(result.includes('apple.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_APPLE;
                                this.setState({ error });
                            }
                            else if(result.includes('facebook.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_FACEBOOK;
                                this.setState({ error });
                            }
                            else if(result.includes('microsoft.com')){
                                error.message = pageText.ERROR_MSG_SOCIAL_MICROSOFT;
                                this.setState({ error });
                            }
                        })
                    }
                    if (error.code === pageText.ERROR_CODE_INVALID_EMAIL) {
                        error.message = pageText.ERROR_MSG_INVALID_EMAIL;
                    }
                    this.setState({ error, redirectLoginDetected: false });
                });
            event.preventDefault();
        }
        else if (email === ''){
            error = {code: '000', message: 'Please add your email!'};
            this.setState({ error, redirectLoginDetected: false });
            event.preventDefault();
        }
        else{ 
            error = {code: '000', message: 'Please add your password!'};
            this.setState({ error, redirectLoginDetected: false });
            event.preventDefault();
        }
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value.trim() });
    };

    doublecheckCoachUserSignin = (signInMethod, email, username, uid) => {
        if(uid!==undefined && uid!==null && uid!==''){
            //console.log('doublechek', username, uid)
            var userExisting = true;
            this.props.firebase.user(uid).get()
            .then(  (userCheck) =>  {
                //console.log('usercheck', userCheck, userCheck.data())
                //if(userCheck === undefined || userCheck === null || userCheck.data()===undefined 
                //|| userCheck.data()===null || (Object.keys(userCheck.data()).length <= 2)){
                if(!userCheck.exists) {
                    var data = userCheck.data();
                    if (!("username" in data)) {
                        userExisting = false;
                        // deleted or missing user content, start new signup
                        //console.log('no userdata available')
                        signInMethod = signInMethod + " after undetected user data";
                        if(this.state.username!==undefined && this.state.username.length>1){
                            username = this.state.username;
                        }
                        else if(this.props.username!==undefined && this.props.username.length>1){
                            username = this.props.username;
                        }
                        if(username===undefined || username ==='') username = 'Padawan';
                        this.addNewUserSignin(signInMethod, email, username, uid)
                        .then( (result )=> {
                            //console.log( 'doublecheck', result)
                            if(result.success) {
                                setTimeout(() => {
                                    console.log('Timeout Stored user set')
                                }, 1000);
                                this.props.history.replace(ROUTES.PROFILE);
                                this.props.history.push(ROUTES.HOME);
                            }
                            else this.props.history.push(ROUTES.SIGN_UP);
                        })
                    }
                }
                if(userExisting) {
                    //console.log('Check succesfull user data exist', userCheck.data())
                    //this.props.history.push(ROUTES.HOME);
                    var userDataSet = {...userCheck.data(), uid: uid};
                    if(this.props.createCoachAccount){
                        var updateExtraSet = ReturnCoachAccountSettings(this, uid, userDataSet);
                        if(updateExtraSet.userBatch !== undefined){
                            this.props.firebase.user(uid).update(updateExtraSet);
                        }
                    }
                    DataCacheWrite('authUser', {uid: uid, timezone: userDataSet['timezone'], roles: userDataSet['roles']}, 1);
                    setTimeout(() => {
                        console.log('setTimeout Stored user set')
                    }, 1000);
                    var usernameOld = userCheck.data().username;
                    var username = usernameOld;
                    if(this.state.username!==undefined && this.state.username.length>1){
                        username = this.state.username;
                    }
                    else if(this.props.username!==undefined && this.props.username.length>1){
                        username = this.props.username;
                    }
                    if(username !== usernameOld){
                        console.log('udpate user name ofr existing user', username)
                        this.props.firebase.user(uid).update({ username: username });
                    }
                    this.props.history.replace(ROUTES.PROFILE);
                    ///// EXISTING USERS Go Straight to home
                    this.props.history.push(ROUTES.HOME);
                }
            })
            .catch(error => {
                this.setState({redirectLoginDetected: false})
                console.log('Doublecheck error', error)
            })
        }
        else{
            console.log('No Id delivered with doublecheck', uid)
            this.setState({redirectLoginDetected: false})
        }
    }

    addNewUserSignin = async (signInMethod, email, username, uid ) => {
        try{
            var newSet = await GetDefaultUserSetup(this.props.firebase);
            const timestamp = this.props.firebase.fieldValue.serverTimestamp();
            if (email && email !== ''){
                newSet = Object.assign(newSet, {
                    email,
                    username,
                    journeyStartedAt: timestamp,
                    signInMethod,
                    uid: uid
                });
                if(this.props.createCoachAccount){
                    var updateExtraSet = ReturnCoachAccountSettings(this, uid, newSet);
                    newSet = Object.assign(newSet, updateExtraSet);
                }
                //console.log('init db to be stores', newSet);
                //console.log('user id', result.user.uid);
                return this.props.firebase.user(uid).set(newSet, { merge: true })
                .then( ( )=> {
                    //DataCacheWrite('authUser', newSet, 1)
                    DataCacheWrite('authUser', {uid: uid, timezone: newSet['timezone'], roles: newSet['roles']}, 1);
                    //console.log('going home after 1', this.props.history)
                    //console.log('registered user', this.state)
                    //console.log('registered user', this.state.readyToLoad)
                    //console.log('registered user', this.props)
                    //console.log('going home after2', this.props.history)
                    return {success: true, onboardingDone: true}; 
                   /////////// need change is false
                })
                
            } else{
                //this.setState({redirectLoginDetected: false});
                //console.log('no email', result)
                var error = new Error(pageText.ERROR_MSG_SOCIAL_FAILED); 
                this.setState({error});
                return {success: false, onboardingDone: true};
            }        
        }
        catch(error) {
            //this.setState({redirectLoginDetected: false});
            //console.log('error', error.message)
            error.message = pageText.ERROR_MSG_GETDEFAULTSETTING_FAILED;
            this.setState({ error });
            return {success: false, onboardingDone: true};
        }
    }

    render() {
        const { classes, theme } = this.props;
        const { email, password, error } = this.state;
        //const isInvalid = password === "" || email === "";
        return (
            <div style={{width: '100%'}}>
                <Dialog
                    open={this.state.redirectLoginDetected || false}
                    onClose={()=>{this.setState({redirectLoginDetected:false})}}
                    PaperProps={{style:{borderRadius: theme.borderRadius}}}
                    >
                    <DialogContent>
                        <div className={classes.form}>
                            {!error &&
                                <LoadingProgress 
                                    defaultSet={this.props.defaultSet}
                                    type={'LoadingPageAnimated'} loadingText={"loading login"} />
                            }
                            {error && <p className={classes.error}>{error.message}</p>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{this.setState({redirectLoginDetected:false})}} color="primary">
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.showEmailSignInForm !== true
                    &&
                    <div className={classes.socialLogins}>
                        <Button 
                            onClick={() => this.setState({showEmailSignInForm: true})}
                            className={classes.buttonBottom}
                            //variant = "contained"
                            >
                            <EmailIcon/>&nbsp;Log In with Email
                        </Button>
                    </div>
                }
                {this.state.showEmailSignInForm === true
                    &&
                    <form onSubmit={this.onSubmit}>
                        <TextField
                            name="email"
                            autoComplete="username"
                            label={pageText.form_email}
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            variant="outlined"
                            placeholder={pageText.form_email}
                            fullWidth
                            className={classes.textField}
                        />
                        <TextField
                            name="password"
                            autoComplete="current-password"
                            label={pageText.form_password}
                            value={password}
                            onChange={this.onChange}
                            type={this.state.showPassword ? "text" : "password"}
                            variant="outlined"
                            placeholder={pageText.form_password}
                            fullWidth
                            className={classes.textField}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({showPassword: !this.state.showPassword})}
                                        >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                        {error && error !== null &&
                            error.code !== pageText.ERROR_CODE_USER_NOT_FOUND && error.code !== pageText.ERROR_CODE_WRONG_PASSWORD
                            ?
                                <p className={classes.error}>{error.message}</p>
                            :
                                ""
                        }

                        {error && error !== null &&
                            error.code === pageText.ERROR_CODE_USER_NOT_FOUND
                            ?
                                <p className={classes.error}>{error.message} <Link to={ROUTES.SIGN_UP}>{pageText.linkResetNEW}</Link></p>
                            :
                                ""
                        }
                        {error && error !== null &&
                            error.code === pageText.ERROR_CODE_WRONG_PASSWORD
                            ?
                                <p className={classes.error}>{error.message} <Link to={ROUTES.PASSWORD_FORGET}>{pageText.linkResetPW}</Link></p>
                            :
                                    ""
                        }
                        <div>
                            <Link to={ROUTES.PASSWORD_FORGET}>
                                    {pageText.linkForgot}
                            </Link>
                        </div>
                        <div className={classes.socialLogins}>
                            <Button className={classes.buttonBottom}
                                variant = "contained"
                                //disabled={isInvalid}
                                type="submit"  >
                                {pageText.linkLogin}
                            </Button>
                        </div>
                    </form>
                }
                {this.props.showSocialLogin &&
                    <div style={{visibility: this.state.showEmailSignInForm === true ? 'visible': 'hidden'}}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td><hr /></td>
                                    <td style={{width:1, padding: '0 10px', whiteSpace: 'nowrap'}}>
                                        <span className={classes.socialLoginPrompt}>or</span>
                                    </td>
                                    <td><hr /></td>
                                </tr>
                            </tbody>
                        </table>
                        <SignInSocialForward
                            isCoach={true}
                            loginType={this.state.loginType} 
                        />
                    </div>
                }
            </div>
        );
    }
}

export function ReturnCoachAccountSettings(that, uid, userData){
    var userUpdate = {}; 
    if(userData === undefined || userData.roles === undefined || !(userData?.roles?.includes('REMOTECOACH')) || !(userData?.roles?.includes('COACHADMIN'))){
        userUpdate = {
            roles: that.props.firebase.fieldValue.arrayUnion('REMOTECOACH'),
            groups: that.props.firebase.fieldValue.arrayUnion('#ROCKYFORCOACHES'),
            userBatch: 'ROCKYFORCOACHES'
        }
        if(userData.code === undefined || userData.code === ''){
            userUpdate['code'] = '#ROCKYFORCOACHES';
        }
        console.log('return data user as Coach')
        return userUpdate;
    }
    else{
        console.log('existing coach account')
        return userUpdate;
    }
}

const SignInForm = compose(
    withStyles(stylesSignin, { withTheme: true }),
    withRouter,
    withFirebase
)(SignInFormBase);

SignInPage.propTypes = {
    classes: PropTypes.object.isRequired
};
export default compose(
    withRouter,
    withStyles(stylesSignin, { withTheme: true }),
    withFirebase,
)(SignInPage);

export { SignInForm };