function muiStyle (palette) { return {
    root: {
        color: palette.secondary.main,
        '&:hover': { // Target hover state for visual feedback
            color: palette.secondary.main // Change hover color
        },
        '&:active': { // Target hover state for visual feedback
            color: palette.secondary.light // Change hover color
        },
        '&:visited': { // Target hover state for visual feedback
            color: palette.secondary.main // Change hover color
        },
    }
}}

export default muiStyle;