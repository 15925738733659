import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import ChatBlock from '../Routine/ChatBlock';
import Card from '@material-ui/core/Card';
import {AddConversation} from '../Support/MessagesUser';
import DialogSupport from '../Support/DialogSupport';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';

import SpeechToTextButton from '../Modules/SpeechToTextButton';
import SendIcon from '@material-ui/icons/Send';
import { CreateAlert } from '../Modules/CreateAlert';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        backgroundColor: '#2d2c4c',
    },
});

class OneOnOneSupport extends Component {  
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
    
        this.state = {
            messages : [],
            loading: true,
        };
    }


    componentDidMount() {
        //console.log('props',this.props.user?.username, this.props, this.state)
        this._isMounted = true;
        this.loadSupportMessages();
    }
    
    componentWillUnmount() {
        this._isMounted =false;
        if(this.unsubscribeMessages !== undefined) this.unsubscribeMessages()
    }

    componentDidUpdate(prevProps)  {
        if(this.props.userId !== undefined && this.props.userId !== "" && this.props.userId !== prevProps.userId && this.props.userId !== this.state.userId){
            //console.log('update dielg', this.props.user?.username)
            this.setState({
                dialogueId: undefined, 
                dialogueTitle: undefined,
                supportChatAvailable: false,
                messages: [], 
                messageListById: [], 
                supportChatArchive: []
            }, () => this.loadSupportMessages());
        }
        return true
    }
    
    

    loadSupportMessages() {
        if(this.unsubscribeMessages !== undefined){this.unsubscribeMessages();}
        if(this._isMounted){
            this.setState({
                messages: [], 
                messageListById: [], 
                supportChatArchive: []
            })
        }
        //console.log('loaded Messages',this.props, this.state)
        var supportChatAvailable = false;
        let supportChatArchive = [];
        var dialogueId = '';
        var dialogueTitle = 'Notes';
        if(this.props.user !== undefined && this.props.user.uid !== undefined && this.props.groupId !== undefined){
            var groups = ["default"];
            if (this.props.groupId !== undefined && this.props.groupId !== ''){
                groups.push(this.props.groupId);
            }
            //console.log('this.props.user', this.props.user)
            if(this.props.user.groups !== undefined && this.props.user.groups.length >= 1){
                groups = this.props.user.groups;
                groups.unshift("default");
                if(groups.length > 10){
                    groups = groups.slice(0,9);
                }
                //console.log('groups', groups)
            }
            this.props.firebase
            .dialogues()
            .where('userId', '==', this.props.user.uid)
            //.where('coachId', '==', this.props.groupId)
            .where('coachId', 'in', groups)
            .where('dialogueType', '==', "SUPPORT")
            .orderBy('createdAt', 'desc')
            .limit(3)
            .get().then(snapshotDialogues => {
                if (snapshotDialogues.size) {
                    var indexChat = 0;
                    snapshotDialogues.forEach( (doc) => {
                        //console.log('xx coach dialog', doc.data())
                        if(indexChat === 0){
                            dialogueId = doc.id;
                            if(doc.data().dialogueEnded !== true){
                                supportChatAvailable = true;
                            }
                            dialogueTitle = doc.data().title;
                            console.log('last coach dialog', dialogueId)
                        }
                        else{
                            supportChatArchive.push({...doc.data(), id: doc.id});
                        }
                        indexChat += 1;
                    })
                }
                else if(false && this.props.user !== undefined){
                    dialogueId = this.props.user.currentDialogue;
                    supportChatAvailable = false;
                    console.log('last user dialog', dialogueId)
                }
                if(dialogueId!== undefined && dialogueId!== ''){
                    this.unsubscribeMessages = this.props.firebase
                    .messages()
                    .orderBy('createdAt', 'asc')
                    .where('dialogueId', '==', dialogueId)
                    .onSnapshot(snapshot => {
                        if (snapshot.size) {
                            let loadedMessages = [];
                            let messageListById = [];
                            snapshot.forEach( doc => {
                                var newMessage = doc.data();
                                if(!messageListById.includes(doc.id) && newMessage.text !== undefined && newMessage.text.length>0 ){
                                    if(newMessage?.utteranceClass?.includes('utter_goodbye')){
                                        // do nothing
                                    }
                                    else {
                                        messageListById.push(doc.id);
                                        loadedMessages.push({ ...newMessage, uid: doc.id });
                                        //messageListById = [doc.id];
                                        //loadedMessages = [{ ...newMessage, uid: doc.id }];
                                    }
                                }
                            })
                            messageListById = messageListById.slice(-2);
                            loadedMessages = loadedMessages.slice(-2);
                            //console.log('x messages',loadedMessages)
                            if(this._isMounted ){
                                this.setState({
                                    messages: loadedMessages,
                                    messageListById : messageListById,
                                    loading: false,
                                    dialogueTitle,
                                    dialogueId,
                                    supportChatAvailable,
                                    supportChatArchive
                                });
                            }
                        } 
                        else {
                            if(this._isMounted ){
                                this.setState({ 
                                    messages: [], 
                                    messageListById: [], 
                                    loading: false, 
                                    dialogueTitle
                                });
                            }
                            console.log('no message requesting')
                            //this.request();
                        }
                    });
                }
            })
        }
    }

    render(){
        const { theme } = this.props;
        return(
            <div style={{width:"100%", marginBottom: 40}}>
                <Card style={{borderRadius: theme.borderRadius}}>
                    <div style={{padding: 6, minHeight: 50}}>
                        <p><strong>Messaging | {this.state.dialogueTitle || 'Start Conversation'}</strong></p>
                        {this.state.messages!== undefined && this.state.messages.length>0
                            &&
                            <>
                                <ChatBlock 
                                    variant={"chatMain"}
                                    currentDialogueId={this.state.dialogueId}
                                    messages={this.state.messages} 
                                    showTextInputHandler={this.showTextInputHandler} 
                                    invertCoach={this.props.isCoach || false}
                                    noAnimation={true}
                                    showDetail={true}
                                    user={this.props.user}
                                    typingDoneHandler={() => {
                                        //this.messagesEnd.scrollIntoView({ behavior: "smooth" })
                                    }}
                                />
                                <div style={{textAlign: 'right', width: '100%'}}>
                                    {this.state.supportChatAvailable &&
                                        <Button onClick={() => this.setState({openMessaging: true})}>
                                            Show
                                        </Button>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <TextField
                        name="comment"
                        style={ {fontSize: '16px'} }
                        value={this.state.comment || ""} 
                        onChange={(e) => {this.setState({ comment: e.target.value})} }
                        type="text"
                        variant="outlined"
                        placeholder= {this.props.coach ? "Send private message or reminder to user" : "Add your request here..."}
                        fullWidth
                        multiline
                        InputProps={{
                            style:{fontSize: '16px', backgroundColor: theme.palette.white, borderRadius: theme.borderRadius},
                            endAdornment: (
                                <InputAdornment position="end">
                                    {this.state.comment!=="" && this.state.comment!==undefined
                                        ?
                                            <IconButton
                                                edge="end"
                                                aria-label="Send message"
                                                onClick={ this.onSubmit(this.state.comment)}
                                                >
                                                { this.state.comment.length >  10 
                                                    ? <SendIcon style={{color: theme.palette.themeRed}} />
                                                    : <SendIcon  />
                                                }
                                            </IconButton>
                                        :            
                                            <IconButton
                                                edge="end"
                                                aria-label="Write message"
                                                disabled={true}
                                                >
                                                <SendIcon />
                                            </IconButton>
                                    }
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SpeechToTextButton 
                                        renderKey={'1on1_'}
                                        returnText={(result) => this.setState({comment : result})}
                                        existingText={this.state.comment || ""}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Card>

                <DialogSupport 
                    dialogueId = {this.state.dialogueId || ''}
                    dialogueTitle = {this.state.dialogueTitle || ''}
                    userId = {this.props.user?.uid}
                    userData = {this.props.user}
                    dialogueArchive = {false}
                    hideArchive = {true}
                    isCoach = {this.props.isCoach || false}
                    groupOwners = {this.props.groupOwners}
                    username = {this.context.username !== undefined ? this.context.username.split("/")[0] : 'User'}
                    groupId = {this.props.groupId}
                    openMessaging = {this.state.openMessaging || false}
                    handleCloseMessaging = {() => this.setState({openMessaging: false})}
                    />
                {false && this.state.supportChatArchive!== undefined && this.state.supportChatArchive.map( (archive, index) => (
                    <div>
                        Archive {index}
                    </div>
                ) )}
            </div>
        )// end return
        
    }//end render

    onSubmit = (text) => async () => {
        console.log('submit new dialogue', this.props, this.state)
        if(this.state.supportChatAvailable !== true || this.state.dialogueId === undefined){
            AddConversation(this, this.context.uid, this.props.groupId, this.props.userId, this.props.user)
        }
        else if(this.state.dialogueId !== undefined && this.state.dialogueId !== '' && text!=="" && this._isMounted){
            var username = this.context.username!==undefined && this.context.username !=="" ? this.context.username.split('/')[0] : "User";
            var messageData= {
                userId: this.props.userId,
                groupId: this.props.groupId,
                isCoach: true,
                createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                dialogueId: this.state.dialogueId,
                text: [text],
                username: username
            }
            if(this.props.isCoach){
                messageData['isCoach'] = true;
                messageData['coachId'] = this.context.uid;
            }
            console.log('messageData', messageData)
            this.props.firebase.messages().add(messageData)
            this.setState({comment:""})
            var alertDict = {}
            if(this.props.isCoach){
                var userContext = this.props.user;
                userContext['uid'] = this.props.userId;
                userContext['username'] = username;
                alertDict = {
                    coachId : this.context.uid,
                    userId : this.props.userId,
                    groupId : this.props.groupId,
                    dialogueId : this.state.dialogueId,
                    type : "SUPPORT"
                }
                //this.updateAlerts(this.state.dialogueId,this.context.uid, this.props.userId, this.props.groupId, username, text, this.props.dialogueTitle)
                //console.log(this.props.user)
                CreateAlert(this.props.firebase, this.props.user, text, this.state.dialogueTitle, username, new Date(), alertDict )
            }
            else{
                if (this.props.groupOwners!== undefined && this.props.groupOwners.length > 0){
                    this.props.groupOwners.map((coachId) => {
                        alertDict = {
                            coachId : coachId,
                            userId : this.props.userId,
                            groupId : this.props.groupId,
                            dialogueId : this.state.dialogueId,
                            type : "SUPPORT"
                        }
                        CreateAlert(this.props.firebase,  {uid: coachId, username: 'Coach', emailNotifications:'daily'}, text, this.state.dialogueTitle, username, new Date(), alertDict )
                        return true;
                        //this.updateAlerts(this.state.dialogueId, coachId, this.props.userId, this.props.groupId, username, text, this.props.dialogueTitle)
                    })
                }
                else{
                    alertDict = {
                        coachId : 'default',
                        userId : this.props.userId,
                        groupId : this.props.groupId,
                        dialogueId : this.state.dialogueId,
                        type : "SUPPORT"
                    }
                    CreateAlert(this.props.firebase, {uid: 'AwWa9CbyOMY5hcSyeo68ihVbRnx1', username: 'Customer Support', emailNotifications:'daily'}, text, this.state.dialogueTitle, username, new Date(), alertDict )
                    //this.updateAlerts(this.state.dialogueId,'default', this.props.userId, this.props.groupId, username, text, this.props.dialogueTitle)
                }
            }
        }
    }

    archiveDialogue(){
        var data = {
            phase: 'session_ended',
            dialogueEnded: true
        }
        this.props.firebase.dialogue(this.state.dialogueId).update(data); 
        this.props.firebase.dialoguePostprocess(this.state.dialogueId, 'dialogueEnded').set(data, {merge:true});
        this.props.handleCloseMessaging()    
    }

};//end class

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(OneOnOneSupport);