function muiStyle (palette) { return {
    color: palette.black,

    root: {
        //fontSize: 44,
        "&.MuiStepIcon-active": {
            color: palette.secondary.main
        },
        "&.MuiStepIcon-completed": {
            color: palette.themeAlertGreen
        },
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        color: palette.themeGrey
    }
}}

export default muiStyle;