import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { Link } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import SnackbarInfo from '../Modules/SnackbarInfo';
import MessagesUser from '../Support/MessagesUser';
import HomeIcon from '@material-ui/icons/Home';
import LoadingProgress from '../Modules/LoadingProgress';

///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

//import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GroupIcon from '@material-ui/icons/Group';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import {IsMobileDevice} from '../Modules/IsMobileDevice';



//import Grid from '@material-ui/core/Grid';
//import IconButton from '@material-ui/core/IconButton';
//import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';



const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    highlightTab: {
        position: theme.highlightTab.position,
        left:  IsMobileDevice()? theme.HomeNoNavigationShiftContent.paddingLeft : theme.HomeNavigationShiftContent.paddingLeft ,
        top: IsMobileDevice()? theme.highlightTab.top : 60,
        backgroundColor:theme.highlightTab.backgroundColor, 
        zIndex:theme.highlightTab.zIndex,
    },
    unHighlightTab: {
        position: "flexible",
        //backgroundColor:theme.highlightTab.backgroundColor, 
        backgroundColor: "transparent", 
    },
});



class HomeSupport extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.dialogRef = React.createRef();
        var startTab = "default";
        this.state = {
            loading: true,
            ownedGroups: ["default"],
            value: startTab,
            days: [],
            user: null,
            headerVisible: true,
        };
    }
    handleChange = (event, newValue) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                //document.body.scrollTop = document.documentElement.scrollTop = 0;
                if(this.dialogRef !== undefined ) this.dialogRef.current.scrollIntoView({behaviour: 'smooth'});
                //if(this.dialogRef !== undefined && this.dialogRef.scrollTop !== undefined ) this.dialogRef.scrollTop  = 0;
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
            });
            //console.log(this.state)
        }
    }
    
    componentDidMount() {
        this._isMounted = true;
        this.loadGroups();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadGroups() {
        if(this.context.roles !== undefined && 
            (this.context.roles?.includes('REMOTECOACH')
            || this.context.roles?.includes('ADMIN')
            || this.context.roles?.includes('COACHADMIN'))
            ){
            this.props.firebase.groups()
            //.where('code', 'in', this.context.groups)
            .where('owner', 'array-contains', this.context.uid)
            .get()
            .then((data) => {
                var ownedGroups = [];
                var groupOwners = {};
                var startTab = 'default';
                data.forEach(myGroupsDoc => {
                    ownedGroups.push(myGroupsDoc.id);
                    //startTab = myGroupsDoc.id;
                    groupOwners[myGroupsDoc.id] = myGroupsDoc.data().owner;
                })
                ownedGroups.push("default")
                //console.log('admin', ownedGroups, groupOwners, startTab)
                if(this._isMounted){
                    this.setState({ownedGroups, groupOwners, loading: false, value: startTab})
                }
            })
        }
        else{
            var ownedGroups = [];
            var startTab = 'default';
            if(this.context.groups !== undefined && this.context.groups.length>0){
                this.props.firebase.groups()
                .where('code', 'in', this.context.groups)
                .get()
                .then((data) => {
                    data.forEach(myGroupsDoc => {
                        ownedGroups.push(myGroupsDoc.id);
                        startTab = myGroupsDoc.id;
                    })
                    ownedGroups.push("default")
                    //console.log('user', ownedGroups)
                    if(this._isMounted){
                        this.setState({ownedGroups, loading: false, value: startTab})
                    }
                })
            }
            else{
                if(this._isMounted){
                    this.setState({ loading: false})
                }
            }
        }
    }
    

    render() {
        const { theme } = this.props;
        const { loading, value, ownedGroups } = this.state;
        const { user, screen } = this.props;
        let today = new Date(Date.now());
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        if(user !== null ){
            //console.log("new user", user)
            return(
                <Grid container direction="row" ref={this.dialogRef}>
                    <Grid item xs={screen==="desktop" ? 3 : false} >
                        {screen==="desktop"
                            &&
                                <Card style={theme.CardDesktopLeft}>
                                    <Card style={theme.CardNaviLeft}>
                                        <h4 style={theme.CardContent.titleText}>Support</h4>
                                        {this.renderTabs("vertical")}
                                    </Card>
                                </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 9 : 12} >
                        {loading
                            ?
                                <div>
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"loading coach support"} />
                                </div>
                            :
                                <div>
                                    {screen!=="desktop"
                                    &&
                                        <VizSensor 
                                            partialVisibility={"top"}
                                            onChange={(isVisible) => { 
                                                if (IsMobileDevice()){
                                                    this.setState({headerVisible: false});
                                                }
                                            }}>
                                            <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                        </VizSensor>
                                    }
                                    {screen!=="desktop"
                                        ?
                                            <Card style={{
                                                    ...theme.CardTop, 
                                                    position: this.state.headerVisible? 'relative' : 'fixed',
                                                    zIndex: this.state.headerVisible? 0 : 100,
                                                    top: 0,
                                                    opacity: this.state.headerVisible? '1.0' : '0.94',
                                                }}>
                                                <p  hidden={ !this.state.headerVisible} style={theme.textTodayDate}>{weekdays[today.getDay()] + ', ' + months[today.getMonth()] + ' ' + today.getDate()}</p>
                                                {this.state.headerVisible
                                                    ? <h2 style={theme.CardContent.titleText}><GroupIcon />&nbsp; Support</h2>
                                                    : <div style={theme.textMonthCenter}><strong>Support</strong></div>
                                                }
                                                {user !== null && ownedGroups!== undefined && ownedGroups.length > 0
                                                &&
                                                    this.renderTabs()   
                                                }
                                                
                                            </Card>
                                        :
                                            <h2 style={theme.CardContent.titleText}><GroupIcon />&nbsp; Support - {value}</h2>
                                    }
                                    
                                    <Card style={{
                                            ...theme.CardContentItem.CardContentSpace,
                                            paddingTop: this.state.headerVisible? 0 : 200,
                                            paddingBottom: this.state.headerVisible? 0 : '70vh',
                                        }} >
                                        
                                        {user !== null && ownedGroups!== undefined && ownedGroups.length > 0
                                        ?
                                            <div>
                                                {
                                                    <div key={'Latest'} index={0} hidden={value !== 'Latest'}>
                                                        <MessagesUser 
                                                            groupId={'Latest'}
                                                            isCoach={
                                                                value==='default' 
                                                                    ?   (this.context.roles?.includes("ADMIN")) ? true : false 
                                                                    :   (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("COACHADMIN")) ? true : false
                                                            }
                                                            groupOwners={['default']}
                                                        />  
                                                    </div>
                                                }
                                                {ownedGroups.map((id, index) => (
                                                    <div key={id} index={0} hidden={value !== id}>
                                                        {(value===id || this.state[id]) && 
                                                            <MessagesUser 
                                                                groupId={value}
                                                                isCoach={
                                                                    value==='default' 
                                                                        ?   (this.context.roles?.includes("ADMIN")) ? true : false 
                                                                        :   (this.context.roles?.includes("ADMIN") || this.context.roles?.includes("REMOTECOACH") || this.context.roles?.includes("COACHADMIN")) ? true : false
                                                                }
                                                                groupOwners={this.state.groupOwners!==undefined && this.state.groupOwners[value] !==undefined ? this.state.groupOwners[value] : ['default']}
                                                            />    
                                                        }
                                                    </div>
                                                ))}
                                                
                                                <SnackbarInfo 
                                                    alertText = 'You can communicate here with your assigned coach or with the Technical Support.'
                                                    alertName = 'supportintro'
                                                    userId = {this.context.uid}
                                                    />
                                            </div>
                                        :
                                            <div>
                                                <p>You can communicate here with your assigned coach or with the Technical Support.</p>
                                            </div>
                                        }
                                    </Card>
                                </div> 
                        }
                    </Grid>
                </Grid>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent} ref={this.dialogRef}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

    renderTabs(orientation = "horizontal") {
        const { value, ownedGroups  } = this.state;
        const { user, theme } = this.props;
        var messageTotalCounter = 0;
        if(this.context.newMessages!== undefined){
            try{ messageTotalCounter = this.context.newMessages.filter(q => q.type === "SUPPORT").length;}
            catch (error) {messageTotalCounter = 0}
        }
        if(orientation === 'vertical'){
            return(
                <>
                    <Link to={ROUTES.HOME}>
                        <span><HomeIcon style={theme.inlineIconLeft}/> Home</span>
                    </Link>
                    <hr/>
                    <Link to={ROUTES.SUPPORT} onClick={() => (this.handleChange(undefined, "Latest"))}>
                        <span><GroupIcon style={theme.inlineIconLeft}/> Latest Messages</span>
                    </Link>
                    <hr/>
                    {ownedGroups?.includes("default")
                        &&
                        <>
                            <Link to={ROUTES.SUPPORT} onClick={() => (this.handleChange(undefined, 'default'))} 
                                value={'default'} key={'default'}>
                                <span><HeadsetMicIcon style={theme.inlineIconLeft}/>
                                    <Badge badgeContent={ this.context.newMessages?.filter(q => q.groupId === 'default')?.length || 0 }
                                        color="error"> Support Request
                                    </Badge>
                                </span>
                            </Link>
                            <hr/>
                        </>
                    }
                    {ownedGroups.map((id, index) => {
                        var messageCounter = 0;
                        if(this.context.newMessages!== undefined){
                            try{ messageCounter = this.context.newMessages.filter(q => q.groupId === id).length;}
                            catch (error) {messageCounter = 0}
                        }
                        if(id==="default"){
                            return ""
                        }
                        else{
                            return(
                                <Link to={ROUTES.SUPPORT} onClick={() => (this.handleChange(undefined, id))} 
                                    value={id} key={index}>
                                    <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap'}}>
                                        <Badge badgeContent={ messageCounter }
                                            color="error">
                                            {id==="default" ? "Support Request" : id}
                                        </Badge>
                                    </li>
                                </Link>
                            )
                        }
                    })}
                </>
            )
        }
        else{
            return(
                <>
                {user !== null && ownedGroups!== undefined && ownedGroups.length > 0
                    ?  
                    <Tabs
                        value={value}
                        orientation={orientation}
                        onChange={this.handleChange}
                        aria-label="My Groups"
                        variant="scrollable"
                        scrollButtons="auto"
                        initialselectedindex={"Community"}
                        style={{
                            marginTop: 20,
                            marginBottom: -1,
                            marginLeft: -20,
                            width: 'calc(100% + 40px)'
                        }}
                        ref={el => this.containerLine = el}
                    >
                        {false && this.props.screen==="desktop" && 
                            <Tab value="Home" label={<span><HomeIcon style={theme.inlineIconLeft}/> Home</span>}/>
                        }
                        <Tab value={'Latest'} key={'Latest'} 
                            label={
                                <Badge badgeContent={ messageTotalCounter }
                                    color="error">
                                    Latest Messages
                                </Badge>
                            } 
                        />
                        {ownedGroups.map((id, index) => {
                            var messageCounter = 0;
                            if(this.context.newMessages!== undefined){
                                try{ messageCounter = this.context.newMessages.filter(q => q.groupId === id).length;}
                                catch (error) {messageCounter = 0}
                            }
                            return(
                                <Tab value={id} key={index} 
                                    label={
                                        <Badge badgeContent={ messageCounter }
                                            color="error">
                                            {id==="default" ? "Support Request" : id}
                                        </Badge>
                                    } 
                                />
                            )
                        })}
                    </Tabs>                
                    : "loading groups"
                } 
                </>
            )
        }
    }
} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeSupport);
  
